import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TrendingUp from '@material-ui/icons/TrendingUp';
import AccountBalance from '@material-ui/icons/AccountBalance';
import Assessment from '@material-ui/icons/Assessment';
import Backup from '@material-ui/icons/Backup';
import CardTravel from '@material-ui/icons/CardTravel'
import Commute from '@material-ui/icons/Commute'
import OfflineBolt from '@material-ui/icons/OfflineBolt'
import GroupWork from '@material-ui/icons/GroupWork'
import DonutSmall from '@material-ui/icons/DonutSmall'
import Explore from '@material-ui/icons/Explore'
import Fingerprint from '@material-ui/icons/Fingerprint'
import EventSeat from '@material-ui/icons/EventSeatOutlined'
import Rowing from '@material-ui/icons/Rowing'
import Reorder from '@material-ui/icons/Reorder'
import Visibility from '@material-ui/icons/Visibility'
import Extension from '@material-ui/icons/ExtensionOutlined'
import Star from '@material-ui/icons/StarsRounded'
import TextR from '@material-ui/icons/TextRotateUp'
import FindReplace from '@material-ui/icons/FindReplace'
import Polymer from '@material-ui/icons/Polymer'
import Print from '@material-ui/icons/Print'
import OpenInNew from '@material-ui/icons/OpenInNew'
import AspectRatio from '@material-ui/icons/AspectRatio'
import Backspace from '@material-ui/icons/Backspace'
import Cached from '@material-ui/icons/Cached'
import Deck from '@material-ui/icons/Deck'
import Eco from '@material-ui/icons/Eco'
import PlusOne from '@material-ui/icons/PlusOne'

import CardHeader from '@material-ui/core/CardHeader';
import AcUnit from '@material-ui/icons/AcUnit'
import Book from '@material-ui/icons/Book'
import CastConnected from '@material-ui/icons/CastConnected'
import '../rules/RadioBox.css'

import DateRange from '@material-ui/icons/DateRange'
import EcoTwoTone from '@material-ui/icons/EcoTwoTone'
import FeaturedVideo from '@material-ui/icons/FeaturedVideo'
import OfflinePin from '@material-ui/icons/OfflinePin'
import QuestionAnswer from '@material-ui/icons/QuestionAnswer'
import Cloud from '@material-ui/icons/Cloud'
import CommuteSharp from '@material-ui/icons/CommuteSharp'
import LocalCarWash from '@material-ui/icons/LocalCarWash'
import MapSharp from '@material-ui/icons/MapSharp'
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
    card: {
        maxHeight: 65,
        border: '1px #c5d9c5 solid',
        marginBottom: 0,
        // backgroundColor: theme.palette.background.default,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
        },
        whiteSpace: "nowrap"
    },
    title1: {
        fontSize: 10,
        whiteSpace: "nowrap"
    },
    avatar1: {
        fontSize: 22
    },
}));


export default function RuleTp5(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [enable, setEnable] = React.useState(false)

    const getEnableColor = () => {
        return theme.palette.type === 'light' ? "#f6fcdc" : "#474747"
    }

    const handleCardChange = (enabled) => {
        setEnable(enabled)
        updateValue(enabled)
    }

    const clickTitle = () => {
        handleCardChange(!enable);
    };

    const updateValue = (enabled) => {
        let data = {
            id: props.id
        }

        if (enabled) {
            props.ruleChange(props.id, data, enabled)
        } else {
            props.ruleChange(props.id, data, enabled)
        }
    }

    const isTurnOn = () => {
        if (props.rules != undefined) return props.rules.some(rule => rule.id === props.id);
        else return false
    }

    const getIcon = () => {
        let icon = <TrendingUp color="primary" className={classes.avatar1} />
        if (props.id === '33') {
            icon = <TrendingUp color="primary" className={classes.avatar1} />
        } else if (props.id === '34') {
            icon = <AccountBalance color="primary" className={classes.avatar1} />
        } else if (props.id === '35') {
            icon = <Assessment color="primary" className={classes.avatar1} />
        } else if (props.id === '36') {
            icon = <Backup color="primary" className={classes.avatar1} />
        } else if (props.id === '37') {
            icon = <CardTravel color="primary" className={classes.avatar1} />
        } else if (props.id === '38') {
            icon = <Commute color="primary" className={classes.avatar1} />
        } else if (props.id === '39') {
            icon = <OfflineBolt color="primary" className={classes.avatar1} />
        } else if (props.id === '40') {
            icon = <GroupWork color="primary" className={classes.avatar1} />
        } else if (props.id === '41') {
            icon = <DonutSmall color="primary" className={classes.avatar1} />
        } else if (props.id === '42') {
            icon = <Explore color="primary" className={classes.avatar1} />
        } else if (props.id === '43') {
            icon = <Fingerprint color="primary" className={classes.avatar1} />
        } else if (props.id === '101') {
            icon = <EventSeat className={classes.avatar1} />
        } else if (props.id === '201' || props.id === "247") {
            icon = <Rowing color="primary" className={classes.avatar1} />
        } else if (props.id === '48') {
            icon = <Reorder color="primary" className={classes.avatar1} />
        } else if (props.id === '47') {
            icon = <Visibility color="primary" className={classes.avatar1} />
        } else if (props.id === '202') {
            icon = <Extension color="primary" className={classes.avatar1} />
        } else if (props.id === '203') {
            icon = <Star color="primary" className={classes.avatar1} />
        } else if (props.id === '204') {
            icon = <TextR color="primary" className={classes.avatar1} />
        } else if (props.id === '205') {
            icon = <Print color="primary" className={classes.avatar1} />
        } else if (props.id === '206') {
            icon = <Polymer color="primary" className={classes.avatar1} />
        } else if (props.id === '207') {
            icon = <FindReplace color="primary" className={classes.avatar1} />
        } else if (props.id === '208' ) {
            icon = <AspectRatio color="primary" className={classes.avatar1} />
        } else if (props.id === '209') {
            icon = <OpenInNew color="primary" className={classes.avatar1} />
        } else if (props.id === '210'  || props.id === "253") {
            icon = <AcUnit color="primary" className={classes.avatar1} />
        } else if (props.id === '211') {
            icon = <Book color="primary" className={classes.avatar1} />
        } else if (props.id === '212') {
            icon = <Backspace color="primary" className={classes.avatar1} />
        } else if (props.id === '213') {
            icon = <Cached color="primary" className={classes.avatar1} />
        } else if (props.id === '214') {
            icon = <Deck color="primary" className={classes.avatar1} />
        } else if (props.id === '215' || props.id === "251") {
            icon = <Eco color="primary" className={classes.avatar1} />
        } else if (props.id === '216') {
            icon = <CastConnected color="primary" className={classes.avatar1} />
        } else if (props.id === '217') {
            icon = <DateRange color="primary" className={classes.avatar1} />
        } else if (props.id === '218'  || props.id === "249") {
            icon = <EcoTwoTone color="primary" className={classes.avatar1} />
        } else if (props.id === '219') {
            icon = <FeaturedVideo color="primary" className={classes.avatar1} />
        } else if (props.id === '220') {
            icon = <PlusOne color="primary" className={classes.avatar1} />
        } else if (props.id === '221' || props.id === '233') {
            icon = <QuestionAnswer color="primary" className={classes.avatar1} />
        } else if (props.id === '222' || props.id === '246') {
            icon = <OfflinePin color="primary" className={classes.avatar1} />
        } else if (props.id === '224') {
            icon = <Assessment color="primary" className={classes.avatar1} />
        } else if (props.id === '225') {
            icon = <Cloud color="primary" className={classes.avatar1} />
        } else if (props.id === '226') {
            icon = <CommuteSharp color="primary" className={classes.avatar1} />
        } else if (props.id === '227') {
            icon = <LocalCarWash color="primary" className={classes.avatar1} />
        } else if (props.id === '228') {
            icon = <MapSharp color="primary" className={classes.avatar1} />
        } else if (props.id === '230') {
            icon = <AccountBalance color="primary" className={classes.avatar1} />
        } else if (props.id === '231' || props.id === '245') {
            icon = <OfflineBolt color="primary" className={classes.avatar1} />
        } else if (props.id === '232') {
            icon = <FindReplace color="primary" className={classes.avatar1} />
        } else if (props.id === '299') {
            icon = <OpenInNew color="primary" className={classes.avatar1} />
        } else if (props.id === '235') {
            icon = <OfflinePin color="primary" className={classes.avatar1} />
        } else if (props.id === '236'  || props.id === "250") {
            icon = <FindReplace color="primary" className={classes.avatar1} />
        } else if (props.id === '237') {
            icon = <EventSeat color="primary" className={classes.avatar1} />
        } else if (props.id === '238') {
            icon = <Backspace color="primary" className={classes.avatar1} />
        } else if (props.id === '239') {
            icon = <QuestionAnswer color="primary" className={classes.avatar1} />
        } else if (props.id === '240') {
            icon = <Deck color="primary" className={classes.avatar1} />
        } else if (props.id === '241') {
            icon = <Star color="primary" className={classes.avatar1} />
        } else if (props.id === '243') {
            icon = <Cached color="primary" className={classes.avatar1} />
        } else if (props.id === '244') {
            icon = <Book color="primary" className={classes.avatar1} />
        } else if (props.id === '451') {
            icon = <Avatar alt="w" src="w.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '452') {
            icon = <Avatar alt="head_bottom" src="head_bottom.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '453') {
            icon = <Avatar alt="head_bottom" src="w3.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '454') {
            icon = <Avatar alt="head_bottom" src="w4.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '455') {
            icon = <Avatar alt="head_bottom" src="w5.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '456') {
            icon = <Avatar alt="head_bottom" src="w6.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '457') {
            icon = <Avatar alt="head_bottom" src="w7.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '458') {
            icon = <Avatar alt="head_bottom" src="w8.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '459') {
            icon = <Avatar alt="head_bottom" src="w9.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '460') {
            icon = <Avatar alt="head_bottom" src="w10.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '461') {
            icon = <Avatar alt="head_bottom" src="w11.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '462') {
            icon = <Avatar alt="head_bottom" src="w12.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '463') {
            icon = <Avatar alt="head_bottom" src="w13.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '464') {
            icon = <Avatar alt="head_bottom" src="w14.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '465') {
            icon = <Avatar alt="head_bottom" src="w15.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '466') {
            icon = <Avatar alt="head_bottom" src="w16.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '467') {
            icon = <Avatar alt="head_bottom" src="w17.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '468') {
            icon = <Avatar alt="head_bottom" src="w18.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '469') {
            icon = <Avatar alt="head_bottom" src="w19.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '470') {
            icon = <Avatar alt="head_bottom" src="p0.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '471') {
            icon = <Avatar alt="head_bottom" src="p1.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '472') {
            icon = <Avatar alt="head_bottom" src="p2.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '473') {
            icon = <Avatar alt="head_bottom" src="p3.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '474') {
            icon = <Avatar alt="head_bottom" src="p4.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '475') {
            icon = <Avatar alt="head_bottom" src="p5.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '476') {
            icon = <Avatar alt="head_bottom" src="p6.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '477') {
            icon = <Avatar alt="head_bottom" src="p7.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '478') {
            icon = <Avatar alt="head_bottom" src="w20.png" style={{ width: 48, height: 48 }} />
        } else if (props.id === '479') {
            icon = <Avatar alt="head_bottom" src="w21.png" style={{ width: 48, height: 48 }} />
        } else {
            icon = <TrendingUp color="primary" className={classes.avatar1} />
        }
        return icon
    }

    return (
        <Card className={classes.card} onClick={clickTitle} style={{ backgroundColor: isTurnOn() ? getEnableColor() : null }}>
            <CardHeader
                avatar={getIcon()}
                title={<div className={classes.title} >{props.t1}</div>}
                subheader={<div className={classes.title1}  >{props.desc}</div>}
            />
        </Card>
    );

}
