import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function BSmallBigSetting(props) {
    //   const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState(yy());
    const [age1, setAge1] = React.useState(gg());
    
    const handleChange = (event) => {
        setAge(event.target.value);
      };

      const handleChange1 = (event) => {
        setAge1(event.target.value);
      };

    // const yref = React.createRef();
    // const gref = React.createRef();

    const handleOk = () => {
        // if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('bsco', parseInt(age));

        // if (gref.current.value != null && gref.current.value != "")
            localStorage.setItem('bsmo', parseInt(age1));

        props.handleOk()
    };

    function yy() {
        return localStorage.getItem('bsco') === null ? 50 : localStorage.getItem('bsco')
    }

    function gg() {
        return localStorage.getItem('bsmo') === null ? 50 : localStorage.getItem('bsmo')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">大戶/散戶(日)設定</DialogTitle>

            <DialogContent>
                <div style={{ fontSize: 12, color: '#b3b3b3' }}>
                    散戶預設為當日每個分點券商/每個價位成交量50張股票以下或者成交金額在50萬以下。大戶則是大於散戶成交張數或成交金額的設定值。
                </div>
                <br />

                散戶成交量
                <Select
                fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    onChange={handleChange}
                >
                    <MenuItem value={10}>10張以下</MenuItem>
                    <MenuItem value={30}>30張以下</MenuItem>
                    <MenuItem value={50}>50張以下</MenuItem>
                    <MenuItem value={80}>80張以下</MenuItem>
                    <MenuItem value={100}>100張以下</MenuItem>
                    <MenuItem value={200}>200張以下</MenuItem>
                    <MenuItem value={300}>300張以下</MenuItem>
                    <MenuItem value={500}>500張以下</MenuItem>
                </Select>

                <br /><br />
                散戶成交金額
                <Select
                fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age1}
                    onChange={handleChange1}
                >
                    <MenuItem value={10}>10萬以下</MenuItem>
                    <MenuItem value={30}>30萬以下</MenuItem>
                    <MenuItem value={50}>50萬以下</MenuItem>
                    <MenuItem value={80}>80萬以下</MenuItem>
                    <MenuItem value={100}>100萬以下</MenuItem>
                    <MenuItem value={200}>200萬以下</MenuItem>
                    <MenuItem value={300}>300萬以下</MenuItem>
                    <MenuItem value={500}>500萬以下</MenuItem>
                </Select>
            </DialogContent>

            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}
