import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import RevList from './RevList'
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function createData(stock_no, name, m1r, m3r, m12r, s1r, m1inc, m3inc, m12inc, s1inc, m1incc, m3incc, m12incc, s1incc, rev, lastmp, lastyp, lastaccp, y1incc, y1large, y2large, y3large, lastym) {
    return {
        stock_no, name, m1r, m3r, m12r, s1r, m1inc, m3inc, m12inc, s1inc, m1incc, m3incc, m12incc, s1incc, rev, lastmp, lastyp, lastaccp, y1incc, y1large, y2large, y3large, lastym
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


function stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: 'name', numeric: false, disablePadding: false, label: '股票代號' },
        // { id: 'stock_no', numeric: false, disablePadding: false, label: '代號' },

        { id: 'm1r', numeric: false, disablePadding: false, label: '月排名' },
        { id: 's1r', numeric: false, disablePadding: false, label: '季排名' },
        { id: 'm12r', numeric: false, disablePadding: false, label: '年排名' },

        { id: 'm1inc', numeric: false, disablePadding: false, label: '月增率正' },
        { id: 's1inc', numeric: false, disablePadding: false, label: '季增率正' },
        { id: 'm12inc', numeric: false, disablePadding: false, label: '年增率正' },

        { id: 'm1incc', numeric: false, disablePadding: false, label: '月增率' },
        { id: 's1incc', numeric: false, disablePadding: false, label: '季增率' },
        { id: 'm12incc', numeric: false, disablePadding: false, label: '年增率' },
        { id: 'y1incc', numeric: false, disablePadding: false, label: '累績年增率' },

        { id: 'y1large', numeric: false, disablePadding: false, label: '近1年' },
        { id: 'y2large', numeric: false, disablePadding: false, label: '近2年' },
        { id: 'y3large', numeric: false, disablePadding: false, label: '近3年' },

        { id: 'lastym', numeric: false, disablePadding: false, label: '年月份' },
        { id: 'rev', numeric: false, disablePadding: false, label: '營收' },
        { id: 'lastmp', numeric: false, disablePadding: false, label: '單月月增率' },
        { id: 'lastyp', numeric: false, disablePadding: false, label: '單月年增率' },
        { id: 'lastaccp', numeric: false, disablePadding: false, label: '累計年增率' },

        { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
    ];

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb', whiteSpace: 'nowrap' }}>股票名稱</TableCell>
                {/* <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">基本資料</TableCell> */}
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一月/季/年的歷史排名（5年）</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">月/季/年增率為正的次數（5年）</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">月/季/年增率連續為正或負成長</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">創24月新高的次數</TableCell>

                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一個月</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'm1r' || headCell.id === 'm1inc' || headCell.id === 'm1incc' || headCell.id === 'lastym' || headCell.id === 'y1large' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :<TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 7,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function RevTable(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)  
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    const rows = props.data.map(row =>
        createData(row.market_company.stock_no,
            row.market_company.short_name,
            row.rev.m1r,
            row.rev.m3r,
            row.rev.m12r,
            row.rev.s1r,
            row.rev.m1inc,
            row.rev.m3inc,
            row.rev.m12inc,
            row.rev.s1inc,
            row.rev.m1incc,
            row.rev.m3incc,
            row.rev.m12incc,
            row.rev.s1incc,
            row.rev.rev,
            row.rev.lastmp,
            row.rev.lastyp,
            row.rev.lastaccp,
            row.rev.y1incc,
            row.rev.y1large,
            row.rev.y2large,
            row.rev.y3large,
            row.rev.lastym
        )
    )

    {/* gp, oi, txb, txa */ }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    const show5Rate = (value, total, stockno, stockName) => {
        if (value === -99999) {
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (value === 1) {
            return <Button size="small" onClick={handleClickOpencc(stockno, stockName)} style={{minWidth: 100,  background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                特優  第{value}名
      </Button>
        } else if (value === 2) {
            return <Button size="small" onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.upcolorbox }} className={classes.button1}>
                前段  第{value}名
    </Button>
        } else if (value === 3) {
            return <Button size="small" onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.faircolor }} className={classes.button1} >
                中間  第{value}名
      </Button>
        } else if (value === 4) {
            return <Button size="small" onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.downcolorbox }} className={classes.button1} >
                後段  第{value}名
    </Button>
        } else if (value === 5) {
            return <Button size="small" onClick={handleClickOpencc(stockno, stockName)} style={{minWidth: 100,  background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                倒數   第{value}名
    </Button>
        } else {
            return <Button size="small" onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    //60,20,5
    const showRate = (value, total, stockno, stockName) => {
        let k = (value * 100) / total

        if (value === -99999) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (k <= 10) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)}  style={{minWidth: 100,  background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                特優  第{value}名
      </Button>
        } else if (k > 10 && k <= 40) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                前段  第{value}名
    </Button>
        } else if (k > 40 && k <= 60) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.faircolor }} className={classes.button1} >
                中間  第{value}名
      </Button>
        } else if (k >= 60 && k < 90) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.downcolorbox }} className={classes.button1} >
                後段  第{value}名
    </Button>
        } else if (k >= 90) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                倒數   第{value}名
    </Button>
        } else {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }


    const showCount = (value, total, stockno, stockName) => {
        let k = (value * 100) / total

        if (value === -99999) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (k >= 90) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} style={{minWidth: 100,  background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                超多 {value}次
      </Button>
        } else if (k >= 60 && k < 90) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                偏多 {value}次
    </Button>
        } else if (k >= 40 && k < 60) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >
                穩定 {value}次
      </Button>
        } else if (k >= 10 && k < 40) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                偏低 {value}次
    </Button>
        } else if (k < 10) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} style={{minWidth: 100,  background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                超低 {value}次
    </Button>
        } else {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const showCount1 = (value, total, unit, stockno, stockName) => {
        // let k = (value * 100) / total

        if (value === -99999) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else if (value > 0) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                正成長 {value}{unit}
            </Button>
        } else if (value === 0) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{minWidth: 100,  color: BColor.faircolor }} className={classes.button1} >
                持平 {value}{unit}
            </Button>
        } else if (value < 0) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                負成長 {-value}{unit}
            </Button>
        } else {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const showCount2 = (value, total, unit, stockno, stockName) => {
        let k = (value * 100) / total

        if (value === -99999) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (k >= 10) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.upcolorbox }} className={classes.button1}>
                {value}{unit}
            </Button>
        } else if (k < 10) {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.faircolor }} className={classes.button1} >
                {value}{unit}
            </Button>
        } else {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const renderInPrice = (value, unit) => {
        if (value === -99999) return "-"
        else return (value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + unit
    }

    const renderYm = (value, stockno, stockName) => {
        if (value === "") {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else {
            return <Button size="small"  onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{minWidth: 100,  color: BColor.faircolor }} className={classes.button1} >
                {value}
            </Button>
        } 
    }

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <RevList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></RevList>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : { }) }}
                >
                    <Table
                                ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    // const isItemSelected = isSelected(row.stock_no);
                                    // const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={event => handleClick(event, row.stock_no)}
                                            role="checkbox"
                                            // aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.stock_no}
                                        // selected={isItemSelected}
                                        >
                                            {/* stock_no, name, inductry, sharecapital
                      
                      */}
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
                                                    color="inherit"
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => {
                                                        openDetail(row.stock_no)
                                                    }}
                                                >
                                                    {row.name.length > 8 ? `${row.name.substring(0, 8)}...` : row.name}
                                                </Link>
                                                <br />
                                                <Link
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.stock_no)}
                                                >
                                                    {row.stock_no}
                                                </Link>
                                            </TableCell>



                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 8, paddingRight: 0, borderLeft: '1px solid #dbdbdb', }} size="small" align="left">{showRate(row.m1r, 60, row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, }} size="small" align="left">{showRate(row.s1r, 20, row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">{show5Rate(row.m12r, 5, row.stock_no, row.name)}</TableCell>


                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb', }} size="small" align="left">{showCount(row.m1inc, 60, row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, }} size="small" align="left">{showCount(row.s1inc, 20, row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">{showCount(row.m12inc, 5, row.stock_no, row.name)}</TableCell>

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb', }} size="small" align="left">{showCount1(row.m1incc, 60, '月', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, }} size="small" align="left">{showCount1(row.s1incc, 20, '季', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">{showCount1(row.m12incc, 5, '年', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">{showCount1(row.y1incc, 60, '月', row.stock_no, row.name)}</TableCell>

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb', }} size="small" align="left">{showCount2(row.y1large, 12, '次', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, }} size="small" align="left">{showCount2(row.y2large, 24, '次', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, }} size="small" align="left">{showCount2(row.y3large, 36, '次', row.stock_no, row.name)}</TableCell>

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, borderLeft: '1px solid #dbdbdb', }} size="small" align="right">{renderYm(row.lastym, row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} size="small" align="right">{renderInPrice(row.rev, '百萬元', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, }} size="small" align="right">{renderInPrice(row.lastmp, '%', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} size="small" align="right">{renderInPrice(row.lastyp, '%', row.stock_no, row.name)}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} size="small" align="right">{renderInPrice(row.lastaccp, '%', row.stock_no, row.name)}</TableCell>

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(RevTable);
