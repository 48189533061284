import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button1: {
  },

}));

export default function CD1(props) {
  const classes = useStyles();

  const [state, setState] = React.useState([
    {
      startDate: moment(props.bigtitle.se, 'YYYYMMDD').toDate(),
      endDate: moment(props.bigtitle.ss, 'YYYYMMDD').toDate(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    setState([
        {
            startDate: moment(props.bigtitle.se, 'YYYYMMDD').toDate(),
            endDate: moment(props.bigtitle.ss, 'YYYYMMDD').toDate(),
            key: 'selection'
          }

        ])
  }, [props.bigtitle.se]);


  const handleClose1 = () => {
    props.handleClose()
};

  const handleCancel = () => {
    localStorage.removeItem("cal_sdd")
    localStorage.removeItem("cal_ldd")
    localStorage.removeItem("sd_param")
    props.handleOk()
  };

  const handleOk = () => {
    localStorage.setItem('cal_sdd', moment(state[0].startDate).format('YYYYMMDD'));
    localStorage.setItem('cal_ldd', moment(state[0].endDate).format('YYYYMMDD'));

    props.handleOk()
  };



  return (
    <div>
      <Dialog open={props.open} onClose={handleClose1} aria-labelledby="form-dialog-title">
        <DateRange
          locale={locales['zhTW']}
          editableDateInputs={true}
          onChange={item => setState([item.selection])}
          moveRangeOnFirstSelection={false}
          minDate={moment(props.bigtitle.me, 'YYYYMMDD').toDate()}
          maxDate={moment(props.bigtitle.ms, 'YYYYMMDD').toDate()}
          ranges={state}
        />
        <DialogActions>
          <Button onClick={handleCancel} style={{ color: "#5c5c5c", fontSize: 12 }}>
            最新交易日期（恢復預設）
          </Button>
          <Button onClick={handleOk} style={{ color: "#5c5c5c", fontSize: 12 }}>
          特定區間
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}
