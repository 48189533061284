import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import { useTheme } from '@material-ui/core/styles';

import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {     
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function StockCMF(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover2' : undefined;
    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'simple-popover3' : undefined;


    function getXX() {
        if (props.row.cmf != null && props.row.cmf.a != null &&
            props.row.kd3.d.length >= props.row.cmf.a.length) {
            let data = props.row.kd3.d.slice(-props.row.cmf.a.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1() {
        if (props.row.cmf != null && props.row.cmf.a != null &&
            props.row.kd3.d.length >= props.row.cmf.a.length) {

            let data = props.row.kd3.d.slice(-props.row.cmf.a.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }


    function getDefaultAChartOption(data) {
        let option = {
            grid: [
                {
                    top: '3%',
                    left: '40',
                    right: '40',
                    height: '50%',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '65%',
                    left: '40',
                    right: '40',
                    //bottom: '0%',
                    height: '20%',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    show: true,
                    gridIndex: 1,
                    scale: true,
                    type: 'value'
                }
            ],
            visualMap: {
                show: false,
                type: 'piecewise',
                seriesIndex: 1,
                min: -1,
                max: 1,
                pieces: [
                    { min: -1, max: 0, color: '#65b206' },
                    { min: 0, max: 1, color: "#d41c1c" },
                ]
            },
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.x }),
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }

            x2['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
        }

        option['xAxis'] = [x1, x2]

        if (data != null) {
            option['series'] = [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: getYY1(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: 'CMF',
                    type: 'line',
                    color: "#ff7171",
                    areaStyle: {
                        // opacity: 0.8,
                        // color: "#63dbff"
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.y }), //data.a != null? data.a : [],
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                }
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 80,
                    end: 100
                }
            ]
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 70,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '90%',
                    start: 70,
                    end: 100
                }
            ]
        }

        return option
    }


    useEffect(() => {
        if (props.row.cmf != null) setOptions(getDefaultAChartOption(props.row.cmf.a))
    }, [props.row.cmf]);

    function getString() {
        return "目前無相關的資金流向指標"
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            蔡金資金流量(CMF)
                        </Typography>
                    </div>
                        
                    <div style={{ fontSize: 13, color: "#808080" }}>
                        <Chip label="趨勢" variant="outlined" onClick={handleClick} />
                        <Chip label="交叉" style={{ marginLeft: 3 }} variant="outlined" onClick={handleClick2} />
                        <Chip label="背離" style={{ marginLeft: 3 }} variant="outlined" onClick={handleClick3} />
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ padding: 5, fontSize: 13, color: "#808080" }}>價格處於上漲趨勢時，CMF持續大於0，價格會持續上揚。價格處於下跌趨勢時，CMF持續小於0，價格會持續下跌。</div>
                    </Popover>
                    <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ padding: 5, fontSize: 13, color: "#808080" }}>CMF從負變成正，即將變為上漲趨勢。當CMF從正變成負，即將變為下跌趨勢。</div>
                    </Popover>

                    <Popover
                        id={id3}
                        open={open3}
                        anchorEl={anchorEl3}
                        onClose={handleClose3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ padding: 5, fontSize: 13, color: "#808080" }}>當價格往上，CMF卻往下，下跌警訊。當價格往下，CMF卻往上，上漲訊號。</div>
                    </Popover>

                    {props.row.cmf != undefined && options != null ? <ReactEChartsCore
                        style={{ height: 450, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}