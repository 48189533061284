import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import BrokerList from '../details/BrokerList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Broker from '../menu/Broker'
import Typography from '@material-ui/core/Typography';
import DataTable from 'react-data-table-component';
import WinerDetail from './WinerDetail';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor';

const useStyles = makeStyles((theme) => ({
}));


export default function SimpleStockList(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [reports, setReports] = React.useState([]);
    const [pending, setPending] = React.useState(false);
    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const openDetail = (stockno) => {
        if (stockno != null) {
            setStockNo(stockno)
            setOpendetail(true)
        }
    }

    const closeDetail = () => {
        setOpendetail(false)
    }

    useEffect(() => {
        getAll()
    }, [props.rid, props.value1, props.value2]);

    const getColor = (value) => {
        if(value > 0) {
            return BColor.upcolor
        } else if(value < 0) return BColor.downcolor
        else return BColor.faircolor
    }

    function renderIncp(row) {
        let oriValue = row.fin.price.last_value - row.fin.price.incv
        let percent = ((row.fin.price.incv * 100) / oriValue).toFixed(2)
        return percent
      }    

    const columns = [
        {
            name: '股票名稱',
            sortable: true,
            grow: 1,
            selector: row => row.market_company.short_name,
            cell: row => <Button color="primary" onClick={e => openDetail(row.market_company.stock_no)}>
                {row.market_company.short_name}
            </Button>,
        }, {
            name: '股票代號',
            sortable: true,
            selector: row => row.market_company.stock_no,
        }, {
            name: '股價(元)',
            sortable: true,
            selector: row => row.fin.price.last_value,
        }, {
            name: '漲跌(元)',
            sortable: true,
            selector: row => row.fin.price.incv,
            cell: row => <div style={{color:getColor(row.fin.price.incv)}}>{row.fin.price.incv}</div>,
        }, {
            name: '漲跌(%)',
            cell: row => <div style={{color:getColor(row.fin.price.incv)}}>{renderIncp(row)}</div>,
        }, {
            name: '成交量(張)',
            sortable: true,
            selector: row => row.shortmoney.tq,
        }, {
            name: '本益比',
            sortable: true,
            selector: row => row.daily_stock.per,
        }, {
            name: '產業',
            sortable: true,
            selector: row => row.market_company.inductry,
        }, {
            name: '股本(億)',
            sortable: true,
            cell: row => <div>{(parseFloat(row.market_company.normal_stock) / 100000000).toFixed(1)}</div>,
        }

        
    ];


    const getAll = () => {
        if (localStorage.getItem('lic') != null) {
            setPending(true)
            let a = {
                count: 150,
                page: 0,
                rules: [{
                    id: props.rid,
                    value1: props.value1,
                    value2: props.value2,
                    s1: props.dealer
                }],
                lic: localStorage.getItem('lic')
            }

            fetch("/jstock/v1/stock/rules", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        // if (result.length > 0)
                        setPending(false)
                        setReports(result.company)
                    }
                ).catch(err => { /* maybe 400 */ });
        }
    }




    const customStyles = {
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };

    const getAllIds = () => {
        if (reports != null) {
            return reports.map(row => {
                return row.market_company.stock_no
            })
        } else return null
    }

    return (
        <div style={{}}>
            {opendetail ? <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} /> : null}
            {reports != null ? <DataTable
                customStyles={customStyles}
                style={{}}
                columns={columns}
                data={reports}
                pagination
                theme={theme.palette.type}
                noHeader={true}
                noDataComponent={"無任何結果。"}
                paginationPerPage={50}
                progressPending={pending}
                progressComponent={<div>載入中...</div>}
            /> : null}

        </div>
    );
}
