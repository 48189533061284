import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';

import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'


function createData(stock_no, name, tp, last_value, eps, per, teps, inprice, minprice,
  inductry, sharecapital, incv, dy, pb, highprice, lowprice, gp, oi, txb, txa, cash, avgcash,
  intrate, leps, last_value1, ty, ly, newyd, meetdate, yieldrate, rate, incp, incp1, ls, seps, tq, tyeps, ymd, ts, v) {
  return {
    stock_no, name, tp, last_value, eps, per, teps, inprice, minprice,
    inductry, sharecapital, incv, dy, pb, highprice, lowprice, gp, oi, txb, txa,
    cash, avgcash, intrate, leps, last_value1, ty, ly, newyd, meetdate, yieldrate, rate, incp, incp1, ls, seps, tq, tyeps,
    ymd, ts, v
  };
}

function isnum(num) {
  if (num.match(/^-{0,1}\d+$/)) {
    return true;
    //valid integer (positive or negative)
  } else if (num.match(/^\d+\.\d+$/)) {
    return true;
    //valid float
  } else {
    return false;
    //not valid number
  }
}

function desc(a, b, orderBy) {
  if (orderBy === "newyd" || orderBy === "sharecapital") {
    if (isnum(b[orderBy]) && isnum(a[orderBy]) && parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
      return -1;
    }
    if (isnum(b[orderBy]) && isnum(a[orderBy]) && parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;

}


function stableSort(array, cmp) {

  const stabilizedThis = array.map((el, index) =>
    [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: '股票名稱' },


  { id: 'last_value', numeric: false, disablePadding: true, label: '收盤價' },
  { id: 'incv', numeric: false, disablePadding: true, label: '漲跌' },
  { id: 'incp1', numeric: false, disablePadding: true, label: '漲跌%' },
  { id: 'tq', numeric: false, disablePadding: true, label: '成交量' },
  { id: 'per', numeric: false, disablePadding: false, label: '本益比' },
  { id: 'pb', numeric: false, disablePadding: false, label: '股價淨值比' },
  { id: 'ymd', numeric: false, disablePadding: true, label: '最後更新' },

  { id: 'tp', numeric: false, disablePadding: false, label: '上市櫃' },
  { id: 'stock_no', numeric: false, disablePadding: false, label: '股票代號' },
  { id: 'inductry', numeric: false, disablePadding: false, label: '產業' },
  { id: 'sharecapital', numeric: false, disablePadding: false, label: '股本(億)' },

  { id: 'tyeps', numeric: false, disablePadding: false, label: '今年目前' },
  { id: 'teps', numeric: false, disablePadding: false, label: '近一年' },
  { id: 'seps', numeric: false, disablePadding: false, label: '近一季' },
  { id: 'eps', numeric: false, disablePadding: false, label: '近四季' },


  { id: 'gp', numeric: false, disablePadding: false, label: '毛利率(%)' },
  { id: 'oi', numeric: false, disablePadding: false, label: '營業收益率(%)' },
  { id: 'txb', numeric: false, disablePadding: false, label: '稅前純益率(%)' },
  { id: 'txa', numeric: false, disablePadding: false, label: '稅後純益率(%)' },

  { id: 'meetdate', numeric: false, disablePadding: false, label: '最近一次董事會' },
  { id: 'yieldrate', numeric: false, disablePadding: false, label: '現金殖利率' },
  { id: 'rate', numeric: false, disablePadding: false, label: '盈餘分配率' },

  { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: color1 }}>
      <TableRow style={{}}>
        <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票代號</TableCell>
        <TableCell className={classes.stickya} colSpan={7} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">今日資訊</TableCell>
        <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">基本資料</TableCell>
        {/* <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">內含價值</TableCell> */}
        <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">每股盈餘</TableCell>
        <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一季獲利指標</TableCell>
        <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最新股利公告</TableCell>
        {/* <TableCell colSpan={7} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">6年平均</TableCell> */}
        <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
      </TableRow>
      <TableRow style={{}}>
        {headCells.map(headCell => (
          <TableCell
            // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
            key={headCell.id}
            className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
            style={{ ...((headCell.id === 'tp' || headCell.id === 'meetdate' || headCell.id === 'gp' || headCell.id === 'love' || headCell.id === 'last_value' || headCell.id === 'last_value1' || headCell.id === 'tyeps' || headCell.id === 'inpriceStatus') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
            size="small"
            align={(headCell.id === 'name' || headCell.id === 'inductry' ? 'left' : 'center')}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id != 'love' ? <TableSortLabel
              style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel> : <div style={{ whiteSpace: 'nowrap' }}>{headCell.label}</div>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
  table: {
    minWidth: 750,

  },
  tableWrapper: {

    // overflowY: 'auto',
    overflowX: 'auto'
  },


  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  vvvv: {
    fontSize: 10,
    padding: 5,
    color: BColor.faircolor,
    overflow: 'visible'
  },
  button1: {
  },
  stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
  stickya: {
    background: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    height: 35,
    zIndex: 10,
    top: 0,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    padding: 0,
    borderBottom: '1px solid #dbdbdb'
  },
  stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 10,
    top: 37,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    padding: 0
  }, //This is header
  stickya1: {
    backgroundColor: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    height: 35,
    zIndex: 20,
    top: 0,
    left: 0,
    paddingTop: 7,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    padding: 0,
    borderBottom: '0px solid #dbdbdb'
  },
  stickyhh: {
    backgroundColor: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    top: 37,
    left: 0,
    zIndex: 20,
    paddingTop: 0,
    paddingBottom: 7,
    paddingLeft: 8,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    padding: 0,
    borderTop: '0px',
    borderRight: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb'
  }
}));

const getTp = tp => {
  if (tp === "s") { return "上市"; }
  else if (tp === "o") { return "上櫃"; }
  else { return "" };
}

const getInp = new_price => {
  if (new_price != null) { return new_price.result; }
  else { return -99 };
}

const getNewYD = row => {
  if (row.ii != null && row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null)
    return (row.ii.mi.cash * 100 / row.fin.price.last_value).toFixed(2)
  else return row.fin.price.dy
}

const getInprice = new_price => {
  if (new_price != null) { return new_price.minprice; }
  else { return -99 };
}

const getHighPrice = new_price => {
  if (new_price != null) { return new_price.high; }
  else { return -99 };
}

const getLowprice = new_price => {
  if (new_price != null) { return new_price.low; }
  else { return -99 };
}

const getgp = rate => {
  if (rate != null) return rate.gp;
  else return '-'
}

const getoi = rate => {
  if (rate != null) return rate.oi;
  else return '-'
}

const gettxb = rate => {
  if (rate != null) return rate.txb;
  else return '-'
}

const gettxa = rate => {
  if (rate != null) return rate.txa;
  else return '-'
}

const getcash = inc => {
  if (inc != null) return inc.cash;
  else return '-'
}

const getavgcash = inc => {
  if (inc != null) return inc.avg_cash;
  else return '-'
}


const getintrate = inc => {
  if (inc != null) return inc.rate;
  else return '-'
}



function FinTable(props) {
  const classes = useStyles();
  const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState(loveItems);
  const [page, setPage] = React.useState(props.currentpage);
  const [dense, setDense] = React.useState(props.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

  const [getStock, setSavestock] = React.useState(null);

  const [opendetail, setOpendetail] = React.useState(false)
  const [stockno, setStockNo] = React.useState(null);

  const [lovemenu, setLovemenu] = React.useState(false)
  const myContainer = React.useRef(null);

  const handleLoveClick = stockNo => event => {
    setSavestock(stockNo);
    setLovemenu(true);
  };

  const handleLoveClose = () => {
    setLovemenu(false);
  };


  function getRowPageData(screen) {
    // if (screen === 'xs')
    //   return [20]
    // else return [10, 20, 40, 80, 150];
    return [10, 20, 40, 80, 150];
  }

  const rows = props.data.map(row =>
    createData(row.market_company.stock_no,
      row.market_company.short_name,
      getTp(row.market_company.tp),
      row.fin.price.last_value,
      row.daily_stock.eps,
      row.daily_stock.per,
      row.daily_stock.teps,
      getInp(row.new_price),
      getInprice(row.new_price),
      row.market_company.inductry,
      row.market_company.normal_stock,
      row.fin.price.incv,
      row.fin.price.dy,
      row.fin.price.pb,
      getHighPrice(row.new_price),
      getLowprice(row.new_price),
      getgp(row.fin.rate),
      getoi(row.fin.rate),
      gettxb(row.fin.rate),
      gettxa(row.fin.rate),
      getcash(row.fin.inc),
      getavgcash(row.fin.inc),
      getintrate(row.fin.inc),
      row.daily_stock.leps,
      row.fin.price.last_value,
      row.daily_stock.ty,
      row.daily_stock.ly,
      getNewYD(row),
      rendermeetingdate(row),
      exactYield(row),
      exactRate(row),
      renderIncp(row),
      renderIncp1(row),
      row.daily_stock.ls,
      row.daily_stock.seps,
      row.shortmoney.tq,
      getTyEPS(row.eps),
      row.daily_stock.d,
      row.daily_stock.t,
      row.daily_stock.v
    )
  )

  function getTyEPS(eps) {
    if (eps != null && eps != undefined && eps.tyeps != null && eps.tyeps != undefined) {
      return eps.tyeps
    } else return "-";
  }

  function renderIncp(row) {
    let oriValue = row.fin.price.last_value - row.fin.price.incv
    let percent = ((row.fin.price.incv * 100) / oriValue).toFixed(2)
    return percent
  }

  function renderIncp1(row) {
    let oriValue = row.fin.price.last_value - row.fin.price.incv
    let percent = (row.fin.price.incv * 100) / oriValue

    return percent
  }

  function rendermeetingdate(row) {
    if (row.ii != undefined && row.ii.mi != null)
      return row.ii.mi.ymd
    else return ""
  }

  function exactYield(row) {
    if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
      let yieldrate = (row.ii.mi.cash * 100 / row.fin.price.last_value)
      return yieldrate
    } else return -99999.0
  }

  function exactRate(row) {
    if (row.ii != undefined && row.ii.mi != null) {
      let rate = row.ii.mi.rate
      let ratetp = row.ii.mi.ratetp

      if (ratetp === 0) {
        return rate;
      } else if (ratetp === 1) {
        return -99998.0
      } else {
        return -99999.0
      }
    }
  }

  {/* gp, oi, txb, txa */ }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.stock_no);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.pageupdate(newPage, props.currentcount)
  };

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10));
    props.pageupdate(0, parseInt(event.target.value, 10))

    // setPage(0);
  };


  const rendereps = (year, eps) => {
    if (year === -99999) {
      return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >-</Button>
    } else
      return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >{year}年 {eps}元</Button>
  }

  const rendereps1 = (eps) => {
    if (eps != 0)
      return <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >{eps}元</Button>
    else return <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >-</Button>
  }

  const rendereps2 = (year, eps) => {
    if (year === "") {
      return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >-</Button>
    } else
      return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor, whiteSpace: "nowrap" }} className={classes.button1} >{year} {eps}元</Button>
  }

  const showBalance2 = (value) => {
    if (value === -99999) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        -
      </Button>
    } else {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        {value}張
      </Button>
    }
  }

  const pbcolor = dy => {
    if (dy <= 0.7) return BColor.upcolor
    else return BColor.faircolor
  }



  const dycolor = (value, base) => {
    if (value > base) return BColor.upcolor
    else return BColor.faircolor
  }

  const pricecolor = incv => {
    if (incv > 0) return BColor.upcolor
    else if (incv < 0) return BColor.downcolor
    else return BColor.faircolor
  }

  const zerotosla = value => {
    if (value === 0) return "0"
    else return value
  }

  const zerotosla1 = value => {
    if (value === "-") return "0"
    else return value
  }

  const isSelected = stock_no => selected.indexOf(stock_no) !== -1;

  const getItem = (iv) => {
    let ii = iv + "_stockname"
    return localStorage.getItem(ii) != null ? localStorage.getItem(ii) : '自選股清單' + iv
  }

  const openDetail = (stockno) => {
    if (props.detailUpdate != undefined) props.detailUpdate(true)
    setStockNo(stockno)
    setOpendetail(true)
  }

  const closeDetail = () => {
    if (props.detailUpdate != undefined) props.detailUpdate(false)
    if (props.closeupdate != undefined) props.closeupdate()
    setOpendetail(false)
  }

  const showrate = (rate) => {
    if (rate === -99999.0) {
      return "-"
    } else {
      return rate.toFixed(2) + "%"
    }
  }

  const showrate1 = (rate) => {
    if (rate === -99999.0) {
      return "-"
    } else if (rate === -99998.0) {
      return "當期無盈餘"
    } else {
      return rate + "%"
    }
  }

  const getAllIds = () => {
    return props.data.map(row => {
      return row.market_company.stock_no
    })
  }



  return (
    <div className={classes.root}>
      <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
      <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}
          style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
        >
          <Table
            ref={myContainer}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >

            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.stock_no);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.stock_no)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.stock_no}
                    // selected={isItemSelected}
                    >
                      {/* stock_no, name, inductry, sharecapital
                      
                      */}
                      <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            openDetail(row.stock_no)
                          }}
                          color="inherit"
                        >
                          {row.name.length > 8 ? `${row.name.substring(0, 8)}...` : row.name}
                        </Link>
                        <br />
                        <Link
                          style={{ color: BColor.upcolor }}
                          component="button"
                          variant="caption"
                          onClick={() => openDetail(row.stock_no)}
                        >
                          {row.stock_no}
                        </Link>
                      </TableCell>


                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb', color: pricecolor(row.incv) }} size="small" align="center">{row.last_value}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, color: pricecolor(row.incv) }} size="small" align="center">{row.incv}元</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, color: pricecolor(row.incv) }} size="small" align="center">{row.incp}%</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} size="small" align="center">{showBalance2(row.v)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="center">{row.per}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, color: pbcolor(row.pb) }} align="center">{row.pb}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">{row.ts === "" ? '-' : row.ts}</TableCell>


                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} align="left">{row.tp}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="center">{row.stock_no}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">{row.inductry}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="center">{row.sharecapital != "" ? (row.sharecapital / 100000000).toFixed(2) : '-'}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 0 }} align="left">{rendereps1(row.tyeps)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">{rendereps(row.ty, row.teps)}</TableCell>
                      {/* <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="center">{rendereps(row.ly, row.leps)}</TableCell> */}
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">{rendereps2(row.ls, row.seps)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="left">{rendereps1(row.eps)}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 0 }} align="center">{zerotosla(row.gp)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="center">{row.oi}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="center">{row.txb}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="center">{row.txa}</TableCell>


                      {row.meetdate != "" ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 0 }} align="left">
                        <Button variant="outlined" size="small" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >{row.meetdate}</Button> </TableCell> : null}
                      {row.meetdate != "" ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">
                        <Button variant="outlined" size="small" style={{ minWidth: 100, color: dycolor(row.yieldrate, 7), marginBottom: 0 }} className={classes.button1} >{showrate(row.yieldrate)}</Button></TableCell> : null}
                      {row.meetdate != "" ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="left">
                        <Button variant="outlined" size="small" style={{ minWidth: 100, color: dycolor(row.rate, 80), marginBottom: 0 }} className={classes.button1} >{showrate1(row.rate)}</Button></TableCell> : null}
                      {row.meetdate === "" ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} colSpan="3" align="left">
                        <Button variant="outlined" size="small" style={{ width: '100%', color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >近期無公告</Button>
                      </TableCell> : null}


                      {/* <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 30 }} align="right">{zerotosla1(row.cash)}元</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} align="right">{zerotosla1(row.avgcash)}元</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} align="right">{zerotosla1(row.intrate)}%</TableCell> */}

                      <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                        <Button
                          size="small"
                          onClick={handleLoveClick(row.stock_no)}
                          variant="outlined"
                          style={{ width: '100%', color: BColor.faircolor }}
                          className={classes.button}
                          startIcon={<FavoriteBorder />}
                        >
                          管理
                        </Button>
                      </TableCell>
                    </TableRow>

                  );
                })}
            </TableBody>
          </Table>
        </div>
        {props.disablepage ?
          <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
            匯出
          </Button></div>
          : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Divider orientation="vertical" flexItem />
            <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
              匯出
            </Button>
            <Divider orientation="vertical" flexItem />
            <TablePagination
              rowsPerPageOptions={getRowPageData(props.width)}
              component="div"
              count={props.total}
              rowsPerPage={props.currentcount}
              page={props.currentpage}
              labelRowsPerPage={'每頁'}
              labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /></div>
        }
      </Paper>
    </div>

  );
}

export default withWidth()(FinTable);
