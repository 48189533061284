import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(ymd, buysell, buyprice, allbuy, sellprice, allsell) {
    return { ymd, buysell, buyprice, allbuy, sellprice, allsell };
}


const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    button1: {
      minWidth: 110,
    }
  }));

export default function BrokerList1(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    useEffect(() => {
        if (props.open) fectchStockData(props.stockId, props.dealerId)
    }, [props.stockId, props.dealerId]);


    function fectchStockData(stockId, dealerId) {
        if (stockId != null) {
            let a = {
                sid: stockId,
                did: dealerId
            };

            fetch("/jstock/v1/stock/dealer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let dd = result.a.map(row =>
                            createData(moment(row.ymd, "YYYYMMDD").format('YYYY/MM/DD'), row.buysell, row.buyprice, row.allbuy, row.sellprice, row.allsell)
                        )
                        setBuyselldata(dd)
                    },
                    error => {
                    }
                );
        }
    }

    function renderStock(value) {
        let colorx = BColor.nodatacolor
        let word = "中立"
        let vv = ""

        if (value < 0) {
            colorx = BColor.downcolorbox
            word = "賣超 "
            if(value > -1000) 
            vv = value + "股"
            else
            vv = -((value / 1000).toFixed())  + "張"
            
        } else if (value === 0) {
            colorx = BColor.nodatacolor
            word = "中立 "
            vv = "0張"
        } else if (value > 0) {
            colorx = BColor.upcolorbox
            word = "買超 "
            if(value < 1000) 
            vv = value + "股"
            else
            vv = ((value / 1000).toFixed())　+ "張"
        } else {
            colorx = BColor.nodatacolor
            word = "中立 "
            vv = ((value / 1000).toFixed())　+ "張"
        }

        return <StyledTableCell style={{}} size="small" align="left">
            <Button variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{vv}</Button>
        </StyledTableCell>
    }


    function renderStock1(value) {
        let colorx = BColor.nodatacolor
        let word = ""
        let vv = ""

        if(value < 1000) 
        vv = value + "股"
        else
        vv = ((value / 1000).toFixed())　+ "張"

        return <StyledTableCell style={{}} size="small" align="left">
            <Button variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{vv}</Button>
        </StyledTableCell>
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.stockname}【{props.dealername}券商】</DialogTitle>

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="center">買賣超</StyledTableCell>
                                <StyledTableCell align="center">買入張數</StyledTableCell>
                                <StyledTableCell align="center">賣出張數</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.ymd}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.ymd}
                                    </StyledTableCell>
                                    {renderStock(row.buysell)}
                                    {renderStock1(row.allbuy)}
                                    {renderStock1(row.allsell)}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
