import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { getSynNo } from '../util/Syno'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});



export default function MangeLoveList(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [checked, setChecked] = React.useState([]);
    const [namelist, setNamelist] = React.useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };


    useEffect(() => {
        if (props.open) getloveinmenu();
    }, [props.open]);

    const getloveinmenu = () => {
        let lic = localStorage.getItem('lic')
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let param = {
            lic: lic,
            menu: props.menu,
            synno: synno
        }

        fetch("/jstock/v1/stock/getloveinmenu", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setNamelist(result)
                }
            );

    }

    const delStock = () => {
        let lic = localStorage.getItem('lic')
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let param = {
            lic: lic,
            menu: props.menu,
            synno: synno,
            sids: checked
        }

        fetch("/jstock/v1/stock/removelove2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.text())
            .then(
                result => {
                    props.handleClose()
                }
            );

    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen} onClose={props.handleClose} open={props.open}>
            <DialogTitle id="simple-dialog-title">股票清單</DialogTitle>
            <List>
                {namelist.map((data) => (
                    <ListItem dense button key={data.id} onClick={handleToggle(data.id)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={checked.indexOf(data.id) !== -1}
                                tabIndex={-1}
                            />
                        </ListItemIcon>
                        <ListItemText primary={data.name + " （" + data.id + "）"} />
                    </ListItem>
                ))}
            </List>
            <DialogActions>
                <Button variant="outlined" onClick={props.handleClose} color="secondary">
                    關閉
                </Button>
                <Button variant="outlined" onClick={e => delStock()} color="secondary">
                    從自選股移除
                </Button>
            </DialogActions>
        </Dialog>
    );
}
