import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import BColor from '../BColor'
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function TurnDetail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [options1, setOptions1] = React.useState(null)
    const [options2, setOptions2] = React.useState(null)

    function genData1() {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '轉折波與趨勢線',
                    top: '10',
                },
            ],
            legend: {
                top: 45,
                data: ["5日均", '轉折波', '趨勢線']
            },
            grid: [
                {
                    top: '80',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
            ]
        };


        let x1 = {
            type: 'category',
            data: props.row.chart11.d.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = x1

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: props.row.chart11.d.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            {
                name: "5日均",
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.avg.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'none',
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                lineStyle: {
                    width: 1
                }
            },
            {
                name: '轉折波',
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.t.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'circle',
                // symbolSize: 4,
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                lineStyle: {
                    width: 3
                }
            },
            {
                name: '趨勢線',
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.trend.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'circle',
                // symbolSize: 4,
                lineStyle: {
                    width: 3
                }
            },
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }

        return option
    }


    function genData2() {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '轉折波',
                    top: '10',
                }
            ],
            legend: {
                top: 45,
                data: ["5日均", '轉折波']
            },
            grid: [
                {
                    top: '80',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                }
            ]
        };


        let x1 = {
            type: 'category',
            data: props.row.chart11.d.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = x1
        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: props.row.chart11.d.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            {
                name: "5日均",
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.avg.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'none',
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                lineStyle: {
                    width: 1
                }
            },
            {
                name: '轉折波',
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.t.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'circle',
                // symbolSize: 4,
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                lineStyle: {
                    width: 3
                }
            }
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }

        return option
    }


    function genData3() {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '轉折波與趨勢線',
                    top: '10',
                },
            ],
            legend: {
                top: 45,
                data: ["5日均", '趨勢線']
            },
            grid: [
                {
                    top: '80',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
            ]
        };


        let x1 = {
            type: 'category',
            data: props.row.chart11.d.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = x1

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: props.row.chart11.d.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            {
                name: "5日均",
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.avg.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'none',
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                lineStyle: {
                    width: 1
                }
            },
            {
                name: '趨勢線',
                type: 'line',
                connectNulls: true,
                emphasis: {
                    focus: 'series'
                },
                data: props.row.turn.trend.map(vv => {
                    return [vv.x, vv.y]
                }),
                symbol: 'circle',
                // symbolSize: 4,
                lineStyle: {
                    width: 3
                }
            },
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }

        return option
    }


    useEffect(() => {
        if (props.row.turn != null) setOptions(genData1())
        if (props.row.turn != null) setOptions1(genData2())
        if (props.row.turn != null) setOptions2(genData3())
        // if (props.row.turn != null) setOption1(genData1(props.row.chart10.w))
        // if (props.row.turn != null ) setOption2(genData1(props.row.chart10.m))
    }, [props.row]);

    function getString() {
        return "目前無相關的轉折波與趨勢線"
    }

    function getTop10(dd, index) {
        let ddl = dd.length
        if (dd.length > 10) ddl = 10

        const rd = [...dd]
        return rd.reverse().slice(index, ddl)
    }

    return (
        <div>
            {localStorage.getItem('noad') != null && localStorage.getItem('noad') == "1" ?<Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            轉折波+趨勢線
                        </Typography>
                    </div>

                    {options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 430, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={8} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            轉折波
                        </Typography>
                    </div>

                    {options1 != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 430, marginTop: 0 }}
                        echarts={echarts}
                        option={options1}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : null}
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            轉折波明細
                        </Typography>
                    </div>

                    {props.row.turn.t != undefined && props.row.turn.t != null & props.row.turn.t.length > 0 ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>日期</StyledTableCell>
                                    <StyledTableCell align="left">轉折價位</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getTop10(props.row.turn.t, 1).map(vv => {
                                    return <StyledTableRow key={'t' + vv.x}>
                                        <StyledTableCell component="th" scope="row">
                                            {vv.x}
                                        </StyledTableCell>

                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                {vv.y}元
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> : <div>無任何明細</div>}
                </Grid>


                <Grid item xs={12} sm={8} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            趨勢線
                        </Typography>
                    </div>

                    {options2 != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 430, marginTop: 0 }}
                        echarts={echarts}
                        option={options2}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : null}
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            趨勢線明細
                        </Typography>
                    </div>

                    {props.row.turn != undefined && props.row.turn.trend != undefined && props.row.turn.trend.length > 0 ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>日期</StyledTableCell>
                                    <StyledTableCell align="left">趨勢價位</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getTop10(props.row.turn.trend, 0).map(vv => {
                                    return <StyledTableRow key={'s' + vv.x}>
                                        <StyledTableCell component="th" scope="row">
                                            {vv.x}
                                        </StyledTableCell>

                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                {vv.y}元
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow >

                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> : <div>無任何明細</div>}
                </Grid>

            </Grid> : <div>目前轉折波與趨勢線僅開放給訂閱者。</div>}
        </div >
    );
}