import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PERSetting(props) {
    const yref = React.createRef();

    const handleOk = () => {
      if(yref.current.value != null && yref.current.value != "")
      localStorage.setItem('ccy', parseInt(yref.current.value));

      props.handleOk()
    };

  function yy() {
    return localStorage.getItem('ccy') === null ?  "3" : localStorage.getItem('ccy')
  }
  
  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">盈餘法</DialogTitle>

        <DialogContent>
        <div style={{ fontSize: 12, color: '#b3b3b3' }}>
            藉由自身歷史本益比推算出價格。將近幾年所有的本益比排序後，從前高位數、中位數、低位數得到過去給予的高中低本益比，在與個別股票的預估的EPS相乘得到價格。<br /><br />
          </div>

          <div style={{ fontSize: 12, color: '#82bd98' }} >
            <div>公式</div>
            <div>1. 超低價 = 歷史本益比低位數(5%) * 預估EPS </div>
            <div>2. 偏低價 = 歷史本益比低位數(25%) * 預估EPS </div>
            <div>3. 合理價 = 歷史本益比中位數(25%) * 預估EPS</div>
            <div>4. 偏貴價 = 歷史本益比高位數(75%) * 預估EPS </div>
            <div>5. 超貴價 = 歷史本益比高位數(95%) * 預估EPS</div>
          </div>

          <br />

          <br />
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label="使用近幾年本益比估算（y預設：3年，最大6年）"
            type="text"
            fullWidth
            defaultValue={yy()}
            inputRef={yref}
            InputLabelProps={{
              shrink: true,
            }}
          />


        </DialogContent>
        {/* <DialogContentText  style={{paddingLeft: 20, paddingRight:20}}>


          </DialogContentText> */}
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>
  );
}
