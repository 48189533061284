import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DataTable, { createTheme } from 'react-data-table-component';
import Typography from '@material-ui/core/Typography';
import StockName from '../StockName'
import ActionMenu from './ActionMenu'
import EditStrage from './EditStrage';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';

createTheme('dark', {
    text: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
    },
    background: {
        default: 'transparent',
    },

});

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 700,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root: {
        '.rdt_TableHeader': {
            paddingLeft: 0
        }
    },
}));



const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div style={{ display: 'flex', marginLeft: 5, fontSize: 12, marginBottom: 5 }}>
        <TextField variant='outlined' style={{ width: 160 }} id="search" type="text" size="small" placeholder="策略名稱" value={filterText} onChange={onFilter} />
        <Button size="small" style={{ width: 50 }} type="button" variant="outlined" onClick={onClear}>X</Button>
    </div>
);


export default function RuleTable1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [open1, setOpen1] = React.useState(false);
    const [rrow, setRRow] = React.useState(null);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [toggleCleared, setToggleCleared] = React.useState(false);

    const filteredItems = props.data.filter(item => item.name != null && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [openEdit, setOpenEdit] = React.useState(false);

    const handleCloseEdit = () => {
        setOpenEdit(false)
    };

    const handleEditOk = () => {
        props.getMyRules()
        setOpenEdit(false)
    };

    const handleOpenEdit = (row) => {
        setRRow(row)
        setOpenEdit(true)
    }

    const handleClose1 = () => {
        setOpen1(false)
    };

    const handleOk = (name) => {
        if (rrow != null) props.updateStockName(name, rrow.id)
        setOpen1(false)
    };

    const handleEdit = (row) => {
        setRRow(row)
        setOpen1(true)
    }

    const handleShare = (row) => {
        if (row != null) props.share(row, row.name)
    };

    const handleDelete = (row) => {
        if (row != null) props.runDelRuleData(row.id)
    };

    const customStyles = {
        header: {
            style: {
                fontSize: "36px",
                fontWeight: "bold",
                paddingLeft: 0
            },
        },
        rows: {
            style: {
            }
        },
        headCells: {
            style: {
                fontSize: 13,
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        cells: {
            style: {
                fontSize: 13,
                padding: 10,
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };

    const queryRule = (row) => {
        // setselectid(row.id)
        props.runRuleData(row.rules, row.id)
    }


    const columns1 = [
        {
            name: '選股',
            grow: 0,
            cell: row => <div style={{}} >
                <Button variant="outlined" size="small" color="secondary" onClick={(e) => queryRule(row)}>執行策略</Button>
            </div>,
        },
        {
            grow: 1,
            name: '策略名稱',
            selector: 'name',
            sortable: true,
            wrap: false,
            width: '150px',
            cell: row => <div style={{}}>{row.name}</div>,
        },
        {
            name: '規則',
            selector: 'desc',
            sortable: true,
            // minWidth: '300px',
            grow: 2.4,
            cell: row => <div style={{ whiteSpace: 'nowrap' }}>{row.desc.map((data, index) => { return (<li key={index}>{data}</li>) })}</div>,
        },
        {
            maxWidth: 50,
            minWidth: 50,
            grow: 0,
            name: '其他功能',
            cell: row => <ActionMenu row={row} onUpdateRow={handleEdit} onDeleteRow={handleDelete} onSharedRow={handleShare} handleOpenEdit={handleOpenEdit} />,
        },
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const conditionalRowStyles = [
        {
            when: row => props.runid != null && row.id === props.runid,
            style: {
                backgroundColor: theme.palette.type === "light" ? '#e3e3e3' : '#787878',

                // color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]


    const handleRowSelected = ({ selectedRows }) => {
        if (selectedRows.length > 0) {
            //增加
            let allRules = (selectedRows.map(row => { return row.rules }))
            props.runRuleData(allRules.flat(), selectedRows[0].id)
        } else if (selectedRows.length === 0) {
            props.clearRule()
        }
    };

    const contextActions = React.useMemo(() => {
        const handleDelete = () => {
            props.clearRule()
            setToggleCleared(!toggleCleared);
        };
        return (

            <Box display="flex">
                <Button disableElevation variant="outlined" onClick={handleDelete}>
                    取消選取
                </Button>
            </Box>
        );
    }, [toggleCleared]);

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';


        const keys = ['策略名稱', "規則"]

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            result += '"' + item.name + '"' + columnDelimiter + '"' + item.desc.join("\r\n") + '"'
            result += lineDelimiter;
        });

        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'strategy.csv';
        link.setAttribute('href', "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv))
        link.setAttribute('download', filename);
        link.click();
    }


    const Export = ({ onExport }) => <Button style={{ marginLeft: 2, marginBottom: 5 }} variant="outlined" onClick={e => onExport(e.target.value)}>匯出</Button>;
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(filteredItems)} />, []);

    return (
        <div>
            {open1 ? <StockName open={open1} handleClose={handleClose1} handleOk={handleOk}></StockName> : null}
            {openEdit ? <EditStrage srow={rrow} rules={rrow.rules} open={openEdit} handleClose={handleCloseEdit} handleOk={handleEditOk}></EditStrage> : null}

            {props.data != null ? <DataTable
                theme={theme.palette.type}
                // theme="solarized"
                subHeaderAlign='left'
                conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles} noDataComponent={"無任何選股策略。"} title={<div style={{
                    display: 'flex', marginTop: 20
                }}>
                    <Typography variant="h6" id="tableTitle">
                        選股策略
                    </Typography>
                    {subHeaderComponentMemo}
                    {actionsMemo}
                </div>} selectableRows
                contextActions={contextActions} onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleCleared}
                pagination paginationPerPage={15} columns={columns1} data={filteredItems} /> : null}
        </div>
    );
}
