import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BIASList from '../details/BIASList'
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Chip from '@material-ui/core/Chip';

echarts.use(
    [ScatterChart, AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function AA(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null)


    function getOption(row) {
        let newOption = {
            animation: false,
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '40',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        newOption['yAxis'] = [
            {
                scale: true,
                splitArea: {
                    show: false
                },
                show: true,
                position: 'left',
                splitLine: {
                    show: true
                },
            }
        ]

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: row.a.map(v => { return v.x }),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        newOption['xAxis'] = [x1]

        newOption['series'] = [
            {
                name: '日K',
                type: 'candlestick',
                data: row.a.map(v => { return v.y }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markLine: {
                    symbol: "none",
                    label: {
                        show: true
                    },
                    data: [
                        {
                            name: '低',
                            yAxis: row.aa.lprice,
                            lineStyle: {
                                // color: "#a1a1a1",
                                // type: 'solid',
                                width: 2,
                            }, label: {
                                formatter: '最低',
                            }
                        },
                        {
                            name: '高',
                            yAxis: row.aa.hprice,
                            lineStyle: {
                                // color: "#a1a1a1",
                                // type: 'solid',
                                width: 2,
                            }, label: {
                                formatter: '最高',
                            }
                        }
                    ]
                },
            }
        ]

        return newOption
    }

    const getPHV1 = () => {
        setLoading(true)

        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
            sid: props.row.market_company.stock_no
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/aa", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setData(result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);

    function getString() {
        return "目前無實施庫藏股"
    }

    const getReason = (value) => {
        if (value === 1) {
            return "轉讓股份予員工"
        } else if (value === 2) {
            return "股權轉換"
        } else if (value === 3) {
            return "維護公司信用及股東權益"
        } else return "未知"
    }
    return (
        <div>
            <Grid item xs={12} sm={12} >
                <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        庫藏股/股價走勢圖
                    </Typography>
                </div>

                {data != null && data.map(row => {
                    if (row.a != null && row.a.length > 0) {
                        return <div style={{ marginBottom: 30 }}>
                            <div style={{ display: 'flex' }}>
                            {row.aa.isdone ?<Chip variant="outlined" label="已結束" disabled />:<Chip variant="outlined" color="primary" label="正執行" />}
                                <Chip variant="outlined" style={{ marginLeft: 2 }} label={row.aa.st + " - " + row.aa.et} />
                                <Chip variant="outlined" style={{ marginLeft: 2 }} label={row.aa.lprice + "元 - " + row.aa.hprice + "元"} />
                            </div>
                            <ReactEChartsCore
                                theme={theme.palette.type}
                                style={{ height: 300, marginTop: 0 }}
                                echarts={echarts}
                                option={getOption(row)}
                                notMerge={true}
                                lazyUpdate={false}
                                showLoading={loading}
                            /></div>
                    } else return null
                })}

                {data != null && data.filter (row => row.a != null && row.a.length > 0).length === 0 && <div>近期無任何庫藏股/股價走勢圖</div>}
                <div style={{ display: 'flex', marginTop: 20, marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        庫藏股明細
                    </Typography>
                </div>

                {data != null && data.length > 0 && <TableContainer style={{  }} component={Paper}>
                    <Table className={classes.table} style={{ overflowX: 'scroll', width: '100%' }} >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>狀態</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>預定起始日</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}>預定結束日</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">價格(最低)</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">價格(最高)</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} align="right">預定買回股數</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} >原因</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow
                                    key={row.aa.st}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{row.aa.isdone ?
                                        <Button disableElevation variant="contained" disabled>
                                            結束
                                        </Button> : <Button disableElevation variant="contained" color="primary" >
                                            執行中
                                        </Button>}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                        {row.aa.st}
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} >{row.aa.et}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} align="right">{row.aa.lprice}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} align="right">{row.aa.hprice}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} align="right">{(row.aa.amount / 1000).toFixed(0)}張</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{getReason(row.aa.purpose)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                {data != null && data.length === 0 && getString()}
            </Grid>

        </div >
    );
}

