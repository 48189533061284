import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BackDetail from './BackDetail'
import BColor from '../BColor'
import SellStockList from './SellStockList'
import RestStockList from './RestStockList'

import DataTable from 'react-data-table-component';
import Typography from '@material-ui/core/Typography';
import ROIDetail from './ROIDetail'

const useStyles = makeStyles({
    table: {
        // minWidth: 700,
    },
});


export default function BackTable1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState(null);

    const [id, setId] = React.useState(null);

    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);


    const columns1 = [
        {
            name: '選股策略',
            selector: 'rname',
            sortable: true,
            // cell: row => <Button size="small" style={{ marginTop: 5, marginBottom: 5 }} variant="outlined" size="small" onClick={(e) => showRuleDetail(row)}>
            //      {row.rname}
            //  </Button>,
            cell: row => <div style={{ color: "#517fc9", whiteSpace: 'nowrap' }} onClick={(e) => showRuleDetail(row)}>
                {row.rname}
            </div>,
        },
        {
            name: '執行狀態',
            selector: 'status',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{getStatus(row.status, row.per)}</div>,
        },
        {
            name: '回測日期',
            selector: 'start',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.start}<br />{row.end}</div>,
        },
        {
            name: '交易數',
            selector: row => row.result != null ? row.result.tran_count : null,
            sortable: true,
            cell: row =>
                <Button variant="outlined" size="small" onClick={(e) => showStockDetail3(row.id)} style={{ fontSize: 12 }} >{row.result != null ? row.result.tran_count : '-'}</Button>
            // <div style={{ whiteSpace: 'nowrap' }} >{row.result != null ? row.result.tran_count : '-'}</div>,
        },
        {
            name: '報酬率',
            selector: row => row.result != null ? row.result.roi : null,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: row.result != null ? getColor2(row.result.roi) : null }}>
                {row.result != null && row.result.roi != -99999 ? row.result.roi + "%" : '-'}
            </div>,
        },
        {
            name: '報酬率(未實現)',
            selector: row => row.result != null ? row.result.frate : null,
            sortable: true,
            wrap: false,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: row.result != null ? getColor2(row.result.frate) : null }}>{row.result != null && row.result.frate != null && row.result.frate != -99999 ? row.result.frate + "%" : '-'}</div>,
        },
        {
            name: '勝率',
            selector: row => row.result != null ? row.result.win_rate : null,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.result != null && row.result.win_rate != -99999 ? row.result.win_rate + "%" : '-'}</div>,
        },
        {
            name: '盈虧比',
            selector: row => row.result != null ? row.result.wl : null,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: row.result != null ? getColor3(row.result.wl) : null }} >{row.result != null && row.result.wl != null ? row.result.wl : '-'}</div>,
        },
        {
            name: '期望值',
            selector: row => row.result != null ? row.result.expv : null,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: row.result != null ? getColor2(row.result.expv) : null }} >{row.result != null && row.result.expv != null ? row.result.expv : '-'}</div>,
        }, {
            name: '已賣出',
            button: true,
            cell: row => <Button size="small" disableElevation variant="contained" onClick={(e) => showStockDetail1(row.id)}>已賣出</Button>,
        }, {
            name: '未賣出',
            button: true,
            cell: row => <Button size="small" disableElevation variant="contained" onClick={(e) => showStockDetail2(row.id)}>未賣出</Button>,
        }, {
            name: '操作',
            button: true,
            // cell: row => <div>{row.status === 1 ? null : <Button size="small" disableElevation variant="contained" onClick={(e) => props.delbacktest(row.id)}>刪除</Button>}</div>,
            cell: row => <div>{<Button size="small" disableElevation variant="contained" onClick={(e) => props.delbacktest(row.id)}>刪除</Button>}</div>,
        }
    ];



    function getStatus(status, per) {
        if (status === 0) {
            return <Button size="small" style={{  }}>排隊中</Button>
        } else if (status === 1) {
            return <Button size="small" style={{ backgroundColor: BColor.bluecolor, color: BColor.whitecolor }}>執行中({per != null ? per + "%" : "0%"})</Button>
        } else if (status === 2) {
            return <Button size="small" style={{ backgroundColor: BColor.downcolorbox, color: BColor.whitecolor }}>成功</Button>
        } else if (status === -1) {
            return <Button size="small" style={{ backgroundColor: BColor.upcolorbox, color: BColor.whitecolor }}>失敗</Button>
        } else {
            return <Button size="small" style={{  }}>-</Button>
        }
    }

    function showRuleDetail(row1) {
        setRow(row1)
        props.stopRefresh()
        setOpen(true)
    }

    function closeDialog() {
        props.restartRefresh()
        setOpen(false)
    }

    function showStockDetail1(id) {
        props.stopRefresh()
        setOpen1(true)
        setId(id)
    }

    function closeDialog1() {
        props.restartRefresh()
        setOpen1(false)
        setId(null)
    }

    function showStockDetail2(id) {
        props.stopRefresh()
        setOpen2(true)
        setId(id)
    }

    function showStockDetail3(id) {
        props.stopRefresh()
        setOpen3(true)
        setId(id)
    }

    function closeDialog2() {
        props.restartRefresh()
        setOpen2(false)
        setId(null)
    }

    function closeDialog3() {
        props.restartRefresh()
        setOpen3(false)
        setId(null)
    }


    function getColor2(value) {
        if (value != null && value != -99999) {
            if (value > 0) return BColor.upcolor
            else if (value === 0) return BColor.faircolor
            else if (value < 0) return BColor.downcolor
            else return BColor.faircolor
        } return BColor.faircolor
    }

    function getColor3(value) {
        if (value != null && value != -99999) {
            if (value > 1) return BColor.upcolor
            else if (value === 1) return BColor.faircolor
            else if (value < 1) return BColor.downcolor
            else return BColor.faircolor
        } return BColor.faircolor
    }

    const conditionalRowStyles = [
    ];

    const customStyles = {
        header: {
            style: {
                fontSize: "36px",
                fontWeight: "bold",
                paddingLeft: 0
            },
        },
        rows: {
            style: {
                // minHeight: '72px', // override the row height
            }
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
                // whiteSpace: 'nowrap'
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };


    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        

        const keys = ['選股策略', "規則", "回測起始日", "回測結束日", "交易數", "報酬率", "報酬率（未實現）", "勝率", "盈虧比", "期望值", "停利賣出", "停損賣出", "勝場數", "敗場數", "平均持有天數", "未實現平均持有天數", "移動停利", "出場策略1", "出場規則1", "出場策略2", "出場規則2" ]

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            if(item.result != null) {
            result +=  '"' + item.rname + '"' 
                + columnDelimiter + '"' +  item.inr  + '"'
                + columnDelimiter + '"' +  item.start  + '"' 
                + columnDelimiter + '"' +  item.end  + '"'
                + columnDelimiter + '"' +  item.result.tran_count  + '"'
                + columnDelimiter + '"' +  item.result.roi  + '"'
                + columnDelimiter + '"' +  item.result.frate  + '"'
                + columnDelimiter + '"' +  item.result.win_rate  + '"'
                + columnDelimiter + '"' +  item.result.wl  + '"'
                + columnDelimiter + '"' +  item.result.expv  + '"'
                + columnDelimiter + '"' +  item.outwin  + '"'
                + columnDelimiter + '"' +  item.outlost  + '"'
                + columnDelimiter + '"' +  item.result.wc  + '"'
                + columnDelimiter + '"' +  item.result.lc  + '"'
                + columnDelimiter + '"' +  item.result.avghold  + '"'
                + columnDelimiter + '"' +  item.result.avghold1  + '"'
                + columnDelimiter + '"' +  item.mvlost  + '"'
                + columnDelimiter + '"' +  item.outrname  + '"'
                + columnDelimiter + '"' +  item.outrulestr  + '"'            
                + columnDelimiter + '"' +  item.outrname1  + '"'
                + columnDelimiter + '"' +  item.outrulestr1  + '"'               
                result += lineDelimiter;
            }
        });

        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'strategy.csv';
        link.setAttribute('href', "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv))
        link.setAttribute('download', filename);
        link.click();
    }


    const Export = ({ onExport }) => <Button size="small" style={{marginLeft: 5}} variant="outlined" onClick={e => onExport(e.target.value)}>匯出</Button>;
    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(props.data)} />, []);


    return (
        <div>
            {open ? <BackDetail open={open} row={row} handleClose={closeDialog} ismobile={props.ismobile}></BackDetail> : null}
            {open1 ? <SellStockList open={open1} id={id} handleClose={closeDialog1} ismobile={props.ismobile}></SellStockList> : null}
            {open2 ? <RestStockList open={open2} id={id} handleClose={closeDialog2} ismobile={props.ismobile} ></RestStockList> : null}
            {open3 ? <ROIDetail open={open3} id={id} handleClose={closeDialog3} ismobile={props.ismobile} ></ROIDetail> : null}

            {props.data != null ? <DataTable
                theme={theme.palette.type}
                style={{}}
                customStyles={customStyles} noDataComponent={"無任何回測結果。"} title={<div style={{ display: 'flex', marginTop: 20 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        回測結果
                    </Typography>
                    <Export onExport={() => downloadCSV(props.data)} />
                </div>} pagination paginationPerPage={10} columns={columns1} data={props.data} /> : null}
        </div>
    );
}
