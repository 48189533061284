import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CashFlowSetting(props) {
  //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const gref = React.createRef();
    const rref = React.createRef();
    const hrref = React.createRef();
    const mrref = React.createRef();
    const lrref = React.createRef();

    const handleOk = () => {
 
      if(yref.current.value != null && yref.current.value != "")
      localStorage.setItem('free_y', parseInt(yref.current.value));

      if(gref.current.value != null && gref.current.value != "")
      localStorage.setItem('free_g', parseFloat(gref.current.value));

      if(rref.current.value != null && rref.current.value != "")
      localStorage.setItem('free_r', parseFloat(rref.current.value));

      if(hrref.current.value != null && hrref.current.value != "")
      localStorage.setItem('free_hr', parseFloat(hrref.current.value));

      if(mrref.current.value != null && mrref.current.value != "")
      localStorage.setItem('free_mr', parseFloat(mrref.current.value));

      if(lrref.current.value != null && lrref.current.value != "")
      localStorage.setItem('free_lr', parseFloat(lrref.current.value));

      let y1 = localStorage.getItem('free_y1') === null ?  "5" : localStorage.getItem('free_y1')
      let hr1 = localStorage.getItem('free_hr1') === null ?  6.6 : localStorage.getItem('free_hr1')
      let mr1 = localStorage.getItem('free_mr1') === null ?  5 : localStorage.getItem('free_mr1') 
      let lr1 = localStorage.getItem('free_lr1') === null ?  3.3 : localStorage.getItem('free_lr1')

      props.handleOk(yref.current.value, 
        gref.current.value, 
        rref.current.value, 
        hrref.current.value, 
        mrref.current.value, 
        lrref.current.value,
        y1, hr1, mr1, lr1)
    };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };
 


  function yy() {
    return localStorage.getItem('free_y') === null ?  "5" : localStorage.getItem('free_y')
  }

  function gg() {
    return localStorage.getItem('free_g') === null ?  0 : localStorage.getItem('free_g')
  }
  
  function rr() {
    return localStorage.getItem('free_r') === null ?  0 : localStorage.getItem('free_r')
  }  

  function hR() {
    return localStorage.getItem('free_hr') === null ?  20 : localStorage.getItem('free_hr')
  }  

  function mR() {
    return localStorage.getItem('free_mr') === null ?  10 : localStorage.getItem('free_mr')
  }  

  function lR() {
    return localStorage.getItem('free_lr') === null ?  6 : localStorage.getItem('free_lr')
  }  

  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">現金流折現法</DialogTitle>

        <DialogContent>
          <div style={{ fontSize: 12, color: '#b3b3b3' }}>
            利用公司替股東賺多少錢來推算內含價格，主要觀念是：<br />
            1. 公司的價值是它存活期間所賺到金錢的加總．<br />
            2. 這些未來賺的錢值多少呢？用現值理論可以算出未來的錢在此刻價值多少．<br /><br />
          </div>

          <div style={{ fontSize: 12, color: '#82bd98' }} >
            <div>公式與參數</div>
            <div>1. 自由現金流 = 營業活動現金流量 - 必要資本支出 (取得/處分不動產、廠房及設備 + 取得/處分無形資產) - 利息費用 - 退還及支付之所得稅</div>
            <div>2. 計算1到y年的現值總和 = 近四季自由現金流 x (1 + g) x (1 - (1 + g) / (1 + R)y次方) / (R-g))</div>
            <div>3. 計算y年後永續成長的現值總和 = (第y年的自由現金流 * (1 + r) / R - r) / (1 + R)y次方</div>
            <div>4. 公司總價值 = 1到y年的現值總和 + y年後永續成長的現值總和</div>
            <div>5. 股價 = 公司總價值 / 在外流通的股數</div>
          </div>

          <br />
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label="預估公司成長幾年（y預設：5年）"
            type="text"
            fullWidth
            defaultValue={yy()}
            inputRef={yref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="g"
            label="自由現金流成長率（g預設：0%）"
            type="text"
            fullWidth
            defaultValue={gg()}
            inputRef={gref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="r"
            label="永續成長率（r預設：0%）"
            type="text"
            fullWidth
            defaultValue={rr()}
            inputRef={rref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="hr"
            label="高折現率（R預設：20%）"
            type="text"
            fullWidth
            defaultValue={hR()}
            inputRef={hrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="mr"
            label="中折現率（R預設：10%）"
            type="text"
            fullWidth
            defaultValue={mR()}
            inputRef={mrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="lr"
            label="低折現率（R預設：6%）"
            type="text"
            fullWidth
            defaultValue={lR()}
            inputRef={lrref}
            InputLabelProps={{
              shrink: true,
            }}
          />

        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>
  );
}
