import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AsyncSelect from 'react-select/async';
import StockDetail from './StockDetail';
import StockAutocomplete from './StockAutocomplete'
import Avatar from '@material-ui/core/Avatar';


export default function AppMenu3(props) {
    const [symbol, setSymbol] = React.useState(null);
    const [opendetail, setOpendetail] = React.useState(false)

    const menuChange = (data) => {
        setSymbol(data)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    return (
        <>
            {symbol != null ? <StockDetail ismobile={props.ismobile} open={opendetail}  handleClose={closeDetail} stockno={symbol} /> : null}
            <StockAutocomplete menuChange={menuChange} />
        </>
    );
}
