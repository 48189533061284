import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function KTwoChart1(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [options, setOptions] = React.useState(null)
    const [options1, setOptions1] = React.useState(null)

    function genData1(top, k, avg5, avg20, avg60, avg120, avg240, smark, emark) {
        let option = {
            legend: {
                data: ['5日均線', '20日均線', '60日均線', '120日均線', '240日均線']
            },
            grid: [
                {
                    top: top,
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                }
            ]
        };


        let x1 = {
            type: 'category',
            data: k.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = [x1]

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: k.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markArea: {
                    data: [[
                        {
                            name: '取樣區域',
                            xAxis: smark
                        },
                        {
                            xAxis: emark
                        }
                    ]
                    ]
                }
            },
            {
                name: "5日均線",
                type: 'line',
                data: avg5.map(vv => { return [vv.x, vv.y] }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "20日均線",
                type: 'line',
                data: avg20.map(vv => { return [vv.x, vv.y] }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "60日均線",
                type: 'line',
                data: avg60.map(vv => { return [vv.x, vv.y] }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "120日均線",
                type: 'line',
                data: avg120.map(vv => { return [vv.x, vv.y] }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "240日均線",
                type: 'line',
                data: avg240.map(vv => { return [vv.x, vv.y] }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }

        return option
    }

    useEffect(() => {
        if (props.open) getKTwoList()
    }, [props.oid, props.stock2, props.et2, props.open]);


    function getKTwoList() {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let a = {
                lic: lic,
                stock1: props.stock1,
                stock2: props.stock2,
                et2: props.et2,
                days: props.days
            }

            if (props.et1 == null || props.et1 == undefined) {
                a['et1'] = props.rundate
            } else {
                a['et1'] = props.et1
            }

            fetch("/jstock/v1/stock/ktest/twok", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setOptions(genData1('50', result.d1, result.avg15d, result.avg120d, result.avg160d, result.avg1120d, result.avg1240d, result.s1, result.e1))
                        setOptions1(genData1('50', result.d2, result.avg25d, result.avg220d, result.avg260d, result.avg2120d, result.avg2240d, result.s2, result.e2))
                    }
                );
        }
    }


    function getString() {
        return "無法找到對應的K線圖。"
    }

    function closea() {
        setOptions(null)
        props.handleClose()
    }

    return (
        <div>
            {props.open ? <Dialog fullScreen open={props.open} onClose={props.handleClose} >
                <DialogTitle id="form-dialog-title">相似型態比較</DialogTitle>

                {/* <DialogContent style={{ paddingRight: 0 }}> */}
                {localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1" ?
                    <div>
                        <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 25 }}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                {props.name1}
                            </Typography>
                        </div>
                        {options != null ? <ReactEChartsCore
                            theme={theme.palette.type}
                            style={{ height: 350, marginTop: 0 }}
                            echarts={echarts}
                            option={options}
                            notMerge={true}
                            lazyUpdate={false}
                        /> : null}


                        <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 25, marginTop: 70 }}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                {props.stock2} {props.name2}
                            </Typography>
                        </div>

                        {options1 != null ? <ReactEChartsCore
                            theme={theme.palette.type}
                            style={{ height: 350, marginTop: 0 }}
                            echarts={echarts}
                            option={options1}
                            notMerge={true}
                            lazyUpdate={false}
                        /> : null}

                    </div> : <div>目前僅開放給訂閱者。</div>}
                <DialogActions>
                    <Button onClick={e => closea()} color="primary">
                        關閉
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </div>


    );
}