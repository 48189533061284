import React, { useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BColor from '../BColor'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
import BBSetting from '../details/BBSetting'
import { putInpQueryParam } from '../util/Syno'

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)


export default function Scant(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(getDefaultAChartOption())
    const [loading, setLoading] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleOk = () => {
        setOpen1(false);
        props.bigupdate()
    };

    function renderpb(value) {
        if (value >= 100) {
            return <Button size="small" style={{ background: BColor.upcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1}>上軌上方 {value}%</Button>
        } else if (value >= 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>貼近上軌 {value}%</Button>
        } else if (value > 55 && value < 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>上軌-中軌 {value}%</Button>
        } else if (value <= 55 && value >= 45) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >貼近中軌 {value}%</Button>
        } else if (value < 45 && value > 20) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >中軌-下軌 {value}%</Button>
        } else if (value < 20 && value > 0) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >貼近下軌 {value}%</Button>
        } else if (value <= 0) {
            return <Button size="small" style={{ background: BColor.downcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1} >下軌下方 {value}%</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }



    function renderpw(value) {
        if (value >= 95) {
            return <Button size="small" style={{ background: BColor.upcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1}>超級寬廣 {value}%</Button>
        } else if (value >= 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>中度寬廣 {value}%</Button>
        } else if (value < 80 && value > 20) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >正常寬度 {value}%</Button>
        } else if (value < 20 && value > 5) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >中度狹窄 {value}%</Button>
        } else if (value <= 5) {
            return <Button size="small" style={{ background: BColor.downcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1} >超級狹窄 {value}%</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    // val b = if(a1 == 0 && a2 == 0) 0 //持續上軌線
    // else if(a1 == 0 && a2 != 0) 1 //突破上
    // else if(a1 == 3 && a2 != 3) 2 //突破下
    // else if(a1 == 1 && a2 == 2) 3 // 向上突破中線
    // else if(a1 == 1 && a2 == 1) 4 // 中線與上軌線波動
    // else if(a1 == 2 && a2 == 2) 5 // 中線與下軌線波動
    // else if(a1 == 1 && a2 == 0) 6 // 向下跌破上軌線
    // else if(a1 == 2 && a2 == 1) 7 // 向下跌破中線
    // else if(a1 == 3 && a2 == 2) 8 // 向下跌破下軌線
    // else -1

    function renderNowp(value) {
        if (value === 0) {
            return <Button size="small" style={{ background: BColor.upcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1}>持續上軌線</Button>
        } else if (value === 1) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>突破上軌線</Button>
        } else if (value === 2) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >突破下軌線</Button>
        } else if (value === 3) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>向上突破中線</Button>
        } else if (value === 4) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >中線與上軌線波動</Button>
        } else if (value === 5) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >中線與下軌線波動</Button>
        } else if (value === 6) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >向下跌破上軌線</Button>
        } else if (value === 7) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >向下跌破中線</Button>
        } else if (value === 8) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >向下跌破下軌線</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    // val b = if(b1 - b2 > 10) 0 //通道開口開大
    // else if(b2 - b1 > 10) 1 //通道開口縮小
    // else if(b1 < 5 && b2 < 5) 2 //通道狹窄
    // else if(b1 > 70 && b2 > 70) 3//通道寬廣
    // else 4

    function renderNowb(value) {
        if (value === 0) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>開口開大</Button>
        } else if (value === 1) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >開口縮小</Button>
        } else if (value === 2) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1}>通道狹窄</Button>
        } else if (value === 3) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1} >通道寬廣</Button>
        } else if (value === 4) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >正常寬度</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    function getDefaultAChartOption() {
        let newOption = {
            legend: {
                show: true,
                data: ['%b', '帶寬']
                // bottom: 0,
            },
            title: [
                {
                    top: '30',
                    left: 'center',
                    text: '布林通道'
                }, {
                    top: '530',
                    left: 'center',
                    text: '肯特納通道'
                }
            ],
            grid: [
                {
                    top: '40',
                    left: '40',
                    right: '35',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }, {
                    top: '330',
                    left: '40',
                    right: '35',
                    height: '100',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }, {
                    top: '550',
                    left: '40',
                    right: '35',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        if (props.ismobile) {
            newOption['tooltip'] = getMobileTooltip()
            newOption['axisPointer'] = getAxisPointer()
            newOption['dataZoom'] = getMobileDataZoom(70, 100)
        } else {
            newOption['tooltip'] = getComputerTooltip()
            newOption['axisPointer'] = getAxisPointer()
            newOption['dataZoom'] = getComputerDataZoom(0, 100, 850)
        }

        return newOption
    }

    const getPHV1 = () => {
        setLoading(true)

        let a = {
            sid: props.row.market_company.stock_no,
            inp: {}
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        a = putInpQueryParam(a)
        fetch("/jstock/v1/stock/scant", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        let newOption = getDefaultAChartOption()
                        newOption['yAxis'] = [
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: false
                                }
                            }, {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: false
                                },
                                gridIndex: 1,
                            }, {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'right',
                                splitLine: {
                                    show: false
                                },
                                gridIndex: 1,
                            }, {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: false
                                },
                                gridIndex: 2,
                            }
                        ]

                        let x1 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 20,
                            min: 'dataMin',
                            max: 'dataMax',
                        }

                        let x2 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 1,
                        }

                        let x21 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 1,
                            show: false
                        }

                        let x3 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 20,
                            min: 'dataMin',
                            max: 'dataMax',
                            gridIndex: 2,
                        }

                        if (props.ismobile) {
                            let xpoint = {
                                type: 'shadow',
                                label: { show: false },
                                triggerTooltip: true,
                                handle: {
                                    show: true,
                                    margin: 25,
                                    color: '#1868d9',
                                    size: 30
                                }
                            }

                            x1['axisPointer'] = xpoint
                            x2['axisPointer'] = xpoint
                            x21['axisPointer'] = xpoint
                            x3['axisPointer'] = xpoint
                        }


                        newOption['xAxis'] = [x1, x2, x21, x3]
                        newOption['series'] = [
                            {
                                name: '日K',
                                type: 'candlestick',
                                data: result.map(v => v.y),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                                z: 100
                            },
                            {
                                name: '布林中',
                                type: 'line',
                                data: result.map(v => v.mid),
                                hoverAnimation: false,
                                symbolSize: 6,
                                showSymbol: false,
                                endLabel: {
                                    show: true,
                                    fontSize: 10,
                                }
                            },
                            {
                                name: '布林上',
                                type: 'line',
                                data: result.map(v => v.high),
                                areaStyle: {
                                    // color: '#ffe7ab',
                                    opacity: 0.2
                                },
                                symbol: 'none',
                                endLabel: {
                                    show: true,
                                    fontSize: 10,
                                }
                            },
                            {
                                name: '布林下',
                                type: 'line',
                                data: result.map(v => v.low),
                                areaStyle: {
                                    // color: '#ffffff',
                                    opacity: 0.5
                                },
                                symbol: 'none',
                                endLabel: {
                                    show: true,
                                    fontSize: 10,
                                }
                            }, {
                                name: '%b',
                                type: 'line',
                                data: result.map(v => v.bp),
                                symbol: 'none',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                            }, {
                                name: '帶寬',
                                type: 'line',
                                data: result.map(v => v.bw),
                                symbol: 'none',
                                xAxisIndex: 2,
                                yAxisIndex: 2,
                            }, {
                                name: '日K',
                                type: 'candlestick',
                                data: result.map(v => v.y),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                                xAxisIndex: 3,
                                yAxisIndex: 3,
                                z: 100
                            },
                            {
                                name: '肯特納中',
                                type: 'line',
                                data: result.map(v => v.m),
                                hoverAnimation: false,
                                symbolSize: 6,
                                showSymbol: false,
                                xAxisIndex: 3,
                                yAxisIndex: 3,
                                labelLayout: {
                                    moveOverlap: 'shiftY'
                                },
                                endLabel: {
                                    show: true,
                                    fontSize: 10,
                                }
                            },
                            {
                                name: '肯特納上',
                                type: 'line',
                                data: result.map(v => v.up),
                                areaStyle: {
                                    opacity: 0.2
                                },
                                symbol: 'none',
                                xAxisIndex: 3,
                                yAxisIndex: 3,
                                labelLayout: {
                                    moveOverlap: 'shiftY'
                                },
                                endLabel: {
                                    show: true,
                                    fontSize: 10,
                                }
                            },
                            {
                                name: '肯特納下',
                                type: 'line',
                                data: result.map(v => v.ld),
                                areaStyle: {
                                    // color: '#ffffff',
                                    opacity: 0.5
                                },
                                symbol: 'none',
                                xAxisIndex: 3,
                                yAxisIndex: 3,
                                labelLayout: {
                                    moveOverlap: 'shiftY'
                                },
                                endLabel: {
                                    show: true,
                                    fontSize: 10,
                                }
                            }
                        ]

                        setLoading(false)
                        setOptions(newOption)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);



    return (
        <div>
            {open1 ? <BBSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></BBSetting> : null}
            <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen1()}>調整參數</Button>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 950, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            布林通道表
                        </Typography>

                    </div>

                    {props.row.bbr != null && <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'上軌'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'上軌'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {((props.row.bbr.last.high).toFixed(2))}元
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'中軌'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'中軌'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {((props.row.bbr.last.mid).toFixed(2))}元
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'下軌'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'下軌'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {((props.row.bbr.last.low).toFixed(2))}元
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'標準差'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'標準差'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.bbr.last.std).toFixed(2)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'%b值'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'%b值'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderpb(props.row.bbr.last.bp)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'帶寬指標'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'帶寬指標'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderpw(props.row.bbr.last.bw)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股價狀態'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價狀態'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderNowp(props.row.bbr.pps)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'通道狀態'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'通道狀態'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderNowb(props.row.bbr.ppb)}
                                    </StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>

            </Grid>
        </div >
    );
}