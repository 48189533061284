import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import PERSetting from './PERSetting';
import PBRSetting from './PBRSetting';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    if (b.hl != undefined && a.hl != undefined && b.hl[orderBy] < a.hl[orderBy]) {
        return -1;
    }
    if (b.hl != undefined && a.hl != undefined && b.hl[orderBy] > a.hl[orderBy]) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'p', numeric: false, disablePadding: false, label: '股價', sid: 'p' },
    { id: 'lp', numeric: false, disablePadding: false, label: '最低價', sid: 'lp' },
    { id: 'hp', numeric: false, disablePadding: false, label: '最高價', sid: 'hp' },
    { id: 'pe', numeric: false, disablePadding: false, label: '本益比', sid: 'pe' },
    { id: 'le', numeric: false, disablePadding: false, label: '最低本益比', sid: 'le' },
    { id: 'he', numeric: false, disablePadding: false, label: '最高本益比', sid: 'he' },

    { id: 'p1', numeric: false, disablePadding: false, label: '股價', sid: 'p' },
    { id: 'lp1', numeric: false, disablePadding: false, label: '最低價', sid: 'lp1' },
    { id: 'hp1', numeric: false, disablePadding: false, label: '最高價', sid: 'hp1' },
    { id: 'pe1', numeric: false, disablePadding: false, label: '本益比', sid: 'pe' },
    { id: 'le1', numeric: false, disablePadding: false, label: '最低本益比', sid: 'le1' },
    { id: 'he1', numeric: false, disablePadding: false, label: '最高本益比', sid: 'he1' },

    { id: 'p2', numeric: false, disablePadding: false, label: '股價', sid: 'p' },
    { id: 'lp2', numeric: false, disablePadding: false, label: '最低價', sid: 'lp2' },
    { id: 'hp2', numeric: false, disablePadding: false, label: '最高價', sid: 'hp2' },
    { id: 'pe2', numeric: false, disablePadding: false, label: '本益比', sid: 'pe' },
    { id: 'le2', numeric: false, disablePadding: false, label: '最低本益比', sid: 'le2' },
    { id: 'he2', numeric: false, disablePadding: false, label: '最高本益比', sid: 'he2' },

    { id: 'p3', numeric: false, disablePadding: false, label: '股價', sid: 'p' },
    { id: 'lp3', numeric: false, disablePadding: false, label: '最低價', sid: 'lp3' },
    { id: 'hp3', numeric: false, disablePadding: false, label: '最高價', sid: 'hp3' },
    { id: 'pe3', numeric: false, disablePadding: false, label: '本益比', sid: 'pe' },
    { id: 'le3', numeric: false, disablePadding: false, label: '最低本益比', sid: 'le3' },
    { id: 'he3', numeric: false, disablePadding: false, label: '最高本益比', sid: 'he3' },

    { id: 'p5', numeric: false, disablePadding: false, label: '股價', sid: 'p' },
    { id: 'lp5', numeric: false, disablePadding: false, label: '最低價', sid: 'lp5' },
    { id: 'hp5', numeric: false, disablePadding: false, label: '最高價', sid: 'hp5' },
    { id: 'pe5', numeric: false, disablePadding: false, label: '本益比', sid: 'pe' },
    { id: 'le5', numeric: false, disablePadding: false, label: '最低本益比', sid: 'le5' },
    { id: 'he5', numeric: false, disablePadding: false, label: '最高本益比', sid: 'he5' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleOk = () => {
        setOpen(false);
        setOpen1(false);
        props.freecashupdate()
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <PERSetting open={open} handleClose={handleClose} handleOk={handleOk}></PERSetting>
            <PBRSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></PBRSetting>

            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近6個月</TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近1年</TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近2年</TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近3年</TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近5年</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'p' || headCell.id === 'p1' || headCell.id === 'p2' || headCell.id === 'p3' || headCell.id === 'p5' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.sid)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function HighLowDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);
    const [openstock, setOpenstock] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };
    

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    function render1(value, unit) {
        let color = BColor.faircolor

        let k = value + unit
        if (value <= 0) k = '-'

        return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} size="small" align="left">
            <Button size="small" variant="outlined" style={{ minWidth: 110, color: color, marginRight: 1 }} className={classes.button2} >
                {k}
            </Button>
        </TableCell>
    }


    function render2(v, index, unit) {
        let color = BColor.faircolor
        let value = ''

        if (index === 0) {
            color = BColor.upcolorbox
            value = '高檔'
        } else if (index === 1) {
            color = BColor.downcolorbox
            value = '低檔'
        } else if (index === 2) {
            value = '中間'
        } else {
            value = '-'
            if (v === 0) v = ''
        }

        return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
            <Button size="small" variant="outlined" style={{ minWidth: 110, color: color, marginRight: 1, marginLeft: 1 }} className={classes.button2} >
                {value} {v}{unit}
            </Button>
        </TableCell>
    }



    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {row.hl != undefined ? render2(row.hl.p, row.hl.pi, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.lp, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.hp, '元') : null}
                                            {row.hl != undefined ? render2(row.hl.pe, row.hl.ei, '') : null}
                                            {row.hl != undefined ? render1(row.hl.le, '') : null}
                                            {row.hl != undefined ? render1(row.hl.he, '') : null}
                                            {row.hl == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} colSpan="6" size="small"　>
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}


                                            {row.hl != undefined ? render2(row.hl.p, row.hl.pi1, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.lp1, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.hp1, '元') : null}
                                            {row.hl != undefined ? render2(row.hl.pe, row.hl.ei1, '') : null}
                                            {row.hl != undefined ? render1(row.hl.le1, '') : null}
                                            {row.hl != undefined ? render1(row.hl.he1, '') : null}
                                            {row.hl == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} colSpan="6" size="small"　>
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}


                                            {row.hl != undefined ? render2(row.hl.p, row.hl.pi2, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.lp2, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.hp2, '元') : null}
                                            {row.hl != undefined ? render2(row.hl.pe, row.hl.ei2, '') : null}
                                            {row.hl != undefined ? render1(row.hl.le2, '') : null}
                                            {row.hl != undefined ? render1(row.hl.he2, '') : null}
                                            {row.hl == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} colSpan="6" size="small"　>
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}


                                            {row.hl != undefined ? render2(row.hl.p, row.hl.pi3, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.lp3, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.hp3, '元') : null}
                                            {row.hl != undefined ? render2(row.hl.pe, row.hl.ei3, '') : null}
                                            {row.hl != undefined ? render1(row.hl.le3, '') : null}
                                            {row.hl != undefined ? render1(row.hl.he3, '') : null}
                                            {row.hl == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} colSpan="6" size="small"　>
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}

                                            {row.hl != undefined ? render2(row.hl.p, row.hl.pi5, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.lp5, '元') : null}
                                            {row.hl != undefined ? render1(row.hl.hp5, '元') : null}
                                            {row.hl != undefined ? render2(row.hl.pe, row.hl.ei5, '') : null}
                                            {row.hl != undefined ? render1(row.hl.le5, '') : null}
                                            {row.hl != undefined ? render1(row.hl.he5, '') : null}

                                            {row.hl == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} colSpan="6" size="small"　>
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(HighLowDetail);
