import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SimpleStockList from './SimpleStockList'
import IconBreadcrumbs from './IconBreadcrumbs'
import StockName from '../StockName';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MoveMyLove from '../mylove/MoveMyLove'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MangeLoveList from '../love/MangeLoveList'
import { getSynNo, putInpQueryParam } from '../util/Syno'
import MyLoveNameList from '../mylove/MyLoveNameList';


const StyledSelect = withStyles({
    root: {
        padding: 9,
    }
})(Select);

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(0.5),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(0.5),
        marginRight: 2,
        marginLeft: 2
    },
    button1: {
        marginTop: theme.spacing(0.5),
        marginLeft: 0
    },
}));

export default function MyLoveList(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState(1);
    const [open, setopen] = React.useState(false)
    const [open1, setopen1] = React.useState(false)
    const [open2, setopen2] = React.useState(false)
    const [open3, setopen3] = React.useState(false)
    const [open4, setopen4] = React.useState(false)
    const [open5, setopen5] = React.useState(false)    
    const [openManage, setOpenManage] = React.useState(false)

    const [isLoaded, setLoaded] = React.useState(true);
    const [count, setCount] = React.useState(20)
    const [page, setPage] = React.useState(0)
    const [total, setTotal] = React.useState(0)
    const [error, setError] = React.useState(null)
    const [items, setItems] = React.useState(null)
    const [bigtitle, setBigtitle] = React.useState(null)
    const [fmenulist, setFmenuList] = React.useState([])
    const [nameupdate, setNameUpdate] = React.useState(true)
    const [sortItems, setSortItems] = React.useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openRuleDialog = (event) => {
        setAge(event.target.value);
        if (event.target.value != 0) {
            getMylove(event.target.value, 0, count)
            setPage(0)
        }
    };

    const handleOk = (name) => {
        updateStockName(name)
        setopen1(false);
    };

    const handleOpen1 = () => {
        setAnchorEl(null);
        setopen1(true);
    };

    const handleMangeOpen = () => {
        setAnchorEl(null);
        setOpenManage(true);
    };

    const handleClose1 = () => {
        setopen1(false);
    };

    function createTable() {
        if (error) {
            return "";
        } else if (!isLoaded) {
            return "";
        } else if (items === null) {
            return "";
        } else if (items.length === 0) {
            return <div style={{ color: '#b3b3b3', fontSize: 14 }}>此自選股清單中尚未有任何股票。</div>;
        } else {
            return <SimpleStockList myloveupdate={refreshmylove} total={total} dense={true} disablepage={true} pageupdate={pageupdate} currentpage={page} currentcount={count} items={items} bigtitle={bigtitle} />
        }
    }

    useEffect(() => {
        getMylove(-1, page, count)
    }, []);

    useEffect(() => {
        refreshmylove()
    }, [props.refreshlove]);

    useEffect(() => {
        let h = moment().hour()
        let w = moment().day()

        // console.log("h:" + h + ",w:" + w)
        if (h >= 9 && h < 14 && w > 0 && w < 6) {
            const interval = setInterval(() => {
                let h1 = moment().hour()
                let w1 = moment().day()

                if (h1 >= 9 && h1 < 14 && w1 > 0 && w1 < 6) {
                    pageupdate(page, count)
                }
            }, 15000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [age, page, count]);


    const refreshmylove = () => {
        getMylove(age, page, count)
    }

    const pageupdate = (page, count) => {
        getMylove(age, page, count)
        setPage(page)
        setCount(count)
    }

    const handleSort = () => {
        setAnchorEl(null);
        setopen(true)
    }

    const handleSortMenu = () => {
        setAnchorEl(null);
        setopen5(true)
    }

    const getMylove = (mm, page, count) => {
        let menu = age
        if (mm != undefined && mm != null) menu = mm

        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let a = {
            synno: synno,
            menu: menu,
            inp: {},
            page: page,
            count: count
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (localStorage.getItem('token') != null)
            a["token"] = localStorage.getItem('token')

        a = putInpQueryParam(a)

        fetch("/jstock/v1/stock/getlove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        if (result.data3 != null) {
                            setLoaded(true)
                            setItems(result.data3.company)
                            setSortItems(result.alldata)
                            setBigtitle(result.data3.bigtitle)
                            setTotal(result.data3.total)
                            setError(null)
                        }

                        if (result.data1 != null) {
                            const newChecked = [];
                            result.data1.data.map((value) => {
                                newChecked.push({ value: value.menu, label: value.name })
                            })
                            if(mm === -1 && result.data1.data.length > 0) {
                                setAge(result.data1.data[0].menu)                               
                            }
                            setFmenuList(newChecked)
                            setNameUpdate(!nameupdate)
                        }
                    }
                }
            );

    }

    const updateStockName = (name) => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let menu = age

        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
            menu: menu,
            name: name
        } : {
            synno: synno,
            lic: localStorage.getItem('lic'),
            menu: menu,
            name: name
        };

        fetch("/jstock/v1/stock/addlovename", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        getMylove(age, page, count)
                    }
                }
            );
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const shareLove = () => {
        setAnchorEl(null);

        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')
        let name = fmenulist[parseInt(age) - 1].label

        if (items.length > 0) {
            let stockIds = items.map((value => value.market_company.stock_no))

            let param = lic === null ? {
                name: name,
                stock1: stockIds,
                syno: synno
            } : {
                name: name,
                lic: lic,
                stock1: stockIds,
                syno: synno
            };


            fetch("/jstock/v1/stock/sharelove", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            setopen2(true)
                        } else if (result.status === -1) {
                            setopen3(true)
                        } else if (result.status === -2) {
                            setopen4(true)
                        }
                    },
                    error => {
                    }
                );
        }
    }

    const handleClose = () => {
        setopen(false)
        getMylove(age, page, count)
    };


    const handleMangeClose = () => {
        setOpenManage(false)
        getMylove(age, 0, count)
    };

    const handleClose5 = () => {
        setopen5(false)
        getMylove(age, page, count)
    };

    const delAll = () => {
        setAnchorEl(null);
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let a = {
            synno: synno,
            menu: age,
        }
        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        fetch("/jstock/v1/stock/delalllove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            // .then(res => res.json())
            .then(
                result => {
                    setPage(0)
                    getMylove(age, 0, count)
                }
            );

    }

    return (
        <div>
            <MangeLoveList menu={age} open={openManage} handleClose={handleMangeClose} />

            <Snackbar open={open2} autoHideDuration={5000} onClose={(e) => setopen2(false)}>
                <Alert severity="success" onClose={(e) => setopen2(false)} >
                    感謝您的無私分享，我們會盡快地將它呈現在股民面前。
                </Alert>
            </Snackbar>
            <Snackbar open={open3} autoHideDuration={5000} onClose={(e) => setopen3(false)}>
                <Alert severity="warning" onClose={(e) => setopen3(false)} >
                    很抱歉，已經有人分享過類似的清單。
                </Alert>
            </Snackbar>
            <Snackbar open={open4} autoHideDuration={5000} onClose={(e) => setopen4(false)}>
                <Alert severity="warning" onClose={(e) => setopen4(false)} >
                    很抱歉，24小時內只能分享一次，請明天在繼續吧。
                </Alert>
            </Snackbar>
            <StockName open={open1} handleClose={handleClose1} handleOk={handleOk}></StockName>
            <MoveMyLove open={open} handleClose={handleClose} loveitems={sortItems} menu={age} mobile={true} ></MoveMyLove>
            <MyLoveNameList open={open5} handleClose={handleClose5} menu={age} mobile={true} ></MyLoveNameList>
            
            {/* <IconBreadcrumbs tp={1}></IconBreadcrumbs> */}

            <div style={{ display: 'flex', marginTop: 10 }}>
            <FormControl variant="outlined" className={classes.formControl} >
                <StyledSelect
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    style={{ width: '100%' }}
                    onChange={openRuleDialog}

                >
                    {fmenulist.map((data) =>
                        <MenuItem key={data.value} id={data.value} value={data.value}>{data.label}</MenuItem>
                    )}
                </StyledSelect>

            </FormControl>

            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuopen}
                onClose={handleMenuClose}
            >
                <MenuItem key={'k1'} onClick={handleOpen1}>更改名稱</MenuItem>
                <MenuItem key={'k5'} onClick={handleMangeOpen}>管理個股</MenuItem>
                <MenuItem key={'k2'} onClick={shareLove}>分享清單</MenuItem>
                <MenuItem key={'k6'} onClick={handleSortMenu}>調整清單排序</MenuItem>
                <MenuItem key={'k3'} onClick={handleSort}>調整自選股排序</MenuItem>
                <MenuItem key={'k4'} onClick={delAll}>清空自選股</MenuItem>
            </Menu>
            </div>
            {createTable()}
        </div>
    );
}
