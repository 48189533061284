import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';

export default function AVSetting(props) {
    //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const hrref = React.createRef();
    const drref = React.createRef();
    const erref = React.createRef();
    const frref = React.createRef();
    const maref = React.createRef();
    const dref = React.createRef();

    const [checked1, setChecked1] = React.useState(localStorage.getItem('av1i') === null ? true : localStorage.getItem('av1i').toLowerCase() === 'true');
    const [checked2, setChecked2] = React.useState(localStorage.getItem('av2i') === null ? true : localStorage.getItem('av2i').toLowerCase() === 'true');
    const [checked3, setChecked3] = React.useState(localStorage.getItem('av3i') === null ? true : localStorage.getItem('av3i').toLowerCase() === 'true');
    const [checked4, setChecked4] = React.useState(localStorage.getItem('av4i') === null ? true : localStorage.getItem('av4i').toLowerCase() === 'true');
    const [checked5, setChecked5] = React.useState(localStorage.getItem('av5i') === null ? true : localStorage.getItem('av5i').toLowerCase() === 'true');


    const handleChange = (event, name) => {
        if (name === "av1i") setChecked1(event.target.checked)
        else if (name === "av2i") setChecked2(event.target.checked)
        else if (name === "av3i") setChecked3(event.target.checked)
        else if (name === "av4i") setChecked4(event.target.checked)
        else if (name === "av5i") setChecked5(event.target.checked)
    };

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('av1', parseInt(yref.current.value));

        if (hrref.current.value != null && hrref.current.value != "")
            localStorage.setItem('av2', parseFloat(hrref.current.value));

        if (drref.current.value != null && drref.current.value != "")
            localStorage.setItem('av3', parseFloat(drref.current.value));

        if (erref.current.value != null && erref.current.value != "")
            localStorage.setItem('av4', parseFloat(erref.current.value));

        if (frref.current.value != null && frref.current.value != "")
            localStorage.setItem('av5', parseFloat(frref.current.value));

        if (maref.current.value != null && maref.current.value != "") {
            localStorage.setItem('ema1', maref.current.value);

            if (maref.current.value === 'EMA') localStorage.setItem('ema', 1);
            else localStorage.setItem('ema', 0)
        }

        if (dref.current.value != null && dref.current.value != "") {
            localStorage.setItem('ema2', dref.current.value);
            
            if(dref.current.value === '1/n') localStorage.setItem('ematp', 1);
            else localStorage.setItem('ematp', 0)
        }

        localStorage.setItem('av1i', checked1)
        localStorage.setItem('av2i', checked2)
        localStorage.setItem('av3i', checked3)
        localStorage.setItem('av4i', checked4)
        localStorage.setItem('av5i', checked5)

        props.handleOk()
    };

    const handleClose = () => {
        let av1i = localStorage.getItem('av1i') === null ? true : localStorage.getItem('av1i').toLowerCase() === 'true'
        let av2i = localStorage.getItem('av2i') === null ? true : localStorage.getItem('av2i').toLowerCase() === 'true'
        let av3i = localStorage.getItem('av3i') === null ? true : localStorage.getItem('av3i').toLowerCase() === 'true'
        let av4i = localStorage.getItem('av4i') === null ? false : localStorage.getItem('av4i').toLowerCase() === 'true'
        let av5i = localStorage.getItem('av5i') === null ? false : localStorage.getItem('av5i').toLowerCase() === 'true'

        setChecked1(av1i)
        setChecked2(av2i)
        setChecked3(av3i)
        setChecked4(av4i)
        setChecked5(av5i)

        props.handleClose()
    }

    function yy() {
        return localStorage.getItem('av1') === null ? 5 : localStorage.getItem('av1')
    }

    function hR() {
        return localStorage.getItem('av2') === null ? 20 : localStorage.getItem('av2')
    }

    function hkdk() {
        return localStorage.getItem('av3') === null ? 60 : localStorage.getItem('av3')
    }

    function ehR() {
        return localStorage.getItem('av4') === null ? 120 : localStorage.getItem('av4')
    }

    function fkdk() {
        return localStorage.getItem('av5') === null ? 200 : localStorage.getItem('av5')
    }

    function maf() {
        return localStorage.getItem('ema1') === null ? 'SMA' : localStorage.getItem('ema1')
    }

    function dd() {
        return localStorage.getItem('ema2') === null ? '2/(n+1)' : localStorage.getItem('ema2')
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">均線參數</DialogTitle>

            <DialogContent>
                <Box display="flex" flexDirection="row" >
                    <Checkbox
                        checked={checked1}
                        onChange={e => handleChange(e, 'av1i')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> <TextField
                        autoFocus
                        margin="dense"
                        id="yy"
                        label="均線1(預設:5)"
                        type="text"
                        fullWidth
                        defaultValue={yy()}
                        inputRef={yref}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box display="flex" flexDirection="row" >
                    <Checkbox
                        checked={checked2}
                        onChange={e => handleChange(e, 'av2i')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <TextField
                        margin="dense"
                        id="hr"
                        label="均線2(預設:20)"
                        type="text"
                        fullWidth
                        defaultValue={hR()}
                        inputRef={hrref}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <Box display="flex" flexDirection="row" >
                    <Checkbox
                        checked={checked3}
                        onChange={e => handleChange(e, 'av3i')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <TextField
                        margin="dense"
                        id="kdk"
                        label="均線3(預設:60)"
                        type="text"
                        fullWidth
                        defaultValue={hkdk()}
                        inputRef={drref}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <Box display="flex" flexDirection="row" >
                    <Checkbox
                        checked={checked4}
                        onChange={e => handleChange(e, 'av4i')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <TextField
                        margin="dense"
                        id="erref"
                        label="均線4(預設:120)"
                        type="text"
                        fullWidth
                        defaultValue={ehR()}
                        inputRef={erref}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <Box display="flex" flexDirection="row" >
                    <Checkbox
                        checked={checked5}
                        onChange={e => handleChange(e, 'av5i')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <TextField
                        margin="dense"
                        id="frref"
                        label="均線5(預設:200)"
                        type="text"
                        fullWidth
                        defaultValue={fkdk()}
                        inputRef={frref}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <Box display="flex" flexDirection="row" >
                    <TextField
                        autoFocus
                        margin="dense"
                        id="maf"
                        label="預設:SMA，可輸入EMA"
                        type="text"
                        fullWidth
                        defaultValue={maf()}
                        inputRef={maref}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>

                <TextField
                    autoFocus
                    margin="dense"
                    id="dd"
                    label="EMA計算方式，預設:2/(n+1)，可輸入:1/n"
                    type="text"
                    fullWidth
                    defaultValue={dd()}
                    inputRef={dref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    取消
                </Button>
                <Button onClick={handleOk} color="primary">
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    );
}
