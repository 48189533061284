import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import zhtwLocale from "date-fns/locale/zh-TW";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import * as dayjs from 'dayjs'

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 10,
        backgroundColor: theme.palette.background.default,
    },
    margin: {
        // margin: theme.spacing(0.5),
    },
    textField: {
        width: 165,
    },
    textField1: {
        // width: 340,
    },
    title: {
        color: "#000000"
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateReport1(props) {
    const classes = useStyles();
    const [stock, setStock] = React.useState("");
    const [sname, setSname] = React.useState("");
    const [tranType, setTranType] = React.useState(0);
    const [real, setReal] = React.useState(0);
    const [bs, setBS] = React.useState(0);
    const [buyDate, setBuyDate] = React.useState(null);
    const [buyTime, setBuyTime] = React.useState(null);
    const [bprice, setBprice] = React.useState("");
    const [bvolumn, setbvolumn] = React.useState("");
    const [cost, setcost] = React.useState("");
    const [inReason, setinReason] = React.useState("");


    const [stopEarn, setstopEarn] = React.useState("");
    const [stopEarnp, setstopEarnp] = React.useState("");
    const [stopLost, setstopLost] = React.useState("");
    const [stopLostp, setstopLostp] = React.useState("");
    const [stopmove, setStopMove] = React.useState("");
    const [instrage, setInstrage] = React.useState("");
    const [outstrage, setOutstrage] = React.useState("");
    const [smemo, setSMemo] = React.useState("");

    const [rules, setRules] = React.useState([]);
    const [stopmsg, setStopMsg] = React.useState("");


    const handlestock = (event) => {
        setStock(event.target.value);

        fetch("/jstock/v1/stock/searchcom?key=" + event.target.value)
            .then(res => res.json())
            .then(
                result => {
                    if (result.length === 1) {
                        let v = result[0].label.split(' ')
                        if (v.length === 2) {
                            setSname(v[1])
                        } else {
                            setSname("")
                        }
                    } else {
                        setSname("")
                    }
                });
    }

    const handlestockname = (event) => {
        setSname(event.target.value);

        fetch("/jstock/v1/stock/searchcom?key=" + event.target.value)
            .then(res => res.json())
            .then(
                result => {
                    if (result.length === 1) {
                        let v = result[0].label.split(' ')
                        if (v.length === 2) {
                            setStock(v[0])
                        } else {
                            setStock("")
                        }
                    } else {
                        setStock("")
                    }
                });
    }

    const handleBuyDate = (value) => {
        setBuyDate(value);
    };

    const handleBuyTime = (value) => {
        setBuyTime(value);
    };


    const saveReport = () => {
        let a = {
            "real": real,
            "tp": tranType,
            "bs": bs,
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (stock != null && stock != "")
            a["stock"] = stock
        if (sname != null && sname != "")
            a["sname"] = sname
        if (buyDate != null && buyDate != "")
            a["ymd"] = dayjs(buyDate).format('YYYYMMDD')
        if (buyTime != null && buyTime != "")
            a["ts"] = dayjs(buyTime).format('HH:mm')
        if (bprice != null && bprice != "")
            a["p"] = bprice
        if (bvolumn != null && bvolumn != "")
            a["num"] = bvolumn
        if (cost != null && cost != "")
            a["cost"] = cost
        if (inReason != null && inReason != "")
            a["tmemo"] = inReason
        if (instrage != null && instrage != "")
            a["ins"] = instrage
        if (outstrage != null && outstrage != "")
            a["outs"] = outstrage
        if (stopEarn != null && stopEarn != "")
            a["stopearn"] = stopEarn
        if (stopEarnp != null && stopEarnp != "")
            a["stopearnp"] = stopEarnp
        if (stopLost != null && stopLost != "")
            a["stoplost"] = stopLost
        if (stopLostp != null && stopLostp != "")
            a["stoplostp"] = stopLostp
        if (stopmove != null && stopmove != "")
            a["move"] = stopmove
        if (smemo != null && smemo != "")
            a["smemo"] = smemo

        fetch("/jstock/v1/stock/treport1/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setStock("")
                        setSname("")
                        setBuyDate(null)
                        setBuyTime(null)
                        setBprice("")
                        setbvolumn("")
                        setcost("")
                        setstopEarn("")
                        setstopLost("")
                        setstopEarnp("")
                        setstopLostp("")
                        setinReason("")
                        setInstrage("")
                        setOutstrage("")
                        setSMemo("")

                        props.close()
                    }
                }
            );
    }


    function getLoveRules() {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
        } : {
                synno: synno,
                lic: localStorage.getItem('lic'),
            };


        fetch("/jstock/v1/stock/getloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setRules(result.data.allrules)
                    }
                }
            );
    }

    function makeid1(length) {
        var result = '';
        var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function getSynNo() {
        let id = makeid1(7) + "000"
        localStorage.setItem("synno", id)
        return id
    }

    useEffect(() => {
        if (props.open) getLoveRules()
    }, [props.open]);

    return (
        <Dialog fullWidth={true}
            maxWidth={'lg'} open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <DialogTitle id="simple-dialog-title">新增交易日誌</DialogTitle>
            <DialogContent dividers style={{ paddingLeft: 15, paddingRight: 15 }} >
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhtwLocale}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={4}>
                            <KeyboardDatePicker
                                disableToolbar
                                required
                                variant="inline"
                                format="yyyyMMdd"
                                id="buyDate"
                                label="成交日期"
                                value={buyDate}
                                onChange={handleBuyDate}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <KeyboardTimePicker
                                // margin="normal"
                                label="成交時間"
                                id="buyTime"
                                format="HH:mm"
                                value={buyTime}
                                onChange={handleBuyTime}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="股票代號"
                                id="stock"
                                required
                                value={stock}
                                fullWidth={true}
                                onChange={handlestock}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="股票名稱"
                                id="sname"
                                required
                                value={sname}
                                fullWidth={true}
                                onChange={handlestockname}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="型態"
                                id="real"
                                required
                                value={real}
                                fullWidth={true}
                                onChange={(event) => setReal(event.target.value)}
                                select
                            >
                                <MenuItem key={'0'} value={0}>實單</MenuItem>
                                <MenuItem key={'1'} value={1}>模擬單</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="交易類別"
                                id="trantype"
                                required
                                value={tranType}
                                fullWidth={true}
                                onChange={(event) => setTranType(event.target.value)}
                                select
                            >
                                <MenuItem key={'0'} value={"0"}>現股</MenuItem>
                                <MenuItem key={'1'} value={"1"}>融資</MenuItem>
                                <MenuItem key={'2'} value={"2"}>融券</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="交易買賣"
                                id="transell"
                                required
                                value={bs}
                                fullWidth={true}
                                onChange={(event) => setBS(event.target.value)}
                                select
                            >
                                <MenuItem key={'0'} value={0}>買進</MenuItem>
                                <MenuItem key={'1'} value={1}>賣出</MenuItem>
                            </TextField>
                        </Grid>



                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                label="成交價格"
                                id="stockprice"
                                value={bprice}
                                fullWidth={true}
                                onChange={event => setBprice(event.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                label="成交股數"
                                id="stocknum"
                                fullWidth={true}
                                value={bvolumn}
                                onChange={event => setbvolumn(event.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="交割金額"
                                id="amount"
                                value={cost}
                                onChange={event => setcost(event.target.value)}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="inReason"
                                label="交易備註"
                                placeholder=""
                                helperText=""
                                fullWidth={true}
                                value={inReason}
                                onChange={event => setinReason(event.target.value)}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>


                    <Grid container spacing={1} style={{marginTop: 10}} >
                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="進場策略"
                                id="instrage"
                                fullWidth={true}
                                value={instrage}
                                onChange={(event) => setInstrage(event.target.value)}
                                select
                            >
                                {rules.map((data, index) => { return (<MenuItem key={data.id + index} name={data.name} value={data.id} >{data.name}</MenuItem>) })}
                            </TextField>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="出場策略"
                                id="outstrage"
                                fullWidth={true}
                                value={outstrage}
                                onChange={(event) => setOutstrage(event.target.value)}
                                select
                            >
                                {rules.map((data, index) => { return (<MenuItem key={data.id + index} name={data.name} value={data.id} >{data.name}</MenuItem>) })}
                            </TextField>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="停利價"
                                id="stopearn"
                                value={stopEarn}
                                onChange={event => setstopEarn(event.target.value)}
                                fullWidth={true}
                            />
                        </Grid>


                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="停損價"
                                id="stoplost"
                                value={stopLost}
                                fullWidth={true}
                                onChange={event => setstopLost(event.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="移動停利"
                                id="stopmove"
                                value={stopmove}
                                onChange={(event) => setStopMove(event.target.value)}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="smemo"
                                label="策略備註"
                                placeholder=""
                                helperText=""
                                fullWidth={true}
                                value={smemo}
                                onChange={(event) => setSMemo(event.target.value)}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>

                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="primary">
                    關閉
                </Button>
                <Button onClick={saveReport} color="primary">
                    新增
                </Button>
            </DialogActions>
        </Dialog>
    );
}
