import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import BColor from '../BColor'
import Link from '@material-ui/core/Link';
import { hashCode } from "../util/Syno";

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 3, 2),
  },
  heroContent1: {
    padding: theme.spacing(8, 3, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardmenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));


function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export default function Donation2() {
  const classes = useStyles();

  const [hasLic, setHasLic] = React.useState(0);
  const [lic, setLic] = React.useState(null);
  const [lictxt, setLictxt] = React.useState("");
  const [inlic, setInLic] = React.useState("");

  const [noad, setNoad] = React.useState(false)
  const [p1, setP1] = React.useState(false);
  const [p2, setP2] = React.useState(false);


  const handleOk1 = () => {
    // setOpen(false);
    window.open('https://stock-digger.com/#/rules#price', '股票挖土機')
  }

  // const handleOk1 = (tp) => {
  //   setOpen(false);
  //   if(tp === 0) {
  //     window.open('https://www.ruten.com.tw/item/show?22112304725863#price', '股票挖土機')
  //   } else if(tp === 1) {
  //     window.open('https://www.ruten.com.tw/item/show?22112304750939#price', '股票挖土機')
  //   } else {
  //     window.open('https://www.ruten.com.tw/item/show?22112304612251#price', '股票挖土機')
  //   }
  // }




  useEffect(() => {
    checkLic();
  }, []);


  const checkLic = () => {
    let lic = localStorage.getItem('lic')

    if (lic != null) {
      let param = {
        lic: lic
      }

      fetch("/jstock/v1/stock/checklic", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(param)
      })
        .then(res => res.json())
        .then(
          result => {
            if (result.status === 0) {

              if (result.hlic != null) {
                setNoad(result.hlic.noad)
                setHasLic(result.hlic.licok)
                setLic(result.hlic)

                if (result.hlic.licok === 1 || result.hlic.tp === 3) {
                  localStorage.setItem('noad', 1);
                } else localStorage.setItem('noad', 0);

              } else {
                setHasLic(0)
                localStorage.setItem('noad', 0);
                setNoad(false)
                setP1(false)
                setP2(false)
              }

              if (result.lp != null) {
                setP1(result.lp.p1)
                setP2(result.lp.p2)
              }
            } else {
              setHasLic(0)
              localStorage.setItem('noad', 0);
              setNoad(false)
              setP1(false)
              setP2(false)
            }
          },
          error => {
            setHasLic(0)
            localStorage.setItem('noad', 0);
            setNoad(false)
            setP1(false)
            setP2(false)
          }
        );
    }
  }


  const clearLic = () => {
    localStorage.removeItem('lic')
    localStorage.setItem('noad', 0);
    setHasLic(0)
    setNoad(false)
    setLictxt("")
    setLic(null)

  }

  const checkLicByLic = () => {
    let lic = inlic

    if (lic != null) {
      let param = {
        lic: lic
      }

      fetch("/jstock/v1/stock/checklic", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(param)
      })
        .then(res => res.json())
        .then(
          result => {
            if (result.status === 0) {

              if (result.hlic != null) {
                localStorage.setItem('lic', lic);
                setNoad(result.hlic.noad)
                setHasLic(result.hlic.licok)
                setLic(result.hlic)
                setLictxt("")

                if (result.hlic.licok === 1 || result.hlic.tp === 3)
                  localStorage.setItem('noad', 1);
                else localStorage.setItem('noad', 0);

              } else {
                setHasLic(0)
                localStorage.setItem('noad', 0);
                setLictxt("此序號不存在")
              }

              if (result.lp != null) {
                setP1(result.lp.p1)
                setP2(result.lp.p2)
              }
            } else {
              setHasLic(0)
              localStorage.setItem('noad', 0);
              setLictxt("此序號不存在")
            }
          },
          error => {
            setHasLic(0)
            localStorage.setItem('noad', 0);
            setLictxt("此序號不存在")
          }
        );
    }
  }

  const getNewLicId = () => {
    let id = makeid(6)
    localStorage.setItem("lica", id)
    return id
  }

  const inputLic = name => event => {
    setInLic(event.target.value)
  }

  const getTitle = () => {
    if (lic === null) return '免費'
    else if (lic.tp === 2) return '無使用期限'
    else if (lic.tp === 3) return '無廣告'
    else if (lic.tp === 4) return '試用十天'
    else if (lic.tp === 5) return '訂閱一個月'
    else if (lic.tp === 0) return '每月繳'
    else if (lic.tp === 12) return '每季繳'
    else if (lic.tp === 13) return '每季繳優惠#1'
    else if (lic.tp === 6) return '每半年繳'
    else if (lic.tp === 7) return '每年繳'
    else if (lic.tp === 8) return '訂閱半年'
    else if (lic.tp === 9) return '無使用期限'
    else if (lic.tp === 10) return '無使用期限優惠#1'
    else if (lic.tp === 11) return '無使用期限優惠#2'
    else if (lic.tp === 14) return '訂閱一年'
    else if (lic.tp === 15) return '每月繳'
    else if (lic.tp === 16) return '買斷三年'
    else if (lic.tp === 17) return '買斷六年'
    else return ''
  }

  const getExpireTitle = () => {
    if (lic.expire != null)
      return lic.expire
    else if (lic.tp != null && (lic.tp === 0 || lic.tp === 7 || lic.tp === 15))
      return "訂閱期間有效"
    else
      return '無特定期限'
  }

  const getPriceList = () => {
    let tiers = [
      {
        title: '免費',
        price: '0',
        description: [
          '會有廣告在手機版的最下方',
          '搜尋結果最多10筆',
          '手機跟電腦分開使用不能同步',
          '換手機無法保留自選股與選股策略',
          '沒有產業,懶人,轉折波,型態選股',
          '沒有交易日誌,回測系統,相似型態',
        ],
        buttonText: '直接使用',
        buttonVariant: 'outlined',
        unit: '',
        tp: '',
      },
    ];


    let lic = localStorage.getItem('lic')
    // if (lic != null && (hashCode(lic) === 1404558798 || hashCode(lic) === -1100120674)) {
    //   tiers.push(
    //     {
    //       title: '舊方案-月訂閱',
    //       price: "188",
    //       description: [
    //         <div style={{ backgroundColor: BColor.upcolor, color: "#FFFFFF" }}></div>,
    //         '信用卡每月自動扣款',
    //         '訂閱期間解鎖所有功能',
    //         <div style={{ color: BColor.upcolor }}>取消訂閱: 大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理</div>
    //       ],
    //       buttonText: '刷卡訂閱',
    //       buttonVariant: 'outlined',
    //       unit: '/每月',
    //       tp: '0',
    //     }
    //   )
    // } 

    tiers.push(
      {
        title: '十天方案',
        price: '99',
        description: [
          '十天內解鎖所有功能與限制',
          '十天內無任何廣告',
          '十天內新功能免費使用'
        ],
        buttonText: '刷卡,ATM,超商',
        buttonVariant: 'outlined',
        unit: '/10天',
        tp: '4',
      }
    )

    tiers.push(
      {
        title: '月訂閱',
        price: '199',
        description: [
          '信用卡每月自動扣款',
          '訂閱期間解鎖所有功能',
          <div style={{ color: BColor.upcolor }}>取消訂閱: 大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理</div>
        ],
        buttonText: '刷卡訂閱',
        buttonVariant: 'outlined',
        unit: '/每月',
        tp: '15',
      }
    )

    tiers.push(
      {
        title: '年訂閱',
        price: '1988',
        description: [
          '信用卡每年自動扣款',
          '訂閱期間解鎖所有功能',
          <div style={{ color: BColor.upcolor }}>取消訂閱: 大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理</div>
        ],
        buttonText: '刷卡訂閱',
        buttonVariant: 'outlined',
        unit: '/每年',
        tp: '7',
      },
      {
        title: '三年優惠方案',
        price: '3888',
        description: [
          '三年內解鎖所有功能與限制',
          '三年內無任何廣告',
          '三年內新功能免費使用',
        ],
        buttonText: '刷卡,ATM,超商',
        buttonVariant: 'outlined',
        unit: '/3年',
        tp: '16',
      },
      {
        title: '六年優惠方案',
        price: '5888',
        description: [
          '六年內解鎖所有功能與限制',
          '六年內無任何廣告',
          '六年內新功能免費使用',
        ],
        buttonText: '刷卡,ATM,超商',
        buttonVariant: 'outlined',
        unit: '/6年',
        tp: '17',
      },
    )
    return tiers;
  }


  return (
    <React.Fragment>
      <CssBaseline />

      {/* <Container maxWidth="md" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          方案價格
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          找到自己最合適的支持方案，讓使用者與開發者達到雙贏的目的。隨著功能不斷的開發與使用人數的增長，為了讓挖土機持續開挖出好股票，不論何種形式支持都是對股票挖土機的永續經營來說都是莫大的幫助。
        </Typography>
      </Container> */}
      <div style={{ marginTop: 30 }}></div>
      {lic != null && (lic.licok === 1 || lic.tp === 3) ?
        <Container maxWidth="md" component="main" style={{ marginBottom: 30 }}>
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item key={"aa"} xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  title={getTitle()}
                  subheader={""}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={<StarIcon />}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardmenu}>
                    <Typography variant="h6" color="textPrimary">
                      序號
                    </Typography>
                  </div>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {lic.lic}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">

                    </Typography>
                  </div>

                  <ul>
                    <Typography component="li" variant="subtitle1" align="center" key={Math.random()}>
                      使用期限：{getExpireTitle()}
                    </Typography>
                    <Typography component="li" variant="caption" align="center" color="textSecondary">
                      妥善保管，勿公開給他人使用
                    </Typography>
                    <Typography component="li" variant="caption" align="center" color="textSecondary">
                      取消訂閱: 大名與手機末五碼寄信到jookershop@gmail.com隨即會有專人處理
                    </Typography>

                  </ul>
                </CardContent>
                <CardActions>
                  <Button style={{ width: '100%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => clearLic()}>清除
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container> : <Container maxWidth="md" component="main" style={{ marginBottom: 30 }}>
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item key={"aa"} xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  title={'輸入序號'}
                  subheader={""}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={<StarIcon />}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <TextField
                    id="standard-name"
                    label="輸入序號"
                    onChange={inputLic('')}
                    fullWidth
                    style={{ fontSize: 18 }}
                    helperText={lictxt}
                  />
                  <br />
                  <Typography variant="caption" color="textSecondary" component="p">
                    <li>相同序號皆可共用於手機，平板，電腦等平台</li>
                    <li>如何取得序號? <Link onClick={(e) => handleOk1()}>請洽官網方案列表</Link></li>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button style={{ width: '100%', backgroundColor: "#1c1c1c", color: "#FFFFFF" }} size="large" fullWidth variant="outlined" align="center" color="primary" onClick={event => checkLicByLic()}>送出
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>}



    </React.Fragment>
  );
}