import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'


echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function TLBChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)


    function getAnnoValue(xvalue, yvalue, title, color) {
        let offsetX = 0

        return {
            symbol: 'rect',
            symbolSize: 2,
            coord: [xvalue, yvalue],
            value: title,
            label: {
                show: true,
                backgroundColor: color,
                padding: 3,
                offset: [offsetX, -15],
            }
        }
    }

    function getXAnnoValue(xvalue, xvalue1, title, color1) {
        return [
            {
                name: title,
                xAxis: xvalue,
                label: {
                    show: true,
                    padding: 3,
                    position: 'top'
                },
                itemStyle: {
                    color: color1
                }
            },
            {
                xAxis: xvalue1
            }
        ]



        // let v = {
        //     x: dayjs(xvalue).locale('zh-tw').format('MM/DD'),
        //     x2: dayjs(xvalue1).locale('zh-tw').format('MM/DD'),
        //     fillColor: color1,
        //     borderColor: '#000000',
        //     strokeDashArray: 5,
        //     label: {
        //         style: {
        //             color: '#fff',
        //             background: color,
        //         },
        //         borderWidth: 0,
        //         text: title,
        //         orientation: 'horizontal',
        //     }
        // }
        // return v
    }

    function genData1() {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '新三價線-轉折',
                    top: '10',
                },{
                    left: 'center',
                    text: '新三價線-趨勢',
                    top: '390',
                },
            ],
            grid: [
                {
                    top: '40',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },{
                    top: '450',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },{
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 1,
                },
            ]
        };


        let x1 = {
            type: 'category',
            data: props.row.tlb.d1.map(v => { return v.x })
        }

        let x2 = {
            type: 'category',
            data: props.row.tlb.d1.map(v => { return v.x }),
            gridIndex: 1,
        }


        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
            x2['axisPointer'] = xp
        }

        option['xAxis'] = [x1, x2]

        let yax = []
        if (props.row.tlb != undefined) {
            props.row.tlb.buy.map(r => {
                yax.push(getAnnoValue(r.x, r.y, "買進", "#FF4560"))
            })
            props.row.tlb.sell.map(r => {
                yax.push(getAnnoValue(r.x, r.y, "賣出", "#16a850"))
            })
        }

        let xax = []
        let color1 = theme.palette.type === "light" ? "#ffd9de" : "#c7939a"
        let color2 = theme.palette.type === "light" ? "#9dc7ae" : "#6e8f7c"

        if (props.row.tlb != undefined) {
            props.row.tlb.buy1.map(r => {
                xax.push(getXAnnoValue(r.x, r.x2, "上漲", color1))
            })
            props.row.tlb.sell1.map(r => {
                xax.push(getXAnnoValue(r.x, r.x2, "下跌", color2))
            })
        }        

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: props.row.tlb.d1.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markPoint: {
                    data: yax
                },
            },
            {
                name: '日K線',
                type: 'candlestick',
                data: props.row.tlb.d1.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                xAxisIndex: 1,
                yAxisIndex: 1,
                markArea: {
                    data: xax
                }
            },
        ]


        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }


        return option
    }



    useEffect(() => {
        if (props.row.tlb != null) setOptions(genData1())
    }, [props.row]);

    function getString() {
        return "目前無相關的新三價線圖"
    }

    return (
        <div>
            {localStorage.getItem('noad') != null && localStorage.getItem('noad') == "1" ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ fontSize: 14, color: "#808080" }}>
                        源自Steve Nison股票K線戰，判別中長期多空趨勢方向的指標<br />
                        1. 連續三根紅棒可證實為上漲趨勢，可買進並以作多為主<br />
                        2. 連續三根綠棒可證實為下跌趨勢，可賣出並以作空為主<br />
                        3. 連續三根紅棒之後反轉成綠棒為賣出信號<br />
                        4. 連續三根綠棒之後反轉成紅棒為買進信號<br />
                        5. 紅綠棒交錯頻繁表示市場缺乏明顯趨勢<br />
                    </div>
                    {options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 800, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

            </Grid> : <div>目前新三價線圖僅開放給訂閱者。</div>}
        </div >
    );
}