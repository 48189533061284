import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function RevWow(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false);


    const [options1, setOptions1] = React.useState(null)    
    const [r2, setR2] = React.useState(null)


    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let param = {
                lic: lic,
                sid: props.row.market_company.stock_no,
            };

            fetch("/jstock/v1/stock/revwow", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setLoading(false)
                        if (result.data != null && result.data.r1 != null) {
                            setData(result.data.r1)
                        }
                        if (result.data != null && result.data.r2 != null) {
                            setR2(result.data.r2)
                        }

                    }
                )

        }
    }

    function genData1() {
        let option = {
            animation: false,
            legend: {
                data: ['股價成長', '營收成長'],
                show: true
            },
            title: [
            ],
            grid: [
                {
                    top: '40',
                    left: '10',
                    right: '10',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.3
                        }
                    }
                }
            ]
        };


        let x1 = {
            type: 'category',
            data: data.map(v => { return v.x })
        }



        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = [x1]

        option['series'] = [
            {
                name: '股價成長',
                type: 'line',
                data: data.map(v => { return v.y }),
            },
            {
                name: '營收成長',
                type: 'line',
                data: data.map(v => { return v.z }),
                connectNulls: true,
            }
        ]


        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }


        return option
    }


    useEffect(() => {
        if (data != null) setOptions(genData1())
    }, [data]);
    
    useEffect(() => {
        if (r2 != null) setOptions1(genData2())
    }, [r2]);

    useEffect(() => {
        if (props.row != null) getPHV1()
    }, [props.row]);


    function genData2() {
        let option = {
            animation: false,
            legend: {
                data: ['股價成長', 'EPS成長'],
                show: true
            },
            title: [
            ],
            grid: [
                {
                    top: '40',
                    left: '10',
                    right: '10',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.3
                        }
                    }
                }
            ]
        };


        let x1 = {
            type: 'category',
            data: r2.map(v => { return v.x })
        }



        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = [x1]

        option['series'] = [
            {
                name: '股價成長',
                type: 'line',
                data: r2.map(v => { return v.y }),
            },
            {
                name: 'EPS成長',
                type: 'line',
                data: r2.map(v => { return v.z }),
                connectNulls: true,
            }
        ]


        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }


        return option
    }


    function getString() {
        return "目前無相關的股狗圖"
    }

    return (
        <div>
            {localStorage.getItem('noad') != null && localStorage.getItem('noad') == "1" ? <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ fontSize: 14, color: "#808080" }}>
                    </div>
                    {options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 400, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    /> : <div style={{}}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={12} >
                    {options1 != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 400, marginTop: 0 }}
                        echarts={echarts}
                        option={options1}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    /> : <div style={{}}>{getString()}</div>}
                </Grid>

            </Grid> : <div>目前股狗圖僅開放給訂閱者。</div>}
        </div >
    );
}