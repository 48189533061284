import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import FinRateList from './FinRateList';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'


function desc(a, b, orderBy) {
    if (b.pll != undefined && a.pll != undefined && orderBy != "" && b.pll[orderBy] < a.pll[orderBy]) {
        return -1;
    }
    if (b.pll != undefined && a.pll != undefined && orderBy != "" && b.pll[orderBy] > a.pll[orderBy]) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// case class ChipPage(all : Long, sinc : Long, user : Long, uinc : Long, avgs : Double, avginc : Double, cuser : Long, cuper : Double, cincp : Double, cinc : Long, cstock : Long, csper : Double, csincp: Double)

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'pli', numeric: false, disablePadding: false, label: '毛利率', sid: 'pli' },
    { id: 'plc1', numeric: false, disablePadding: false, label: '毛利率方向', sid: 'plc1' },
    { id: 'noi', numeric: false, disablePadding: false, label: '營益率', sid: 'noi' },
    { id: 'noc1', numeric: false, disablePadding: false, label: '營益率方向', sid: 'noc1' },
    { id: 'iti', numeric: false, disablePadding: false, label: '淨利率', sid: 'iti' },
    { id: 'itc1', numeric: false, disablePadding: false, label: '淨利率方向', sid: 'itc1' },

    { id: 'ymd', numeric: false, disablePadding: false, label: '年份/季度', sid: 'ymd' },
    { id: 'pliv', numeric: false, disablePadding: false, label: '毛利率', sid: 'pliv' },
    { id: 'plyoy', numeric: false, disablePadding: false, label: '毛利YoY', sid: 'plyoy' },
    { id: 'plqoq', numeric: false, disablePadding: false, label: '毛利QoQ', sid: 'plqoq' },

    { id: 'noiv', numeric: false, disablePadding: false, label: '營益率', sid: 'noiv' },
    { id: 'noiyoy', numeric: false, disablePadding: false, label: '營益YoY', sid: 'noiyoy' },
    { id: 'noiqoq', numeric: false, disablePadding: false, label: '營益QoQ', sid: 'noiqoq' },

    { id: 'itiv', numeric: false, disablePadding: false, label: '淨利率', sid: 'itiv' },
    { id: 'itiyoy', numeric: false, disablePadding: false, label: '淨利YoY', sid: 'itiyoy' },
    { id: 'itiqoq', numeric: false, disablePadding: false, label: '淨利QoQ', sid: 'itiqoq' },

    { id: 'ymd1', numeric: false, disablePadding: false, label: '年份', sid: 'ymd1' },

    { id: 'pliv1', numeric: false, disablePadding: false, label: '毛利率', sid: 'pliv1' },
    { id: 'plyoyi1', numeric: false, disablePadding: false, label: '毛利YoY', sid: 'plyoy1' },

    { id: 'noiv1', numeric: false, disablePadding: false, label: '營益率', sid: 'noiv1' },
    { id: 'noiyoy1', numeric: false, disablePadding: false, label: '營益YoY', sid: 'noiyoy1' },

    { id: 'itiv1', numeric: false, disablePadding: false, label: '淨利率', sid: 'itiv1' },
    { id: 'itiyoy1', numeric: false, disablePadding: false, label: '淨利YoY', sid: 'itiyoy1' },
    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>

            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">總覽
                </TableCell>

                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一季毛利率
                </TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一季營益率
                </TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一季淨利率
                </TableCell>
                <TableCell className={classes.stickya} colSpan={7} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一年狀態
                </TableCell>

                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'ymd1' || headCell.id === 'ymd' || headCell.id === 'noiv' || headCell.id === 'itiv' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap' }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function FinRateDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }


    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };



    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }


    function renderValue(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = value1 + "%"
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = value1 + "%"
        } else if (value === 2) {
            colorx = BColor.faircolor
            word = value1 + "%"
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValue1(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.downcolorbox
            word = '虧轉虧'
        } else if (value === 1) {
            colorx = BColor.upcolorbox
            word = '虧轉盈'
        } else if (value === 2) {
            colorx = BColor.downcolorbox
            word = '盈轉虧'
        } else if (value === 3) {
            if (value1 > 0) {
                colorx = BColor.upcolorbox
            } else if (value1 < 0) {
                colorx = BColor.downcolorbox
            } else {
                colorx = BColor.faircolor
            }
            word = value1 + unit
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        // {unit}
        return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }



    function renderValue3(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = "高" + unit
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = "低" + unit
        } else if (value === 2) {
            colorx = BColor.faircolor
            word = "中" + unit
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValue4(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = "持續正成長"
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = "持續負成長"
        } else if (value === 2) {
            colorx = BColor.upcolorbox
            word = "大幅回升"
        } else if (value === 3) {
            colorx = BColor.upcolorbox
            word = '小幅回升'
        } else if (value === 4) {
            colorx = BColor.downcolorbox
            word = '大幅下跌'
        } else if (value === 5) {
            colorx = BColor.downcolorbox
            word = '小幅下跌'
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <FinRateList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></FinRateList>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 5 }} align="right">
                                                {renderValue3(row.pll.pli, row.pll.pliv, '毛利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 5 }} align="right">
                                                {renderValue4(row.pll.plc1, row.pll.pliv, '毛利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.pll.noi, row.pll.noiv, '營益率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 5 }} align="right">
                                                {renderValue4(row.pll.noc1, row.pll.pliv, '營益率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}


                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 10 }} align="right">
                                                {renderValue3(row.pll.iti, row.pll.itiv, '淨利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 10 }} align="right">
                                                {renderValue4(row.pll.itc1, row.pll.itiv, '淨利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll == null || row.pll == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="6" align="center">
                                                目前無任何資料
                                            </TableCell> : null}


                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 10, paddingRight: 5 }} align="right">
                                                <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{row.pll.ymd}</Button>
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue(row.pll.pli, row.pll.pliv, '毛利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.plyoyi, row.pll.plyoy, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 10 }} align="right">
                                                {renderValue1(row.pll.plqoqi, row.pll.plqoq, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll == null || row.pll == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="4" align="center">
                                                目前無任何資料
                                            </TableCell> : null}

                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 10, paddingRight: 5 }} align="right">
                                                {renderValue(row.pll.noi, row.pll.noiv, '營益率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.noii, row.pll.noiyoy, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 10 }} align="right">
                                                {renderValue1(row.pll.noiqi, row.pll.noiqoq, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll == null || row.pll == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="3" align="center">
                                                目前無任何資料
                                            </TableCell> : null}

                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 10, paddingRight: 5 }} align="right">
                                                {renderValue(row.pll.iti, row.pll.itiv, '淨利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.itii, row.pll.itiyoy, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.itiqi, row.pll.itiqoq, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}


                                            {row.pll == null || row.pll == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="3" align="center">
                                                目前無任何資料
                                            </TableCell> : null}




                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="right">
                                                <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{row.pll.ymd1}</Button>
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue(row.pll.pli1, row.pll.pliv1, '毛利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.plyoyi1, row.pll.plyoy1, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue(row.pll.noi1, row.pll.noiv1, '營益率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.noii1, row.pll.noiyoy1, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue(row.pll.iti1, row.pll.itiv1, '淨利率', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll != null && row.pll != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue1(row.pll.itii1, row.pll.itiyoy1, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.pll == null || row.pll == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="7" align="center">
                                                目前無任何資料
                                            </TableCell> : null}
                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(FinRateDetail);
