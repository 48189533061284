import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BackTest from '../backtest/BackTest'
import KTest from '../ktest/KTest'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
  },
  tabcontainer: {
    paddingTop: 16,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8 * 1,
      paddingRight: 8 * 1,
      paddingBottom: 10,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 8 * 1,
      paddingRight: 8 * 1,
      paddingBottom: 30,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 30,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 30,
    },
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.default,

  },


}));

export default function FindSameD(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="回測系統" {...a11yProps(0)} />
          <Tab label="相似型態" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {value === 0 && <div className={classes.tabcontainer}>
        <BackTest ismobile={props.ismobile} />
      </div>}
      {value === 1 && <div className={classes.tabcontainer}>
        <KTest  ismobile={props.ismobile} />
      </div>}

      {/* <TabPanel value={value} index={0}>
        <BackTest />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KTest />
      </TabPanel> */}
    </div>
  );
}
