import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function StockName(props) {
  //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();

    const handleOk = () => {
    //   if(yref.current.value != null && yref.current.value != "")
    //   localStorage.setItem('name_temp', yref.current.value);

      props.handleOk(yref.current.value)
    };


//   function yy() {
//     return localStorage.getItem('name_temp') === null ?  20 : localStorage.getItem('name_temp')
//   }

  
  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">自選清單</DialogTitle> */}

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label="新名稱"
            type="text"
            fullWidth
            size="small"
            // defaultValue={yy()}
            inputRef={yref}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
                maxLength: 25
              }}
          />
          

        </DialogContent>
        <DialogActions>
          <Button  size="small" onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button size="small" onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>
  );
}
