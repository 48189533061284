import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        whiteSpace: 'nowrap'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);


function createData(ymd, tq, main_index, main_inc, tle_index, tle_inc, inv_index, inv_inc, self_index, self_inc, hotc, eb, 
    fin_index, finance_value, margin_index, margin_value, dt, brocount, mf, dtf, to) {
    return { ymd, tq, main_index, main_inc, tle_index, tle_inc, inv_index, inv_inc, self_index, self_inc, hotc, eb, 
        fin_index, finance_value, margin_index, margin_value, dt, brocount, mf, dtf, to };
}


const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    button1: {
      minWidth: 110,
    }
  }));

export default function BigCusListD11(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    useEffect(() => {
        if (props.open) fectchStockData(props.stockId)
    }, [props.stockId]);


    function fectchStockData(stockId) {
        if (stockId != null) {
            let a = {
                sid: stockId,
                inp: {}
            };

            if(localStorage.getItem('lms') != null && localStorage.getItem('lms') != 12)
                a.inp["lms"] = parseInt(localStorage.getItem('lms'))
            if(localStorage.getItem('sms') != null && localStorage.getItem('sms') != 8)
                a.inp["sms"] = parseInt(localStorage.getItem('sms'))

            fetch("/jstock/v1/stock/moneylist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let dd = result.map(row =>
                            createData(
                                moment(row.ymd, "YYYYMMDD").format('YYYY/MM/DD'),
                                row.mr.tq, row.mr.main_index, row.mr.main_inc,
                                row.mr.tle_index, row.mr.tle_inc,
                                row.mr.inv_index, row.mr.inv_inc,
                                row.mr.self_index, row.mr.self_inc,
                                row.mr.hotc,
                                row.mr.eb,
                                row.mr.fin_index, row.mr.finance_value,
                                row.mr.margin_index, row.mr.margin_value,
                                row.mr.dt,
                                row.mr.brocount,
                                row.mr.mf, row.mr.dtr, row.mr.to
                                )
                        )
                        setBuyselldata(dd)
                    },
                    error => {
                    }
                );
        }
    }

    const renderInpriceStatusV = (status, value) => {
        if (status === -1) {
            return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        } else if (status === 4) {
            return <Button size="small" variant="contained"  style={{ whiteSpace: "nowrap", minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                大賣{value}張
          </Button>
        } else if (status === 3) {
            return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
                小賣{value}張
        </Button>
        } else if (status === 0) {
            return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                中立{value}張
          </Button>
        } else if (status === 1) {
            return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
                小買{value}張
        </Button>
        } else if (status === 2) {
            return <Button size="small" variant="contained" style={{ whiteSpace: "nowrap", minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                大買{value}張
        </Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }

    const showCount1 = (value, unit) => {

        if (value === -99999) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
            </Button>
        } else if (value > 0) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
            買進 {value}{unit}
          </Button>
        } else if (value === 0) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
            持平 {value}{unit}
          </Button>
        } else if (value < 0) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
            賣出 {value}{unit}
          </Button>
        } else {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
      </Button>
        }
      }
    
      const renderInpriceStatusV1 = (status, value) => {
        if (status === -1) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        } else if (status === 4) {
          return <Button size="small"  variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
            銳減{value}張
          </Button>
        } else if (status === 3) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
            減少{value}張
        </Button>
        } else if (status === 0) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
            中立{value}張
          </Button>
        } else if (status === 1) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
            增加{value}張
        </Button>
        } else if (status === 2) {
          return <Button size="small"  variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
            激增{value}張
        </Button>
        } else {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        }
      }
    
    
      const renderInpriceStatusV2 = (status, value) => {
        if (status === -1) {
          return <Button size="small" variant="outlined"  style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        } else if (status === 4) {
          return <Button size="small"  variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
            銳減{value}張
          </Button>
        } else if (status === 3) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
            減少{value}張
        </Button>
        } else if (status === 0) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
            中立{value}張
          </Button>
        } else if (status === 1) {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
            增加{value}張
        </Button>
        } else if (status === 2) {
          return <Button size="small"  variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
            激增{value}張
        </Button>
        } else {
          return <Button size="small"  variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        }
      }
    

      const renderInpriceStatusV3 = (value) => {
        if (value === -99999) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        } else if (value < 0) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
            減少{value}張
        </Button>
        } else if (value === 0) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
            中立{value}張
          </Button>
        } else if (value > 1) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
            增加{value}張
        </Button>
        } else {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        }
      }
      

      const showBalance = (value) => {
        if (value == -1) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1}>
            -
          </Button>
        } else if (value >= 30) {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
            {value}%
          </Button>
        } else {
          return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
            {value}%
        </Button>
        }
      }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title" fullScreen >
            <DialogTitle id="form-dialog-title">{props.name}({props.stockId})籌碼日報表</DialogTitle>

            <DialogContent>
                <TableContainer component={Paper}>

                    <Table className={classes.table} aria-label="customized table" size="small">
                        {/* <caption>每列的資料不包含日期區間的起始日</caption> */}
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="left">成交量</StyledTableCell>
                                <StyledTableCell align="left">主力</StyledTableCell>
                                {/* <StyledTableCell align="left">外資</StyledTableCell>
                                <StyledTableCell align="left">投信</StyledTableCell>
                                <StyledTableCell align="left">自營商</StyledTableCell>
                                <StyledTableCell align="left">中實戶</StyledTableCell>
                                <StyledTableCell align="left">八大官股</StyledTableCell> */}
                                <StyledTableCell align="left">融資</StyledTableCell>
                                <StyledTableCell align="left">融券</StyledTableCell>
                                
                                <StyledTableCell align="left">券資比</StyledTableCell>

                                <StyledTableCell align="left">借券賣</StyledTableCell>
                                <StyledTableCell align="left">當沖</StyledTableCell>

                                <StyledTableCell align="left">當沖率</StyledTableCell>
                                <StyledTableCell align="left">周轉率</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.ymd}>
                                    <StyledTableCell component="th" scope="row" style={{ whiteSpace: "nowrap", }}>
                                        {row.ymd}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" >{row.tq}張</StyledTableCell>
                                    <StyledTableCell align="left" >{renderInpriceStatusV(row.main_index, row.main_inc)}</StyledTableCell>
                                    {/* <StyledTableCell align="left" >{renderInpriceStatusV(row.tle_index, row.tle_inc)}</StyledTableCell>
                                    <StyledTableCell align="left" >{renderInpriceStatusV(row.inv_index, row.inv_inc)}</StyledTableCell>
                                    <StyledTableCell align="left" >{renderInpriceStatusV(row.self_index, row.self_inc)}</StyledTableCell>
                                    <StyledTableCell align="left" >{showCount1(row.hotc, '張')}</StyledTableCell>
                                    <StyledTableCell align="left" >{showCount1(row.eb, '張')}</StyledTableCell> */}
                                    <StyledTableCell align="left" >{renderInpriceStatusV1(row.fin_index, row.finance_value)}</StyledTableCell>
                                    <StyledTableCell align="left" >{renderInpriceStatusV2(row.margin_index, row.margin_value)}</StyledTableCell>
                                    <StyledTableCell align="left" >{showBalance(row.mf)}</StyledTableCell>
                                    <StyledTableCell align="left" >{renderInpriceStatusV3(row.brocount)}</StyledTableCell>
                                    <StyledTableCell align="left" >{row.dt != -99999 ? row.dt + '張': '-'}</StyledTableCell>
                                    <StyledTableCell align="left" >{showBalance(row.dtf)}</StyledTableCell>
                                    <StyledTableCell align="left" >{showBalance(row.to)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
