import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);



const useStyles = makeStyles({
    table: {
        minWidth: 250,
        overflow: 'scroll',
    },
    button1: {
        // minWidth: 110,
    }
});

export default function ROIDetail(props) {
    const classes = useStyles();
    const [options, setOptions] = React.useState(null)

    useEffect(() => {
        if (props.open) fectchStockData(props.id)
    }, [props.id, props.open]);


    function fectchStockData(id) {
        let lic = localStorage.getItem('lic')

        if (lic != null && id != null) {
            let a = {
                lic: lic,
                id: id
            };

            fetch("/jstock/v1/stock/backteststocklist1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setOptions(getDefaultAChartOption(result))
                    },
                    error => {
                    }
                );
        }
    }


    function getDefaultAChartOption(result) {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '報酬率(%)'
                },
                {
                    left: 'center',
                    text: '累積盈虧(元)',
                    top: '350',
                },
                {
                    left: 'center',
                    text: '買進交易(次)',
                    top: '700',
                },
                {
                    left: 'center',
                    text: '賣出交易(次)',
                    top: '1050',
                },
                {
                    left: 'center',
                    text: '持有期間(筆)',
                    top: '1400',
                },
            ],           
            grid: [
                {
                    top: '40',
                    left: '50',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '390',
                    left: '50',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '740',
                    left: '50',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1090',
                    left: '50',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                } ,
                {
                    top: '1440',
                    left: '50',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }                
            ],
            yAxis: [
                {
                    show: true,
                    gridIndex: 0,
                    scale: true,
                    type: 'value'
                },
                {
                    show: true,
                    gridIndex: 1,
                    scale: true,
                    type: 'value',
                    axisLabel: {
                        formatter: function (value, index) {
                            return (value / 1000) + 'K'
                        }
                    }   
                },
                {
                    show: true,
                    gridIndex: 2,
                    scale: true,
                    type: 'value'
                },
                {
                    show: true,
                    gridIndex: 3,
                    scale: true,
                    type: 'value'
                },
                {
                    show: true,
                    gridIndex: 4,
                    scale: true,
                    type: 'value'
                }
            ],
            visualMap: [{
                show: false,
                type: 'piecewise',
                seriesIndex: 0,
                min: -9000,
                max: 9000,
                pieces: [
                    { min: -9000, max: 0, color: '#78b7c2' },
                    { min: 0, max: 9000, color: "#ffaf38" },
                ]
            },
            {
                show: false,
                type: 'continuous',
                seriesIndex: 1,
                color: ['#d45b6f', '#68a66e'],
            }],
        };

        let x1 = {
            gridIndex: 0,
            scale: true,
            type: 'category',
            data: result.b != null ? result.b.map(v => { return v.ym }): [],
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data:  result.b != null ? result.b.map(v => { return v.ym }) : [],
         
        }

        let x3 = {
            gridIndex: 2,
            scale: true,
            type: 'category',
            data:  result.b != null ? result.b.map(v => { return v.ym }): [],
        }

        let x4 = {
            gridIndex: 3,
            scale: true,
            type: 'category',
            data:  result.b != null ? result.b.map(v => { return v.ym }): [],
        }


        let x5 = {
            gridIndex: 4,
            scale: true,
            type: 'category',
            data: result.a != null ?result.a.map(v => { return v.x }): [],
        }

        if (props.ismobile) {
            let k = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = k
            x2['axisPointer'] = k
            x3['axisPointer'] = k
            x4['axisPointer'] = k
            x5['axisPointer'] = k
        }

        option['xAxis'] = [x1, x2, x3, x4, x5]

        if (result != null && result.b != null) {
            option['series'] = [
                {
                    name: '報酬率',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.b.map(v => { return v.roi }),
                    symbol: 'none',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                },
                {
                    name: '累積盈虧',
                    type: 'line',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.b.map(v => { return v.profit }),
                    symbol: 'none',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                },
                {
                    name: '買進交易次數',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.b.map(v => { return v.buyc }),
                    symbol: 'none',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                },
                {
                    name: '賣出交易次數',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.b.map(v => { return v.sellc }),
                    symbol: 'none',
                    xAxisIndex: 3,
                    yAxisIndex: 3,
                },
                {
                    name: '持有時間',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.a.map(v => { return v.y }),
                    symbol: 'none',
                    xAxisIndex: 4,
                    yAxisIndex: 4,
                },
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: [0, 1, 2, 3] },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: [0, 1, 2, 3] },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }
        }

        return option
    }


    return (
        <div>
            <Dialog fullScreen open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogContent style={{ }}>
                    {options != null ? <ReactEChartsCore
                        style={{ height: 1800, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : null}                    

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
