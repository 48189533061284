import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CCISetting(props) {
    const yref = React.createRef();
    const hrref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('dma1', parseInt(yref.current.value));

        if (hrref.current.value != null && hrref.current.value != "")
            localStorage.setItem('dma2', parseInt(hrref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('dma1') === null ? 10 : localStorage.getItem('dma1')
    }

    function hR() {
        return localStorage.getItem('dma2') === null ? 50 : localStorage.getItem('dma2')
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">參數設定</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="DMA短天期(預設:10)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="hr"
                    label="DMA長天期(預設:50)"
                    type="text"
                    fullWidth
                    defaultValue={hR()}
                    inputRef={hrref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
                </Button>
                <Button onClick={handleOk} color="primary">
                    確定
                </Button>
            </DialogActions>
        </Dialog>
    );
}
