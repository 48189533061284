import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function IntSetting(props) {

  const hrref = React.createRef();
  const mrref = React.createRef();
  const lrref = React.createRef();

  const handleOk = () => {
    if(hrref.current.value === "")
    localStorage.removeItem("free_hr2")

    if(mrref.current.value === "")
    localStorage.removeItem("free_mr2")

    if(lrref.current.value === "")
    localStorage.removeItem("free_lr2")

    
    if(hrref.current.value != null && hrref.current.value != "")
    localStorage.setItem('free_hr2', parseFloat(hrref.current.value));

    if(mrref.current.value != null && mrref.current.value != "")
    localStorage.setItem('free_mr2', parseFloat(mrref.current.value));

    if(lrref.current.value != null && lrref.current.value != "")
    localStorage.setItem('free_lr2', parseFloat(lrref.current.value));

    props.handleOk()
  };


  function hR() {
    return localStorage.getItem('free_hr2') === null ?  "" : localStorage.getItem('free_hr2')
  }  

  function mR() {
    return localStorage.getItem('free_mr2') === null ?  "" : localStorage.getItem('free_mr2')
  }  

  function lR() {
    return localStorage.getItem('free_lr2') === null ?  "" : localStorage.getItem('free_lr2')
  } 

  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">市場本益比</DialogTitle>

        <DialogContent>
          <div style={{ fontSize: 12, color: '#b3b3b3' }}>
            藉由本益比的比較推算出市場價格。每日將所有股票的本益比排序後，從前高位數、中位數、低位數得到目前市場願意給予的高中低本益比，在與個別股票的近四季的EPS相乘得到價格。若發現價格偏低，建議進一步確認公司是否擁有高成長率，雙重確認可以確保公司被市場低估。若價格偏高，建議也進一步地確認是否因為伴隨著高成長率所以市場願意給予較高的本益比。<br /><br />
          </div>

          <div style={{ fontSize: 12, color: '#82bd98' }} >
            <div>公式</div>
            <div>1. 便宜價 = 市場上本益比低位數(80%) * 近四季EPS </div>
            <div>2. 合理價 = 市場上本益比中位數(50%) * 近四季EPS</div>
            <div>3. 昂貴價 = 市場上本益比高位數(20%) * 近四季EPS</div>
          </div>

          <br />
          <TextField
            margin="dense"
            id="hr"
            label="市場高本益比（預設：系統自行計算）"
            type="text"
            fullWidth
            defaultValue={hR()}
            inputRef={hrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="mr"
            label="市場中本益比（預設：系統自行計算）"
            type="text"
            fullWidth
            defaultValue={mR()}
            inputRef={mrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="lr"
            label="市場低本益比（預設：系統自行計算）"
            type="text"
            fullWidth
            defaultValue={lR()}
            inputRef={lrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        {/* <DialogContentText  style={{paddingLeft: 20, paddingRight:20}}>


          </DialogContentText> */}
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>
  );
}
