import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import zhtwLocale from "date-fns/locale/zh-TW";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker
} from '@material-ui/pickers';
import * as dayjs from 'dayjs'

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 10,
        backgroundColor: theme.palette.background.default,
    },
    margin: {
        // margin: theme.spacing(0.5),
    },
    textField: {
        width: 165,
    },
    textField1: {
        // width: 340,
    },
    title: {
        color: "#000000"
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateTran(props) {
    const classes = useStyles();

    const [tranType, setTranType] = React.useState(0);
    const [bs, setBS] = React.useState(0);
    const [buyDate, setBuyDate] = React.useState(null);
    const [buyTime, setBuyTime] = React.useState(null);
    const [bprice, setBprice] = React.useState("");
    const [bvolumn, setbvolumn] = React.useState("");
    const [cost, setcost] = React.useState("");
    const [inReason, setinReason] = React.useState("");
    const [msgopen, setMsgOpen] = React.useState(false);
    const [errmsg, setErrMsg] = React.useState(null);

    const handleBuyDate = (value) => {
        setBuyDate(value);
    };

    const handleBuyTime = (value) => {
        setBuyTime(value);
    };

    const saveReport = () => {
        let a = {
            "id": props.id,
            "id1": props.row.id,
            "tp": tranType,
            "bs": bs
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (buyDate != null && buyDate != "")
            a["ymd"] = dayjs(buyDate).format('YYYYMMDD')
        if (buyTime != null && buyTime != "")
            a["ts"] = dayjs(buyTime).format('HH:mm')
        if (bprice != null && bprice != "")
            a["p"] = bprice
        if (bvolumn != null && bvolumn != "")
            a["num"] = bvolumn
        if (cost != null && cost != "")
            a["cost"] = cost
        if (inReason != null && inReason != "")
            a["memo"] = inReason

        fetch("/jstock/v1/stock/treport1/updatetran", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {


                        props.close()
                    } else if (result.status === -3) {
                        setErrMsg("交易日誌不存在，請重新新增交易日誌。")
                        setMsgOpen(true)
                    } else if (result.status === -2) {
                        setErrMsg("訂閱專屬功能。")
                        setMsgOpen(true)
                    } else {
                        setErrMsg("發生錯誤，請稍後嘗試")
                        setMsgOpen(true)
                    }
                }
            );
    }

    const handleMsgClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrMsg(null)
        setMsgOpen(false);
    };

    useEffect(() => {
        if (props.row != null) {
            setTranType(props.row.tp)
            setBS(props.row.bs)
            setBuyDate(dayjs(props.row.ymd))
            setBuyTime(dayjs(props.row.ymd + ' ' + props.row.ts))
            setBprice(props.row.p)
            setbvolumn(props.row.num)
            setcost(props.row.cost)
            setinReason(props.row.memo)
        }
    }, [props.row]);


    return (
        <Dialog fullWidth={true}
            maxWidth={'lg'} open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <DialogTitle id="simple-dialog-title">修改交易明細</DialogTitle>
            <DialogContent dividers style={{ paddingLeft: 15, paddingRight: 15 }} >
                <Snackbar open={msgopen} autoHideDuration={6000} onClose={handleMsgClose}>
                    <Alert onClose={handleMsgClose} severity="error">
                        {errmsg}
                    </Alert>
                </Snackbar>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhtwLocale}>
                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={4}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="yyyyMMdd"
                                // margin="normal"
                                id="buyDate"
                                label="成交日期"
                                value={buyDate}
                                onChange={handleBuyDate}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <KeyboardTimePicker
                                id="time-picker"
                                label="成交時間"
                                id="buyTime"
                                format="HH:mm"
                                value={buyTime}
                                onChange={handleBuyTime}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="交易類別"
                                id="trantype"
                                required
                                value={tranType}
                                fullWidth={true}
                                onChange={(event) => setTranType(event.target.value)}
                                select
                            >
                                <MenuItem key={'0'} value={"0"}>現股</MenuItem>
                                <MenuItem key={'1'} value={"1"}>融資</MenuItem>
                                <MenuItem key={'2'} value={"2"}>融券</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="交易買賣"
                                id="transell"
                                required
                                value={bs}
                                fullWidth={true}
                                onChange={(event) => setBS(event.target.value)}
                                select
                            >
                                <MenuItem key={'0'} value={0}>買進</MenuItem>
                                <MenuItem key={'1'} value={1}>賣出</MenuItem>
                            </TextField>
                        </Grid>



                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                label="成交價格"
                                id="stockprice"
                                value={bprice}
                                fullWidth={true}
                                onChange={event => setBprice(event.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                required
                                label="成交股數"
                                id="stocknum"
                                fullWidth={true}
                                value={bvolumn}
                                onChange={event => setbvolumn(event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment style={{ paddingRight: 15 }} position="end">股</InputAdornment>
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4}>
                            <TextField
                                label="交割金額"
                                id="amount"
                                value={cost}
                                onChange={(event) => setcost(event.target.value)}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="inReason"
                                label="交易備註"
                                placeholder=""
                                helperText=""
                                fullWidth={true}
                                value={inReason}
                                onChange={event => setinReason(event.target.value)}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>

                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close} color="primary">
                    關閉
                </Button>
                <Button onClick={saveReport} color="primary">
                    更新
                </Button>
            </DialogActions>
        </Dialog>
    );
}
