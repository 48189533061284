import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactECharts from 'echarts-for-react';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';

export default function ShapeDashboard1(props) {
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [period, setPeriod] = React.useState('60');
    const [similar, setSimilar] = React.useState('80');

    const [options1, setOptions1] = React.useState(null)
    const [period1, setPeriod1] = React.useState('60');
    const [similar1, setSimilar1] = React.useState('80');

    const [options2, setOptions2] = React.useState(null)
    const [period2, setPeriod2] = React.useState('60');
    const [similar2, setSimilar2] = React.useState('80');


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [al, setAl] = React.useState([])
    const lines = React.useRef([]);
    const mouseAction = React.useRef(null);
    const echartRef = React.useRef(null);


    const [al1, setAl1] = React.useState([])
    const lines1 = React.useRef([]);
    const mouseAction1 = React.useRef(null);
    const echartRef1 = React.useRef(null);

    const [al2, setAl2] = React.useState([])
    const lines2 = React.useRef([]);
    const mouseAction2 = React.useRef(null);
    const echartRef2 = React.useRef(null);

    function getDefaultOption() {
        let option = {
            animation: false,
            legend: {
                show: false
            },
            grid: [
                {
                    top: '30',
                    left: '0',
                    right: '0',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        return option
    }

    const getKeys = () => {
        if (period != null && period != "") {
            return [...Array(parseInt(period)).keys()]
        } else return null
    }

    const getKeys1 = () => {
        if (period1 != null && period1 != "") {
            return [...Array(parseInt(period1)).keys()]
        } else return null
    }

    const getKeys2 = () => {
        if (period2 != null && period2 != "") {
            return [...Array(parseInt(period2)).keys()]
        } else return null
    }



    function genoption() {
        let k = getDefaultOption()

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getKeys(),
            axisLine: { onZero: false },
            splitLine: { show: false },
        }


        k['xAxis'] = [x1]

        let yAxis = [{
            scale: true,
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            z: 100,
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }
        ]
        k['yAxis'] = yAxis

        let markdata = []
        if (al != null && al.length > 0) {
            al.map(l => {
                if (l != null && l.length == 4)
                    markdata.push([
                        { x: l[0], y: l[1] },
                        { x: l[2], y: l[3] }
                    ])
            })
        }

        let serdata = [
            {
                name: "a",
                type: 'line',
                data: [],
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false,
                markLine: {
                    lineStyle: {
                        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF",
                        type: 'solid',
                        width: 2
                    },
                    symbol: ['none', 'none'],
                    data: markdata,

                },
            }
        ]

        k['series'] = serdata
        setOptions(k)
    }

    function genoption1() {
        let k = getDefaultOption()

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getKeys1(),
            axisLine: { onZero: false },
            splitLine: { show: false },
        }


        k['xAxis'] = [x1]

        let yAxis = [{
            scale: true,
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            z: 100,
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }
        ]
        k['yAxis'] = yAxis

        let markdata = []
        if (al1 != null && al1.length > 0) {
            al1.map(l => {
                if (l != null && l.length == 4)
                    markdata.push([
                        { x: l[0], y: l[1] },
                        { x: l[2], y: l[3] }
                    ])
            })
        }

        let serdata = [
            {
                name: "a",
                type: 'line',
                data: [],
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false,
                markLine: {
                    lineStyle: {
                        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF",
                        type: 'solid',
                        width: 2
                    },
                    symbol: ['none', 'none'],
                    data: markdata,

                },
            }
        ]

        k['series'] = serdata
        setOptions1(k)
    }

    function genoption2() {
        let k = getDefaultOption()

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getKeys2(),
            axisLine: { onZero: false },
            splitLine: { show: false },
        }


        k['xAxis'] = [x1]

        let yAxis = [{
            scale: true,
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            z: 100,
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }
        ]
        k['yAxis'] = yAxis

        let markdata = []
        if (al2 != null && al2.length > 0) {
            al2.map(l => {
                if (l != null && l.length == 4)
                    markdata.push([
                        { x: l[0], y: l[1] },
                        { x: l[2], y: l[3] }
                    ])
            })
        }

        let serdata = [
            {
                name: "a",
                type: 'line',
                data: [],
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false,
                markLine: {
                    lineStyle: {
                        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF",
                        type: 'solid',
                        width: 2
                    },
                    symbol: ['none', 'none'],
                    data: markdata,

                },
            }
        ]

        k['series'] = serdata
        setOptions2(k)
    }



    const chartReady = (event) => {
        let zr = event.getZr()
        zr.on('mousedown', onMouseDownClick)
        zr.on('mouseup', onMouseUpClick)
        zr.on('mousemove', onMouseMoveClick)
    }

    const chartReady1 = (event) => {
        let zr = event.getZr()
        zr.on('mousedown', onMouseDownClick1)
        zr.on('mouseup', onMouseUpClick1)
        zr.on('mousemove', onMouseMoveClick1)
    }

    const chartReady2 = (event) => {
        let zr = event.getZr()
        zr.on('mousedown', onMouseDownClick2)
        zr.on('mouseup', onMouseUpClick2)
        zr.on('mousemove', onMouseMoveClick2)
    }

    const onMouseDownClick = (...rest) => {
        mouseAction.current = "down"
        if (rest != null && rest.length > 0) {
            const newLines = [...lines.current];

            newLines.push(
                [parseInt(rest[0].offsetX), parseInt(rest[0].offsetY),
                parseInt(rest[0].offsetX), parseInt(rest[0].offsetY)]
            )
            lines.current = newLines
            setAl(newLines)
        }
    };

    const onMouseDownClick1 = (...rest) => {
        mouseAction1.current = "down"
        if (rest != null && rest.length > 0) {
            const newLines = [...lines1.current];

            newLines.push(
                [parseInt(rest[0].offsetX), parseInt(rest[0].offsetY),
                parseInt(rest[0].offsetX), parseInt(rest[0].offsetY)]
            )
            lines1.current = newLines
            setAl1(newLines)
        }
    };

    const onMouseDownClick2 = (...rest) => {
        mouseAction2.current = "down"
        if (rest != null && rest.length > 0) {
            const newLines = [...lines2.current];

            newLines.push(
                [parseInt(rest[0].offsetX), parseInt(rest[0].offsetY),
                parseInt(rest[0].offsetX), parseInt(rest[0].offsetY)]
            )
            lines2.current = newLines
            setAl2(newLines)
        }
    };


    const onMouseUpClick = (...rest) => {
        mouseAction.current = "up"

        const newLines = [...lines.current];

        if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
            let originX = newLines[newLines.length - 1][0]
            let originY = newLines[newLines.length - 1][1]
            newLines.splice(newLines.length - 1, 1);
            newLines.push(
                [originX, originY,
                    parseInt(rest[0].offsetX),
                    parseInt(rest[0].offsetY)]
            )
            lines.current = newLines
            setAl(newLines)
        }
    };

    const onMouseUpClick1 = (...rest) => {
        mouseAction1.current = "up"

        const newLines = [...lines1.current];

        if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
            let originX = newLines[newLines.length - 1][0]
            let originY = newLines[newLines.length - 1][1]
            newLines.splice(newLines.length - 1, 1);
            newLines.push(
                [originX, originY,
                    parseInt(rest[0].offsetX),
                    parseInt(rest[0].offsetY)]
            )
            lines1.current = newLines
            setAl1(newLines)
        }
    };


    const onMouseUpClick2 = (...rest) => {
        mouseAction2.current = "up"

        const newLines = [...lines2.current];

        if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
            let originX = newLines[newLines.length - 1][0]
            let originY = newLines[newLines.length - 1][1]
            newLines.splice(newLines.length - 1, 1);
            newLines.push(
                [originX, originY,
                    parseInt(rest[0].offsetX),
                    parseInt(rest[0].offsetY)]
            )
            lines2.current = newLines
            setAl2(newLines)
        }
    };


    const onMouseMoveClick = (...rest) => {
        if (mouseAction.current === "down") {
            const newLines = [...lines.current];

            if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
                let originX = newLines[newLines.length - 1][0]
                let originY = newLines[newLines.length - 1][1]
                newLines.splice(newLines.length - 1, 1);
                newLines.push(
                    [originX, originY,
                        parseInt(rest[0].offsetX),
                        parseInt(rest[0].offsetY)]
                )
                lines.current = newLines
                setAl(newLines)


            }
        }
    };

    const onMouseMoveClick1 = (...rest) => {
        if (mouseAction1.current === "down") {
            const newLines = [...lines1.current];

            if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
                let originX = newLines[newLines.length - 1][0]
                let originY = newLines[newLines.length - 1][1]
                newLines.splice(newLines.length - 1, 1);
                newLines.push(
                    [originX, originY,
                        parseInt(rest[0].offsetX),
                        parseInt(rest[0].offsetY)]
                )
                lines1.current = newLines
                setAl1(newLines)
            }
        }
    };

    const onMouseMoveClick2 = (...rest) => {
        if (mouseAction2.current === "down") {
            const newLines = [...lines2.current];

            if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
                let originX = newLines[newLines.length - 1][0]
                let originY = newLines[newLines.length - 1][1]
                newLines.splice(newLines.length - 1, 1);
                newLines.push(
                    [originX, originY,
                        parseInt(rest[0].offsetX),
                        parseInt(rest[0].offsetY)]
                )
                lines2.current = newLines
                setAl2(newLines)


            }
        }
    };

    useEffect(() => {
        genoption()
    }, [al, period]);

    useEffect(() => {
        genoption1()
    }, [al1, period1]);

    useEffect(() => {
        genoption2()
    }, [al2, period2]);

    useEffect(() => {
        let rule = getRule("3000")
        if (rule != null && rule.aa != null) {
            lines.current = rule.aa
            setAl(rule.aa)
            setPeriod(rule.value3)
            setSimilar(rule.value4)
        }

        let rule1 = getRule("3001")
        if (rule1 != null && rule1.aa != null) {
            lines1.current = rule1.aa
            setAl1(rule1.aa)
            setPeriod1(rule1.value3)
            setSimilar1(rule1.value4)
        }

        let rule2 = getRule("3002")
        if (rule2 != null && rule2.aa != null) {
            lines2.current = rule2.aa
            setAl2(rule2.aa)
            setPeriod2(rule2.value3)
            setSimilar2(rule2.value4)
        }
    }, [props.rules]);

    const getRule = (rid) => {
        if (props.rules != undefined) {
            const findId = id => (element, index, array) => {
                return element.id === id;
            }

            let rule = props.rules.find(findId(rid))
            return rule;
        } else {
            return null
        }
    }

    const clearDraw = () => {
        if (value === 0) {
            lines.current = []
            setAl([])
            genoption()
            if (props.onRemoveItem != null) props.onRemoveItem("3000")
        } else if (value === 1) {
            lines1.current = []
            setAl1([])
            genoption1()
            if (props.onRemoveItem != null) props.onRemoveItem("3001")
        } else if (value === 2) {
            lines2.current = []
            setAl2([])
            genoption2()
            if (props.onRemoveItem != null) props.onRemoveItem("3002")
        }
    }


    const search = () => {
        let lic = localStorage.getItem('lic')
        let aa = []
        if (props.lockScreen != null) props.lockScreen(false)

        if (lic != null) {
            let width = 0
            let height = 0

            if (value === 0 && echartRef.current != null) {
                const echartInstance = echartRef.current.getEchartsInstance()
                width = echartInstance.getWidth()
                height = echartInstance.getHeight()
            }

            if (value === 1 && echartRef1.current != null) {
                const echartInstance = echartRef1.current.getEchartsInstance()
                width = echartInstance.getWidth()
                height = echartInstance.getHeight()
            }

            if (value === 2 && echartRef2.current != null) {
                const echartInstance = echartRef2.current.getEchartsInstance()
                width = echartInstance.getWidth()
                height = echartInstance.getHeight()
            }

            if (al.length > 0) {

                let a = {
                    id: "3000",
                    aa: al,
                    value1: height,
                    value2: width,
                    value3: period,
                    value4: similar
                }
                aa.push(a)
            }

            if (al1.length > 0) {
                let a = {
                    id: "3001",
                    aa: al1,
                    value1: height,
                    value2: width,
                    value3: period1,
                    value4: similar1
                }
                aa.push(a)
            }

            if (al2.length > 0) {
                let a = {
                    id: "3002",
                    aa: al2,
                    value1: height,
                    value2: width,
                    value3: period2,
                    value4: similar2
                }
                aa.push(a)
            }

            props.updateRule("3000", aa, true)
        }
    }

    const lock = () => {
        if (props.lockScreen != null) props.lockScreen(!props.lockScroll)
    }

    return (
        <>
            <Box display={"flex"}>
                <Tabs style={{flexGrow:1}} value={value} onChange={handleChange} textColor="primary" indicatorColor='primary' >
                    <Tab label="日型態" id="0" />
                    <Tab label="周型態" id="1" />
                    <Tab label="月型態" id="2" />
                </Tabs>
                {props.saveRule1 != null && <Button  size="small" style={{ verticalAlign: "bottom" }} disableElevation variant="contained" onClick={(e) => props.saveRule1()}>儲存</Button>}
            </Box>
            {value == 0 && options != null && <>
                <Box display={"flex"} style={{ marginTop: 16 }}>
                    <TextField
                        size="small"
                        id="outlined-controlled"
                        label="天數"
                        variant="outlined"
                        value={period}
                        onChange={(event) => {
                            setPeriod(event.target.value);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="start">天</InputAdornment>,
                        }}
                    />
                    <TextField
                        size="small"
                        id="outlined-controlled"
                        label="相似度"
                        value={similar}
                        variant="outlined"
                        fullWidth
                        style={{ marginLeft: 2 }}
                        onChange={(event) => {
                            setSimilar(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />

                </Box>
                <ReactECharts
                    ref={(e) => { echartRef.current = e; }}
                    theme={theme.palette.type}
                    option={options}
                    notMerge={true}
                    lazyUpdate={true}
                    onChartReady={chartReady}
                /></>
            }


            {value == 1 && options1 != null && <>
                <Box display={"flex"} style={{ marginTop: 16 }}>
                    <TextField
                        size="small"
                        id="outlined-controlled1"
                        label="周數"
                        variant="outlined"
                        value={period1}
                        onChange={(event) => {
                            setPeriod1(event.target.value);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="start">周</InputAdornment>,
                        }}
                    />
                    <TextField
                        size="small"
                        id="outlined-controlled1"
                        label="相似度"
                        value={similar1}
                        variant="outlined"
                        fullWidth
                        style={{ marginLeft: 2 }}
                        onChange={(event) => {
                            setSimilar1(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />

                </Box>
                <ReactECharts
                    ref={(e) => { echartRef1.current = e; }}
                    theme={theme.palette.type}
                    option={options1}
                    notMerge={true}
                    lazyUpdate={true}
                    onChartReady={chartReady1}
                /></>
            }


            {value == 2 && options2 != null && <>
                <Box display={"flex"} style={{ marginTop: 16 }}>
                    <TextField
                        size="small"
                        id="outlined-controlled2"
                        label="月數"
                        variant="outlined"
                        value={period2}
                        onChange={(event) => {
                            setPeriod2(event.target.value);
                        }}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="start">月</InputAdornment>,
                        }}
                    />
                    <TextField
                        size="small"
                        id="outlined-controlled2"
                        label="相似度"
                        value={similar2}
                        variant="outlined"
                        fullWidth
                        style={{ marginLeft: 2 }}
                        onChange={(event) => {
                            setSimilar2(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />

                </Box>
                <ReactECharts
                    ref={(e) => { echartRef2.current = e; }}
                    theme={theme.palette.type}
                    option={options2}
                    notMerge={true}
                    lazyUpdate={true}
                    onChartReady={chartReady2}
                /></>
            }


            <Grid container spacing={1} style={{ marginTop: 5 }}>
                {/* <Grid item xs={6} sm>

                </Grid>
                <Grid item xs={6} sm>

                </Grid> */}
                <Grid item xs sm>
                    <Button fullWidth size="medium" style={{ marginLeft: 2, verticalAlign: "bottom" }} disableElevation variant="contained" onClick={(e) => clearDraw()}>清除</Button>
                </Grid>
                <Grid item xs sm>
                    <Button fullWidth size="medium" style={{ verticalAlign: "bottom" }} disableElevation variant="contained" onClick={(e) => search()}>搜尋</Button>
                </Grid>
                {props.lockScreen != null && <Grid item xs sm>
                    <Button fullWidth size="medium" style={{ verticalAlign: "bottom" }} color={props.lockScroll?"primary":"default"} disableElevation variant="contained" onClick={(e) => lock()}>{props.lockScroll? "解除":"鎖定"}</Button>
                </Grid>}
                {/* <Grid item xs sm>
                    {props.saveRule1 != null && <Button fullWidth size="medium" style={{ verticalAlign: "bottom" }} disableElevation variant="contained" onClick={(e) => props.saveRule1()}>儲存</Button>}
                </Grid> */}
            </Grid>

        </>
    );
}
