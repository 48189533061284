import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

echarts.use(
    [ScatterChart, AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


export default function InterestAllDetail(props) {
    const classes = useStyles();
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState(null)
    const theme = useTheme();

    function getOption() {
        let data1 = data.slice().reverse();
        let newOption = {
            animation: false,
            legend: {
                data: ['現金', '股票', '會計', '還原'],
                bottom: 0,
            },            
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '40',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        newOption['yAxis'] = [
            {
                scale: true,
                splitArea: {
                    show: false
                },
                show: true,
                position: 'left',
                splitLine: {
                    show: true
                },
            }
        ]

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data1.map(v => { if (v.s2.meetdate != "") return v.s2.meetdate; else return v.s2.meetdate1 }),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        newOption['xAxis'] = [x1]

        newOption['series'] = [
            {
                name: '現金',
                type: 'line',
                data: data1.map(v => v.v1),
                showSymbol: true,
            }, {
                name: '股票',
                type: 'line',
                data: data1.map(v => v.v2),
                showSymbol: true,
            }, {
                name: '會計',
                type: 'line',
                data: data1.map(v => v.v3),
                showSymbol: true,
            }, {
                name: '還原',
                type: 'line',
                data: data1.map(v => v.v4),
                showSymbol: true,
            }
        ]

        return newOption
    }



    const getPHV1 = () => {
        setLoading(true)

        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
            sid: props.row.market_company.stock_no
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/anoint", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setData(result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }

    useEffect(() => {
        if (data != null) setOptions(getOption())
    }, [data]);

    useEffect(() => {
        getPHV1()
    }, [props.row]);

    return (
        <div>
            <Dialog
                fullScreen
                open={props.open}
                onClose={e => props.setOpen(false)}
                scroll={"paper"}
            >
                <DialogTitle id="alert-dialog-title">{"殖利率圖與明細"}</DialogTitle>
                <DialogContent dividers={true} style={{ paddingTop: 0, padding: 0, paddingLeft: 5, paddingRight: 5, marginRight: 5, marginLeft: 5 }}>
                    
                    {options != null && <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 320, marginTop: 0, marginBottom: 20 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    />}




                    {data != null && data.length > 0 && <TableContainer component={Paper}>
                        <Table style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000", overflowX: 'scroll', width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} >董事會</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>股東會</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>會議</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>股利</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>現金殖利率</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>股票殖利率</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>會計殖利率</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>還原殖利率</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>盈餘分配率</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <StyledTableRow key={row.s2.meetdate}>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
                                            {row.s2.meetdate}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.s2.meetdate1}</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.s2.meeting}</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>現:{row.s2.cash}元/股:{row.s2.stock}元</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.v1}%</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.v2}%</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.v3}%</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.v4}%</StyledTableCell>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{row.s2.rate}%</StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}

                </DialogContent>
                <DialogActions>
                    <Button onClick={e => props.setOpen(false)} color="primary">
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
