import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function DuKong(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)

    function getXX() {
        if (props.row.dk != null && props.row.dk.a != null &&
            props.row.kd3.d.length >= props.row.dk.a.length) {
            let data = props.row.kd3.d.slice(-props.row.dk.a.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1() {
        if (props.row.dk != null && props.row.dk.a != null &&
            props.row.kd3.d.length >= props.row.dk.a.length) {

            let data = props.row.kd3.d.slice(-props.row.dk.a.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }


    function getDefaultAChartOption(data) {
        let option = {
            // legend: {
            //     data: ['昂貴區', '合理區', '便宜區', '股價',]
            // },
            grid: [
                {
                    top: '30',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '320',
                    left: '40',
                    right: '40',
                    //bottom: '0%',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    show: true,
                    gridIndex: 1,
                    scale: true,
                    type: 'value'
                }
            ],
            visualMap: {
                show: false,
                type: 'piecewise',
                seriesIndex: 1,
                min: -100,
                max: 100,
                pieces: [
                    { min: -100, max: 0, color: '#65b206' },
                    { min: 0, max: 100, color: "#d41c1c" },
                ]
            },
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.x }),
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }

            x2['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
        }

        option['xAxis'] = [x1, x2]

        if (data != null) {
            option['series'] = [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: getYY1(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: '串聯指標',
                    type: 'line',
                    color: "#ff7171",
                    areaStyle: {
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 'true'
                }
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 60,
                    end: 100
                }
            ]
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 60,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '580',
                    start: 60,
                    end: 100
                }
            ]
        }

        return option
    }


    useEffect(() => {
        if (props.row.dk != null) setOptions(getDefaultAChartOption(props.row.dk.a))
    }, [props.row.dk]);

    function getString() {
        return "目前無相關的串聯指標"
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            串聯指標
                        </Typography>
                    </div>
                    <div style={{ fontSize: 14, color: "#808080" }}>將股價,營收,EPS三大重要資料相互串連所產生的指標。
                        指標最大值為100，數值越大，意味著股價，營收，EPS都不斷的創新高。指標最低值為-100，數值越小，意味著這三項都不斷的創新低。
                        <br />
                        <li>買低賣高－例如:指標超過20買進，超過40賣出。</li>
                        <li>熱門強勢－例如:找出指標前10大。</li>
                        <li>翻身股－例如:指標由綠翻紅，並進入前100大。</li>
                    </div>


                    {props.row.dk != undefined  && options != null ? <ReactEChartsCore
                        style={{ height: 620, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}