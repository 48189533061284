import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import zhtwLocale from "date-fns/locale/zh-TW";
import Button from '@material-ui/core/Button';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import * as dayjs from 'dayjs'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 5,
        backgroundColor: theme.palette.background.default,
    },
    margin: {
        margin: theme.spacing(0.5),
    },
    textField: {
        width: 225,
    },
    title: {
        color: "#000000"
    },
}));


export default function TransReport() {
    const classes = useStyles();
    const [stock, setStock] = React.useState(null);
    const [buyDate, setBuyDate] = React.useState(null);
    const [buyTime, setBuyTime] = React.useState(null);
    const [bprice, setBprice] = React.useState(null);
    const [bvolumn, setbvolumn] = React.useState(null);
    const [cost, setcost] = React.useState(null);
    const [stopEarn, setstopEarn] = React.useState(null);
    const [stopLost, setstopLost] = React.useState(null);
    const [inReason, setinReason] = React.useState(null);
    const [sellDate, setsellDate] = React.useState(null);
    const [sellTime, setsellTime] = React.useState(null);
    const [sprice, setSprice] = React.useState(null);
    const [sVolumn, setsVolumn] = React.useState(null);
    const [settle, setsettle] = React.useState(null);
    const [outReason, setoutReason] = React.useState(null);
    const [winLost, setwinLost] = React.useState(null);
    const [total, settotal] = React.useState(null);
    const [roi, setroi] = React.useState(null);

    const handlesprice = (event) => {
        setSprice(event.target.value);
    }

    const handlestock = (event) => {
        setStock(event.target.value);
    }

    const handleroi = (event) => {
        setroi(event.target.value);
    }

    const handletotal = (event) => {
        settotal(event.target.value);
    }

    const handlewinLost = (event) => {
        setwinLost(event.target.value);
    }

    const handleoutReason = (event) => {
        setoutReason(event.target.value);
    }

    const handlesettle = (event) => {
        setsettle(event.target.value);
    }

    const handlesVolumn = (event) => {
        setsVolumn(event.target.value);
    }

    const handlesellTime = (event) => {
        setsellTime(event.target.value);
    }

    const handlesellDate = (value) => {
        setsellDate(value);
    }

    const handleinReason = (event) => {
        setinReason(event.target.value);
    };

    const handleStopLost = (event) => {
        setstopLost(event.target.value);
    };

    const handleBuyDate = (value) => {
        setBuyDate(value);
    };

    const handleBuyTime = (event) => {
        setBuyTime(event.target.value);
    };

    const handleBprice = (event) => {
        setBprice(event.target.value);
    };

    const handleBVolumn = (event) => {
        setbvolumn(event.target.value);
    };

    const handleCost = (event) => {
        setcost(event.target.value);
    };

    const handleStopEarn = (event) => {
        setstopEarn(event.target.value);
    };


    const saveReport = () => {
        let a = {
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (stock != null && stock != "")
            a["stock_no"] = stock
        if (buyDate != null && buyDate != "")
            a["buy_date"] = dayjs(buyDate).format('YYYY/MM/DD')
        if (buyTime != null && buyTime != "")
            a["buy_time"] = buyTime
        if (bprice != null && bprice != "")
            a["bprice"] = bprice
        if (bvolumn != null && bvolumn != "")
            a["b_volumn"] = bvolumn
        if (cost != null && cost != "")
            a["cost"] = cost
        if (stopEarn != null && stopEarn != "")
            a["stop_earn"] = stopEarn
        if (stopLost != null && stopLost != "")
            a["stop_lost"] = stopLost
        if (inReason != null && inReason != "")
            a["in_reason"] = inReason
        if (sellDate != null && sellDate != "")
            a["sell_date"] = dayjs(sellDate).format('YYYY/MM/DD')
        if (sellTime != null && sellTime != "")
            a["sell_time"] = sellTime
        if (sprice != null && sprice != "")
            a["sprice"] = sprice
        if (sVolumn != null && sVolumn != "")
            a["s_volumn"] = sVolumn
        if (settle != null && settle != "")
            a["settle"] = settle
        if (outReason != null && outReason != "")
            a["out_reason"] = outReason
        if (winLost != null && winLost != "")
            a["win_lost"] = winLost
        if (total != null && total != "")
            a["total"] = total
        if (roi != null && roi != "")
            a["roi"] = roi
          
        fetch("/jstock/v1/stock/treport/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                    }
                }
            );

    }


    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhtwLocale}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h4" className={classes.title}>
                            交易日誌
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.title}>
                            進場資訊
                    </Typography>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="buyDate"
                            label="日期"
                            value={buyDate}
                            onChange={handleBuyDate}
                        />
                        <TextField
                            label="時間"
                            id="buyTime"
                            value={buyTime}
                            onChange={handleBuyTime}
                        />

                        <TextField
                            label="股票代號/名稱"
                            id="stockname"
                            value={stock}
                            onChange={handlestock}
                        />
                        <TextField
                            label="進場價格"
                            id="stockprice"
                            value={bprice}
                            onChange={handleBprice}
                        />

                        <TextField
                            label="交易量（股）"
                            id="stocknum"
                            value={bvolumn}
                            onChange={handleBVolumn}
                        />

                        <TextField
                            label="總成本（含手續費等）"
                            id="amount"
                            value={cost}
                            onChange={handleCost}
                        />

                        <TextField
                            label="停利"
                            id="stopprofit"
                            value={stopEarn}
                            onChange={handleStopEarn}
                        />

                        <TextField
                            label="停損"
                            id="stoplost"
                            value={stopLost}
                            onChange={handleStopLost}
                        />
                        <TextField
                            id="inReason"
                            label="進場原因"
                            placeholder=""
                            helperText=""
                            fullWidth
                            value={inReason}
                            onChange={handleinReason}
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" className={classes.title}>
                            出場資訊
                        </Typography>

                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="sellDate"
                            label="日期"
                            value={sellDate}
                            onChange={handlesellDate}
                        />

                        <TextField
                            label="時間"
                            id="sellTime"
                            value={sellTime}
                            onChange={handlesellTime}
                        />
                        <TextField
                            label="出場價格"
                            id="sprice"
                            value={sprice}
                            onChange={handlesprice}
                            className={clsx(classes.margin, classes.textField)}
                        />

                        <TextField
                            label="交易量（股）"
                            id="sVolumn"
                            value={sVolumn}
                            onChange={handlesVolumn}
                        />

                        <TextField
                            label="交割金額（已扣稅）"
                            value={settle}
                            onChange={handlesettle}
                            id="settle"
                        />
                        <TextField
                            label="勝負"
                            id="winLost"
                            placeholder=""
                            value={winLost}
                            onChange={handlewinLost}
                        />

                        <TextField
                            label="結算損益"
                            id="total"
                            value={total}
                            onChange={handletotal}
                        />
                        <TextField
                            label="獲利率"
                            id="roi"
                            value={roi}
                            onChange={handleroi}
                        />
                        <TextField
                            id="outReason"
                            label="出場原因"
                            value={outReason}
                            onChange={handleoutReason}
                            placeholder=""
                            helperText=""
                            fullWidth
                            margin="normal"
                        />

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button onClick={saveReport}>新增</Button>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
}
