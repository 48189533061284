import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingUp from '@material-ui/icons/TrendingUp';
import ClearAll from '@material-ui/icons/ClearAll';
import Button from '@material-ui/core/Button';

import BPage from '../BPage'
import Rule675 from '../rules/Rule675';
import Rule676 from '../rules/Rule676';
import RuleTp4 from '../ruletp/RuleTp4';

import { SHAPE_1, SHAPE_2 } from '../ruletp/RuleData'
import {
    SHAPE_1_IDS, SHAPE_2_IDS
} from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'

import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';



const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
    },
    panelTitle: {
        marginLeft: 10,
    },
    panelTitle1: {
        marginLeft: 10,
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
})


class ShapeDashboard extends React.Component {
    state = {
        expanded: 'panelx',
    };

    handleOpen1 = () => {
        this.props.tabchange(BPage.donate_page)
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };


    render() {
        const { expanded } = this.state;
        const { classes } = this.props;

        const isShapeOn1 = this.props.rules.some(rule => SHAPE_1_IDS.includes(rule.id));
        const isShapeOn2 = this.props.rules.some(rule => SHAPE_2_IDS.includes(rule.id));

        return (
            <div className={classes.root}>
                <div>
                    {this.props.tabchange != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                            訂閱
                        </Button> : null
                    }

                    {this.props.saveRule1 != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.saveRule1()}>
                            儲存規則
                        </Button> : null
                    }


                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.clearRule()}>
                        清除規則
                    </Button>

                </div>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'SHAPEOn1'}
                    onChange={this.handleChange('SHAPEOn1')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isShapeOn1 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <TrendingUp color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>轉折波</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {SHAPE_1.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'SHAPEOn2'}
                    onChange={this.handleChange('SHAPEOn2')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isShapeOn2 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ClearAll color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>量價</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {SHAPE_2.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

export default withStyles(styles)(ShapeDashboard);