import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desccm(name, index, a, b, orderBy, colname) {
    if (orderBy === name && a.cb.length > index && b.cb.length > index) {
        if (b.cb[index][colname] < a.cb[index][colname]) {
            return -1;
        }
        if (b.cb[index][colname] > a.cb[index][colname]) {
            return 1;
        }
    }
    return 0;
}

function desc(a, b, orderBy) {
    let bid = desccm('bid', 0, a, b, orderBy, 'bid')
    let sname = desccm('sname', 0, a, b, orderBy, 'sname')
    let expiredays = desccm('expiredays', 0, a, b, orderBy, 'expiredays')
    let sprice = desccm('sprice', 0, a, b, orderBy, 'sprice')
    let cprice = desccm('cprice', 0, a, b, orderBy, 'cprice')
    let bprice = desccm('bprice', 0, a, b, orderBy, 'bprice')
    let gprice = desccm('gprice', 0, a, b, orderBy, 'gprice')
    let alreadyc = desccm('alreadyc', 0, a, b, orderBy, 'alreadyc')
    let guarant = desccm('guarant', 0, a, b, orderBy, 'guarant')

    let bid1 = desccm('bid2', 1, a, b, orderBy, 'bid')
    let sname1 = desccm('sname2', 1, a, b, orderBy, 'sname')
    let expiredays1 = desccm('expiredays2', 1, a, b, orderBy, 'expiredays')
    let sprice1 = desccm('sprice2', 1, a, b, orderBy, 'sprice')
    let cprice1 = desccm('cprice2', 1, a, b, orderBy, 'cprice')
    let bprice1 = desccm('bprice2', 1, a, b, orderBy, 'bprice')
    let gprice1 = desccm('gprice2', 1, a, b, orderBy, 'gprice')
    let alreadyc1 = desccm('alreadyc2', 1, a, b, orderBy, 'alreadyc')
    let guarant1 = desccm('guarant2', 1, a, b, orderBy, 'guarant')

    if (bid === -1 || bid === 1) {
        return bid;
    } else if (sname === -1 || sname === 1) {
        return sname;
    } else if (expiredays === -1 || expiredays === 1) {
        return expiredays;
    } else if (sprice === -1 || sprice === 1) {
        return sprice;
    } else if (cprice === -1 || cprice === 1) {
        return cprice;
    } else if (bprice === -1 || bprice === 1) {
        return bprice;
    } else if (gprice === -1 || gprice === 1) {
        return gprice;
    } else if (alreadyc === -1 || alreadyc === 1) {
        return alreadyc;
    } else if (guarant === -1 || guarant === 1) {
        return guarant;
    } else if (bid1 === -1 || bid1 === 1) {
        return bid1;
    } else if (sname1 === -1 || sname1 === 1) {
        return sname1;
    } else if (expiredays1 === -1 || expiredays1 === 1) {
        return expiredays1;
    } else if (sprice1 === -1 || sprice1 === 1) {
        return sprice1;
    } else if (cprice1 === -1 || cprice1 === 1) {
        return cprice1;
    } else if (bprice1 === -1 || bprice1 === 1) {
        return bprice1;
    } else if (gprice1 === -1 || gprice1 === 1) {
        return gprice1;
    } else if (alreadyc1 === -1 || alreadyc1 === 1) {
        return alreadyc1;
    } else if (guarant1 === -1 || guarant1 === 1) {
        return guarant1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'bid', numeric: false, disablePadding: false, label: '代碼', sid: 'bid' },
    { id: 'sname', numeric: false, disablePadding: false, label: '簡稱', sid: 'sname' },
    { id: 'expiredays', numeric: false, disablePadding: false, label: '距到期日', sid: 'expiredays' },
    { id: 'sprice', numeric: false, disablePadding: false, label: '股價', sid: 'sprice' },
    { id: 'cprice', numeric: false, disablePadding: false, label: '轉換價', sid: 'cprice' },
    { id: 'bprice', numeric: false, disablePadding: false, label: '參考現價', sid: 'bprice' },
    { id: 'gprice', numeric: false, disablePadding: false, label: '理論價', sid: 'gprice' },
    { id: 'alreadyc', numeric: false, disablePadding: false, label: '已轉換', sid: 'alreadyc' },
    { id: 'guarant', numeric: false, disablePadding: false, label: '有無擔保', sid: 'guarant' },

    { id: 'bid2', numeric: false, disablePadding: false, label: '代碼', sid: 'bid' },
    { id: 'sname2', numeric: false, disablePadding: false, label: '簡稱', sid: 'sname' },
    { id: 'expiredays2', numeric: false, disablePadding: false, label: '距到期日', sid: 'expiredays' },
    { id: 'sprice2', numeric: false, disablePadding: false, label: '股價', sid: 'sprice2' },
    { id: 'cprice2', numeric: false, disablePadding: false, label: '轉換價', sid: 'cprice' },
    { id: 'bprice2', numeric: false, disablePadding: false, label: '參考現價', sid: 'bprice' },
    { id: 'gprice2', numeric: false, disablePadding: false, label: '理論價', sid: 'gprice' },
    { id: 'alreadyc2', numeric: false, disablePadding: false, label: '已轉換', sid: 'alreadyc' },
    { id: 'guarant2', numeric: false, disablePadding: false, label: '有無擔保', sid: 'guarant' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            {/* <BBSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></BBSetting> */}
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={9} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">可轉換公司債#1</TableCell>
                <TableCell className={classes.stickya} colSpan={9} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">可轉換公司債#2</TableCell>
                {/* <TableCell className={classes.stickya} colSpan={8} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">可轉換公司債</TableCell> */}
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'bid' || headCell.id === 'bid2' || headCell.id === 'love' || headCell.id === 'bname2' || headCell.id === 'bname3' || headCell.id === 'ymd5' || headCell.id === 'psend') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' || headCell.id === 'bname' || headCell.id === 'buysell' || headCell.id === 'winprice' || headCell.id === 'bname1' || headCell.id === 'buysell1' || headCell.id === 'winprice1' || headCell.id === 'bname2' || headCell.id === 'buysell2' || headCell.id === 'winprice2' || headCell.id === 'bname3' || headCell.id === 'buysell3' || headCell.id === 'winprice3' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'bname2' ||
                        headCell.id === 'bname3' || headCell.id === 'status1' || headCell.id === 'status2' || headCell.id === 'status3'
                        || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function CBDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };



    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };


    function renderbid(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                <Link
                    component="button"
                    variant="caption"
                    onClick={() => {
                        window.open('https://mops.twse.com.tw/mops/web/t120sg01?TYPEK=&bond_id=' + row.cb[index].bid + '&bond_kind=5&bond_subn=' + row.cb[index].subn + '&bond_yrn=' + row.cb[index].yrn + '&come=2&encodeURIComponent=1&firstin=ture&issuer_stock_code=' + row.market_company.stock_no + '&monyr_reg=' + row.cb[index].reptdate + '&pg=&step=0&tg=&price', '')
                    }}
                >{row.cb[index].bid}
                </Link>

            </TableCell>
    }

    function rendername(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                {row.cb[index].sname}
            </TableCell>
    }

    function renderexpiredays(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                {row.cb[index].expiredays}天
            </TableCell>
    }

    function rendersprice(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                {row.cb[index].sprice}
            </TableCell>
    }

    function renderecprice(row, index) {
        if (row.cb != null && row.cb.length > index) {
            if (row.cb[index].sprice >= row.cb[index].cprice)
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >
                        已到達　{row.cb[index].cprice}元
                    </Button>
                </TableCell>
            else if (row.cb[index].sprice < row.cb[index].cprice)
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                        未到達　{row.cb[index].cprice}元
                </Button>
                </TableCell>
        }
    }

    function renderecbprice(row, index) {
        if (row.cb != null && row.cb.length > index) {
            if (row.cb[index].bprice >= row.cb[index].gprice)
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >
                        溢價　{row.cb[index].bprice}元
                    </Button>
                </TableCell>
            else if (row.cb[index].bprice < row.cb[index].gprice)
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                        折價　{row.cb[index].bprice}元
                </Button>
                </TableCell>
        }
    }

    function rendergprice(row, index) {
        if (row.cb != null && row.cb.length > index) {
            let k = row.cb[index].gprice + "元"
            if (row.cb[index].gprice === -99999.0) k = "-"
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                {k}
            </TableCell>
        }
    }

    function renderalreadyc(row, index) {
        if (row.cb != null && row.cb.length > index)
            if (row.cb[index].alreadyc >= 80)
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <div style={{ color: BColor.upcolorbox }}>{row.cb[index].alreadyc}%</div>
                </TableCell>
            else
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    {row.cb[index].alreadyc}%
            </TableCell>
    }

    function renderguarant(row, index) {
        if (row.cb != null && row.cb.length > index)
            if (row.cb[index].guarant)
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <div style={{ color: BColor.upcolorbox }}>有擔保</div>
                </TableCell>
            else
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    無擔保
            </TableCell>
    }

    function renderEmpty(row, index) {
        if (row.cb == null || (row.cb != null && row.cb.length <= index))
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="9" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無資料或上櫃交易</Button>

            </TableCell>
        //   return <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: 5, marginBottom: 5}} >現：{cash.toFixed(2)}元　股：{stock.toFixed(2)}元</div>
    }

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {renderbid(row, 0)}
                                            {rendername(row, 0)}
                                            {renderexpiredays(row, 0)}
                                            {rendersprice(row, 0)}
                                            {renderecprice(row, 0)}
                                            {renderecbprice(row, 0)}
                                            {rendergprice(row, 0)}
                                            {renderalreadyc(row, 0)}
                                            {renderguarant(row, 0)}
                                            {renderEmpty(row, 0)}

                                            {renderbid(row, 1)}
                                            {rendername(row, 1)}
                                            {renderexpiredays(row, 1)}
                                            {rendersprice(row, 1)}
                                            {renderecprice(row, 1)}
                                            {renderecbprice(row, 1)}
                                            {rendergprice(row, 1)}
                                            {renderalreadyc(row, 1)}
                                            {renderguarant(row, 1)}
                                            {renderEmpty(row, 1)}

                                            {/* {renderbid(row, 2)}
                                            {rendername(row, 2)}
                                            {renderexpiredays(row, 2)}
                                            {renderecprice(row, 2)}
                                            {renderecbprice(row, 2)}
                                            {rendergprice(row, 2)}
                                            {renderalreadyc(row, 2)}
                                            {renderguarant(row, 2)}
                                            {renderEmpty(row, 2)} */}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(CBDetail);
