import React, { useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import FinRateList from '../details/FinRateList3';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart, CandlestickChart } from 'echarts/charts';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
    GridComponent,
    SingleAxisComponent,
    ToolboxComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import AVSetting from './AVSetting'

echarts.use(
    [SingleAxisComponent, AxisPointerComponent, CanvasRenderer, ToolboxComponent, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, BarChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 12,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 12,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function StockPressure(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [open1, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState(getDefaultOption(80, 100))

    const [c1, setC1] = React.useState(false)
    const [c2, setC2] = React.useState(false)
    const [c3, setC3] = React.useState(true)
    const [c4, setC4] = React.useState(true)
    const [c5, setC5] = React.useState(true)
    const [c6, setC6] = React.useState(true)

    const [p0, setP0] = React.useState(0)
    const [p1, setP1] = React.useState(5)
    const [options1, setOptions1] = React.useState(getDefaultOption1(0, 5))

    const [p2, setP2] = React.useState(1)
    const [p3, setP3] = React.useState(5)
    const [options2, setOptions2] = React.useState(getDefaultOption2(1, 5))

    const zStart = useRef(80);
    const zEnd = useRef(100);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        props.bigupdate()
        setOpen(false);
    };



    function getXX() {
        let data = props.row.kd3.d.map(vv => {
            return vv.x
        })
        return data
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1() {
        let data = props.row.kd3.d.map(vv => {
            return vv.y
        })
        return data
    }

    function getYY2() {
        if (props.row.kd3.d != null) {
            let data = []
            for (var i = 0; i < props.row.kd3.d.length; i++) {
                data.push([i, props.row.kd3.d[i].v, props.row.kd3.d[i].i]);
            }
            return data
        } else return []
    }


    function getDefaultOption(zoStart, zoEnd) {
        let option = {
            tooltip: {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                // trigger: 'axis',
                // axisPointer: {
                //     type: 'cross'
                // },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            },
            legend: {
                show: false
                // type: 'scroll',
            },
            grid: [
                {
                    top: '25',
                    left: '40',
                    right: '70',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '330',
                    left: '40',
                    right: '70',
                    height: '150',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            visualMap: {
                show: false,
                dimension: 2,
                seriesIndex: 1,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            }
        };

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: zoStart,
                    end: zoEnd
                }
            ]
        } else {
            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: zoStart,
                    end: zoEnd
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '500',
                    start: zoStart,
                    end: zoEnd
                }
            ]
        }

        return option
    }


    function getValue1(tp, value) {
        if (props.row.kd3 != null && props.row.kd3.d != undefined && props.row.press1 != null) {
            if (tp === 0 && value === 5 && props.row.press1.pp5 != null && props.row.press1.pp5 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.pp5)
            else if (tp === 0 && value === 10 && props.row.press1.pp10 != null && props.row.press1.pp10 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.pp10)
            else if (tp === 0 && value === 20 && props.row.press1.pp20 != null && props.row.press1.pp20 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.pp20)
            else if (tp === 0 && value === 60 && props.row.press1.pp60 != null && props.row.press1.pp60 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.pp60)
            else if (tp === 0 && value === 120 && props.row.press1.pp120 != null && props.row.press1.pp120 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.pp120)
            else if (tp === 0 && value === 240 && props.row.press1.pp240 != null && props.row.press1.pp240 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.pp240)
            else if (tp === 1 && value === 5 && props.row.press1.sp5 != null && props.row.press1.sp5 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.sp5)
            else if (tp === 1 && value === 10 && props.row.press1.sp10 != null && props.row.press1.sp10 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.sp10)
            else if (tp === 1 && value === 20 && props.row.press1.sp20 != null && props.row.press1.sp20 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.sp20)
            else if (tp === 1 && value === 60 && props.row.press1.sp60 != null && props.row.press1.sp60 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.sp60)
            else if (tp === 1 && value === 120 && props.row.press1.sp120 != null && props.row.press1.sp120 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.sp120)
            else if (tp === 1 && value === 240 && props.row.press1.sp240 != null && props.row.press1.sp240 != -1)
                return Array(props.row.kd3.d.length).fill(props.row.press1.sp240)
            else return []
        } else return []
    }

    function genLine(title, tp, days, title1) {
        let color = "#de8c9e"
        let color2 = "#bf4b65"

        if (tp === 1) {
            color = "#90b570"
            color2 = "#628f3b"
        }

        let data = getValue1(tp, days)

        if (data.length > 0) {
            return {
                name: title,
                type: 'line',
                data: data,
                lineStyle: {
                    width: 5,
                    opacity: 0.35,
                    color: color
                },
                showSymbol: false,
                animation: false,
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                endLabel: {
                    show: true,
                    backgroundColor: color2,
                    color: "#FFFFFF",
                    borderWidth: 1,
                    borderColor: color2,
                    borderRadius: 3,
                    padding: 5,
                    fontSize: 10,
                    formatter: title1 + ':{c}',
                },
            }
        } else return null
    }

    const changePeriod = (ca1, ca2, ca3, ca4, ca5, ca6) => {
        let k = getDefaultOption(zStart.current, zEnd.current)

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }
        }

        let x2 = {
            type: 'category',
            gridIndex: 1,
            data: getXX(),
            scale: true,
            boundaryGap: true,
            min: 'dataMin',
            max: 'dataMax',
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            splitNumber: 20
        }

        if (props.ismobile) {
            x2['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
        }

        k['xAxis'] = [x1, x2]

        let yAxis = [{
            scale: true,
            splitArea: {
                show: false
            },
            show: true,
            position: 'left',
            splitLine: {
                show: true
            },
        },
        {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
        }
        ]
        k['yAxis'] = yAxis

        let serdata = [
            {
                name: '日K',
                type: 'candlestick',
                data: getYY1(),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            {
                name: '成交量',
                type: 'bar',
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: getYY2()
            }
        ]

        if (ca1) {
            let g5 = genLine('壓5日', 0, 5, '5日')
            if (g5 != null) serdata.push(g5)

            let s5 = genLine('撐5日', 1, 5, '5日')
            if (s5 != null) serdata.push(s5)
        }

        if (ca2) {
            let g5 = genLine('壓10日', 0, 10, '10日')
            if (g5 != null) serdata.push(g5)

            let s5 = genLine('撐10日', 1, 10, '10日')
            if (s5 != null) serdata.push(s5)
        }

        if (ca3) {
            let g5 = genLine('壓20日', 0, 20, '20日')
            if (g5 != null) serdata.push(g5)

            let s5 = genLine('撐20日', 1, 20, '20日')
            if (s5 != null) serdata.push(s5)
        }

        if (ca4) {
            let g5 = genLine('壓60日', 0, 60, '60日')
            if (g5 != null) serdata.push(g5)

            let s5 = genLine('撐60日', 1, 60, '60日')
            if (s5 != null) serdata.push(s5)
        }


        if (ca5) {
            let g5 = genLine('壓120日', 0, 120, '120日')
            if (g5 != null) serdata.push(g5)

            let s5 = genLine('撐120日', 1, 120, '120日')
            if (s5 != null) serdata.push(s5)
        }

        if (ca6) {
            let g5 = genLine('壓240日', 0, 240, '240日')
            if (g5 != null) serdata.push(g5)

            let s5 = genLine('撐240日', 1, 240, '240日')
            if (s5 != null) serdata.push(s5)
        }

        k['series'] = serdata
        setOptions(k)
    }

    function getPriceList(tp, value) {
        if (props.row.kd3 != null && props.row.press1 != null) {
            if (tp === 0 && value === 5 && props.row.press1.p5 != null && props.row.press1.p5.length > 0)
                return props.row.press1.p5.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 0 && value === 10 && props.row.press1.p10 != null && props.row.press1.p10.length > 0)
                return props.row.press1.p10.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 0 && value === 20 && props.row.press1.p20 != null && props.row.press1.p20.length > 0)
                return props.row.press1.p20.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 0 && value === 60 && props.row.press1.p60 != null && props.row.press1.p60.length > 0)
                return props.row.press1.p60.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 0 && value === 120 && props.row.press1.p120 != null && props.row.press1.p120.length > 0)
                return props.row.press1.p120.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 0 && value === 240 && props.row.press1.p240 != null && props.row.press1.p240.length > 0)
                return props.row.press1.p240.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 1 && value === 5 && props.row.press1.s5 != null && props.row.press1.s5.length > 0)
                return props.row.press1.s5.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 1 && value === 10 && props.row.press1.s10 != null && props.row.press1.s10.length > 0)
                return props.row.press1.s10.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 1 && value === 20 && props.row.press1.s20 != null && props.row.press1.s20.length > 0)
                return props.row.press1.s20.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 1 && value === 60 && props.row.press1.s60 != null && props.row.press1.s60.length > 0)
                return props.row.press1.s60.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 1 && value === 120 && props.row.press1.s120 != null && props.row.press1.s120.length > 0)
                return props.row.press1.s120.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else if (tp === 1 && value === 240 && props.row.press1.s240 != null && props.row.press1.s240.length > 0)
                return props.row.press1.s240.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
                    return vv.p
                })
            else return []
        } else return []
    }

    function genVol(p20) {
        let maxv = Math.max.apply(Math, p20.map(function (o) { return o.v; }))

        return p20.sort((a, b) => (a.p > b.p) ? 1 : ((b.p > a.p) ? -1 : 0)).map(vv => {
            if (maxv === vv.v) {
                return {
                    value: vv.v,
                    itemStyle: {
                        color: '#a90000'
                    }
                }
            } else return vv.v
        })
    }

    function getVolList(tp, value) {
        if (props.row.kd3 != null && props.row.press1 != null) {
            if (tp === 0 && value === 5 && props.row.press1.p5 != null && props.row.press1.p5.length > 0) {
                return genVol(props.row.press1.p5)
            } else if (tp === 0 && value === 10 && props.row.press1.p10 != null && props.row.press1.p10.length > 0) {
                return genVol(props.row.press1.p10)
            } else if (tp === 0 && value === 20 && props.row.press1.p20 != null && props.row.press1.p20.length > 0) {
                return genVol(props.row.press1.p20)
            } else if (tp === 0 && value === 60 && props.row.press1.p60 != null && props.row.press1.p60.length > 0)
                return genVol(props.row.press1.p60)
            else if (tp === 0 && value === 120 && props.row.press1.p120 != null && props.row.press1.p120.length > 0)
                return genVol(props.row.press1.p120)
            else if (tp === 0 && value === 240 && props.row.press1.p240 != null && props.row.press1.p240.length > 0)
                return genVol(props.row.press1.p240)
            else if (tp === 1 && value === 5 && props.row.press1.s5 != null && props.row.press1.s5.length > 0)
                return genVol(props.row.press1.s5)
            else if (tp === 1 && value === 10 && props.row.press1.s10 != null && props.row.press1.s10.length > 0)
                return genVol(props.row.press1.s10)
            else if (tp === 1 && value === 20 && props.row.press1.s20 != null && props.row.press1.s20.length > 0)
                return genVol(props.row.press1.s20)
            else if (tp === 1 && value === 60 && props.row.press1.s60 != null && props.row.press1.s60.length > 0)
                return genVol(props.row.press1.s60)
            else if (tp === 1 && value === 120 && props.row.press1.s120 != null && props.row.press1.s120.length > 0)
                return genVol(props.row.press1.s120)
            else if (tp === 1 && value === 240 && props.row.press1.s240 != null && props.row.press1.s240.length > 0)
                return genVol(props.row.press1.s240)
            else return []
        } else return []
    }

    function getDefaultOption1(psa, tpa) {
        let option = {
            grid:
            {
                top: '15',
                left: '40',
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
                type: 'value',
            },
            yAxis: {
                type: 'category',
                data: getPriceList(psa, tpa)
            },
            series: [{
                data: getVolList(psa, tpa),
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                },
                label: {
                    position: 'right',
                    show: true
                },
            }]
        };

        return option
    }



    useEffect(() => {
        if (props.row.kd3 != null) {
            changePeriod(c1, c2, c3, c4, c5, c6)
            handleChange1(0, 5)
            handleChange2(1, 5)
        }
    }, [props.row.kd3]);

    const handleChange = (event, tp) => {
        if (tp === 5) {
            setC1(event.target.checked)
            changePeriod(event.target.checked, c2, c3, c4, c5, c6)
        } else if (tp === 10) {
            setC2(event.target.checked)
            changePeriod(c1, event.target.checked, c3, c4, c5, c6)
        } else if (tp === 20) {
            setC3(event.target.checked)
            changePeriod(c1, c2, event.target.checked, c4, c5, c6)
        } else if (tp === 60) {
            setC4(event.target.checked)
            changePeriod(c1, c2, c3, event.target.checked, c5, c6)
        } else if (tp === 120) {
            setC5(event.target.checked)
            changePeriod(c1, c2, c3, c4, event.target.checked, c6)
        } else if (tp === 240) {
            setC6(event.target.checked)
            changePeriod(c1, c2, c3, c4, c5, event.target.checked)
        }
    };

    const handleChange1 = (ps, tp) => {
        setP0(ps)
        setP1(tp)

        let p = getDefaultOption1(ps, tp)
        setOptions1(p)
    };

    const handleChange2 = (ps, tp) => {
        setP2(ps)
        setP3(tp)

        let p = getDefaultOption2(ps, tp)
        setOptions2(p)
    };

    function getDefaultOption2(psa, tpa) {
        let option = {
            grid:
            {
                top: '15',
                left: '40',
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
                type: 'value',
            },
            yAxis: {
                type: 'category',
                data: getPriceList(psa, tpa)
            },
            series: [{
                data: getVolList(psa, tpa),
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                },
                label: {
                    position: 'right',
                    show: true
                },
            }]
        };

        return option
    }

    const onRoomClient = (event) => {
        if (event.start != undefined && event.end != undefined) {
            zStart.current = event.start
            zEnd.current = event.end
        }
    }

    const onEvents = {
        'datazoom': onRoomClient,
    }


    return (
        <div>
            <FinRateList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></FinRateList>
            <AVSetting open={open1} handleClose={handleClose} handleOk={handleOk}></AVSetting>

            <Grid container spacing={3} className={classes.grid}>

                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            支撐壓力表
                        </Typography>
                    </div>

                    <FormGroup row >
                        <FormControlLabel
                            control={<Checkbox checked={c1} name="c1" fontSize="small" onChange={e => handleChange(e, 5)} />}
                            label={<div style={{ fontSize: 12, color: "#737373" }}>5日</div>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={c2} name="c2" fontSize="small" onChange={e => handleChange(e, 10)} />}
                            label={<div style={{ fontSize: 12, color: "#737373" }}>10日</div>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={c3} name="c3" fontSize="small" onChange={e => handleChange(e, 20)} />}
                            label={<div style={{ fontSize: 12, color: "#737373" }}>20日</div>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={c4} name="c4" fontSize="small" onChange={e => handleChange(e, 60)} />}
                            label={<div style={{ fontSize: 12, color: "#737373" }}>60日</div>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={c5} name="c5" fontSize="small" onChange={e => handleChange(e, 120)} />}
                            label={<div style={{ fontSize: 12, color: "#737373" }}>120日</div>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={c6} name="c6" fontSize="small" onChange={e => handleChange(e, 240)} />}
                            label={<div style={{ fontSize: 12, color: "#737373" }}>240日</div>}
                        />
                    </FormGroup>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 550, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        onEvents={onEvents}
                    />
                </Grid>

                <Grid item xs={12} sm={4}  >
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            主壓力價
                        </Typography>
                    </div>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'50'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'5日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.pp5 != null && props.row.press1.pp5 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange1(0, 5)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.pp5}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'51'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'10日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.pp10 != null && props.row.press1.pp10 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange1(0, 10)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.pp10}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'20日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.pp20 != null && props.row.press1.pp20 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange1(0, 20)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.pp20}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'60日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.pp60 != null && props.row.press1.pp60 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange1(0, 60)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.pp60}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'120日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.pp120 != null && props.row.press1.pp120 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange1(0, 120)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.pp120}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'240日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.pp240 != null && props.row.press1.pp240 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange1(0, 240)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.pp240}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={8} >
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            {p1}日壓力Top10價量明細
                        </Typography>
                    </div>

                    <ReactEChartsCore
                    theme={theme.palette.type}
                        style={{ marginTop: 0 }}
                        echarts={echarts}
                        option={options1}
                        notMerge={true}
                        lazyUpdate={false}
                    />
                </Grid>


                <Grid item xs={12} sm={4}  >
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            主支撐價
                        </Typography>
                    </div>
                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'50'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'5日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.sp5 != null && props.row.press1.sp5 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange2(1, 5)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.sp5}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'51'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'10日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.sp10 != null && props.row.press1.sp10 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange2(1, 10)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.sp10}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'20日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.sp20 != null && props.row.press1.sp20 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange2(1, 20)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.sp20}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'60日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.sp60 != null && props.row.press1.sp60 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange2(1, 60)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.sp60}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'120日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.sp120 != null && props.row.press1.sp120 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange2(1, 120)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.sp120}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'240日'}
                                    </StyledTableCell>

                                    {props.row.press1 != undefined && props.row.press1.sp240 != null && props.row.press1.sp240 != -1 ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={e => handleChange2(1, 240)} size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.press1.sp240}元
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button size="small" variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={8} >
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            {p3}日支撐Top10價量明細
                        </Typography>
                    </div>

                    <ReactEChartsCore
                    theme={theme.palette.type}
                        style={{ marginTop: 0 }}
                        echarts={echarts}
                        option={options2}
                        notMerge={true}
                        lazyUpdate={false}
                    />
                </Grid>


            </Grid>
        </div >
    );
}