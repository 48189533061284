import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import BColor from '../BColor';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import Button from '@material-ui/core/Button'
import HLPSetting from './HLPSetting'

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function HLPChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        props.bigupdate()
        setOpen(false);
    };

    function getXX() {
        if (props.row.phl != null &&
            props.row.kd3.d.length >= props.row.phl.length) {
            let data = props.row.kd3.d.slice(-props.row.phl.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1() {
        if (props.row.phl != null &&
            props.row.kd3.d.length >= props.row.phl.length) {

            let data = props.row.kd3.d.slice(-props.row.phl.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }


    function getDefaultAChartOption(data) {
        let option = {
            legend: {
                data: ['多頭', '空頭', '盤整','空方', '多方']
            },
            title: [
                {
                    top: '310',
                    left: 'center',
                    text: '三方指標圖'
                }, {
                    top: '550',
                    left: 'center',
                    text: '三方戰勝圖'
                }
            ],
            grid: [
                {
                    top: '30',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }, {
                    top: '340',
                    left: '40',
                    right: '40',
                    height: '150',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                } , {
                    top: '580',
                    left: '40',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                }, {
                    show: true,
                    gridIndex: 1,
                    scale: true,
                    type: 'value',
                    splitNumber: 8
                }, {
                    show: true,
                    gridIndex: 2,
                    // scale: true,
                    type: 'value',
                    // splitNumber: 8
                }
            ],
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.ymd }),
        }

        let x3 = {
            gridIndex: 2,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.ymd }),
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }

            x2['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
            x3['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
        }

        option['xAxis'] = [x1, x2, x3]

        if (data != null) {
            option['series'] = [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: getYY1(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: '多頭',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.much }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    lineStyle: {
                        width: 3
                    },
                    smooth: 'true'
                }, {
                    name: '空頭',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.less }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    lineStyle: {
                        width: 3
                    },
                    smooth: 'true'
                },

                {
                    name: '多方',
                    type: 'bar',
                    data: data.map(v => { return v.much1 }),
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                },
                {
                    name: '空方',
                    type: 'bar',
                    data: data.map(v => { return v.less1 }),
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                },
                {
                    name: '盤整',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.nothing }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    lineStyle: {
                        width: 0.5
                    },
                    smooth: 'true'
                },
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(60, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getComputerDataZoom(0, 100, 820)
        }

        return option
    }


    useEffect(() => {
        if (props.row.dk != null) setOptions(getDefaultAChartOption(props.row.phl))
    }, [props.row.dk]);

    function getString() {
        return "目前無相關的三方指標"
    }

    return (
        <div>
            <HLPSetting open={open1} handleClose={handleClose} handleOk={handleOk}></HLPSetting>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            三方指標 <Button size="small" color="primary" variant="outlined" onClick={(e) => setOpen(true)}>設定</Button>
                        </Typography>
                    </div>
                    <Typography variant="caption" id="desc1">
                        三方指標用來顯示目前多頭，空頭，盤整三方走勢變化。每日算出過去區間（例：近10，40，60天）股價出現多頭，空頭，盤整型態佔總天數的百分比。
                        
                        <div>買賣時機參考點：
                        多（空）頭從低點往上升。多頭與空頭金（死）叉。三方戰勝表出現多次多（空）頭訊號。
                        </div>
                    </Typography>
                    {props.row.phl != undefined && options != null ? <ReactEChartsCore
                        style={{ height: 900, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}