import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter, Link } from "react-router-dom";
import { HashLink as LinkH } from 'react-router-hash-link';

class AlertDialog extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     open: props.open
        // };
    }

    handleClickOpen = () => {
        // this.setState({ open: true });
    };

    handleClose = () => {
        // this.setState({ open: false });
        this.props.handleClose()
    };

    renderLinkA() {
        if(this.props.nag_label != "") {
            if(this.props.nativelinkto === "") {
                return <Button onClick={this.handleClose} color="primary" >
                    {this.props.nag_label}
                </Button>
            } else {
                return <LinkH to={this.props.nativelinkto} style={{ textDecoration: 'none' }}>
                <Button onClick={this.handleClose} color="primary" >
                    {this.props.nag_label}
                </Button>
                </LinkH>            
            }
        }
    }

    renderLink() {
        if(this.props.linkto === "") {
            return <Button onClick={this.handleClose} color="primary" autoFocus>
                {this.props.pos_label}
            </Button>
        } else {
            return <LinkH to={this.props.linkto} style={{ textDecoration: 'none' }}>
            <Button onClick={this.handleClose} color="primary" autoFocus>
                {this.props.pos_label}
            </Button>
            </LinkH>            
        }

    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.desc}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={this.handleClose} color="primary">
                            {this.props.nag_label}
                        </Button> */}
                        {this.renderLinkA()}
                        {this.renderLink()}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AlertDialog;