import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function StopValueSetting(props) {
    //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const aref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('factor', parseFloat(yref.current.value));

        if (aref.current.value != null && aref.current.value != "")
            localStorage.setItem('days', parseInt(aref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('factor') === null ? 3.0 : localStorage.getItem('factor')
    }

    function aa() {
        return localStorage.getItem('days') === null ? 20 : localStorage.getItem('days')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">參數設定</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="aa"
                    label="ATR天數(預設:20)"
                    type="text"
                    fullWidth
                    defaultValue={aa()}
                    inputRef={aref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="Factor"
                    label="(預設:3.0)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
                </Button>
                <Button onClick={handleOk} color="primary">
                    確定
                </Button>
            </DialogActions> : <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    關閉（訂閱專屬功能）
                </Button>
            </DialogActions>}
        </Dialog>
    );
}
