import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AsyncSelect from 'react-select/async';
import StockDetail from './StockDetail';
import StockAutocomplete from './StockAutocomplete'
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
    root: {
        // flexGrow: 0,
        backgroundColor: "#FFFFFF",
        color: "#FFFFFF",
    },
    menuButton: {
        marginRight: theme.spacing(3),
        color: "#FFFFFF",
    },
    title: {
        color: "#FFFFFF",
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        // maxWidth: 100
        // borderRadius: theme.shape.borderRadius,
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.white, 0.25),
        // },
        // marginLeft: 0,
        // width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: theme.spacing(1),
        //     width: 'auto',
        // },
        // color: "#FFFFFF",
    },
    // searchIcon: {
    //     padding: theme.spacing(0, 2),
    //     height: '100%',
    //     position: 'absolute',
    //     // pointerEvents: 'none',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     color: "#FFFFFF"
    // },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function AppMenu2(props) {
    const classes = useStyles();
    const [symbol, setSymbol] = React.useState(null);
    const [opendetail, setOpendetail] = React.useState(false)
    const [usercount, setUsercount] = React.useState("")
    const [uuuser, setuuuser] = React.useState(0)

    const menuChange = (data) => {
        setSymbol(data)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    useEffect(() => {
        fectchUserAPI()
    }, [uuuser]);

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const fectchUserAPI = () => {
        fetch("/jstock/v1/stock/usercount", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.text())
            .then(
                result => {
                    if (isNumeric(result)) {
                        setUsercount("新功能：畫型態選股")
                    } else setUsercount("系統正在更新中.........")
                },
                error => {
                    setUsercount("系統正在更新中.........")
                }
            );
    }

    // const fectchUserAPI = () => {
    //     fetch("/jstock/v1/stock/usercount", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     })
    //         .then(res => res.text())
    //         .then(
    //             result => {
    //                 setUsercount(result)
    //             }
    //         );
    // }

    return (
        <div className={classes.root}>
            {symbol != null ? <StockDetail open={opendetail}  handleClose={closeDetail} stockno={symbol} /> : null}

            <AppBar position="static" style={{ backgroundColor: '#2b2b2b', }} >
                <Toolbar style={{ minHeight: 52 }}>
                    {/* <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <MenuIcon />
                    </IconButton> */}
                    {/* <Avatar className={classes.menuButton} src="assets/images/logo-transparent-1-128x128.png" /> */}
                    <Typography className={classes.title} variant="subtitle2" noWrap>
                        {usercount}  
                    </Typography>

                    <div className={classes.search}>
                        <StockAutocomplete menuChange={menuChange} />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
