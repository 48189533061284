import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
// import { borderBottom } from '@material-ui/system';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import DealerList1 from './DealerList1';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'

import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    if (b.risk != null && a.risk != null) {
        if (b.risk[orderBy] < a.risk[orderBy]) {
            return -1;
        }
        if (b.risk[orderBy] > a.risk[orderBy]) {
            return 1;
        }
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// case class KDInfo(ymd : String, k : Double, d : Double, dir : Int, pas : Int, pashday : Int, paslday : Int , pasmday : Int, corss : Int, crosg : Int, crosd : Int, crosp : Int)


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'r1a', numeric: false, disablePadding: false, label: '報酬', sid: 'r1a' },
    { id: 'r3a', numeric: false, disablePadding: false, label: '風險', sid: 'r3a' },
    { id: 'r2a', numeric: false, disablePadding: false, label: '報酬率', sid: 'r2a' },
    { id: 'r4a', numeric: false, disablePadding: false, label: '日波動率(σ)', sid: 'r4a' },
    { id: 'r5a', numeric: false, disablePadding: false, label: 'Beta(β)', sid: 'r5a' },

    { id: 'r1b', numeric: false, disablePadding: false, label: '報酬', sid: 'r1b' },
    { id: 'r3b', numeric: false, disablePadding: false, label: '風險', sid: 'r3b' },
    { id: 'r2b', numeric: false, disablePadding: false, label: '報酬率', sid: 'r2b' },
    { id: 'r4b', numeric: false, disablePadding: false, label: '日波動率(σ)', sid: 'r4b' },
    { id: 'r5b', numeric: false, disablePadding: false, label: 'Beta(β)', sid: 'r5b' },

    { id: 'r1c', numeric: false, disablePadding: false, label: '報酬', sid: 'r1c' },
    { id: 'r3c', numeric: false, disablePadding: false, label: '風險', sid: 'r3c' },
    { id: 'r2c', numeric: false, disablePadding: false, label: '報酬率', sid: 'r2c' },
    { id: 'r4c', numeric: false, disablePadding: false, label: '日波動率(σ)', sid: 'r4c' },
    { id: 'r5c', numeric: false, disablePadding: false, label: 'Beta(β)', sid: 'r5c' },

    { id: 'r1d', numeric: false, disablePadding: false, label: '報酬', sid: 'r1d' },
    { id: 'r3d', numeric: false, disablePadding: false, label: '風險', sid: 'r3d' },
    { id: 'r2d', numeric: false, disablePadding: false, label: '報酬率', sid: 'r2d' },
    { id: 'r4d', numeric: false, disablePadding: false, label: '日波動率(σ)', sid: 'r4d' },
    { id: 'r5d', numeric: false, disablePadding: false, label: 'Beta(β)', sid: 'r5d' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近20日</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近60日</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近120日</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近240日</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'r1a' || headCell.id === 'r1b' || headCell.id === 'r1c' || headCell.id === 'r1d' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'pashday' || headCell.id === 'pashday1' || headCell.id === 'pashday2' || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        // minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function MainCostDetail(props) {
    let redcolor = BColor.upcolor
    let greencolor = BColor.downcolor
    let backcolor = "#FFFFFF"
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox
    let cellpadding = 5

    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);


    const [openstock, setOpenstock] = React.useState(null);
    const [maincusopen, setMainCusOpen] = React.useState(false)
    const [dealeropen, setDealerOpen] = React.useState(false)
    const [dealerbuy, setDealerBuy] = React.useState(true)
    const [dealerday, setDealerDay] = React.useState(1)
    const [stockname, setStockName] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)

    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpengg = (stock_no, buy, day, name) => event => {
        setOpenstock(stock_no)
        setDealerOpen(true);
        setDealerBuy(buy)
        setDealerDay(day)
        setStockName(name)
    };

    const handleClosegg = () => {
        setOpenstock(null)
        setDealerOpen(false);
        setDealerBuy(true)
        setDealerDay(1)
        setStockName(null)
    };





    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };


    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }


    const renderValue = (value) => {
        let color = BColor.nodatacolor

        if (value === -1) {
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        } else if (value === 0) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                高獲利
            </Button>
        } else if (value === 2) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                低獲利
            </Button>
        } else if (value === 3) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                虧損
            </Button>
        } else if (value === 1) {
            color = BColor.faircolor
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                中等獲利
            </Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }

    const renderValue2 = (value) => {
        let color = BColor.nodatacolor

        if (value === -1) {
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        } else if (value === 2) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                低風險
            </Button>
        } else if (value === 0) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                高風險
            </Button>
        } else if (value === 1) {
            color = BColor.faircolor
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                中風險
            </Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }


    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <DealerList1 open={dealeropen} handleClose={handleClosegg} day={dealerday} stockId={openstock} buylist={dealerbuy} name={stockname}></DealerList1>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.market_company.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {/* {row.fin != null && row.fin != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.fin.price.last_value}元
                                                </Button>
                                            </TableCell> : null} */}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderValue(row.risk.r1a)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {renderValue2(row.risk.r3a)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r2a != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r2a}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r4a != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r4a}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r5a != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r5a}
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk == null || row.risk == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="5" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    目前無任何資料
                                                    </Button>
                                            </TableCell> : null}


                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderValue(row.risk.r1b)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {renderValue2(row.risk.r3b)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r2b != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r2b}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r4b != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r4b}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r5b != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r5b}
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk == null || row.risk == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="5" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    目前無任何資料
                                                    </Button>
                                            </TableCell> : null}



                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderValue(row.risk.r1c)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {renderValue2(row.risk.r3c)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r2c != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r2c}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r4c != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r4c}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r5c != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r5c}
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk == null || row.risk == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="5" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    目前無任何資料
                                                    </Button>
                                            </TableCell> : null}




                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderValue(row.risk.r1d)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {renderValue2(row.risk.r3d)}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r2d != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r2d}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r4d != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r4d}%
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk != null && row.risk != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">
                                                {row.risk.r5d != -99999 ? <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.risk.r5d}
                                                </Button> : <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                        -
                                                </Button>}
                                            </TableCell> : null}

                                            {row.risk == null || row.risk == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="5" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    目前無任何資料
                                                    </Button>
                                            </TableCell> : null}
                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(MainCostDetail);
