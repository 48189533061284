import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import Slide from '@material-ui/core/Slide';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import BigSmallList from '../details/BigSmallList';
import BigSmallList1 from '../details/BigSmallList1';
import BigSmallSetting from '../details/BigSmallSetting';
import BSmallBigSetting from '../details/BSmallBigSetting'

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MBigSmallTable(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [cdata, setCData] = React.useState(null);
    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [concentopen1, setConcentOpen1] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
        setConcentOpen1(false);
    };

    const handleClickOpencc1 = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen1(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        props.bigupdate()
    };

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleOk1 = () => {
        setOpen1(false);
        props.bigupdate()
    };

    function renderValue(value, unit, stockno, stockName) {
        let colorx = null
        let word = '持平'
        let vv = value

        if (value > 0) {
            colorx = BColor.upcolorbox
            word = '增加'
        } else if (value < 0) {
            colorx = BColor.downcolorbox
            word = '減少'
            vv = -value
        } else {
            word = '持平'
        }
        { (value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{vv}{unit}</Button>
    }

    function renderValue1(value, unit, stockno, stockName) {
        let colorx = "#000205"
        let word = '持平'
        let vv = value

        if (value > 0) {
            colorx = BColor.upcolorbox
            word = '增加'
        } else if (value < 0) {
            colorx = BColor.downcolorbox
            word = '減少'
            vv = -value
        } else {
            word = '持平'
        }
        { (value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }
        return <Button variant="outlined" onClick={handleClickOpencc1(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{vv}{unit}</Button>
    }

    function render1(value, unit) {
        let color = null

        if (value > 0) {
            color = BColor.upcolorbox
        } else if (value < 0) {
            color = BColor.downcolorbox
        }

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: color, marginRight: 1, marginLeft: 1 }} className={classes.button2} >
                {value}{unit}
            </Button>
        </StyledTableCell>
    }


    function render2(index, divid) {
        let color = null
        let value = '-'
        let border = ''

        if (divid) border = '1px solid #dbdbdb'

        if (index === 0) {
            value = '散戶賣 -> 大戶買'
            color = BColor.upcolor
        } else if (index === 1) {
            value = '大戶賣 -> 散戶買'
            color = BColor.downcolor
        } else if (index === 2) {
            value = '散戶跟大戶都買'
            color = BColor.upcolor
        } else if (index === 3) {
            value = '散戶跟大戶都賣'
            color = BColor.downcolor
        } else if (index === 4) {
            value = '散戶跟大戶都中立'
        } else if (index === 5) {
            value = '大戶買超'
            color = BColor.upcolor
        } else if (index === 6) {
            value = '大戶賣超'
            color = BColor.downcolor
        } else if (index === 7) {
            value = '散戶賣超'
            color = BColor.downcolor
        } else if (index === 8) {
            value = '散戶買超'
            color = BColor.upcolor
        }

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0, borderLeft: border }} align="left">
            <Button variant="outlined" style={{ minWidth: 140, color: color, marginRight: 1, marginLeft: 1 }} className={classes.button2} >
                {value}
            </Button>
        </StyledTableCell>
    }

    function genData1() {
        let k = genoption1()

        k['xAxis'] = {
            data: props.row.chart8.h.map(function (item) {
                return item.x;
            })
        }
        let series = []
        let y1 = {
            name: '大戶持股(%)',
            type: 'line',
            data: props.row.chart8.h.map(function (item) {
                return item.y;
            }),
            smooth: true,
        }
        let y2 = {
            name: '散戶持股(%)',
            type: 'line',
            data: props.row.chart8.m.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
        }

        series.push(y1)
        series.push(y2)

        k['series'] = series
        return k
    }


    function genoption1() {
        let k = {
            legend: {
                data: ['大戶持股(%)', '散戶持股(%)'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }


    function genData2() {
        let k = genoption2()

        k['xAxis'] = {
            data: props.row.chart9.h.map(function (item) {
                return item.x;
            })
        }
        let series = []
        let y1 = {
            name: '大戶人數',
            type: 'line',
            data: props.row.chart9.h.map(function (item) {
                return item.y;
            }),
            smooth: true,
        }
        let y2 = {
            name: '散戶人數',
            type: 'line',
            data: props.row.chart9.m.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
        }

        series.push(y1)
        series.push(y2)

        k['series'] = series
        return k
    }


    function genoption2() {
        let k = {
            legend: {
                data: ['大戶人數', '散戶人數'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 1000)
                                return (value / 1000).toFixed(0) + 'K'
                            else return value
                        }
                    }
                }
            ],
        }
        return k;
    }

    function genData3() {
        let k = genoption3()

        k['xAxis'] = {
            data: props.row.inte.map(function (item) {
                return item.x;
            })
        }
        let series = []
        let y1 = {
            name: '內部大戶持股走勢',
            type: 'line',
            data: props.row.inte.map(function (item) {
                return item.yt;
            }),
            smooth: true,
        }
        let y2 = {
            name: '三大法人持股走勢',
            type: 'line',
            data: props.row.inte.map(function (item) {
                return item.zt;
            }),
            yAxisIndex: 1,
            smooth: true,
        }

        series.push(y1)
        series.push(y2)

        k['series'] = series
        return k
    }


    function genoption3() {
        let k = {
            legend: {
                data: ['內部大戶持股走勢', '三大法人持股走勢'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 1000000 || value < -1000000)
                                return (value / 1000000).toFixed(0) + 'K'
                            else if (value > 1000 || value < -1000)
                                return (value / 1000).toFixed(0) + ''
                            else return value
                        }
                    }
                },
                {
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 1000000 || value < -1000000)
                                return (value / 1000000).toFixed(0) + 'K'
                            else if (value > 1000 || value < -1000)
                                return (value / 1000).toFixed(0) + ''
                            else return value
                        }
                    }
                }
            ],
        }
        return k;
    }

    useEffect(() => {
        if (props.row.chart8 != null) setOption(genData1())
        if (props.row.chart9 != null) setOption1(genData2())
        if (props.row.inte != null) setOption2(genData3())        
    }, [props.row]);


    return (
        <div>
            {concentopen? <BigSmallList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></BigSmallList>:null}
            {concentopen1? <BigSmallList1 open={concentopen1} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></BigSmallList1>:null}
            {open?<BigSmallSetting open={open} handleClose={handleClose} handleOk={handleOk}></BigSmallSetting>:null}
            {open1?<BSmallBigSetting open={open1} handleClose={handleClose1} handleOk={handleOk1}></BSmallBigSetting>:null}

            <Grid container >
                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            大戶散戶持股圖(周)<Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen()}>調整參數</Button>
                        </Typography>
                    </div>
                </Grid>


                {option != null && props.row.chart8 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無大戶散戶持股圖(周)</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            大戶散戶人數圖(周)
                        </Typography>
                    </div>
                </Grid>


                {option1 != null && props.row.chart9 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無大戶散戶人數圖(周)</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        內部大戶與法人持股走勢圖(周)
                        </Typography>
                    </div>
                </Grid>


                {option2 != null && props.row.inte != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option2}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無內部大戶與法人持股走勢圖(周)</div>}

            </Grid>

            {props.row.ebs != null && props.row.ebs != undefined ?
                <Grid container spacing={3} className={classes.grid} style={{marginTop: 30}}>
                    <Grid item xs={12} sm={6}>
                        <div style={{ display: 'flex', marginBottom: 5 }}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                依成交張數(日)
                            </Typography>
                            <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen1()}>調整參數</Button>
                        </div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table" size="small">
                                <caption></caption>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>名稱</StyledTableCell>
                                        <StyledTableCell>資料</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key={'日期'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'日期'}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {props.row.ebs.ymd}
                                        </StyledTableCell>
                                    </StyledTableRow >

                                    <StyledTableRow key={'交易量'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'交易量'}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {props.row.ebs.t1}張
                                        </StyledTableCell>
                                    </StyledTableRow >

                                    <StyledTableRow key={'狀態'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'狀態'}
                                        </StyledTableCell>
                                        {render2(props.row.ebs.sb1i, false)}
                                    </StyledTableRow >

                                    <StyledTableRow key={'散戶買'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'散戶買超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.sb1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'散戶賣'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'散戶賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.ss1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'散戶買賣超'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'散戶買賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.sbs1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'大戶買'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'大戶買超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.bb1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'大戶賣超'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'大戶賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.bs1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'大戶買賣超'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'大戶買賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.bbs1, '張')}
                                    </StyledTableRow >

                                </TableBody>
                            </Table>

                        </TableContainer>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <div style={{ display: 'flex', marginBottom: 5 }}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                依成交金額(日)
                            </Typography>

                        </div>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="caption table" size="small">
                                <caption></caption>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>名稱</StyledTableCell>
                                        <StyledTableCell>資料</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key={'狀態'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'狀態'}
                                        </StyledTableCell>
                                        {render2(props.row.ebs.msb1i, false)}
                                    </StyledTableRow >

                                    <StyledTableRow key={'散戶買'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'散戶買超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.msb1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'散戶賣'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'散戶賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.mss1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'散戶買賣超'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'散戶買賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.msbs1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'大戶買'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'大戶買超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.mbb1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'大戶賣超'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'大戶賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.mbs1, '張')}
                                    </StyledTableRow >
                                    <StyledTableRow key={'大戶買賣超'}>
                                        <StyledTableCell component="th" scope="row">
                                            {'大戶買賣超'}
                                        </StyledTableCell>
                                        {render1(props.row.ebs.mbbs1, '張')}
                                    </StyledTableRow >
                                </TableBody>
                            </Table>

                        </TableContainer>
                    </Grid>
                </Grid> : null}
            {/* <Typography className={classes.title} variant="h6" id="tableTitle">
                    暫無任何其它大戶與散戶(日)資料
                        </Typography> */}

            {props.row.chip != null && props.row.chip != undefined ? <Grid container spacing={3} className={classes.grid} style={{marginTop: 30}}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            總股東人數與股票張數(週)
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen()}>調整參數</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'日期'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日期'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.chip.ymd}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股票張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股票張數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.all / 10000000).toFixed(2)}萬張
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股票增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股票增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue((props.row.chip.sinc / 1000).toFixed(0), '張', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股東人數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股東人數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.user.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}人
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'人數增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'人數增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue(props.row.chip.uinc, '人', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            大戶(週)
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'人數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'人數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.biguser.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}人
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'佔全體股東'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'佔全體股東'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.biguper).toFixed(2)}%</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'人數增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'人數增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue((props.row.chip.bincp).toFixed(2), '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股票張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股票張數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.bigstock / 10000000).toFixed(1)}萬張</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'持股比例'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'持股比例'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.bigsper).toFixed(2)}%</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'本週持股增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本週持股增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue((props.row.chip.bigincp).toFixed(2), '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近2週持股增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近2週持股增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue((props.row.chip.b2incp).toFixed(2), '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            散戶(週)
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'人數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'人數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.cuser.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}人
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'佔全體股東'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'佔全體股東'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.cuper).toFixed(2)}%</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'人數增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'人數增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue1((props.row.chip.cincp).toFixed(2), '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股票張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股票張數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.cstock / 10000000).toFixed(1)}萬張
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'持股比例'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'持股比例'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(props.row.chip.csper).toFixed(2)}%
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'本週持股增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本週持股增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue1((props.row.chip.csincp).toFixed(2), '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近2週持股增減'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近2週持股增減'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue1((props.row.chip.c2incp).toFixed(2), '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Grid>


            </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                暫無任何其它大戶與散戶(週)資料
            </Typography>}


        </div>
    );
}

