const BPage = {
    donate_page : 4,
    love_page : 5,
    myrule_page : 6,
    report_page: 7,
    backtest_page : 8,
    ktest_page : 9,


    // winer_page: 6,
    // industry_page : 9,
    // pro_page: 8,
    // shape_page: 9,
    // shape_page_1: 91,
    // shape_page_2: 92,      
}

export default BPage