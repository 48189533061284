import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import Slide from '@material-ui/core/Slide';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpAvg(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [cdata, setCData] = React.useState(null);
    const [option, setOption] = React.useState(genoption1());
    const [option1, setOption1] = React.useState(genoption1());
    const [avgDate, setAvgDate] = React.useState(localStorage.getItem("avgDate") == null ? 200 : parseInt(localStorage.getItem("avgDate")));

    const handleChange = (event) => {
        localStorage.setItem("avgDate", event.target.value)
        setAvgDate(event.target.value);
    };

    useEffect(() => {
        if (props.value === 1) runFectchAPII();
    }, [props.value]);

    useEffect(() => {
        runFectchAPII();
    }, [avgDate]);

    function genData(m1, r1) {
        let k = genoption1()
        k['xAxis'] = {
            data: m1.map(function (item) {
                return item.x;
            })
        }
        let series = []
        let y1 = {
            name: '加權指數',
            type: 'line',
            data: m1.map(function (item) {
                return item.y;
            }),
            smooth: true,
        }
        let y2 = {
            name: '站上均線率',
            type: 'line',
            data: r1.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            markLine: {
                label: {
                    show: false
                },
                symbol: 'circle',
                data: [
                    {
                        name: '80',
                        yAxis: 80,
                        lineStyle: {
                            color: "#f04d4d",
                            width: 2,
                        }
                    },
                    {
                        name: '20',
                        yAxis: 20,
                        lineStyle: {
                            color: "#33ba30",
                            width: 2,
                        }
                    },
                    {
                        name: '50',
                        yAxis: 50,
                        lineStyle: {
                            color: "#808080",
                            width: 2,
                        }
                    }
                ]
            },
            labelLayout: {
                moveOverlap: 'shiftY'
            },
            endLabel: {
                show: true,
                backgroundColor: BColor.upcolorbox,
                color: "#FFFFFF",
                padding: 4,
                fontSize: 10,
            }

        }

        series.push(y1)
        series.push(y2)

        k['series'] = series
        return k
    }


    const runFectchAPII = () => {
        let a = {
            v1: avgDate
        };

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        fetch("/jstock/v1/stock/bigtable2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.r1 != null && result.r1.length > 0) setCData(true)
                    else setCData(false)

                    let k1 = genData(result.m1, result.r1)
                    setOption(k1)

                    let k2 = genData(result.m2, result.r2)
                    setOption1(k2)
                }
            );
    }

    function genoption1() {
        let k = {
            animation: false,
            legend: {
                data: ['加權指數', '站上均線率'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }

    return (
        <div>
            <Grid container >
                <Grid item xs={12} sm={12} style={{}}>
                    <FormControl size="small" variant="filled" fullWidth style={{marginBottom: 10}} >
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={avgDate}
                            label="avgDate"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>10日</MenuItem>
                            <MenuItem value={20}>20日</MenuItem>
                            <MenuItem value={50}>50日</MenuItem>
                            <MenuItem value={60}>60日</MenuItem>
                            <MenuItem value={100}>100日</MenuItem>
                            <MenuItem value={120}>120日</MenuItem>
                            <MenuItem value={200}>200日</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}> */}
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        集中市場與站上均線率
                    </Typography>
                    {/* </div> */}
                </Grid>


                {cdata ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無集中市場與站上均線率資料</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            櫃買市場與站上均線率
                        </Typography>
                    </div>
                </Grid>


                {cdata ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無櫃買市場與站上均線率資料</div>}


            </Grid>
        </div>
    );
}

