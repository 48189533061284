import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        marginLeft: 5,
        marginRight: 5,
        paddingLeft: 5,
        paddingRight: 5
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    button1: {
      minWidth: 110,
    }
  }));

export default function CashReport3(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [buyselldata, setBuyselldata] = React.useState([]);


    useEffect(() => {
        if (props.open) fectchStockData(props.stockId)
    }, [props.stockId]);


    function fectchStockData(stockId) {
        if (stockId != null) {
            let a = {
                sid: stockId,
                inp: {}
            };

            fetch("/jstock/v1/stock/finreport/bslist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.length > 0) {
                            setBuyselldata(result)
                        }
                    },
                    error => {
                    }
                );
        }
    }


    function renderValue3(value) {
        let colorx = BColor.faircolor
        let word = "-"
        let unit = "百萬元"

        if (value === undefined || value == null || value === -99999) {
            word = "-"
            unit = ''
        } else {
            let x = 1000000
            if (value > 100000000 || value < -100000000) {
                x = 100000000
                unit = "億元"
            } else if (value > 1000000 || value < -1000000) {
                x = 1000000
            }
            word = ((value / x).toFixed(1)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }

        if (value < 0 && value != -99999)
        colorx = BColor.downcolorbox
        return <Button size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button>
    }


    function genYmdWord(row) {
        return row.year + "第" + row.season + "季"
    }


    function getColor(row) {
        if (row.season === 4) {
            if(theme.palette.type === "light")
            return "#cccccc"
            else return "#454545"
        }
        else return null
    }

    return (
        <Dialog
            fullScreen
            open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.stockName}({props.stockId})資產負債表</DialogTitle>

            <DialogContent style={{ overflow: "hidden" }}>
                <TableContainer style={{ maxHeight: '80vh' }}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="center">資產</StyledTableCell>
                                <StyledTableCell align="center">流動資產</StyledTableCell>
                                <StyledTableCell align="center">現金</StyledTableCell>
                                <StyledTableCell align="center">存貨</StyledTableCell>
                                <StyledTableCell align="center">應收帳款</StyledTableCell>
                                <StyledTableCell align="center">非流動資產</StyledTableCell>
                                <StyledTableCell align="center">無形資產</StyledTableCell>
                                <StyledTableCell align="center">不動產</StyledTableCell>
                                <StyledTableCell align="center">負債</StyledTableCell>
                                <StyledTableCell align="center">流動負債</StyledTableCell>
                                <StyledTableCell align="center">非流動負債</StyledTableCell>
                                <StyledTableCell align="center">股東權益</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.ymd} style={{backgroundColor: getColor(row)}}>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">
                                        {genYmdWord(row)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.full_asset)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.cur_ast)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.cash)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.inv)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.rev)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.noncur)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.int_ast)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.ppe)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.liabilityies)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.cur_liy)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.nonlib)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">
                                        {renderValue3(row.equity)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
