import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'
import InterestAllDetail from '../mobile/InterestAllDetail'


function desc(a, b, orderBy) {
    if (orderBy === "yieldrate") {
        if (a['ii']['mi'] != null && a['fin'] != null && a['fin']['price'] != null && b['ii']['mi'] != null && b['fin'] != null && b['fin']['price'] != null) {
            let ayieldrate = (a['ii']['mi']['cash'] * 100 / a['fin']['price']['last_value'])
            let byieldrate = (b['ii']['mi']['cash'] * 100 / b['fin']['price']['last_value'])
            if (byieldrate < ayieldrate) return -1;
            if (byieldrate > ayieldrate) return 1;
        }
    } else if (orderBy === "stockyieldrate") {
        if (a['ii']['mi'] != null && a['fin'] != null && a['fin']['price'] != null && b['ii']['mi'] != null && b['fin'] != null && b['fin']['price'] != null) {
            let ayieldrate = (a['ii']['mi']['stock'] * 100 / a['fin']['price']['last_value'])
            let byieldrate = (b['ii']['mi']['stock'] * 100 / b['fin']['price']['last_value'])
            if (byieldrate < ayieldrate) return -1;
            if (byieldrate > ayieldrate) return 1;
        }
    } else if (orderBy === "allyieldrate") {
        if (a['ii']['mi'] != null && a['fin'] != null && a['fin']['price'] != null && b['ii']['mi'] != null && b['fin'] != null && b['fin']['price'] != null) {
            let ayieldrate = ((a['ii']['mi']['stock'] + a['ii']['mi']['cash']) * 100 / a['fin']['price']['last_value'])
            let byieldrate = ((b['ii']['mi']['stock'] + b['ii']['mi']['cash']) * 100 / b['fin']['price']['last_value'])
            if (byieldrate < ayieldrate) return -1;
            if (byieldrate > ayieldrate) return 1;
        }
    } else if (orderBy === "backyieldrate") {
        if (a['ii']['mi'] != null && a['fin'] != null && a['fin']['price'] != null && b['ii']['mi'] != null && b['fin'] != null && b['fin']['price'] != null) {
            let ayieldrate = (a['ii']['mi']['cash'] * 100) / a['fin']['price']['last_value'] + (a['ii']['mi']['stock'] * 10)
            let byieldrate = (b['ii']['mi']['cash'] * 100) / b['fin']['price']['last_value'] + (b['ii']['mi']['stock'] * 10)

            if (byieldrate < ayieldrate) return -1;
            if (byieldrate > ayieldrate) return 1;
        }
    } else {
        if (b['ii'][orderBy] < a['ii'][orderBy]) {
            return -1;
        }
        if (b['ii'][orderBy] > a['ii'][orderBy]) {
            return 1;
        }
    }
    return 0;
}


function stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號' },

    { id: 'psend', numeric: false, disablePadding: false, label: '配股年份' },
    { id: 'tprate', numeric: false, disablePadding: false, label: '填息/權率' },
    { id: 'pdate', numeric: false, disablePadding: false, label: '填息/權天數' },

    { id: 'midate', numeric: false, disablePadding: false, label: '董事會' },
    { id: 'midate1', numeric: false, disablePadding: false, label: '股東會' },
    { id: 'miname', numeric: false, disablePadding: false, label: '會議' },
    { id: 'micash', numeric: false, disablePadding: false, label: '股利' },
    { id: 'yieldrate', numeric: false, disablePadding: false, label: '現金殖利率' },
    { id: 'stockyieldrate', numeric: false, disablePadding: false, label: '股票殖利率' },
    { id: 'allyieldrate', numeric: false, disablePadding: false, label: '會計殖利率' },
    { id: 'backyieldrate', numeric: false, disablePadding: false, label: '還原殖利率' },
    { id: 'rate', numeric: false, disablePadding: false, label: '盈餘分配率' },

    //   { id: 'isy1', numeric: false, disablePadding: false, label: '發放年' },
    { id: 'ymd1', numeric: false, disablePadding: false, label: '除息/權日' },
    { id: 'cash1', numeric: false, disablePadding: false, label: '股利' },
    { id: 'day1', numeric: false, disablePadding: false, label: '填息/權狀態' },
    { id: 'croi1', numeric: false, disablePadding: false, label: '現金殖利率' },

    //   { id: 'isy2', numeric: false, disablePadding: false, label: '發放年' },
    { id: 'ymd2', numeric: false, disablePadding: false, label: '除息/權日' },
    { id: 'cash2', numeric: false, disablePadding: false, label: '股利' },
    { id: 'day2', numeric: false, disablePadding: false, label: '填息/權狀態' },
    { id: 'croi2', numeric: false, disablePadding: false, label: '現金殖利率' },

    //   { id: 'isy3', numeric: false, disablePadding: false, label: '發放年' },
    { id: 'ymd3', numeric: false, disablePadding: false, label: '除息/權日' },
    { id: 'cash3', numeric: false, disablePadding: false, label: '股利' },
    { id: 'day3', numeric: false, disablePadding: false, label: '填息/權狀態' },
    { id: 'croi3', numeric: false, disablePadding: false, label: '現金殖利率' },

    //   { id: 'isy4', numeric: false, disablePadding: false, label: '發放年' },
    { id: 'ymd4', numeric: false, disablePadding: false, label: '除息/權日' },
    { id: 'cash4', numeric: false, disablePadding: false, label: '股利' },
    { id: 'day4', numeric: false, disablePadding: false, label: '填息/權狀態' },
    { id: 'croi4', numeric: false, disablePadding: false, label: '現金殖利率' },

    //   { id: 'isy5', numeric: false, disablePadding: false, label: '發放年' },
    { id: 'ymd5', numeric: false, disablePadding: false, label: '除息/權日' },
    { id: 'cash5', numeric: false, disablePadding: false, label: '股利' },
    { id: 'day5', numeric: false, disablePadding: false, label: '填息/權狀態' },
    { id: 'croi5', numeric: false, disablePadding: false, label: '現金殖利率' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近九年平均</TableCell>
                <TableCell className={classes.stickya} colSpan={9} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最近一次公告</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最近一次除權息</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最近二次除權息</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最近三次除權息</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最近四次除權息</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">最近五次除權息</TableCell>

                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'ymd1' || headCell.id === 'ymd2' || headCell.id === 'love' || headCell.id === 'ymd3' || headCell.id === 'ymd4' || headCell.id === 'ymd5' || headCell.id === 'psend' || headCell.id === 'midate') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    > {headCell.id === 'ymd1' || headCell.id === 'cash1' || headCell.id === 'day1' || headCell.id === 'ymd2' || headCell.id === 'cash2' || headCell.id === 'day2' ||
                        headCell.id === 'ymd3' || headCell.id === 'cash3' || headCell.id === 'day3' ||
                        headCell.id === 'ymd4' || headCell.id === 'cash4' || headCell.id === 'day4' ||
                        headCell.id === 'ymd5' || headCell.id === 'cash5' || headCell.id === 'day5' ||
                        headCell.id === 'midate' || headCell.id === 'miname' || headCell.id === 'micash' || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> : <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 5,
        paddingRight: 10,
        // padding: 0, 
        whiteSpace: 'nowrap',
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));


function IntDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState(null);

    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };



    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data


    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };


    const renderInpriceStatus = (value) => {
        if (value === -99999) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (value === 0) {
            return <Button size="small" style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>零分 {value}%</Button>
        } else if (value > 0 && value < 60) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>偏低 {value}%</Button>
        } else if (value >= 60 && value < 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >尚可 {value}%</Button>
        } else if (value >= 80 && value < 100) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >優質 {value}%</Button>
        } else if (value === 100) {
            return <Button size="small" style={{ minWidth: 100, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >滿分 {value}%</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    const renderDay = (value) => {
        if (value === 99999) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (value >= 120) {
            return <Button size="small" style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>超級慢 {value}天</Button>
        } else if (value >= 60 && value < 120) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>有點慢 {value}天</Button>
        } else if (value >= 20 && value < 60) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >尚可 {value}天</Button>
        } else if (value >= 5 && value < 20) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >有點快 {value}天</Button>
        } else if (value < 5) {
            return <Button size="small" style={{ minWidth: 100, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >超級快 {value}天</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }


    function renderEmpty(row, index) {
        if (row.ii == undefined || row.ii.a.length <= index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="4" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無資料</Button>

            </TableCell>
        //   return <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: 5, marginBottom: 5}} >現：{cash.toFixed(2)}元　股：{stock.toFixed(2)}元</div>
    }

    function rendermeetingdate(row) {
        if (row.ii != undefined && row.ii.mi != null && row.ii.mi.ymd != null && row.ii.mi.ymd != "")
            return <TableCell style={{ minWidth: 100, whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                {row.ii.mi.ymd}
            </TableCell>
        else return <TableCell style={{ minWidth: 100, whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
            -
    </TableCell>

    }

    function rendermeetingdate1(row) {
        if (row.ii != undefined && row.ii.mi != null && row.ii.mi.ymd1 != null  && row.ii.mi.ymd1 != "")
            return <TableCell style={{ minWidth: 100, whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                {row.ii.mi.ymd1}
            </TableCell>
        else return <TableCell style={{ minWidth: 100, whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
            -
        </TableCell>

    }

    function rendermeetingname(row) {
        if (row.ii != undefined && row.ii.mi != null)
            return <TableCell style={{ minWidth: 110, maxWidth: 150, paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                {row.ii.mi.name}
            </TableCell>
    }

    function rendermeetingcash(row) {
        if (row.ii != undefined && row.ii.mi != null)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF' }} >現:{row.ii.mi.cash.toFixed(2)}元/股:{row.ii.mi.stock.toFixed(2)}元</div>
            </TableCell>
    }

    function openAn(row) {
        setRow(row)
        setOpen(true)
    }

    function renderyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = (row.ii.mi.cash * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)} >{yieldrate}%</Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            }
        }
    }


    function renderStockyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = (row.ii.mi.stock * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            }
        }
    }

    function renderAllyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = ((row.ii.mi.cash + row.ii.mi.stock) * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            }
        }
    }


    function renderBackyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = ((row.ii.mi.cash * 100) / row.fin.price.last_value + (row.ii.mi.stock * 10)).toFixed(2)

            if (yieldrate < 6) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{yieldrate}%</Button>
                </TableCell>
            }
        }
    }

    function renderRate(row) {
        if (row.ii != undefined && row.ii.mi != null) {
            let rate = row.ii.mi.rate
            let ratetp = row.ii.mi.ratetp

            if (ratetp === 0) {
                if (rate < 80) {
                    return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                        <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{rate}%</Button>
                    </TableCell>
                } else {
                    return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                        <Button variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} onClick={e => openAn(row)}>{rate}%</Button>
                    </TableCell>
                }
            } else if (ratetp === 1) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >當期無盈餘</Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >-</Button>
                </TableCell>
            }
        }
    }

    function renderMeetingEmpty(row, index) {
        if (row.ii.mi == undefined || row.ii.mi == null)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="7" size="small" align="right">
                <Button variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無公告</Button>

            </TableCell>
        //   return <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: 5, marginBottom: 5}} >現：{cash.toFixed(2)}元　股：{stock.toFixed(2)}元</div>
    }

    function renderIntCell2(row, index, hasEmpty) {
        if (row.ii != undefined && row.ii.a.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                {row.ii.a[index].ints.map(function (item, aindex, array) {
                    let spc = array.length > 1 ? 8 : 5
                    return <div key={Math.random()} style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: spc, marginBottom: spc }}>{item.ymd}</div>;
                })
                }
            </TableCell>
        else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="right"></TableCell>
    }

    function renderIntCell3(row, index, hasEmpty) {
        if (row.ii != undefined && row.ii.a.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                {row.ii.a[index].ints.map(function (item, aindex, array) {
                    let spc = array.length > 1 ? 8 : 5
                    return <div key={Math.random()} style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: spc, marginBottom: spc }} >現:{item.cash.toFixed(2)}元/股:{item.stock.toFixed(2)}元</div>
                })
                }
            </TableCell>
        else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right"></TableCell>
    }

    function renderIntCell4(row, index, hasEmpty) {
        if (row.ii != undefined && row.ii.a.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                {row.ii.a[index].ints.map(function (item, aindex, array) {
                    let spc = array.length > 1 ? 2 : 0
                    if (item.fill === 0) {
                        return <div key={Math.random()} ><Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, marginBottom: spc }} className={classes.button1} >已完成 {item.day}天</Button></div>
                        // return <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: 5, marginBottom: 5}}>{item.day}</div>;
                    } else if (item.fill === 1) {
                        return <div key={Math.random()} ><Button size="small" variant="outlined" style={{ minWidth: 100, color: '#469447', marginBottom: spc }} className={classes.button1}>未完成 {item.day}天</Button></div>
                    } else if (item.fill === 2) {
                        return <div key={Math.random()}><Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor, marginBottom: spc }} className={classes.button1} >即將開始</Button></div>
                    } else if (item.fill === 3) {
                        return <div key={Math.random()}><Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor, marginBottom: spc }} className={classes.button1} >-</Button></div>
                    }
                })
                }
            </TableCell>
        else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderIntCell5(row, index, hasEmpty) {
        if (row.ii != undefined && row.ii.a.length > index)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                {row.ii.a[index].ints.map(function (item, aindex, array) {
                    let spc = array.length > 1 ? 8 : 5
                    return <div key={Math.random()} style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: spc, marginBottom: spc }} >{item.croi}%</div>
                })
                }
            </TableCell>
        else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right"></TableCell>
    }

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />
            {open && <InterestAllDetail row={row} setOpen={setOpen} open={open} />}

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />

                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>

                                            </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 2, paddingRight: 2, borderLeft: '1px solid #dbdbdb' }} size="small" align="center">
                                                {renderInpriceStatus(row.ii.psend)}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 2, paddingRight: 2 }} size="small" align="center">
                                                {renderInpriceStatus(row.ii.tprate)}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 2, paddingRight: 2 }} size="small" align="center">{renderDay(row.ii.pdate)}</TableCell>

                                            {rendermeetingdate(row)}
                                            {rendermeetingdate1(row)}
                                            {rendermeetingname(row)}
                                            {rendermeetingcash(row)}
                                            {renderyield(row)}
                                            {renderStockyield(row)}
                                            {renderAllyield(row)}
                                            {renderBackyield(row)}
                                            {renderRate(row)}
                                            {renderMeetingEmpty(row)}

                                            {renderIntCell2(row, 0, false)}
                                            {renderIntCell3(row, 0, false)}
                                            {renderIntCell4(row, 0, false)}
                                            {renderIntCell5(row, 0, false)}
                                            {renderEmpty(row, 0)}


                                            {renderIntCell2(row, 1, false)}
                                            {renderIntCell3(row, 1, false)}
                                            {renderIntCell4(row, 1, false)}
                                            {renderIntCell5(row, 1, false)}
                                            {renderEmpty(row, 1)}


                                            {renderIntCell2(row, 2, false)}
                                            {renderIntCell3(row, 2, false)}
                                            {renderIntCell4(row, 2, false)}
                                            {renderIntCell5(row, 2, false)}
                                            {renderEmpty(row, 2)}

                                            {renderIntCell2(row, 3, false)}
                                            {renderIntCell3(row, 3, false)}
                                            {renderIntCell4(row, 3, false)}
                                            {renderIntCell5(row, 3, false)}
                                            {renderEmpty(row, 3)}

                                            {renderIntCell2(row, 4, false)}
                                            {renderIntCell3(row, 4, false)}
                                            {renderIntCell4(row, 4, false)}
                                            {renderIntCell5(row, 4, false)}
                                            {renderEmpty(row, 4)}


                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(IntDetail);
