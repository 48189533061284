import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BrokerList from '../details/BrokerList'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

// buym : Long, price : Double, buy : List[DealerData], sellm : Long, sell : List[DealerData]
// case class DealerData(dealerId : String, buysell : Long, cost : Double)
function createData(name, dealer, buysell, cost) {
    return { name, dealer, buysell, cost };
}


const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    button1: {
      minWidth: 110,
    }
  }));

export default function DealerList1(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);
    
    const [opendealer, setOpenDealer] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opendname, setOpenDname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (dealer_id, dealername) => event => {
        setOpenDealer(dealer_id)
        setOpenDname(dealername)        
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenDealer(null)
        setOpenDname(null) 
        setConcentOpen(false);
    };
    

    useEffect(() => {
        if (props.open) fectchStockData(props.stockId, props.day)
    }, [props.stockId]);


    function fectchStockData(stockId, day) {
        if (stockId != null) {
            let a = {
                sid: stockId,
                day: day
            };


            fetch("/jstock/v1/stock/dealerlist1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (props.buylist) {
                            let dd = result.buy.map(row =>
                                createData(row.name, row.dealer_id, row.buysell, row.cost)
                            )
                            setBuyselldata(dd)
                        } else {
                            let ss = result.sell.map(row =>
                                createData(row.name, row.dealer_id, row.buysell, row.cost)
                            )
                            setBuyselldata(ss)
                        }
                    },
                    error => {
                    }
                );
        }
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <BrokerList open={concentopen} handleClose={handleClosecc} stockId={props.stockId} stockname={props.name} dealerId={opendealer} dealername={opendname}></BrokerList>
            {props.buylist?<DialogTitle id="form-dialog-title">買超主力券商</DialogTitle>:<DialogTitle id="form-dialog-title">賣超主力券商</DialogTitle>}

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" >券商代號</StyledTableCell>
                                <StyledTableCell align="center" >券商名稱</StyledTableCell>
                                <StyledTableCell align="center">買賣超</StyledTableCell>
                                {props.buylist?<StyledTableCell align="center">均買價</StyledTableCell>:<StyledTableCell align="center">均賣價</StyledTableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.dealer}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.dealer}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Button onClick={handleClickOpencc(row.dealer, row.name)} variant="outlined" style={{ minWidth: 120  }} className={classes.button1} >
                                        {row.name.length > 8 ? `${row.name.substring(0, 8)}...` : row.name}
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {(row.buysell / 1000).toFixed(0)}張
                                    </StyledTableCell>
                                    <StyledTableCell align="left" >
                                        {row.cost}元
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
