import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";
import RuleDesc from '../easyrules/RuleDesc';

const styles = theme => ({
  root: {
  }
})

class Rule462 extends Component {
    render() {
        return (
            <RuleDesc
            id="462"
            t1="上升楔型"
            desc=""
            ruleChange={this.props.ruleChange}
            rules={this.props.rules}
        ></RuleDesc>
          );
    }
  }
  

export default withRouter(withStyles(styles)(Rule462));