import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import Slide from '@material-ui/core/Slide';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import MASetting from '../mobile/MASetting'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const StyledTableCell = withStyles(theme => ({
    head: {
        // backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
    },
    body: {
        fontSize: 16,
    },
}))(TableCell);


const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function BigTable1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [cdata, setCData] = React.useState(null);
    const [option, setOption] = React.useState(genoption1());
    const [option1, setOption1] = React.useState(genoption1());
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = () => {
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setConcentOpen(false);
        runFectchAPII();
    };

    useEffect(() => {
        if (props.value === 0) runFectchAPII();
    }, [props.value]);


    function genData(m1, g1, d1) {
        let k = genoption1()
        k['xAxis'] = {
            data: m1.map(function (item) {
                return item.x;
            })
        }
        let series = []
        let y1 = {
            name: '加權指數',
            type: 'line',
            data: m1.map(function (item) {
                return item.y;
            }),
            smooth: true,
            showSymbol: false,
        }
        let y2 = {
            name: '黃金交叉數量',
            type: 'line',
            data: g1.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            showSymbol: false,
        }

        let y3 = {
            name: '死亡交叉數量',
            type: 'line',
            data: d1.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            showSymbol: false,
        }

        series.push(y1)
        series.push(y2)
        series.push(y3)

        k['series'] = series
        return k
    }

    const runFectchAPII = () => {
        let a = {
        };

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (localStorage.getItem('sma') != null && localStorage.getItem('sma') != 5)
            a["d1"] = parseInt(localStorage.getItem('sma'))
        if (localStorage.getItem('lma') != null && localStorage.getItem('lma') != 20)
            a["d2"] = parseInt(localStorage.getItem('lma'))
        if (localStorage.getItem('dma') != null && localStorage.getItem('dma') != 1)
            a["d3"] = parseInt(localStorage.getItem('dma'))

        fetch("/jstock/v1/stock/bigtable?", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.m != null && result.m.length > 0) {
                        setCData(result)

                        let k1 = genData(result.m, result.g, result.d)
                        setOption(k1)

                        let k2 = genData(result.m1, result.g1, result.d1)
                        setOption1(k2)
                    }

                }
            );
    }

    function genoption1() {
        let k = {
            animation: false,
            title: {
                show: false
            },
            legend: {
                data: ['加權指數', '黃金交叉數量', '死亡交叉數量'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true,
                    
                }, {
                    type: 'value',
                    scale: true
                }, {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }

    return (
        <div>
            <MASetting open={concentopen} handleClose={handleClosecc} handleOk={handleClosecc}></MASetting>

            <Grid container >
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}>
                        <Typography variant="h6" id="tableTitle">
                            {"集中市場與均線交叉"}
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 10 }} color="primary" size="small" onClick={handleClickOpencc}>調整參數</Button>
                    </div>
                </Grid>


                {cdata != null ? <Grid item xs={12} sm={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無集中市場與均線交叉資料</div>}

                {cdata != null ? <Grid item xs={12} sm={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ marginTop: 25 }}><Table className={classes.table} aria-label="caption table" size="small">
                        <TableBody>
                            <StyledTableRow key={'0'}>
                                <StyledTableCell component="th" scope="row">
                                    {'日期'}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {cdata.srv1 != "" ? cdata.srv1 : '-'}
                                </StyledTableCell>
                            </StyledTableRow >
                            <StyledTableRow key={'1'}>
                                <StyledTableCell component="th" scope="row">
                                    {'加權指數'}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {cdata.srv4 != -99999 ? cdata.srv4 : '-'}
                                </StyledTableCell>
                            </StyledTableRow >
                            <StyledTableRow key={'4'}>
                                <StyledTableCell component="th" scope="row">
                                    {'漲跌'}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {cdata.incv != -99999 && cdata.incp != -99999 ? <font style={{ color: cdata.incp < 0 ? BColor.downcolor : BColor.upcolor }} >{cdata.incv + "(" + cdata.incp + "%)"}</font> : '-'}
                                </StyledTableCell>
                            </StyledTableRow >


                            <StyledTableRow key={'2'}>
                                <StyledTableCell component="th" scope="row">
                                    {'黃金交叉數量'}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {cdata.srv2 != -99999 ? cdata.srv2 + "支股票" : '-'}
                                </StyledTableCell>
                            </StyledTableRow >
                            <StyledTableRow key={'3'}>
                                <StyledTableCell component="th" scope="row">
                                    {'死亡交叉數量'}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {cdata.srv3 != -99999 ? cdata.srv3 + "支股票" : '-'}
                                </StyledTableCell>
                            </StyledTableRow >
                        </TableBody>
                    </Table></div>
                </Grid> : null}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            櫃買市場與均線交叉
                        </Typography>
                    </div>
                </Grid>


                {cdata != null ? <Grid item xs={12} sm={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無櫃買市場與均線交叉資料</div>}

                {cdata != null ? <Grid item xs={12} sm={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div style={{ marginTop: 25 }}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <TableBody>
                                <StyledTableRow key={'0a'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日期'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {cdata.srv11 != "" ? cdata.srv11 : '-'}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'1a'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'加權指數'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {cdata.srv14 != -99999 ? cdata.srv14 : '-'}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'4a'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'漲跌'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {cdata.incv1 != -99999 && cdata.incp1 != -99999 ? <font style={{ color: cdata.incp1 < 0 ? BColor.downcolor : BColor.upcolor }} >{cdata.incv1 + "(" + cdata.incp1 + "%)"}</font> : '-'}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'2a'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'黃金交叉數量'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {cdata.srv12 != -99999 ? cdata.srv12 + "支股票" : '-'}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'3a'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'死亡交叉數量'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {cdata.srv13 != -99999 ? cdata.srv13 + "支股票" : '-'}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </div>

                </Grid> : null}

            </Grid>
        </div>
    );
}

