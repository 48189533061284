import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function WilliamSetting(props) {
  //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const hrref = React.createRef();

    const handleOk = () => {
      if(yref.current.value != null && yref.current.value != "")
      localStorage.setItem('wis', parseInt(yref.current.value));

      if(hrref.current.value != null && hrref.current.value != "")
      localStorage.setItem('wil', parseFloat(hrref.current.value));

      props.handleOk()
    };


  function yy() {
    return localStorage.getItem('wis') === null ? 7 : localStorage.getItem('wis')
  }

  function hR() {
    return localStorage.getItem('wil') === null ?  14 : localStorage.getItem('wil')
  }  

  
  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">威廉指標參數</DialogTitle>

        <DialogContent>

          <br />
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label="短天期(預設:7天)"
            type="text"
            fullWidth
            defaultValue={yy()}
            inputRef={yref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="hr"
            label="長天期(預設:14天)"
            type="text"
            fullWidth
            defaultValue={hR()}
            inputRef={hrref}
            InputLabelProps={{
              shrink: true,
            }}
          />

        </DialogContent>
        {localStorage.getItem('noad') === "1" ? <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>:<DialogActions>
          <Button onClick={props.handleClose} color="primary">
            關閉（訂閱專屬功能）
          </Button>
        </DialogActions>}
      </Dialog>
  );
}
