import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    // CanvasRenderer,
    SVGRenderer,
} from 'echarts/renderers';

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function CashChart1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(getDefaultAChartOption(props.row.chart1))
    const [options2, setOptions2] = React.useState(getDefaultAChartOption(props.row.chart2))
    const [options3, setOptions3] = React.useState(getDefaultAChartOption(props.row.chart3))
    const [options4, setOptions4] = React.useState(getDefaultAChartOption(props.row.chart4))


    function getDefaultAChartOption(data) {
        let option = {
            legend: {
                data: ['昂貴區', '合理區', '便宜區', '股價',]
            },
            grid: {
                left: 15,
                right: 60,
                bottom: '15%',
                containLabel: true,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis:
            {
                type: 'time',
            },
            yAxis:
            {
                type: 'value',
                scale: true,
            },
        };

        if (data != null) {
            let seriesa = []
            if (data.h != null && data.h.length > 0) {
                seriesa.push({
                    name: '昂貴區',
                    type: 'line',
                    color: "#ff7171",
                    areaStyle: {
                        // opacity: 0.8,
                        // color: "#63dbff"
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.h != null ? data.h : [],
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    }
                })
            }

            if (data.m != null && data.m.length > 0) {
                seriesa.push({
                    name: '合理區',
                    type: 'line',
                    color: "#ffdb5f",
                    areaStyle: {
                        // opacity: 0.8,
                        // color: "#ffae45"
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.m != null ? data.m : [],
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    }
                })
            }

            if (data.l != null && data.l.length > 0) {
                seriesa.push(
                    {
                        name: '便宜區',
                        type: 'line',
                        color: "#a0e080",
                        areaStyle: {
                            // opacity: 0.8,
                            // color: "#9ee68a"
                        },
                        symbol: 'none',
                        emphasis: {
                            focus: 'series'
                        },
                        data: data.l != null ? data.l : [],
                        labelLayout: {
                            moveOverlap: 'shiftY'
                        },
                        endLabel: {
                            show: true
                        }
                    }
                )
            }

            if (data.pp != null) {
                seriesa.push({
                    name: '股價',
                    type: 'line',
                    data: data.pp,
                    smooth: true,
                    symbol: 'none',
                    lineStyle: {
                        width: 2
                    },
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    z: 10
                })
            }

            option['series'] = seriesa
        }

        if (props.ismobile) {
            option['xAxis']['axisPointer'] = {
                show: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }
        }

        return option
    }


    useEffect(() => {
        if (props.row.chart1 != null) setOptions(getDefaultAChartOption(props.row.chart1))
        if (props.row.chart2 != null) setOptions2(getDefaultAChartOption(props.row.chart2))
        if (props.row.chart3 != null) setOptions3(getDefaultAChartOption(props.row.chart3))
        if (props.row.chart4 != null) setOptions4(getDefaultAChartOption(props.row.chart4))
    }, [props.row.chart1, props.row.chart2, props.row.chart3, props.row.chart4]);

    function getString() {
        return "目前無相關的趨勢圖"
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 10 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            股價盈餘比
                        </Typography>
                    </div>

                    {props.row.chart1 != undefined ? <ReactEChartsCore
                        style={{ height: 400, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={6} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 10 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            股價營收比
                        </Typography>
                    </div>

                    {props.row.chart2 != undefined ? <ReactEChartsCore
                        style={{ height: 400, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options2}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={6} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 10 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            股價淨值比
                        </Typography>
                    </div>

                    {props.row.chart3 != undefined ? <ReactEChartsCore
                        style={{ height: 400, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options3}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={6} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 10 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            股價乖離
                        </Typography>
                    </div>

                    {props.row.chart4 != undefined ? <ReactEChartsCore
                        style={{ height: 400, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options4}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

            </Grid>
        </div >
    );
}