import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BIASList from '../details/BIASList'
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

echarts.use(
    [ScatterChart, AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function BIASMDetail(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [openstock, setOpenstock] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const [options, setOptions] = React.useState(null)
    const [loading, setLoading] = React.useState(false);

    const handleClickOpencc = (stock_no) => event => {
        setOpenstock(stock_no)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setConcentOpen(false);
    };


    function renderavg5(row) {
        if (row.bias != null && row.bias != undefined)
            return <StyledTableCell align="left">
                {row.bias.avg5}
            </StyledTableCell>
    }

    function renderbi5(row, stockno) {
        if (row.bias != null && row.bias != undefined) {
            let status = row.bias.status5
            if (status === 2 && row.bias.bi5 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                        過熱 {row.bias.bi5}%
                    </Button>
                </StyledTableCell>
            } else if (status === 1 && row.bias.bi5 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
                        正常 {row.bias.bi5}%
                    </Button>
                </StyledTableCell>
            } else if (status === 0 && row.bias.bi5 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                        正常 {row.bias.bi5}%
                    </Button>
                </StyledTableCell>
            } else if (status === 3 && row.bias.bi5 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
                        正常 {row.bias.bi5}%
                    </Button>
                </StyledTableCell>
            } else if (status === 4 && row.bias.bi5 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                        跌深 {row.bias.bi5}%
                    </Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
                        -
                    </Button>
                </StyledTableCell>
            }
        }
    }


    function renderup5(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status5 === 2) color = BColor.upcolorbox

            if (row.bias.up5 != -99999.0) {
                let finalprice = (row.bias.avg5 * (row.bias.up5 / 100) + row.bias.avg5).toFixed(2);
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color, marginRight: 1 }} className={classes.button1} >
                        {finalprice}元
                    </Button><br />
                    <Button variant="outlined" style={{ marginTop: 1, minWidth: 90, color: color }} className={classes.button1} >
                        {row.bias.up5}%
                    </Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color }} className={classes.button1} >
                        -
                    </Button>
                </StyledTableCell>
            }
        }
    }


    function renderdown5(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status5 === 4) color = BColor.downcolorbox

            if (row.bias.down5 != -99999.0) {
                let finalprice = (row.bias.avg5 * (row.bias.down5 / 100) + row.bias.avg5).toFixed(2);
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color, marginRight: 1 }} className={classes.button1} >
                        {finalprice}元
                    </Button><br />
                    <Button variant="outlined" style={{ marginTop: 1, minWidth: 90, color: color }} className={classes.button1} >
                        {row.bias.down5}%
                    </Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color }} className={classes.button1} >
                        -
                    </Button>
                </StyledTableCell>
            }
        }

    }



    function renderavg20(row) {
        if (row.bias != null && row.bias != undefined)
            return <StyledTableCell align="left">
                {row.bias.avg20}
            </StyledTableCell>
    }



    function renderbi20(row, stockno) {
        if (row.bias != null && row.bias != undefined) {
            let status = row.bias.status20
            if (status === 2 && row.bias.bi20 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                        過熱 {row.bias.bi20}%
                    </Button>
                </StyledTableCell>
            } else if (status === 1 && row.bias.bi20 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
                        正常 {row.bias.bi20}%
                    </Button>
                </StyledTableCell>
            } else if (status === 0 && row.bias.bi20 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                        正常 {row.bias.bi20}%
                    </Button>
                </StyledTableCell>
            } else if (status === 3 && row.bias.bi20 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
                        正常 {row.bias.bi20}%
                    </Button>
                </StyledTableCell>
            } else if (status === 4 && row.bias.bi20 != -99999.0) {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                        跌深 {row.bias.bi20}%
                    </Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
                        -
                    </Button>
                </StyledTableCell>
            }
        }
    }


    function renderup20(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status20 === 2) color = BColor.upcolorbox

            if (row.bias.up20 != -99999.0) {
                let finalprice = (row.bias.avg20 * (row.bias.up20 / 100) + row.bias.avg20).toFixed(2);
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color, marginRight: 1 }} className={classes.button1} >
                        {finalprice}元
                    </Button><br />
                    <Button variant="outlined" style={{ marginTop: 1, minWidth: 90, color: color }} className={classes.button1} >
                        {row.bias.up20}%
                    </Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color }} className={classes.button1} >
                        -
                    </Button>
                </StyledTableCell>
            }
        }
    }


    function renderdown20(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status20 === 4) color = BColor.downcolorbox

            if (row.bias.down20 != -99999.0) {
                let finalprice = (row.bias.avg20 * (row.bias.down20 / 100) + row.bias.avg20).toFixed(2);
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color, marginRight: 1 }} className={classes.button1} >
                        {finalprice}元
                    </Button><br />
                    <Button variant="outlined" style={{ marginTop: 1, minWidth: 90, color: color }} className={classes.button1} >
                        {row.bias.down20}%
                    </Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 90, color: color }} className={classes.button1} >
                        -
                    </Button>
                </StyledTableCell>
            }
        }
    }

    function getDefaultAChartOption() {
        let newOption = {
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '40',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            visualMap: {
                show: false,
                type: 'piecewise',
                seriesIndex: 1,
                dimension: 2,
                pieces: [{
                    value: 1,
                    color: "#faa739"
                }, {
                    value: 0,
                    color: "#31a0a8"
                }]
            },            
        };

        return newOption
    }

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
            sid: props.row.market_company.stock_no
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/sar", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        let newOption = getDefaultAChartOption()
                        newOption['yAxis'] = [
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                            }
                        ]

                        let x1 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => { return v.x }),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 20,
                            min: 'dataMin',
                            max: 'dataMax',
                        }

                        if (props.ismobile) {
                            let xpoint = {
                                type: 'shadow',
                                label: { show: false },
                                triggerTooltip: true,
                                handle: {
                                    show: true,
                                    margin: 25,
                                    color: '#1868d9',
                                    size: 30
                                }
                            }

                            x1['axisPointer'] = xpoint
                        }


                        newOption['xAxis'] = [x1]
                        newOption['series'] = [
                            {
                                name: '日K',
                                type: 'candlestick',
                                data: result.map(v => { return v.y }),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                            },
                            {
                                name: 'SAR',
                                type: 'scatter',
                                data: result.map(v => { return [v.x, v.v1, v.v3] }),
                                symbol: "circle",
                                symbolSize: 6,
                            }
                        ]

                        if (props.ismobile) {
                            newOption['tooltip'] = getMobileTooltip()
                            newOption['axisPointer'] = getAxisPointer()
                            newOption['dataZoom'] = getMobileDataZoom(80, 100)

                        } else {
                            newOption['tooltip'] = getComputerTooltip()
                            newOption['axisPointer'] = getAxisPointer()
                            newOption['dataZoom'] = getComputerDataZoom(50, 100, 300)
                        }

                        setLoading(false)
                        setOptions(newOption)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);

    function getString() {
        return "目前無相關的Sar指標"
    }

    return (
        <div>
            <BIASList open={concentopen} handleClose={handleClosecc} stockId={openstock}></BIASList>

            {options != null ? <Grid item xs={12} sm={12} >
                <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        SAR
                    </Typography>
                </div>

                <ReactEChartsCore
                    theme={theme.palette.type}
                    style={{ height: 400, marginTop: 0 }}
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={false}
                    showLoading={loading}
                />
            </Grid> : null}

            {props.row.bias != null && props.row.bias != undefined ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            5日均線乖離率
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'均線'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'均線'}
                                    </StyledTableCell>
                                    {renderavg5(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'乖離率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'乖離率'}
                                    </StyledTableCell>
                                    {renderbi5(props.row, props.row.market_company.stock_no)}
                                </StyledTableRow >

                                <StyledTableRow key={'天花板'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'天花板價格'}
                                    </StyledTableCell>
                                    {renderup5(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'地板'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'地板價格'}
                                    </StyledTableCell>
                                    {renderdown5(props.row)}
                                </StyledTableRow >
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            20日均線乖離率
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'均線'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'均線'}
                                    </StyledTableCell>
                                    {renderavg20(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'乖離率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'乖離率'}
                                    </StyledTableCell>
                                    {renderbi20(props.row, props.row.market_company.stock_no)}
                                </StyledTableRow >

                                <StyledTableRow key={'天花板'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'天花板價格'}
                                    </StyledTableCell>
                                    {renderup20(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'地板'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'地板價格'}
                                    </StyledTableCell>
                                    {renderdown20(props.row)}
                                </StyledTableRow >
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Grid>

            </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                暫無任何乖離率資料
            </Typography>}
        </div >
    );
}

