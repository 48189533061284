import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
},
    button1: {
    },

}));

export default function BigCusCalendar(props) {
    const classes = useStyles();

    const [state, setState] = React.useState([
        {
            startDate: moment(props.bigtitle.le, 'YYYYMMDD').toDate(),
            endDate: moment(props.bigtitle.ls, 'YYYYMMDD').toDate(),
            key: 'selection'
        }
    ]);

    // const [finalDate, setfinalDate] = React.useState([
    //     {
    //         startDate: moment(props.bigtitle.le, 'YYYYMMDD').toDate(),
    //         endDate: moment(props.bigtitle.ls, 'YYYYMMDD').toDate(),
    //         key: 'selection'
    //     }
    // ]);

    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
        // setState(finalDate)
    };

    const handleCancel = () => {
        setOpen1(false);
        // setState(finalDate)
        localStorage.removeItem("cal_wsdd")
        localStorage.removeItem("cal_wldd")
        localStorage.removeItem("ld_param")
        props.bigupdate()
    };

    const handleOk = () => {
        setOpen1(false);
        // setfinalDate(state)

        localStorage.setItem('cal_wsdd', moment(state[0].startDate).format('YYYYMMDD'));
        localStorage.setItem('cal_wldd', moment(state[0].endDate).format('YYYYMMDD'));

        // setState([
        //     {
        //         startDate: moment(props.bigtitle.le, 'YYYYMMDD').toDate(),
        //         endDate: moment(props.bigtitle.ls, 'YYYYMMDD').toDate(),
        //         key: 'selection'
        //     }
        // ])

        props.bigupdate()
    };

    useEffect(() => {
        setState([
            {
                startDate: moment(props.bigtitle.le, 'YYYYMMDD').toDate(),
                endDate: moment(props.bigtitle.ls, 'YYYYMMDD').toDate(),
                key: 'selection'
            }
        ])
      }, [props.bigtitle]);
    

    return (
        <div>
            【{moment(props.bigtitle.le).format('YYYY/MM/DD')}】~【{moment(props.bigtitle.ls).format('YYYY/MM/DD')}】
            {/* 【{moment(finalDate[0].startDate).format('YYYY/MM/DD')}】~【{moment(finalDate[0].endDate).format('YYYY/MM/DD')}】 */}
            <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8' }} className={classes.button1} onClick={handleClickOpen1}>
                變更區間（系統會自動調整成週）
            </Typography>

            <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title">
                <DateRange
                    locale={locales['zhTW']}
                    editableDateInputs={true}
                    onChange={item => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    minDate={moment(props.bigtitle.mle, 'YYYYMMDD').toDate()}
                    maxDate={moment(props.bigtitle.mls, 'YYYYMMDD').toDate()}
                    ranges={state}
                />
                <DialogActions>
                    <Button onClick={handleCancel} style={{ color: "#5c5c5c", fontSize: 12 }}>
                        最新一週（恢復預設）
          </Button>
                    <Button onClick={handleOk} style={{ color: "#5c5c5c", fontSize: 12 }}>
                        特定一週
          </Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}
