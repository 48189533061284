import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import Slide from '@material-ui/core/Slide';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import RevList from '../details/RevList'

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MRev1Detail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };


    function renderValueA(value, stockno, stockName) {
        let colorx = "#000205"
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = '持續大幅增強'
        } else if (value === 1) {
            colorx = BColor.upcolorbox
            word = '大幅增強'
        } else if (value === 2) {
            colorx = BColor.upcolorbox
            word = '小幅增強'
        } else if (value === 3) {
            colorx = BColor.downcolorbox
            word = '小幅減弱'
        } else if (value === 4) {
            colorx = BColor.downcolorbox
            word = '大幅減弱'
        } else if (value === 5) {
            colorx = BColor.downcolorbox
            word = '持續大幅減弱'
        } else {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValueB(value, unit, stockno, stockName) {
        let colorx = "#000205"

        if (value > 0) {
            colorx = BColor.upcolorbox
        } else if (value < 0) {
            colorx = BColor.downcolorbox
        } else {
            colorx = BColor.faircolor
        }
        // {(value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}
        return <div variant="outlined" style={{ color: colorx }} >{value}{unit}</div>
    }

    function renderValueC(value, unit, stockno, stockName) {
        let colorx = "#000205"
        return <div variant="outlined" style={{ color: colorx }}>{(value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}{unit}</div>
    }

    function renderValueD(value, stockno, stockName) {
        let colorx = "#000205"
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = '往上'
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = '往下'
        } else if (value === 2) {
            colorx = "#000205"
            word = '持平'
        } else {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValueE(value, stockno, stockName) {
        let colorx = "#000205"
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = '金叉'
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = '死叉'
        } else if (value === 2) {
            colorx = "#000205"
            word = '無交叉'
        } else {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function genData1() {
        let k = genoption1()

        k['xAxis'] = {
            data: props.row.chart6.pp.map(function (item) {
                return item.x;
            })
        }
        let series = []

        let y1 = {
            name: '營收',
            type: 'bar',
            data: props.row.chart6.pp.map(function (item) {
                return item.y;
            }),
        }

        let y2 = {
            name: '短期(3月)',
            type: 'line',
            data: props.row.chart6.h.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y3 = {
            name: '中期(6月)',
            type: 'line',
            data: props.row.chart6.m.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y4 = {
            name: '長期(12月)',
            type: 'line',
            data: props.row.chart6.l.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        let y5 = {
            name: '最長期(60月)',
            type: 'line',
            data: props.row.chart6.ll.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        series.push(y1)
        series.push(y2)
        series.push(y3)
        series.push(y4)
        series.push(y5)

        k['series'] = series
        return k
    }


    function genoption1() {
        let k = {
            legend: {
                data: ['營收', '短期(3月)', '中期(6月)', '長期(12月)', '最長期(60月)'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }


    function genData2() {
        let k = genoption2()

        k['xAxis'] = {
            data: props.row.chart7.pp.map(function (item) {
                return item.x;
            })
        }
        let series = []

        let y1 = {
            name: '營收',
            type: 'bar',
            data: props.row.chart7.pp.map(function (item) {
                return item.y;
            }),
        }


        let y2 = {
            name: '短期(3月)',
            type: 'line',
            data: props.row.chart7.h.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y3 = {
            name: '中期(6月)',
            type: 'line',
            data: props.row.chart7.m.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y4 = {
            name: '長期(12月)',
            type: 'line',
            data: props.row.chart7.l.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        let y5 = {
            name: '最長期(60月)',
            type: 'line',
            data: props.row.chart7.ll.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        series.push(y1)
        series.push(y2)
        series.push(y3)
        series.push(y4)
        series.push(y5)
        
        k['series'] = series
        return k
    }


    function genoption2() {
        let k = {
            legend: {
                data: ['營收', '短期(3月)', '中期(6月)', '長期(12月)', '最長期(60月)'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }



    useEffect(() => {
        if (props.row.chart6 != null) setOption(genData1())
        if (props.row.chart7 != null) setOption1(genData2())
    }, [props.row]);


    return (
        <div>
            <RevList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></RevList>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            年成長率動能圖
                        </Typography>
                    </div>
                </Grid>


                {option != null && props.row.chart6 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無年成長率動能圖</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            平均營收動能圖
                        </Typography>
                    </div>
                </Grid>


                {option1 != null && props.row.chart7 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無平均營收動能圖</div>}
            </Grid>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            動能總表
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'成長率動能'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'成長率動能'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueA(props.row.grev.ds, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'成長率交叉'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'成長率交叉'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueE(props.row.grev.ss, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'營收動能'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營收動能'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueA(props.row.grev.dr, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'營收交叉'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營收交叉'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueE(props.row.grev.sr, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            短期營收（3個月）
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'1wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'YoY'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueB(props.row.grev.yoy3, "%")}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'YoY方向'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueD(props.row.grev.dyoy3, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'1wmc'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均營收'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueC((props.row.grev.avg_rev3 / 1000000).toFixed(1), "百萬")}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            平均營收
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wmc'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均營收方向'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueD(props.row.grev.drev3, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            中期營收（6個月）
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'1wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'YoY'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueB(props.row.grev.yoy6, "%")}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'YoY方向'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueD(props.row.grev.dyoy6, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'1wmc'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均營收'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueC((props.row.grev.avg_rev6 / 1000000).toFixed(1), "百萬")}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wmc'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均營收方向'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueD(props.row.grev.drev6, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            長期營收（12個月）
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'1wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'YoY'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueB(props.row.grev.yoy12, "%")}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'YoY方向'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueD(props.row.grev.dyoy12, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'1wmc'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均營收'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueC((props.row.grev.avg_rev12 / 1000000).toFixed(1), "百萬")}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wmc'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均營收方向'}
                                    </StyledTableCell>

                                    {props.row.grev != null && props.row.grev != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValueD(props.row.grev.drev12, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div>
    );
}

