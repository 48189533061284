import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import GrainIcon from '@material-ui/icons/Grain';
import LocalPlay from '@material-ui/icons/LocalPlay';
import HdSharp from '@material-ui/icons/HdSharp';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    fontSize: 12,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 18,
    height: 18,
  },
}));

function handleClick(event) {
  event.preventDefault();
}

export default function IconBreadcrumbs(props) {
  const classes = useStyles();

  function getIcon() {
    if (props.tp === 0) return <GrainIcon className={classes.icon} />
    else if (props.tp === 1) return <LocalPlay className={classes.icon}></LocalPlay>
    else if (props.tp === 3) return <HdSharp className={classes.icon}></HdSharp>
    return <GrainIcon className={classes.icon} />
  }

  function getWord() {
    if (props.tp === 0) return '選股規則'
    else if (props.tp === 1) return '自選清單'
    else if (props.tp === 2) return '選股策略' 
    else if (props.tp === 3) return '交易日誌'         
    else return ''
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 5 }}>
      <Link color="inherit" href="/" onClick={handleClick} className={classes.link}>
        <HomeIcon className={classes.icon} />
        股票挖土機
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        {getIcon()}
        {getWord()}
      </Typography>
    </Breadcrumbs>
  );
}
