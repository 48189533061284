import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BIASList from '../details/BIASList'
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

echarts.use(
    [ScatterChart, AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function FutureTurn(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [loading, setLoading] = React.useState(false);


    function getDefaultAChartOption() {
        let newOption = {
            title: [
                {
                    left: 'center',
                    text: '轉折預測圖(日)'
                },
                {
                    left: 'center',
                    text: '轉折預測圖(周)',
                    top: '320',
                },
                {
                    left: 'center',
                    text: '轉折預測圖(月)',
                    top: '620',
                }
            ],
            grid: [
                {
                    top: '30',
                    left: '40',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '350',
                    left: '40',
                    right: '20',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '650',
                    left: '40',
                    right: '20',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        return newOption
    }

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
            sid: props.row.market_company.stock_no
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/futureturn", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0 && result.data != null) {
                        let newOption = getDefaultAChartOption()
                        newOption['yAxis'] = [
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                            },
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                                gridIndex: 1,
                            },
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                                gridIndex: 2,
                            }
                        ]

                        let x1 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.data.d.map(v => { return v.x }),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 20,
                            min: 'dataMin',
                            max: 'dataMax',
                        }

                        let x2 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.data.w.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 1,
                        }

                        let x3 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.data.m.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 2,
                        }

                        if (props.ismobile) {
                            let xpoint = {
                                type: 'shadow',
                                label: { show: false },
                                triggerTooltip: true,
                                handle: {
                                    show: true,
                                    margin: 25,
                                    color: '#1868d9',
                                    size: 30
                                }
                            }

                            x1['axisPointer'] = xpoint
                            x2['axisPointer'] = xpoint
                            x3['axisPointer'] = xpoint
                        }


                        newOption['xAxis'] = [x1, x2, x3]

                        newOption['series'] = [
                            {
                                name: '日K',
                                type: 'candlestick',
                                data: result.data.d.map(v => { return v.y }),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                            },
                            {
                                name: '轉折線',
                                type: 'line',
                                data: result.data.d.map(v => { return v.v1 }),
                            }, {
                                name: '周K',
                                type: 'candlestick',
                                data: result.data.w.map(v => { return v.y }),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                            },
                            {
                                name: '轉折線',
                                type: 'line',
                                data: result.data.w.map(v => { return v.v1 }),
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                            }, {
                                name: '月K',
                                type: 'candlestick',
                                data: result.data.m.map(v => { return v.y }),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                                xAxisIndex: 2,
                                yAxisIndex: 2,
                            },
                            {
                                name: '轉折線',
                                type: 'line',
                                data: result.data.m.map(v => { return v.v1 }),
                                xAxisIndex: 2,
                                yAxisIndex: 2,
                            },
                        ]

                        if (props.ismobile) {
                            newOption['tooltip'] = getMobileTooltip(0)
                            // newOption['axisPointer'] = getAxisPointer()
                            newOption['dataZoom'] = getMobileDataZoom(80, 100)

                        } else {
                            newOption['tooltip'] = getComputerTooltip(0)
                            // newOption['axisPointer'] = getAxisPointer()
                            newOption['dataZoom'] = {
                                type: 'inside',
                                xAxisIndex: 'all',
                                start: 70,
                                end: 100,
                            }
                            // newOption['dataZoom'] = getComputerDataZoom(50, 100, 300)
                        }

                        setLoading(false)
                        setOptions(newOption)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);


    return (
        <div>
            {options != null ? <Grid item xs={12} sm={12} >
                <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        轉折預測圖
                    </Typography>

                </div>
                <Typography className={classes.title} variant="body2" id="tableTitle">
                    預測某日價格 = 某日三天前的股價 * 2 - 某日六天前的股價
                </Typography>

                <ReactEChartsCore
                    theme={theme.palette.type}
                    style={{ height: 950, marginTop: 20 }}
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={false}
                    showLoading={loading}
                />
            </Grid> : <Typography className={classes.title} variant="body2" id="tableTitle">
                    目前無任何轉折預測圖
                </Typography>}

        </div >
    );
}

