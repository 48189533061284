import React from 'react'
import { BrowserRouter as Router, Routes, Route, HashRouter, Switch } from 'react-router-dom'

import Home from "./components/Home"
import Dashboard2 from './components/Dashboard3'
import Mdashboard from './components/mobile3/Mdashboard3'
import TransReport from './components/report/TransReport'

const a = () => {
  return localStorage.getItem("run_computer") != null ? localStorage.getItem("run_computer") : "0"
}


export default props => (
  <Router>
    <HashRouter>
      <Switch>
        <Route exact path='/report' component={TransReport} />
        <Route exact path='/' component={Home} />
        <Route exact path='/rules' component={Dashboard2} />
        <Route exact path='/mdashboard' component={a() === "0" ? Mdashboard : Dashboard2} />
        <Route exact path='/mdashboard1' component={a() === "0" ? Mdashboard : Dashboard2} />
        <Route exact path='/mdashboardg' component={a() === "0" ? Mdashboard : Dashboard2} />
        <Route exact path='/mdashboardios' component={a() === "0" ? Mdashboard : Dashboard2} />
      </Switch>
    </HashRouter>
  </Router>
)