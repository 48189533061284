import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FinRateList from '../details/FinRateList';
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function MFinRateDetail(props) {
    const classes = useStyles();
    let redcolor = "#ed5f84"
    let backcolor = "#FFFFFF"
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox
    let greencolor = "#53a66f"

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opentp, setOpenTp] = React.useState(0);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };



    function renderValue(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = value1 + "%"
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = value1 + "%"
        } else if (value === 2) {
            colorx = BColor.faircolor
            word = value1 + "%"
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValue1(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.downcolorbox
            word = '虧轉虧'
        } else if (value === 1) {
            colorx = BColor.upcolorbox
            word = '虧轉盈'
        } else if (value === 2) {
            colorx = BColor.downcolorbox
            word = '盈轉虧'
        } else if (value === 3) {
            if (value1 > 0) {
                colorx = BColor.upcolorbox
            } else if (value1 < 0) {
                colorx = BColor.downcolorbox
            } else {
                colorx = BColor.faircolor
            }
            word = value1 + unit
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        // {unit}
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }



    function renderValue3(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = "高" + unit
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = "低" + unit
        } else if (value === 2) {
            colorx = BColor.faircolor
            word = "中" + unit
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    // if(pa1 >= pa2 && pa2 >= pa3) 0 //持續正成長
    // else if(pa1 <= pa2 && pa2 <= pa3) 1 //持續負成長
    // else if(pa1 >= pa3 && pa3 >= pa2) 2 //大幅回升
    // else if(pa1 <= pa3 && pa1 >= pa2) 3 //小幅回升
    // else if(pa1 <= pa3 && pa2 >= pa3) 4 //大幅下跌
    // else if(pa1 <= pa2 && pa1 >= pa3) 5 //小幅下跌
    // else -1

    function renderValue4(value, value1, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = "持續正成長"
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = "持續負成長"
        } else if (value === 2) {
            colorx = BColor.upcolorbox
            word = "大幅回升"
        } else if (value === 3) {
            colorx = BColor.upcolorbox
            word = '小幅回升'
        } else if (value === 4) {
            colorx = BColor.downcolorbox
            word = '大幅下跌'
        } else if (value === 5) {
            colorx = BColor.downcolorbox
            word = '小幅下跌'
        } else if (value === -1) {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }


    return (
        <div>
            <FinRateList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></FinRateList>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            總覽
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {renderValue3(props.row.pll.pli, props.row.pll.pliv, '毛利率', props.row.market_company.stock_no, props.row.market_company.short_name)}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利率方向'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue4(props.row.pll.plc1, props.row.pll.pliv, '毛利率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue3(props.row.pll.noi, props.row.pll.noiv, '營益率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益率方向'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue4(props.row.pll.noc1, props.row.pll.pliv, '營益率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue3(props.row.pll.iti, props.row.pll.itiv, '營益率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利率方向'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue4(props.row.pll.itc1, props.row.pll.itiv, '營益率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近一季狀態
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'1w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'年份/季度'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{props.row.pll.ymd}</Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue(props.row.pll.pli, props.row.pll.pliv, '毛利率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利YoY'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.plyoyi, props.row.pll.plyoy, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利QoQ'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.plqoqi, props.row.pll.plqoq, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue(props.row.pll.noi, props.row.pll.noiv, '營益率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'51w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益YoY'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.noii, props.row.pll.noiyoy, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'52w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益QoQ'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.noiqi, props.row.pll.noiqoq, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'53w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue(props.row.pll.iti, props.row.pll.itiv, '淨利率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'54w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利YoY'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.itii, props.row.pll.itiyoy, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'55w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利QoQ'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.itiqi, props.row.pll.itiqoq, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近一年狀態
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            <StyledTableRow key={'11wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'年度'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{props.row.pll.ymd1}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'1wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue(props.row.pll.pli1, props.row.pll.pliv1, '毛利率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利YoY'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.plyoyi1, props.row.pll.plyoy1, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue(props.row.pll.noi1, props.row.pll.noiv1, '營益率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營益YoY'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.noii1, props.row.pll.noiyoy1, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利率'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue(props.row.pll.iti1, props.row.pll.itiv1, '淨利率', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'51wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨利YoY'}
                                    </StyledTableCell>

                                    {props.row.pll != null && props.row.pll != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.pll.itii1, props.row.pll.itiyoy1, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    );
}