import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles } from '@material-ui/core/styles';
import StockDetail from './StockDetail';
import StockAutocomplete from './StockAutocomplete'
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.white, 0.25),
        // },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function SearchAppBar(props) {
    const classes = useStyles();
    const [symbol, setSymbol] = React.useState(null);
    const [opendetail, setOpendetail] = React.useState(false)
    const [usercount, setusercount] = React.useState("")

    const menuChange = (data) => {
        setSymbol(data)
        setOpendetail(true)
    }

    const closeDetail = () => {
        setOpendetail(false)
        if (props.closeupdate != undefined) props.closeupdate()
    }

    const changeScreen = () => {
        let k = localStorage.getItem('run_computer') === null ? "0" : localStorage.getItem('run_computer')

        if (k === "0") {
            localStorage.setItem("run_computer", "1")
        } else {
            localStorage.setItem("run_computer", "0")
        }
    }

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const fectchUserAPI = () => {
        fetch("/jstock/v1/stock/usercount", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.text())
            .then(
                result => {
                    if (isNumeric(result)) {
                        // setusercount("線上：" + result + "人")
                    } else setusercount("系統正在更新")
                },
                error => {
                    setusercount("系統正在更新")
                }
            );
    }

    // const fectchUserAPI = () => {
    //     fetch("/jstock/v1/stock/usercount", {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     })
    //         .then(res => res.text())
    //         .then(
    //             result => {
    //                 if (isNumeric(result)) {
    //                     setusercount(result)
    //                 } else setusercount(0)
    //             },
    //             error => {
    //                 setusercount(0)
    //             }
    //         );
    // }

    useEffect(() => {
        fectchUserAPI()
    }, [usercount]);

    return (
        <div className={classes.root}>
            {symbol != null ? <StockDetail  ismobile={props.ismobile} open={opendetail}  handleClose={closeDetail} stockno={symbol} toolbar={false} /> : null}
            <AppBar position="static">
                <Toolbar style={{ backgroundColor: "#000000", color: "#FFFFFF" }}>
                    <div style={{ fontSize: 8, textAlign: "justify" }}>
                        <Avatar className={classes.menuButton} src="assets/images/logo-transparent-1-128x128.png" onClick={(e) => changeScreen()} />
    {usercount}
                    </div>
                    <Typography className={classes.title} variant="h6" noWrap>
                        股票挖土機
                    </Typography>
                    <div className={classes.search}>
                        <StockAutocomplete menuChange={menuChange} />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
