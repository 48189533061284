import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import zhtwLocale from "date-fns/locale/zh-TW";
import Button from '@material-ui/core/Button';
import moment from 'moment'
import KTestTable from './KTestTable'
import TextField from '@material-ui/core/TextField';
import AsyncSelect from 'react-select/async';
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        // margin: theme.spacing(1),
        // minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function KTest() {
    const classes = useStyles();
    const theme = useTheme();
    const [days, setDays] = React.useState(5);
    const [count, setCount] = React.useState(30);
    const [stock, setStock] = React.useState(null);
    const [klist, setklist] = React.useState(null);
    const [getlic, setlic] = React.useState(0);
    const [tp, setTp] = React.useState(0);
    const [startdate, setStartDate] = React.useState(new Date());
    const pause = useRef(false);
    
    useEffect(() => {
        checkLic()
        getKList()

        const interval = setInterval(() => {
            if(!pause.current) getKList()
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };


    const stopRefresh = () => {
        pause.current = true
    };

    const restartRefresh = () => {
        pause.current = false
    };

    function checkLic() {
        let lic = localStorage.getItem('lic')
        if (lic != null) {
            let param = {
                lic: lic
            }

            fetch("/jstock/v1/stock/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0 && result.hlic != null) {
                            setlic(result.hlic.licok)
                        } else {
                            setlic(0)
                        }
                    }
                );
        }
    }

    function delktest(id) {
        let lic = localStorage.getItem('lic')
        if (lic != null) {
            let param = {
                lic: lic,
                id: id
            }

            fetch("/jstock/v1/stock/delktest", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(
                    result => {
                        getKList()
                    }
                );
        }
    }

    const handleCount = (event) => {
        setCount(event.target.value);
    };

    const handleDays = (event) => {
        setDays(event.target.value);
    };

    const handletp = (event) => {
        setTp(event.target.value);
    };


    const runBacktest = () => {
        let lic = localStorage.getItem('lic')

        if (lic != null) {

            let a = {
                lic: lic,
                stock: stock.value,
                name: stock.label,
                days: days,
                count: count,
                tp: tp
            }

            if (startdate != null && startdate != '') {
                a["tdate"] = moment(startdate).format('YYYYMMDD')
            }

            fetch("/jstock/v1/stock/ktest", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        getKList()
                    }
                );
        }
    }



    const getKList = () => {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let a = {
                lic: lic
            }

            fetch("/jstock/v1/stock/ktestlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setklist(result.data)
                    }
                );
        }
    }

    const loadOptions = (inputValue, callback) => {
        // if (inputValue === null || inputValue === "") return callback(JSON.stringify('[]'))
        // else {
        setTimeout(() => {
            fetch("/jstock/v1/stock/searchcom?key=" + inputValue)
                .then(res => res.json())
                .then(
                    result => {
                        callback(result);
                    });
        }, 1000);
        // }
    };

    const menuChange0 = (data) => {
        if (data === null || (data != null && data.value != null))
            setStock(data);
    }

    return (
        <div className={classes.root}>
            <div style={{ fontSize: 12, color: '#8c8c8c' }} >
                #訂閱專屬。利用近1800支股票，10年的歷史資料，找出最相似的型態並統計這些股票後來的走勢。<br />
                #排隊。找出相似型態需要準備資料與排隊，尋找花費時間也不一定，所以可以稍後再回來看結果。
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhtwLocale}>
                <Grid container spacing={1} style={{ marginTop: 10 }}>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl  fullWidth={true} required>
                            <AsyncSelect
                                placeholder={'股票代號或名稱'}
                                styles={{
                                    menu: provided => ({ ...provided, zIndex: 99999999 }),
                                    control: provided => ({ ...provided, backgroundColor: null, color:theme.palette.type ==="light"? "#000000":"#FFFFFF" }),
                                    container: (base) => ({ ...base, zIndex: 501, fontSize: 16 }),
                                    valueContainer: (base) => ({
                                        ...base, minHeight: 55
                                    }),
                                    singleValue: provided => ({ ...provided, color: null }),
                                    input: provided => ({ ...provided, color: null }),

                                }}
                                menuContainerStyle={{ zIndex: 99999999 }}
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                value={stock}
                                openMenuOnClick={false}
                                onChange={menuChange0}
                                isClearable
                                isSearchable
                            />
                        </FormControl>
                    </Grid>

                    <Grid item  xs={6} sm={4} md={3}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true} required>
                            <InputLabel id="demo-simple-select-outlined-label3g">取樣標的</InputLabel>
                            <Select
                                fontSize="14"
                                size="small"
                                labelId="demo-simple-select-outlined-label3g"
                                id="demo-simple-select-outlinedg"
                                value={tp}
                                onChange={handletp}
                                label="取樣標的"
                            >
                                <MenuItem value={0}>收盤價</MenuItem>
                                <MenuItem value={1}>5日與20日均線</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item  xs={6} sm={4} md={3}>
                        <KeyboardDatePicker
                            // style={{ width: 300 }}
                            fullWidth={true}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="取樣最後基準日"
                            format="yyyy/MM/dd"
                            value={startdate}
                            minDate={Date.parse("2015-01-01")}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={date => handleStartDateChange(date)}
                        />
                    </Grid>

                    <Grid item  xs={6} sm={4} md={3}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true} required>
                            <InputLabel id="demo-simple-select-outlined-labelk">往前取樣天數</InputLabel>
                            <Select
                                fontSize="14"
                                size="small"
                                labelId="demo-simple-select-outlined-labelk"
                                id="demo-simple-select-outlinedk"
                                value={days}
                                onChange={handleDays}
                                label="往前取樣天數"
                            >
                                <MenuItem value={5}>5天</MenuItem>
                                <MenuItem value={10}>10天</MenuItem>
                                <MenuItem value={20}>20天</MenuItem>
                                <MenuItem value={30}>30天</MenuItem>                                
                                <MenuItem value={40}>40天</MenuItem>
                                <MenuItem value={50}>50天</MenuItem>
                                <MenuItem value={60}>60天</MenuItem>
                                <MenuItem value={120}>120天</MenuItem>
                                <MenuItem value={240}>240天</MenuItem>
                                <MenuItem value={360}>360天</MenuItem>
                                <MenuItem value={720}>720天</MenuItem>                                                                                            
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item  xs={6} sm={4} md={3}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true} required>
                            <InputLabel id="demo-simple-select-outlined-labelz">相似數量</InputLabel>
                            <Select
                                size="small"
                                labelId="demo-simple-select-outlined-labelz"
                                id="demo-simple-select-outlinedz"
                                value={count}
                                onChange={handleCount}
                                label="相似數量"
                            >
                                <MenuItem value={30}>30筆</MenuItem>
                                <MenuItem value={40}>40筆</MenuItem>
                                <MenuItem value={50}>50筆</MenuItem>
                                <MenuItem value={80}>80筆</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    {getlic === 1 ? <Button size="large" variant="outlined" color="secondary" onClick={runBacktest} >
                                        執行
                            </Button> : <Button size="large" disabled variant="outlined" color="secondary" onClick={runBacktest} >
                                            執行
                            </Button>}
                                </FormControl>
                            </Grid>
                            {/* <Grid item>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    {getlic === 1 ? <Button size="large" variant="outlined" color="secondary" onClick={getKList}>
                                        更新結果
                            </Button> : <Button size="large" disabled variant="outlined" color="secondary" onClick={getKList} >
                                            更新結果
                            </Button>}
                                </FormControl>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <KTestTable data={klist} delktest={delktest} stopRefresh={stopRefresh} restartRefresh={restartRefresh}/>

                    </Grid>

                </Grid>

            </MuiPickersUtilsProvider>




        </div>
    );
}
