import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RevList from '../details/RevList'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function RiskDetail(props) {
    const classes = useStyles();

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };


    const renderValue = (value) => {
        let color = BColor.nodatacolor

        if (value === -1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        } else if (value === 0) {
            color = BColor.upcolorbox
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                高獲利
            </Button>
        } else if (value === 2) {
            color = BColor.downcolorbox
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                低獲利
            </Button>
        } else if (value === 3) {
            color = BColor.downcolorbox
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                虧損
            </Button>
        } else if (value === 1) {
            color = BColor.faircolor
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                中等獲利
            </Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }

    const renderValue2 = (value) => {
        let color = BColor.nodatacolor

        if (value === -1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        } else if (value === 2) {
            color = BColor.upcolorbox
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                低風險
            </Button>
        } else if (value === 0) {
            color = BColor.downcolorbox
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                高風險
            </Button>
        } else if (value === 1) {
            color = BColor.faircolor
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                中風險
            </Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }


    return (
        <div>
            <RevList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></RevList>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近20日風險
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'報酬'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderValue(props.row.risk.r1a)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'風險'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'風險'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue2(props.row.risk.r3a)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'報酬率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r2a != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r2a}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'波動率(σ)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日波動率(σ)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r4a != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r4a}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'Beta(β)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'Beta(β)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r5a != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r5a}
                                        </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近60日風險
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'報酬'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderValue(props.row.risk.r1b)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'風險'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'風險'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue2(props.row.risk.r3b)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'報酬率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r2b != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r2b}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'波動率(σ)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日波動率(σ)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r4b != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r4b}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'Beta(β)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'Beta(β)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r5b != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r5b}
                                        </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近120日風險
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'報酬'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderValue(props.row.risk.r1c)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'風險'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'風險'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue2(props.row.risk.r3c)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'報酬率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r2c != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r2c}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'波動率(σ)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日波動率(σ)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r4c != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r4c}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'Beta(β)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'Beta(β)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r5c != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r5c}
                                        </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近240日風險
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'報酬'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderValue(props.row.risk.r1d)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'風險'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'風險'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderValue2(props.row.risk.r3d)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'報酬率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'報酬率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r2d != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r2d}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'波動率(σ)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日波動率(σ)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r4d != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r4d}%
                                                </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'Beta(β)'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'Beta(β)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.risk.r5d != -99999 ? <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >
                                            {props.row.risk.r5d}
                                        </Button> : <Button  variant="outlined" style={{ width: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    );
}
