import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AllStrageRules from './AllStrageRules';

const useStyles = makeStyles(theme => ({
}));


export default function EditStrage(props) {
    const classes = useStyles();
    const [nowRows, setNowRows] = React.useState([])


    useEffect(() => {
        if (props.srow != null) {
            setNowRows(props.srow.rules)
        }
    }, [props.srow]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    const updateRules = () => {
        let synno = localStorage.getItem('synno')
        let lic = localStorage.getItem('lic')

        if (props.srow != null && (synno != null || lic != null)) {
            let param = {
                synno: synno,
                lic: lic,
                id: props.srow.id,
                name: props.srow.name,
                rules: nowRows
            }

            fetch("/jstock/v1/stock/updaterule", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            props.handleOk()
                        }
                    },
                );
        }
    }

    return (
        <div>
            {props.open ? <Dialog
                fullScreen={true}
                fullWidth={true}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />
                </DialogContent>
                <DialogActions>
                    <Button style={{ minWidth: 100 }} onClick={updateRules} color="secondary" variant="outlined" >
                        更新
                    </Button>
                    <Button style={{ minWidth: 100 }} onClick={props.handleClose} color="secondary" variant="outlined"  >
                        關閉
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </div>
    );
}