import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { getSynNo } from '../util/Syno';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
}));



export default function MyLoveList(props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [items, setItems] = React.useState([]);

    useEffect(() => {
        if (props.open) getMenuList()
    }, [props.open]);


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    function getMenuList() {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let a = {
            synno: synno,
            id: props.stockno,
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')


        fetch("/jstock/v1/stock/listlovename", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    setItems(result)
                    const newChecked = [];

                    if(result != null) result.map((value) => {
                        if (value.exist) newChecked.push(value.menu)
                    })

                    if(newChecked != null && newChecked.length > 0) 
                        setChecked(newChecked)
                },
            );
    }


    // synno : String, id : String, menu : List[Int], lic : Option[String] = None, inp : Option[InPriceReq] = None)
    function addMyLove() {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let a = {
            synno: synno,
            id: props.stockno,
            menu: checked
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')


        fetch("/jstock/v1/stock/addlove1", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (props.myloveupdate != undefined) props.myloveupdate()
                    props.handleClose()
                },
                error => {
                    props.handleClose()
                }
            );
    }

    const handleOkClose = () => {
        addMyLove()
    };

    const handleCancelClose = () => {
        props.handleClose()
    };

    return (
        <Dialog  fullWidth={true} maxWidth={'md'} onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
            <DialogTitle id="alert-dialog-title">{"自選股清單"}</DialogTitle>
            <DialogContent>
                <List className={classes.root}>
                    {items != null && items.map((value) => {
                        const labelId = `checkbox-list-label-${value.menu}`;

                        return (
                            <ListItem key={value.menu} dense button onClick={handleToggle(value.menu)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked != null && checked.includes(value.menu)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOkClose} color="primary" autoFocus>
                    確定
          </Button>
            </DialogActions>
        </Dialog>
    );
}
