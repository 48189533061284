import React, { useState, useEffect, useRef } from 'react';

import Button from '@material-ui/core/Button'

import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import BPage from './BPage'
import UserRule from './easyrules/UserRule'

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
        overflow: 'auto',
    },
    panelTitle: {
        marginLeft: 10
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: 5,
    },
    input: {
        display: 'none',
    },
    margin: {
        width: '100%',
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        // backgroundColor: theme.palette.background.default,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}))

function StockUser(props) {
    const classes = useStyles();
    const [menu, setMenu] = React.useState([]);

    const handleOpen = () => {

        props.tabchange(BPage.donate_page)
    }

    useEffect(() => {
        getmenus()
    }, []);



    const getmenus = () => {
        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
        } : {
            lic: lic
        };

        fetch("/jstock/v1/stock/getsharenames", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.share != null) {
                        setMenu(result.share);
                    } else {
                    }
                },
                error => {
                    // setHasLic(0)
                }
            );

    }

    const getColumns = (props) => {
        if (props.tabchange === undefined && props.width === 'xs') {
            return 0.9
        } else if (props.width === 'xs') {
            return 1.3
        } if (props.width === 'sm') {
            return 2.3
        } if (props.width === 'md') {
            return 4
        } else return 4
    }

    const pages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

    return (
        <div className={classes.root} >
            {props.tabchange != undefined ?
                <Button variant="outlined" size="small" color="primary" className={classes.button} onClick={handleOpen}>
                    訂閱
                </Button> : null
            }

            <Button style={{ marginLeft: 5 }} variant="outlined" size="small" color="primary" className={classes.button} onClick={(e) => props.clearRule()}>
                清除規則
            </Button>
            <div style={{ fontSize: 12, color: '#8c8c8c', marginLeft: 5, }} >
                #這是匯集眾人智慧一起抬轎的好地方，歡迎大家無私的貢獻選股清單與策略，一同共襄盛舉。<br />
            </div>
            <div className={classes.root1}>
                <GridList className={classes.gridList} spacing={10} cellHeight='auto' cols={getColumns(props)}>

                    {/* {menu.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)       */}
                    {pages.map((page, pi) => {
                        return <GridListTile key={page}>

                            {menu
                                .slice(page * 4, page * 4 + 4)
                                .map((row, index) => {
                                    return <div key={row.ts.toString()} style={{ marginBottom: 10 }}>
                                        <UserRule
                                            id={row.tp + "" + row.ts.toString()}
                                            t1={row.name}
                                            desc={row.desc}
                                            ruleChange={props.updateRule}
                                            rules={props.rules}
                                            incp={row.inc5}

                                        ></UserRule>

                                    </div>
                                })}
                        </GridListTile>
                    })}



                </GridList>
            </div>

        </div>
    );
}

export default withWidth()(StockUser);