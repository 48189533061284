import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Button from '@material-ui/core/Button'
import { getComputerOnlyDataZoom, getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function Alligator(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);

    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (props.row != null) getPHV1()
    }, [props.row]);

    useEffect(() => {
        if (data != null) setOptions(genData1())
    }, [data]);

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')


        if (lic != null) {
            let param = {
                lic: lic,
                sid: props.row.market_company.stock_no
            };

            fetch("/jstock/v1/alligator/get", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setLoading(false)
                        if (result != null && result.status === 0) {
                            setData(result.r)
                        }
                    }
                )

        }
    }



    function genData1() {
        let option = {
            // legend: {
            //     data: ['昂貴區', '合理區', '便宜區', '股價',]
            // },
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '10',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                }
            ],
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data.map(v => { return v.d }),
        }


        if (props.ismobile) {
            let k = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = k
        }

        option['xAxis'] = [x1]

        // p.o, p.p, p.l, p.h
        option['series'] = [
            {
                name: '日K',
                type: 'candlestick',
                data: data.map(v => { return v.ap != null ? v.ap : []}),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            {
                name: '顎',
                type: 'line',
                data: data.map(v => { return v.r13 }),
                smooth: 'true',
                showSymbol: false,
            },
            {
                name: '齒',
                type: 'line',
                data: data.map(v => { return v.r8 }),
                smooth: 'true',
                showSymbol: false,
                itemStyle: {
                    color: "#d41c1c",
                },                   
            },
            {
                name: '唇',
                type: 'line',
                data: data.map(v => { return v.r5 }),
                smooth: 'true',
                showSymbol: false,
                itemStyle: {
                    color: "#65b206",
                },                
            }


        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(80, 100)

        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getComputerOnlyDataZoom(70, 100)
        }

        return option
    }

    function getString() {
        return "目前無相關的鱷魚指標圖"
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        鱷魚指標
                        </Typography>
                    </div>
                    <div style={{ fontSize: 14, color: "#808080" }}>
                        鱷魚線向上開口：由下而上是顎(藍色)，齒(紅色)，唇(綠色)排列，做多訊號。<br />
                        鱷魚線向下開口：由下而上是唇(綠色)，齒(紅色)，顎(藍色)排列，做空訊號。<br />
                        鱷魚線糾纏：趨勢未明。
                    </div>
                    {options != null ? <ReactEChartsCore
                        style={{ height: 350, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={true}
                        showLoading={loading}
                    /> : <div style={{}}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}