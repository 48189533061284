import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import Button from '@material-ui/core/Button'
import BaTaSetting from './BaTaSetting'
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function BaTa(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);
    // const bataRef = React.useRef(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        props.bigupdate()
        setOpen(false);
    };

    function getXX() {
        if (props.row.bol != null &&
            props.row.kd3.d.length >= props.row.bol.d.length) {
            let data = props.row.kd3.d.slice(-props.row.bol.d.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }

    function getXX1() {
        if (props.row.bol != null &&
            props.row.kd3.w.length >= props.row.bol.w.length) {
            let data = props.row.kd3.w.slice(-props.row.bol.w.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }

    function getXX2() {
        if (props.row.bol != null &&
            props.row.kd3.m.length >= props.row.bol.m.length) {
            let data = props.row.kd3.m.slice(-props.row.bol.m.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }

    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY() {
        if (props.row.bol != null &&
            props.row.kd3.d.length >= props.row.bol.d.length) {

            let data = props.row.kd3.d.slice(-props.row.bol.d.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }

    function getYY1() {
        if (props.row.bol != null &&
            props.row.kd3.w.length >= props.row.bol.w.length) {

            let data = props.row.kd3.w.slice(-props.row.bol.w.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }

    function getYY2() {
        if (props.row.bol != null &&
            props.row.kd3.m.length >= props.row.bol.m.length) {

            let data = props.row.kd3.m.slice(-props.row.bol.m.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }

    function getDefaultAChartOption(data) {
        let option = {
            animation: false,
            title: [
                {
                    left: 'center',
                    text: '日K線'
                },
                {
                    top: '350',
                    left: 'center',
                    text: '日寶塔線'
                },
                {
                    top: '700',
                    left: 'center',
                    text: '周K線'
                },
                {
                    top: '1050',
                    left: 'center',
                    text: '周寶塔線'
                },
                {
                    top: '1400',
                    left: 'center',
                    text: '月K線'
                },
                {
                    top: '1750',
                    left: 'center',
                    text: '月寶塔線'
                }
            ],
            grid: [
                {
                    top: '30',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '380',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '730',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1080',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1430',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1780',
                    left: '40',
                    right: '40',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 1,
                },
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 2,
                },
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 3,
                },
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 4,
                },
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 5,
                },
            ],
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data.d.map(v => { return v.d }),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x3 = {
            gridIndex: 2,
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX1(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x4 = {
            gridIndex: 3,
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data.w.map(v => { return v.d }),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x5 = {
            gridIndex: 4,
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX2(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x6 = {
            gridIndex: 5,
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data.m.map(v => { return v.d }),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }


        if (props.ismobile) {
            let xpoint = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
            x1['axisPointer'] = xpoint
            x2['axisPointer'] = xpoint
            x3['axisPointer'] = xpoint
            x4['axisPointer'] = xpoint
            x5['axisPointer'] = xpoint
            x6['axisPointer'] = xpoint
        }

        option['xAxis'] = [x1, x2, x3, x4, x5, x6]

        if (data != null) {
            option['series'] = [
                {
                    name: '日K線',
                    type: 'candlestick',
                    data: getYY(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: '日寶塔線',
                    type: 'candlestick',
                    data: data.d.map(v => { return v.y }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                },
                {
                    name: '周K線',
                    type: 'candlestick',
                    data: getYY1(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                },
                {
                    name: '周寶塔線',
                    type: 'candlestick',
                    data: data.w.map(v => { return v.y }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    xAxisIndex: 3,
                    yAxisIndex: 3,
                },
                {
                    name: '月K線',
                    type: 'candlestick',
                    data: getYY2(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    xAxisIndex: 4,
                    yAxisIndex: 4,
                },
                {
                    name: '月寶塔線',
                    type: 'candlestick',
                    data: data.m.map(v => { return v.y }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    xAxisIndex: 5,
                    yAxisIndex: 5,
                }
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: [
                    {
                        xAxisIndex: [0, 1],
                        xAxisIndex: [0, 1],
                    },
                    {
                        xAxisIndex: [2, 3],
                        xAxisIndex: [2, 3],
                    },
                    {
                        xAxisIndex: [4, 5],
                        xAxisIndex: [4, 5],
                    },
                ],
                // link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['axisPointer'] = {
                link: [
                    {
                        xAxisIndex: [0, 1],
                        xAxisIndex: [0, 1],
                    },
                    {
                        xAxisIndex: [2, 3],
                        xAxisIndex: [2, 3],
                    },
                    {
                        xAxisIndex: [4, 5],
                        xAxisIndex: [4, 5],
                    },
                ],
                // link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }
        }

        return option
    }


    useEffect(() => {
        if (props.row.bol != null) {
            setOptions(getDefaultAChartOption(props.row.bol))
        }
    }, [props.row.bol]);



    function getString() {
        return "目前無相關的寶塔線(訂閱專屬)"
    }

    const handleOpen = () => {
        setOpen(true);
    };




    return (
        <div>
            <BaTaSetting open={open1} handleClose={handleClose} handleOk={handleOk}></BaTaSetting>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            寶塔線 <Button size="small" color="primary" variant="outlined" onClick={(e) => handleOpen()}>設定</Button>
                        </Typography>

                    </div>

                    {props.row.bol != undefined && options != null ?
                        <ReactEChartsCore
                            style={{ height: 2100, marginTop: 0 }}
                            theme={theme.palette.type}
                            echarts={echarts}
                            option={options}
                            notMerge={true}
                            lazyUpdate={false}
                            // onEvents={onEvents}
                            // onChartReady={chartReady}
                            // ref={(e) => { bataRef.current = e; }}
                        />
                        : <div style={{}}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}