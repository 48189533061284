import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { useTheme } from '@material-ui/core/styles';
import DealerList1 from '../details/DealerList1';
import BColor from '../BColor'

import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
    },
    body: {
        fontSize: 16,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function MMainCostDetail1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [openstock, setOpenstock] = React.useState(null);
    const [maincusopen, setMainCusOpen] = React.useState(false)
    const [dealeropen, setDealerOpen] = React.useState(false)
    const [dealerbuy, setDealerBuy] = React.useState(true)
    const [dealerday, setDealerDay] = React.useState(1)
    const [stockname, setStockName] = React.useState(null);


    const handleClickOpengg = (stock_no, buy, day, name) => event => {
        setOpenstock(stock_no)
        setDealerOpen(true);
        setDealerBuy(buy)
        setDealerDay(day)
        setStockName(name)
    };

    const handleClosegg = () => {
        setOpenstock(null)
        setDealerOpen(false);
        setDealerBuy(true)
        setDealerDay(1)
        setStockName(null)
    };
    const renderValue = (base, value, stockno, buy, day, name) => {
        let color = BColor.nodatacolor

        if (value === -1) {
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        } else if (value > base) {
            color = BColor.downcolorbox
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                虧 {value}元
            </Button>
        } else if (value < base) {
            color = BColor.upcolorbox
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                盈 {value}元
            </Button>
        } else if (base === value) {
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                持平{value}元
            </Button>
        } else {
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderValue1 = (base, value, stockno, buy, day, name) => {
        if (value === -1) {
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                -
            </Button>
        } else {
            return <Button onClick={handleClickOpengg(stockno, buy, day, name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                {value}元
            </Button>
        }
    }
    function getXX() {
        if (props.row.mline != null && props.row.mline.m1 != null &&
            props.row.kd3.d.length >= props.row.mline.m1.length) {
            let data = props.row.kd3.d.slice(-props.row.mline.m1.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)
    function getYY1() {
        if (props.row.mline != null && props.row.mline.m1 != null &&
            props.row.kd3.d.length >= props.row.mline.m1.length) {

            let data = props.row.kd3.d.slice(-props.row.mline.m1.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }


    function getDefaultAChartOption() {
        let option = {
            legend: {
                data: ['主力持股走勢', '1日', '5日', '10日', '20日', '40日'],
                bottom: 0
            },
            grid: [
                {
                    top: '15',
                    left: '40',
                    right: '40',
                    height: '350',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    show: false,
                    position: 'right',
                    scale: true,
                    type: 'value'
                }
            ]
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }


        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }
        }

        option['xAxis'] = x1
        let series = [
            {
                name: '日K線',
                type: 'candlestick',
                data: getYY1(),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            }, {
                name: '主力持股走勢',
                type: 'line',
                data: props.row.mline.m1.map(v => { return v.y }),
                smooth: true,
                yAxisIndex: 1,
                symbol: 'none'
            }
        ]

        if (props.row.mcost != null && props.row.mcost.d1 != -1 && props.row.mline != null) series.push(costline("1日", props.row.mcost.d1, 0, props.row.mline.m1.length))
        if (props.row.mcost != null && props.row.mcost.d5 != -1 && props.row.mline != null) series.push(costline("5日", props.row.mcost.d5, 20, props.row.mline.m1.length))
        if (props.row.mcost != null && props.row.mcost.d10 != -1 && props.row.mline != null) series.push(costline("10日", props.row.mcost.d10, 40, props.row.mline.m1.length))
        if (props.row.mcost != null && props.row.mcost.d20 != -1 && props.row.mline != null) series.push(costline("20日", props.row.mcost.d20, -20, props.row.mline.m1.length))
        if (props.row.mcost != null && props.row.mcost.d40 != -1 && props.row.mline != null) series.push(costline("40日", props.row.mcost.d40, -40, props.row.mline.m1.length))

        option['series'] = series
        return option
    }


    const costline = (name, value, distance, length) => {
        return {
            name: name,
            type: 'line',
            data: Array(length).fill(value),
            smooth: true,
            symbol: 'none',
            labelLayout: {
                moveOverlap: 'shiftY'
            },
            endLabel: {
                show: true,
                formatter: '{a}'
            }
        }
    }

    useEffect(() => {
        if (props.row.mline != null) setOptions(getDefaultAChartOption())
    }, [props.row]);

    function getString() {
        return "目前無相關的主力成本與持股"
    }

    return (
        <div>
            <DealerList1 open={dealeropen} handleClose={handleClosegg} day={dealerday} stockId={openstock} buylist={dealerbuy} name={stockname}></DealerList1>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            主力成本與持股
                        </Typography>
                    </div>

                    {options != null ? <ReactEChartsCore
                        style={{ height: 450, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            1日主力
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, true, 1, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.buym1 / 1000).toFixed(0)}張
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue(props.row.fin.price.last_value, props.row.mcost.d1, props.row.market_company.stock_no, true, 1, props.row.market_company.short_name)}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'賣超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, false, 1, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.sellm1 / 1000).toFixed(0)}張
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均賣價'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue1(props.row.fin.price.last_value, props.row.mcost.s1, props.row.market_company.stock_no, false, 1, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >




                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            5日主力
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, true, 5, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.buym5 / 1000).toFixed(0)}張
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue(props.row.fin.price.last_value, props.row.mcost.d5, props.row.market_company.stock_no, true, 5, props.row.market_company.short_name)}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'6'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'賣超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, false, 5, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.sellm5 / 1000).toFixed(0)}張
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'7'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均賣價'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue1(props.row.fin.price.last_value, props.row.mcost.s5, props.row.market_company.stock_no, false, 5, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            10日主力
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'41'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, true, 10, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.buym10 / 1000).toFixed(0)}張
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'51'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue(props.row.fin.price.last_value, props.row.mcost.d10, props.row.market_company.stock_no, true, 10, props.row.market_company.short_name)}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'61'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'賣超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, false, 10, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.sellm10 / 1000).toFixed(0)}張
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'71'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均賣價'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue1(props.row.fin.price.last_value, props.row.mcost.s10, props.row.market_company.stock_no, false, 10, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            20日主力
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'42'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, true, 20, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.buym20 / 1000).toFixed(0)}張
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'52'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue(props.row.fin.price.last_value, props.row.mcost.d20, props.row.market_company.stock_no, true, 20, props.row.market_company.short_name)}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'62'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'賣超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, false, 20, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.sellm20 / 1000).toFixed(0)}張
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'72'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均賣價'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue1(props.row.fin.price.last_value, props.row.mcost.s20, props.row.market_company.stock_no, false, 20, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            40日主力
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'43'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, true, 40, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.buym40 / 1000).toFixed(0)}張
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'53'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue(props.row.fin.price.last_value, props.row.mcost.d40, props.row.market_company.stock_no, true, 40, props.row.market_company.short_name)}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'63'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'賣超張數'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button onClick={handleClickOpengg(props.row.market_company.stock_no, false, 40, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >
                                            {(props.row.mcost.sellm40 / 1000).toFixed(0)}張
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'73'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均賣價'}
                                    </StyledTableCell>

                                    {props.row.mcost != null && props.row.mcost != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderValue1(props.row.fin.price.last_value, props.row.mcost.s40, props.row.market_company.stock_no, false, 40, props.row.market_company.short_name)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


            </Grid>
        </div >
    );
}