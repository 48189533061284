import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import FinRateList from './FinRateList3';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'

import {exportExcel} from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    if (b.rate != undefined && a.rate != undefined && orderBy != "" && b.rate[orderBy] < a.rate[orderBy]) {
        return -1;
    }
    if (b.rate != undefined && a.rate != undefined && orderBy != "" && b.rate[orderBy] > a.rate[orderBy]) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// case class ChipPage(all : Long, sinc : Long, user : Long, uinc : Long, avgs : Double, avginc : Double, cuser : Long, cuper : Double, cincp : Double, cinc : Long, cstock : Long, csper : Double, csincp: Double)

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'roa', numeric: false, disablePadding: false, label: '資產報酬率', sid: 'roa' },
    { id: 'roe', numeric: false, disablePadding: false, label: '股東權益報酬率', sid: 'roe' },
    { id: 'cv', numeric: false, disablePadding: false, label: '營運資金銷售率', sid: 'cv' },
    { id: 'tst', numeric: false, disablePadding: false, label: '總資產周轉率', sid: 'tst' },
    { id: 'revd', numeric: false, disablePadding: false, label: '應收帳款天數', sid: 'revd' },
    { id: 'invd', numeric: false, disablePadding: false, label: '庫存天數', sid: 'invd' },

    { id: 'ib', numeric: false, disablePadding: false, label: '利息保障倍數', sid: 'ib' },
    { id: 'cr', numeric: false, disablePadding: false, label: '流動比率', sid: 'cr' },
    { id: 'qr', numeric: false, disablePadding: false, label: '速動比率', sid: 'qr' },
    { id: 'dea', numeric: false, disablePadding: false, label: '負債佔資產比率', sid: 'dea' },
    { id: 'cl', numeric: false, disablePadding: false, label: '合約負債', sid: 'cl' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow >
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">獲利能力（近四季）
                </TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">經營能力（近四季）
                </TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">償還能力（最新一季）
                </TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'roa' || headCell.id === 'ib' || headCell.id === 'tst' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === '0bname' || headCell.id === '1bname' || headCell.id === '2bname' || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap' }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function FinOtherRateDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }


    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    const openDetail = (stockno) => {
        if (props.detailUpdate != undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate != undefined) props.detailUpdate(false)
        setOpendetail(false)
    }


    function renderValue0(value, unit, stockno, stockName) {
        let colorx = BColor.faircolor
        let word = value + unit
        if (value === -99999.0) {
            word = " - "
        }

        return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 120, color: colorx }} className={classes.button1}>{word}</Button>
    }

    const getAllIds = () => {
        return props.data.map(row => {
            return row.market_company.stock_no
        })
    }

    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
            <FinRateList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></FinRateList>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} align="left">
                                                {renderValue0(row.rate.roa, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">
                                                {renderValue0(row.rate.roe, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="left">
                                                {renderValue0(row.rate.cv, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} align="left">
                                                {renderValue0(row.rate.tst, '次', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">
                                                {renderValue0(row.rate.revd, '天', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="left">
                                                {renderValue0(row.rate.invd, '天', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} align="left">
                                                {renderValue0(row.rate.ib, '倍', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">
                                                {renderValue0(row.rate.cr, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">
                                                {renderValue0(row.rate.qr, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} align="left">
                                                {renderValue0(row.rate.dea, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.rate != null && row.rate != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="left">
                                                {renderValue0(row.rate.cl, '%', row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Divider orientation="vertical" flexItem />
                        <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                            匯出
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <TablePagination
                            rowsPerPageOptions={getRowPageData(props.width)}
                            component="div"
                            count={props.total}
                            rowsPerPage={props.currentcount}
                            page={props.currentpage}
                            labelRowsPerPage={'每頁'}
                            labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(FinOtherRateDetail);
