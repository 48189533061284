import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BrokerList from '../details/BrokerList';
import BColor from '../BColor'
import CashReport1 from '../details/CashReport1'
import CashReport2 from '../details/CashReport2';
import CashReport3 from '../details/CashReport3';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function CashReport(props) {
    const classes = useStyles();

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [concentopen1, setConcentOpen1] = React.useState(false);
    const [concentopen2, setConcentOpen2] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    const handleClickOpencc1 = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen1(true);
    };

    const handleClosecc1 = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen1(false);
    };

    const handleClickOpencc2 = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen2(true);
    };

    const handleClosecc2 = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen2(false);
    };


    function renderValue3(value, stockno, stockName, tp) {
        let colorx = BColor.faircolor
        let word = "-"
        let unit = "百萬元"

        if (value === undefined || value == null || value === -99999) {
            word = "-"
            unit = ''
        } else {
            let x = 1000000
            if (value > 100000000 || value < -100000000) {
                x = 100000000
                unit = "億元"
            } else if (value > 1000000 || value < -1000000) {
                x = 1000000
            }
            word = ((value / x).toFixed(1)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }

        if (value < 0 && value != -99999)
            colorx = "#87c988"

        if (tp === 0)
            return <StyledTableCell><Button onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button></StyledTableCell>
        else if (tp === 1)
            return <StyledTableCell><Button onClick={handleClickOpencc1(stockno, stockName)} variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button></StyledTableCell>
        else if (tp === 2)
            return <StyledTableCell><Button onClick={handleClickOpencc2(stockno, stockName)} variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button></StyledTableCell>
        else return <StyledTableCell><Button onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button></StyledTableCell>

    }

    function renderValue4(value, stockno, stockName) {
        let colorx = BColor.faircolor
        let unit = "元"
        let word = value

        if (value === undefined || value == null || value === -99999) {
            word = "-"
            unit = ''
        }

        if (value < 0 && value != -99999)
            colorx = "#87c988"
        return <StyledTableCell><Button onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button></StyledTableCell>
    }

    function genYmdWord(row) {
        if (row.tp === 0) return row.year + "第" + row.season + "季"
        else if (row.tp === 1) return row.year + "年度"
        else if (row.tp === 2 && row.season === 1) return row.year + "上半年"
        else if (row.tp === 2 && row.season === 3) return row.year + "下半年"
        else return "-"
    }

    function genYmdWord1(row) {
        return row.year + "第" + row.season + "季"
    }

    function genYmdWord2(row) {
        if (row.month === 3) return row.year + "第" + row.season + "季"
        else if (row.month === 12) return row.year + "年度"
        else return "-"
    }

    return (
        <div>
            <CashReport1 open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></CashReport1>
            <CashReport2 open={concentopen1} handleClose={handleClosecc1} stockId={openstock} stockName={opensname}></CashReport2>
            <CashReport3 open={concentopen2} handleClose={handleClosecc2} stockId={openstock} stockName={opensname}></CashReport3>

            <Grid container spacing={3} className={classes.grid}>
                {props.row.proi != null && props.row.proi != undefined ? <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            損益表
                        </Typography>

                    </div>
                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table" >
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'日期1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日期'}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={handleClickOpencc(props.row.market_company.stock_no, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{genYmdWord(props.row.proi)}</Button>
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'營收'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營收'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.revenue, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'毛利'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.pl, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'營業費用'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營業費用'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.oe, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'營業利益'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營業利益'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.nopil, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'業外'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'業外'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.npie, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'稅前淨利'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'稅前淨利'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.plbt, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'所得稅'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'所得稅'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.iteco, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >
                                <StyledTableRow key={'本期淨利'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本期淨利'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.proi.profit_loss, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}
                                </StyledTableRow >
                                <StyledTableRow key={'EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EPS'}
                                    </StyledTableCell>
                                    {renderValue4(props.row.proi.eps, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                        很抱歉，近期沒有找到此股票的損益表。
                    </Typography>}


                {props.row.bs != null && props.row.bs != undefined ? <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            資產負債表
                        </Typography>
                    </div>


                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table" >
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'日期2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日期'}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={handleClickOpencc2(props.row.market_company.stock_no, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{genYmdWord1(props.row.bs)}</Button>
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'資產'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'資產'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.full_asset, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'流動資產'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'流動資產'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.cur_ast, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'現金當約現金'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'現金當約現金'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.cash, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'存貨'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'存貨'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.inv, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'應收帳款'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'應收帳款'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.rev, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'非流動資產'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'非流動資產'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.noncur, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'無形資產'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'無形資產'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.int_ast, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >
                                <StyledTableRow key={'不動產'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'不動產'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.ppe, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >
                                <StyledTableRow key={'負債'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'負債'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.liabilityies, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >
                                <StyledTableRow key={'流動負債'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'流動負債'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.cur_liy, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >
                                <StyledTableRow key={'非流動負債'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'非流動負債'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.nonlib, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >
                                <StyledTableRow key={'股東權益'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股東權益'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.bs.equity, props.row.market_company.stock_no, props.row.market_company.short_name, 2)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                        很抱歉，近期沒有找到此股票的資產負債表。
                    </Typography>}

                {props.row.cashi != null && props.row.cashi != undefined ? <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            現金流量表
                        </Typography>
                    </div>


                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table" >
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'日期3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日期'}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button onClick={handleClickOpencc1(props.row.market_company.stock_no, props.row.market_company.short_name)} variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{genYmdWord2(props.row.cashi)}</Button>
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'營業活動'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營業活動'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.cashi.cffu, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'投資活動'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'投資活動'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.cashi.ncffi, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'籌資活動'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'籌資活動'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.cashi.cffuif, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'資本支出'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'資本支出'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.cashi.captialout, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'自由現金流'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'自由現金流'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.cashi.freecash, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'淨現金流'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'淨現金流'}
                                    </StyledTableCell>
                                    {renderValue3(props.row.cashi.cceep, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                        很抱歉，近期沒有找到此股票的現金流量表。
                    </Typography>}

            </Grid>


        </div >
    );
}

