import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'
import KTwoChart1 from './KTwoChart1'
import StockDetail from '../mobile/StockDetail'
import Link from '@material-ui/core/Link';
import Pagination from '@material-ui/lab/Pagination';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import MyLoveList from '../mylove/MyLoveList';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(bymd, nymd, name, bprice, nprice, sid, bymd1, nymd1, count) {
    return { bymd, nymd, name, bprice, nprice, sid, bymd1, nymd1, count };
}



const useStyles = makeStyles({
    table: {
        minWidth: 250,
        overflow: 'scroll',
    },
    button1: {
        // minWidth: 110,
    }
});

export default function RestStockList(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    const [row, setRow] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stock, setStock] = React.useState(null);

    const [page, setPage] = React.useState(1);
    const [totoalPage, setTotalPage] = React.useState(1);

    const [enPrex, setEnPrex] = React.useState(true);
    const [enNext, setEnNext] = React.useState(true);

    const [lovemenu, setLovemenu] = React.useState(false)

    const handleLoveClick = stockNo => event => {
        setStock(stockNo);
        setLovemenu(true);
    };

    
    const handleLoveClose = () => {
        setLovemenu(false);
    };


    const handlePageChange = (event, value) => {
        setPage(value);
        fectchStockData(props.id, value)
    };


    const closeDetail = () => {
        setOpendetail(false)
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpendetail(true)
    }


    useEffect(() => {
        if (props.open) fectchStockData(props.id)
    }, [props.id, props.open]);


    function fectchStockData(id, rpage) {
        let lic = localStorage.getItem('lic')

        if (lic != null && id != null) {
            let a = {
                lic: lic,
                id: id
            };

            if (rpage == null) a['page'] = page
            else a['page'] = rpage

            fetch("/jstock/v1/stock/backtestreststock2", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.rs != null) {
                            let dd = result.rs.map(row =>
                                createData(moment(row.bymd, "YYYYMMDD").format('YYYY/MM/DD'), moment(row.nymd, "YYYYMMDD").format('YYYY/MM/DD'), row.name, row.bprice, row.nprice, row.sid, row.bymd, row.nymd, row.count)
                            )
                            setBuyselldata(dd)
                            setTotalPage(result.total)
                        }
                    },
                    error => {
                    }
                );
        }
    }


    function renderReason(row) {
        let k = <Button size="small" variant="outlined" style={{ color: BColor.nodatacolor }} className={classes.button1} >
            -
        </Button>
        let roi = ((row.nprice - row.bprice) * 100 / row.bprice).toFixed(1)

        if (row.nprice > row.bprice) {
            k = <Button size="small" style={{ background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                {roi}%
            </Button>
        } else if (row.nprice === row.bprice) {
            k = <Button size="small" style={{}} className={classes.button1}>
                {roi}%
            </Button>
        } else {
            k = <Button size="small" style={{ background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                {roi}%
            </Button>
        }

        return k
    }

    const openTwoK = (row) => {
        setRow(row)
        setOpen(true)
    }

    function closeDialog1() {
        setOpen(false)
    }

    const handleNext = () => {
        if (buyselldata != null) {
            const index = buyselldata.findIndex(a => a.bymd === row.bymd && a.symd === row.symd && a.sid === row.sid);
            if (index != -1) {
                if (index + 1 < buyselldata.length) {
                    let newRow = buyselldata[index + 1]
                    setRow(newRow)

                    if (index + 1 === buyselldata.length - 1) {
                        setEnNext(false)
                    } else {
                        setEnNext(true)
                    }
                    setEnPrex(true)
                } else {
                    //最後一頁
                }
            } else {
                //無此筆資料
            }
        }
    }

    const handlePrex = () => {
        if (buyselldata != null) {
            const index = buyselldata.findIndex(a => a.bymd === row.bymd && a.symd === row.symd && a.sid === row.sid);
            if (index != -1) {
                if (index - 1 >= 0) {
                    let newRow = buyselldata[index - 1]
                    setRow(newRow)

                    if (index - 1 === 0) {
                        setEnPrex(false)
                    } else setEnPrex(true)

                    if (index - 1 === buyselldata.length - 1) {
                        setEnNext(false)
                    } else {
                        setEnNext(true)
                    }

                } else {
                    //最後一頁
                }
            } else {
                //無此筆資料
            }
        }
    }

    return (
        <div>
            {row != null ? <KTwoChart1 enPrex={enPrex} enNext={enNext} handlePrex={handlePrex} handleNext={handleNext} row={row} open={open} handleClose={closeDialog1}></KTwoChart1> : null}
            <StockDetail open={opendetail} handleClose={closeDetail} stockno={stock} ismobile={props.ismobile} />
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={stock} />

            <Dialog fullScreen scroll={'paper'} open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">未賣出股票列表</DialogTitle>

                <DialogContent style={{ overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: '80vh' }}>
                        <Table className={classes.table} aria-label="customized table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left"></StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">股票</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">買入日期</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">買入張數</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">買入價格</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">測試最後日期</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">測試最後價格</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">最後獲利</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">K線圖</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {buyselldata.map(row => (
                                    <StyledTableRow key={row.name + '' + row.bymd + '' + row.nymd}>
                                        <StyledTableCell align="left">
                                            <FavoriteBorder onClick={handleLoveClick(row.sid)}/>
                                        </StyledTableCell>

                                        <StyledTableCell style={{ width: "20%" }} component="th" scope="row">
                                            <Link
                                                style={{ color: BColor.upcolor, fontSize: 14 }}
                                                component="button"
                                                variant="caption"
                                                onClick={() => openDetail(row.sid)}
                                            >{row.name}({row.sid})
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.bymd}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.count != null ? row.count : 1}張
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.bprice}元
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.nymd}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.nprice}元
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {renderReason(row)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Button variant="contained" onClick={(e) => openTwoK(row)}>日K</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Pagination count={totoalPage} page={page} onChange={handlePageChange} />
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
