import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Routes from './routes'
import ColorModeContext from './components/util/ColorMode'
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

import {EchartTheme, EchartDarkTheme} from './components/util/EchartTheme'

echarts.registerTheme('light', EchartTheme);
echarts.registerTheme('dark', EchartDarkTheme);



function App() {
  const [mode, setMode] = React.useState(localStorage.getItem("colormode") != null ? localStorage.getItem("colormode") : 'light');


  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          let curMode = prevMode === 'light' ? 'dark' : 'light'
          localStorage.setItem("colormode", curMode)
          return curMode
        });
      },
    }),
    [],
  );

  // theme.palette.background.default
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: "#4d918b",
          },
          secondary: {
            main: "#54A0CC",
          },
          type: mode
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );

}

export default App;
