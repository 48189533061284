import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import MainDetail from './details/MainDetail3';
import MainDetail1 from './details/MainDetail3'
import MenuItem from '@material-ui/core/MenuItem';
import AsyncSelect from 'react-select/async';
import Select1 from 'react-select'
import StockName from './StockName';
import AlertDialog from "./AlertDialog"
import MoveMyLove from './mylove/MoveMyLove'
import Menu from '@material-ui/core/Menu';
import BColor from './BColor'
import moment from 'moment'
import MangeLoveList from './love/MangeLoveList'
import { putInpQueryParam } from './util/Syno'
import MyLoveNameList from './mylove/MyLoveNameList'
import { getSynNo } from './util/Syno';

const styles = theme => ({
    root: {
        // display: "flex",
        // flexDirection: "columnn
    },
    table: {
        left: 0,
        right: 0,
        bottom: 0,
        marginBottom: 40,
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    menu1: {
        marginBottom: 8
    },
    menu2: {
        display: "flex",
        flexDirection: "columnn",
        marginBottom: 8
    },
    menu3: {
        marginBottom: 20
    },
    tableLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.secondary.dark
    },
    button: {
        // marginLeft: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        marginRight: 5,
    },
    input: {
        display: 'none',
    },
    textField: {
        marginTop: 0,
        marginBottom: 0,
        width: 80,
        verticalAlign: 'bottom',
        color: '#404040'
    }
})


class MyRulesBoard1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            items: null,
            top: null,
            loveitems: null,
            symbol: null,
            lictxt: '',
            age: -1,
            bigtitle: null,
            open1: false,
            nameupdate: true,
            open2: false,
            open3: false,
            open4: false,
            open: false,
            open5: false,
            mvitems: null,
            fmenu: null,
            fmenulist: [],
            time: null,
            openmanage: false
        };
    }

    componentDidMount() {
        this.getMylove(-1);

        let h = moment().hour()
        let w = moment().day()
        if (h >= 9 && h < 14 && w > 0 && w < 6) {
            this.interval = setInterval(() => {
                this.getMylove(this.state.age)
            }, 15000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handlefClick = (event) => {
        this.setState({ fmenu: event.currentTarget });
    };

    handlefClose = () => {
        this.setState({ fmenu: null });
    };

    handlef1Click = () => {
        this.setState({ fmenu: null });
        let k = localStorage.getItem('hfix')
        if (k != null) {
            if (k === "y")
                localStorage.setItem("hfix", 'n')
            else localStorage.setItem("hfix", 'y')
        } else {
            localStorage.setItem("hfix", 'n')
        }
    };

    handleOpenSortName = () => {
        this.setState({ open5: true, fmenu: null });
    };


    handleClickOpen = () => {
        this.setState({ open: true, fmenu: null });
    };

    handleMangeClose = () => {
        this.setState({ openmanage: false });
        this.getMylove()
    };

    handleMangeOpen = () => {
        this.setState({ openmanage: true, fmenu: null  });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getMylove()
    };

    handleClose5 = () => {
        this.setState({ open5: false });
        this.getMylove()
    };

    menuChange = data => event => {
        this.setState({ age: event.target.value });
        this.getMylove(event.target.value)
    }

    handleTabChange = (event, value) => {
        this.setState({ tabIndex: value });
    };

    isComputer = () => {
        return this.props.location.pathname === "/rules"
    }

    renderMyLoveTable() {
        const { error, isLoaded, loveitems, top } = this.state;

        if (error) {
            return "";
        } else if (!isLoaded) {
            return "";
        } else if (loveitems === null) {
            return "";
        } else if (loveitems.length === 0) {
            return <div style={{ color: '#b3b3b3', fontSize: 14 }}>此自選股清單中尚未有任何股票。</div>;
        } else {
            if (this.isComputer())
                return <MainDetail1 data={loveitems} dense={true} disablepage={true} bigtitle={this.state.bigtitle} freecashupdate={this.getMylove} bigupdate={this.getMylove} myloveupdate={this.getMylove} ismobile={false} />;
            else return <MainDetail data={loveitems} dense={true} disablepage={true} bigtitle={this.state.bigtitle} freecashupdate={this.getMylove} bigupdate={this.getMylove} myloveupdate={this.getMylove} ismobile={true} />;
        }
    }

    getdata(k, i) {
        return { id: k, menu: i }
    }



    shareLove = () => {
        this.setState({ fmenu: null });

        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')
        let menul = this.state.fmenulist.find(v => v.value === parseInt(this.state.age))

        if (this.state.loveitems.length > 0) {
            let stockIds = this.state.loveitems.map((value => value.market_company.stock_no))

            let param = lic === null ? {
                name: menul != null ? menul.label: "",
                stock1: stockIds,
                syno: synno
            } : {
                name: menul != null ? menul.label: "",
                lic: lic,
                stock1: stockIds,
                syno: synno
            };


            fetch("/jstock/v1/stock/sharelove", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            this.setState({ open2: true });
                        } else if (result.status === -1) {
                            this.setState({ open3: true });
                        } else if (result.status === -2) {
                            this.setState({ open4: true });
                        }
                    },
                    error => {
                    }
                );
        }
    }



    getMylove = (mm) => {
        let menu = this.state.age
        if (mm != undefined && mm != null) menu = mm
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

        let a = {
            synno: synno,
            menu: menu,
            inp: {}
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (localStorage.getItem('token') != null)
            a["token"] = localStorage.getItem('token')

        a = putInpQueryParam(a)
        fetch("/jstock/v1/stock/getlove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {

                        if (result.data3 != null)
                            this.setState({
                                isLoaded: true,
                                loveitems: result.data3.company,
                                bigtitle: result.data3.bigtitle,
                                error: null
                            });

                        if (result.data1 != null) {
                            const newChecked = [];
                            result.data1.data.map((value) => {
                                newChecked.push({ value: value.menu, label: value.name })
                            })

                            if(menu === -1 && result.data1.data.length > 0) {
                                this.setState({
                                    age: result.data1.data[0].menu,
                                })                                
                            } 

                            this.setState({
                                fmenulist: newChecked,
                                nameupdate: !this.state.nameupdate
                            })

                            this.setState({
                                fmenulist: newChecked,
                                nameupdate: !this.state.nameupdate
                            })
                        }
                    }
                },
                error => {
                }
            );

    }


    addMylove = () => {
        if (this.state.symbol === null) return;

        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let menu = this.state.age
        let stock_no = this.state.symbol.value

        let a = {
            synno: synno,
            menu: menu,
            id: stock_no,
            inp: {}
        }
        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        fetch("/jstock/v1/stock/addlove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        this.setState({ symbol: null })
                        this.getMylove(this.state.age)
                    } else {
                    }
                },
                error => {
                }
            );
    }


    delAll = () => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let menu = this.state.age

        let a = {
            synno: synno,
            menu: menu,
        }
        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        fetch("/jstock/v1/stock/delalllove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            // .then(res => res.json())
            .then(
                result => {
                    this.setState({fmenu: null });
                    this.getMylove(this.state.age)
                }
            );

    }



    loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            fetch("/jstock/v1/stock/searchcom?key=" + inputValue)
                .then(res => res.json())
                .then(
                    result => {
                        callback(result);
                    });
        }, 1000);
    };

    menuChange0 = (data) => {
        if (data === null || (data != null && data.value != null))
            this.setState({ symbol: data });
    }

    menuChange1 = (data) => {
        this.setState({ age: data.value });
        this.getMylove(data.value)
    }


    componentDidUpdate(prevProps) {
        if (this.props.updatemylove != undefined && this.props.updatemylove !== prevProps.updatemylove) {
            this.getMylove(this.state.age)
        }
    }

    updateStockName = (name) => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let menu = this.state.age

        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
            menu: menu,
            name: name
        } : {
            synno: synno,
            lic: localStorage.getItem('lic'),
            menu: menu,
            name: name
        };

        fetch("/jstock/v1/stock/addlovename", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        
                        this.getMylove(this.state.age)
                    } else {
                    }
                },
                error => {
                }
            );
    }

    getItem = (iv) => {
        let ii = iv + "_stockname"
        return localStorage.getItem(ii) != null ? localStorage.getItem(ii) : '自選股清單' + iv
    }

    handleClickOpen1 = () => {
        this.setState({ open1: true, fmenu: null });
    };

    handleClose1 = () => {
        this.setState({ open1: false });

    };

    handleOk = (name) => {
        this.updateStockName(name)
        this.setState({ open1: false });
    };

    handleClose2 = () => {
        this.setState({ open2: false });
    };
    handleClose3 = () => {
        this.setState({ open3: false });
    };
    handleClose4 = () => {
        this.setState({ open4: false });
    };
    render() {
        const { classes } = this.props;
        const { rules, tabIndex, time } = this.state;

        return (
            <div>
                <MangeLoveList menu={this.state.age} open={this.state.openmanage} handleClose={this.handleMangeClose} />
                <MoveMyLove open={this.state.open} handleClose={this.handleClose} loveitems={this.state.loveitems} menu={this.state.age} mobile={this.props.mobile} ></MoveMyLove>
                <MyLoveNameList open={this.state.open5} handleClose={this.handleClose5} loveitems={this.state.loveitems} menu={this.state.age} mobile={this.props.mobile} ></MyLoveNameList>

                <div className={classes.table}>
                    {time}
                    <AlertDialog
                        open={this.state.open2}
                        title=''
                        desc='感謝您的無私分享，我們會盡快地將它呈現在股民面前。'
                        pos_label='確定'
                        linkto='#'
                        handleClose={this.handleClose2}
                    />
                    <AlertDialog
                        open={this.state.open3}
                        title=''
                        desc='很抱歉，已經有人分享過類似的清單。'
                        pos_label='確定'
                        linkto='#'
                        handleClose={this.handleClose3}
                    />
                    <AlertDialog
                        open={this.state.open4}
                        title=''
                        desc='很抱歉，24小時內只能分享一次，請明天在繼續吧。'
                        pos_label='確定'
                        linkto='#'
                        handleClose={this.handleClose4}
                    />

                    <div className={classes.menu3}>
                        <StockName open={this.state.open1} handleClose={this.handleClose1} handleOk={this.handleOk}></StockName>
                        <div className={classes.menu2} >
                            <AsyncSelect
                                placeholder={'股票代號或名稱'}
                                styles={{ menu: provided => ({ ...provided, zIndex: 99999999 }), container: (base) => ({ ...base, zIndex: 501, minWidth: 200, width: 200, marginRight: 5, fontSize: 14 }) }}
                                menuContainerStyle={{ zIndex: 99999999 }}
                                cacheOptions
                                loadOptions={this.loadOptions}
                                defaultOptions
                                value={this.state.symbol}
                                openMenuOnClick={false}
                                onChange={this.menuChange0}
                                isClearable
                                isSearchable
                            />
                            <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={this.addMylove}>加入</Button>
                        </div>
                        <div className={classes.menu2} >
                            <Select1
                                key={this.state.nameupdate}
                                styles={{ container: (base) => ({ ...base, zIndex: 500, width: 200, minWidth: 200, marginRight: 5, fontSize: 14 }) }}
                                defaultValue={this.state.fmenulist.find( v => v.value === parseInt(this.state.age))}
                                onChange={this.menuChange1}
                                options={this.state.fmenulist}
                                isSearchable={false}
                            />

                            <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={this.handlefClick}>功能</Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.fmenu}
                                keepMounted
                                open={Boolean(this.state.fmenu)}
                                onClose={this.handlefClose}
                            >
                                <MenuItem onClick={this.handleClickOpen1} >清單更名</MenuItem>
                                <MenuItem onClick={this.shareLove} >清單分享</MenuItem>
                                <MenuItem onClick={this.handleOpenSortName} >清單順序</MenuItem>

                                <MenuItem onClick={this.handleMangeOpen} >自選股修改</MenuItem>
                                <MenuItem onClick={this.handleClickOpen}>自選股順序</MenuItem>
                                <MenuItem onClick={this.delAll} >自選股清空</MenuItem>
                                <MenuItem onClick={this.handlef1Click}>表頭固定/解除</MenuItem>
                            </Menu>
                        </div>
                    </div>

                    {/* </div> */}
                    <div>{this.renderMyLoveTable()}</div>
                    <div style={{ fontSize: 10, padding: 5, color: BColor.faircolor }}>
                        免責聲明: 本站提供之分析資料、選股工具僅供參考，不暗示買賣建議，本站對資訊正確、更新延誤或傳輸中斷不負任何責任，依本站資訊交易發生損失需自行負責，請謹慎評估風險。
                    </div>
                </div>


            </div>

        );
    }
}

export default withRouter(withStyles(styles)(MyRulesBoard1));