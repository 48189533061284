import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function Fakk1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false);

    const [turn1, setTurn1] = React.useState(0)
    const [turn2, setTurn2] = React.useState(0)
    const [turn3, setTurn3] = React.useState(0)
    const [turn4, setTurn4] = React.useState(0)
    const [avg, setAvg] = React.useState(5)

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let param = {
                lic: lic,
                sid: props.row.market_company.stock_no,
                avg: avg,
                turn1: turn1,
                turn2: turn2,
                turn3: turn3,
                turn4: turn4
            };

            fetch("/jstock/v1/stock/fakk1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setLoading(false)
                        if (result.data != null) {
                            setData(result.data)
                        }
                    }
                )

        }
    }

    const getColor = (i) => {
        if (theme.palette.type === "light") {
            if (i % 4 === 0) return "#32a852"
            else if (i % 4 === 1) return "#662387"
            else if (i % 4 === 2) return "#d95b21"
            else return "#21bad9"
        } else {
            if (i % 4 === 0) return "#32a852"
            else if (i % 4 === 1) return "#662387"
            else if (i % 4 === 2) return "#d95b21"
            else return "#21bad9"
        }

    }

    const getColor1 = (i) => {
        if (i === "1") return "#8f2ca3"
        else return "#23434a"
    }

    const getColor2 = (i) => {
        if (i === "1") return "#64b3a5"
        else return "#FFFFFF"
    }

    function genData1() {
        let option = {
            animation: false,
            title: [
            ],
            grid: [
                {
                    top: '10',
                    left: '10',
                    right: '10',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.3
                        }
                    }
                }
            ]
        };


        let x1 = {
            type: 'category',
            data: data.data.map(v => { return v.x })
        }



        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = [x1]

        let xa = []
        if (data.xs != null) {
            data.xs.map(x => {
                xa.push(
                    {
                        name: x.x,
                        xAxis: x.x,
                        lineStyle: {
                            color: getColor(x.i),
                            width: 1,
                            type: 'solid'
                        },
                        label: {
                            show: false
                        }
                    }
                )
            })
        }

        if (data.padding != null) {
            data.padding.map(x => {
                xa.push(
                    {
                        name: x.i,
                        xAxis: x.x,
                        lineStyle: {
                            color: getColor1(x.i),
                            width: 4,
                            type: 'dashed'
                        },
                        label: {
                            show: false,
                            formatter: '{b}',
                            position: 'middle',
                            padding: 0,
                            color: getColor1(x.i)
                        }
                    }
                )
            })
        }


        let trandata = []
        if (data.lp != null) {
            data.lp.map(tt => {
                trandata.push({
                    name: tt.x,
                    coord: [tt.x, tt.y],
                    value: tt.i,
                    symbol: 'pin',
                    itemStyle: {
                        color: theme.palette.type === "light" ? getColor1(tt.i) : getColor2(tt.i)
                    },
                })
            })
        }

        // if (data.padding != null) {
        //     data.padding.map(tt => {
        //         trandata.push({
        //             name: tt.x,
        //             coord: [tt.x, tt.y],
        //             value: tt.i,
        //             symbol: 'rect',
        //             symbolSize: 20,
        //             label: {
        //                 show:true,
        //             },
        //             itemStyle: {
        //                 color: theme.palette.type === "light" ? getColor1(tt.i) : getColor2(tt.i)
        //             },
        //         })
        //     })
        // }

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                // type: 'line',
                data: data.data.map(vv => {
                    return [vv.y[0], vv.y[1], vv.y[2], vv.y[3]]
                }),
                // data: data.data.map(vv => {
                //     return vv.y[1]
                // }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markPoint: {
                    symbol: 'circle',
                    symbolSize: 25,
                    symbolOffset: [0, -30],
                    label: {
                        padding: 10,
                    },
                    itemStyle: {
                        // color: theme.palette.type === "light" ? "#23434a" : "#FFFFFF"
                    },
                    data: trandata
                },
                markLine: {
                    symbol: 'none',
                    data: xa
                }

            }
        ]


        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(60, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getComputerDataZoom(0, 100, 350)
        }


        return option
    }


    useEffect(() => {
        if (data != null) setOptions(genData1())
    }, [data]);

    useEffect(() => {
        if (props.row != null) getPHV1()
    }, [props.row, turn1, turn2, turn3, turn4, avg]);

    function getString() {
        return "目前無相關的K線分割圖"
    }

    return (
        <div>
            {localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1" ? <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginTop: 5, marginBottom: 5, width: '100%', overflowX:'auto' }}>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">區域1</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s2-label1"
                                id="turn1"
                                value={turn1}
                                onChange={e => setTurn1(e.target.value)}
                            >
                                <MenuItem value={"0"}>0個點</MenuItem>
                                <MenuItem value={"1"}>1個點</MenuItem>
                                <MenuItem value={"2"}>2個點</MenuItem>
                                <MenuItem value={"3"}>3個點</MenuItem>
                                <MenuItem value={"4"}>4個點</MenuItem>
                                <MenuItem value={"5"}>5個點</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">區域2</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s2-label2"
                                id="turn2"
                                value={turn2}
                                onChange={e => setTurn2(e.target.value)}
                            >
                                <MenuItem value={"0"}>0個點</MenuItem>
                                <MenuItem value={"1"}>1個點</MenuItem>
                                <MenuItem value={"2"}>2個點</MenuItem>
                                <MenuItem value={"3"}>3個點</MenuItem>
                                <MenuItem value={"4"}>4個點</MenuItem>
                                <MenuItem value={"5"}>5個點</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">區域3</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s2-label3"
                                id="turn3"
                                value={turn3}
                                onChange={e => setTurn3(e.target.value)}
                            >
                                <MenuItem value={"0"}>0個點</MenuItem>
                                <MenuItem value={"1"}>1個點</MenuItem>
                                <MenuItem value={"2"}>2個點</MenuItem>
                                <MenuItem value={"3"}>3個點</MenuItem>
                                <MenuItem value={"4"}>4個點</MenuItem>
                                <MenuItem value={"5"}>5個點</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">區域4</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s2-label4"
                                id="turn4"
                                value={turn4}
                                onChange={e => setTurn4(e.target.value)}
                            >
                                <MenuItem value={"0"}>0個點</MenuItem>
                                <MenuItem value={"1"}>1個點</MenuItem>
                                <MenuItem value={"2"}>2個點</MenuItem>
                                <MenuItem value={"3"}>3個點</MenuItem>
                                <MenuItem value={"4"}>4個點</MenuItem>
                                <MenuItem value={"5"}>5個點</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">均線</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s2-avg"
                                id="avg"
                                value={avg}
                                onChange={e => setAvg(e.target.value)}
                            >
                                <MenuItem value={"2"}>2日</MenuItem>
                                <MenuItem value={"3"}>3日</MenuItem>
                                <MenuItem value={"4"}>4日</MenuItem>
                                <MenuItem value={"5"}>5日</MenuItem>
                                <MenuItem value={"7"}>7日</MenuItem>
                                <MenuItem value={"9"}>9日</MenuItem>
                                <MenuItem value={"10"}>10日</MenuItem>
                                <MenuItem value={"20"}>20日</MenuItem>
                                <MenuItem value={"40"}>40日</MenuItem>
                                <MenuItem value={"60"}>60日</MenuItem>
                            </Select>
                        </FormControl>

                    </div>

                    <div style={{ fontSize: 14, color: "#808080" }}>

                    </div>
                    {options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 400, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    /> : <div style={{}}>{getString()}</div>}
                </Grid>

            </Grid> : <div>目前K線分割圖僅開放給訂閱者。</div>}
        </div >
    );
}