import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";
import RuleTable from './RuleTable1'
import Button from '@material-ui/core/Button';
import AlertDialog from "../AlertDialog"
import BPage from '../BPage'
import RestoreDialog from '../RestoreDialog';
import { getSynNo } from '../util/Syno'
import MoveStrange from './MoveStrange';

const styles = theme => ({
    root: {
        // display: "flex",
        // flexDirection: "columnn
    },
    table: {
        left: 0,
        right: 0,
        bottom: 0,
        marginBottom: 20,
        marginTop: 5,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    menu1: {
        marginBottom: 8
    },
    menu2: {
        display: "flex",
        flexDirection: "columnn",
        marginBottom: 8
    },
    menu3: {
        marginBottom: 20
    },
    tableLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.secondary.dark
    },
    button: {
        // marginLeft: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        marginRight: 5,
    },
    input: {
        display: 'none',
    },
    textField: {
        marginTop: 0,
        marginBottom: 0,
        width: 80,
        verticalAlign: 'bottom',
        color: '#404040'
    },
    menua: {
        marginTop: 2,
        // [theme.breakpoints.down('sm')]: {
        //     padding: 5,
        // },
        // [theme.breakpoints.up('md')]: {
        //     padding: 15,
        // },
        // [theme.breakpoints.up('lg')]: {
        //     padding: 15,
        // },
    },
})


class MyStrageBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            items: null,
            top: null,
            loveitems: null,
            symbol: null,
            lictxt: '',
            age: '1',
            bigtitle: null,
            open2: false,
            open3: false,
            open4: false,
            open5: false,
            open6: false,
            runid: null
        };
    }


    componentDidMount() {
        if (localStorage.getItem('top_rules') != null) {
            this.initMyRules()
        } else {
            this.getMyRules()
        }
    }


    // (synno : String, id : String, lic : Option[String] = None)
    // removeloverule
    handleDelete = (id) => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')

        let param = lic === null ? {
            synno: synno,
            id: id
        } : {
                synno: synno,
                id: id,
                lic: lic
            };

        this.fectchDelAPI(param)
    };



    updateStockName = (name, id) => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')

        let param = lic === null ? {
            synno: synno,
            id: id,
            name: name
        } : {
                synno: synno,
                lic: localStorage.getItem('lic'),
                id: id,
                name: name
            };

        fetch("/jstock/v1/stock/rulecname", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        this.setState({
                            items: result.data.allrules
                        })
                    }
                },
                error => {
                }
            );
    }

    fectchDelAPI(param) {
        fetch("/jstock/v1/stock/removeloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        this.setState({
                            items: result.data.allrules
                        })
                    }
                },
                error => {
                }
            );
    }

    runRuleData1 = (rules, id) => {
        this.setState({ runid: id });
        this.props.runRuleData(rules) 
    }

    renderTable() {
        const { error, isLoaded, items, top } = this.state;
        const page = 0

        if (error) {
            return "";
        } else if (!isLoaded) {
            return "";
        } else if (items === null) {
            return "";
        } else if (items.length === 0) {
            return <div style={{ color: '#b3b3b3', fontSize: 14 }}>您目前還沒有任何的選股策略，按下儲存規則可以將您辛苦設定的挖股條件保存下來。</div>;
        } else {
            // window.scrollTo(0, 0);
            return <RuleTable clearRule={this.clearRule} data={items} runid={this.state.runid} runRuleData={this.runRuleData1} addbt={false} delbt={true} runDelRuleData={this.handleDelete} updateStockName={this.updateStockName} share={this.share} getMyRules={this.getMyRules} />;
        }
    }


    shareLove = () => {
        let prefix = this.state.age
        if (prefix === '1') prefix = ''
        const lname = prefix + 'love_stock'

        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        const loveItems = localStorage.getItem(lname) != null ? JSON.parse(localStorage.getItem(lname)) : []
        let lic = localStorage.getItem('lic')
        let name = this.getItem(this.state.age)

        let param = lic === null ? {
            name: name,
            stock1: loveItems,
            syno: synno
        } : {
                name: name,
                lic: lic,
                stock1: loveItems,
                syno: synno
            };


        fetch("/jstock/v1/stock/sharelove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        this.setState({ open2: true });
                    } else if (result.status === -1) {
                        this.setState({ open3: true });
                    } else if (result.status === -2) {
                        this.setState({ open4: true });
                    }
                },
                error => {
                }
            );

    }

    getMyRules = () => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let menu = this.state.age

        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
            menu: menu
        } : {
                synno: synno,
                lic: localStorage.getItem('lic'),
                menu: menu
            };


        fetch("/jstock/v1/stock/getloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        this.setState({
                            items: result.data.allrules
                        })
                    }
                },
                error => {
                }
            );

    }


    initMyRules = () => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')
        let topItems = localStorage.getItem('top_rules') != null ? JSON.parse(localStorage.getItem('top_rules')) : []
        let param = lic === null ? {
            allrules: topItems,
            synno: synno
        } : {
                allrules: topItems,
                lic: lic,
                synno: synno
            };
        this.fectchSaveRuleAPI(param)
    }


    fectchSaveRuleAPI(param) {
        fetch("/jstock/v1/stock/initloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        localStorage.removeItem("top_rules")
                    } else {
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => {
                }
            );
    }

    handleOpen1() {
        this.props.tabchange(BPage.donate_page)
    }

    handleOpen5() {
        this.setState({ open5: true });
    }    

    handleOpen6() {
        this.setState({ open6: true });
    }   

    // (name : String, tp : Int = 0, stock1 : Option[List[String]] = None, rule1 : Option[MyRule] = None, syno : String, nickname : Option[String] = None, lic : Option[String] = None, desc : Option[String] = None)
    share = (raw, name) => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')

        let param = lic === null ? {
            name: name,
            tp: 1,
            syno: synno,
            rule1: raw,
            desc: raw.desc.map((data, index) => { return "<li key=" + index + ">" + data + "</li>" }).join('')
            //raw.desc.join("")
        } : {
                name: name,
                tp: 1,
                lic: lic,
                syno: synno,
                rule1: raw,
                desc: raw.desc.map((data, index) => { return "<li key=" + index + ">" + data + "</li>" }).join('')
            };


        fetch("/jstock/v1/stock/sharelove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        this.setState({ open2: true });
                    } else if (result.status === -1) {
                        this.setState({ open3: true });
                    } else if (result.status === -2) {
                        this.setState({ open4: true });
                    }
                }
            );

    }

    handleClose2 = () => {
        this.setState({ open2: false });
    };
    handleClose3 = () => {
        this.setState({ open3: false });
    };
    handleClose4 = () => {
        this.setState({ open4: false });
    };

    handleClose5 = () => {
        this.setState({ open5: false });
        this.getMyRules();
    };

    clearRule = () => {
        this.setState({runid: null})
        this.props.clearRule()
    }

    handleClose6 = () => {
        this.setState({ open6: false });
        this.getMyRules();
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <RestoreDialog open={this.state.open5} handleClose={this.handleClose5} />
                <MoveStrange open={this.state.open6} handleClose={this.handleClose6} />

                <AlertDialog
                    open={this.state.open2}
                    title=''
                    desc='感謝您的無私分享，我們會盡快地將它呈現在股民面前。'
                    pos_label='確定'
                    linkto='#'
                    handleClose={this.handleClose2}
                />
                <AlertDialog
                    open={this.state.open3}
                    title=''
                    desc='很抱歉，已經有人分享過類似的清單。'
                    pos_label='確定'
                    linkto='#'
                    handleClose={this.handleClose3}
                />
                <AlertDialog
                    open={this.state.open4}
                    title=''
                    desc='很抱歉，24小時內只能分享一次，請明天在繼續吧。'
                    pos_label='確定'
                    linkto='#'
                    handleClose={this.handleClose4}
                />
                <div className={classes.menua}>
                    {this.props.tabchange != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                            訂閱
                        </Button> : null
                    }

                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.clearRule() }>
                        清除規則
                    </Button>
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen5()}>
                        還原
                    </Button>
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen6()}>
                        調整順序
                    </Button>
                </div>
                <div className={classes.table}>
                    {/* <div className={classes.tableLabel}>我收藏的規則</div> */}
                    <div>{this.renderTable()}</div>
                    {/* <div style={{fontSize: 10, padding: 5, color: BColor.faircolor}}>
    免責聲明: 本站提供之分析資料、選股工具僅供參考，不暗示買賣建議，本站對資訊正確、更新延誤或傳輸中斷不負任何責任，依本站資訊交易發生損失需自行負責，請謹慎評估風險。
  </div> */}
                </div>

            </div>

        );
    }
}

export default withRouter(withStyles(styles)(MyStrageBoard));