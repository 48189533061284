import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TrendingUp from '@material-ui/icons/TrendingUp';
import AccountBalance from '@material-ui/icons/AccountBalance';
import Assessment from '@material-ui/icons/Assessment';
import Backup from '@material-ui/icons/Backup';
import CardTravel from '@material-ui/icons/CardTravel'
import Commute from '@material-ui/icons/Commute'
import OfflineBolt from '@material-ui/icons/OfflineBolt'
import GroupWork from '@material-ui/icons/GroupWork'
import DonutSmall from '@material-ui/icons/DonutSmall'
import Explore from '@material-ui/icons/Explore'
import Fingerprint from '@material-ui/icons/Fingerprint'
import EventSeat from '@material-ui/icons/EventSeatOutlined'
import Rowing from '@material-ui/icons/Rowing'
import Reorder from '@material-ui/icons/Reorder'
import Visibility from '@material-ui/icons/Visibility'
import Extension from '@material-ui/icons/ExtensionOutlined'
import Star from '@material-ui/icons/StarsRounded'
import TextR from '@material-ui/icons/TextRotateUp'
import FindReplace from '@material-ui/icons/FindReplace'
import Polymer from '@material-ui/icons/Polymer'
import Print from '@material-ui/icons/Print'
import OpenInNew from '@material-ui/icons/OpenInNew'
import AspectRatio from '@material-ui/icons/AspectRatio'
import Backspace from '@material-ui/icons/Backspace'
import Cached from '@material-ui/icons/Cached'
import Deck from '@material-ui/icons/Deck'

import CardHeader from '@material-ui/core/CardHeader';
import AcUnit from '@material-ui/icons/AcUnit'
import Book from '@material-ui/icons/Book'
import '../rules/RadioBox.css'
import ADown from '@material-ui/icons/ArrowDropDown'
import AUP from '@material-ui/icons/ArrowDropUp'
import SaveOtherDialog from '../dialog/SaveOtherDialog'
import DoubleArrow from '@material-ui/icons/DoubleArrow'
import BColor from '../BColor'

const useStyles = makeStyles((theme) => ({
    card: {
        // minWidth: 200,
        maxHeight: 70,
        border: '1px #c5d9c5 solid',
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
        // marginBottom:15,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        // marginTop: -10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
        },
        // color: "#000205",
        // whiteSpace: "nowrap"
    },
    pos: {
        marginTop: 5,
        // color: "#777777"
    },
    title1: {
        // marginTop: 5,
        fontSize: 10,
        // color: "#9e9e9e",
        // whiteSpace: "nowrap"
    },
    avatar1: {
        fontSize: 22,
    },
    avatar2: {
        // color: '#c94770',
        fontSize: 22
    },
    avatar3: {
        fontSize: 14,
        // color: BColor.faircolor,

    },
    root1: {
        display: 'flex',
        fontSize: 12,
        marginTop: 3
        // flexWrap: 'wrap',
        // justifyContent: 'space-around',
        // overflow: 'hidden',
        // backgroundColor: theme.palette.background.default,
    }

}));

// const enableColor = "#f6fcdc"
// const disableColor = "#FFFFFF"

export default function UserRule(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [enable, setEnable] = React.useState(false)
    const [open, setOpen] = React.useState(false)

    const getEnableColor = () => {
        return theme.palette.type === 'light' ? "#f6fcdc" : "#474747"
    }

    const handleOpen = (desc) => {
        if (props.desc != null && props.desc != "") {
            setOpen(true)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSwitchChange = event => {
        handleCardChange(event.target.checked);
    };


    const handleCardChange = (enabled) => {
        setEnable(enabled)
        updateValue(enabled)
    }

    const clickTitle = () => {
        handleCardChange(!enable);
    };

    const updateValue = (enabled) => {
        let data = {
            id: props.id
        }

        if (enabled) {
            props.ruleChange(props.id, data, enabled)
        } else {
            props.ruleChange(props.id, data, enabled)
        }
    }

    const rendericon = (value) => {
        if (value === -99999) {
            return <div style={{ color: "#303030" }} onClick={e => clickTitle()} ></div>
        } else if (value >= 0) {
            return <div style={{ color: "#ff6363" }} onClick={e => clickTitle()}><AUP /></div>
        } else if (value <= 0) {
            return <div style={{ color: "#39a632" }} onClick={e => clickTitle()} ><ADown /></div>
        } else {
            return <div style={{ color: "#303030" }} onClick={e => clickTitle()} ></div>
        }
    }

    const renderper = (value) => {
        if (value === -99999) {
            return <div style={{ color: "#303030" }} onClick={e => clickTitle()}></div>
        } else if (value >= 0) {
            return <div style={{ color: "#ff6363" }} onClick={e => clickTitle()}>{value}%</div>
        } else if (value <= 0) {
            return <div style={{ color: "#39a632" }} onClick={e => clickTitle()} >{-value}%</div>
        } else {
            return <div style={{ color: "#303030" }} onClick={e => clickTitle()}>{value}%</div>
        }
    }

    const isTurnOn = () => {
        if (props.rules != undefined) return props.rules.some(rule => rule.id === props.id);
        else return false
    }

    const getIcon = () => {
        let icon = <TrendingUp color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        if (parseInt(props.id) % 30 === 0) {
            icon = <TrendingUp color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 1) {
            icon = <AccountBalance color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 2) {
            icon = <Assessment color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 3) {
            icon = <Backup color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 4) {
            icon = <CardTravel color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 5) {
            icon = <Commute color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 6) {
            icon = <OfflineBolt color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 7) {
            icon = <GroupWork color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 8) {
            icon = <DonutSmall color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 9) {
            icon = <Explore color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 10) {
            icon = <Fingerprint color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 11) {
            icon = <EventSeat color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 12) {
            icon = <Rowing color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 13) {
            icon = <Reorder color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 14) {
            icon = <Visibility color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 15) {
            icon = <Extension color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 16) {
            icon = <Star color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 17) {
            icon = <TextR color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 18) {
            icon = <Print color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 19) {
            icon = <Polymer color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 20) {
            icon = <FindReplace color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 21) {
            icon = <AspectRatio color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 22) {
            icon = <OpenInNew color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 23) {
            icon = <AcUnit color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 24) {
            icon = <Book color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 25) {
            icon = <Backspace color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 26) {
            icon = <Cached color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else if (parseInt(props.id) % 30 === 27) {
            icon = <Deck color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        } else {
            icon = <TrendingUp color="primary" className={classes.avatar1} onClick={e => clickTitle()} />
        }
        return icon
    }

    return (
        <Card className={classes.card}  style={{ backgroundColor: isTurnOn() ? getEnableColor() : null }}>

            {open ?<SaveOtherDialog
                id={props.id}
                open={open}
                title='投資策略'
                desc={props.desc}
                handleClose={e => handleClose()}
            />: null}

            <CardHeader
                avatar={getIcon()}
                title={
                    <div onClick={e => clickTitle()}>
                        <div className={classes.title} >{props.t1}</div>
                    </div>
                }
                subheader={
                    <div className={classes.root1} onClick={e => clickTitle()}>
                        <div>5日平均漲幅</div>{rendericon(props.incp)}{renderper(props.incp)}
                    </div>
                }

                action={
                    props.desc === "" || props.desc === undefined ? "" : <DoubleArrow className={classes.avatar3} onClick={e => handleOpen()} />
                }
            />
        </Card>
    );

}
