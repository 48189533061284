import React, { Component } from 'react';
import update from 'immutability-helper';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend'
import ReorderListItem from './ReorderListItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { DndProvider } from 'react-dnd'

class ReorderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items && props.items.map(item => ({ id: item.id, text: item.text })),
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.moveListItem = this.moveListItem.bind(this);
  }

  handleOnClick(item) {
    // You may pass handle on click event to props
    // console.log(item);
  }

  moveListItem(dragIndex, hoverIndex) {
    const { items } = this.state;
    const dragitem = items[dragIndex];

    this.setState(
      update(this.state, {
        items: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragitem]],
        },
      }),
    );
    // You may pass the state to props
    // console.log(this.state.items);
    this.props.update(this.state.items)
  }

  render() {
    const { items } = this.state;

    return (
      <div>
        {this.props.mobile != null && this.props.mobile === true ? <DndProvider backend={TouchBackend}>
          <List>
            {items.map((item, i) => (
              <ListItem><div style={{}}>{item.text}</div>
                <ReorderListItem
                  key={item.id}
                  handleOnClick={() => this.handleOnClick(item)}
                  index={i}
                  id={item.id}
                  text={item.text}
                  moveListItem={this.moveListItem}
                />
              </ListItem>
            ))}
          </List>
        </DndProvider> :
          <DndProvider backend={HTML5Backend}>
            <List>
              {items.map((item, i) => (
                <ListItem>
                  {/* <div style={{}}>{item.text}</div> */}
                <ReorderListItem
                  key={item.id}
                  handleOnClick={() => this.handleOnClick(item)}
                  index={i}
                  id={item.id}
                  text={item.text}
                  moveListItem={this.moveListItem}
                />
                </ListItem>
              ))}
            </List>
          </DndProvider>}
      </div>
    );
  }
}

export default ReorderList;