import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FinRateList from '../details/FinRateList';
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function MHighLowDetail(props) {
    const classes = useStyles();
    let redcolor = "#ed5f84"
    let backcolor = "#FFFFFF"
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox
    let greencolor = "#53a66f"

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opentp, setOpenTp] = React.useState(0);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };



    function render1(value, unit) {
        let color = BColor.faircolor

        let k = value + unit
        if (value <= 0) k = '-'

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: color, marginRight: 1 }} className={classes.button2} >
                {k}
            </Button>
        </StyledTableCell>
    }

    function renderEmpty() {
        return <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >-</Button>
        </StyledTableCell>
    }

    function render2(v, index, unit) {
        let color = BColor.faircolor
        let value = ''

        if (index === 0) {
            color = BColor.upcolorbox
            value = '高檔'
        } else if (index === 1) {
            color = BColor.downcolorbox
            value = '低檔'
        } else if (index === 2) {
            value = '中間'
        } else {
            value = '-'
            if (v === 0) v = ''
        }

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} size="small" align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: color, marginRight: 1 }} className={classes.button2} >
                {value} {v}{unit}
            </Button>
        </StyledTableCell>
    }



    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近六個月
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.p, props.row.hl.pi, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.lp, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.hp, '元') :  renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.pe, props.row.hl.ei, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.le, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.he, '') : renderEmpty() }
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近一年
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'10'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.p, props.row.hl.pi1, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'11'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.lp1, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'12'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.hp1, '元') :  renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'13'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.pe, props.row.hl.ei1, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'14'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.le1, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'15'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.he1, '') : renderEmpty() }
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>



                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近二年
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'20'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.p, props.row.hl.pi2, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'21'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.lp2, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'22'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.hp2, '元') :  renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'23'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.pe, props.row.hl.ei2, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'24'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.le2, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'25'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.he2, '') : renderEmpty() }
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近三年
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'30'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.p, props.row.hl.pi3, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'31'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.lp3, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'32'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.hp3, '元') :  renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'33'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.pe, props.row.hl.ei3, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'34'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.le3, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'35'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.he3, '') : renderEmpty() }
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近五年
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'50'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.p, props.row.hl.pi5, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'51'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.lp5, '元') : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'52'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高價'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.hp5, '元') :  renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'53'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render2(props.row.hl.pe, props.row.hl.ei5, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'54'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最低本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.le5, '')  : renderEmpty() }
                                </StyledTableRow >

                                <StyledTableRow key={'55'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最高本益比'}
                                    </StyledTableCell>

                                    {props.row.hl != null && props.row.hl != undefined ? render1(props.row.hl.he5, '') : renderEmpty() }
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    );
}