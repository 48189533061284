import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function SaveRuleDialog(props) {
  // const [open, setOpen] = React.useState(props.open);

  const yref = React.createRef();

  // const handleClose = () => {
  //   setOpen(false)
  // }

  const handleOk = () => {
    saveRule(yref.current.value)
  };


  useEffect(() => {
    if (localStorage.getItem('top_rules') != null) {
      initMyRules()
    }
  }, []);

  
  function makeid1(length) {
    var result = '';
    var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  function getSynNo() {
    let id = makeid1(7) + "000"
    localStorage.setItem("synno", id)
    return id
  }

  const initMyRules = () => {
    let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
    let lic = localStorage.getItem('lic')
    let topItems = localStorage.getItem('top_rules') != null ? JSON.parse(localStorage.getItem('top_rules')) : []
    let param = lic === null ? {
      allrules: topItems,
      synno: synno
    } : {
        allrules: topItems,
        lic: lic,
        synno: synno
      };
    fectchInitRuleAPI(param)
  }

  const fectchInitRuleAPI = (param) => {
    fetch("/jstock/v1/stock/initloverule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(param)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.status === 0) {
            localStorage.removeItem("top_rules")
          }
        },
        error => {
        }
      );
  }

  //case class MyRule(id : String, rules : List[RuleParam], name : String, desc : List[String])
  // case class MyLoveRuleAddReq(synno : String, rule : MyRule, lic : Option[String] = None)

  const saveRule = (name) => {
    let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
    let lic = localStorage.getItem('lic')

    if (props.rules.length > 0) {
      let param = lic === null ? {
        synno: synno,
        name: name,
        rules: props.rules
      } : {
          lic: lic,
          synno: synno,
          name: name,
          rules: props.rules
        };
      fectchSaveRuleAPI(param)

    }
  }

  const fectchSaveRuleAPI = (param) => {
    fetch("/jstock/v1/stock/addloverule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(param)
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.status === 0) {
            props.handleClose()
          } else {
            props.handleClose()
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
        }
      );
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">建立策略</DialogTitle>

      <DialogContent>
        <div style={{ fontSize: 12, color: '#b3b3b3' }}>
          將目前的選股規則存成往後可以重複使用的選股策略。選股策略也可以分享給所有股友。<br /><br />
        </div>

        <br />
        <TextField
          autoFocus
          margin="dense"
          id="y"
          label="策略名稱"
          type="text"
          fullWidth
          inputRef={yref}
          inputProps={{
            maxLength: 20,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />


      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          取消
          </Button>
        <Button onClick={handleOk} color="primary">
          確定
          </Button>
      </DialogActions>
    </Dialog>
  );
}
