import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function WinRateSetting(props) {
    //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const hrref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('wl1', parseInt(yref.current.value));

        if (hrref.current.value != null && hrref.current.value != "")
            localStorage.setItem('wl2', parseInt(hrref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('wl1') === null ? 10 : localStorage.getItem('wl1')
    }

    function hR() {
        return localStorage.getItem('wl2') === null ? 15 : localStorage.getItem('wl2')
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">期望指標參數</DialogTitle>

            <DialogContent>
                <br /><br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="期望天數1(預設:10)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="hr"
                    label="期望天數2(預設:15)"
                    type="text"
                    fullWidth
                    defaultValue={hR()}
                    inputRef={hrref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}
