import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import ReorderList from '../reorder/DnDList'
import { getSynNo } from '../util/Syno';


function createData(id, stock) {
    return { id: id, text: stock };
}


const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    button1: {
      minWidth: 110,
    }
  }));

export default function MoveMyLove(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState(null);

    useEffect(() => {
        // if (props.open) getMylove(props.menu)
 
    }, [props.menu, props.open]);


    const upload = () => {
        // let data = localStorage.getItem("myloveitem")
        if(buyselldata != null && buyselldata.length > 0) {
            let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()

            let a = {
                synno: synno,
                menu: props.menu,
                ml: buyselldata
            }

            if (localStorage.getItem('lic') != null)
                a["lic"] = localStorage.getItem('lic')   
        

            fetch("/jstock/v1/stock/sortlove", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
            .then(res => res.json())
            .then(
                result => {
                    props.handleClose()
                },
                error => {
                    props.handleClose()
                }
            );
        } else props.handleClose()
    }

    const uploadMyLove = (data) => {
        if(data != null && data.length) {
            setBuyselldata(data)
            // localStorage.setItem("myloveitem", data)
        }
    }

    const handleClose = () => {
        upload()
        // localStorage.remove("myloveitem")
        
    };


    function getItems()  {
        return props.loveitems.map(row =>
            createData(row.market_company.stock_no, row.market_company.stock_no + " " + row.market_company.short_name)
        )
    }
    
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">調整股票順序</DialogTitle>

            <DialogContent>
                {props.loveitems != null && props.loveitems.length > 0 ? <ReorderList items={getItems()} update={uploadMyLove} mobile={props.mobile}></ReorderList>: null}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
