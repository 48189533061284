import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Button from '@material-ui/core/Button'
import PressureIndexSetting from './PressureIndexSetting'


echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function PressureIndex(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);

    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (props.row != null) getPHV1()
    }, [props.row]);

    useEffect(() => {
        if (data != null) setOptions(genData1())
    }, [data]);

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')
        let pressa = localStorage.getItem('pressa') != null ? localStorage.getItem('pressa') : 21 

        if (lic != null) {
            let param = {
                lic: lic,
                sid: props.row.market_company.stock_no,
                inp: {}
            };

            param.inp["pressa"] = pressa

            fetch("/jstock/v1/stock/pressindex", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setLoading(false)
                        if (result != null) {
                            setData(result)
                        }
                    }
                )

        }
    }


    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        getPHV1()
        setOpen(false);
    };




    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)


    function yy() {
        return localStorage.getItem('pressa') === null ? "21日" : localStorage.getItem('pressa') + "日"
    }


    function genData1() {
        let option = {
            // legend: {
            //     data: ['昂貴區', '合理區', '便宜區', '股價',]
            // },
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '10',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '300',
                    left: '40',
                    right: '10',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    show: true,
                    gridIndex: 1,
                    scale: true,
                    type: 'value',
                    axisLabel: {
                        show: true
                    },
                    splitLine: {
                        show: true
                    }
                }
            ],
            visualMap: {
                show: false,
                type: 'piecewise',
                seriesIndex: 1,
                min: -100,
                max: 100,
                pieces: [
                    { min: -100, max: 0, color: '#65b206' },
                    { min: 0, max: 100, color: "#d41c1c" },
                ]
            },
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data.map(v => { return v.x }),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.x }),
        }


        if (props.ismobile) {
            let k = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = k
            x2['axisPointer'] = k
        }

        option['xAxis'] = [x1, x2]

        option['series'] = [
            {
                name: '日K',
                type: 'candlestick',
                data: data.map(v => { return v.y }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            // {
            //     name: yy() + '壓力',
            //     type: 'line',
            //     data: data.map(v => { return v.v1 }),
            //     symbol: 'none',
            //     labelLayout: {
            //         moveOverlap: 'shiftY'
            //     },
            //     endLabel: {
            //         show: false
            //     },
            //     xAxisIndex: 1,
            //     yAxisIndex: 1,
            //     smooth: 'true'
            // },
            // {
            //     name: yy() + '支撐',
            //     type: 'line',
            //     data: data.map(v => { return v.v2 }),
            //     symbol: 'none',
            //     labelLayout: {
            //         moveOverlap: 'shiftY'
            //     },
            //     endLabel: {
            //         show: false
            //     },
            //     xAxisIndex: 1,
            //     yAxisIndex: 1,
            //     smooth: 'true'
            // }
            {
                name: '支壓線',
                type: 'bar',
                color: "#ff7171",
                areaStyle: {
                },
                emphasis: {
                    focus: 'series'
                },
                data: data.map(v => { return v.v3 }),
                symbol: 'none',
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                endLabel: {
                    show: true
                },
                xAxisIndex: 1,
                yAxisIndex: 1,
                smooth: 'true'
            }


        ]


        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 0,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '600',
                    start: 0,
                    end: 100
                }
            ]

        }

        return option
    }

    function getString() {
        return "目前無相關的支壓圖"
    }

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <PressureIndexSetting open={open1} handleClose={handleClose} handleOk={handleOk}></PressureIndexSetting>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        支壓圖<Button style={{ marginLeft: 2 }} size="small" color="primary" variant="outlined" onClick={(e) => handleOpen()}>設定</Button>
                        </Typography>
                    </div>
                    <div style={{ fontSize: 14, color: "#808080" }}>
                        紅色柱為支撐，數值為0 ~ 100，越長代表支撐強度越大<br />
                        綠色柱為壓力，數值為0 ~ -100，越長代表壓力強度越大。<br />
                        近N天 (收盤價與最低價的距離 * 成交量權重) 加總 - 近N天(收盤價與最高價的距離 * 成交量權重)加總<br />
                        因投資週期長短可自行調整天數，例如:短線5日，中線20, 50日，長線100日<br />
                        
                        
                    </div>
                    {options != null ? <ReactEChartsCore
                        style={{ height: 700, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    /> : <div style={{}}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}