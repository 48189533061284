import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'
import SimpleStockList from './SimpleStockList'
import StockName from '../StockName';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import OkDialog from '../dialog/OkDialog'

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment'
import { getSynNo, putInpQueryParam } from '../util/Syno'

import MoveStrange from '../strage/MoveStrange'
import EditStrage from '../strage/EditStrage'

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const StyledSelect = withStyles({
    root: {
        padding: 9,
    }
})(Select);

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(0.5),
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(0.5),
        marginRight: 2,
        marginLeft: 2
    },
    button1: {
        marginTop: theme.spacing(0.5),
        marginLeft: 0
    },
}));

export default function StrageList(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState(null);
    const [sids, setSIDs] = React.useState([]);
    const [open, setopen] = React.useState(false)
    const [open1, setopen1] = React.useState(false)
    const [open2, setopen2] = React.useState(false)
    const [open3, setopen3] = React.useState(false)
    const [open4, setopen4] = React.useState(false)
    const [open5, setopen5] = React.useState(false)
    const [open6, setopen6] = React.useState(false)

    const [errorOpen, setErrorOpen] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState(null)

    const [isLoaded, setLoaded] = React.useState(true);
    const [count, setCount] = React.useState(20)
    const [page, setPage] = React.useState(0)
    const [total, setTotal] = React.useState(0)
    const [error, setError] = React.useState(null)
    const [items, setItems] = React.useState(null)
    const [bigtitle, setBigtitle] = React.useState(null)
    const [fmenulist, setFmenuList] = React.useState([])
    const [nameupdate, setNameUpdate] = React.useState(true)
    const [rules, setRules] = React.useState([])
    const [strage, setStrage] = React.useState(null)
    const [desc, setDesc] = React.useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);

    const [openEdit, setOpenEdit] = React.useState(false);
    const needRefresh = React.useRef(true);

    const detailUpdate = (value) => {
        needRefresh.current = !value
    }

    const handleCloseEdit = () => {
        setOpenEdit(false)
    };

    const handleEditOk = () => {
        setOpenEdit(false)
        getMylove(age)
        // runFectchAPII(rules, page, count)
    };

    const handleOpenEdit = () => {
        setAnchorEl(null);
        if (sids.length > 1) {
            setErrorMsg("一次只能修改一個策略。")
            setErrorOpen(true)
        } else if (strage == null) {
            setErrorMsg("請至少選擇一個策略來修改。")
            setErrorOpen(true)
        } else setOpenEdit(true)
    }


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openRuleDialog1 = (event) => {
        const {
            target: { value },
        } = event;
        setSIDs(
            typeof value === 'string' ? value.split(',') : value,
        );

        setAnchorEl(null);
        if (value != null && value.length > 0) {
            setAge(value[value.length - 1])
            let r = getAllRules(value)
            updateRule(r)
        } else {
            setAge(null)
            setRules([])
            setItems(null)
            setStrage(null)
            setDesc(null)
        }
    }


    const updateRule = (r) => {
        if (r != null && r.length > 0) {
            setPage(0)
            setStrage(r[r.length - 1])

            let a1 = r.map(row => row.rules)
            let allRules = a1.flat()
            setRules(allRules)

            let a2 = r.map(row => row.desc)
            let a3 = a2.flat()
            let a4 = a3.join('<br />')
            setDesc(a4)

            runFectchAPII(allRules, 0, count)
        }
    }


    const handleOk = (name) => {
        updateStockName(name)
        setopen1(false);
    };

    const handleOpen1 = () => {
        setAnchorEl(null);
        if (sids.length > 1) {
            setErrorMsg("一次只能修改一個策略。")
            setErrorOpen(true)
        } else if (strage == null) {
            setErrorMsg("請至少選擇一個策略來修改。")
            setErrorOpen(true)
        } else setopen1(true);
    };

    const handleClose1 = () => {
        setopen1(false);
    };

    const handleClose5 = () => {
        setopen5(false);
    };

    const handleClose6 = () => {
        setopen6(false);
        getMylove()
    };

    function createTable() {
        if (error) {
            return "";
        } else if (!isLoaded) {
            return "";
        } else if (items === null) {
            return "";
        } else if (items.length === 0) {
            return <div style={{ color: '#b3b3b3', fontSize: 14 }}>此自選股清單中尚未有任何股票。</div>;
        } else {
            return <SimpleStockList detailUpdate={detailUpdate} total={total} dense={true} disablepage={true} pageupdate={pageupdate} currentpage={page} currentcount={count} items={items} bigtitle={bigtitle} />
        }
    }

    useEffect(() => {
        getMylove()
    }, []);


    const handleDelete = () => {
        setAnchorEl(null);

        if (sids.length > 1) {
            setErrorMsg("一次只能刪除一個策略。")
            setErrorOpen(true)
        } else if (strage == null) {
            setErrorMsg("請至少選擇一個策略來刪除。")
            setErrorOpen(true)
        } else {
            let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
            let lic = localStorage.getItem('lic')

            let param = lic === null ? {
                synno: synno,
                id: age
            } : {
                synno: synno,
                id: age,
                lic: lic
            };

            fetch("/jstock/v1/stock/removeloverule", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setAnchorEl(null);
                        if (result.status === 0) {
                            setAge(null)
                            setRules([])
                            setItems(null)
                            setStrage(null)
                            setFmenuList(result.data.allrules)
                            setNameUpdate(!nameupdate)
                        }
                    }
                );
        }

    }

    const getMylove = (id) => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
        } : {
            synno: synno,
            lic: localStorage.getItem('lic'),
        };


        fetch("/jstock/v1/stock/getloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setFmenuList(result.data.allrules)
                        setNameUpdate(!nameupdate)

                        if (id != 0 && id != null) {
                            const r = result.data.allrules.filter(
                                function (data) { return data.id == id }
                            )
                            updateRule(r)
                        }
                    }
                }
            );
    }

    const updateStockName = (name) => {
        setAnchorEl(null);

        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')

        let param = lic === null ? {
            synno: synno,
            id: age,
            name: name
        } : {
            synno: synno,
            lic: localStorage.getItem('lic'),
            id: age,
            name: name
        };

        fetch("/jstock/v1/stock/rulecname", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {

                    if (result.status === 0) {
                        setFmenuList(result.data.allrules)
                        setNameUpdate(!nameupdate)
                    }
                }
            );
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const shareLove = () => {
        setAnchorEl(null);

        if (sids.length > 1) {
            setErrorMsg("一次只能分享一個策略。")
            setErrorOpen(true)
        } else if (strage == null) {
            setErrorMsg("請至少選擇一個策略來分享。")
            setErrorOpen(true)
        } else {

            let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
            let lic = localStorage.getItem('lic')

            let param = lic === null ? {
                name: strage.name,
                tp: 1,
                syno: synno,
                rule1: strage,
                desc: strage.desc.map((data, index) => { return "<li key=" + index + ">" + data + "</li>" }).join('')
            } : {
                name: strage.name,
                tp: 1,
                lic: lic,
                syno: synno,
                rule1: strage,
                desc: strage.desc.map((data, index) => { return "<li key=" + index + ">" + data + "</li>" }).join('')
            };


            fetch("/jstock/v1/stock/sharelove", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {

                        if (result.status === 0) {
                            setopen2(true)
                        } else if (result.status === -1) {
                            setopen3(true)
                        } else if (result.status === -2) {
                            setopen4(true)
                        }
                    }
                );
        }
    }

    useEffect(() => {
        if (localStorage.getItem("refreshr") === 'true') {
            const interval = setInterval(() => {
                let h = moment().hour()
                let w = moment().day()
                let isRun = h >= 9 && h < 14 && w > 0 && w < 6 && localStorage.getItem("refreshr") === 'true'
                if (isRun && needRefresh.current) runFectchAPII(rules, page, count)
            }, 25000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [rules, page, count]);


    const runFectchAPII = (rules, pages, count) => {
        if (rules.length === 0) {
            setLoaded(true)
            setItems(null)
            setError(null)
            setRules([])
        } else {
            // setLoaded(false)
            let a = {
                rules: rules,
                page: pages,
                count: count,
                inp: {}
            };

            if (localStorage.getItem('lic') != null)
                a["lic"] = localStorage.getItem('lic')
            if (localStorage.getItem('token') != null)
                a["token"] = localStorage.getItem('token')

            a = putInpQueryParam(a)
            fectchAPI(a);
        }
    }

    const fectchAPI = (param) => {
        fetch("/jstock/v1/stock/rules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setLoaded(true)
                        setItems(result.company)
                        setTotal(result.total)
                        setBigtitle(result.bigtitle)
                        setError(null)
                    } else {
                        this.setState({ open: true });
                    }
                },
                error => {
                    setLoaded(true)
                    setError(error)
                }
            );
    }

    const pageupdate = (page, count) => {
        setPage(page)
        setCount(count)
        runFectchAPII(rules, page, count)
    }

    // function getAllRules(id) {
    //     return fmenulist.filter(
    //         function (data) { return data.id == id }
    //     );
    // }

    function getAllRules(ids) {
        return fmenulist.filter(
            function (data) { return ids.includes(data.id) }
        );
    }

    function getRuleNames(ids) {
        let a = fmenulist.filter(
            function (data) { return ids.includes(data.id) }
        )
        let b = a.map(row => row.name)
        return b.join(" , ")
    }

    const handleMoveStrange = () => {
        setAnchorEl(null);
        setopen6(true)
    }

    return (
        <div>
            <Snackbar open={open2} autoHideDuration={5000} onClose={(e) => setopen2(false)}>
                <Alert severity="success" onClose={(e) => setopen2(false)} >
                    感謝您的無私分享，我們會盡快地將它呈現在股民面前。
                </Alert>
            </Snackbar>
            <Snackbar open={open3} autoHideDuration={5000} onClose={(e) => setopen3(false)}>
                <Alert severity="warning" onClose={(e) => setopen3(false)} >
                    很抱歉，已經有人分享過類似的清單。
                </Alert>
            </Snackbar>
            <Snackbar open={open4} autoHideDuration={5000} onClose={(e) => setopen4(false)}>
                <Alert severity="warning" onClose={(e) => setopen4(false)} >
                    很抱歉，24小時內只能分享一次，請明天在繼續吧。
                </Alert>
            </Snackbar>

            {errorMsg != null && errorOpen && <Snackbar open={errorOpen} autoHideDuration={5000} onClose={(e) => setErrorOpen(false)}>
                <Alert severity="warning" onClose={(e) => setErrorOpen(false)} >
                    {errorMsg}
                </Alert>
            </Snackbar>}

            {open1 ? <StockName open={open1} handleClose={handleClose1} handleOk={handleOk}></StockName> : null}
            {open5 ? <OkDialog open={open5} handleClose={handleClose5} desc={desc}></OkDialog> : null}
            {openEdit ? <EditStrage srow={strage} rules={strage.rules} open={openEdit} handleClose={handleCloseEdit} handleOk={handleEditOk}></EditStrage> : null}
            {open6 && <MoveStrange open={open6} handleClose={handleClose6} />}


            {/* <IconBreadcrumbs tp={2}></IconBreadcrumbs> color: '#264653', */}
            <div style={{ display: 'flex', marginTop: 10 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <StyledSelect
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ width: '100%' }}
                        // value={age}
                        // onChange={openRuleDialog}
                        renderValue={(selected) => getRuleNames(selected)}
                        value={sids}
                        onChange={openRuleDialog1}
                        multiple
                    >
                        {/* <MenuItem id={0} value={0}>請選擇...</MenuItem> */}
                        {fmenulist.map((data) =>
                            <MenuItem key={data.id} id={data.id} value={data.id}>
                                <Checkbox checked={sids.indexOf(data.id) > -1} />
                                <ListItemText primary={data.name} />
                            </MenuItem>
                        )}
                    </StyledSelect>
                </FormControl>

                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                >
                    <MoreVertIcon />
                </IconButton>

                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuopen}
                    onClose={handleMenuClose}
                >
                    <MenuItem key={'k1'} onClick={(e) => { setAnchorEl(null); if (age != 0) setopen5(true); }}>規則明細</MenuItem>
                    <MenuItem key={'k5'} onClick={handleOpenEdit}>修改策略</MenuItem>
                    <MenuItem key={'k2'} onClick={handleOpen1}>更改名稱</MenuItem>
                    <MenuItem key={'k5'} onClick={handleMoveStrange}>調整順序</MenuItem>
                    <MenuItem key={'k3'} onClick={shareLove}>分享</MenuItem>
                    <MenuItem key={'k4'} onClick={handleDelete}>刪除</MenuItem>
                </Menu>
            </div>

            {createTable()}
        </div>
    );
}
