import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BIASList from '../details/BIASList'
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Chip from '@material-ui/core/Chip';

echarts.use(
    [ScatterChart, AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function NotificationF(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null)

    const getPHV1 = () => {
        setLoading(true)

        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
            sid: props.row.market_company.stock_no
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/nf", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setData(result)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);

    function getString() {
        return "目前無任何注意資訊"
    }

    return (
        <div>
            <Grid item xs={12} sm={12} >
                <div style={{ display: 'flex', marginTop: 10, marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        注意資訊
                    </Typography>
                </div>

                {data != null && data.r.length > 0 && <TableContainer style={{}} component={Paper}>
                    <Table size="small" style={{ overflowX: 'scroll', width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>時間</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>原因</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.r.map((row) => (
                                <TableRow
                                    key={row.d}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                        <Button size="small" variant="outlined">{row.d}</Button>
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} >{row.desc}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                {data != null && data.r.length === 0 && getString()}

                <div style={{ display: 'flex', marginTop: 40, marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        進入處置門檻
                    </Typography>
                </div>
                {data != null && <TableContainer style={{}} component={Paper}>
                    <Table className={classes.table}  size="small" style={{ overflowX: 'scroll', width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>達標?</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>門檻</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>現狀</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                key={'1'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell style={{ whiteSpace: 'nowrap' }} >{data.c10 ? <Button size="small" style={{ width: 120 }} disableElevation variant="contained" color="primary">達標</Button> : <Button size="small" style={{ width: 120 }} disableElevation variant="contained" disabled>未達標</Button>}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                    10個營業日有6次注意
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} >{data.c10d}次注意</TableCell>

                            </TableRow>
                            <TableRow
                                key={'2'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell style={{ whiteSpace: 'nowrap' }} >{data.c30 ? <Button size="small" style={{ width: 120 }} disableElevation variant="contained" color="primary">達標</Button> : <Button size="small" style={{ width: 120 }} disableElevation variant="contained" disabled>未達標</Button>}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                    30個營業日有12次注意
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} >{data.c30d}次注意</TableCell>

                            </TableRow>
                            <TableRow
                                key={'3'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell style={{ whiteSpace: 'nowrap' }} >{data.c5 ? <Button size="small" style={{ width: 120 }} disableElevation variant="contained" color="primary">達標</Button> : <Button size="small" style={{ width: 120 }} disableElevation variant="contained" disabled>未達標</Button>}</TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                    連續3或5個注意
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }} >連續{data.c5d}天注意</TableCell>

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>}

                <div style={{ display: 'flex', marginTop: 40, marginBottom: 5, paddingLeft: 0 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        處置資訊
                    </Typography>
                </div>
                {data != null && data.r1.length > 0 && <TableContainer style={{}} component={Paper}>
                    <Table className={classes.table} size="small" style={{ overflowX: 'scroll', width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>狀態</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>時間</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>條件</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>措施</TableCell>
                                <TableCell style={{ fontWeight:"bold", whiteSpace: 'nowrap' }}>內容</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.r1.map((row) => (
                                <TableRow
                                    key={row.d}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ whiteSpace: 'nowrap' }} >{row.isin ? <Button size="small" style={{ width: 120 }} disableElevation variant="contained" color="primary">處置中</Button> : <Button size="small" style={{ width: 120 }} disableElevation variant="contained" disabled>未處置</Button>}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                        <Button size="small" variant="outlined">{row.start}-{row.end}</Button>
                                    </TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} >{row.rule}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} >{row.desc}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }} >{row.detail}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                {data != null && data.r1.length === 0 && <di>目前無任何處置資訊</di>}
            </Grid>

        </div >
    );
}

