

let BColor1 = {
    upcolor: "#bd2658",
    downcolor: "#1b945f",
    faircolor: null,

    upcolorbox: "#bd2658",    
    downcolorbox: "#1b945f",  
    nodatacolor: null,
    whitecolor: "#FFFFFF",

    bluecolor: "#516694",
    blue1color: "#5674a3",

    blackcolor: "#000000",
    orangecolor: "#ff8b1f",
    graycolor: "#a8a8a8",

    blackground: "#000000",
    whiteground: "#FFFFFF",

    upcolorbox1: "#E76F51",   
    downcolorbox1: "#2A9D8F",
    nothing: "#cfcfcf",

    orange: "#fa8343",

    drawbg: getColor()
}


let BColor = {
    upcolor: "#ff5959",
    downcolor: "#39a632",
    faircolor: null,

    upcolorbox: "#ff5959",    
    downcolorbox: "#39a632",  
    nodatacolor: null,
    whitecolor: "#FFFFFF",

    bluecolor: "#516694",
    blue1color: "#5674a3",

    blackcolor: "#000000",
    orangecolor: "#ff8b1f",
    graycolor: "#a8a8a8",

    blackground: "#000000",
    whiteground: "#FFFFFF",

    upcolorbox1: "#ff5959",   
    downcolorbox1: "#39a632",
    nothing: null,

    orange: "#fa8343",
    column: "#e0e0e0",
    drawbg: getColor()
}

function getColor() {
    let k = localStorage.getItem('screen_color') === null ? 0 : localStorage.getItem('screen_color')
    if(k === 0) return "#000000"
    else if(k === 1) return "#FFFFFF"
    return "#FFFFFF"
}

export default BColor