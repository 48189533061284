import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import moment from 'moment'

import CD1 from './CD1';
import CD2 from './CD3';

import ConcentrationDetail from '../details/ConcentrationDetail'
import BuysellDetail from '../details/BuysellDetail';
import TLEDetail from '../details/BigCusListD71'
import BigCusDetail from '../details/BigCusListD7'
import MainCusList from '../details/BigCusListD1';
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
    },
    body: {
        fontSize: 16,
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function MainCusDetail(props) {
    const classes = useStyles();

    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [openstock, setOpenstock] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [tleopen, setTLEOpen] = React.useState(false);
    const [bigcusopen, setBigCusOpen] = React.useState(false);
    const [maincusopen, setMainCusOpen] = React.useState(false)
    const [name, setName] = React.useState(null)

    const handleClickOpenff = (stock_no, name) => event => {
        setOpenstock(stock_no)
        setName(name)
        setMainCusOpen(true);
    };

    const handleCloseff = () => {
        setOpenstock(null)
        setName(null)
        setMainCusOpen(false);
    };

    const handleClickOpenee = (stock_no, name) => event => {
        setOpenstock(stock_no)
        setName(name)
        setBigCusOpen(true);
    };

    const handleCloseee = () => {
        setOpenstock(null)
        setName(null)
        setBigCusOpen(false);
    };

    const handleClickOpendd = (stock_no, name) => event => {
        setOpenstock(stock_no)
        setName(name)
        setTLEOpen(true);
    };

    const handleClosedd = () => {
        setOpenstock(null)
        setName(null)
        setTLEOpen(false);
    };

    const handleClickOpencc = (stock_no, name) => event => {
        setOpenstock(stock_no)
        setName(name)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setConcentOpen(false);
    };

    const handleClickOpenbb = (stock_no, name) => event => {
        setOpenstock(stock_no)
        setName(name)
        setOpen(true);
    };

    const handleClosebb = () => {
        setOpenstock(null)
        setOpen(false);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleOpenCD1 = () => {
        setOpen1(true);
    }

    const handleOpenCD2 = () => {
        setOpen2(true);
    }

    const handleOk = () => {
        setOpen1(false);
        setOpen2(false);
        props.bigupdate()
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const renderInpriceStatusV = (status, value, stockno, name) => {
        if (status === -1) {
            return <Button size="medium" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 100, color: BColor.nodatacolor, fontSize: 14 }} className={classes.button1} >
                -
          </Button>
        } else if (status === 4) {
            return <Button size="medium" onClick={handleClickOpenff(stockno, name)} style={{ background: BColor.downcolorbox, minWidth: 100, color: '#FFFFFF', fontSize: 14 }} className={classes.button1}>
                大賣{value}張
          </Button>
        } else if (status === 3) {
            return <Button size="medium" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 100, color: BColor.downcolorbox, fontSize: 14 }} className={classes.button1}>
                小賣{value}張
        </Button>
        } else if (status === 0) {
            return <Button size="medium" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 100, fontSize: 14 }} className={classes.button1} >
                中立{value}張
          </Button>
        } else if (status === 1) {
            return <Button size="medium" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 100, color: BColor.upcolorbox, fontSize: 14 }} className={classes.button1} >
                小買{value}張
        </Button>
        } else if (status === 2) {
            return <Button size="medium" onClick={handleClickOpenff(stockno, name)} style={{ background: BColor.upcolorbox, minWidth: 100, color: '#FFFFFF', fontSize: 14 }} className={classes.button1} >
                大買{value}張
        </Button>
        } else {
            return <Button size="medium" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }

    const renderBuySell = (value, unit, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpenee(stockno, name)

        if (value === -99999) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else if (value > 0) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                買進 {value}{unit}
            </Button>
        } else if (value === 0) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100 }} className={classes.button1} >
                持平 {value}{unit}
            </Button>
        } else if (value < 0) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                賣出 {-value}{unit}
            </Button>
        } else {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const showCount1 = (value, unit) => {

        if (value === -99999) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else if (value > 0) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                買進 {value}{unit}
            </Button>
        } else if (value === 0) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                持平 {value}{unit}
            </Button>
        } else if (value < 0) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                賣出 {-value}{unit}
            </Button>
        } else {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const showCount2 = (value, unit) => {

        if (value === -99999) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else if (value > 0) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                增加 {value}{unit}
            </Button>
        } else if (value === 0) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                持平 {value}{unit}
            </Button>
        } else if (value < 0) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                減少 {-value}{unit}
            </Button>
        } else {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }


    const renderInpriceStatusV1 = (status, value, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpendd(stockno, name)

        if (status === -1) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        } else if (status === 4) {
            return <Button size="medium" onClick={v} style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                銳減{value}張
          </Button>
        } else if (status === 3) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>
                減少{value}張
        </Button>
        } else if (status === 0) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                中立{value}張
          </Button>
        } else if (status === 1) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >
                增加{value}張
        </Button>
        } else if (status === 2) {
            return <Button size="medium" onClick={v} style={{ minWidth: 100, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                激增{value}張
        </Button>
        } else {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }

    const renderInpriceStatusV2 = (status, value, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpendd(stockno, name)

        if (status === -1) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        } else if (status === 4) {
            return <Button size="medium" onClick={v} style={{ background: BColor.upcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1}>
                銳減{value}張
          </Button>
        } else if (status === 3) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                減少{value}張
        </Button>
        } else if (status === 0) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                中立{value}張
          </Button>
        } else if (status === 1) {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                增加{value}張
        </Button>
        } else if (status === 2) {
            return <Button size="medium" onClick={v} style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                激增{value}張
        </Button>
        } else {
            return <Button size="medium" onClick={v} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }

    const renderInpriceStatusV3 = (value, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpendd(stockno, name)

        if (value === -99999) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        } else if (value < 0) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1}>
                減少{-value}張
        </Button>
        } else if (value === 0) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100 }} className={classes.button1} >
                中立{value}張
          </Button>
        } else if (value > 1) {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1} >
                增加{value}張
        </Button>
        } else {
            return <Button size="medium" variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }



    const renderSellerV = (value, stockno, name) => {
        if (value === -99999) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        } else if (value <= -300) {
            return <Button size="medium" onClick={handleClickOpenbb(stockno, name)} style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                {value}間
          </Button>
        } else if (value < 0 && value > -300) {
            return <Button size="medium" onClick={handleClickOpenbb(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>
                {value}間
        </Button>
        } else if (value === 0) {
            return <Button size="medium" onClick={handleClickOpenbb(stockno, name)} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                {value}間
          </Button>
        } else if (value > 0 && value < 300) {
            return <Button size="medium" onClick={handleClickOpenbb(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >
                {value}間
        </Button>
        } else if (value >= 300) {
            return <Button size="medium" onClick={handleClickOpenbb(stockno, name)} style={{ background: BColor.upcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1} >
                {value}間
        </Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }

    const renderSellerV1 = (value, baseValue, stockno, name) => {
        if (value === -99999) {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        } else if (value <= -baseValue) {
            return <Button size="medium" onClick={handleClickOpencc(stockno, name)} style={{ background: BColor.downcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1}>
                高度{value}%
          </Button>
        } else if (value < 0 && value > -baseValue) {
            return <Button size="medium" onClick={handleClickOpencc(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>
                中度{value}%
        </Button>
        } else if (value === 0) {
            return <Button size="medium" onClick={handleClickOpencc(stockno, name)} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                持平{value}%
          </Button>
        } else if (value > 0 && value < baseValue) {
            return <Button size="medium" onClick={handleClickOpencc(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >
                中度{value}%
        </Button>
        } else if (value >= baseValue) {
            return <Button size="medium" onClick={handleClickOpencc(stockno, name)} style={{ background: BColor.upcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1} >
                高度{value}%
        </Button>
        } else {
            return <Button size="medium" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }



    const renderInpriceStatusBigCusV = (status, value, stockno, name) => {
        if (status === -1) {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor, fontSize: 14 }} className={classes.button1} >
                -
          </Button>
        } else if (status === 4) {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} style={{ background: BColor.downcolorbox, minWidth: 100, color: '#FFFFFF', fontSize: 14 }} className={classes.button1}>
                大賣{value}張
          </Button>
        } else if (status === 3) {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox, fontSize: 14 }} className={classes.button1}>
                小賣{value}張
        </Button>
        } else if (status === 0) {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 100, fontSize: 14 }} className={classes.button1} >
                中立{value}張
          </Button>
        } else if (status === 1) {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox, fontSize: 14 }} className={classes.button1} >
                小買{value}張
        </Button>
        } else if (status === 2) {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} style={{ background: BColor.upcolorbox, minWidth: 100, color: '#FFFFFF', fontSize: 14 }} className={classes.button1} >
                大買{value}張
        </Button>
        } else {
            return <Button size="medium" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
          </Button>
        }
    }

    const showBalance = (value, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpendd(stockno, name)

        if (value == -1) {
            return <Button variant="outlined" onClick={v} style={{ minWidth: 100 }} className={classes.button1}>
                -
          </Button>
        } else if (value >= 30) {
            return <Button variant="outlined" onClick={v} style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>
                {value}%
          </Button>
        } else {
            return <Button variant="outlined" onClick={v} style={{ minWidth: 100 }} className={classes.button1} >
                {value}%
        </Button>
        }
    }

    const showBalance2 = (value, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpenee(stockno, name)

        if (value === -99999) {
            return <Button size="small" onClick={v} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                0 張
        </Button>
        } else {
            return <Button size="small" onClick={v} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                {value}張
        </Button>
        }
    }


    const showBalance1 = (value, stockno, name, tp) => {
        let v = handleClickOpenff(stockno, name)
        if (tp === 1) v = handleClickOpendd(stockno, name)

        if (value === -99999) {
            return <Button size="small" onClick={v} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                0 張
        </Button>
        } else {
            return <Button size="small" onClick={v} variant="outlined" style={{ minWidth: 100 }} className={classes.button1} >
                {value}張
        </Button>
        }
    }

    const converPerValue = per => {
        if (per === -1) return "-"
        else return per + "%"
    }




    return (
        <div>
            <BuysellDetail open={open} handleClose={handleClosebb} stockId={openstock}></BuysellDetail>
            <ConcentrationDetail open={concentopen} handleClose={handleClosecc} stockId={openstock}></ConcentrationDetail>
            <TLEDetail open={tleopen} name={name} handleClose={handleClosedd} stockId={openstock}></TLEDetail>
            <BigCusDetail open={bigcusopen} handleClose={handleCloseee} name={name} stockId={openstock}></BigCusDetail>
            <MainCusList open={maincusopen} handleClose={handleCloseff} name={name} stockId={openstock}></MainCusList>

            <Grid container spacing={3} className={classes.grid}>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            籌碼日報
                </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={(e) => handleOpenCD1()}>變更日期</Button>
                    </div>

                    <CD1 open={open1} handleClose={handleClose1} handleOk={handleOk} bigtitle={props.bigtitle} bigupdate={props.bigupdate}></CD1>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption>報表區間：{moment(props.bigtitle.se).format('YYYY/MM/DD')} - {moment(props.bigtitle.ss).format('YYYY/MM/DD')}</caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'成交量'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'成交量'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance2(props.row.shortmoney.tq, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'主力'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'主力'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV(props.row.shortmoney.main_index, props.row.shortmoney.main_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'家數差'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'家數差'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderSellerV(props.row.shortmoney.buysell_dealer, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'籌碼集中'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'籌碼集中'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderSellerV1(props.row.shortmoney.concent, 25, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'外資'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'外資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV(props.row.shortmoney.tle_index, props.row.shortmoney.tle_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'投信'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'投信'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV(props.row.shortmoney.inv_index, props.row.shortmoney.inv_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'自營商'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'自營商'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV(props.row.shortmoney.self_index, props.row.shortmoney.self_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'中實戶'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'中實戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderBuySell(props.row.shortmoney.hotc, '張', props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'八大官股'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'八大官股'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderBuySell(props.row.shortmoney.eb, '張', props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'融資'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV1(props.row.shortmoney.fin_index, props.row.shortmoney.finance_value, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'融券'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融券'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV2(props.row.shortmoney.margin_index, props.row.shortmoney.margin_value, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券資比'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance(props.row.shortmoney.mf, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'借券賣'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'借券賣'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV3(props.row.shortmoney.brocount, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'當沖'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'當沖'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance1(props.row.shortmoney.dt, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'當沖率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'當沖率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance(props.row.shortmoney.dtr, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'v1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'周轉率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance(props.row.shortmoney.to, props.row.market_company.stock_no, props.row.market_company.short_name, 0)}</StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            籌碼週報
                </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={(e) => handleOpenCD2()}>變更日期</Button>
                    </div>

                    <CD2 open={open2} handleClose={handleClose2} handleOk={handleOk} bigtitle={props.bigtitle} bigupdate={props.bigupdate}></CD2>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption>報表區間：{moment(props.bigtitle.le).format('YYYY/MM/DD')} - {moment(props.bigtitle.ls).format('YYYY/MM/DD')}（系統會自動調整成週）</caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'成交量'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'成交量'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance2(props.row.money.tq, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'主力'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'主力'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.main_index, props.row.money.main_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'家數差'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'家數差'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderSellerV(props.row.money.buysell_dealer, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'籌碼集中'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'籌碼集中'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderSellerV1(props.row.money.concent, 25, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'大戶'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'大戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.big_cus_index, props.row.money.big_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'內部大戶'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'內部大戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.intp_index, props.row.money.intp_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'散戶'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'散戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.small_cus_index, props.row.money.small_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'外資'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'外資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.tle_index, props.row.money.tle_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'投信'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'投信'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.inv_index, props.row.money.inv_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'自營商'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'自營商'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusBigCusV(props.row.money.self_index, props.row.money.self_inc, props.row.market_company.stock_no, props.row.market_company.short_name)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'中實戶'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'中實戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderBuySell(props.row.money.hotc, '張', props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'八大官股'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'八大官股'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderBuySell(props.row.money.eb, '張', props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'融資'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV1(props.row.money.fin_index, props.row.money.finance_value, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'融券'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融券'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV2(props.row.money.margin_index, props.row.money.margin_value, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'31'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券資比'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance(props.row.money.mf, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'借券賣'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'借券賣'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{renderInpriceStatusV3(props.row.money.brocount, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'當沖'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'當沖'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance1(props.row.money.dt, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'當沖率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'當沖率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance(props.row.money.dtr, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'v2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'周轉率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showBalance(props.row.money.to, props.row.market_company.stock_no, props.row.market_company.short_name, 1)}</StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            佔股本比例
                </Typography>
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'n1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'外資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{converPerValue(props.row.money.tle_per)}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'n2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'大戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{converPerValue(props.row.money.big_cus_per)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'n3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'散戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{converPerValue(props.row.money.small_cus_per)}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'n4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{props.row.money.fin_per}%</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'n5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融券'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{props.row.money.mar_per}%</StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            持續買賣超天(週)數
                </Typography>
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'k1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'主力'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount1(props.row.shortmoney.main_con, '天')}</StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'k2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'大戶'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount1(props.row.shortmoney.big_con, '週')}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'k3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'外資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount1(props.row.shortmoney.tle_con, '天')}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'k4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'投信'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount1(props.row.shortmoney.inv_con, '天')}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'k5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'自營商'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount1(props.row.shortmoney.self_con, '天')}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'k6'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融資'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount2(props.row.money.fin_total, '天')}</StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'k7'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'融券'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{showCount2(props.row.money.mar_total, '天')}</StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    );
}