import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import Slide from '@material-ui/core/Slide';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart, LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import RevList from '../details/RevList'

echarts.use(
    [PieChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function WinMDetail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    const renderInPrice = (value) => {
        if (value === -99999) return "-"
        else return value + "%"
    }

    const pricecolor = incv => {
        if (incv > 0) return BColor.upcolor
        else if (incv < 0) return BColor.downcolor
        else return BColor.faircolor
    }

    const calWinLoss = (win, loss) => {
        let result = (win * 100) / (win + loss)
        if (loss === -1 || win === -1) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (result <= 20) {
            return <Button style={{ minWidth: 120, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>大敗 {win}勝{loss}敗</Button>
        } else if (result > 20 && result < 48) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.downcolorbox }} className={classes.button1}>小輸 {win}勝{loss}敗</Button>
        } else if (result >= 48 && result <= 52) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.faircolor }} className={classes.button1} >平手 {win}勝{loss}敗</Button>
        } else if (result > 52 && result < 80) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.upcolorbox }} className={classes.button1} >小贏 {win}勝{loss}敗</Button>
        } else if (result >= 80) {
            return <Button style={{ minWidth: 120, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >大贏 {win}勝{loss}敗</Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    const calPer = (sper, mper, ind) => {
        let result = (sper - mper).toFixed(2)
        if (sper === -99999 || mper === -99999) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (result < -ind) {
            return <Button style={{ background: BColor.downcolorbox, minWidth: 120, color: '#FFFFFF' }} className={classes.button1}>大敗 {result}%</Button>
        } else if (result >= -ind && result < 0) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.downcolorbox }} className={classes.button1}>小輸  {result}%</Button>
        } else if (result === 0) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.faircolor }} className={classes.button1} >平手  {result}%</Button>
        } else if (result > 0 && result < ind) {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.upcolorbox }} className={classes.button1} >小贏  {result}%</Button>
        } else if (result >= ind) {
            return <Button style={{ background: BColor.upcolorbox, minWidth: 120, color: '#FFFFFF' }} className={classes.button1} >大贏  {result}%</Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 120, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }


    function genData1() {
        let k = genoption1()

        k['xAxis'] = {
            data: props.row.tx.m.map(function (item) {
                return item.x;
            })
        }
        let series = []

        let y1 = {
            name: '大盤漲幅',
            type: 'line',
            data: props.row.tx.m.map(function (item) {
                return item.y;
            }),
            lineStyle: {
                width: 3
            }
        }

        let y2 = {
            name: '個股漲幅',
            type: 'line',
            data: props.row.tx.p.map(function (item) {
                return item.y;
            }),
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y3 = {
            name: '個股成交量',
            type: 'bar',
            data: props.row.tx.vol.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        series.push(y1)
        series.push(y2)
        series.push(y3)

        k['series'] = series
        return k
    }


    function genoption1() {
        let k = {
            legend: {
                data: ['大盤漲幅', '個股漲幅', '個股成交量'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }


    function genData2() {
        let k = genoption2()

        k['xAxis'] = {
            data: props.row.rs.rs.map(function (item) {
                return item.x;
            })
        }
        let series = []

        let y1 = {
            name: 'Mansfield RS',
            type: 'bar',
            data: props.row.rs.rs.map(function (item) {
                return item.y;
            }),
        }


        let y2 = {
            name: '個股大盤比(RS)',
            type: 'line',
            data: props.row.rs.bs.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        
        series.push(y1)
        series.push(y2)

        k['series'] = series
        return k
    }

    function genoption2() {
        let k = {
            legend: {
                data: ['Mansfield RS', '個股大盤比(RS)'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
            visualMap: {
                show: false,
                type: 'piecewise',
                seriesIndex: 0,
                // min: -500,
                // max: 500,
                pieces: [
                    { min: -500, max: 0, color: BColor.downcolorbox },
                    { min: 0, max: 500, color: BColor.upcolorbox },
                ]
            },
        }
        return k;
    }

    const aa = () => {
        return 49 - parseInt(props.row.tx.mw)- parseInt(props.row.tx.pw)
    }

    function genData3() {
        let k = genoption3()

        let series = []
        let y1 = {
            name: '49日大盤勝負',
            type: 'pie',
            data:[
                {value: props.row.tx.mw, name:'大盤勝'},
                {value: props.row.tx.pw, name:'個股勝'},
                {value: aa(), name:'平手'}
            ]
        }

        series.push(y1)

        k['series'] = series
        return k
    }

    function genoption3() {
        let k = {
            legend: {
                data: ['大盤勝', '個股勝'],
                bottom: 0
            },
            tooltip: {
                trigger: 'item'
            },
            // grid: {
            //     top: 15,
            //     left: 50,
            //     right: 50,
            //     borderWidth: 1,
            //     borderColor: "#878787",
            //     show: true
            // },
        }
        return k;
    }

    useEffect(() => {
        if (props.row.tx != null) setOption(genData1())
        if (props.row.rs != null) setOption1(genData2())
        if (props.row.tx != null) setOption2(genData3()) 
               
    }, [props.row]);


    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        大盤與個股走勢圖(日)
                        </Typography>
                    </div>
                </Grid>


                {option != null && props.row.chart6 != null ? <Grid item xs={12} sm={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無大盤與個股走勢圖(日)</div>}

                {props.row.chart6 != null ? <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'5日漲幅'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'5日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInPrice(props.row.winm.d5.sper)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'10日漲幅'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'10日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInPrice(props.row.winm.d10.sper)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'20日漲幅'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'20日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInPrice(props.row.winm.d20.sper)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'60日漲幅'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'60日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInPrice(props.row.winm.d60.sper)}
                                    </StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>:null}



                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        49日大盤個股勝負比率
                        </Typography>
                    </div>
                </Grid>

                {option2 != null && props.row.chart7 != null ? <Grid item xs={12} sm={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option2}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 400 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無49日大盤個股勝負比率</div>}

                {props.row.chart7 != null ? <Grid item xs={12} sm={4} >
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'5日'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'5日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calPer(props.row.winm.d5.sper, props.row.winm.d5.mper, 5)}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'5日勝負'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'5日勝負'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calWinLoss(props.row.winm.d5.w, props.row.winm.d5.l)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'10日'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'10日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calPer(props.row.winm.d10.sper, props.row.winm.d10.mper, 8)}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'10日勝負'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'10日勝負'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calWinLoss(props.row.winm.d10.w, props.row.winm.d10.l)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'20日'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'20日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calPer(props.row.winm.d20.sper, props.row.winm.d20.mper, 15)}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'20日勝負'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'20日勝負'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calWinLoss(props.row.winm.d20.w, props.row.winm.d20.l)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'60日'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'60日漲幅'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calPer(props.row.winm.d60.sper, props.row.winm.d60.mper, 25)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'60日勝負'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'60日勝負'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {calWinLoss(props.row.winm.d60.w, props.row.winm.d60.l)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'連勝大盤'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'連勝大盤'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button1} >{props.row.winm.wd}天</Button>

                                    </StyledTableCell>
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>: null}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        相對強度
                        </Typography>
                    </div>
                </Grid>

                {option1 != null && props.row.chart7 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無相對強度</div>}





            </Grid>

           
        </div>
    );
}

