import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  formControl: {},
  selector:{
    marginLeft: theme.spacing(1),      
    marginRight: theme.spacing(1),
    marginTop: -5,
    textAlign: "center",
    fontSize: 12
  }
});

class Selector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      age: this.props.selectedV,
      open: false
    };    
  }

  handleChange = event => {
    // this.setState({ [event.target.name]: event.target.value });
    this.setState({ 'age': event.target.value });
    this.props.mumChange(event.target.value, event.currentTarget.outerText)
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { classes } = this.props;
    const d1 = this.props.d1;

    return (
          <Select
            className={classes.selector}
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.age}
            onChange={this.handleChange}
            // inputProps={{
            //   name: "age",
            //   id: "demo-controlled-open-select"
            // }}
          >
           {d1.map((data) =>
            <MenuItem key={data.id} value={data.id} >{data.value}</MenuItem>
          )}
          </Select>
    );
  }
}

Selector.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Selector);