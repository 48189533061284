import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function BBSetting(props) {
  const yref = React.createRef();
  const hrref = React.createRef();

  const can1ref = React.createRef();
  const can2ref = React.createRef();
  const can3ref = React.createRef();

  const handleOk = () => {
    if (yref.current.value != null && yref.current.value != "")
      localStorage.setItem('bb_y', parseInt(yref.current.value));
    if (hrref.current.value != null && hrref.current.value != "")
      localStorage.setItem('bb_k', parseFloat(hrref.current.value));

    if (can1ref.current.value != null && can1ref.current.value != "")
      localStorage.setItem('can1', parseInt(can1ref.current.value));
    if (can2ref.current.value != null && can2ref.current.value != "")
      localStorage.setItem('can2', parseInt(can2ref.current.value));
    if (can3ref.current.value != null && can3ref.current.value != "")
      localStorage.setItem('can3', parseFloat(can3ref.current.value));

    props.handleOk()
  };


  function yy() {
    return localStorage.getItem('bb_y') === null ? 20 : localStorage.getItem('bb_y')
  }

  function hR() {
    return localStorage.getItem('bb_k') === null ? 2.0 : localStorage.getItem('bb_k')
  }

  function can1() {
    return localStorage.getItem('can1') === null ? 20 : localStorage.getItem('can1')
  }

  function can2() {
    return localStorage.getItem('can2') === null ? 10 : localStorage.getItem('can2')
  }

  function can3() {
    return localStorage.getItem('can3') === null ? 2.0 : localStorage.getItem('can3')
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">參數設定</DialogTitle>

      <DialogContent>
        <div style={{ fontSize: 12, color: '#b3b3b3' }}>
          布林通道應用上結合了移動平均和標準差的概念，其基本的型態是由三條軌道線組成的帶狀通道（中軌和上、下軌各一條）。中軌為股價的平均成本，上軌和下軌可分別視為股價的壓力線和支撐線。<br /><br />
        </div>

        <div style={{ fontSize: 12, color: '#82bd98' }} >
          <div>公式與參數</div>
          <div>中軌 = N時間段的簡單移動平均線</div>
          <div>上軌 = 中軌 + K × N時間段的標準差</div>
          <div>下軌 = 中軌 − K × N時間段的標準差</div>
        </div>

        <br />
        <TextField
          autoFocus
          margin="dense"
          id="y"
          label="布林時間長度（N預設：20。可輸入5,20,60,100）"
          type="text"
          fullWidth
          defaultValue={yy()}
          inputRef={yref}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="hr"
          label="標準差數量（K預設：2.0）"
          type="text"
          fullWidth
          defaultValue={hR()}
          inputRef={hrref}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          margin="dense"
          id="can1"
          label="肯特通道中線天數(預設：20)"
          type="text"
          fullWidth
          defaultValue={can1()}
          inputRef={can1ref}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="can2"
          label="肯特通道ATR天數（預設：10）"
          type="text"
          fullWidth
          defaultValue={can2()}
          inputRef={can2ref}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          margin="dense"
          id="can3"
          label="肯特通道ATR倍數（預設：2.0）"
          type="text"
          fullWidth
          defaultValue={can3()}
          inputRef={can3ref}
          InputLabelProps={{
            shrink: true,
          }}
        />


      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          取消
        </Button>
        <Button onClick={handleOk} color="primary">
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
