import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(date, money) {
    return { date, money };
}


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
  },
}));

export default function ETFIntList(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    useEffect(() => {
        if (props.open) { 
            fectchStockData(props.stockId) 
        } else {
            setBuyselldata([])
        }
    }, [props.stockId]);


    function fectchStockData(stockId) {
        if (stockId != null) {
            let a = {
                sid: stockId
            };

            fetch("/jstock/v1/stock/etfint", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let dd = result.map(row =>
                            createData(row.div_date, row.money)
                        )
                        setBuyselldata(dd)
                    },
                    error => {
                    }
                );
        }
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.stockName}({props.stockId})歷年配息明細</DialogTitle>

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="center" style={{whiteSpace: "nowrap", }}>配息</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.date}>
                                    <StyledTableCell component="th" scope="row" style={{whiteSpace: "nowrap",}}>
                                        {row.date}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{whiteSpace: "nowrap", paddingLeft: 5, paddingRight: 5, }}>{row.money}元</StyledTableCell>
                                </StyledTableRow>
))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
