import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';


echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function DMIChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    function genData1(data) {
        let k = genoption1()

        k['xAxis'] = {
            data: data.pp.map(function (item) {
                return item.x;
            })
        }
        let series = []

        let y1 = {
            name: '股價',
            type: 'line',
            data: data.pp.map(function (item) {
                return item.y;
            }),
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        let y2 = {
            name: '+DI',
            type: 'line',
            data: data.h.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y3 = {
            name: '-DI',
            type: 'line',
            data: data.m.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y4 = {
            name: 'ADX',
            type: 'line',
            data: data.l.map(function (item) {
                return item.y;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        series.push(y1)
        series.push(y2)
        series.push(y3)
        series.push(y4)

        k['series'] = series
        return k
    }


    function genoption1() {
        let k = {
            legend: {
                data: ['股價', '+DI', '-DI', 'ADX'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 10,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }


    useEffect(() => {
        if (props.row.chart10 != null && props.row.chart10.d != null) setOption(genData1(props.row.chart10.d))
        if (props.row.chart10 != null && props.row.chart10.w != null) setOption1(genData1(props.row.chart10.w))
        if (props.row.chart10 != null && props.row.chart10.m != null) setOption2(genData1(props.row.chart10.m))
    }, [props.row]);



    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            DMI(日)
                        </Typography>
                    </div>
                </Grid>


                {option != null && props.row.chart10 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>

                    
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                        // onEvents={onEvents}
                    />
                </Grid> : <div style={{ marginLeft: 15 }} >無DMI(日)圖</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            DMI(週)
                        </Typography>
                    </div>
                </Grid>


                {option1 != null && props.row.chart10 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 15 }} >無DMI(週)圖</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            DMI(月)
                        </Typography>
                    </div>
                </Grid>


                {option2 != null && props.row.chart10 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option2}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 15 }} >無DMI(月)圖</div>}
            </Grid>


        </div>
    );
}

