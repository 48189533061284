import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import BrokerList from '../details/BrokerList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Broker from '../menu/Broker'
import Typography from '@material-ui/core/Typography';
import DataTable from 'react-data-table-component';
import WinerDetail from './WinerDetail';

const useStyles = makeStyles((theme) => ({
}));


export default function WinerDashboard1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [reports, setReports] = React.useState([]);

    const [dayp, setDayp] = React.useState(120);
    const [money, setMoney] = React.useState(100);
    const [rank, setRank] = React.useState(0);
    const [win, setWin] = React.useState(0);
    const [holdday, setHoldDay] = React.useState(0);
    const [roi, setROI] = React.useState(0);
    const [stock, setStock] = React.useState('');
    const [dealera, setDealera] = React.useState(null);

    const [detailOpen, setDetailOpen] = React.useState(false);

    const [opendealer, setOpenDealer] = React.useState(null);
    const [opendname, setOpenDname] = React.useState(null);
    const [pending, setPending] = React.useState(false);

    const handleDetailClickOpencc = (dealer_id, dealername) => {
        setOpenDealer(dealer_id)
        setOpenDname(dealername)
        setDetailOpen(true);
    };

    const handleDetailClosecc = () => {
        setOpenDealer(null)
        setOpenDname(null)
        setDetailOpen(false);
    };

    useEffect(() => {
        getAll()
    }, []);


    const columns = [
        {
            name: '券商',
            sortable: true,
            grow: 2,
            selector: row => row.name + "(" + row.dealer + ")",
        }, {
            name: '交易次數',
            sortable: true,
            selector: row => row.tc,
        }, {
            name: '勝率(%)',
            sortable: true,
            selector: row => row.win,
        }, {
            name: '報酬率(%)',
            sortable: true,
            selector: row => row.roi,
        }, {
            name: '持有天數',
            sortable: true,
            selector: row => row.period,
        }, {
            name: '當沖(次)',
            sortable: true,
            selector: row => row.p0,
        }, {
            name: '當沖率(%)',
            sortable: true,
            selector: row => ((row.p0 * 100) / row.tc).toFixed(1),
        }, {
            name: '隔日沖(次)',
            sortable: true,
            selector: row => row.p1,
        }, {
            name: '隔日率(%)',
            sortable: true,
            selector: row => ((row.p1 * 100) / row.tc).toFixed(1)
        }, {
            name: '股票清單',
            button: true,
            cell: row => <Button disableElevation variant="contained" onClick={e => handleDetailClickOpencc(row.dealer, row.name)} >明細</Button>,
        }
    ];


    const getAll = (dayp1, money1, rank1, win1, holdday1, roi1, holdbigday1, stock1, dealer1) => {
        if (localStorage.getItem('lic') != null) {
            setPending(true)
            let a = {
            }

            if (dayp1 == null) a['dayp'] = dayp; else a['dayp'] = dayp1;
            if (money1 == null) a['money'] = money; else a['money'] = money1;
            if (rank1 == null) a['rank'] = rank; else a['rank'] = rank1;
            if (win1 == null) a['win'] = win; else a['win'] = win1;
            if (holdday1 == null) a['holdday'] = holdday; else a['holdday'] = holdday1;
            if (roi1 == null) a['roi'] = roi; else a['roi'] = roi1;

            if (stock1 != null && stock1 != '') a['stock'] = stock1
            if (dealer1 != null && dealer1 != '') a['dealer'] = dealer1.id

            a["lic"] = localStorage.getItem('lic')

            fetch("/jstock/v1/winner/all", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        // if (result.length > 0)
                        setPending(false)
                        setReports(result)
                    }
                ).catch(err => { /* maybe 400 */ });
        }
    }


    // dayp1, money1, rank1, win1, holdday1, roi1
    const handleChange1 = (event) => {
        setDayp(event.target.value);
        getAll(event.target.value, null, null, null, null, null, null, stock, dealera)
    };

    const handleChange2 = (event) => {
        setMoney(event.target.value);
        getAll(null, event.target.value, null, null, null, null, null, stock, dealera)
    };

    const handleChange3 = (event) => {
        setRank(event.target.value);
        getAll(null, null, event.target.value, null, null, null, null, stock, dealera)
    };

    const handleChange4 = (event) => {
        setWin(event.target.value);
        getAll(null, null, null, event.target.value, null, null, null, stock, dealera)
    };

    const handleChange5 = (event) => {
        setROI(event.target.value);
        getAll(null, null, null, null, null, event.target.value, null, stock, dealera)
    };

    const handleChange6 = (event) => {
        setHoldDay(event.target.value);
        getAll(null, null, null, null, event.target.value, null, null, stock, dealera)
    };

    const handleStockChange = (event) => {
        setStock(event.target.value);
        if ((event.target.value != null && event.target.value.length >= 4) || event.target.value == '') {
            getAll(null, null, null, null, null, null, null, event.target.value, dealera)
        }
    };

    const handleDealerChange1 = (event, v) => {
        setDealera(v)
        if (v != null) {
            getAll(null, null, null, null, null, null, null, stock, v)
        } else {
            getAll(null, null, null, null, null, null, null, stock, null)
        }
    };


    const customStyles = {
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };

    return (
        <div style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000", }}>
            {localStorage.getItem('noad') === "1" ? <div>
                {detailOpen ? <WinerDetail  day={dayp} bs={money}  open={detailOpen} handleClose={handleDetailClosecc} stockId={stock} dealerId={opendealer} stockname={''} dealername={opendname}></WinerDetail> : null}

                <Typography variant="caption" display="block" gutterBottom style={{ padding: 5 }}>
                    券商選股最主要的目的是讓您發現哪些券商勝率高，哪些券商專做當沖或隔日沖，找到券商正在布局的股票。
                    此處的資料不包含市值前兩百大的公司。如果輸入券商找不到資料，
                    可能是此券商都是交易前兩百大的股票或者是在時間區間內單筆交易金額沒有到達條件門檻。
                </Typography>

                <Grid container spacing={1} style={{ marginTop: 10 }}>
                    <Grid item xs={6} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl} required size="small">
                            <InputLabel id="demo-simple-select-outlined-labelv">交易區間</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelv"
                                id="demo-simple-select-outlinedv"
                                value={dayp}
                                onChange={handleChange1}
                                label="交易區間"
                            >
                                <MenuItem value={20}>近20日</MenuItem>
                                <MenuItem value={40}>近40日</MenuItem>
                                <MenuItem value={60}>近60日</MenuItem>
                                <MenuItem value={120}>近120日</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl} required size="small">
                            <InputLabel id="demo-simple-select-outlined-labelg">交易金額</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelg"
                                id="demo-simple-select-outlinedg"
                                value={money}
                                onChange={handleChange2}
                                label="交易金額"
                            >
                                <MenuItem value={100}>100萬元以上</MenuItem>
                                <MenuItem value={200}>200萬元以上</MenuItem>
                                <MenuItem value={300}>300萬元以上</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl} required size="small">
                            <InputLabel id="demo-simple-select-outlined-labely">類型</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labely"
                                id="demo-simple-select-outlinedy"
                                value={rank}
                                onChange={handleChange3}
                                label="排名"
                            >
                                <MenuItem value={0}>勝率高</MenuItem>
                                <MenuItem value={1}>報酬率高</MenuItem>
                                <MenuItem value={2}>持有天數高</MenuItem>
                                <MenuItem value={3}>持有天數低</MenuItem>
                                <MenuItem value={4}>當沖次數高</MenuItem>
                                <MenuItem value={5}>隔日沖次數高</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl} required size="small">
                            <InputLabel id="demo-simple-select-outlined-labelz">勝率</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelz"
                                id="demo-simple-select-outlinedz"
                                value={win}
                                onChange={handleChange4}
                                label="勝率"
                            >
                                <MenuItem value={0}>0%以上</MenuItem>
                                <MenuItem value={10}>10%以上</MenuItem>
                                <MenuItem value={20}>20%以上</MenuItem>
                                <MenuItem value={30}>30%以上</MenuItem>
                                <MenuItem value={40}>40%以上</MenuItem>
                                <MenuItem value={50}>50%以上</MenuItem>
                                <MenuItem value={60}>60%以上</MenuItem>
                                <MenuItem value={70}>70%以上</MenuItem>
                                <MenuItem value={80}>80%以上</MenuItem>
                                <MenuItem value={90}>90%以上</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl} required size="small">
                            <InputLabel id="demo-simple-select-outlined-labelr">平均報酬率</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelr"
                                id="demo-simple-select-outlinedr"
                                value={roi}
                                onChange={handleChange5}
                                label="平均報酬率"
                            >
                                <MenuItem value={0}>0%以上</MenuItem>
                                <MenuItem value={1}>1%以上</MenuItem>
                                <MenuItem value={2}>2%以上</MenuItem>
                                <MenuItem value={3}>3%以上</MenuItem>
                                <MenuItem value={4}>4%以上</MenuItem>
                                <MenuItem value={5}>5%以上</MenuItem>
                                <MenuItem value={6}>6%以上</MenuItem>
                                <MenuItem value={7}>7%以上</MenuItem>
                                <MenuItem value={8}>8%以上</MenuItem>
                                <MenuItem value={9}>9%以上</MenuItem>
                                <MenuItem value={10}>10%以上</MenuItem>
                                <MenuItem value={13}>13%以上</MenuItem>
                                <MenuItem value={15}>15%以上</MenuItem>
                                <MenuItem value={20}>20%以上</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl} required size="small">
                            <InputLabel id="demo-simple-select-outlined-labelw">平均持有天數</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelw"
                                id="demo-simple-select-outlinedw"
                                value={holdday}
                                onChange={handleChange6}
                                label="平均持有天數"
                            >
                                <MenuItem value={0}>0日以上</MenuItem>
                                <MenuItem value={1}>1日以上</MenuItem>
                                <MenuItem value={2}>2日以上</MenuItem>
                                <MenuItem value={3}>3日以上</MenuItem>
                                <MenuItem value={4}>4日以上</MenuItem>
                                <MenuItem value={5}>5日以上</MenuItem>
                                <MenuItem value={6}>6日以上</MenuItem>
                                <MenuItem value={7}>7日以上</MenuItem>
                                <MenuItem value={8}>8日以上</MenuItem>
                                <MenuItem value={9}>9日以上</MenuItem>
                                <MenuItem value={10}>10日以上</MenuItem>
                                <MenuItem value={15}>15日以上</MenuItem>
                                <MenuItem value={20}>20日以上</MenuItem>
                                <MenuItem value={25}>25日以上</MenuItem>
                                <MenuItem value={30}>30日以上</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2}>
                        <TextField
                            fullWidth={true}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            id="outlined-requireda"
                            label="股票代號"
                            variant="outlined"
                            value={stock}
                            onChange={handleStockChange}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={Broker}
                            getOptionLabel={(option) => option.value}
                            value={dealera}
                            onChange={handleDealerChange1}
                            renderInput={(params) =>
                                <TextField {...params}
                                    fullWidth={true}
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    id="outlined-requiredb"
                                    label="券商代號"
                                    variant="outlined"
                                />}
                        />


                    </Grid>
                </Grid>


                {reports != null ? <DataTable
                    customStyles={customStyles}
                    style={{ marginTop: 10 }}
                    columns={columns}
                    data={reports}
                    pagination
                    theme={theme.palette.type}
                    noHeader={true}
                    noDataComponent={"無任何結果。"}
                    paginationPerPage={10}
                    progressPending={pending}
                    progressComponent={<div>載入中...</div>}                    
                /> : null}
            </div> : <div>目前僅開放給訂閱者。</div>}
        </div>
    );
}
