import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function StockRunning(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(getDefaultAChartOption())
    const [loading, setLoading] = React.useState(false);

    function getXX(result) {
        if (result != null && result.p != null &&
            props.row.kd3.d.length >= result.p.length) {
            let data = props.row.kd3.d.slice(-result.p.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1(result) {
        if (result != null && result.p != null &&
            props.row.kd3.d.length >= result.p.length) {

            let data = props.row.kd3.d.slice(-result.p.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }


    function getDefaultAChartOption() {
        let newOption = {
            legend: {
                show: true,
                bottom: 0,
            },
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '40',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '360',
                    left: '40',
                    right: '40',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        return newOption
    }

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
        } : {
            lic: lic,
            stock: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/hpv", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.p != null) {
                        let newOption = getDefaultAChartOption()
                        newOption['yAxis'] = [
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                            },
                            {
                                show: true,
                                gridIndex: 1,
                                scale: true,
                                type: 'value',
                                max: 100,
                                min: 0
                            }
                        ]

                        let x1 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: getXX(result),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 20,
                            min: 'dataMin',
                            max: 'dataMax',
                        }

                        let x2 = {
                            gridIndex: 1,
                            scale: true,
                            type: 'category',
                            data: result.p.map(v => { return v.x }),
                        }

                        if (props.ismobile) {
                            let xpoint = {
                                type: 'shadow',
                                label: { show: false },
                                triggerTooltip: true,
                                handle: {
                                    show: true,
                                    margin: 25,
                                    color: '#1868d9',
                                    size: 30
                                }
                            }

                            x1['axisPointer'] = xpoint
                            x2['axisPointer'] = xpoint
                        }


                        newOption['xAxis'] = [x1, x2]
                        newOption['series'] = [
                            {
                                name: '日K',
                                type: 'candlestick',
                                data: getYY1(result),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                            },
                            {
                                name: '10日',
                                type: 'line',
                                data: result.p.map(v => { return v.y }),
                                symbol: 'none',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                smooth: true,
                                // areaStyle: {
                                //     // opacity: 0.8,
                                //     // color: "#63dbff"
                                // },
                                emphasis: {
                                    focus: 'series'
                                },
                                markArea: {
                                    data: [[{
                                        name: '衝刺',
                                        yAxis: '80',
                                        label: {
                                            position: 'right'
                                        },
                                        itemStyle: {
                                            color: '#d41c1c'
                                        },
                                    }, {
                                        yAxis: '100'
                                    }],
                                    [{
                                        name: '慢跑',
                                        yAxis: '20',
                                        label: {
                                            position: 'right'
                                        },
                                        itemStyle: {
                                            // color: '#f2f2f2'
                                        },
                                    }, {
                                        yAxis: '80'
                                    }],
                                    [{
                                        name: '休息',
                                        yAxis: '0',
                                        label: {
                                            position: 'right'
                                        },
                                        itemStyle: {
                                            color: '#65b206'
                                        },
                                    }, {
                                        yAxis: '20'
                                    }]

                                    ]
                                }
                            }
                        ]

                        if (props.ismobile) {
                            newOption['tooltip'] = {
                                triggerOn: 'none',
                                transitionDuration: 0,
                                confine: true,
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                position: function (pos, params, el, elRect, size) {
                                    var obj = { top: 10 };
                                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                                    return obj;
                                },
                                extraCssText: 'width: 170px',
                                bordeRadius: 4,
                                borderWidth: 1,
                                borderColor: '#333',
                            }

                            newOption['axisPointer'] = {
                                link: { xAxisIndex: 'all' },
                                label: {
                                    backgroundColor: '#777'
                                }
                            }

                        } else {
                            newOption['axisPointer'] = {
                                link: { xAxisIndex: 'all' },
                                label: {
                                    backgroundColor: '#777'
                                }
                            }

                            newOption['tooltip'] = {
                                // triggerOn: 'none',
                                transitionDuration: 0,
                                confine: true,
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross'
                                },
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                position: function (pos, params, el, elRect, size) {
                                    var obj = { top: 10 };
                                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                                    return obj;
                                },
                                extraCssText: 'width: 170px',
                                bordeRadius: 4,
                                borderWidth: 1,
                                borderColor: '#333',
                            }

                        }


                        setLoading(false)
                        setOptions(newOption)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);

    function getString() {
        return "目前無相關的衝刺指標"
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            衝刺指標
                        </Typography>
                    </div>

                    <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 750, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    />
                </Grid>
            </Grid>
        </div >
    );
}