import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        marginLeft: 5,
        marginRight: 5,
        paddingLeft: 5,
        paddingRight: 5
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(ymd, roa, roe, cprev, intbase, cr, qr, cl, dea, r1, r2, r3, cni) {
    return {ymd, roa, roe, cprev, intbase, cr, qr, cl, dea, r1, r2, r3, cni};
}


const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 250,
      backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    button1: {
      minWidth: 110,
    }
  }));

export default function FinRateList3(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    useEffect(() => {
        if(props.open) fectchStockData(props.stockId)
    }, [props.stockId]);


    function fectchStockData(stockId) {
        if(stockId != null) {
            let a = {
                sid: stockId
            };

            fetch("/jstock/v1/stock/finrate1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if(result != undefined) {
                            let dd = result.map(row =>
                                createData(row.ymd, 
                                row.roa, row.roe, row.cprev, row.intbase, 
                                row.cr, row.qr, row.cl, row.dea, 
                                row.r1, row.r2, row.r3, row.cni)
                            )
                            setBuyselldata(dd)
                        }
                    },
                    error => {
                    }
                );
        }
    }


    function renderValue3(value, unit) {
        let colorx = BColor.faircolor
        let word = value + unit
        
        if(value < 0 && value != -99999)
        colorx= BColor.downcolor

        if(value === -99999) word = " - "

        return <Button size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    return (
        <Dialog 
        fullWidth={true} 
        maxWidth={'xl'}
         open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.stockName}({props.stockId})財務表</DialogTitle>

            <DialogContent>
                {/* <ReorderList items={items}></ReorderList> */}

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="center">資產報酬率</StyledTableCell>
                                <StyledTableCell align="center">股東權益報酬率</StyledTableCell>
                                <StyledTableCell align="center">營運資金銷售率</StyledTableCell>
                                <StyledTableCell align="center">總資產迴轉率</StyledTableCell>
                                <StyledTableCell align="center">應收帳款天數</StyledTableCell>
                                <StyledTableCell align="center">庫存天數</StyledTableCell>

                                <StyledTableCell align="center">利息保障倍數</StyledTableCell>
                                <StyledTableCell align="center">流動比率</StyledTableCell>
                                <StyledTableCell align="center">速動比率</StyledTableCell>
                                <StyledTableCell align="center">負債佔資產比率</StyledTableCell>
                                <StyledTableCell align="center">合約負債/負債比</StyledTableCell>
                                <StyledTableCell align="center">合約負債/股本比</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.ymd}>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="left">
                                        {row.ymd}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.roa, '%')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.roe, '%')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.cprev, '%')}
                                    </StyledTableCell>

                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.r1, '次')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.r2, '天')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.r3, '天')}
                                    </StyledTableCell>
                                    
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.intbase, '倍')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.cr, '%')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.qr, '%')}
                                    </StyledTableCell>


                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.dea, '%')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.cl, '%')}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap', marginRight: 5, marginLeft: 5}} align="center">
                                        {renderValue3(row.cni, '%')}
                                    </StyledTableCell>


                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
