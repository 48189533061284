import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PBRSetting(props) {
    const yref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('ccy1', parseInt(yref.current.value));

        props.handleOk()
    };

    function yy() {
        return localStorage.getItem('ccy1') === null ? "3" : localStorage.getItem('ccy1')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">淨值法</DialogTitle>

            <DialogContent>
                <div style={{ fontSize: 12, color: '#b3b3b3' }}>
                    藉由自身歷史淨值比算出價格。將近幾年所有的淨值比排序後，從前高位數、中位數、低位數得到過去給予的高中低淨值比，在與個別股票的最新的每股淨值相乘得到價格。<br /><br />
                </div>

                <div style={{ fontSize: 12, color: '#82bd98' }} >
                    <div>公式</div>
                    <div>1. 超低價 = 歷史淨值比低位數(5%) * 每股淨值</div>
                    <div>2. 偏低價 = 歷史淨值比低位數(25%) * 每股淨值</div>
                    <div>3. 合理價 = 歷史淨值比中位數(25%) * 每股淨值</div>
                    <div>4. 偏貴價 = 歷史淨值比高位數(75%) * 每股淨值</div>
                    <div>5. 超貴價 = 歷史淨值比高位數(95%) * 每股淨值</div>
                </div>

                <br />

                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="y"
                    label="使用近幾年本益比估算（y預設：3年，最大6年）"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />


            </DialogContent>
            {/* <DialogContentText  style={{paddingLeft: 20, paddingRight:20}}>


          </DialogContentText> */}
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions>
        </Dialog>
    );
}
