import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BColor from '../BColor'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import TablePagination from '@material-ui/core/TablePagination';
import ComputerOrMobile from './ComputerOrMobile'
import StockDetail from '../mobile/StockDetail';
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: '#264653',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
    table: {
        maxHeight: localStorage.getItem('noad') === "1" ? `calc(100vh - 170px)`: `calc(100vh - 170px)`,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
    },
    symbolt: {
        fontSize: 16,
        // color: "#000000"
    },
    symbols: {
        fontSize: 11,
        // color: "#b5b5b5"
    },
    uprice: {
        fontWeight: 'bold',
        fontSize: 20,
        color: BColor.upcolor
    },
    dprice: {
        fontWeight: 'bold',
        fontSize: 20,
        color: BColor.downcolor
    },
    eprice: {
        fontWeight: 'bold',
        fontSize: 20,
        color: BColor.faircolor
    },
    downinc: {
        fontSize: 12,
        color: BColor.downcolor
    },
    upinc: {
        fontSize: 12,
        color: BColor.upcolor
    },
    eqinc: {
        fontSize: 12,
        color: BColor.faircolor
    },
}));

export default function SimpleStockList(props) {
    let classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const rows = props.items

    function renderPrice(row) {
        if (row.fin.price.incv > 0) {
            return <div className={classes.uprice}>{row.fin.price.last_value}</div>
        } else if (row.fin.price.incv < 0) {
            return <div className={classes.dprice}>{row.fin.price.last_value}</div>
        } else {
            return <div className={classes.eprice}>{row.fin.price.last_value}</div>
        }
    }

    function renderInc(row) {
        let oriValue = row.fin.price.last_value - row.fin.price.incv

        if (row.fin.price.incv > 0) {
            return <div className={classes.upinc}>▲{row.fin.price.incv}</div>
        } else if (row.fin.price.incv < 0) {
            return <div className={classes.downinc}>▼{row.fin.price.incv}</div>
        } else {
            return <div className={classes.eqinc}>{row.fin.price.incv}</div>
        }
    }

    function renderInc1(row) {
        let oriValue = row.fin.price.last_value - row.fin.price.incv
        let percent = Math.abs(row.fin.price.incv * 100) / oriValue

        if (row.fin.price.incv > 0) {
            return <div className={classes.upinc}>{percent.toFixed(2)}%</div>
        } else if (row.fin.price.incv < 0) {
            return <div className={classes.downinc}>{percent.toFixed(2)}%</div>
        } else {
            return <div className={classes.eqinc}>{percent.toFixed(2)}%</div>
        }
    }

    const renderMainColor = (value1) => {
        if (value1 === -1) {
            return pricecolor(0)
        } else if (value1 === 2) {
            return pricecolor(1)
        } else if (value1 === 1) {
            return pricecolor(2)
        } else if (value1 === 0) {
            return pricecolor(3)
        } else if (value1 === 3) {
            return pricecolor(4)
        } else if (value1 === 4) {
            return pricecolor(5)
        } else {
            return pricecolor(0)
        }
    }

    const pricecolor = tp => {
        //ORIGIN
        if (tp === 1) return BColor.upcolorbox1
        else if (tp === 2) return BColor.upcolorbox1
        else if (tp === 3) return BColor.faircolor
        else if (tp === 4) return BColor.downcolorbox1
        else if (tp === 5) return BColor.downcolorbox1
        else if (tp === 0) return BColor.nothing// return "#f0a097"         
        else return BColor.faircolor
    }

    const renderRev = (value) => {
        if (value === -99999) return "-- 元"
        else return (value.toFixed(0).toString()) + '百萬元'
    }

    const renderInPrice = (value, unit) => {
        if (value == null || value == undefined) return "-- " + unit
        else if (value === -99999) return "-- " + unit
        else return (value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + unit
    }


    const renderPriceColor = (value2, value1) => {
        if (value2 === -1) {
            return pricecolor(0)
        } else if (value1 === value2) {
            return pricecolor(0)
        } else if (value1 > value2) {
            return pricecolor(1)
        } else if (value1 < value2) {
            return pricecolor(5)
        } else {
            return pricecolor(0)
        }
    }

    const renderRevColor = (value1) => {
        if (value1 === 0) {
            return pricecolor(0)
        } else if (value1 > 0) {
            return pricecolor(1)
        } else if (value1 < 0) {
            return pricecolor(5)
        } else {
            return pricecolor(0)
        }
    }

    const renderNColor1 = (value1) => {
        if (value1 < 0) {
            return pricecolor(5)
        } else {
            return pricecolor(3)
        }
    }

    const showEPS1 = (index, value, unit) => {
        if (value == null || value == undefined) return "-- " + unit
        else if (index === 0) {
            return (value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + unit
        } else if (index === 1) {
            return "虧損"
        } else if (index === 2) {
            return '轉盈'
        } else if (index === 3) {
            return '虧損'
        } else {
            return '-'
        }
    }


    const renderV = (index, value) => {
        if (value == null || value == undefined || index === -1) return "--"
        else return value + "%"
    }

    const renderColor = (index) => {
        if (index == null || index == undefined) return pricecolor(0)
        else if (index === 0) {
            return pricecolor(1)
        } else if (index === 1) {
            return pricecolor(5)
        } else if (index === 2) {
            return pricecolor(3)
        } else if (index === -1) {
            return pricecolor(3)
        }
    }

    const showEPSColor = (index, value) => {
        if (index === 0) {
            if (value > 0) {
                return pricecolor(1)
            } else if (value === 0) {
                return pricecolor(3)
            } else if (value < 0) {
                return pricecolor(5)
            } else {
                return pricecolor(3)
            }
        } else if (index === 1) {
            return pricecolor(5)
        } else if (index === 2) {
            return pricecolor(1)
        } else if (index === 3) {
            return pricecolor(5)
        } else {
            return pricecolor(3)
        }
    }

    const renderEmptyChip = (data) => {
        return <Chip
            variant="outlined"
            size="medium"
            avatar={<Avatar style={{ backgroundColor: BColor.nothing, color: BColor.whitecolor }}>{data}</Avatar>}
            label={'--'}
            style={{ color: BColor.nothing, margin: 1 }}
        />
    }

    const openDetail = (row) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(row.market_company.stock_no)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const renderStatus = (h, m, l, value1) => {
        if (h === -1 || m === -1 || l === -1) {
            return renderStatusIcon(0, '-')
        } else if (value1 >= h) {
            return renderStatusIcon(1, '昂貴')
        } else if (value1 < h && value1 > m) {
            return renderStatusIcon(2, '偏貴')
        } else if (value1 <= m && value1 > l) {
            return renderStatusIcon(3, '合理')
        } else if (value1 <= l) {
            return renderStatusIcon(5, '超低')
        } else {
            return renderStatusIcon(0, '-')
        }
    }

    const renderStatusIcon = (tp, value) => {
        return <Button variant="outlined" style={{ minWidth: 50, width: 50, height: 100, fontSize: 16, color: pricecolor1(tp) }}>
            {value}
        </Button>
    }

    //0-, 1大買, 2小買, 3中壢, 4小麥, 5大麥
    const renderEmpty1 = () => {
        return <Button variant="outlined" style={{ minWidth: 50, width: 50, height: 100, fontSize: 16, color: pricecolor1(0) }}>
            {'-'}
        </Button>
    }

    const pricecolor1 = tp => {
        //GOOD
        if (tp === 1) return BColor.upcolorbox1 //"#abc9a3"
        else if (tp === 2) return BColor.upcolorbox1// return "#f0a097" 
        else if (tp === 3) return BColor.faircolor// return "#f0a097" 
        else if (tp === 4) return BColor.downcolorbox1// return "#f0a097" 
        else if (tp === 5) return BColor.downcolorbox1// return "#f0a097" 
        else if (tp === 0) return BColor.nothing// return "#f0a097"         
        else return BColor.faircolor
    }

    const renderStatus1 = (value1) => {
        if (value1 === -1) {
            return renderStatusIcon(0, '-')
        } else if (value1 === 2) {
            return renderStatusIcon(1, '大買')
        } else if (value1 === 1) {
            return renderStatusIcon(2, '小買')
        } else if (value1 === 0) {
            return renderStatusIcon(3, '中立')
        } else if (value1 === 3) {
            return renderStatusIcon(4, '小賣')
        } else if (value1 === 4) {
            return renderStatusIcon(5, '大賣')
        } else {
            return renderStatusIcon(0, '-')
        }
    }

    const showRate = (value) => {
        let total = 60
        let k = (value * 100) / total

        if (value === -99999) {
            return renderStatusIcon(0, '-')
        } else if (k <= 10) {
            return renderStatusIcon(1, '特優')
        } else if (k > 10 && k <= 40) {
            return renderStatusIcon(2, '前段')
        } else if (k > 40 && k <= 60) {
            return renderStatusIcon(3, '中間')
        } else if (k >= 60 && k < 90) {
            return renderStatusIcon(4, '後段')
        } else if (k >= 90) {
            return renderStatusIcon(5, '倒數')
        } else {
            return renderStatusIcon(0, '-')
        }
    }

    const showEPS = (index, value) => {
        if (index === 0) {
            if (value > 0 && value < 100) {
                return renderStatusIcon(2, '成長')
            } else if (value === 0) {
                return renderStatusIcon(3, '持平')
            } else if (value >= 100) {
                return renderStatusIcon(1, '激增')
            } else if (value < 0 && value > -100) {
                return renderStatusIcon(4, '衰退')
            } else if (value < -100) {
                return renderStatusIcon(5, '銳減')
            }
        } else if (index === 1) {
            return renderStatusIcon(4, '虧損')
        } else if (index === 2) {
            return renderStatusIcon(4, '轉盈')
        } else if (index === 3) {
            return renderStatusIcon(4, '虧損')
        } else {
            return renderStatusIcon(0, '-')
        }
    }

    const getAllIds = () => {
        return props.items.map (row => {
          return row.market_company.stock_no
        })
      }
      
    const getPiece = (row) => {
        if(row != null && row.shortmoney != null) {
            return <Typography variant="caption">{row.shortmoney.tq}張</Typography>
        } return null
    }
    
    return (
        (rows != undefined && rows.length > 0) ? <div style={{ marginTop: 0 }}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} toolbar={false} myloveupdate={props.myloveupdate} ismobile={true}/>
            <TableContainer component={Paper} className={classes.table}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }}>股票</StyledTableCell>
                            <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>股價</StyledTableCell>
                            <StyledTableCell align="left" style={{ whiteSpace: 'nowrap' }}>股利評價</StyledTableCell>
                            <StyledTableCell align="left" style={{ whiteSpace: 'nowrap' }}>日籌碼</StyledTableCell>
                            <StyledTableCell align="left" style={{ whiteSpace: 'nowrap' }}>週籌碼</StyledTableCell>
                            <StyledTableCell align="left" style={{ whiteSpace: 'nowrap' }}>月營收</StyledTableCell>
                            <StyledTableCell align="left" style={{ whiteSpace: 'nowrap' }}>EPS</StyledTableCell>
                            <StyledTableCell align="left" style={{ whiteSpace: 'nowrap' }}>三率</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.market_company.stock_no} onClick={event => openDetail(row)}>
                                <StyledTableCell component="th" scope="row" style={{ }} align='left'>
                                    <div className={classes.symbolt} style={{ whiteSpace: 'nowrap' }}>{row.market_company.short_name}</div>
                                    <Typography variant="caption" style={{ whiteSpace: 'nowrap' }}>{row.market_company.stock_no}</Typography>
                                    <Typography variant="caption" style={{ whiteSpace: 'nowrap' }}>{row.market_company.inductry}</Typography>
                                    {/* <div className={classes.symbols} style={{ whiteSpace: 'nowrap' }}>{row.market_company.stock_no}</div> */}
                                    {/* <div className={classes.symbols}>{row.market_company.inductry}</div> */}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                                    {renderPrice(row)}
                                    {renderInc(row)}
                                    {renderInc1(row)}
                                    {getPiece(row)}
                                </StyledTableCell>
                                <StyledTableCell align="left">

                                    {row.inprice != null && row.inprice != undefined ? <div style={{ display: 'flex' }}>
                                        {renderStatus(row.inprice.i1h, row.inprice.i1m, row.inprice.i1l, row.inprice.f)}
                                        <div style={{ marginLeft: 2 }}>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderPriceColor(row.inprice.i1l, row.fin.price.last_value), color: BColor.whitecolor }}>便</Avatar>}
                                                    label={row.inprice.i1l != -1 ? row.inprice.i1l + '元' : " -- "}
                                                    style={{ color: renderPriceColor(row.inprice.i1l, row.fin.price.last_value), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderPriceColor(row.inprice.i1m, row.fin.price.last_value), color: BColor.whitecolor }}>合</Avatar>}
                                                    label={row.inprice.i1m != -1 ? row.inprice.i1m + '元' : " -- "}
                                                    style={{ color: renderPriceColor(row.inprice.i1m, row.fin.price.last_value), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderPriceColor(row.inprice.i1h, row.fin.price.last_value), color: BColor.whitecolor }}>昂</Avatar>}
                                                    label={row.inprice.i1h != -1 ? row.inprice.i1h + '元' : " -- "}
                                                    style={{ color: renderPriceColor(row.inprice.i1h, row.fin.price.last_value), margin: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </div> :
                                        <div>
                                            {renderEmpty1()}
                                            <div style={{ marginLeft: 2 }}>
                                                <div>{renderEmptyChip('便')}</div>
                                                <div>{renderEmptyChip('合')}</div>
                                                <div>{renderEmptyChip('昂')}</div>
                                            </div>
                                        </div>
                                    }
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.shortmoney != null && row.shortmoney != undefined ? <div style={{ display: 'flex' }}>
                                        {renderStatus1(row.shortmoney.main_index)}
                                        <div style={{ marginLeft: 2 }}>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderMainColor(row.shortmoney.main_index), color: BColor.whitecolor }}>主</Avatar>}
                                                    label={row.shortmoney.main_inc + '張'}
                                                    style={{ color: renderMainColor(row.shortmoney.main_index), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderMainColor(row.shortmoney.tle_index), color: BColor.whitecolor }}>外</Avatar>}
                                                    label={row.shortmoney.tle_inc + '張'}
                                                    style={{ color: renderMainColor(row.shortmoney.tle_index), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderMainColor(row.shortmoney.inv_index), color: BColor.whitecolor }}>投</Avatar>}
                                                    label={row.shortmoney.inv_inc + '張'}
                                                    style={{ color: renderMainColor(row.shortmoney.inv_index), margin: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </div> :
                                        <div>
                                            {renderEmpty1()}
                                            <div style={{ marginLeft: 2 }}>
                                                <div>{renderEmptyChip('主')}</div>
                                                <div>{renderEmptyChip('外')}</div>
                                                <div>{renderEmptyChip('投')}</div>
                                            </div>
                                        </div>}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.money != null && row.money != undefined ? <div style={{ display: 'flex' }}>
                                        {renderStatus1(row.money.main_index)}
                                        <div style={{ marginLeft: 2 }}>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderMainColor(row.money.main_index), color: BColor.whitecolor }}>主</Avatar>}
                                                    label={row.money.main_inc + '張'}
                                                    style={{ color: renderMainColor(row.money.main_index), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderMainColor(row.money.big_cus_index), color: BColor.whitecolor }}>大</Avatar>}
                                                    label={row.money.big_inc + '張'}
                                                    style={{ color: renderMainColor(row.money.big_cus_index), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderMainColor(row.money.intp_index), color: BColor.whitecolor }}>內</Avatar>}
                                                    label={row.money.intp_inc + '張'}
                                                    style={{ color: renderMainColor(row.money.intp_index), margin: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </div> :
                                        <div>
                                            {renderEmpty1()}
                                            <div style={{ marginLeft: 2 }}>
                                                <div>{renderEmptyChip('主')}</div>
                                                <div>{renderEmptyChip('大')}</div>
                                                <div>{renderEmptyChip('內')}</div>
                                            </div>
                                        </div>}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.rev != null && row.rev != undefined ? <div style={{ display: 'flex' }}>
                                        {showRate(row.rev.m1r)}
                                        <div style={{ marginLeft: 2 }}>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: BColor.upcolorbox1, color: BColor.whitecolor }}>營</Avatar>}
                                                    label={renderRev(row.rev.rev)}
                                                    style={{ color: BColor.upcolorbox1, margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderRevColor(row.rev.lastmp), color: BColor.whitecolor }}>M</Avatar>}
                                                    label={renderInPrice(row.rev.lastmp, '%')}
                                                    style={{ color: renderRevColor(row.rev.lastmp), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderRevColor(row.rev.lastyp), color: BColor.whitecolor }}>Y</Avatar>}
                                                    label={renderInPrice(row.rev.lastyp, '%')}
                                                    style={{ color: renderRevColor(row.rev.lastyp), margin: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </div> :
                                        <div>
                                            {renderEmpty1()}
                                            <div style={{ marginLeft: 2 }}>
                                                <div>{renderEmptyChip('營')}</div>
                                                <div>{renderEmptyChip('M')}</div>
                                                <div>{renderEmptyChip('Y')}</div>
                                            </div>
                                        </div>}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.eps1 != null && row.eps1 != undefined ? <div style={{ display: 'flex' }}>
                                        {showEPS(row.eps1.nsgri, row.eps1.nsgr)}
                                        <div style={{ marginLeft: 2 }}>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: renderNColor1(row.eps1.n4seps), color: BColor.whitecolor }}>四</Avatar>}
                                                    label={renderInPrice(row.eps1.n4seps, '元')}
                                                    style={{ color: renderNColor1(row.eps1.n4seps), margin: 1 }}
                                                />
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: showEPSColor(row.eps1.nsgri, row.eps1.nsgr), color: BColor.whitecolor }}>Q</Avatar>}
                                                    label={showEPS1(row.eps1.nsgri, row.eps1.nsgr, '%')}
                                                    style={{ color: showEPSColor(row.eps1.nsgri, row.eps1.nsgr), margin: 1 }}
                                                /> 
                                            </div>
                                            <div>
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    avatar={<Avatar style={{ backgroundColor: showEPSColor(row.eps1.nsyoyi, row.eps1.nsyoy), color: BColor.whitecolor }}>Y</Avatar>}
                                                    label={showEPS1(row.eps1.nsyoyi, row.eps1.nsyoy, '%')}
                                                    style={{ color: showEPSColor(row.eps1.nsyoyi, row.eps1.nsyoy), margin: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </div> :
                                        <div>
                                            {renderEmpty1()}
                                            <div style={{ marginLeft: 2 }}>
                                                <div>{renderEmptyChip('四')}</div>
                                                <div>{renderEmptyChip('Q')}</div>
                                                <div>{renderEmptyChip('Y')}</div>
                                            </div>
                                        </div>}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <div>
                                        {row.pll != null && row.pll != undefined ? <Chip
                                            variant="outlined"
                                            size="medium"
                                            avatar={<Avatar style={{ backgroundColor: renderColor(row.pll.pli), color: BColor.whitecolor }}>毛</Avatar>}
                                            label={renderV(row.pll.pli, row.pll.pliv)}
                                            style={{ color: renderColor(row.pll.pli), margin: 1 }}
                                        /> : renderEmptyChip('毛')}
                                    </div>
                                    <div>
                                        {row.pll != null && row.pll != undefined ? <Chip
                                            variant="outlined"
                                            size="medium"
                                            avatar={<Avatar style={{ backgroundColor: renderColor(row.pll.noi), color: BColor.whitecolor }}>營</Avatar>}
                                            label={renderV(row.pll.noi, row.pll.noiv)}
                                            style={{ color: renderColor(row.pll.noi), margin: 1 }}
                                        /> : renderEmptyChip('營')}
                                    </div>
                                    <div>
                                        {row.pll != null && row.pll != undefined ? <Chip
                                            variant="outlined"
                                            size="medium"
                                            avatar={<Avatar style={{ backgroundColor: renderColor(row.pll.iti), color: BColor.whitecolor }}>淨</Avatar>}
                                            label={renderV(row.pll.iti, row.pll.itiv)}
                                            style={{ color: renderColor(row.pll.iti), margin: 1 }}
                                        /> : renderEmptyChip('淨')}
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                props.disablepage ? <TablePagination
                component="div"
                style={{ padding: 0, display: "flex", alignContent: "flex-start" }}
                rowsPerPageOptions={[10]}
                count={props.total}
                backIconButtonText={"上一頁"}
                nextIconButtonText={"下一頁"}
                rowsPerPage={props.currentcount}
                page={props.currentpage}
                labelRowsPerPage={'每頁'}
                labelDisplayedRows={({ from, to, count }) => `顯示 ${from}-${to}筆，共 ${count}筆`}
                onChangePage={handleChangePage}
            /> : <TablePagination
                    component="div"
                    style={{ padding: 0, display: "flex", alignContent: "flex-start" }}
                    rowsPerPageOptions={[20]}
                    count={props.total}
                    backIconButtonText={"上一頁"}
                    nextIconButtonText={"下一頁"}
                    rowsPerPage={props.currentcount}
                    page={props.currentpage}
                    labelRowsPerPage={'每頁'}
                    labelDisplayedRows={({ from, to, count }) => `顯示 ${from}-${to}筆，共 ${count}筆`}
                    onChangePage={handleChangePage}
                />
            }
            <ComputerOrMobile></ComputerOrMobile>

        </div > : null
    );
}
