import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function InterestSetting(props) {
  //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const hrref = React.createRef();
    const mrref = React.createRef();
    const lrref = React.createRef();

    const handleOk = () => {
      // console.log(yref.current.value)

      if(yref.current.value != null && yref.current.value != "")
      localStorage.setItem('free_y1', parseInt(yref.current.value));

      if(hrref.current.value != null && hrref.current.value != "")
      localStorage.setItem('free_hr1', parseFloat(hrref.current.value));

      if(mrref.current.value != null && mrref.current.value != "")
      localStorage.setItem('free_mr1', parseFloat(mrref.current.value));

      if(lrref.current.value != null && lrref.current.value != "")
      localStorage.setItem('free_lr1', parseFloat(lrref.current.value));

      let y = localStorage.getItem('free_y') === null ?  "5" : localStorage.getItem('free_y')
      let g = localStorage.getItem('free_g') === null ?  0 : localStorage.getItem('free_g')
      let r = localStorage.getItem('free_r') === null ?  0 : localStorage.getItem('free_r')
      let hr = localStorage.getItem('free_hr') === null ?  20 : localStorage.getItem('free_hr')
      let mr = localStorage.getItem('free_mr') === null ?  10 : localStorage.getItem('free_mr')
      let lr = localStorage.getItem('free_lr') === null ?  6 : localStorage.getItem('free_lr')

      props.handleOk(y, g, r, hr, mr, lr,
        yref.current.value,
        hrref.current.value, 
        mrref.current.value, 
        lrref.current.value)
    };


  function yy() {
    return localStorage.getItem('free_y1') === null ?  "6" : localStorage.getItem('free_y1')
  }

  function hR() {
    return localStorage.getItem('free_hr1') === null ?  6.6 : localStorage.getItem('free_hr1')
  }  

  function mR() {
    return localStorage.getItem('free_mr1') === null ?  5 : localStorage.getItem('free_mr1')
  }  

  function lR() {
    return localStorage.getItem('free_lr1') === null ?  3.3 : localStorage.getItem('free_lr1')
  } 

  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">股利折現法</DialogTitle>

        <DialogContent>
          <div style={{ fontSize: 12, color: '#b3b3b3' }}>
            假設公司賺的錢都轉換成股利發給股東，並且股利長時間穩定的發放，此時可以當成永久定期定額支付的現金流來推算價值．<br /><br />
          </div>

          <div style={{ fontSize: 12, color: '#82bd98' }} >
            <div>公式與參數</div>
            <div>1. 便宜價 = 股利 / 高殖利率 </div>
            <div>2. 合理價 = 股利 / 中殖利率</div>
            <div>3. 昂貴價 = 股利 / 低殖利率</div>
          </div>

          <br />
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label="平均幾年股利（y預設：6年）"
            type="text"
            fullWidth
            defaultValue={yy()}
            inputRef={yref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="hr"
            label="高殖利率（R預設：6.6%）"
            type="text"
            fullWidth
            defaultValue={hR()}
            inputRef={hrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="mr"
            label="中殖利率（R預設：5%）"
            type="text"
            fullWidth
            defaultValue={mR()}
            inputRef={mrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="lr"
            label="低殖利率（R預設：3.3%）"
            type="text"
            fullWidth
            defaultValue={lR()}
            inputRef={lrref}
            InputLabelProps={{
              shrink: true,
            }}
          />

        </DialogContent>
        {/* <DialogContentText  style={{paddingLeft: 20, paddingRight:20}}>


          </DialogContentText> */}
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>
      </Dialog>
  );
}
