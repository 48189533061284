import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import BColor from '../BColor'


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function CBDetail(props) {
    const classes = useStyles();
    const [open1, setOpen1] = React.useState(false);    

    function renderbid(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <StyledTableCell  align="left">
                <Link
                    component="button"
                    variant="caption"
                    onClick={() => {
                        window.open('https://mops.twse.com.tw/mops/web/t120sg01?TYPEK=&bond_id=' + row.cb[index].bid + '&bond_kind=5&bond_subn=' + row.cb[index].subn + '&bond_yrn=' + row.cb[index].yrn + '&come=2&encodeURIComponent=1&firstin=ture&issuer_stock_code='+ row.market_company.stock_no + '&monyr_reg=' + row.cb[index].reptdate + '&pg=&step=0&tg=&price' , '')
                    }}
                >{row.cb[index].bid}
                </Link>
                
            </StyledTableCell>
    }

    function rendername(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <StyledTableCell align="left">
                {row.cb[index].sname}
            </StyledTableCell>
    }

    function renderexpiredays(row, index) {
        if (row.cb != null && row.cb.length > index)
            return <StyledTableCell align="left">
                {row.cb[index].expiredays}天
            </StyledTableCell>
    }

    function renderecprice(row, index) {
        if (row.cb != null && row.cb.length > index) {
            if (row.cb[index].sprice >= row.cb[index].cprice)
                return <StyledTableCell  align="left">
                    <Button  variant="outlined" style={{ minWidth: 140,  color: BColor.upcolorbox }} className={classes.button1} >
                        已到達　{row.cb[index].cprice}元
                    </Button>
                </StyledTableCell>
            else if (row.cb[index].sprice < row.cb[index].cprice)
                return <StyledTableCell  align="left">
                    <Button  variant="outlined" style={{ minWidth: 140, color: BColor.downcolorbox }} className={classes.button1} >
                        未到達　{row.cb[index].cprice}元
                </Button>
                </StyledTableCell>
        }
    }

    function renderecbprice(row, index) {
        if (row.cb != null && row.cb.length > index) {
            if (row.cb[index].bprice >= row.cb[index].gprice)
                return <StyledTableCell  align="left">
                    <Button  variant="outlined" style={{ minWidth: 140, color: BColor.upcolorbox }} className={classes.button1} >
                        溢價　{row.cb[index].bprice}元
                    </Button>
                </StyledTableCell>
            else if (row.cb[index].bprice < row.cb[index].gprice)
                return <StyledTableCell  align="left">
                    <Button  variant="outlined" style={{ minWidth: 140, color: BColor.downcolorbox }} className={classes.button1} >
                        折價　{row.cb[index].bprice}元
                </Button>
                </StyledTableCell>
        }
    }

    function rendergprice(row, index) {
        if (row.cb != null && row.cb.length > index) {
            let k = row.cb[index].gprice + "元"
            if (row.cb[index].gprice === -99999.0) k = "-"
            return <StyledTableCell  align="left">
                {k}
            </StyledTableCell>
        }
    }

    function renderalreadyc(row, index) {
        if (row.cb != null && row.cb.length > index)
            if (row.cb[index].alreadyc >= 80)
                return <StyledTableCell  align="left">
                    <div style={{ color: BColor.upcolorbox }}>{row.cb[index].alreadyc}%</div>
                </StyledTableCell>
            else
                return <StyledTableCell  align="left">
                    {row.cb[index].alreadyc}%
            </StyledTableCell>
    }

    function renderguarant(row, index) {
        if (row.cb != null && row.cb.length > index)
            if (row.cb[index].guarant)
                return <StyledTableCell  align="left">
                    <div style={{ color: BColor.upcolorbox }}>有擔保</div>
                </StyledTableCell>
            else
                return <StyledTableCell  align="left">
                    無擔保
            </StyledTableCell>
    }

    function renderEmpty(row, index) {
        if (row.cb == null || (row.cb != null && row.cb.length <= index))
            return <StyledTableCell  colSpan="8" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期沒有發行可轉換公司債或無上櫃交易</Button>

            </StyledTableCell>
        //   return <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: 5, marginBottom: 5}} >現：{cash.toFixed(2)}元　股：{stock.toFixed(2)}元</div>
    }

    return (
        <div>
            {props.row.cb != null && props.row.cb.length > 0 ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            可轉換公司債＃1
                        </Typography>
                        
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'代碼'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'代碼'}
                                    </StyledTableCell>
                                    {renderbid(props.row, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'簡稱'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'簡稱'}
                                    </StyledTableCell>
                                    {rendername(props.row, 0)}
                                </StyledTableRow >
                                
                                <StyledTableRow key={'距到期日'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'距到期日'}
                                    </StyledTableCell>
                                    {renderexpiredays(props.row, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'轉換價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'轉換價'}
                                    </StyledTableCell>
                                    {renderecprice(props.row, 0)}
                                </StyledTableRow >   

                                <StyledTableRow key={'參考現價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'參考現價'}
                                    </StyledTableCell>
                                    {renderecbprice(props.row, 0)}
                                </StyledTableRow >  

                                <StyledTableRow key={'理論價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'理論價'}
                                    </StyledTableCell>
                                    {rendergprice(props.row, 0)}
                                </StyledTableRow >  

                                <StyledTableRow key={'已轉換率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'已轉換率'}
                                    </StyledTableCell>
                                    {renderalreadyc(props.row, 0)}
                                </StyledTableRow >  

                                <StyledTableRow key={'有無擔保'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'有無擔保'}
                                    </StyledTableCell>
                                    {renderguarant(props.row, 0)}
                                </StyledTableRow > 
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> :  <Typography className={classes.title} variant="h6" id="tableTitle">
            近期沒有發行可轉換公司債或無任何交易
                        </Typography>}


                        {props.row.cb != null && props.row.cb.length > 1 ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            可轉換公司債＃2
                        </Typography>
                        
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'代碼'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'代碼'}
                                    </StyledTableCell>
                                    {renderbid(props.row, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'簡稱'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'簡稱'}
                                    </StyledTableCell>
                                    {rendername(props.row, 1)}
                                </StyledTableRow >
                                
                                <StyledTableRow key={'距到期日'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'距到期日'}
                                    </StyledTableCell>
                                    {renderexpiredays(props.row, 1)}
                                </StyledTableRow >

                                <StyledTableRow key={'轉換價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'轉換價'}
                                    </StyledTableCell>
                                    {renderecprice(props.row, 1)}
                                </StyledTableRow >   

                                <StyledTableRow key={'參考現價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'參考現價'}
                                    </StyledTableCell>
                                    {renderecbprice(props.row, 1)}
                                </StyledTableRow >  

                                <StyledTableRow key={'理論價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'理論價'}
                                    </StyledTableCell>
                                    {rendergprice(props.row, 1)}
                                </StyledTableRow >  

                                <StyledTableRow key={'已轉換率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'已轉換率'}
                                    </StyledTableCell>
                                    {renderalreadyc(props.row, 1)}
                                </StyledTableRow >  

                                <StyledTableRow key={'有無擔保'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'有無擔保'}
                                    </StyledTableCell>
                                    {renderguarant(props.row, 1)}
                                </StyledTableRow > 
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> :  null}
                      
        </div >
    );
}