import React, { useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';

echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function PeopleTable(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [cdata, setCData] = React.useState(null);
    const [option, setOption] = React.useState(genoption1());
    const [option1, setOption1] = React.useState(genoption1());

    useEffect(() => {
        if (props.value === 7) runFectchAPII();
    }, [props.value]);


    function genData(m1) {
        let k = genoption1()
        k['xAxis'] = {
            data: m1.map(function (item) {
                return item.x;
            })
        }
        let series = []
        let y1 = {
            name: '加權指數',
            type: 'line',
            data: m1.map(function (item) {
                return item.y;
            }),
            smooth: true,
            showSymbol: false,
            connectNulls: true,
        }
        let y2 = {
            name: '股東人數',
            type: 'line',
            data: m1.map(function (item) {
                return item.p;
            }),
            yAxisIndex: 1,
            smooth: true,
            connectNulls: true,
            showSymbol: true,
            symbolSize: 4,
            showAllSymbol: true
        }

        series.push(y1)
        series.push(y2)

        k['series'] = series
        return k
    }

    const runFectchAPII = () => {
        let a = {
        };

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        fetch("/jstock/v1/stock/bigtable6", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.r != null && result.r.length > 0) setCData(true)
                    else setCData(false)

                    let k1 = genData(result.r)
                    setOption(k1)

                    let k2 = genData(result.r1)
                    setOption1(k2)
                }
            );
    }

    function genoption1() {
        let k = {
            legend: {
                data: ['加權指數', '股東人數'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 15,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true,
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 1000000)
                                return (value / 1000000).toFixed(1) + 'M'
                            else
                                return (value / 1000) + 'K'
                        }
                    }
                }
            ],
        }
        return k;
    }

    return (
        <div>
            <Grid container >
                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ marginLeft: 5, marginTop: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            集中市場與股東人數
                        </Typography>
                        <Typography style={{ color: "#858585" }} variant="caption" id="tableTitle1">
                            每周個股股東人數加總。一人同時擁有多間公司股票時，會重複計算。
                        </Typography>
                    </div>
                </Grid>


                {cdata ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無集中市場與股東人數資料</div>}


                <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            櫃買市場與股東人數
                        </Typography>
                    </div>
                </Grid>
                {cdata ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無櫃買市場與股東人數資料</div>}
            </Grid>
        </div>
    );
}

