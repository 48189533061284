import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import ExposureZero from '@material-ui/icons/Clear'
import BrokerList from './BrokerList1';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    let index = orderBy.substring(0, 1)
    let key = orderBy.substring(1)
    let bv = 0
    let av = 0

    if (index === "0") {
        if (b.avbk[0] != undefined) bv = b.avbk[0].db[key]
        if (a.avbk[0] != undefined) av = a.avbk[0].db[key]
    } else if (index === "1") {
        if (b.avsk[0] != undefined) bv = b.avsk[0].db[key]
        if (a.avsk[0] != undefined) av = a.avsk[0].db[key]
    } else if (index === "2") {
        if (b.avbsk[0] != undefined) bv = b.avbsk[0].db[key]
        if (a.avbsk[0] != undefined) av = a.avbsk[0].db[key]
    }

    if (bv < av) {
        return -1;
    }
    if (bv > av) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: '0bname', numeric: false, disablePadding: false, label: '券商分點', sid: 'bname' },
    { id: '0avgbuy', numeric: false, disablePadding: false, label: '40日均買入', sid: 'avgbuy' },
    { id: '0tbuy', numeric: false, disablePadding: false, label: '近期買入', sid: 'tbuy' },
    { id: '0abinc', numeric: false, disablePadding: false, label: '增加幅度', sid: 'abinc' },

    { id: '1bname', numeric: false, disablePadding: false, label: '券商分點', sid: 'bname' },
    { id: '1avgsell', numeric: false, disablePadding: false, label: '40日均賣出', sid: 'avgsell' },
    { id: '1tsell', numeric: false, disablePadding: false, label: '近期賣出', sid: 'tsell' },
    { id: '1asinc', numeric: false, disablePadding: false, label: '增加幅度', sid: 'asinc' },

    { id: '2bname', numeric: false, disablePadding: false, label: '券商分點', sid: 'bname' },
    { id: '2avgtbs', numeric: false, disablePadding: false, label: '40日均買賣超', sid: 'avgtbs' },
    { id: '2tbs', numeric: false, disablePadding: false, label: '近期買賣超', sid: 'tbs' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;


    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">買入異常增加</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">賣出異常增加</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">買賣超異常</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === '0bname' || headCell.id === '1bname' || headCell.id === '2bname' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === '0bname' || headCell.id === '1bname' || headCell.id === '2bname' || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap' }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function BrokerAvgTable(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [opendealer, setOpenDealer] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opendname, setOpenDname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no, stockname, dealer_id, dealername) => event => {
        setOpenstock(stock_no)
        setOpenDealer(dealer_id)
        setOpenSname(stockname)
        setOpenDname(dealername)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenDealer(null)
        setOpenSname(null)
        setOpenDname(null)
        setConcentOpen(false);
    };


    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }



    function renderName(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbk[index].db.dealer_id, row.avbk[index].name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avbk[index].name}</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left"></TableCell>
    }

    function renderBuy(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbk[index].db.avgbuy / 1000).toFixed())

            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbk[index].db.dealer_id, row.avbk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 張</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderBuy1(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbk[index].db.tbuy / 1000).toFixed())
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbk[index].db.dealer_id, row.avbk[index].name)} variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{row.avbk[index].db.tymd} / {word} 張</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderWinprice(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            let word = "持平 "
            let vv = row.avbk[index].db.abinc

            if (row.avbk[index].db.abinc < -100) {
                colorx = BColor.downcolorbox
                word = "銳減 "
                vv = -vv
            } else if (row.avbk[index].db.abinc >= -100 && row.avbk[index].db.abinc <= 100) {
                colorx = "#000205"
                word = "正常 "
            } else if (row.avbk[index].db.abinc > 200 && row.avbk[index].db.abinc < 800) {
                colorx = BColor.upcolorbox
                word = "激增 "
            } else if (row.avbk[index].db.abinc > 800) {
                colorx = BColor.upcolorbox
                word = "激增 "
            } else {
                colorx = "#000205"
                word = "正常 "
            }

            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{word} {vv}%</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderEmpty(row, index) {
        if (row.avbk === undefined || row.avbk.length < index) {
            let colorx = "#000205"
            let word = "尚無券商大幅買進"

            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="4" size="small" align="center">
                {/* <Button size="small" variant="outlined" style={{ width: "100%", color: colorx }} className={classes.button1}>{word}</Button> */}
                <ExposureZero color="primary" />
            </TableCell>
        }
    }

    function rendersName(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avsk[index].db.dealer_id, row.avsk[index].name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avsk[index].name}</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left"></TableCell>
    }

    function renderSell(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avsk[index].db.avgsell / 1000).toFixed())

            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avsk[index].db.dealer_id, row.avsk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 張</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderSell1(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avsk[index].db.tsell / 1000).toFixed())
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avsk[index].db.dealer_id, row.avsk[index].name)} variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{row.avsk[index].db.tymd} / {word} 張</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderSWinprice(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            let word = "持平 "
            let vv = row.avsk[index].db.asinc

            if (row.avsk[index].db.asinc < -100) {
                // colorx = BColor.downcolorbox
                word = "銳減 "
                vv = -vv
            } else if (row.avsk[index].db.asinc >= -100 && row.avsk[index].db.asinc <= 100) {
                colorx = "#000205"
                word = "正常 "
            } else if (row.avsk[index].db.asinc > 200 && row.avsk[index].db.asinc < 800) {
                colorx = BColor.downcolorbox
                word = "激增 "
            } else if (row.avsk[index].db.asinc > 800) {
                colorx = BColor.downcolorbox
                word = "激增 "
            } else {
                colorx = "#000205"
                word = "正常 "
            }

            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{word} {vv}%</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderSEmpty(row, index) {
        if (row.avsk === undefined || row.avsk.length < index) {
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="4" size="small" align="center">
                {/* <Button size="small" variant="outlined" style={{ width: "100%", color: colorx }} className={classes.button1}>{word}</Button> */}
                <ExposureZero color="primary" />
            </TableCell>
        }
    }

    function renderbsName(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avbsk.length > index) {
            let colorx = "#000205"
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbsk[index].db.dealer_id, row.avbsk[index].name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avbsk[index].name}</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="left"></TableCell>
    }

    function renderBS(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avbsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbsk[index].db.avgtbs / 1000).toFixed())

            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbsk[index].db.dealer_id, row.avbsk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 張</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderBS1(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avbsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbsk[index].db.tbs / 1000).toFixed())
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbsk[index].db.dealer_id, row.avbsk[index].name)} variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{row.avbsk[index].db.tymd} / {word} 張</Button>
            </TableCell>
        } else if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }



    function renderBSEmpty(row, index) {
        if (row.avsk === undefined || row.avbsk.length < index) {
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="3" size="small" align="center">
                {/* <Button size="small" variant="outlined" style={{ width: "100%", color: colorx }} className={classes.button1}>{word}</Button> */}
                <ExposureZero color="primary" />
            </TableCell>
        }
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <BrokerList open={concentopen} handleClose={handleClosecc} stockId={openstock} dealerId={opendealer} stockname={opensname} dealername={opendname}></BrokerList>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {renderName(row, 0, false)}
                                            {renderBuy(row, 0, false)}
                                            {renderBuy1(row, 0, false)}
                                            {renderWinprice(row, 0, false)}
                                            {renderEmpty(row, 1)}

                                            {/* {renderName(row, 1, false)}
                                            {renderBuy(row, 1, false)}
                                            {renderBuy1(row, 1, false)}
                                            {renderWinprice(row, 1, false)}
                                            {renderEmpty(row, 1)} */}

                                            {rendersName(row, 0, false)}
                                            {renderSell(row, 0, false)}
                                            {renderSell1(row, 0, false)}
                                            {renderSWinprice(row, 0, false)}
                                            {renderSEmpty(row, 1)}

                                            {/* {rendersName(row, 1, false)}
                                            {renderSell(row, 1, false)}
                                            {renderSell1(row, 1, false)}
                                            {renderSWinprice(row, 1, false)}
                                            {renderSEmpty(row, 1)} */}

                                            {renderbsName(row, 0, false)}
                                            {renderBS(row, 0, false)}
                                            {renderBS1(row, 0, false)}
                                            {renderBSEmpty(row, 1)}


                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(BrokerAvgTable);
