import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MASetting(props) {

    const yref = React.createRef();
    const hrref = React.createRef();
    const drref = React.createRef();

    const handleOk = () => {
      if(yref.current.value != null && yref.current.value != "")
      localStorage.setItem('sma', parseInt(yref.current.value));

      if(hrref.current.value != null && hrref.current.value != "")
      localStorage.setItem('lma', parseFloat(hrref.current.value));

      if(drref.current.value != null && drref.current.value != "")
      localStorage.setItem('dma', parseFloat(drref.current.value));

      props.handleOk()
    };


  function yy() {
    return localStorage.getItem('sma') === null ? 5 : localStorage.getItem('sma')
  }

  function hR() {
    return localStorage.getItem('lma') === null ?  20 : localStorage.getItem('lma')
  }  

  function hkdk() {
    return localStorage.getItem('dma') === null ?  1 : localStorage.getItem('dma')
  }    
  
  return (
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">均線設定</DialogTitle>

        <DialogContent>

          <br />
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label="短均線(預設:5)"
            type="text"
            fullWidth
            defaultValue={yy()}
            inputRef={yref}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            id="hr"
            label="長均線(預設:20)"
            type="text"
            fullWidth
            defaultValue={hR()}
            inputRef={hrref}
            InputLabelProps={{
              shrink: true,
            }}
          />
<TextField
            margin="dense"
            id="kdk"
            label="累積天數(預設:1)"
            type="text"
            fullWidth
            defaultValue={hkdk()}
            inputRef={drref}
            InputLabelProps={{
              shrink: true,
            }}
          />

        </DialogContent>
        {localStorage.getItem('noad') === "1" ? <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            取消
          </Button>
          <Button onClick={handleOk} color="primary">
            確定
          </Button>
        </DialogActions>:<DialogActions>
          <Button onClick={props.handleClose} color="primary">
            關閉（訂閱專屬功能）
          </Button>
        </DialogActions>}
      </Dialog>
  );
}
