import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";
import Rule2ADesc from './Rule2ADesc';

const styles = theme => ({
  root: {
  }
})


class Rule676 extends Component {
    render() {
        return (
          <Rule2ADesc
          id="676"
          t1=""
          t2="的"
          t3="投資的股票"
          desc=""
        //   data1={MoneyMB}
        //   data2={MoneyMB}
          ruleChange={this.props.ruleChange}
          rules={this.props.rules}
      ></Rule2ADesc>
          );
    }
  }
  

export default withRouter(withStyles(styles)(Rule676));