import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { Divider } from '@material-ui/core';
import CategoryDetail from '../industry/CategoryDetail'
import StockDetail from '../mobile/StockDetail'


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function CategoryInfo(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [opendetail, setOpendetail] = React.useState(false)
    const [stock, setStock] = React.useState(null);

    useEffect(() => {
    }, [props.row]);

    function showRuleDetail(row) {
        let v = {
            cs: row
        }
        setRow(v)
        setOpen(true)
    }

    function closeDialog() {
        setOpen(false)
    }

    const closeDetail = () => {
        setOpendetail(false)
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpendetail(true)
    }

    return (
        <div>
            {opendetail?<StockDetail open={opendetail} handleClose={closeDetail} stockno={stock} ismobile={props.ismobile} />:null}
            <CategoryDetail ismobile={props.ismobile} open={open} row={row} handleClose={closeDialog}></CategoryDetail>
            <Grid container spacing={3} className={classes.grid}>
                {props.row.sc != null ? props.row.sc.map(row => {
                    return <Grid item xs={12} sm={12} md={4} xl={6} style={{}}>
                        <Paper variant="outlined" square style={{ padding: 5 }}>
                            <Typography variant="h5">{row.name}
                            <Typography variant="caption" style={{marginLeft: 5}}  onClick={e => showRuleDetail(row)}>產業圖表</Typography>
                            </Typography>
                            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                            {row.ss.map(stock => {
                                return <Chip onClick={e => openDetail(stock.id)} style={{minWidth:80, background: stock.inc > 0 ? '#e8b0c5' : undefined, margin: 5 }} label={stock.id + ' ' + stock.name} />
                            })}
                        </Paper>
                    </Grid>
                }) : null}
            </Grid>


        </div>
    );
}

