import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'
import KTwoChart1 from './KTwoChart1';
import StockDetail from '../mobile/StockDetail'
import Link from '@material-ui/core/Link';


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(ymd, name, roi1, roi3, stock, rymd) {
    return { ymd, name, roi1, roi3, stock, rymd };
}


const useStyles = makeStyles({
    table: {
        minWidth: 250,
    },
    button1: {
        // minWidth: 110,
    }
});

export default function SellStockList(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    const [stock1, setStock1] = React.useState(null);
    const [stock2, setStock2] = React.useState(null);
    const [days, setDays] = React.useState(null);
    const [et1, setET1] = React.useState(null);
    const [et2, setET2] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [oid, setOid] = React.useState(false);
    const [rundate, setRunDate] = React.useState(false);

    const [name1, setName1] = React.useState(null);
    const [name2, setName2] = React.useState(null);
    // openTwoK(props.stock, row.stock, props.days, props.et, row.rymd)

    const [opendetail, setOpendetail] = React.useState(false)
    const [stock, setStock] = React.useState(null);

    const closeDetail = () => {
        setOpendetail(false)
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpendetail(true)
    }



    const openTwoK = (id, stock1, stock2, days, et1, et2, name1v, name2v, rundatev) => {
        setStock1(stock1)
        setStock2(stock2)
        setDays(days)
        setET1(et1)
        setET2(et2)
        setOid(id)
        setName1(name1v)
        setName2(name2v)
        setRunDate(rundatev)
        setOpen(true)
    }

    useEffect(() => {
        if (props.open) fectchStockData(props.id)
    }, [props.id]);


    function closeDialog1() {
        setOpen(false)
    }

    function fectchStockData(id) {
        let lic = localStorage.getItem('lic')

        if (lic != null && id != null) {
            let a = {
                lic: lic,
                id: id
            };

            fetch("/jstock/v1/stock/kteststocklist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let dd = result.map(row =>
                            createData(moment(row.ymd, "YYYYMMDD").format('YYYY/MM/DD'), row.name, row.roi1, row.roi3, row.stock, row.ymd)
                        )
                        setBuyselldata(dd)
                    },
                    error => {
                    }
                );
        }
    }

    function getColor(value) {
        if (value != null && value != -99999) {
            if (value > 0) return BColor.upcolor
            else if (value === 0) return BColor.faircolor
            else if (value < 0) return BColor.downcolor
            else return BColor.faircolor
        } return BColor.faircolor
    }

    return (
        <div>
            <KTwoChart1 rundate={rundate} name1={name1} name2={name2} oid={oid} open={open} handleClose={closeDialog1} stock1={stock1} stock2={stock2} et1={et1} et2={et2} days={days}></KTwoChart1>
            <StockDetail open={opendetail}   handleClose={closeDetail} stockno={stock} />
            <Dialog fullScreen open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">相似型態列表 (越前面越相似)</DialogTitle>

                <DialogContent style={{ overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: '80vh' }}>
                        <Table className={classes.table} aria-label="customized table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">股票</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">日期</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">型態比較</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">3日後漲跌幅</StyledTableCell>
                                    <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left">7日後漲跌幅</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {buyselldata.map(row => (
                                    <StyledTableRow key={Math.random()}>
                                        <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                                        <Link
                                                style={{ color: BColor.upcolor, fontSize: 14 }}
                                                component="button"
                                                variant="caption"
                                                onClick={() => openDetail(row.stock)}
                                            >{row.name}({row.stock})
                                            </Link>
                                    </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row.ymd}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <Button variant="contained" onClick={(e) => openTwoK(props.id, props.stock, row.stock, props.days, props.et, row.rymd, props.name, row.name, props.rundate)}>比較圖</Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" style={{ color: getColor(row.roi1) }} >
                                            {row.roi1}%
                                    </StyledTableCell>
                                        <StyledTableCell align="left" style={{ color: getColor(row.roi3) }} >
                                            {row.roi3}%
                                    </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
