import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BIASList from '../details/BIASList'
import BColor from '../BColor'
import { useTheme } from '@material-ui/core/styles';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart, ScatterChart } from 'echarts/charts';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import CCISetting from './CCISetting';
import { putInpQueryParam } from '../util/Syno'

echarts.use(
    [ScatterChart, AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function CCI(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [loading, setLoading] = React.useState(false);
    const [open3, setOpen3] = React.useState(false)

    function getDefaultAChartOption() {
        let newOption = {
            title: [
                {
                    left: 'center',
                    text: 'CCI',
                    top: '300',
                },
                {
                    left: 'center',
                    text: '威廉指標',
                    top: '560',
                },
                {
                    left: 'center',
                    text: 'DMA/AMA',
                    top: '840',
                }
            ],
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '330',
                    left: '40',
                    right: '20',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '590',
                    left: '40',
                    right: '20',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '870',
                    left: '40',
                    right: '20',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]         
        };

        return newOption
    }

    const getPHV1 = () => {
        setOpen3(false)
        setLoading(true)
        let lic = localStorage.getItem('lic')
        let param = lic === null ? {
            sid: props.row.market_company.stock_no,
            inp: {}
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no,
            inp: {}
        };

        param = putInpQueryParam(param)
        
        fetch("/jstock/v1/stock/cci", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        let newOption = getDefaultAChartOption()
                        newOption['yAxis'] = [
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                            },
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                                gridIndex: 1,
                            },
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                                gridIndex: 2,
                            },
                            {
                                scale: true,
                                splitArea: {
                                    show: false
                                },
                                show: true,
                                position: 'left',
                                splitLine: {
                                    show: true
                                },
                                gridIndex: 3,
                            }
                        ]

                        let x1 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => { return v.x }),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            splitNumber: 20,
                            min: 'dataMin',
                            max: 'dataMax',
                        }

                        let x2 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 1,
                        }

                        let x3 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 2,
                        }

                        let x4 = {
                            type: 'category',
                            scale: true,
                            boundaryGap: true,
                            data: result.map(v => v.x),
                            axisLine: { onZero: false },
                            splitLine: { show: false },
                            gridIndex: 3,
                        }

                        if (props.ismobile) {
                            let xpoint = {
                                type: 'shadow',
                                label: { show: false },
                                triggerTooltip: true,
                                handle: {
                                    show: true,
                                    margin: 25,
                                    color: '#1868d9',
                                    size: 30
                                }
                            }

                            x1['axisPointer'] = xpoint
                            x2['axisPointer'] = xpoint
                            x3['axisPointer'] = xpoint
                            x4['axisPointer'] = xpoint
                        }


                        newOption['xAxis'] = [x1, x2, x3, x4]

                        newOption['series'] = [
                            {
                                name: '日K',
                                type: 'candlestick',
                                data: result.map(v => { return v.y }),
                                itemStyle: {
                                    color: "#d41c1c",
                                    color0: '#65b206',
                                    borderColor: "#d41c1c",
                                    borderColor0: '#65b206'
                                },
                                selectedMode: 'single',
                            },
                            {
                                name: 'CCI',
                                type: 'line',
                                data: result.map(v => { return v.v1 }),
                                symbolSize: 6,
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                markLine: {
                                    symbol: "none",
                                    label: {
                                        show: false
                                    },
                                    data: [
                                        {
                                            name: '100',
                                            yAxis: 100,
                                            lineStyle: {
                                                width: 2,
                                            }
                                        },
                                        {
                                            name: '-100',
                                            yAxis: -100,
                                            lineStyle: {
                                                width: 2,
                                            }
                                        }
                                    ]
                                },
                            },
                            {
                                name: '威廉指標',
                                type: 'line',
                                data: result.map(v => { return v.v2 }),
                                symbolSize: 6,
                                xAxisIndex: 2,
                                yAxisIndex: 2,
                                markLine: {
                                    symbol: "none",
                                    label: {
                                        show: false
                                    },
                                    data: [
                                        {
                                            name: '-20',
                                            yAxis: -20,
                                            lineStyle: {
                                                width: 2,
                                            }
                                        },
                                        {
                                            name: '-80',
                                            yAxis: -80,
                                            lineStyle: {
                                                width: 2,
                                            }
                                        }
                                    ]
                                },
                            },
                            {
                                name: 'DMA',
                                type: 'line',
                                data: result.map(v => { return v.v3 }),
                                symbolSize: 6,
                                xAxisIndex: 3,
                                yAxisIndex: 3,
                                showSymbol: false
                            },
                            {
                                name: 'AMA',
                                type: 'line',
                                data: result.map(v => { return v.v4 }),
                                symbolSize: 6,
                                xAxisIndex: 3,
                                yAxisIndex: 3,
                                showSymbol: false
                                
                            }
                        ]

                        if (props.ismobile) {
                            newOption['tooltip'] = getMobileTooltip()
                            newOption['axisPointer'] = getAxisPointer()
                            newOption['dataZoom'] = getMobileDataZoom(80, 100)

                        } else {
                            newOption['tooltip'] = getComputerTooltip()
                            newOption['axisPointer'] = getAxisPointer()
                            // newOption['dataZoom'] = getComputerDataZoom(50, 100, 300)
                        }

                        setLoading(false)
                        setOptions(newOption)
                    } else {
                        setLoading(false)
                    }
                },
                error => {
                    setLoading(false)
                }
            );
    }


    useEffect(() => {
        getPHV1()
    }, [props.row]);


    return (
        <div>
            <CCISetting open={open3} handleClose={getPHV1} handleOk={getPHV1}></CCISetting>

            {options != null ? <Grid item xs={12} sm={12} >
                <Button color="secondary" onClick={e => setOpen3(true)}>參數設定</Button>
                <ReactEChartsCore
                    theme={theme.palette.type}
                    style={{ height: 1100, marginTop: 0 }}
                    echarts={echarts}
                    option={options}
                    notMerge={true}
                    lazyUpdate={false}
                    showLoading={loading}
                />
            </Grid> : null}

        </div >
    );
}

