import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import BColor from '../BColor'
import SellStockList from './SellStockList'
import { useTheme } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});


export default function KTestTable(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState(null);

    const [open1, setOpen1] = React.useState(false);
    const [id, setId] = React.useState(null);

    const [stock, setStock] = React.useState(null);
    const [et, setET] = React.useState(null);
    const [days, setDays] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [rundate, setRundate] = React.useState(null);

    function getStatus(status, per) {
        if (status === 0) {
            return <Button size="small" style={{  }}>排隊中</Button>
        } else if (status === 1) {
            return <Button size="small" style={{ backgroundColor: BColor.bluecolor, color: BColor.whitecolor }}>執行中({per != null ? per + "%" : "0%"})</Button>
        } else if (status === 2) {
            return <Button size="small" style={{ backgroundColor: BColor.downcolorbox, color: BColor.whitecolor }}>成功</Button>
        } else if (status === -1) {
            return <Button size="small" style={{ backgroundColor: BColor.upcolorbox, color: BColor.whitecolor }}>失敗</Button>
        } else {
            return <Button size="small" style={{  }}>-</Button>
        }
    }

    function showRuleDetail(row1) {
        setRow(row1)
        setOpen(true)
    }

    function closeDialog() {
        setOpen(false)
    }

    function showStockDetail1(id, stock, et, days, name, rundatev) {
        setStock(stock)
        setET(et)
        setDays(days)
        setId(id)
        setName(name)
        setRundate(moment(rundatev).format('YYYYMMDD'))
        props.stopRefresh()
        setOpen1(true)
    }

    function closeDialog1() {
        props.restartRefresh()
        setOpen1(false)
    }

    function getColor(value) {
        if (value != null && value.roi != -99999) {
            if (value.roi > 0) return BColor.upcolor
            else if (value.roi === 0) return BColor.faircolor
            else if (value.roi < 0) return BColor.downcolor
            else return BColor.faircolor
        } return BColor.faircolor
    }

    return (
        <div>
            <SellStockList rundate={rundate} name={name} open={open1} id={id} handleClose={closeDialog1} stock={stock} et={et} days={days}></SellStockList>
            {props.data != null ? <TableContainer component={Paper} style={{maxHeight: '75vh', backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",}}>
                <Table size="small" className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">股票</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">執行時間</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">執行狀態</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">取樣標的</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">取樣日期</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">取樣天數</StyledTableCell>

                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">上漲機率(3日後)</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">下跌機率(3日後)</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">平均漲幅(3日後)</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">平均跌幅(3日後)</StyledTableCell>

                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">上漲機率(7日後)</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">下跌機率(7日後)</StyledTableCell>                            
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">平均漲幅(7日後)</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="right">平均跌幅(7日後)</StyledTableCell>

                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">明細</StyledTableCell>
                            <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="center">動作</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                {row.name.length > 8 ? `${row.name.substring(0, 8)}...` : row.name}
                                </StyledTableCell>
                                <StyledTableCell align="center">{moment(row.ts).format('YYYY-MM-DD HH:mm:ss')}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {getStatus(row.status, row.per)}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.tp === 0 ? '收盤價' : '5跟20日均線'}</StyledTableCell>
                                <StyledTableCell align="right">{row.tdate != undefined? moment(row.tdate).format('YYYY-MM-DD') : ''}</StyledTableCell>
                                <StyledTableCell align="right">{row.days}天</StyledTableCell>

                                <StyledTableCell align="right" style={{ color: BColor.upcolorbox}}>
                                        {row.result != null ? row.result.d1w + "%" : '-'}
                                </StyledTableCell>
                                <StyledTableCell align="right" style={{ color: BColor.downcolorbox}} >
                                        {row.result != null ? row.result.d1l + "%" : '-'}
                                </StyledTableCell>
                                <StyledTableCell align="right"  style={{ color: BColor.upcolorbox}}>
                                        {row.result != null && row.result.d1wroi != -99999 ? row.result.d1wroi + "%" : '-'}
                                </StyledTableCell>
                                <StyledTableCell align="right"  style={{ color: BColor.downcolorbox}}>
                                        {row.result != null && row.result.d1lroi != -99999 ? row.result.d1lroi + "%" : '-'}
                                </StyledTableCell>

                                <StyledTableCell align="right" style={{ color: BColor.upcolorbox}} >
                                        {row.result != null ? row.result.d3w + "%" : '-'}
                                </StyledTableCell>
                                <StyledTableCell align="right"  style={{ color: BColor.downcolorbox}}>
                                        {row.result != null ? row.result.d3l + "%" : '-'}
                                </StyledTableCell>

                                <StyledTableCell align="right" style={{ color: BColor.upcolorbox}} >
                                        {row.result != null && row.result.d3wroi != -99999 ? row.result.d3wroi + "%" : '-'}
                                </StyledTableCell>
                                <StyledTableCell align="right"  style={{ color: BColor.downcolorbox}} >
                                        {row.result != null && row.result.d3lroi != -99999 ? row.result.d3lroi + "%" : '-'}
                                </StyledTableCell>

                                <StyledTableCell align="right">
                                    <Button size="small" variant="outlined" color="secondary" onClick={(e) => showStockDetail1(row.id, row.stock, row.tdate, row.days, row.name, row.ts)}>
                                        明細
                                </Button>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Button size="small" variant="outlined" color="secondary" onClick={(e) => props.delktest(row.id)}>
                                        刪除
                                </Button>
                                </StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> : null}
        </div>
    );
}
