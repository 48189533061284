import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import FinTable from './FinTable'
import WinMTable from './WinMTable'
import RevTable from './RevTable'
import IntDetail from './IntDetail'
import InPriceTable from './InPriceTable';
import BrokerTable from './BrokerTable';
import BrokerDateTable from './BrokerDateTable';
import TechDetail from './TechDetail';
import CBDetail from './CBDetail'
import BIASDetail from './BIASDetail'
import EPSDetail from './EPSDetail'
import EPSDetail1 from './EPSDetail1'
import KDDetail from './KDDetail'
import BrokerAvgTable from './BrokerAvgTable'
import { makeStyles } from '@material-ui/core/styles';
import InPrice2Table from './InPrice2Table';
import BigSmallTable from './BigSmallTable'
import BigCusTableD1 from './BigCusTableD1'
import BigCusTableD7 from './BigCusTableD7'
import MACDDetail from './MACDDetail'
import FinRateDetail from './FinRateDetail';
import Rev1Table from './Rev1Table'
import MainCostDetail from './MainCostDetail'
import RiskDetail from './RiskDetail';
import FinOtherRateDetail from './FinOtherRateDetail'
import InPrice3Table from './InPrice3Table';
import ETFDetail from './ETFDetail';
import HighLowDetail from './HighLowDetail'
import BSmallBigDetail from './BSmallBigDetail'
import CashReport from './CashReport';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    tab: {
        display: 'block',
        overflowY: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
        paddingLeft: 0,
        marginBottom: 0,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF"
    }
}));

export default function MainDetail3(props) {
    const classes = useStyles();
    return (
        <>

            <Tabs >
                <TabList className={classes.tab}>
                    <Tab>基本資訊</Tab>
                    <Tab>籌碼日報</Tab>
                    <Tab>籌碼週報</Tab>
                    <Tab>主力</Tab>
                    <Tab>大戶/散戶(日)</Tab>
                    <Tab>大戶/散戶(週)</Tab>
                    <Tab>財務報表</Tab>
                    <Tab>財務比率</Tab>
                    <Tab>價值評估1</Tab>
                    <Tab>價值評估2</Tab>
                    <Tab>價值評估3</Tab>
                    <Tab>風險</Tab>
                    <Tab>營收</Tab>
                    <Tab>營收動能</Tab>
                    <Tab>三率</Tab>
                    <Tab>除權息</Tab>
                    <Tab>大盤勝負</Tab>
                    <Tab>EPS</Tab>
                    <Tab>預估EPS</Tab>
                    <Tab>券商囤貨</Tab>
                    <Tab>券商買超天數</Tab>
                    <Tab>券商異常交易</Tab>
                    <Tab>KD</Tab>
                    <Tab>MACD</Tab>
                    <Tab>布林通道</Tab>
                    <Tab>乖離率</Tab>
                    <Tab>可轉債</Tab>
                    <Tab>ETF</Tab>
                    <Tab>股價本益比</Tab>
                </TabList>
                <Divider variant="fullWidth" style={{ backgroundColor: "#FFFFFF" }} />

                <TabPanel>
                    <FinTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BigCusTableD1 detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} bigtitle={props.bigtitle} bigupdate={props.bigupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BigCusTableD7 detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} bigtitle={props.bigtitle} bigupdate={props.bigupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <MainCostDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} bigtitle={props.bigtitle} bigupdate={props.bigupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BSmallBigDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BigSmallTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <CashReport detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>

                <TabPanel>
                    <FinOtherRateDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <InPriceTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <InPrice2Table detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <InPrice3Table detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <RiskDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>

                <TabPanel>
                    <RevTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <Rev1Table detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <FinRateDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <IntDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <WinMTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <EPSDetail1 detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <EPSDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BrokerTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BrokerDateTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BrokerAvgTable detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <KDDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <MACDDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <TechDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <BIASDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <CBDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <ETFDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
                <TabPanel>
                    <HighLowDetail detailUpdate={props.detailUpdate} data={props.data} dense={props.dense} rules={props.rules} total={props.total} pageupdate={props.pageupdate} currentpage={props.currentpage} currentcount={props.currentcount} disablepage={props.disablepage} freecashupdate={props.freecashupdate} myloveupdate={props.myloveupdate} ismobile={props.ismobile} />
                </TabPanel>
            </Tabs>
        </>
    )
};