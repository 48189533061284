import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EPSList from '../details/EPSList'
import BColor from '../BColor'


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function MEPSDetail(props) {
    const classes = useStyles();
    let redcolor = "#ed5f84"
    let backcolor = "#FFFFFF"
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox
    let greencolor = "#53a66f"

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openstock, setOpenstock] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);


    const handleClickOpencc = (stock_no) => event => {
        setOpenstock(stock_no)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setConcentOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        if (value != null && value != '' && value.length === 4) {
            localStorage.setItem('epnow', value);
            props.bigupdate()
        } else {
            localStorage.removeItem("epnow")
            props.bigupdate()
        }

        setAnchorEl(null);
    };



    const renderEPS = (value, base) => {
        let color = BColor.faircolor

        if (value != null && value != undefined) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {value}元
        </Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
        </Button>
        }
    }

    const renderBaseEPS = (value, base) => {
        let color = BColor.faircolor
        let color1 = "#000000"
        let colorb = "#FFFFFF"
        if (value != null && value != undefined) {

            let a = "0%"
            if (value > 0 && base > 0) {
                let a1 = ((value / base) * 100).toFixed(0)

                if (a1 > 100) {
                    colorb = backcolor
                    color1 = redcolor
                }
                a = a1 + "%"
            } else if (value > 0 && base < 0) {
                a = "盈"
                colorb = redback
                color1 = "#FFFFFF"
            } else if (value < 0 && base > 0) {
                a = "虧"
                colorb = greenback
                color1 = "#FFFFFF"
            }

            return <div ><Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {value}元
        </Button><br /><Button variant="outlined" style={{ marginTop: 1, minWidth: 110, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    {a}
                </Button></div>
        } else {
            return <div ><Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
        </Button><br /><Button variant="outlined" style={{ marginTop: 1, minWidth: 110, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    0%
        </Button></div>
        }
    }


    const renderThisYearEPS = (eps) => {
        let color = BColor.faircolor
        let color1 = "#000000"
        let colorb = "#FFFFFF"
        if (eps != null && eps != undefined && eps.tyeps != null && eps.tyeps != undefined) {
            let a = "0%"
            if (eps.tyeps > 0 && eps.lyeps > 0) {
                let a1 = ((eps.tyeps / eps.lyeps) * 100).toFixed(0)

                if (a1 > 100) {
                    colorb = backcolor
                    color1 = redcolor
                }
                a = a1 + "%"
            } else if (eps.tyeps > 0 && eps.lyeps < 0) {
                a = "盈"
                colorb = redback
                color1 = "#FFFFFF"
            } else if (eps.tyeps < 0 && eps.lyeps > 0) {
                a = "虧"
                colorb = greenback
                color1 = "#FFFFFF"
            }

            return <div ><Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {eps.tyeps}元
        </Button><br /><Button variant="outlined" style={{ marginTop: 1, minWidth: 110, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    {a}
                </Button></div>
        } else {
            return <div ><Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
        </Button><br /><Button variant="outlined" style={{ marginTop: 1, minWidth: 110, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    0%
        </Button></div>
        }
    }

    const renderLastYearEPS = (eps) => {
        let color = BColor.faircolor

        if (eps != null && eps != undefined && eps.lyeps != null && eps.lyeps != undefined) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {eps.ly}年 {eps.lyeps}元
        </Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
        </Button>
        }
    }

    const renderR1LastYearEPS = (eps) => {
        let color = BColor.faircolor
        let color1 = "#000000"
        let colorb = "#FFFFFF"
        if (eps != null && eps != undefined && eps.r1 != null && eps.r1 != undefined && eps.r1.y1av != null && eps.r1.y1av != undefined) {
            let a = "0%"

            if (eps.r1.y1mx > 0 && eps.lyeps > 0) {
                let a1 = ((eps.r1.y1mx / eps.lyeps) * 100).toFixed(0)

                if (a1 > 100) {
                    colorb = backcolor
                    color1 = redcolor
                }

                a = a1 + "%"
            } else if (eps.r1.y1mx > 0 && eps.lyeps < 0) {
                a = "盈"
                colorb = redback
                color1 = "#FFFFFF"
            } else if (eps.r1.y1mx < 0 && eps.lyeps > 0) {
                a = "虧"
                colorb = greenback
                color1 = "#FFFFFF"
            }


            return <div ><Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {eps.ty}年 {eps.r1.y1mx}元
        </Button><br /><Button variant="outlined" style={{ marginTop: 1, minWidth: 110, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    {a}
                </Button></div>
        } else {
            return <div ><Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
        </Button><br /><Button variant="outlined" style={{ marginTop: 1, minWidth: 110, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    0%
        </Button></div>
        }
    }

    const renderNValue = (value, stockno) => {
        let color = BColor.faircolor

        if (value > 0) {
            return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                {value}次
        </Button>
        } else {
            return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {value}次
        </Button>
        }
    }

    const renderPValue = (value, base, stockno) => {
        let color = BColor.faircolor

        if (value > base) {
            return <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                {value}%
        </Button>
        } else {
            return <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {value}%
        </Button>
        }
    }

    const rendereps1 = (index, value, stockno) => {
        if (index === 0) {
            let color = BColor.nodatacolor
            let bcolor = "#FFFFFF"
            if (value >= 0 && value < 100) {
                color = BColor.upcolorbox
            } else if (value >= 100) {
                color = "#FFFFFF"
                bcolor = BColor.upcolorbox
            } else if (value < 0 && value > -100) {
                color = BColor.downcolorbox
            } else if (value < -100) {
                color = "#FFFFFF"
                bcolor = BColor.downcolorbox
            }

            return <Button variant="outlined" onClick={handleClickOpencc(stockno)} style={{ whiteSpace: "nowrap", minWidth: 110, backgroundColor: bcolor, color: color }} className={classes.button1} >
                {value}%
          </Button>
        } else if (index === 1) {
            return <Button variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 110, color: BColor.downcolorbox }} className={classes.button1}>盈轉虧</Button>
        } else if (index === 2) {
            return <Button variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 110, color: BColor.upcolorbox }} className={classes.button1}>虧轉盈</Button>
        } else if (index === 3) {
            return <Button variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 110, color: BColor.downcolorbox }} className={classes.button1} >虧轉虧</Button>
        } else {
            return <Button variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 110, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    const renderee = (title, value, stockno) => {
        let color = BColor.faircolor
        if (value === -99999.0) {
            return <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        } else {
            return <Button onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {title}{value}元
        </Button>
        }
    }

    return (
        <div>
            <EPSList open={concentopen} handleClose={handleClosecc} stockId={openstock}></EPSList>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近六年 - 季EPS
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'負EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderNValue(props.row.eps1.ns, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'正YoY比例'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPValue(props.row.eps1.psp, 50, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'正QoQ比例'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPValue(props.row.eps1.psgr, 50, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均YoY'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPValue(props.row.eps1.savyoy, 50, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均QoQ'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPValue(props.row.eps1.savgr, 50, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.eps1.saveps}元
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近六年 - 年EPS
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'6'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'負EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderNValue(props.row.eps1.ny, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'7'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'正YoY比例'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPValue(props.row.eps1.pyp, 50, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'8'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均YoY'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPValue(props.row.eps1.yavyoy, 50, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'9'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.eps1.yaveps}元
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            最近一季/年
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                            <StyledTableRow key={'12'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近一季EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderee(props.row.eps1.st, props.row.eps1.nseps, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'13'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近一季YoY'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {rendereps1(props.row.eps1.nsyoyi, props.row.eps1.nsyoy, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'14'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近一季QoQ'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {rendereps1(props.row.eps1.nsgri, props.row.eps1.nsgr, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'15'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近四季EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.eps1.n4seps}元
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'18'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近四季YoY'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {rendereps1(props.row.eps1.s4yoyi, props.row.eps1.s4yoy, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'16'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近一年EPS'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderee(props.row.eps1.yt, props.row.eps1.nyeps, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'17'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近一年YoY'}
                                    </StyledTableCell>

                                    {props.row.eps1 != null && props.row.eps1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {rendereps1(props.row.eps1.nyyoyi, props.row.eps1.nyyoy, props.row.market_company.stock_no)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            預估EPS
                        </Typography>

                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small"  onClick={(e) => handleClose('')}>最新預估</Button>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={handleClick}>查舊年份預估</Button>

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={(e) => handleClose('2024')}>2024年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2023')}>2023年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2022')}>2022年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2021')}>2021年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2020')}>2020年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2019')}>2019年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2018')}>2018年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2017')}>2017年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2016')}>2016年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2015')}>2015年度</MenuItem>
                            <MenuItem onClick={(e) => handleClose('2015')}>2014年度</MenuItem>
                        </Menu>

                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption>預估公式：近一個月累積年增率 * 去年總營收 * 近四季稅後淨利率平均  / 普通股數<br />預估值會隨著每個月營收跟每一季稅後淨利率而調整</caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'去年EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'去年'}
                                    </StyledTableCell>

                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderLastYearEPS(props.row.eps)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年預估EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今年預估'}
                                    </StyledTableCell>

                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {renderR1LastYearEPS(props.row.eps)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年目前EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今年目前'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {renderThisYearEPS(props.row.eps)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'去年第一季'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第一季去年'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderEPS(props.row.eps.lys1)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第一季預估'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第一季預估'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {props.row.eps.r1 != undefined ? renderBaseEPS(props.row.eps.r1.s1mx, props.row.eps.lys1) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                        -
                                    </Button>}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第一季實際'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第一季今年'}
                                    </StyledTableCell>
                                    {
                                        props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                            {renderBaseEPS(props.row.eps.tys1, props.row.eps.lys1)}
                                        </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                                <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                            </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'去年第二季'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第二季去年'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderEPS(props.row.eps.lys2)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第二季預估'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第二季預估'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {props.row.eps.r1 != undefined ? renderBaseEPS(props.row.eps.r1.s2mx, props.row.eps.lys2) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                        -
                                    </Button>}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第二季實際'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第二季今年'}
                                    </StyledTableCell>
                                    {
                                        props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                            {renderBaseEPS(props.row.eps.tys2, props.row.eps.lys2)}
                                        </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                                <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                            </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'去年第三季'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第三季去年'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderEPS(props.row.eps.lys3)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第三季預估'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第三季預估'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {props.row.eps.r1 != undefined ? renderBaseEPS(props.row.eps.r1.s3mx, props.row.eps.lys3) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                        -
                                    </Button>}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第三季實際'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第三季今年'}
                                    </StyledTableCell>
                                    {
                                        props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                            {renderBaseEPS(props.row.eps.tys3, props.row.eps.lys3)}
                                        </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                                <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                            </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'去年第四季'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第四季去年'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderEPS(props.row.eps.lys4)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第四季預估'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第四季預估'}
                                    </StyledTableCell>
                                    {props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        {props.row.eps.r1 != undefined ? renderBaseEPS(props.row.eps.r1.s4mx, props.row.eps.lys4) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                        -
                                    </Button>}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                            -
                                                </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'今年第四季實際'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'第四季今年'}
                                    </StyledTableCell>
                                    {
                                        props.row.eps != null && props.row.eps != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                            {renderBaseEPS(props.row.eps.tys4, props.row.eps.lys4)}
                                        </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                                <Button variant="outlined" style={{  minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                </Button>
                                            </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div >
    );
}







// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
//         {renderEPS(row.eps.lys2)}
//     </TableCell> : null
// }
// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
//         {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s2mx, row.eps.lys2) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
//             -
// </Button>
//         }
//     </TableCell> : null
// }
// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
//         {renderBaseEPS(row.eps.tys2, row.eps.lys2)}
//     </TableCell> : null
// }
// {
//     row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
//         <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
//             -
// </Button>
//     </TableCell> : null
// }


// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
//         {renderEPS(row.eps.lys3)}
//     </TableCell> : null
// }
// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
//         {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s3mx, row.eps.lys3) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
//             -
// </Button>}
//     </TableCell> : null
// }
// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
//         {renderBaseEPS(row.eps.tys3, row.eps.lys3)}
//     </TableCell> : null
// }
// {
//     row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
//         <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
//             -
// </Button>
//     </TableCell> : null
// }



// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
//         {renderEPS(row.eps.lys4)}
//     </TableCell> : null
// }
// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
//         {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s4mx, row.eps.lys4) : <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
//             -
// </Button>}
//     </TableCell> : null
// }
// {
//     row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
//         {renderBaseEPS(row.eps.tys4, row.eps.lys4)}
//     </TableCell> : null
// }
// {
//     row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
//         <Button variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
//             -
// </Button>
//     </TableCell> : null
// }

// <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 8 }} align="left">
//     {renderButton1(row.market_company.stock_no)}
// </TableCell>