// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';

export default function StockAutocomplete(props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [aukey, setAuKey] = React.useState(false);

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const oninput = (event, value, reason) => {
    if ((isNumeric(value) && value.length > 1) || (!isNumeric(value) && value.length > 0)) {
      fetch("/jstock/v1/stock/searchcom?key=" + value)
        .then(res => res.json())
        .then(
          result => {
            setOptions(result);
          });
    }
  }

  const onclose = (event, reason) => {
    setOpen(false);
  }

  const onchange = (event, value) => {
    if (value != null) {
      props.menuChange(value.value)
      setAuKey(!aukey)
    }
  }
  // , backgroundColor: "#FFFFFF", color:"#000000" 

  return (
    <Autocomplete
      id="stock input"
      key={aukey}
      style={{
        width: '100%', backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF",
      }}
      open={open}
      onInputChange={oninput}
      onOpen={() => {
        setOpen(true);
      }}
      freeSolo
      autoHighlight
      onChange={onchange}
      onClose={onclose}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.label}
      options={options}
      // size = 'medium'
      noOptionsText=""
      renderInput={params => (
        <TextField
          {...params}
          size="medium"
          style={{minWidth:150}}
          fullWidth
          placeholder="股票代號或名稱"
          InputProps={{
            ...params.InputProps,
            style: {
              height: 40,
              width: '100%',
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ marginLeft: 5 }} />
              </InputAdornment>
            ),
          }}

        />
      )}
    />
  );
}
