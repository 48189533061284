import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getSynNo } from './util/Syno';

export default function RestoreDialog(props) {
  const [lic, setLic] = React.useState(null);

  const handleOk = () => {
    // console.log("lic:" + lic)
    let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
    let nowLic = localStorage.getItem('lic')
    if(nowLic != null) synno = nowLic

    if (lic != null) {
      let param = {
        lic: lic,
        synno: synno
      };

      fetch("/jstock/v1/stock/myloverule/copy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(param)
      })
        .then(res => res.json())
        .then(
          result => {
            props.handleClose()
          },

        );
    }

  };


  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">還原選股策略</DialogTitle>

      <DialogContent>
        <div style={{ fontSize: 12, color: '#b3b3b3' }}>
          停止訂閱後，可以輸入之前的序號將原本的選股策略還原進來。<br /><br />
        </div>

        <TextField
          autoFocus
          margin="dense"
          id="y"
          label="序號"
          type="text"
          fullWidth
          onChange={(event) => setLic(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          取消
        </Button>
        <Button onClick={handleOk} color="primary">
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
