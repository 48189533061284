import React, { useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart, CandlestickChart } from 'echarts/charts';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MACDList from '../details/MACDList';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'


import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import AVSetting from '../mobile/AVSetting'
import { it } from 'react-date-range/dist/locale';

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, BarChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function KD(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);
    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [opentp, setOpenTp] = React.useState(0);

    const [startDraw, setStartDraw] = React.useState(false);
    const [tp, setTp] = React.useState(0)
    const lines = React.useRef([]);
    const mouseAction = React.useRef(null);


    const zStart = useRef(80);
    const zEnd = useRef(100);

    let redcolor = "#ed5f84"
    let backcolor = "#FFFFFF"
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox
    let greencolor = "#53a66f"

    const handleClickOpencc = (stock_no, stockname, tp) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockname)
        setOpenTp(tp)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setOpenTp(0)
        setConcentOpen(false);
    };


    const renderDirection = (value) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            return <Button variant="outlined" style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                向上
            </Button>
        } else if (value === 1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                向下
            </Button>
        } else if (value === 2) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                平整
            </Button>
        } else if (value === -1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderPas = (value) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            return <Button variant="outlined" style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                高檔鈍化
            </Button>
        } else if (value === 1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                低檔鈍化
            </Button>
        } else if (value === 2) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                無鈍化
            </Button>
        } else if (value === -1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderCross = (value) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            return <Button variant="outlined" style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                黃金交叉
            </Button>
        } else if (value === 1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                死亡交叉
            </Button>
        } else if (value === 2) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                無交叉
            </Button>
        } else if (value === -1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        }
    }


    const renderPasDay = (row) => {
        let color = BColor.nodatacolor

        if (row.pashday > 5) {
            return <Button variant="outlined" style={{ minWidth: 110, backgroundColor: redback, color: "#FFFFFF" }} className={classes.button2} >
                高檔鈍化{row.pashday}次
            </Button>
        } else if (row.paslday > 5) {
            return <Button variant="outlined" style={{ minWidth: 110, backgroundColor: greenback, color: "#FFFFFF" }} className={classes.button2} >
                低檔鈍化{row.paslday}次
            </Button>
        } else if (row.pashday > 0 && row.paslday > 0) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                高鈍{row.pashday}次 低鈍{row.paslday}次
            </Button>
        } else if (row.pashday > 0) {
            return <Button variant="outlined" style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                高檔鈍化{row.pashday}次
            </Button>
        } else if (row.paslday > 0) {
            return <Button variant="outlined" style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                低檔鈍化{row.paslday}次
            </Button>
        } else if (row.pashday === -1) {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                無鈍化
            </Button>
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        props.bigupdate()
        setOpen(false);
    };


    function getXX(tp) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                return vv.x
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                return vv.x
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                return vv.x
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                return vv.x
            })
            return data
        }
    }



    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1(tp) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                return vv.y
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                return vv.y
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                return vv.y
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                return vv.y
            })
            return data
        }
    }

    function getYY2(tp) {
        if (tp === 0) {
            let data = []
            for (var i = 0; i < props.row.kd3.d.length; i++) {
                data.push([i, props.row.kd3.d[i].v, props.row.kd3.d[i].i]);
            }
            return data
        } else if (tp === 1) {
            let data = []
            for (var i = 0; i < props.row.kd3.w.length; i++) {
                data.push([i, props.row.kd3.w[i].v, props.row.kd3.w[i].i]);
            }
            return data
        } else if (tp === 2) {
            let data = []
            for (var i = 0; i < props.row.kd3.m.length; i++) {
                data.push([i, props.row.kd3.m[i].v, props.row.kd3.m[i].i]);
            }
            return data
        } else {
            let data = []
            for (var i = 0; i < props.row.kd3.d.length; i++) {
                data.push([i, props.row.kd3.d[i].v, props.row.kd3.d[i].i]);
            }
            return data
        }
    }

    function getMAV(vv, line) {
        if (line === 1)
            return vv.a1
        else if (line === 2)
            return vv.a2
        else if (line === 3)
            return vv.a3
        else if (line === 4)
            return vv.a4
        else if (line === 5)
            return vv.a5
        else return vv.a1
    }

    function getMA(tp, line) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        }
    }

    function getKD(tp, line, count) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                if (vv.kd != null) {
                    if (line === 1)
                        return vv.kd.k
                    else if (line === 2)
                        return vv.kd.d
                    else return vv.kd.k
                } else return 0
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                if (vv.kd != null) {
                    if (line === 1)
                        return vv.kd.k
                    else if (line === 2)
                        return vv.kd.d
                    else return vv.kd.k
                } else return 0
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                if (vv.kd != null) {
                    if (line === 1)
                        return vv.kd.k
                    else if (line === 2)
                        return vv.kd.d
                    else return vv.kd.k
                } else return 0
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                if (vv.kd != null) {
                    if (line === 1)
                        return vv.kd.k
                    else if (line === 2)
                        return vv.kd.d
                    else return vv.kd.k
                } else return 0
            })
            return data
        }
    }

    function getMACD(tp, line, count) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                if (vv.macd != null) {
                    if (line === 1)
                        return vv.macd.macd
                    else if (line === 2)
                        return vv.macd.diff
                    else return vv.macd.macd
                } else return 0
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                if (vv.macd != null) {
                    if (line === 1)
                        return vv.macd.macd
                    else if (line === 2)
                        return vv.macd.diff
                    else return vv.macd.macd
                } else return 0
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                if (vv.macd != null) {
                    if (line === 1)
                        return vv.macd.macd
                    else if (line === 2)
                        return vv.macd.diff
                    else return vv.macd.macd
                } else return 0
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                if (vv.macd != null) {
                    if (line === 1)
                        return vv.macd.macd
                    else if (line === 2)
                        return vv.macd.diff
                    else return vv.macd.macd
                } else return 0
            })
            return data
        }
    }

    function getPSY(tp, line) {
        if (props.row.kd3 != null) {
            if (tp === 0) {
                let data = props.row.kd3.d.map(vv => {
                    if (line === 1 && vv.psys != null) {
                        return vv.psys
                    } else if (line === 2 && vv.psyl != null) {
                        return vv.psyl
                    } else return 0
                })
                return data
            } else if (tp === 1) {
                let data = props.row.kd3.w.map(vv => {
                    if (line === 1 && vv.psys != null) {
                        return vv.psys
                    } else if (line === 2 && vv.psyl != null) {
                        return vv.psyl
                    } else return 0
                })
                return data
            } else if (tp === 2) {
                let data = props.row.kd3.m.map(vv => {
                    if (line === 1 && vv.psys != null) {
                        return vv.psys
                    } else if (line === 2 && vv.psyl != null) {
                        return vv.psyl
                    } else return 0
                })
                return data
            } else {
                let data = props.row.kd3.d.map(vv => {
                    if (line === 1 && vv.psys != null) {
                        return vv.psys
                    } else if (line === 2 && vv.psyl != null) {
                        return vv.psyl
                    } else return 0
                })
                return data
            }
        } else return []
    }

    function getOBV() {
        if (props.row.kd3 != null) {

            if (tp === 0) {
                let data = props.row.kd3.d.map(vv => {
                    return vv.obv
                })
                return data
            } else if (tp === 1) {
                let data = props.row.kd3.w.map(vv => {
                    return vv.obv
                })
                return data
            } else if (tp === 2) {
                let data = props.row.kd3.m.map(vv => {
                    return vv.obv
                })
                return data
            } else {
                let data = props.row.kd3.d.map(vv => {
                    return vv.obv
                })
                return data
            }
        } else return []
    }

    function getYY3(tp, count) {
        if (tp === 0) {
            let data = []
            let data1 = props.row.kd3.d
            for (var i = 0; i < data1.length; i++) {
                if (data1[i].macd != null) {
                    let k = 0
                    if (data1[i].macd.osc > 0) k = 1
                    else if (data1[i].macd.osc < 0) k = -1
                    data.push([i, data1[i].macd.osc, k]);
                } else data.push([i, 0, 0]);
            }
            return data
        } else if (tp === 1) {
            let data = []
            let data1 = props.row.kd3.w
            for (var i = 0; i < data1.length; i++) {
                if (data1[i].macd != null) {
                    let k = 0
                    if (data1[i].macd.osc > 0) k = 1
                    else if (data1[i].macd.osc < 0) k = -1
                    data.push([i, data1[i].macd.osc, k]);
                } else data.push([i, 0, 0]);
            }
            return data
        } else if (tp === 2) {
            let data = []
            let data1 = props.row.kd3.m
            for (var i = 0; i < data1.length; i++) {
                if (data1[i].macd != null) {
                    let k = 0
                    if (data1[i].macd.osc > 0) k = 1
                    else if (data1[i].macd.osc < 0) k = -1
                    data.push([i, data1[i].macd.osc, k]);
                } else data.push([i, 0, 0]);
            }
            return data
        } else {
            let data = []
            let data1 = props.row.kd3.d
            for (var i = 0; i < data1.length; i++) {
                if (data1[i].macd != null) {
                    let k = 0
                    if (data1[i].macd.osc > 0) k = 1
                    else if (data1[i].macd.osc < 0) k = -1
                    data.push([i, data1[i].macd.osc, k]);
                } else data.push([i, 0, 0]);
            }
            return data
        }
    }

    const getK = () => {
        if (props.row.kd != null) {
            if (tp === 0 && props.row.kd.d != null) return props.row.kd.d.k
            else if (tp == 1 && props.row.kd.w != null) return props.row.kd.w.k
            else if (tp == 2 && props.row.kd.m != null) return props.row.kd.m.k
            else return 0
        } else return 0
    }

    const getD = () => {
        if (props.row.kd != null) {
            if (tp === 0 && props.row.kd.d != null) return props.row.kd.d.d
            else if (tp == 1 && props.row.kd.w != null) return props.row.kd.w.d
            else if (tp == 2 && props.row.kd.m != null) return props.row.kd.m.d
            else return 0
        } else return 0
    }

    const getDIF = () => {
        if (props.row.macd != null) {
            if (tp === 0 && props.row.macd.d != null) return props.row.macd.d.diff
            else if (tp === 1 && props.row.macd.w != null) return props.row.macd.w.diff
            else if (tp === 2 && props.row.macd.m != null) return props.row.macd.m.diff
            else return 0
        } else return 0
    }

    const getMACD1 = () => {
        if (props.row.macd != null) {
            if (tp === 0 && props.row.macd.d != null) return props.row.macd.d.macd
            else if (tp === 1  && props.row.macd.w != null) return props.row.macd.w.macd
            else if (tp === 2 && props.row.macd.m != null) return props.row.macd.m.macd
            else return 0
        } else return 0
    }

    function getDefaultOption() {
        let option = {
            animation: false,
            legend: {
                textStyle: {
                    fontSize: 10
                },
                show: true
            },
            title: [
                {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'bold'
                    },
                    top: '400',
                    left: 'left',
                    text: 'KD     K：' + getK() + "     D：" + getD(),
                }, {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'bold'
                    },
                    top: '610',
                    left: 'left',
                    text: 'MACD     DIF：' + getDIF() + "     MACD：" + getMACD1()
                }, {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'bold'
                    },
                    top: '820',
                    left: 'left',
                    text: 'PSY'
                }, {
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'bold'
                    },
                    top: '1030',
                    left: 'left',
                    text: 'OBV'
                }

            ],
            grid: [
                {
                    top: '40',
                    left: '10',
                    right: '10',
                    height: '220',
                    borderWidth: 0,
                    show: true
                }, {
                    top: '300',
                    left: '10',
                    right: '10',
                    height: '80',
                    borderWidth: 0,
                    show: true
                }, {
                    top: '430',
                    left: '10',
                    right: '10',
                    height: '150',
                    borderWidth: 0,
                    show: true
                }, {
                    top: '640',
                    left: '10',
                    right: '10',
                    height: '150',
                    borderWidth: 0,
                    show: true
                }, {
                    top: '850',
                    left: '10',
                    right: '10',
                    height: '150',
                    borderWidth: 0,
                    show: true
                }, {
                    top: '1060',
                    left: '10',
                    right: '10',
                    height: '150',
                    borderWidth: 0,
                    show: true
                }
            ],
            visualMap: [{
                show: false,
                dimension: 2,
                seriesIndex: 4,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            }, {
                show: false,
                dimension: 2,
                seriesIndex: 9,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            }],
        };


        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip(550)
            option['axisPointer'] = getAxisPointer()
            if (startDraw) option['dataZoom'] = getMobileDataZoom(zStart.current, zEnd.current, true)
            else option['dataZoom'] = getMobileDataZoom(zStart.current, zEnd.current)

        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
            if (startDraw) option['dataZoom'] = getComputerDataZoom(zStart.current, zEnd.current, 1110, true)
            else option['dataZoom'] = getComputerDataZoom(zStart.current, zEnd.current, 1260)
        }

        return option
    }


    function genoption2() {
        let t = tp
        let k = getDefaultOption()

        let ks = '日K'
        if (t === 0) ks = '日K'
        else if (t === 1) ks = '周K'
        else if (t === 2) ks = '月K'

        let av1 = localStorage.getItem('av1') === null ? 'MA5' : 'MA' + localStorage.getItem('av1')
        let av2 = localStorage.getItem('av2') === null ? 'MA20' : 'MA' + localStorage.getItem('av2')
        let av3 = localStorage.getItem('av3') === null ? 'MA60' : 'MA' + localStorage.getItem('av3')
        let av4 = localStorage.getItem('av4') === null ? 'MA120' : 'MA' + localStorage.getItem('av4')
        let av5 = localStorage.getItem('av5') === null ? 'MA200' : 'MA' + localStorage.getItem('av5')

        let av1i = localStorage.getItem('av1i') === null ? true : localStorage.getItem('av1i').toLowerCase() === 'true'
        let av2i = localStorage.getItem('av2i') === null ? true : localStorage.getItem('av2i').toLowerCase() === 'true'
        let av3i = localStorage.getItem('av3i') === null ? true : localStorage.getItem('av3i').toLowerCase() === 'true'
        let av4i = localStorage.getItem('av4i') === null ? false : localStorage.getItem('av4i').toLowerCase() === 'true'
        let av5i = localStorage.getItem('av5i') === null ? false : localStorage.getItem('av5i').toLowerCase() === 'true'
        // let kdkvi = localStorage.getItem('kdkvi') === null ? true : localStorage.getItem('kdkvi').toLowerCase() === 'true'
        // let kddvi = localStorage.getItem('kddvi') === null ? true : localStorage.getItem('kddvi').toLowerCase() === 'true'
        // let macd1 = localStorage.getItem('macd1') === null ? true : localStorage.getItem('macd1').toLowerCase() === 'true'
        // let macd2 = localStorage.getItem('macd2') === null ? true : localStorage.getItem('macd2').toLowerCase() === 'true'
        // let macd3 = localStorage.getItem('macd3') === null ? true : localStorage.getItem('macd3').toLowerCase() === 'true'

        k['legend']['data'] = [av1, av2, av3, av4, av5]
        let selectedo = {}
        selectedo[av1] = av1i
        selectedo[av2] = av2i
        selectedo[av3] = av3i
        selectedo[av4] = av4i
        selectedo[av5] = av5i
        selectedo["K"] = true
        selectedo["D"] = true
        selectedo["OSC"] = true
        selectedo["MACD"] = true
        selectedo["DIF"] = true
        selectedo["PSY短天期"] = true
        selectedo["PSY長天期"] = true

        k['legend']['selected'] = selectedo

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(t),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax'
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }
        }

        let x2 = {
            type: 'category',
            gridIndex: 1,
            data: getXX(t),
            scale: true,
            boundaryGap: true,
            min: 'dataMin',
            max: 'dataMax',
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            splitNumber: 20,
        }


        let x3 = {
            type: 'category',
            data: getXX(t),
            gridIndex: 2,
            scale: true,
            boundaryGap: true,
            splitNumber: 20,
        }

        let x4 = {
            type: 'category',
            data: getXX(t),
            gridIndex: 3,
            scale: true,
            boundaryGap: true,
            splitNumber: 20,
        }

        let x5 = {
            type: 'category',
            data: getXX(t),
            gridIndex: 4,
            scale: true,
            boundaryGap: true,
            splitNumber: 20,
        }

        let x6 = {
            type: 'category',
            data: getXX(t),
            gridIndex: 5,
            scale: true,
            boundaryGap: true,
        }

        if (props.ismobile) {
            let vv1 = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x2['axisPointer'] = vv1
            x3['axisPointer'] = vv1
            x4['axisPointer'] = vv1
            x5['axisPointer'] = vv1
            x6['axisPointer'] = vv1
        }

        k['xAxis'] = [x1, x2, x3, x4, x5, x6]

        let yAxis = [{
            scale: true,
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }, {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            show: true,
            position: 'right',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
        }, {
            gridIndex: 2,
            scale: true,
            // splitArea: {
            //     show: true
            // },
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }, {
            gridIndex: 3,
            scale: true,
            // splitArea: {
            //     show: true
            // },
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }, {
            gridIndex: 4,
            scale: true,
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }, {
            gridIndex: 5,
            scale: true,
            show: true,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        }]
        k['yAxis'] = yAxis

        let markdata = []
        if (lines != null && lines.current.length > 0) {
            lines.current.map(l => {
                if (l != null && l.length == 4)
                    markdata.push([
                        { x: l[0], y: l[1] },
                        { x: l[2], y: l[3] }
                    ])
            })
        }

        let serdata = [
            {
                name: ks,
                type: 'candlestick',
                data: getYY1(t),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markLine: {
                    lineStyle: {
                        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF",
                        type: 'solid'
                    },
                    symbol: ['none', 'none'],
                    data: markdata
                },
            },
            {
                name: av1,
                type: 'line',
                data: getMA(t, 1),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: av2,
                type: 'line',
                data: getMA(t, 2),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: av3,
                type: 'line',
                data: getMA(t, 3),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: '成交量',
                type: 'bar',
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: getYY2(t)
            },
            {
                name: av4,
                type: 'line',
                data: getMA(t, 4),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            }, {
                name: av5,
                type: 'line',
                data: getMA(t, 5),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "K",
                type: 'line',
                xAxisIndex: 2,
                yAxisIndex: 2,
                data: getKD(t, 1, 60),
                smooth: true,
                lineStyle: {
                    // color: "#e68225"
                },
                showSymbol: false,
                animation: false,
                // labelLayout: {
                //     moveOverlap: 'shiftY'
                // },
                // endLabel: {
                //     show: true,
                //     backgroundColor: "#e68225",
                //     color: "#FFFFFF",
                //     padding: 4,
                //     fontSize: 10,
                // },
                markLine: {
                    label: {
                        show: false
                    },
                    symbol: 'none',
                    data: [
                        {
                            name: '80',
                            yAxis: 80,
                            lineStyle: {
                                color: "#f04d4d",
                                width: 2,
                            }
                        },
                        {
                            name: '20',
                            yAxis: 20,
                            lineStyle: {
                                color: "#33ba30",
                                width: 2,
                            }
                        }
                    ]
                }
            },
            {
                name: "D",
                type: 'line',
                xAxisIndex: 2,
                yAxisIndex: 2,
                data: getKD(t, 2, 60),
                smooth: true,
                lineStyle: {
                    // color: "#9036eb"
                },
                showSymbol: false,
                animation: false,
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                // endLabel: {
                //     show: true,
                //     backgroundColor: "#9036eb",
                //     color: "#FFFFFF",
                //     padding: 4,
                //     fontSize: 10,
                // }
            },
            {
                name: "OSC",
                type: 'bar',
                data: getYY3(t, 60),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false,
                xAxisIndex: 3,
                yAxisIndex: 3,
            },
            {
                name: "MACD",
                type: 'line',
                data: getMACD(t, 1, 60),
                smooth: true,
                lineStyle: {
                    // color: "#46469c",
                },
                showSymbol: false,
                animation: false,
                xAxisIndex: 3,
                yAxisIndex: 3,
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                // endLabel: {
                //     show: true,
                //     backgroundColor: "#46469c",
                //     color: "#FFFFFF",
                //     padding: 4,
                //     fontSize: 10,
                // },
            },
            {
                name: "DIF",
                type: 'line',
                data: getMACD(t, 2, 60),
                smooth: true,
                showSymbol: false,
                animation: false,
                xAxisIndex: 3,
                yAxisIndex: 3,
                lineStyle: {
                    // color: "#53a657"
                },
                // labelLayout: {
                //     moveOverlap: 'shiftY'
                // },
                // endLabel: {
                //     show: true,
                //     backgroundColor: "#53a657",
                //     color: "#FFFFFF",
                //     padding: 4,
                //     fontSize: 10,
                // },
            }, {
                name: "PSY短天期",
                type: 'line',
                xAxisIndex: 4,
                yAxisIndex: 4,
                data: getPSY(t, 1),
                // smooth: true,
                // lineStyle: {
                //     color: "#e68225"
                // },
                showSymbol: false,
                animation: false,
                labelLayout: {
                    moveOverlap: 'shiftY'
                },
                // endLabel: {
                //     show: true,
                //     backgroundColor: "#ff9021",
                //     color: "#FFFFFF",
                //     padding: 4,
                //     fontSize: 10,
                // },
                markLine: {
                    label: {
                        show: false
                    },
                    symbol: 'none',
                    data: [
                        {
                            name: '80',
                            yAxis: 80,
                            lineStyle: {
                                color: "#f04d4d",
                                width: 2,
                            }
                        },
                        {
                            name: '20',
                            yAxis: 20,
                            lineStyle: {
                                color: "#33ba30",
                                width: 2,
                            }
                        }
                    ]
                }
            },
            {
                name: "PSY長天期",
                type: 'line',
                xAxisIndex: 4,
                yAxisIndex: 4,
                data: getPSY(t, 2),
                showSymbol: false,
                animation: false,
            },
            {
                name: "OBV",
                type: 'line',
                xAxisIndex: 5,
                yAxisIndex: 5,
                data: getOBV(),
                showSymbol: false,
                animation: false,
            }
        ]

        k['series'] = serdata
        setOptions(k)
    }


    const handleTp = (t) => {
        setTp(t)
    }

    useEffect(() => {
        if (props.row.kd3 != null) {
            genoption2()
        }
    }, [props.row.kd3, tp, startDraw]);

    const onChartLegendselectchanged = (event) => {
        let av1 = localStorage.getItem('av1') === null ? 'MA5' : 'MA' + localStorage.getItem('av1')
        let av2 = localStorage.getItem('av2') === null ? 'MA20' : 'MA' + localStorage.getItem('av2')
        let av3 = localStorage.getItem('av3') === null ? 'MA60' : 'MA' + localStorage.getItem('av3')
        let av4 = localStorage.getItem('av4') === null ? 'MA120' : 'MA' + localStorage.getItem('av4')
        let av5 = localStorage.getItem('av5') === null ? 'MA200' : 'MA' + localStorage.getItem('av5')

        let av1v = event.selected[av1]
        let av2v = event.selected[av2]
        let av3v = event.selected[av3]
        let av4v = event.selected[av4]
        let av5v = event.selected[av5]

        if (av1v != null && av1v) {
            localStorage.setItem('av1i', true)
        } else localStorage.setItem('av1i', false)

        if (av2v != null && av2v) {
            localStorage.setItem('av2i', true)
        } else localStorage.setItem('av2i', false)

        if (av3v != null && av3v) {
            localStorage.setItem('av3i', true)
        } else localStorage.setItem('av3i', false)

        if (av4v != null && av4v) {
            localStorage.setItem('av4i', true)
        } else localStorage.setItem('av4i', false)

        if (av5v != null && av5v) {
            localStorage.setItem('av5i', true)
        } else localStorage.setItem('av5i', false)
    }

    const onRoomClient = (event) => {
        if (event != null && event.batch != null &&
            event.batch.length > 0 && event.batch[0].start != undefined &&
            event.batch[0].end != undefined) {
            zStart.current = event.batch[0].start
            zEnd.current = event.batch[0].end
        } else if (event != null && event.start != undefined &&
            event.end != undefined) {
            zStart.current = event.start
            zEnd.current = event.end
        }
    }

    const onEvents = {
        'datazoom': onRoomClient,
        'legendselectchanged': onChartLegendselectchanged
    }


    const renderLinP = (value, tp, row) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                快慢線穿越0軸
            </Button>
        } else if (value === 1) {
            color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                快慢線跌破0軸
            </Button>
        } else if (value === 2) {
            color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                快慢線0軸上
            </Button>
        } else if (value === 3) {
            color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                快慢線0軸下
            </Button>
        } else if (value === 4) {
            return <div><Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button2} >
                快線0軸上
            </Button><br /><Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.downcolorbox, marginTop: 1 }} className={classes.button2} >
                    慢線0軸下
                </Button></div>
        } else if (value === 5) {
            return <div><Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button2} >
                慢線0軸上
            </Button><br /><Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.downcolorbox, marginTop: 1 }} className={classes.button2} >
                    快線0軸下
                </Button></div>
        } else if (value === -1) {
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderCrossM = (value, tp, row) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                金叉
            </Button>
        } else if (value === 1) {
            color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                死叉
            </Button>
        } else if (value === 2) {
            color = BColor.nodatacolor
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                無交叉
            </Button>
        } else if (value === -1) {
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderOSCS = (value, tp, row) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            // color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: "#FFFFFF", backgroundColor: redback }} className={classes.button2} >
                由綠翻紅
            </Button>
        } else if (value === 1) {
            // color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: "#FFFFFF", backgroundColor: greenback }} className={classes.button2} >
                由紅轉綠
            </Button>
        } else if (value === 2) {
            color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                紅柱逐漸變大
            </Button>
        } else if (value === 3) {
            color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                紅柱逐漸變小
            </Button>
        } else if (value === 4) {
            color = BColor.upcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                持續紅柱
            </Button>
        } else if (value === 5) {
            color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                綠柱逐漸變大
            </Button>
        } else if (value === 6) {
            color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                綠柱逐漸變小
            </Button>
        } else if (value === 7) {
            color = BColor.downcolorbox
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                維持綠柱
            </Button>
        } else if (value === -1) {
            return <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
            </Button>
        }
    }

    const getVariant = (t) => {
        if (tp === t) return "contained"
        else return "outlined"
    }


    const chartReady = (event) => {
        // let zr = event.getZr()
        // zr.on('mousedown', onMouseDownClick)
        // zr.on('mouseup', onMouseUpClick)
        // zr.on('mousemove', onMouseMoveClick)
    }


    return (
        <div>
            {open1 ? <AVSetting open={open1} handleClose={handleClose} handleOk={handleOk}></AVSetting> : null}
            {concentopen ? <MACDList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockname={opensname} tp={opentp}></MACDList> : null}

            <ButtonGroup color="primary" size="small" disableElevation >
                <Button variant={getVariant(0)} onClick={(e) => handleTp(0)}>日K</Button>
                <Button variant={getVariant(1)} onClick={(e) => handleTp(1)}>周K</Button>
                <Button variant={getVariant(2)} onClick={(e) => handleTp(2)}>月K</Button>
                <Button onClick={(e) => handleOpen()}>均線</Button>
                {/* <Button disabled={!isBuy()} variant={startDraw ? "contained" : "outlined"} onClick={(e) => clickDraw()}>畫線</Button>
                <Button disabled={!isBuy()} variant="outlined" onClick={(e) => clearDraw()}>清除</Button> */}
            </ButtonGroup>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >

                    {options != null && !startDraw ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 1250, marginTop: 5 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        onEvents={onEvents}
                    /> : null}

                    {options != null && startDraw ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 1250, marginTop: 5 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        onChartReady={chartReady}
                    /> : null}

                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            日KD
                        </Typography>
                    </div>

                    {props.row.kd != null ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'K值'}
                                    </StyledTableCell>

                                    {props.row.kd.d != null && props.row.kd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.kd.d.k}
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'D值'}
                                    </StyledTableCell>

                                    {props.row.kd.d != null && props.row.kd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">

                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.kd.d.d}
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'方向'}
                                    </StyledTableCell>

                                    {props.row.kd.d != null && props.row.kd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderDirection(props.row.kd.d.dir)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今日鈍化'}
                                    </StyledTableCell>

                                    {props.row.kd.d != null && props.row.kd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPas(props.row.kd.d.pas)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近十日鈍化次數'}
                                    </StyledTableCell>

                                    {props.row.kd.d != null && props.row.kd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPasDay(props.row.kd.d)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今日交叉'}
                                    </StyledTableCell>

                                    {props.row.kd.d != null && props.row.kd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderCross(props.row.kd.d.corss)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            日MACD
                        </Typography>
                    </div>

                    {props.row.macd != null ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'日期'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.macd.d.ymd}
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EMA（短）'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.d.ema12}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EMA（長）'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.d.ema26}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'DIF'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.d.diff}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'MACD'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.d.macd}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'快慢線位置'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderLinP(props.row.macd.d.linp, 0, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'51'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'快慢線交叉'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderCrossM(props.row.macd.d.cross, 0, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'52'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'OSC'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.d.osc}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'53'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'柱體狀態'}
                                    </StyledTableCell>

                                    {props.row.macd.d != null && props.row.macd.d != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderOSCS(props.row.macd.d.oscs, 0, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            週KD
                        </Typography>
                    </div>

                    {props.row.kd != null ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'6'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'K值'}
                                    </StyledTableCell>

                                    {props.row.kd.w != null && props.row.kd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.kd.w.k}
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'7'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'D值'}
                                    </StyledTableCell>

                                    {props.row.kd.w != null && props.row.kd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">

                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.kd.w.d}
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'8'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'方向'}
                                    </StyledTableCell>

                                    {props.row.kd.w != null && props.row.kd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderDirection(props.row.kd.w.dir)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'9'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今日鈍化'}
                                    </StyledTableCell>

                                    {props.row.kd.w != null && props.row.kd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPas(props.row.kd.w.pas)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'10'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近十日鈍化次數'}
                                    </StyledTableCell>

                                    {props.row.kd.w != null && props.row.kd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPasDay(props.row.kd.w)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'11'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今日交叉'}
                                    </StyledTableCell>

                                    {props.row.kd.w != null && props.row.kd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderCross(props.row.kd.w.corss)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                </Grid>







                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            週MACD
                        </Typography>
                    </div>

                    {props.row.macd != null ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'1w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EMA（短'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">


                                        {props.row.macd.w.ema12}

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EMA（長）'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.w.ema26}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'DIF'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.w.diff}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'MACD'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.w.macd}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'快慢線位置'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderLinP(props.row.macd.w.linp, 1, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'51w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'快慢線交叉'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderCrossM(props.row.macd.w.cross, 1, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'52w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'OSC'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.w.osc}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'53w'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'柱體狀態'}
                                    </StyledTableCell>

                                    {props.row.macd.w != null && props.row.macd.w != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderOSCS(props.row.macd.w.oscs, 1, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            月KD
                        </Typography>
                    </div>

                    {props.row.kd != null ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'12'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'K值'}
                                    </StyledTableCell>

                                    {props.row.kd.m != null && props.row.kd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.kd.m.k}
                                        </Button>

                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'13'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'D值'}
                                    </StyledTableCell>

                                    {props.row.kd.m != null && props.row.kd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">

                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            {props.row.kd.m.d}
                                        </Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'14'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'方向'}
                                    </StyledTableCell>

                                    {props.row.kd.m != null && props.row.kd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderDirection(props.row.kd.m.dir)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'15'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今日鈍化'}
                                    </StyledTableCell>

                                    {props.row.kd.m != null && props.row.kd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPas(props.row.kd.m.pas)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'16'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近十日鈍化次數'}
                                    </StyledTableCell>

                                    {props.row.kd.m != null && props.row.kd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderPasDay(props.row.kd.m)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'17'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'今日交叉'}
                                    </StyledTableCell>

                                    {props.row.kd.m != null && props.row.kd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderCross(props.row.kd.m.corss)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            月MACD
                        </Typography>
                    </div>

                    {props.row.macd != null ? <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'1wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EMA（短）'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.m.ema12}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'2wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'EMA（長）'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.m.ema26}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'3wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'DIF'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.m.diff}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'4wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'MACD'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.m.macd}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'5wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'快慢線位置'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderLinP(props.row.macd.m.linp, 2, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'51wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'快慢線交叉'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderCrossM(props.row.macd.m.cross, 2, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'52wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'OSC'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {props.row.macd.m.osc}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'53wm'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'柱體狀態'}
                                    </StyledTableCell>

                                    {props.row.macd.m != null && props.row.macd.m != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        {renderOSCS(props.row.macd.m.oscs, 2, props.row)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                        <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button2} >
                                            -
                                        </Button>
                                    </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer> : null}
                </Grid>

            </Grid>

        </div >
    );
}