import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function KTwoChart1(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [options, setOptions] = React.useState(null)


    function getAnnoValue(xvalue, yvalue, title, color) {
        let offsetX = 0

        return {
            symbol: 'pin',
            symbolSize: 15,
            coord: [xvalue, yvalue],
            value: title,
            label: {
                show: true,
                backgroundColor: color,
                padding: 3,
                offset: [offsetX, -40],
            }
        }
    }

    function genData1(data) {
        let option = {
            legend: {
                data: ['5日均線', "10日均線", '20日均線']
            },
            grid: [
                {
                    top: '20',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }, {
                    top: '370',
                    left: '50',
                    right: '30',
                    height: '100',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }, {
                    top: '500',
                    left: '50',
                    right: '30',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            visualMap: {
                show: false,
                dimension: 2,
                seriesIndex: 1,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            },
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    scale: true,
                    gridIndex: 1,
                    splitNumber: 2,
                    axisLabel: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false }
                },
                {
                    gridIndex: 2,
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
            ]
        };


        let x1 = {
            type: 'category',
            data: data.map(v => { return v.x })
        }

        let x2 = {
            gridIndex: 1,
            type: 'category',
            data: data.map(v => { return v.x })
        }


        let x3 = {
            gridIndex: 2,
            type: 'category',
            data: data.map(v => { return v.x })
        }


        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
            x2['axisPointer'] = xp
            x3['axisPointer'] = xp
        }

        option['xAxis'] = [x1, x2, x3]

        let yax = []
        if (props.row.bprice != null && props.row.bprice != '' && props.row.bymd1 != null) {
            yax.push(getAnnoValue(props.row.bymd1, props.row.bprice, "買進", "#FF4560"))
        }

        if (props.row.sprice != null && props.row.sprice != '' && props.row.symd1 != null) {
            yax.push(getAnnoValue(props.row.symd1, props.row.sprice, "賣出", "#FF4560"))
        }

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: data.map(vv => { return vv.y }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markPoint: {
                    data: yax
                },
            },
            {
                name: '成交量',
                type: 'bar',
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: data.map(vv => { return [vv.x, vv.v, vv.i] }),
            },
            {
                name: "5日均線",
                type: 'line',
                data: data.map(vv => { return vv.av5 }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "10日均線",
                type: 'line',
                data: data.map(vv => { return vv.av10 }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "20日均線",
                type: 'line',
                data: data.map(vv => { return vv.av20 }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: "加權指數",
                type: 'line',
                data: data.map(vv => { return vv.tpex }),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false,
                xAxisIndex: 2,
                yAxisIndex: 2,
            }
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }

        return option
    }



    useEffect(() => {
        if (props.row != null && props.open) getKTwoList()
    }, [props.row, props.open]);


    function getKTwoList() {
        let lic = localStorage.getItem('lic')

        if (lic != null && props.row != null) {
            let et = props.row.symd1

            let a = {
                lic: lic,
                stock: props.row.sid,
                st: props.row.bymd1,
            }

            if (props.row.symd1) a['et'] = props.row.symd1
            else if (props.row.nymd1) a['et'] = props.row.nymd1

            fetch("/jstock/v1/stock/backtest/twok1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setOptions(genData1(result))
                    }
                );
        }
    }


    function getString() {
        return "無法找到對應的K線圖。"
    }

    function closea() {
        setOptions(null)
        props.handleClose()
    }

    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose} >
            <DialogTitle id="form-dialog-title">
                回測K線圖

            </DialogTitle>

            <DialogContent style={{ paddingRight: 0, paddingLeft: 0 }}>
                {localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1" ?
                    <div>
                        <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 25 }}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                {props.row.name}({props.row.sid})
                            </Typography>
                            {props.enPrex ? <KeyboardArrowLeftIcon color="primary" style={{color: "#707070"}} fontSize="large" onClick={props.handlePrex} /> :
                                <KeyboardArrowLeftIcon color="disabled" fontSize="large"  />}

                            {props.enNext ? <KeyboardArrowRightIcon color="primary" style={{color: "#707070"}} fontSize="large" onClick={props.handleNext} /> :
                                <KeyboardArrowRightIcon color="disabled" fontSize="large"  />}


                        </div>
                        {options != null ? <ReactEChartsCore
                            theme={theme.palette.type}
                            style={{ height: 800, marginTop: 0 }}
                            echarts={echarts}
                            option={options}
                            notMerge={true}
                            lazyUpdate={false}
                        /> : <div style={{}}>{getString()}</div>}
                    </div> : <div>目前僅開放給訂閱者。</div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={e => closea()} color="primary">
                    關閉
                </Button>
            </DialogActions>
        </Dialog>



    );
}