import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';

import BBSetting from './BBSetting';
import Typography from '@material-ui/core/Typography';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'

import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'


function desc(a, b, orderBy) {
    if (orderBy === 'pps' || orderBy === 'ppb') {
        if (b.bbr != undefined && a.bbr != undefined && b.bbr[orderBy] < a.bbr[orderBy]) {
            return -1;
        }
        if (b.bbr != undefined && a.bbr != undefined && b.bbr[orderBy] > a.bbr[orderBy]) {
            return 1;
        }
    } else {
        if (b.bbr != undefined && a.bbr != undefined && b.bbr.last[orderBy] < a.bbr.last[orderBy]) {
            return -1;
        }
        if (b.bbr != undefined && a.bbr != undefined && b.bbr.last[orderBy] > a.bbr.last[orderBy]) {
            return 1;
        }
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'high', numeric: false, disablePadding: false, label: '上軌', sid: 'high' },
    { id: 'mid', numeric: false, disablePadding: false, label: '中軌', sid: 'mid' },
    { id: 'low', numeric: false, disablePadding: false, label: '下軌', sid: 'low' },
    { id: 'std', numeric: false, disablePadding: false, label: '標準差', sid: 'std' },
    { id: 'bp', numeric: false, disablePadding: false, label: '%b值', sid: 'bp' },
    { id: 'bw', numeric: false, disablePadding: false, label: '帶寬指標', sid: 'bp' },
    { id: 'pps', numeric: false, disablePadding: false, label: '股價狀態', sid: 'pps' },
    { id: 'ppb', numeric: false, disablePadding: false, label: '通道狀態', sid: 'ppb' },
    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const [open1, setOpen1] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleOk = () => {
        setOpen1(false);
        props.freecashupdate()
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <BBSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></BBSetting>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={8} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">布林通道
                <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8', marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen1}>
                        調整參數
          </Typography>
                </TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'love' || headCell.id === 'bname2' || headCell.id === 'bname3' || headCell.id === 'ymd5' || headCell.id === 'psend') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' || headCell.id === 'bname' || headCell.id === 'buysell' || headCell.id === 'winprice' || headCell.id === 'bname1' || headCell.id === 'buysell1' || headCell.id === 'winprice1' || headCell.id === 'bname2' || headCell.id === 'buysell2' || headCell.id === 'winprice2' || headCell.id === 'bname3' || headCell.id === 'buysell3' || headCell.id === 'winprice3' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'bname2' ||
                        headCell.id === 'bname3' || headCell.id === 'status1' || headCell.id === 'status2' || headCell.id === 'status3'
                        || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function TechDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };


    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };


    function renderpb(value) {
        if (value >= 100) {
            return <Button size="small" style={{ background: BColor.upcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1}>上軌上方 {value}%</Button>
        } else if (value >= 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>貼近上軌 {value}%</Button>
        } else if (value > 55 && value < 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>上軌-中軌 {value}%</Button>
        } else if (value <= 55 && value >= 45) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >貼近中軌 {value}%</Button>
        } else if (value < 45 && value > 20) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >中軌-下軌 {value}%</Button>
        } else if (value < 20 && value > 0) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >貼近下軌 {value}%</Button>
        } else if (value <= 0) {
            return <Button size="small" style={{ background: BColor.downcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1} >下軌下方 {value}%</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }



    function renderpw(value) {
        if (value >= 95) {
            return <Button size="small" style={{ background: BColor.upcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1}>超級寬廣 {value}%</Button>
        } else if (value >= 80) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>中度寬廣 {value}%</Button>
        } else if (value < 80 && value > 20) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >正常寬度 {value}%</Button>
        } else if (value < 20 && value > 5) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >中度狹窄 {value}%</Button>
        } else if (value <= 5) {
            return <Button size="small" style={{ background: BColor.downcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1} >超級狹窄 {value}%</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    // val b = if(a1 == 0 && a2 == 0) 0 //持續上軌線
    // else if(a1 == 0 && a2 != 0) 1 //突破上
    // else if(a1 == 3 && a2 != 3) 2 //突破下
    // else if(a1 == 1 && a2 == 2) 3 // 向上突破中線
    // else if(a1 == 1 && a2 == 1) 4 // 中線與上軌線波動
    // else if(a1 == 2 && a2 == 2) 5 // 中線與下軌線波動
    // else if(a1 == 1 && a2 == 0) 6 // 向下跌破上軌線
    // else if(a1 == 2 && a2 == 1) 7 // 向下跌破中線
    // else if(a1 == 3 && a2 == 2) 8 // 向下跌破下軌線
    // else -1

    function renderNowp(value) {
        if (value === 0) {
            return <Button size="small" style={{ background: BColor.upcolorbox, minWidth: 150, color: '#FFFFFF' }} className={classes.button1}>持續上軌線</Button>
        } else if (value === 1) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>突破上軌線</Button>
        } else if (value === 2) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >突破下軌線</Button>
        } else if (value === 3) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>向上突破中線</Button>
        } else if (value === 4) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >中線與上軌線波動</Button>
        } else if (value === 5) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >中線與下軌線波動</Button>
        } else if (value === 6) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >向下跌破上軌線</Button>
        } else if (value === 7) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >向下跌破中線</Button>
        } else if (value === 8) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >向下跌破下軌線</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    // val b = if(b1 - b2 > 10) 0 //通道開口開大
    // else if(b2 - b1 > 10) 1 //通道開口縮小
    // else if(b1 < 5 && b2 < 5) 2 //通道狹窄
    // else if(b1 > 70 && b2 > 70) 3//通道寬廣
    // else 4

    function renderNowb(value) {
        if (value === 0) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1}>開口開大</Button>
        } else if (value === 1) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1} >開口縮小</Button>
        } else if (value === 2) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.downcolorbox }} className={classes.button1}>通道狹窄</Button>
        } else if (value === 3) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.upcolorbox }} className={classes.button1} >通道寬廣</Button>
        } else if (value === 4) {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button1} >正常寬度</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                {((row.bbr.last.high).toFixed(2))}元
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                {((row.bbr.last.mid).toFixed(2))}元
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                {((row.bbr.last.low).toFixed(2))}元
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                {(row.bbr.last.std).toFixed(2)}
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}

                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {renderpb(row.bbr.last.bp)}
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {renderpw(row.bbr.last.bw)}
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {renderNowp(row.bbr.pps)}
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}
                                            {row.bbr != undefined && row.bbr != null ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {renderNowb(row.bbr.ppb)}
                                            </TableCell> : <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">
                                                    -
                                            </TableCell>}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(TechDetail);
