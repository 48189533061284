import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AttachMoney from '@material-ui/icons/AttachMoney';
import FindRule from './FindRule'
import GrainIcon from '@material-ui/icons/Grain';
import LocalPlay from '@material-ui/icons/LocalPlay';
import MyLocation from '@material-ui/icons/MyLocation';
import GraphicEq from '@material-ui/icons/GraphicEq';
import HdSharp from '@material-ui/icons/HdSharp';
import MyLoveList from '../mobile1/MyLoveList'
import StockAutocomplete from './StockAutocomplete'
import StockDetail from '../mobile/StockDetail';
import StrageList from '../mobile1/StrageList'
import Donation1 from '../mobile/Donation1'
import Donation2 from '../mobile/Donation2'
import FindSameD from '../mobile/FindSameD'
import Avatar from '@material-ui/core/Avatar';
import Facebook from '@material-ui/icons/Facebook';
import TReportboard from '../mobile1/TReportboard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EditAttributes from '@material-ui/icons/EditAttributes'
import BigDetails from '../bigtables/BigDetails'

import OfflineBolt from '@material-ui/icons/OfflineBolt'
import Chip from '@material-ui/core/Chip';
import ColorModeContext from '../util/ColorMode'
import Brightness7Icon from '@material-ui/icons/Brightness7';
import WinerDashboard1 from '../winner/WinerDashboard1';
import IndustryHome1 from '../industry/IndustryHome1';
import StayCurrentPortrait from '@material-ui/icons/StayCurrentPortrait';

const drawerWidth = 220;

export default function Mdashboard3(props) {

    const isFromOldIOS = () => {
        return props.location.pathname === "/mdashboard1"
    }

    const isFromIOS = () => {
        return props.location.pathname === "/mdashboardios"
    }


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginTop: isFromOldIOS() ? "12px" : "0px"
            // color: "#FFFFFF",
            // backgroundColor: "#000000"
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,

        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            marginTop: isFromOldIOS() ? "12px" : "0px",
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-start',
        },
        content: {
            flexGrow: 1,
            overflow: 'hidden',
            padding: theme.spacing(0.5),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    }));


    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [menu, setMenu] = React.useState(0);
    const [symbol, setSymbol] = React.useState(null);
    const [opendetail, setOpendetail] = React.useState(false)
    const [checklic, setchecklic] = React.useState(false);
    const [refreshr, setRefreshr] = React.useState((localStorage.getItem("refreshr") === 'true'));
    const colorMode = React.useContext(ColorModeContext);
    const [refreshlove, setRefreshLove] = React.useState(null);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = (index) => {
        setMenu(index);
        setOpen(false);
    };

    const menuChange = (data) => {
        setSymbol(data)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate != undefined) props.detailUpdate(false)
        if (menu === 1) setRefreshLove(!refreshlove)
        setOpendetail(false)
    }

    useEffect(() => {
        fectchUserAPI()
        checkLic()
    }, [checklic]);



    const handleCChange = (event) => {
        localStorage.setItem("refreshr", event.target.checked)
        setRefreshr(event.target.checked)
    };

    const fectchUserAPI = () => {
        let noad = localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
        fetch("/jstock/v1/stock/usercount?noad=" + noad, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.text())
            .then(
                result => {
                },
                error => {
                }
            );
    }

    const checkLic = () => {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let a = {
                lic: lic,
                inp: {}
            }


            fetch("/jstock/v1/stock/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            if (result.hlic != null && (result.hlic.licok === 1 || result.hlic.tp === 3)) {
                                localStorage.setItem('noad', 1);
                                // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("1");
                            } else {
                                localStorage.setItem('noad', 0);
                                // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                            }
                        } else {
                            localStorage.setItem('noad', 0);
                            // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                        }
                    },
                    error => {
                        localStorage.setItem('noad', 0);
                        // if (window.webkit != undefined) window.webkit.messageHandlers.setAD.postMessage("0");
                    }
                );
        }
    }

    function renderContent() {
        if (menu === 0) return <FindRule ismobile={true}></FindRule>
        else if (menu === 1) return <MyLoveList refreshlove={refreshlove} ismobile={true}></MyLoveList>
        else if (menu === 2) return <StrageList ismobile={true}></StrageList>
        else if (menu === 3) return <FindSameD ismobile={true} />
        else if (menu === 4) {
            // if (isFromGoogle() || isFromOldIOS() || isFromIOS())
            //     return <Donation2 ismobile={true}></Donation2>
            // else return <Donation1 ismobile={true}></Donation1>
            return <Donation1 ismobile={true}></Donation1>    
        }
        else if (menu === 5) return <TReportboard ismobile={true}></TReportboard>
        else if (menu === 6) return <BigDetails ismobile={true}></BigDetails>
        else if (menu === 7) return <WinerDashboard1 ismobile={true}></WinerDashboard1>
        else if (menu === 8) return <IndustryHome1 ismobile={true}></IndustryHome1>
        else return <FindRule ismobile={true}></FindRule>
    }

    const changeScreen = () => {
        let k = localStorage.getItem('run_computer') === null ? "0" : localStorage.getItem('run_computer')

        if (k === "0") {
            localStorage.setItem("run_computer", "1")
        } else {
            localStorage.setItem("run_computer", "0")
        }

        window.location.reload(false);
    }

    const isFromGoogle = () => {
        return props.location.pathname === "/mdashboardg"
    }



    return (
        <div className={classes.root} style={{}}>
            <StockDetail open={opendetail} handleClose={closeDetail} stockno={symbol} ismobile={true} />
            <CssBaseline />
            <AppBar
                color="default"
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <StockAutocomplete menuChange={menuChange} />
                </Toolbar>
            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClick={handleDrawerClose}
            >

                <div className={classes.drawerHeader} style={{}} >
                    <Avatar src="assets/images/logo-transparent-1-128x128.png" style={{ height: 24, width: 24, marginRight: 10 }} onClick={(e) => changeScreen()} />
                    <Typography style={{ marginRight: 5, fontSize: 14 }} noWrap>
                        股票挖土機
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List dense={true} style={{}}>
                    <ListItem button key={'選股規則'} onClick={(e) => handleMenu(0)}>
                        <ListItemIcon><GrainIcon /></ListItemIcon>
                        <ListItemText primary={'選股規則'} />
                    </ListItem>
                    <ListItem button key={'自選清單'} onClick={(e) => handleMenu(1)}>
                        <ListItemIcon><LocalPlay /></ListItemIcon>
                        <ListItemText primary={'自選清單'} />
                    </ListItem>
                    <ListItem button key={'選股策略'} onClick={(e) => handleMenu(2)}>
                        <ListItemIcon><MyLocation /></ListItemIcon>
                        <ListItemText primary={'選股策略'} />
                    </ListItem>
                    <ListItem button key={'大盤分析'} onClick={(e) => handleMenu(6)}>
                        <ListItemIcon><EditAttributes /></ListItemIcon>
                        <ListItemText primary={'大盤統計'} />
                    </ListItem>
                    <ListItem button key={'券商分析'} onClick={(e) => handleMenu(7)}>
                        <ListItemIcon><OfflineBolt /></ListItemIcon>
                        <ListItemText primary={<div>券商統計<Chip style={{ marginLeft: 5 }} size="small" label="VIP" /></div>} />
                    </ListItem>
                </List>

                <Divider />
                <List dense={true} style={{}}>
                    <ListItem button key={'回測型態'} onClick={(e) => handleMenu(3)}>
                        <ListItemIcon><GraphicEq /></ListItemIcon>
                        <ListItemText primary={<div>回測型態<Chip style={{ marginLeft: 5 }} size="small" label="VIP" /></div>}>

                        </ListItemText>
                    </ListItem>
                    <ListItem button key={'交易日誌'} onClick={(e) => handleMenu(5)}>
                        <ListItemIcon><HdSharp /></ListItemIcon>
                        <ListItemText primary={<div>交易日誌<Chip style={{ marginLeft: 5 }} size="small" label="VIP" /></div>} />
                    </ListItem>
                </List>


                <Divider />
                <List dense={true} style={{}}>
                    <ListItem button key={'序號'} onClick={(e) => handleMenu(4)}>
                        <ListItemIcon><AttachMoney /></ListItemIcon>
                        <ListItemText primary={'序號'} />
                    </ListItem>


                    <ListItem button key={'手機/電腦版面切換'} onClick={(e) => changeScreen()}>
                        <ListItemIcon><StayCurrentPortrait /></ListItemIcon>
                        <ListItemText primary={'切換成電腦排版'} />
                    </ListItem>


                    <ListItem button key={'一般/深色'} onClick={colorMode.toggleColorMode}>
                        <ListItemIcon><Brightness7Icon /></ListItemIcon>
                        {theme.palette.type === "light" ? <ListItemText primary={'深色模式'} /> : <ListItemText primary={'淺色模式'} />}
                    </ListItem>

                    <ListItem button key={'ka'} >
                        <FormControlLabel
                            control={<Checkbox checked={refreshr} onClick={(e) => handleCChange(e)} name="checkedA" />}
                            label={<div style={{ marginLeft: 25, fontSize: 14 }}>自動挖股</div>}
                        />
                    </ListItem>

                    <ListItem button key={'臉書粉絲頁'} onClick={(e) => window.open('https://www.facebook.com/%E8%82%A1%E7%A5%A8%E6%8C%96%E5%9C%9F%E6%A9%9F-102085821284963#price', '股票挖土機')}>
                        <ListItemIcon><Facebook style={{ color: "#326da8" }} /></ListItemIcon>
                        <ListItemText primary={'臉書粉絲頁'} />
                    </ListItem>
                    <ListItem button key={'臉書社團'} onClick={(e) => window.open('https://www.facebook.com/groups/508460833089268#price', '股票挖土機')}>
                        <ListItemIcon><Facebook style={{ color: "#326da8" }} /></ListItemIcon>
                        <ListItemText primary={'臉書社團'} />
                    </ListItem>
                </List>

                <Divider />
                <List dense={true}>
                    <ListItem button key={'serial'}>
                        <ListItemText style={{ fontSize: 8 }} secondary={'v1.5.9.20220116.1'} />
                    </ListItem>
                </List>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {renderContent()}
            </main>
        </div>
    );
}
