import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import CashReport1 from './CashReport1'
import CashReport2 from './CashReport2';
import CashReport3 from './CashReport3';

import {exportExcel} from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider';

function desc(a, b, orderBy) {
    if (orderBy === "revenue" || orderBy === "pl" || orderBy === "oe" || orderBy === "nopil" ||
        orderBy === "npie" || orderBy === "plbt" || orderBy === "iteco" || orderBy === "profitLoss" ||
        orderBy === "eps"
    ) {
        if (b.proi != undefined && a.proi != undefined && orderBy != "" && b.proi[orderBy] < a.proi[orderBy]) {
            return -1;
        }
        if (b.proi != undefined && a.proi != undefined && orderBy != "" && b.proi[orderBy] > a.proi[orderBy]) {
            return 1;
        }
    } else if (orderBy === "cffu" || orderBy === "ncffi" || orderBy === "noi" || orderBy === "noc1" ||
        orderBy === "iti" || orderBy === "itc1"
    ) {
        if (b.cashi != undefined && a.cashi != undefined && orderBy != "" && b.cashi[orderBy] < a.cashi[orderBy]) {
            return -1;
        }
        if (b.cashi != undefined && a.cashi != undefined && orderBy != "" && b.cashi[orderBy] > a.cashi[orderBy]) {
            return 1;
        }
    } else if (orderBy === "full_asset" || orderBy === "current_asset" || orderBy === "cash" || orderBy === "inv" ||
        orderBy === "rev" || orderBy === "noncur" || orderBy === "int_ast" || orderBy === "ppe" || orderBy === "liabilityies" ||
        orderBy === "cur_liy" || orderBy === "nonlib" || orderBy === "equity"
    ) {
        if (b.bs != undefined && a.bs != undefined && orderBy != "" && b.bs[orderBy] < a.bs[orderBy]) {
            return -1;
        }
        if (b.bs != undefined && a.bs != undefined && orderBy != "" && b.bs[orderBy] > a.bs[orderBy]) {
            return 1;
        }
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// case class ChipPage(all : Long, sinc : Long, user : Long, uinc : Long, avgs : Double, avginc : Double, cuser : Long, cuper : Double, cincp : Double, cinc : Long, cstock : Long, csper : Double, csincp: Double)

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'ymd', numeric: false, disablePadding: false, label: '日期', sid: 'ymd' },
    { id: 'revenue', numeric: false, disablePadding: false, label: '營收', sid: 'revenue' },
    { id: 'pl', numeric: false, disablePadding: false, label: '毛利', sid: 'pl' },
    { id: 'oe', numeric: false, disablePadding: false, label: '營業費用', sid: 'oe' },
    { id: 'nopil', numeric: false, disablePadding: false, label: '營業利益', sid: 'nopil' },
    { id: 'npie', numeric: false, disablePadding: false, label: '業外', sid: 'npie' },
    { id: 'plbt', numeric: false, disablePadding: false, label: '稅前淨利', sid: 'plbt' },
    { id: 'iteco', numeric: false, disablePadding: false, label: '所得稅', sid: 'iteco' },
    { id: 'profit_loss', numeric: false, disablePadding: false, label: '本期淨利', sid: 'profit_loss' },
    { id: 'eps', numeric: false, disablePadding: false, label: 'EPS', sid: 'eps' },

    { id: 'cffu', numeric: false, disablePadding: false, label: '營業活動', sid: 'cffu' },
    { id: 'ncffi', numeric: false, disablePadding: false, label: '投資活動', sid: 'ncffi' },
    { id: 'cffuif', numeric: false, disablePadding: false, label: '籌資活動', sid: 'cffuif' },
    { id: 'captialout', numeric: false, disablePadding: false, label: '資本支出', sid: 'captialout' },
    { id: 'freecash', numeric: false, disablePadding: false, label: '自由現金流', sid: 'freecash' },
    { id: 'cceep', numeric: false, disablePadding: false, label: '淨現金流', sid: 'cceep' },

    { id: 'full_asset', numeric: false, disablePadding: false, label: '資產', sid: 'full_asset' },
    { id: 'cur_ast', numeric: false, disablePadding: false, label: '流動資產', sid: 'cur_ast' },
    { id: 'cash', numeric: false, disablePadding: false, label: '現金當約現金', sid: 'cash' },
    { id: 'inv', numeric: false, disablePadding: false, label: '存貨', sid: 'inv' },
    { id: 'rev', numeric: false, disablePadding: false, label: '應收帳款', sid: 'rev' },
    { id: 'noncur', numeric: false, disablePadding: false, label: '非流動資產', sid: 'noncur' },
    { id: 'int_ast', numeric: false, disablePadding: false, label: '無形資產', sid: 'int_ast' },
    { id: 'ppe', numeric: false, disablePadding: false, label: '不動產', sid: 'ppe' },
    { id: 'liabilityies', numeric: false, disablePadding: false, label: '負債', sid: 'liabilityies' },
    { id: 'cur_liy', numeric: false, disablePadding: false, label: '流動負債', sid: 'cur_liy' },
    { id: 'nonlib', numeric: false, disablePadding: false, label: '非流動負債', sid: 'nonlib' },
    { id: 'equity', numeric: false, disablePadding: false, label: '股東權益', sid: 'equity' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>

            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={10} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">損益表
                </TableCell>

                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">現金流量表
                </TableCell>
                <TableCell className={classes.stickya} colSpan={12} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">資產負債表
                </TableCell>
                {/* 
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">現金流量表
                </TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一季營益率
                </TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一季淨利率
                </TableCell>
                <TableCell className={classes.stickya} colSpan={7} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近一年狀態
                </TableCell> */}

                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'cffu' || headCell.id === 'ymd' || headCell.id === 'full_asset' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap' }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function CashReport(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [concentopen1, setConcentOpen1] = React.useState(false);
    const [concentopen2, setConcentOpen2] = React.useState(false);

    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);


    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    const handleClickOpencc1 = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen1(true);
    };

    const handleClosecc1 = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen1(false);
    };

    const handleClickOpencc2 = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen2(true);
    };

    const handleClosecc2 = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen2(false);
    };

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }


    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };



    const openDetail = (stockno) => {
        if (props.detailUpdate != undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate != undefined) props.detailUpdate(false)
        setOpendetail(false)
    }


    function renderValue3(value, stockno, stockName, tp) {
        let colorx = BColor.faircolor
        let word = "-"
        let unit = "百萬元"

        if (value === undefined || value == null || value === -99999) {
            word = "-"
            unit = ''
        } else {
            let x = 1000000
            if (value > 100000000 || value < -100000000) {
                x = 100000000
                unit = "億元"
            } else if (value > 1000000 || value < -1000000) {
                x = 1000000
            }
            word = ((value / x).toFixed(1)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
        }

        if (value < 0 && value != -99999)
            colorx = "#87c988"

        if (tp === 0)
            return <Button onClick={handleClickOpencc(stockno, stockName)} size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button>
        else if (tp === 1)
            return <Button onClick={handleClickOpencc1(stockno, stockName)} size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button>
        else if (tp === 2)
            return <Button onClick={handleClickOpencc2(stockno, stockName)} size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button>
        else return <Button onClick={handleClickOpencc(stockno, stockName)} size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button>

    }

    function renderValue4(value, stockno, stockName) {
        let colorx = BColor.faircolor
        let unit = "元"
        let word = value

        if (value === undefined || value == null || value === -99999) {
            word = "-"
            unit = ''
        }

        if (value < 0 && value != -99999)
            colorx = "#87c988"
        return <Button onClick={handleClickOpencc(stockno, stockName)} size="small" variant="outlined" style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}{unit}</Button>
    }

    const getAllIds = () => {
        return props.data.map(row => {
            return row.market_company.stock_no
        })
    }

    return (
        <div className={classes.root}>
            <CashReport1 open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></CashReport1>
            <CashReport2 open={concentopen1} handleClose={handleClosecc1} stockId={openstock} stockName={opensname}></CashReport2>
            <CashReport3 open={concentopen2} handleClose={handleClosecc2} stockId={openstock} stockName={opensname}></CashReport3>

            <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        ref={myContainer}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="right">
                                                <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{row.cashi.year}Q{row.cashi.season}</Button>
                                            </TableCell> : null}

                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.revenue, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.pl, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.oe, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.nopil, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.npie, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.plbt, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.iteco, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.proi.profit_loss, row.market_company.stock_no, row.market_company.short_name, 0)}
                                            </TableCell> : null}
                                            {row.proi != null && row.proi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue4(row.proi.eps, row.market_company.stock_no, row.market_company.short_name)}
                                            </TableCell> : null}
                                            {row.proi == null || row.proi == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="10" align="center">
                                                目前無任何資料
                                            </TableCell> : null}



                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} align="right">
                                                {renderValue3(row.cashi.cffu, row.market_company.stock_no, row.market_company.short_name, 1)}
                                            </TableCell> : null}
                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.cashi.ncffi, row.market_company.stock_no, row.market_company.short_name, 1)}
                                            </TableCell> : null}
                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.cashi.cffuif, row.market_company.stock_no, row.market_company.short_name, 1)}
                                            </TableCell> : null}
                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.cashi.captialout, row.market_company.stock_no, row.market_company.short_name, 1)}
                                            </TableCell> : null}
                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.cashi.freecash, row.market_company.stock_no, row.market_company.short_name, 1)}
                                            </TableCell> : null}
                                            {row.cashi != null && row.cashi != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.cashi.cceep, row.market_company.stock_no, row.market_company.short_name, 1)}
                                            </TableCell> : null}
                                            {row.cashi == null || row.cashi == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="6" align="center">
                                                目前無任何資料
                                            </TableCell> : null}


                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} align="right">
                                                {renderValue3(row.bs.full_asset, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.cur_ast, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.cash, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.inv, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.rev, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.noncur, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.int_ast, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.ppe, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.liabilityies, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.cur_liy, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.nonlib, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs != null && row.bs != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} align="right">
                                                {renderValue3(row.bs.equity, row.market_company.stock_no, row.market_company.short_name, 2)}
                                            </TableCell> : null}
                                            {row.bs == null || row.bs == undefined ? <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 0, paddingRight: 5 }} colSpan="12" align="center">
                                                目前無任何資料
                                            </TableCell> : null}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Divider orientation="vertical" flexItem />
                        <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                            匯出
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <TablePagination
                            rowsPerPageOptions={getRowPageData(props.width)}
                            component="div"
                            count={props.total}
                            rowsPerPage={props.currentcount}
                            page={props.currentpage}
                            labelRowsPerPage={'每頁'}
                            labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(CashReport);
