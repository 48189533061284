import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AllStrageRules from '../strage/AllStrageRules';

const useStyles = makeStyles(theme => ({
}));


export default function NormalRules(props) {
    const classes = useStyles();
    // ruleChange={this.props.updateRule} rules={this.props.rules}
    // ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} 
    return (
        <div>
             <AllStrageRules updateRule={props.updateRule} rows={props.rules} />
        </div>
    );
}