import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    DatasetComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function CategoryDetail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [category, setCategory] = React.useState(null)
    const [options, setOptions] = React.useState(null)
    const [options1, setOptions1] = React.useState(null)

    function getDefaultAChartOption(data, data1) {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '日K線'
                }, {
                    top: '300',
                    left: 'center',
                    text: '本益比'
                }, {
                    top: '600',
                    left: 'center',
                    text: '成交值(億)'
                }, {
                    top: '900',
                    left: 'center',
                    text: '市值(億)'
                }, {
                    top: '1200',
                    left: 'center',
                    text: '站上10日均線(%)'
                }, {
                    top: '1500',
                    left: 'center',
                    text: '站上20日均線(%)'
                }, {
                    top: '1800',
                    left: 'center',
                    text: '站上60日均線(%)'
                }, {
                    top: '2100',
                    left: 'center',
                    text: '上漲家(%)'
                }, {
                    top: '2400',
                    left: 'center',
                    text: '股東人數'
                }],

            grid: [
                {
                    top: '30',
                    left: '30',
                    right: '35',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '330',
                    left: '30',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '630',
                    left: '30',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '930',
                    left: '55',
                    right: '50',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1230',
                    left: '30',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1530',
                    left: '30',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '1830',
                    left: '30',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '2130',
                    left: '30',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '2430',
                    left: '40',
                    right: '40',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 1,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 2,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 3,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 4,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 5,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 6,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 7,
                    show: true,
                    scale: true,
                    type: 'value'
                },
                {
                    gridIndex: 8,
                    show: true,
                    scale: true,
                    type: 'value',
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 1000000)
                                return (value / 1000000).toFixed(1) + 'M'
                            else
                                return (value / 1000) + 'K'
                        }
                    }
                }
            ]
        };

        let x1 = {
            gridIndex: 0,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x3 = {
            gridIndex: 2,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x4 = {
            gridIndex: 3,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x5 = {
            gridIndex: 4,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x6 = {
            gridIndex: 5,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }
        let x7 = {
            gridIndex: 6,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x8 = {
            gridIndex: 7,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.d }),
        }

        let x9 = {
            gridIndex: 8,
            scale: true,
            type: 'category',
            data: data1.map(v => { return v.x }),
        }

        if (props.ismobile) {
            let xpoint = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    marginTop: 45,
                    color: '#B80C00'
                }
            }

            x1['axisPointer'] = xpoint
            x2['axisPointer'] = xpoint
            x3['axisPointer'] = xpoint
            x4['axisPointer'] = xpoint
            x5['axisPointer'] = xpoint
            x6['axisPointer'] = xpoint
            x7['axisPointer'] = xpoint
            x8['axisPointer'] = xpoint
            x9['axisPointer'] = xpoint
        }

        option['xAxis'] = [x1, x2, x3, x4, x5, x6, x7, x8, x9]

        if (data != null) {
            option['series'] = [
                {
                    name: '日K線',
                    type: 'candlestick',
                    data: data.map(v => { return v.k }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },

                {
                    name: '本益比',
                    type: 'line',
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.per }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 'true',
                    connectNulls: true,
                },
                {
                    name: '成交值(億)',
                    type: 'line',
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.money }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    smooth: 'true',
                    connectNulls: true,
                },
                {
                    name: '市值(億)',
                    type: 'line',
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.cmoney }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 3,
                    yAxisIndex: 3,
                    smooth: 'true',
                    connectNulls: true,
                },
                {
                    name: '10日均線上(%)',
                    type: 'line',
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.up10 }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 4,
                    yAxisIndex: 4,
                    smooth: 'true',
                    connectNulls: true,
                },
                {
                    name: '20日均線上(%)',
                    type: 'line',
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.up20 }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 5,
                    yAxisIndex: 5,
                    smooth: 'true',
                    connectNulls: true,
                },
                {
                    name: '60日均線上(%)',
                    type: 'line',
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.up60 }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 6,
                    yAxisIndex: 6,
                    smooth: 'true',
                    connectNulls: true,
                }, {
                    name: '上漲家(%)',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.inc }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 7,
                    yAxisIndex: 7,
                    smooth: 'true',
                    connectNulls: true,
                }, {
                    name: '股東人數',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data1.map(v => { return v.y }),
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    xAxisIndex: 8,
                    yAxisIndex: 8,
                    smooth: 'true',
                    connectNulls: true,
                    showSymbol: true,
                    symbolSize: 4,
                    showAllSymbol: true
                }

            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 50 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }


            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 50 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }
        }

        return option
    }


    function getDefaultAChartOption1(data) {
        let option = {
            title: [
                {
                    top: '0',
                    left: 'center',
                    text: '營收'
                }],
            grid: [
                {
                    top: '30',
                    left: '40',
                    right: '20',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    show: true,
                    scale: true,
                    type: 'value',
                    axisLabel: {
                        formatter: function (value, index) {
                            if (value > 1000000000000)
                                return (value / 1000000000000).toFixed(0) + '兆'
                            else if (value > 100000000000)
                                return (value / 100000000).toFixed(0) + '千億'
                            else if (value > 100000000)
                                return (value / 100000000).toFixed(0) + '億'
                            else if (value > 1000000)
                                return (value / 1000000).toFixed(0) + '百萬'
                            else return value
                        }
                    }
                }
            ]
        };

        let x1 = {
            gridIndex: 0,
            scale: true,
            type: 'category',
            data: data.map(v => { return v.x }),
        }


        if (props.ismobile) {
            let xpoint = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    marginTop: 45,
                    color: '#B80C00'
                }
            }

            x1['axisPointer'] = xpoint
        }

        option['xAxis'] = [x1]

        if (data != null) {
            option['series'] = [
                {
                    name: '營收',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    smooth: 'true',
                    connectNulls: true,
                }
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 50 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }


            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 50 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }
        }

        return option
    }


    function getCategory(cid) {
        let lic = localStorage.getItem('lic')

        if (lic != null && cid != null) {
            let param = {
                lic: lic,
                cid: cid
            }

            fetch("/jstock/v1/category/detail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let opt = getDefaultAChartOption(result.data, result.data1)
                        setOptions(opt)
                        let opt1 = getDefaultAChartOption1(result.rev)
                        setOptions1(opt1)
                    }
                );
        }
    }

    useEffect(() => {
        if (props.row != null && props.open) {
            getCategory(props.row.cs.tp + "_" + props.row.cs.id)
        }
    }, [props.row]);

    function getString() {
        return ""
    }

    return (
        <div>
            {props.open ? <Dialog
                fullScreen={true}
                fullWidth={true}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography style={{ marginTop: 10, marginBottom: 10 }} className={classes.title} variant="h5" id="tableTitle" component="div">
                        {props.row.cs.name}
                        <Typography variant="caption">(訂閱專屬)</Typography>
                    </Typography>
                    <Grid container spacing={0} className={classes.grid}>
                        <Grid item xs={12} sm={12} >
                            {options != null ? <ReactEChartsCore
                                theme={theme.palette.type}
                                style={{ height: 2700 }}
                                echarts={echarts}
                                option={options}
                                notMerge={true}
                                lazyUpdate={false}
                            /> : <div style={{}}>{getString()}</div>}
                            <Typography style={{ color: "#858585" }} variant="caption" id="tableTitle">
                                每周個股股東人數加總。一人同時擁有多間公司股票時，會重複計算。
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            {options1 != null ? <ReactEChartsCore
                                theme={theme.palette.type}
                                style={{ height: 350 }}
                                echarts={echarts}
                                option={options1}
                                notMerge={true}
                                lazyUpdate={false}
                            /> : <div style={{}}></div>}
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </div>
    );
}