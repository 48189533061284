import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button1: {
  },

}));

export default function CalendarDialog1(props) {
  const classes = useStyles();

  const [state, setState] = React.useState([
    {
      startDate: moment(props.bigtitle.se, 'YYYYMMDD').toDate(),
      endDate: moment(props.bigtitle.ss, 'YYYYMMDD').toDate(),
      key: 'selection'
    }
  ]);


  useEffect(() => {
    setState([
        {
            startDate: moment(props.bigtitle.se, 'YYYYMMDD').toDate(),
            endDate: moment(props.bigtitle.ss, 'YYYYMMDD').toDate(),
            key: 'selection'
          }

        ])
  }, [props.bigtitle.se]);

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleCancel = () => {
    setOpen1(false);
    // setState(finalDate)
    localStorage.removeItem("cal_sdd")
    localStorage.removeItem("cal_ldd")
    localStorage.removeItem("sd_param")
    props.bigupdate()
  };

  const handleOk = () => {
    setOpen1(false);

    localStorage.setItem('cal_sdd', moment(state[0].startDate).format('YYYYMMDD'));
    localStorage.setItem('cal_ldd', moment(state[0].endDate).format('YYYYMMDD'));

    props.bigupdate()
  };



  return (
    <div>
      【{moment(props.bigtitle.se).format('YYYY/MM/DD')}】~【{moment(props.bigtitle.ss).format('YYYY/MM/DD')}】
            <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8' }} className={classes.button1} onClick={handleClickOpen1}>
        變更區間
            </Typography>

      <Dialog open={open1} onClose={handleClose1} aria-labelledby="form-dialog-title">
        <DateRange
          locale={locales['zhTW']}
          editableDateInputs={true}
          onChange={item => setState([item.selection])}
          moveRangeOnFirstSelection={false}
          minDate={moment(props.bigtitle.me, 'YYYYMMDD').toDate()}
          maxDate={moment(props.bigtitle.ms, 'YYYYMMDD').toDate()}
          ranges={state}
        />
        <DialogActions>
          <Button onClick={handleCancel} style={{ color: "#5c5c5c", fontSize: 12 }}>
            最新交易日期（恢復預設）
          </Button>
          <Button onClick={handleOk} style={{ color: "#5c5c5c", fontSize: 12 }}>
            特定某日
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}
