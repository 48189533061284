import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function BaTaSetting(props) {
    //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();
    const hrref = React.createRef();
    const drref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('bol', parseInt(yref.current.value));

        if (hrref.current.value != null && hrref.current.value != "")
            localStorage.setItem('bolw', parseInt(hrref.current.value));

        if (drref.current.value != null && drref.current.value != "")
            localStorage.setItem('bolm', parseInt(drref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('bol') === null ? 3 : localStorage.getItem('bol')
    }

    function hR() {
        return localStorage.getItem('bolw') === null ? 3 : localStorage.getItem('bolw')
    }

    function hkdk() {
        return localStorage.getItem('bolm') === null ? 3 : localStorage.getItem('bolm')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">寶塔線參數</DialogTitle>

            <DialogContent>
                <br /><br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="日寶塔天數(預設:3)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    margin="dense"
                    id="hr"
                    label="周寶塔天數(預設:3)"
                    type="text"
                    fullWidth
                    defaultValue={hR()}
                    inputRef={hrref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    margin="dense"
                    id="kdk"
                    label="月寶塔天數(預設:3)"
                    type="text"
                    fullWidth
                    defaultValue={hkdk()}
                    inputRef={drref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}
