import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactHtmlParser from 'react-html-parser';

export default function SaveOtherDialog(props) {
    //   const [open, setOpen] = React.useState(false);

    function makeid1(length) {
        var result = '';
        var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function getSynNo() {
        let id = makeid1(7) + "000"
        localStorage.setItem("synno", id)
        return id
    }

    const handleClickOpen = () => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let lic = localStorage.getItem('lic')

        let param = lic === null ? {
            id: props.id,
            synno: synno
        } : {
                id: props.id,
                lic: lic,
                synno: synno
            };
        fectchSaveRuleAPI(param)
    };

    const fectchSaveRuleAPI = (param) => {
        fetch("/jstock/v1/stock/saveorules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        props.handleClose()
                    } else {
                        props.handleClose()
                    }
                }
            );
    }
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {ReactHtmlParser(props.desc)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickOpen} color="primary" autoFocus>
                        收藏
                    </Button>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
