import React, { useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import Chip from '@material-ui/core/Chip';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function StockCost(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(getDefaultAChartOption())
    const [loading, setLoading] = React.useState(false);


    function genData(data) {
        let option = getDefaultAChartOption()
        option['yAxis'] = [
            {
                scale: true,
                splitArea: {
                    show: false
                },
                show: true,
                position: 'left',
                splitLine: {
                    show: true
                },
            }
        ]

        let x1 = {
            type: 'category',
            data: data.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = [x1]

        if (data != null) {
            option['series'] = [
                {
                    name: '股價',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.k }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 2
                    },
                    smooth: true
                },
                {
                    name: '融資成本',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.mr }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 2
                    },
                    smooth: true
                },
                {
                    name: '外資成本',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.fi }),
                    symbol: 'none',
                    // symbolSize: 4,
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 2
                    },
                    smooth: true
                },
                {
                    name: '投信成本',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.let }),
                    symbol: 'none',
                    // symbolSize: 4,
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 2
                    },
                    smooth: true
                }

            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                // link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            // option['dataZoom'] = [
            //     {
            //         type: 'inside',
            //         xAxisIndex: [0, 1],
            //         start: 90,
            //         end: 100
            //     }
            // ]
        } else {
            option['axisPointer'] = {
                // link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            // option['dataZoom'] = [
            //     {
            //         type: 'inside',
            //         xAxisIndex: [0, 1],
            //         start: 90,
            //         end: 100
            //     },
            //     {
            //         show: true,
            //         xAxisIndex: [0, 1],
            //         type: 'slider',
            //         top: '820',
            //         start: 90,
            //         end: 100
            //     }
            // ]
        }

        return option
    }


    const runFectchAPII = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')
        let a = lic === null ? {
        } : {
            lic: lic,
            sid: props.row.market_company.stock_no
        };

        fetch("/jstock/v1/stock/costrate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)
                    if (result.r != null && result.r.length > 0)
                        setOptions(genData(result.r))

                }
            );
    }

    function getDefaultAChartOption() {
        let newOption = {
            title: [
                {
                    left: 'center',
                    text: '成本線',
                    top: '10',
                }
            ],
            legend: {
                top: 45,
                data: ['股價', '融資成本', '外資成本', '投信成本']
            },
            grid: [
                {
                    top: '80',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ]
        };

        return newOption
    }



    useEffect(() => {
        runFectchAPII();
    }, [props.row]);

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography  variant="caption" id="tableTitle">
                            <Chip label="訂閱專屬" />利用近240日的買賣資料來推估成本。如果外資或投信在期間內賣出大於買進時，成本不會畫出。
                        </Typography>
                    </div>

                    <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 550, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        showLoading={loading}
                    />
                </Grid>
            </Grid>
        </div >
    );
}