import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import WinerDetailsMenu from './WinerDetailMenu'

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function WinerDetail(props) {
    const classes = useStyles();
    const [category, setCategory] = React.useState(null)
    const [options, setOptions] = React.useState(null)


    function getCategory(cid) {
        let lic = localStorage.getItem('lic')

        if (lic != null && cid != null) {
            let param = {
                lic: lic,
                cid: cid
            }

            fetch("/jstock/v1/category/detail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        // let opt = getDefaultAChartOption(result.data, result.data1)
                        // setOptions(opt)
                    }
                );
        }
    }

    useEffect(() => {
        if (props.row != null && props.open) {
            getCategory(props.row.cs.tp + "_" + props.row.cs.id)
        }
    }, [props.row]);

    return (
        <div>
            {props.open ? <Dialog
                fullScreen={true}
                fullWidth={true}
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogContent style={{padding: 0}}>
                    <WinerDetailsMenu day={props.day} bs={props.bs} name={props.dealername} id={props.dealerId} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </div>
    );
}