import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TrendingUp from '@material-ui/icons/TrendingUp';
import ClearAll from '@material-ui/icons/ClearAll';
import Gesture from '@material-ui/icons/Gesture';
import Send from '@material-ui/icons/Send'
import LinkIcon from '@material-ui/icons/Link'
import Eject from '@material-ui/icons/Eject'
import FindReplace from '@material-ui/icons/FindReplace'
import Unarchive from '@material-ui/icons/Unarchive'
import FormatAlignJustifySharp from '@material-ui/icons/FormatAlignJustifySharp'
import HdSharp from '@material-ui/icons/HdSharp'
import MailOutline from '@material-ui/icons/MailOutline'
import ImageSearch from '@material-ui/icons/ImageSearch'
import VerifiedUser from '@material-ui/icons/VerifiedUser'

import Button from '@material-ui/core/Button';
import BPage from '../BPage'
import RuleTp4 from '../ruletp/RuleTp4';
import BColor from '../BColor'

import {
    LAZY_1, LAZY_2, LAZY_3, LAZY_4, LAZY_5, LAZY_6, LAZY_7, LAZY_8,
    LAZY_9, LAZY_10, LAZY_11, LAZY_12, LAZY_13
} from '../ruletp/RuleData'
import {
    LAZY_1_IDS, LAZY_2_IDS, LAZY_3_IDS, LAZY_4_IDS, LAZY_5_IDS, LAZY_6_IDS,
    LAZY_7_IDS, LAZY_8_IDS, LAZY_9_IDS, LAZY_10_IDS, LAZY_11_IDS, LAZY_12_IDS,
    LAZY_13_IDS
} from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import HLPSetting from '../mobile3/HLPSetting'

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
    },
    panelTitle: {
        marginLeft: 10,
    },
    panelTitle1: {
        marginLeft: 10,
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    titlea: {
        backgroundColor: '#FFFFFF',
        opacity: 1
    },
    secondaryHeading: {
        marginTop: 5,
        marginLeft: 10,
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
    },
})


class ProDashboard extends React.Component {
    state = {
        expanded: 'panelx',
        onoff: 0,
        open8: false,
    };

    handleClickOpen8 = () => {
        this.setState({
            open8: true,
        });
    };

    handleClose8 = () => {
        this.setState({
            open8: false,
        });
    };

    handleOk8 = () => {
        this.setState({
            open8: false,
        });
        this.props.freecashupdate()
    };


    handleOpen1 = () => {
        this.props.tabchange(BPage.donate_page)
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    componentDidMount() {
        this.checkLic()
    }


    checkLic() {
        let lic = localStorage.getItem('lic')
        if (lic != null) {
            let param = {
                lic: lic
            }

            fetch("/jstock/v1/stock/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0 && result.hlic != null) {
                            this.setState({
                                onoff: result.hlic.licok
                            });
                        } else {
                            this.setState({ onoff: 0 });
                        }
                    },

                    error => {
                        this.setState({
                            onoff: 0
                        });
                    }
                );
        }
    }

    render() {
        const { expanded, open8 } = this.state;
        const { classes } = this.props;

        const isLAZYOn1 = this.props.rules.some(rule => LAZY_1_IDS.includes(rule.id));
        const isLAZYOn2 = this.props.rules.some(rule => LAZY_2_IDS.includes(rule.id));
        const isLAZYOn3 = this.props.rules.some(rule => LAZY_3_IDS.includes(rule.id));
        const isLAZYOn4 = this.props.rules.some(rule => LAZY_4_IDS.includes(rule.id));
        const isLAZYOn5 = this.props.rules.some(rule => LAZY_5_IDS.includes(rule.id));
        const isLAZYOn6 = this.props.rules.some(rule => LAZY_6_IDS.includes(rule.id));
        const isLAZYOn7 = this.props.rules.some(rule => LAZY_7_IDS.includes(rule.id));
        const isLAZYOn8 = this.props.rules.some(rule => LAZY_8_IDS.includes(rule.id));
        const isLAZYOn9 = this.props.rules.some(rule => LAZY_9_IDS.includes(rule.id));
        const isLAZYOn10 = this.props.rules.some(rule => LAZY_10_IDS.includes(rule.id));
        const isLAZYOn11 = this.props.rules.some(rule => LAZY_11_IDS.includes(rule.id));
        const isLAZYOn12 = this.props.rules.some(rule => LAZY_12_IDS.includes(rule.id));
        const isLAZYOn13 = this.props.rules.some(rule => LAZY_13_IDS.includes(rule.id));

        return (
            <div className={classes.root}>
                <HLPSetting open={open8} handleClose={this.handleClose8} handleOk={this.handleOk8}></HLPSetting>

                <div>

                    {this.props.tabchange != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                            訂閱
                        </Button> : null
                    }

                    {this.props.saveRule1 != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.saveRule1()}>
                            儲存規則
                        </Button> : null
                    }

                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.clearRule()}>
                        清除規則
                    </Button>
                </div>
                <div style={{ fontSize: 12, color: '#8c8c8c' }} >
                    #獨創：這是獻給訂閱者的秘密武器，不用在為了設定規則跟搜尋不到股票而煩惱，直接勾選您在意的項目，系統會自動找出最貼近您想法的股票。<br />
                </div>
                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn1'}
                    onChange={this.handleChange('LAZYOn1')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn1 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <TrendingUp color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>基本面</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_1.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn2'}
                    onChange={this.handleChange('LAZYOn2')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn2 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Unarchive color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>EPS、CMF</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_2.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>



                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn3'}
                    onChange={this.handleChange('LAZYOn3')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn3 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <LinkIcon color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>主力、大戶、散戶、集中度</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_3.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn4'}
                    onChange={this.handleChange('LAZYOn4')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn4 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ClearAll color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>三大法人、八大官股</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_4.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn5'}
                    onChange={this.handleChange('LAZYOn5')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn5 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Gesture color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>大盤、除權息</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_5.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn6'}
                    onChange={this.handleChange('LAZYOn6')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn6 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <MailOutline color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>營收</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_6.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn7'}
                    onChange={this.handleChange('LAZYOn7')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn7 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Send color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>內涵價值、樂活五線譜、串聯指標</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_7.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn8'}
                    onChange={this.handleChange('LAZYOn8')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn8 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <VerifiedUser color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>分點券商、三方指標、吊燈指標</Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen8}>
                            調整參數
                        </Typography>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_8.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>



                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn9'}
                    onChange={this.handleChange('LAZYOn9')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn9 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <FormatAlignJustifySharp color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>KD、趨勢線、衝刺指標</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_9.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn10'}
                    onChange={this.handleChange('LAZYOn10')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn10 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Eject color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>布林通道、乖離率、支撐壓力、寶塔線</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_10.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn11'}
                    onChange={this.handleChange('LAZYOn11')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn11 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <HdSharp color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>葛蘭碧法則、九轉序列、新三價線、期望指標</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_11.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn12'}
                    onChange={this.handleChange('LAZYOn12')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn12 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ImageSearch color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>成長圖，風險與報酬率</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_12.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'LAZYOn13'}
                    onChange={this.handleChange('LAZYOn13')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isLAZYOn13 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <FindReplace color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>異常訊號</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {LAZY_13.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

export default withStyles(styles)(ProDashboard);