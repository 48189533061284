import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import Typography from '@material-ui/core/Typography';
import BColor from '../BColor'
import BPage from '../BPage'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CategoryDetail from './CategoryDetail'
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        // padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


export default function IndustryHome1(props) { //IndustryGroup1
    const classes = useStyles();
    const theme = useTheme();

    const [category, setCategory] = React.useState([]);
    const [tp, setTp] = React.useState("0");
    const [sort, setSort] = React.useState("0");
    const [checked, setChecked] = React.useState([]);

    const [selected0, setSelected0] = React.useState([]);
    const [selected1, setSelected1] = React.useState([]);
    const [selected2, setSelected2] = React.useState([]);
    const [selected3, setSelected3] = React.useState([]);
    const [selected4, setSelected4] = React.useState([]);

    const [clearRows, setClearRows] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [pending, setPending] = React.useState(false);

    useEffect(() => {
        let rule = getRule()
        if (rule != null && rule.s1 != null) {
            setChecked(rule.s1.split(","))
        } else {

        }
    }, [props.rules]);


    useEffect(() => {
        getCategory(tp)
    }, []);

    const getRule = () => {
        if (props.rules != undefined) {
            const findId = id => (element, index, array) => {
                return element.id === id;
            }

            let rule = props.rules.find(findId('1000'))
            return rule;
        } else {
            return null
        }
    }


    const rowSelectCritera = row => {
        const currentIndex = checked.indexOf(row.cs.tp + "_" + row.cs.id)
        return currentIndex != -1
    }


    const handleChange1 = (state) => {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        // console.log('Selected Rows: ', state.selectedRows);
        // setSelected(state.selectedRows)
        let origin = []
        if (tp === "0") {
            setSelected0(state.selectedRows)
            origin = origin.concat(selected1).concat(selected2).concat(selected3).concat(selected4)
        } else if (tp === "1") {
            setSelected1(state.selectedRows)
            origin = origin.concat(selected0).concat(selected2).concat(selected3).concat(selected4)
        } else if (tp === "2") {
            setSelected2(state.selectedRows)
            origin = origin.concat(selected0).concat(selected1).concat(selected3).concat(selected4)
        } else if (tp === "3") {
            setSelected3(state.selectedRows)
            origin = origin.concat(selected0).concat(selected1).concat(selected2).concat(selected4)
        } else if (tp === "4") {
            setSelected4(state.selectedRows)
            origin = origin.concat(selected0).concat(selected1).concat(selected2).concat(selected3)
        }

        origin = origin.concat(state.selectedRows)

        let enabled = origin.length > 0
        let tpids = origin.map(row => {
            return row.cs.tp + '_' + row.cs.id
        }).join(",")

        let data = {
            id: "1000",
            s1: tpids
        }
        props.updateRule("1000", data, enabled)
    };

    function getCategory(tpv) {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            setPending(true)
            let param = {
                lic: lic,
                tp: tpv,
                sort: sort
            }

            fetch("/jstock/v1/category/all1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setPending(false)
                        if (result.data.length > 0) {
                            setCategory(result.data)
                        }
                    }
                );
        }
    }


    function getColor2(value) {
        if (value != null && value != -99999) {
            if (value > 0) return BColor.upcolor
            else if (value === 0) return BColor.faircolor
            else if (value < 0) return BColor.downcolor
            else return BColor.faircolor
        } return BColor.faircolor
    }

    function getColor3(value, minv, maxv) {
        if (value != null && value != -99999) {
            if (value >= maxv) return "#e36d6d"
            else if (value > minv && value < maxv) return "#c1d1db"
            else if (value <= minv) return "#61c27c"
            else return BColor.faircolor
        } return BColor.faircolor
    }

    function getColor4(value, minv, maxv) {
        if (value != null && value != -99999) {
            if (value >= maxv) return BColor.upcolor
            else if (value > minv && value < maxv) return BColor.faircolor
            else if (value <= minv) return BColor.downcolor
            else return BColor.faircolor
        } return BColor.faircolor
    }


    const conValue = (i) => {
        if (i != -99999) return i
        else return "-"
    }

    const conValue1 = (i) => {
        if (i != -99999) return i + "%"
        else return "-"
    }

    // function randomIntFromInterval(min, max) { // min and max included 
    //     return Math.floor(Math.random() * (max - min + 1) + min)
    // }

    const columns1 = [
        {
            name: '類別名稱',
            selector: 'name',
            sortable: true,
            cell: row => <Button color="primary" onClick={e => showRuleDetail(row)} >
                {row.cs.name}
            </Button>,
        },
        {
            name: '支數',
            selector: 'css',
            sortable: true,
            grow: 0,
            width: 40,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >
                {row.css}
            </div>,
        },
        {
            name: '漲幅',
            selector: 'i',
            sortable: true,
            grow: 0,
            width: 40,
            cell: row => <div style={{ color: getColor2(row.i) }} >
                {conValue(row.i)}%
            </div>,
        },
        {
            name: '本益比',
            selector: 'p',
            sortable: true,
            grow: 0,
            width: 40,
            cell: row => <div style={{}} >
                {conValue(row.p)}
            </div>,
        }, {
            name: '5日籌碼集中增減',
            selector: 'cc5',
            sortable: true,
            cell: row => <div style={{ display: 'flex', color: getColor4(row.cc5, 0, 0) }}>
                {conValue1(row.cc5)}
            </div>
        }, {
            name: '股東人數(周)',
            selector: 'pero',
            sortable: true,
            cell: row => <div style={{ display: 'flex', color: getColor4(row.pero, 0, 0) }}>
                {conValue1(row.pero)}
            </div>
        }, {
            name: '成交值',
            selector: 'm',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.mp, backgroundColor: getColor3(row.mp, 1, 3), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.mp)}%<br />{(row.m / 100000000).toFixed(0)}億</div>
            </div>
            ,
        },
        {
            name: '市值',
            selector: 'cp',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.cp, backgroundColor: getColor3(row.cp, 1, 2), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.cp)}%<br />{(row.cpv).toFixed(0)}億</div>
            </div>
            ,
        }, {
            name: '上漲家數',
            selector: 'upp',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.upp, backgroundColor: getColor3(row.upp, 25, 40), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.upp)}%</div>
            </div>
            ,
        }, {
            name: '站上5日均',
            selector: 'ap5',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.ap5, backgroundColor: getColor3(row.ap5, 30, 50), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.ap5)}%</div>
            </div>
            ,
        }, {
            name: '站上10日均',
            selector: 'ap10',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.ap10, backgroundColor: getColor3(row.ap10, 30, 50), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.ap10)}%</div>
            </div>
            ,
        },
        {
            name: '站上20日均',
            selector: 'ap20',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.ap20, backgroundColor: getColor3(row.ap20, 30, 60), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.ap20)}%</div>
            </div>
            ,
        },
        {
            name: '站上60日均',
            selector: 'ap60',
            sortable: true,
            cell: row => <div style={{ display: 'flex', minWidth: 140, width: 140 }}>
                <div style={{ whiteSpace: 'nowrap', padding: 8, width: row.ap60, backgroundColor: getColor3(row.ap60, 30, 70), color: "#FFFFFF" }}></div>
                <div style={{ marginLeft: 5 }}>{conValue(row.ap60)}%</div>
            </div>
            ,
        },
    ];

    const customStyles1 = {
        header: {
            style: {
                fontSize: "36px",
                fontWeight: "bold",
                paddingLeft: 0
            },
        },
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };

    const handleChange = (event) => {
        setTp(event.target.value);
        getCategory(event.target.value)
    };

    const handleOpen1 = () => {
        props.tabchange(BPage.donate_page)
    }

    const clearRule = () => {
        setChecked([])

        setSelected0([])
        setSelected1([])
        setSelected2([])
        setSelected3([])
        setSelected4([])

        setClearRows(!clearRows)
        props.clearRule()
    }

    function showRuleDetail(row) {
        setRow(row)
        setOpen(true)
    }

    function closeDialog() {
        setOpen(false)
    }


    return (
        <div className={classes.root}>
            <CategoryDetail ismobile={props.ismobile} open={open} row={row} handleClose={closeDialog}></CategoryDetail>

            <DataTable
                progressPending={pending}
                progressComponent={<div>載入中...</div>}
                theme={theme.palette.type}
                customStyles={customStyles1} noDataComponent={"無任何產業分類，僅開放給付費者使用。"} title={<div style={{ display: 'flex',marginLeft:5, marginTop: 20, marginBottom: 5 }}>
                    <Typography className={classes.title} variant="h6" id="tableTitle">
                        產業選股
                    </Typography>
                    <FormControl variant="outlined" style={{ minWidth: 100, marginLeft: 5 }} className={classes.formControl} required size="small">
                        <InputLabel id="demo-simple-select-outlined-labelv">股票分類</InputLabel>
                        <Select
                            shrink="true"
                            labelId="demo-simple-select-outlined-labelv"
                            id="demo-simple-select-outlinedv"
                            value={tp}
                            onChange={handleChange}
                            label="股票分類"
                        >
                            <MenuItem value={"0"}>上市股</MenuItem>
                            <MenuItem value={"1"}>上櫃股</MenuItem>
                            <MenuItem value={"2"}>產業股</MenuItem>
                            <MenuItem value={"3"}>集團股</MenuItem>
                            <MenuItem value={"4"}>概念股</MenuItem>
                        </Select>
                    </FormControl>

                    {props.saveRule1 != undefined ?
                        <Button style={{ marginLeft: 5 }} variant="outlined" className={classes.button} onClick={(e) => props.saveRule1()}>
                            儲存
                        </Button> : null
                    }

                    <Button style={{ marginLeft: 5 }} variant="outlined" className={classes.button} onClick={(e) => clearRule()}>
                        清除
                    </Button>

                </div>}

                selectableRowsComponent={Checkbox}
                selectableRowSelected={rowSelectCritera} selectableRows onSelectedRowsChange={handleChange1}
                noContextMenu={true}
                clearSelectedRows={clearRows}
                selectableRowsHighlight={true}
                selectableRowsNoSelectAll={false} pagination paginationPerPage={10} columns={columns1} data={category} />


        </div>
    );
}
