import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BrokerList from '../details/BrokerList';
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function BrokerBuyDetail(props) {
    const classes = useStyles();

    const [openstock, setOpenstock] = React.useState(null);
    const [opendealer, setOpenDealer] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opendname, setOpenDname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockname, dealer_id, dealername) => event => {
        setOpenstock(stock_no)
        setOpenDealer(dealer_id)
        setOpenSname(stockname)
        setOpenDname(dealername)        
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenDealer(null)
        setOpenSname(null)
        setOpenDname(null) 
        setConcentOpen(false);
    };

    function renderName(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                return <StyledTableCell style={{ whiteSpace: 'nowrap' }} >
                    <Button onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1}>{rv1.name}</Button>
                </StyledTableCell>
            }
        }

        if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }} ></StyledTableCell>
    }


    function renderStock(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = "中立"
                let vv = ((rv1.db.buysell / 1000).toFixed())

                if (rv1.db.buysell < 0) {
                    colorx = BColor.downcolorbox
                    word = "賣超 "
                    if (rv1.db.buysell <= -1000)
                        vv = ((-rv1.db.buysell / 1000).toFixed()) + "張"
                    else
                        vv = -rv1.db.buysell + "股"

                } else if (rv1.db.buysell === 0) {
                    colorx = BColor.nodatacolor
                    word = "中立 "
                    vv = "0張"
                } else if (rv1.db.buysell > 0) {
                    colorx = BColor.upcolorbox
                    word = "買超 "
                    if (rv1.db.buysell >= 1000)
                        vv = ((rv1.db.buysell / 1000).toFixed()) + "張"
                    else
                        vv = rv1.db.buysell + "股"
                } else {
                    colorx = BColor.nodatacolor
                    word = "中立 "
                    vv = ((rv1.db.buysell / 1000).toFixed()) + "張"
                }

                return <StyledTableCell style={{ whiteSpace: 'nowrap' }} >
                    <Button variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} {vv}</Button>
                </StyledTableCell>
            }
        }

        if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }} ></StyledTableCell>
    }


    function renderWinprice(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = "持平 "
                let vv = ((rv1.db.winprice / 10000).toFixed(1))

                if (rv1.db.winprice < 0) {
                    colorx = BColor.downcolorbox
                    word = "損失 "
                    vv = ((-rv1.db.winprice / 10000).toFixed(1))
                } else if (rv1.db.winprice === 0) {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                } else if (rv1.db.winprice > 0) {
                    colorx = BColor.upcolorbox
                    word = "獲利 "
                } else {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                }

                return <StyledTableCell style={{ whiteSpace: 'nowrap' }} >
                    <Button variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{word} {vv}萬元</Button>
                </StyledTableCell>
            }
        }

        if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }} ></StyledTableCell>
    }

    function renderBuyDate(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let vv = rv1.db.buyc
                return <StyledTableCell style={{ whiteSpace: 'nowrap' }} >
                    <Button onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{vv}天</Button>
                </StyledTableCell>
            }
        }

        if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }} ></StyledTableCell>
    }


    function renderinc(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = "持平 "
                let vv = rv1.db.inc_count

                if (rv1.db.inc_tp === 0) {
                    colorx = BColor.upcolorbox
                    word = "買超 "
                } else if (rv1.db.inc_tp === 1) {
                    colorx = BColor.downcolorbox
                    word = "賣超 "
                } else if (rv1.db.inc_tp === 2) {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                } else {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                }

                return <StyledTableCell style={{ whiteSpace: 'nowrap' }} >
                    <Button onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} {vv}天</Button>
                </StyledTableCell>
            }
        }

        if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }} ></StyledTableCell>
    }

    
    function renderCost(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if(index === 0) rv1 = row.tpbd1.b1
            else if(index === 1) rv1 = row.tpbd1.b5
            else if(index === 2) rv1 = row.tpbd1.b10
            else if(index === 3) rv1 = row.tpbd1.b20
            else if(index === 4) rv1 = row.tpbd1.b40

            if(rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = rv1.db.bcost

                return <StyledTableCell style={{ whiteSpace: 'nowrap' }} >
                    <Button variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 元</Button>
                </StyledTableCell>
            }
        } 
        
        if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }} ></StyledTableCell>
    } 

    return (
        <div>
            <BrokerList open={concentopen} handleClose={handleClosecc} stockId={openstock} dealerId={opendealer} stockname={opensname} dealername={opendname}></BrokerList>

            {props.row.tpbd1 != null && props.row.tpbd1 != undefined ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近一週買超冠軍
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {renderName(props.row, 1, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'買超天數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超天數'}
                                    </StyledTableCell>
                                    {renderBuyDate(props.row, 1, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'近日連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近日連續'}
                                    </StyledTableCell>
                                    {renderinc(props.row, 1, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'張數'}
                                    </StyledTableCell>
                                    {renderStock(props.row, 1, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'平均成本'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>
                                    {renderCost(props.row, 1, true)}
                                </StyledTableRow >


                                <StyledTableRow key={'預估損益'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'預估損益'}
                                    </StyledTableCell>
                                    {renderWinprice(props.row, 1, true)}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近二週買超冠軍
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {renderName(props.row, 2, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'買超天數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超天數'}
                                    </StyledTableCell>
                                    {renderBuyDate(props.row, 2, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'近日連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近日連續'}
                                    </StyledTableCell>
                                    {renderinc(props.row, 2, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'張數'}
                                    </StyledTableCell>
                                    {renderStock(props.row, 2, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'平均成本'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>
                                    {renderCost(props.row, 2, true)}
                                </StyledTableRow >


                                <StyledTableRow key={'預估損益'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'預估損益'}
                                    </StyledTableCell>
                                    {renderWinprice(props.row, 2, true)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近一個月買超冠軍
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {renderName(props.row, 3, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'買超天數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超天數'}
                                    </StyledTableCell>
                                    {renderBuyDate(props.row, 3, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'近日連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近日連續'}
                                    </StyledTableCell>
                                    {renderinc(props.row, 3, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'張數'}
                                    </StyledTableCell>
                                    {renderStock(props.row, 3, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'平均成本'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>
                                    {renderCost(props.row, 3, true)}
                                </StyledTableRow >
                                <StyledTableRow key={'預估損益'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'預估損益'}
                                    </StyledTableCell>
                                    {renderWinprice(props.row, 3, true)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            近二個月買超冠軍
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {renderName(props.row, 4, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'買超天數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'買超天數'}
                                    </StyledTableCell>
                                    {renderBuyDate(props.row, 4, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'近日連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近日連續'}
                                    </StyledTableCell>
                                    {renderinc(props.row, 4, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'張數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'張數'}
                                    </StyledTableCell>
                                    {renderStock(props.row, 4, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'平均成本'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均成本'}
                                    </StyledTableCell>
                                    {renderCost(props.row, 4, true)}
                                </StyledTableRow >
                                <StyledTableRow key={'預估損益'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'預估損益'}
                                    </StyledTableCell>
                                    {renderWinprice(props.row, 4, true)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                    很抱歉，近期沒有找到此股票券商分點的資料。
                        </Typography>}
        </div >
    );
}

