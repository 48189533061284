import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import BIASList from './BIASList';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    if (b.bias[orderBy] < a.bias[orderBy]) {
        return -1;
    }
    if (b.bias[orderBy] > a.bias[orderBy]) {
        return 1;
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'price', numeric: false, disablePadding: false, label: '股價', sid: 'price' },
    { id: 'avg5', numeric: false, disablePadding: false, label: '均線', sid: 'avg5' },
    { id: 'bi5', numeric: false, disablePadding: false, label: '乖離率', sid: 'bi5' },
    { id: 'up5', numeric: false, disablePadding: false, label: '天花板價格', sid: 'up5' },
    { id: 'down5', numeric: false, disablePadding: false, label: '地板價格', sid: 'down5' },

    { id: 'price1', numeric: false, disablePadding: false, label: '股價', sid: 'price' },
    { id: 'avg20', numeric: false, disablePadding: false, label: '均線', sid: 'avg20' },
    { id: 'bi20', numeric: false, disablePadding: false, label: '乖離率', sid: 'bi20' },
    { id: 'up20', numeric: false, disablePadding: false, label: '天花板價格', sid: 'up520' },
    { id: 'down20', numeric: false, disablePadding: false, label: '地板價格', sid: 'down20' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;


    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">5日均線乖離率</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">20日均線乖離率</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'price' || headCell.id === 'price1' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' || headCell.id === 'bname' || headCell.id === 'buysell' || headCell.id === 'winprice' || headCell.id === 'bname1' || headCell.id === 'buysell1' || headCell.id === 'winprice1' || headCell.id === 'bname2' || headCell.id === 'buysell2' || headCell.id === 'winprice2' || headCell.id === 'bname3' || headCell.id === 'buysell3' || headCell.id === 'winprice3' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'bname2' ||
                        headCell.id === 'bname3' || headCell.id === 'status1' || headCell.id === 'status2' || headCell.id === 'status3'
                        || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.sid)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
    paper: {
        width: '100%',
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
        paddingLeft: 10,
        paddingRight: 5,
        width: 10,
        whiteSpace: 'nowrap',
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 1,
        left: 0,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
    stickyh: {
        backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function BIASDetail(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };
    const handleClickOpencc = (stock_no) => event => {
        setOpenstock(stock_no)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setConcentOpen(false);
    };

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    function renderEmpty(row) {
        if (row.bias === null || row.bias === undefined)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="5" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </TableCell>
    }


    function renderavg5(row) {
        if (row.bias != null && row.bias != undefined)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                {row.bias.avg5}
            </TableCell>
    }

    function renderbi5(row, stockno) {
        if (row.bias != null && row.bias != undefined) {
            let status = row.bias.status5

            if (status === 2 && row.bias.bi5 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} style={{ background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                        過熱 {row.bias.bi5}%
                    </Button>
                </TableCell>
            } else if (status === 1 && row.bias.bi5 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.upcolorbox }} className={classes.button1}>
                        正常　{row.bias.bi5}%
                    </Button>
                </TableCell>
            } else if (status === 0 && row.bias.bi5 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.faircolor }} className={classes.button1} >
                        正常　{row.bias.bi5}%
                    </Button>
                </TableCell>
            } else if (status === 3 && row.bias.bi5 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.downcolorbox }} className={classes.button1} >
                        正常　{row.bias.bi5}%
                    </Button>
                </TableCell>
            } else if (status === 4 && row.bias.bi5 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} style={{ background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                        跌深 {row.bias.bi5}%
                    </Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.nodatacolor }} className={classes.button1} >
                        -
                    </Button>
                </TableCell>
            }
        }
    }


    function renderup5(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status5 === 2) color = BColor.upcolorbox

            if (row.bias.up5 != -99999.0) {
                let finalprice = (row.bias.avg5 * (row.bias.up5 / 100) + row.bias.avg5).toFixed(2);

                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: color, marginRight: 1 }} className={classes.button2} >
                        {finalprice}元
                    </Button>
                    <Button size="small" variant="outlined" style={{ minWidth: 80, color: color }} className={classes.button2} >
                        {row.bias.up5}%
                    </Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ color: color }} className={classes.button3} >
                        -
                    </Button>
                </TableCell>
            }
        }
    }


    function renderdown5(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status5 === 4) color = BColor.downcolorbox

            if (row.bias.down5 != -99999.0) {
                let finalprice = (row.bias.avg5 * (row.bias.down5 / 100) + row.bias.avg5).toFixed(2);
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: color, marginRight: 1 }} className={classes.button2} >
                        {finalprice}元
                    </Button>
                    <Button size="small" variant="outlined" style={{ minWidth: 80, color: color }} className={classes.button2} >
                        {row.bias.down5}%
                    </Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ color: color }} className={classes.button3} >
                        -
                    </Button>
                </TableCell>
            }
        }

    }



    function renderavg20(row) {
        if (row.bias != null && row.bias != undefined)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                {row.bias.avg20}
            </TableCell>
    }

    function renderbi20(row, stockno) {
        if (row.bias != null && row.bias != undefined) {
            let status = row.bias.status20
            if (status === 2 && row.bias.bi20 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} style={{ background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
                        過熱 {row.bias.bi20}%
                    </Button>
                </TableCell>
            } else if (status === 1 && row.bias.bi20 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.upcolorbox }} className={classes.button1}>
                        正常　{row.bias.bi20}%
                    </Button>
                </TableCell>
            } else if (status === 0 && row.bias.bi20 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.faircolor }} className={classes.button1} >
                        正常　{row.bias.bi20}%
                    </Button>
                </TableCell>
            } else if (status === 3 && row.bias.bi20 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.downcolorbox }} className={classes.button1} >
                        正常　{row.bias.bi20}%
                    </Button>
                </TableCell>
            } else if (status === 4 && row.bias.bi20 != -99999.0) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} style={{ background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
                        跌深 {row.bias.bi20}%
                    </Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ color: BColor.nodatacolor }} className={classes.button1} >
                        -
                    </Button>
                </TableCell>
            }
        }
    }


    function renderup20(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status20 === 2) color = BColor.upcolorbox

            if (row.bias.up20 != -99999.0) {
                let finalprice = (row.bias.avg20 * (row.bias.up20 / 100) + row.bias.avg20).toFixed(2);
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: color, marginRight: 1 }} className={classes.button2} >
                        {finalprice}元
                    </Button>
                    <Button size="small" variant="outlined" style={{ minWidth: 80, color: color }} className={classes.button2} >
                        {row.bias.up20}%
                    </Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ color: color }} className={classes.button3} >
                        -
                    </Button>
                </TableCell>
            }
        }
    }


    function renderdown20(row) {
        if (row.bias != null && row.bias != undefined) {
            let color = BColor.faircolor
            if (row.bias.status20 === 4) color = BColor.downcolorbox

            if (row.bias.down20 != -99999.0) {
                let finalprice = (row.bias.avg20 * (row.bias.down20 / 100) + row.bias.avg20).toFixed(2);

                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: color, marginRight: 1 }} className={classes.button2} >
                        {finalprice}元
                    </Button>
                    <Button size="small" variant="outlined" style={{ minWidth: 80, color: color }} className={classes.button2} >
                        {row.bias.down20}%
                    </Button>
                </TableCell>
            } else {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ color: color }} className={classes.button3} >
                        -
                    </Button>
                </TableCell>
            }
        }
    }



    const openDetail = (stockno) => {
        if (props.detailUpdate != undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate != undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const pricecolor = fin => {
        if (fin != null && fin != undefined) {
            if (fin.price.incv > 0) return BColor.upcolor
            else if (fin.price.incv < 0) return BColor.downcolor
            else return BColor.faircolor
        } else return BColor.faircolor
    }

    const getAllIds = () => {
        return props.data.map(row => {
            return row.market_company.stock_no
        })
    }

    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => {
                                                        openDetail(row.market_company.stock_no)
                                                    }}
                                                    color="inherit"
                                                >{row.market_company.short_name}
                                                </Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10, color: pricecolor(row.fin), borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {row.bias.price}
                                            </TableCell>

                                            {renderavg5(row)}
                                            {renderbi5(row, row.market_company.stock_no)}
                                            {renderup5(row)}
                                            {renderdown5(row)}
                                            {renderEmpty(row)}

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10, color: pricecolor(row.fin), borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {row.bias.price}
                                            </TableCell>

                                            {renderavg20(row)}
                                            {renderbi20(row, row.market_company.stock_no)}
                                            {renderup20(row)}
                                            {renderdown20(row)}
                                            {renderEmpty(row)}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <BIASList open={concentopen} handleClose={handleClosecc} stockId={openstock}></BIASList>
                </div>
                {props.disablepage ?
                    <div>                        <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                        匯出
                    </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Divider orientation="vertical" flexItem />
                        <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                            匯出
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <TablePagination
                            rowsPerPageOptions={getRowPageData(props.width)}
                            component="div"
                            count={props.total}
                            rowsPerPage={props.currentcount}
                            page={props.currentpage}
                            labelRowsPerPage={'每頁'}
                            labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(BIASDetail);
