import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RevList from '../details/RevList'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function RevDetail(props) {
    const classes = useStyles();

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)  
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };



    const show5Rate = (value, total, stockno, stockName) => {

        if (value === -99999) {
            return <Button  variant="outlined"  onClick={handleClickOpencc(stockno, stockName)}  style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (value === 1) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} style={{ background: BColor.upcolorbox,  minWidth: 100,color: '#FFFFFF' }} className={classes.button1}>
                特優  第{value}名
      </Button>
        } else if (value === 2) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.upcolorbox }} className={classes.button1}>
                前段  第{value}名
    </Button>
        } else if (value === 3) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.faircolor }} className={classes.button1} >
                中間  第{value}名
      </Button>
        } else if (value === 4) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.downcolorbox }} className={classes.button1} >
                後段  第{value}名
    </Button>
        } else if (value === 5) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} style={{ background: BColor.downcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1} >
                倒數   第{value}名
    </Button>
        } else {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    //60,20,5
    const showRate = (value, total, stockno, stockName) => {
        let k = (value * 100) / total

        if (value === -99999) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (k <= 10) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} style={{ background: BColor.upcolorbox,  minWidth: 100,color: '#FFFFFF' }} className={classes.button1}>
                特優  第{value}名
      </Button>
        } else if (k > 10 && k <= 40) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.upcolorbox }} className={classes.button1}>
                前段  第{value}名
    </Button>
        } else if (k > 40 && k <= 60) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.faircolor }} className={classes.button1} >
                中間  第{value}名
      </Button>
        } else if (k >= 60 && k < 90) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.downcolorbox }} className={classes.button1} >
                後段  第{value}名
    </Button>
        } else if (k >= 90) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} style={{ background: BColor.downcolorbox,  minWidth: 100,color: '#FFFFFF' }} className={classes.button1} >
                倒數   第{value}名
    </Button>
        } else {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }


    const showCount = (value, total, stockno, stockName) => {
        let k = (value * 100) / total

        if (value === -99999) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{ color: BColor.nodatacolor,  minWidth: 100 }} className={classes.button1} >
                -
      </Button>
        } else if (k >= 90) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} style={{ background: BColor.upcolorbox,  minWidth: 100, color: '#FFFFFF' }} className={classes.button1}>
                超多 {value}次
      </Button>
        } else if (k >= 60 && k < 90) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.upcolorbox }} className={classes.button1}>
                偏多 {value}次
    </Button>
        } else if (k >= 40 && k < 60) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.faircolor }} className={classes.button1} >
                穩定 {value}次
      </Button>
        } else if (k >= 10 && k < 40) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.downcolorbox }} className={classes.button1} >
                偏低 {value}次
    </Button>
        } else if (k < 10) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} style={{ background: BColor.downcolorbox,  minWidth: 100,color: '#FFFFFF' }} className={classes.button1} >
                超低 {value}次
    </Button>
        } else {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const showCount1 = (value, total, unit, stockno, stockName) => {
        // let k = (value * 100) / total

        if (value === -99999) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else if (value > 0) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.upcolorbox }} className={classes.button1}>
                正成長 {value}{unit}
            </Button>
        } else if (value === 0) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.faircolor }} className={classes.button1} >
                持平 {value}{unit}
            </Button>
        } else if (value < 0) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.downcolorbox }} className={classes.button1} >
                負成長 {-value}{unit}
            </Button>
        } else {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const showCount2 = (value, total, unit, stockno, stockName) => {
        let k = (value * 100) / total

        if (value === -99999) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        } else if (k >= 10) {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.upcolorbox }} className={classes.button1}>
                {value}{unit}
            </Button>
        } else if (k < 10) {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{  minWidth: 100,color: BColor.faircolor }} className={classes.button1} >
                {value}{unit}
            </Button>
        } else {
            return <Button   onClick={handleClickOpencc(stockno, stockName)} variant="outlined" style={{  minWidth: 100,color: BColor.nodatacolor }} className={classes.button1} >
                -
      </Button>
        }
    }

    const renderInPrice = (value, unit) => {
        if (value === -99999) return "-"
        else return (value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + unit
    }

    const renderYm = (value, stockno, stockName) => {
        if (value === "") {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{minWidth: 100,  color: BColor.nodatacolor }} className={classes.button1} >
                -
            </Button>
        } else {
            return <Button  onClick={handleClickOpencc(stockno, stockName)}  variant="outlined" style={{minWidth: 100,  color: BColor.faircolor }} className={classes.button1} >
                {value}
            </Button>
        } 
    }

    return (
        <div>
            <RevList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></RevList>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            營收統計表
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption>營收歷史排名以近五年去計算名次，月季年增率次數統計區間為五年</caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'近一月歷史排名'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近一月歷史排名'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {showRate(props.row.rev.m1r, 60, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近一季歷史排名'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近一季歷史排名'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showRate(props.row.rev.s1r, 20, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近一年歷史排名'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近一年歷史排名'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {show5Rate(props.row.rev.m12r, 5, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'月增率為正次數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'月增率為正次數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount(props.row.rev.m1inc, 60, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'季增率為正次數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'季增率為正次數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount(props.row.rev.s1inc, 20, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'年增率為正次數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'年增率為正次數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount(props.row.rev.m12inc, 5, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'月增率已連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'月增率已連續'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount1(props.row.rev.m1incc, 60, '月', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'季增率已連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'季增率已連續'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount1(props.row.rev.s1incc, 20, '季', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'年增率已連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'年增率已連續'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount1(props.row.rev.m12incc, 5, '年', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'累積年增率已連續'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'累積年增率已連續'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount1(props.row.rev.y1incc, 60, '月', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近一年創新高次數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近一年創新高次數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount2(props.row.rev.y1large, 12, '次', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近二年創新高次數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近二年創新高次數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount2(props.row.rev.y2large, 24, '次', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近三年創新高次數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近三年創新高次數'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {showCount2(props.row.rev.y3large, 36, '次', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            最近一個月營收
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                        <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'年月份'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderYm(props.row.rev.lastym, props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'營收'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營收'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderInPrice(props.row.rev.rev, '百萬元', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'單月月增率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'單月月增率'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                    {renderInPrice(props.row.rev.lastmp, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'單月年增率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'單月年增率'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {renderInPrice(props.row.rev.lastyp, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'累積年增率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'累積年增率'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                    {renderInPrice(props.row.rev.lastaccp, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell>
                                </StyledTableRow >                                                               
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    );
}
