import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Button from '@material-ui/core/Button'
import WinRateSetting from './WinRateSetting'


echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function WinRateLine(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        props.bigupdate()
        setOpen(false);
    };

    
    function getXX() {
        if (props.row.wr != null && props.row.wr.data != null &&
            props.row.kd3.d.length >= props.row.wr.data.length) {
            let data = props.row.kd3.d.slice(-props.row.wr.data.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1() {
        if (props.row.wr != null && props.row.wr.data != null &&
            props.row.kd3.d.length >= props.row.wr.data.length) {

            let data = props.row.kd3.d.slice(-props.row.wr.data.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }

    function yy() {
        return localStorage.getItem('wl1') === null ? "10日" : localStorage.getItem('wl1') + "日"
    }

    function hR() {
        return localStorage.getItem('wl2') === null ? "15日" : localStorage.getItem('wl2') + "日"
    }

    function getDefaultAChartOption(result) {
        let option = {
            // legend: {
            //     data: ['昂貴區', '合理區', '便宜區', '股價',]
            // },
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '10',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '300',
                    left: '40',
                    right: '10',
                    height: '220',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    show: true,
                    gridIndex: 1,
                    scale: false,
                    type: 'value',
                    axisLabel:{
                        show: false
                    },
                    splitLine:{
                        show:true
                    },
                    axisLine:{
                        onZero: true
                    }
                }
            ],
            visualMap: {
                show: false,
                type: 'continuous',
                seriesIndex: 1,
                color: ['#78b7c2', '#ffaf38'],
            }
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: result.data.map(v => { return v.d }),
        }


        if (props.ismobile) {
            let k = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = k
            x2['axisPointer'] = k
        }

        option['xAxis'] = [x1, x2]

        if (result != null) {
            option['series'] = [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: getYY1(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: yy(),
                    type: 'line',
                    // color: "#ff7171",
                    areaStyle: {
                        // opacity: 0.8,
                        // color: "#63dbff"
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.data.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: false
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 'true'
                },
                {
                    name: hR(),
                    type: 'line',
                    color: "#4a4a4a",
                    emphasis: {
                        focus: 'series'
                    },
                    data: result.r2.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: false
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 'true'
                }                
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 0,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '600',
                    start: 0,
                    end: 100
                }
            ]

        }

        return option
    }


    useEffect(() => {
        if (props.row.wr != null) setOptions(getDefaultAChartOption(props.row.wr))
    }, [props.row.wr]);

    function getString() {
        return "目前無相關的期望指標"
    }

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <WinRateSetting open={open1} handleClose={handleClose} handleOk={handleOk}></WinRateSetting>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            期望指標<Button style={{marginLeft: 2}} size="small" color="primary" variant="outlined" onClick={(e) => handleOpen()}>設定</Button>
                        </Typography>
                    </div>
                    <div style={{ fontSize: 14, color: "#808080" }}>
                        期望指標 = 勝率(上漲天/總天數) x 上漲成交額 - 敗率(下跌天/總天數) x 下跌成交額<br />
                        期望值越大代表這段時間參與的人賺得越多，期望值大到一個程度會反轉（獲利了結或盤整）<br />
                        期望值越小代表這段時間參與的人賠得越多，期望值小到一個程度會反轉（跌深反彈或盤整）<br />
                        期望值一值接近0軸，代表無法從此股票獲得巨大利益。<br />

                        <br />
                        <li>低檔黃金交叉買進－例如:在負期望值或接近0軸，10日線向上穿過15日線布局。</li>
                        <li>高檔死亡交叉賣出－例如:在正期望值，10日線向下穿過15日線賣出。</li>
                        <li>依據投資週期自由選擇合適的日線－例如:短天期的5/10/15/20日，長天期40/60/100日。</li>
                    </div>


                    {props.row.dk != undefined  && options != null ? <ReactEChartsCore
                        style={{ height: 700, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{ }}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}