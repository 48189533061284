import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { Box, Container, TextField } from '@material-ui/core';


echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function RSIChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    const [shortDay, setShortDay] = React.useState(localStorage.getItem("rsi_short") != null ? localStorage.getItem("rsi_short") : "10");
    const [longDay, setLongDay] = React.useState(localStorage.getItem("rsi_long") != null ? localStorage.getItem("rsi_long") : "20");

    const handleChange = (event) => {
        setShortDay(event.target.value)
        localStorage.setItem("rsi_short", event.target.value)
    };

    const handleChange1 = (event) => {
        setLongDay(event.target.value);
        localStorage.setItem("rsi_long", event.target.value)
    };


    function genData1(data) {
        let k = genoption1()

        k['xAxis'] = {
            data: data.map(function (item) {
                return item.d;
            })
        }
        let series = []

        let y1 = {
            name: '股價',
            type: 'line',
            data: data.map(function (item) {
                return item.v1;
            }),
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        let y2 = {
            name: shortDay + '日',
            type: 'line',
            data: data.map(function (item) {
                return item.v2;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }
        let y3 = {
            name: longDay + '日',
            type: 'line',
            data: data.map(function (item) {
                return item.v3;
            }),
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
                width: 3
            }
        }

        series.push(y1)
        series.push(y2)
        series.push(y3)

        k['series'] = series
        return k
    }


    function genoption1() {
        let k = {
            legend: {
                data: ['股價', shortDay + '日', longDay + '日'],
                bottom: 0
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: 10,
                left: 50,
                right: 50,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            },
            xAxis: {
            },
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    type: 'value',
                    scale: true
                }
            ],
        }
        return k;
    }


    function getRSI() {
        let param = {
            stock_no: props.row.market_company.stock_no,
            value1: shortDay,
            value2: longDay
        }

        fetch("/jstock/v1/stock/rsi", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setOption(genData1(result.d))
                    setOption1(genData1(result.w))
                    setOption2(genData1(result.m))
                }

            );
    }



    useEffect(() => {
        getRSI()
    }, [props.row, shortDay, longDay]);



    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} style={{}}>
                    {/* <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}> */}
                    <Box style={{display:"flex", margin: 5}}>
                        <Typography variant="h6" id="tableTitle" style={{marginRight: 5, whiteSpace:"nowrap"}}>
                            RSI(日)
                        </Typography>
                        <TextField size="small" style={{marginRight: 5}} variant="outlined" id="shortDay" label="短天期" value={shortDay} onChange={handleChange} />
                        <TextField size="small" variant="outlined" id="longDay" label="長天期" value={longDay} onChange={handleChange1} />
                    </Box>
                    {/* </div> */}
                </Grid>


                {option != null && props.row.chart10 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>


                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    // onEvents={onEvents}
                    />
                </Grid> : <div style={{ marginLeft: 15 }} >無RSI(日)圖</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            RSI(週)
                        </Typography>
                    </div>
                </Grid>


                {option1 != null && props.row.chart10 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option1}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 15 }} >無RSI(週)圖</div>}


                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 25 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            RSI(月)
                        </Typography>
                    </div>
                </Grid>


                {option2 != null && props.row.chart10 != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option2}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 350 }}
                    />
                </Grid> : <div style={{ marginLeft: 15 }} >無RSI(月)圖</div>}
            </Grid>


        </div>
    );
}

