import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';

import CalendarWeekDialog from './BigCusCalendar';
import ConcentrationDetail from './ConcentrationDetail'
import BuysellDetail from './BuysellDetail';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import BigCusDetail from './BigCusListD7'
import MainCusList from './BigCusListD71';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider';


function createData(stock_no, name,
  finPer, marPer,

  mainIndex1, bigCusIndex1, bigCusPer1, smallCusPer1, tleIndex1, tlePer1, invIndex1, selfIndex1, smallCusIndex1,
  incp1, tleInc1, invInc1, selfInc1, mainInc1, bigInc1, smallInc1, intpIndex1, intpInc1, buysellDealer1,
  finIndex1, financeValue1, marginIndex1, marginValue1, concent1,
  brocount1, eb1, mf1, dt1, tq1, hotc1, dtr1,

  maincon, bigcon, tlecon, invcon, selfcon, finTotal1, marTotal1, to1
) {
  return {
    stock_no, name,
    finPer, marPer,
    mainIndex1, bigCusIndex1, bigCusPer1, smallCusPer1, tleIndex1, tlePer1, invIndex1, selfIndex1, smallCusIndex1,
    incp1, tleInc1, invInc1, selfInc1, mainInc1, bigInc1, smallInc1, intpIndex1, intpInc1, buysellDealer1,
    finIndex1, financeValue1, marginIndex1, marginValue1, concent1,
    brocount1, eb1, mf1, dt1, tq1, hotc1, dtr1,

    maincon, bigcon, tlecon, invcon, selfcon, finTotal1, marTotal1, to1
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function stableSort(array, cmp) {

  const stabilizedThis = array.map((el, index) =>
    [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: '股票代號' },


  { id: 'incp1', numeric: false, disablePadding: false, label: '漲幅(%)' },
  { id: 'tq1', numeric: false, disablePadding: false, label: '成交量' },
  { id: 'mainInc1', numeric: false, disablePadding: false, label: '主力' },
  { id: 'buysellDealer1', numeric: false, disablePadding: false, label: '家數差' },
  { id: 'concent1', numeric: false, disablePadding: false, label: '籌碼集中' },
  { id: 'bigInc1', numeric: false, disablePadding: false, label: '大戶' },  //1.02% 
  { id: 'intpInc1', numeric: false, disablePadding: false, label: '內部大戶' },  //1.02%   
  { id: 'smallInc1', numeric: false, disablePadding: false, label: '散戶' },
  { id: 'tleInc1', numeric: false, disablePadding: false, label: '外資' },
  { id: 'invInc1', numeric: false, disablePadding: false, label: '投信' },
  { id: 'selfInc1', numeric: false, disablePadding: false, label: '自營商' },
  { id: 'hotc1', numeric: false, disablePadding: false, label: '中實戶' },
  { id: 'eb1', numeric: false, disablePadding: false, label: '八大官股' },

  { id: 'financeValue1', numeric: false, disablePadding: false, label: '融資' },
  { id: 'marginValue1', numeric: false, disablePadding: false, label: '融券' },
  { id: 'mf1', numeric: false, disablePadding: false, label: '券資比' },
  { id: 'brocount1', numeric: false, disablePadding: false, label: '借券賣' },
  { id: 'dt1', numeric: false, disablePadding: false, label: '當沖' },
  { id: 'dtr1', numeric: false, disablePadding: false, label: '當沖率' },
  { id: 'to1', numeric: false, disablePadding: false, label: '周轉率' },

  { id: 'tlePer1', numeric: false, disablePadding: false, label: '外資' },  //1.02% 
  { id: 'bigCusPer1', numeric: false, disablePadding: false, label: '大戶' },  //1.02% 
  { id: 'smallCusPer1', numeric: false, disablePadding: false, label: '散戶' },  //1.02% 
  { id: 'finPer', numeric: false, disablePadding: false, label: '融資' },  //1.02% 
  { id: 'marPer', numeric: false, disablePadding: false, label: '融券' },  //1.02% 

  { id: 'maincon', numeric: false, disablePadding: false, label: '主力' },
  { id: 'bigcon', numeric: false, disablePadding: false, label: '大戶' },
  { id: 'tlecon', numeric: false, disablePadding: false, label: '外資' },
  { id: 'invcon', numeric: false, disablePadding: false, label: '投信' },
  { id: 'selfcon', numeric: false, disablePadding: false, label: '自營商' },
  { id: 'finTotal1', numeric: false, disablePadding: false, label: '融資' },
  { id: 'marTotal1', numeric: false, disablePadding: false, label: '融券' },

  { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor



function EnhancedTableHead(props) {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOk = () => {
    setOpen1(false);
    props.bigupdate()
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOk2 = () => {
    setOpen2(false);
    props.bigupdate()
  };



  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, bigtitle } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };


  return (
    <TableHead style={{ background: color1 }}>
      <TableRow style={{}}>
        <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>

        <TableCell className={classes.stickya} colSpan={20} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">
          <CalendarWeekDialog open={open2} handleClose={handleClose2} handleOk={handleOk} bigtitle={props.bigtitle} bigupdate={props.bigupdate}></CalendarWeekDialog>

        </TableCell>
        <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">佔股本比例</TableCell>
        <TableCell className={classes.stickya} colSpan={7} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">累積</TableCell>
        <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
      </TableRow>
      <TableRow style={{}}>
        {headCells.map(headCell => (
          <TableCell
            // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
            key={headCell.id}
            className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
            style={{ ...((headCell.id === 'incp' || headCell.id === 'incp1' || headCell.id === 'love' || headCell.id === 'tlePer1' || headCell.id === 'maincon') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
            size="small"
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap' }}>{headCell.label}</div> : <TableSortLabel
              style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>}
          </TableCell>
        ))}
      </TableRow>

    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    // maxHeight: 550,
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  vvvv: {
    fontSize: 10,
    padding: 5,
    color: BColor.faircolor,
    overflow: 'auto'
  },
  button1: {
  },
  stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
  stickya: {
    background: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    height: 35,
    zIndex: 10,
    top: 0,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    padding: 0,
    borderBottom: '1px solid #dbdbdb'
  },
  stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 10,
    top: 37,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    padding: 0
  }, //This is header
  stickya1: {
    backgroundColor: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    height: 35,
    zIndex: 20,
    top: 0,
    left: 0,
    paddingTop: 7,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    padding: 0,
    borderBottom: '0px solid #dbdbdb'
  },
  stickyhh: {
    backgroundColor: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    top: 37,
    left: 0,
    zIndex: 20,
    paddingTop: 0,
    paddingBottom: 7,
    paddingLeft: 8,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    padding: 0,
    borderTop: '0px',
    borderRight: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb'
  }
}));

function BigCusTableD7(props) {
  const classes = useStyles();
  const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState(loveItems);
  const [page, setPage] = React.useState(props.currentpage);
  const [dense, setDense] = React.useState(props.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);
  const [getStock, setSavestock] = React.useState(null);

  const [opendetail, setOpendetail] = React.useState(false)
  const [stockno, setStockNo] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [openstock, setOpenstock] = React.useState(null);
  const [concentopen, setConcentOpen] = React.useState(false);
  const [tleopen, setTLEOpen] = React.useState(false);
  const [bigcusopen, setBigCusOpen] = React.useState(false);
  const [maincusopen, setMainCusOpen] = React.useState(false)
  const [name, setName] = React.useState(null)
  const [lovemenu, setLovemenu] = React.useState(false)
  const myContainer = React.useRef(null);


  const handleLoveClick = stockNo => event => {
    setSavestock(stockNo);
    setLovemenu(true);
  };

  const handleLoveClose = () => {
    setLovemenu(false);
  };

  const handleClickOpenff = (stock_no, name) => event => {
    setOpenstock(stock_no)
    setName(name)
    setMainCusOpen(true);
  };

  const handleCloseff = () => {
    setOpenstock(null)
    setName(null)
    setMainCusOpen(false);
  };

  const handleClickOpenee = (stock_no, name) => event => {
    setOpenstock(stock_no)
    setName(name)
    setBigCusOpen(true);
  };

  const handleCloseee = () => {
    setOpenstock(null)
    setName(null)
    setBigCusOpen(false);
  };

  const handleClickOpendd = (stock_no) => event => {
    setOpenstock(stock_no)
    setTLEOpen(true);
  };

  const handleClosedd = () => {
    setOpenstock(null)
    setTLEOpen(false);
  };

  const handleClickOpencc = (stock_no) => event => {
    setOpenstock(stock_no)
    setConcentOpen(true);
  };

  const handleClosecc = () => {
    setOpenstock(null)
    setConcentOpen(false);
  };

  const handleClickOpenbb = (stock_no) => event => {
    setOpenstock(stock_no)
    setOpen(true);
  };

  const handleClosebb = () => {
    setOpenstock(null)
    setOpen(false);
  };


  const rows = props.data.map(row =>
    createData(row.market_company.stock_no,
      row.market_company.short_name,
      row.shortmoney.fin_per,
      row.shortmoney.mar_per,

      row.money.main_index,
      row.money.big_cus_index,
      row.money.big_cus_per,
      row.money.small_cus_per,
      row.money.tle_index,
      row.money.tle_per,
      row.money.inv_index,
      row.money.self_index,
      row.money.small_cus_index,
      row.money.incp,
      row.money.tle_inc,
      row.money.inv_inc,
      row.money.self_inc,
      row.money.main_inc,
      row.money.big_inc,
      row.money.small_inc,
      row.money.intp_index,
      row.money.intp_inc,
      row.money.buysell_dealer,

      row.money.fin_index,
      row.money.finance_value,
      row.money.margin_index,
      row.money.margin_value,
      row.money.concent,
      row.money.brocount,
      row.money.eb,
      row.money.mf,
      row.money.dt,
      row.money.tq,
      row.money.hotc,
      row.money.dtr,

      row.money.main_con,
      row.money.big_con,
      row.money.tle_con,
      row.money.inv_con,
      row.money.self_con,
      row.money.fin_total,
      row.money.mar_total,
      row.money.to
    )
  )

  {/* gp, oi, txb, txa */ }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.pageupdate(newPage, props.currentcount)
  };

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10));
    props.pageupdate(0, parseInt(event.target.value, 10))

    // setPage(0);
  };
  const renderInpriceStatusV1 = (status, value, stockno, name) => {
    if (status === -1) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (status === 4) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
        銳減{value}張
      </Button>
    } else if (status === 3) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
        減少{value}張
      </Button>
    } else if (status === 0) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        中立{value}張
      </Button>
    } else if (status === 1) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
        增加{value}張
      </Button>
    } else if (status === 2) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
        激增{value}張
      </Button>
    } else {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const renderInpriceStatusV3 = (value, stockno, name) => {
    if (value === -99999) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value < 0) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
        減少{value}張
      </Button>
    } else if (value === 0) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        中立{value}張
      </Button>
    } else if (value > 1) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
        增加{value}張
      </Button>
    } else {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const renderInpriceStatusV2 = (status, value, stockno, name) => {
    if (status === -1) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (status === 4) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1}>
        銳減{value}張
      </Button>
    } else if (status === 3) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
        減少{value}張
      </Button>
    } else if (status === 0) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        中立{value}張
      </Button>
    } else if (status === 1) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
        增加{value}張
      </Button>
    } else if (status === 2) {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1} >
        激增{value}張
      </Button>
    } else {
      return <Button size="small" onClick={handleClickOpenff(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const renderInpriceStatusVBigCus = (status, value, stockno, name) => {
    if (status === -1) {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (status === 4) {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
        大賣{value}張
      </Button>
    } else if (status === 3) {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
        小賣{value}張
      </Button>
    } else if (status === 0) {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        中立{value}張
      </Button>
    } else if (status === 1) {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
        小買{value}張
      </Button>
    } else if (status === 2) {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
        大買{value}張
      </Button>
    } else {
      return <Button size="small" onClick={handleClickOpenee(stockno, name)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }


  const renderSellerV = (value, stockno) => {
    if (value === -99999) {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value <= -300) {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
        {value}間
      </Button>
    } else if (value < 0 && value > -300) {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
        {value}間
      </Button>
    } else if (value === 0) {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        {value}間
      </Button>
    } else if (value > 0 && value < 300) {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
        {value}間
      </Button>
    } else if (value >= 300) {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
        {value}間
      </Button>
    } else {
      return <Button size="small" onClick={handleClickOpenbb(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const renderSellerV1 = (value, baseValue, stockno) => {
    if (value === -99999) {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value <= -baseValue) {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="contained" style={{ minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
        高度{value}%
      </Button>
    } else if (value < 0 && value > -baseValue) {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
        中度{value}%
      </Button>
    } else if (value === 0) {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        持平{value}%
      </Button>
    } else if (value > 0 && value < baseValue) {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
        中度{value}%
      </Button>
    } else if (value >= baseValue) {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="contained" style={{ minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
        高度{value}%
      </Button>
    } else {
      return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  function getRowPageData(screen) {
    if (screen === 'xs')
      return [20]
    else return [10, 20, 40, 80, 150];
  }

  const pbcolor = (value, bar) => {
    if (value >= bar) return BColor.upcolor
    else return BColor.faircolor
  }

  const pricecolor = incv => {
    if (incv > 0) return BColor.upcolor
    else if (incv < 0) return BColor.downcolor
    else return BColor.faircolor
  }

  const converPerValue = per => {
    if (per === -1) return "-"
    else return per + "%"
  }

  const renderBuySell = (value, unit, stockno, name) => {

    if (value === -99999) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value > 0) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
        買進 {value}{unit}
      </Button>
    } else if (value === 0) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        持平 {value}{unit}
      </Button>
    } else if (value < 0) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
        賣出 {value}{unit}
      </Button>
    } else {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const showCount1 = (value, unit) => {

    if (value === -99999) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value > 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
        買進 {value}{unit}
      </Button>
    } else if (value === 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        持平 {value}{unit}
      </Button>
    } else if (value < 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
        賣出 {value}{unit}
      </Button>
    } else {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const showCount2 = (value, unit) => {

    if (value === -99999) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value > 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>
        增加 {value}{unit}
      </Button>
    } else if (value === 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        持平 {value}{unit}
      </Button>
    } else if (value < 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >
        減少 {value}{unit}
      </Button>
    } else {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const showCount3 = (value, unit) => {

    if (value === -99999) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    } else if (value > 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
        增加 {value}{unit}
      </Button>
    } else if (value === 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        持平 {value}{unit}
      </Button>
    } else if (value < 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
        減少 {value}{unit}
      </Button>
    } else {
      return <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
        -
      </Button>
    }
  }

  const showBalance = (value, stockno, name) => {
    if (value == -1) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1}>
        -
      </Button>
    } else if (value >= 30) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
        {value}%
      </Button>
    } else {
      return <Button size="small" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        {value}%
      </Button>
    }
  }

  const showBalance1 = (value, stockno, name) => {
    if (value === -99999) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        0 張
      </Button>
    } else {
      return <Button size="small" variant="outlined" onClick={handleClickOpenff(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        {value}張
      </Button>
    }
  }

  const showBalance2 = (value, stockno, name) => {
    if (value === -99999) {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        0 張
      </Button>
    } else {
      return <Button size="small" variant="outlined" onClick={handleClickOpenee(stockno, name)} style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
        {value}張
      </Button>
    }
  }


  const openDetail = (stockno) => {
    if (props.detailUpdate != undefined) props.detailUpdate(true)
    setStockNo(stockno)
    setOpendetail(true)
  }

  const closeDetail = () => {
    if (props.detailUpdate != undefined) props.detailUpdate(false)
    if (props.closeupdate != undefined) props.closeupdate()
    setOpendetail(false)
  }

  const getAllIds = () => {
    return props.data.map(row => {
      return row.market_company.stock_no
    })
  }

  return (
    <div className={classes.root}>
      <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
      <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}
          style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
        >
          <Table
            ref={myContainer}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >

            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              bigtitle={props.bigtitle}
              bigupdate={props.bigupdate}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.stock_no)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.stock_no}
                    // selected={isItemSelected}
                    >
                      <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            openDetail(row.stock_no)
                          }}
                          color="inherit"
                        >
                          {row.name.length > 8 ? `${row.name.substring(0, 8)}...` : row.name}
                        </Link>
                        < br />
                        <Link
                          style={{ color: BColor.upcolor }}
                          component="button"
                          variant="caption"
                          onClick={() => openDetail(row.stock_no)}
                        >
                          {row.stock_no}
                        </Link>
                      </TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 0, borderLeft: '1px solid #dbdbdb', color: pricecolor(row.incp1) }} size="small" align="left">{row.incp1}%</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{showBalance2(row.tq1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5 }} size="small" align="left">{renderInpriceStatusVBigCus(row.mainIndex1, row.mainInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} size="small" align="left">{renderSellerV(row.buysellDealer1, row.stock_no)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} size="small" align="left">{renderSellerV1(row.concent1, 20, row.stock_no)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusVBigCus(row.bigCusIndex1, row.bigInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusVBigCus(row.intpIndex1, row.intpInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusVBigCus(row.smallCusIndex1, row.smallInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusVBigCus(row.tleIndex1, row.tleInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusVBigCus(row.invIndex1, row.invInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusVBigCus(row.selfIndex1, row.selfInc1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderBuySell(row.hotc1, '張', row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderBuySell(row.eb1, '張', row.stock_no, row.name)}</TableCell>


                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusV1(row.finIndex1, row.financeValue1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusV2(row.marginIndex1, row.marginValue1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{showBalance(row.mf1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{renderInpriceStatusV3(row.brocount1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{showBalance1(row.dt1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{showBalance(row.dtr1, row.stock_no, row.name)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 5, }} align="left">{showBalance(row.to1, row.stock_no, row.name)}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, color: pbcolor(row.tlePer1, 60), borderLeft: '1px solid #dbdbdb' }} size="small" align="right">{converPerValue(row.tlePer1)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, color: pbcolor(row.bigCusPer1, 60) }} size="small" align="right">{converPerValue(row.bigCusPer1)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, color: pbcolor(row.smallCusPer1, 40) }} align="right">{converPerValue(row.smallCusPer1)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, color: pbcolor(row.finPer, 15) }} align="right">{row.finPer}%</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30, color: pbcolor(row.marPer, 15) }} align="right">{row.marPer}%</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.maincon, 5), borderLeft: '1px solid #dbdbdb' }} size="small" align="right">{showCount1(row.maincon, '天')}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.bigcon, 4) }} size="small" align="right">{showCount1(row.bigcon, '週')}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.tlecon, 10) }} align="right">{showCount1(row.tlecon, '天')}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.invcon, 10) }} align="right">{showCount1(row.invcon, '天')}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.selfcon, 10) }} align="right">{showCount1(row.selfcon, '天')}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.finTotal1, 5) }} align="right">{showCount2(row.finTotal1, '天')}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pbcolor(row.marTotal1, 5) }} align="right">{showCount3(row.marTotal1, '天')}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                        <Button
                          size="small"
                          onClick={handleLoveClick(row.stock_no)}
                          variant="outlined"
                          style={{ width: '100%', color: BColor.faircolor }}
                          className={classes.button}
                          startIcon={<FavoriteBorder />}
                        >
                          管理
                        </Button>
                      </TableCell>

                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
          <BuysellDetail open={open} handleClose={handleClosebb} stockId={openstock}></BuysellDetail>
          <ConcentrationDetail open={concentopen} handleClose={handleClosecc} stockId={openstock}></ConcentrationDetail>
          <BigCusDetail open={bigcusopen} name={name} handleClose={handleCloseee} stockId={openstock}></BigCusDetail>
          <MainCusList open={maincusopen} name={name} handleClose={handleCloseff} stockId={openstock}></MainCusList>

        </div>
        {props.disablepage ?
          <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
            匯出
          </Button></div>
          : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Divider orientation="vertical" flexItem />
            <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
              匯出
            </Button>
            <Divider orientation="vertical" flexItem />
            <TablePagination
              rowsPerPageOptions={getRowPageData(props.width)}
              component="div"
              count={props.total}
              rowsPerPage={props.currentcount}
              page={props.currentpage}
              labelRowsPerPage={'每頁'}
              labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /></div>
        }
      </Paper>
    </div>
  )
}

export default withWidth()(BigCusTableD7);