import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';

import withWidth from '@material-ui/core/withWidth';
import CashFlowSetting from './CashFlowSetting';
import InterestSetting from './InterestSetting';
import IntSetting from './IntSetting';
import Typography from '@material-ui/core/Typography';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'

import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'

import {exportExcel} from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    if (b['inprice'][orderBy] < a['inprice'][orderBy]) {
        return -1;
    }
    if (b['inprice'][orderBy] > a['inprice'][orderBy]) {
        return 1;
    }
    return 0;
}


function stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sl: 'name' },

    { id: 'price1', numeric: false, disablePadding: false, label: '收盤價', sl: 'f' },
    { id: 'free4s', numeric: false, disablePadding: false, label: '自由現金流', sl: 'free4s' },
    { id: 'c1l', numeric: false, disablePadding: false, label: '便宜價', sl: 'c1l' },
    { id: 'c1m', numeric: false, disablePadding: false, label: '合理價', sl: 'c1m' },
    { id: 'c1h', numeric: false, disablePadding: false, label: '昂貴價', sl: 'c1h' },

    { id: 'price2', numeric: false, disablePadding: false, label: '收盤價', sl: 'f' },
    { id: 'ints', numeric: false, disablePadding: false, label: '平均現金股利', sl: 'ints' },
    { id: 'i1l', numeric: false, disablePadding: false, label: '便宜價', sl: 'i1l' },
    { id: 'i1m', numeric: false, disablePadding: false, label: '合理價', sl: 'i1m' },
    { id: 'i1h', numeric: false, disablePadding: false, label: '昂貴價', sl: 'i1h' },

    { id: 'price3', numeric: false, disablePadding: false, label: '收盤價', sl: 'f' },
    { id: 'eps', numeric: false, disablePadding: false, label: '近四季EPS', sl: 'eps' },
    { id: 'p1l', numeric: false, disablePadding: false, label: '便宜價', sl: 'p1l' },
    { id: 'p1m', numeric: false, disablePadding: false, label: '合理價', sl: 'p1m' },
    { id: 'p1h', numeric: false, disablePadding: false, label: '昂貴價', sl: 'p1h' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = (y, g, r, hr, mr, lr, y1, hr1, mr1, lr1) => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        props.freecashupdate()
    };

    return (
        <TableHead style={{ background: color1 }}>
            <CashFlowSetting open={open} handleClose={handleClose} handleOk={handleOk}></CashFlowSetting>
            <InterestSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></InterestSetting>
            <IntSetting open={open2} handleClose={handleClose2} handleOk={handleOk}></IntSetting>


            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">現金流折現模型
                    <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8', marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen}>
                        調整參數
                    </Typography>

                </TableCell>

                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">股利折現法
                    <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8', marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen1}>
                        調整參數
                    </Typography>
                </TableCell>

                <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">市場本益比比較法
                    <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8', marginLeft: 8 }} className={classes.button1} onClick={handleClickOpen2}>
                        參數說明
                    </Typography>
                </TableCell>

                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'price1' || headCell.id === 'price2' || headCell.id === 'love' || headCell.id === 'price3' || headCell.id === 'ymd4' || headCell.id === 'ymd5' || headCell.id === 'psend') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.sl ? order : false}
                    >
                        {headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> : <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.sl === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.sl}
                            direction={order}
                            onClick={createSortHandler(headCell.sl)}
                        >
                            {headCell.label}
                            {orderBy === headCell.sl ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function InPriceTable(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);


    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };


    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }



    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))

        // setPage(0);
    };

    function renderEmpty(v1, v2) {
        if (v1 === -99999)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} colSpan="4" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >無相關資料</Button>
            </TableCell>
        else if (v2 === -1.0)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} colSpan="3" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </TableCell>
        else if (v1 === -99999.0)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} colSpan="4" size="small" align="right">
                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >無相關資料</Button>
            </TableCell>

    }


    const renderInpriceStatus = (lowprice, highprice, minprice, lastvalue) => {
        if (lowprice === -1.0) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (lastvalue <= lowprice) {
            return <Button size="small" style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>超低 {lastvalue}元</Button>
        } else if (lastvalue > lowprice && lastvalue < minprice) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>偏低 {lastvalue}元</Button>
        } else if (lastvalue === minprice) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >合理 {lastvalue}元</Button>
        } else if (lastvalue > minprice && lastvalue < highprice) {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >偏高 {lastvalue}元</Button>
        } else if (lastvalue >= highprice) {
            return <Button size="small" style={{ minWidth: 100, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >超高 {lastvalue}元</Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >無法評估 {lastvalue}元</Button>
        }
    }

    function renderIntCell1(value, defaultv) {
        if (value != defaultv)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} size="small" align="right">{value}元</TableCell>
    }

    function renderIntCell2(value, defaultv, unit) {
        if (value != defaultv)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 30 }} size="small" align="right">{(value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}{unit}</TableCell>
    }

    const openDetail = (stockno) => {
        if (props.detailUpdate != undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate != undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const getAllIds = () => {
        return props.data.map(row => {
            return row.market_company.stock_no
        })
    }

    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 8, paddingRight: 2, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderInpriceStatus(row.inprice.c1l, row.inprice.c1h, row.inprice.c1m, row.inprice.f)}
                                            </TableCell>

                                            {renderIntCell2(row.inprice.free4s, -99999, '百萬元')}
                                            {renderIntCell1(row.inprice.c1l, -1.0)}
                                            {renderIntCell1(row.inprice.c1m, -1.0)}
                                            {renderIntCell1(row.inprice.c1h, -1.0)}
                                            {renderEmpty(row.inprice.free4s, row.inprice.c1l)}


                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 8, paddingRight: 2, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderInpriceStatus(row.inprice.i1l, row.inprice.i1h, row.inprice.i1m, row.inprice.f)}
                                            </TableCell>

                                            {renderIntCell2(row.inprice.ints, -99999.0, '元')}
                                            {renderIntCell1(row.inprice.i1l, -1.0)}
                                            {renderIntCell1(row.inprice.i1m, -1.0)}
                                            {renderIntCell1(row.inprice.i1h, -1.0)}
                                            {renderEmpty(row.inprice.ints, row.inprice.i1l)}

                                            <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 8, paddingRight: 2, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderInpriceStatus(row.inprice.p1l, row.inprice.p1h, row.inprice.p1m, row.inprice.f)}
                                            </TableCell>

                                            {renderIntCell2(row.inprice.eps, -99999.0, '元')}
                                            {renderIntCell1(row.inprice.p1l, -1.0)}
                                            {renderIntCell1(row.inprice.p1m, -1.0)}
                                            {renderIntCell1(row.inprice.p1h, -1.0)}
                                            {renderEmpty(row.inprice.eps, row.inprice.p1l)}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Divider orientation="vertical" flexItem />
                        <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                            匯出
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <TablePagination
                            rowsPerPageOptions={getRowPageData(props.width)}
                            component="div"
                            count={props.total}
                            rowsPerPage={props.currentcount}
                            page={props.currentpage}
                            labelRowsPerPage={'每頁'}
                            labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(InPriceTable);
