import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BrokerList from '../details/BrokerList1';
import ExposureZero from '@material-ui/icons/Clear'
import BColor from '../BColor'


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function BrokerAvgDetail(props) {
    const classes = useStyles();

    const [openstock, setOpenstock] = React.useState(null);
    const [opendealer, setOpenDealer] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opendname, setOpenDname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);

    const handleClickOpencc = (stock_no, stockname, dealer_id, dealername) => event => {
        setOpenstock(stock_no)
        setOpenDealer(dealer_id)
        setOpenSname(stockname)
        setOpenDname(dealername)        
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenDealer(null)
        setOpenSname(null)
        setOpenDname(null) 
        setConcentOpen(false);
    };

    function renderName(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                    <Button  variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbk[index].db.dealer_id, row.avbk[index].name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avbk[index].name}</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderBuy(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbk[index].db.avgbuy / 1000).toFixed())

            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbk[index].db.dealer_id, row.avbk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 張</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderBuy1(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbk[index].db.tbuy / 1000).toFixed())
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbk[index].db.dealer_id, row.avbk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avbk[index].db.tymd} / {word} 張</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderWinprice(row, index, hasEmpty) {
        if (row.avbk != undefined && row.avbk.length > index) {
            let colorx = "#000205"
            let word = "持平 "
            let vv = row.avbk[index].db.abinc

            if (row.avbk[index].db.abinc < -100) {
                colorx = BColor.downcolorbox
                word = "銳減 "
                vv = -vv
            } else if (row.avbk[index].db.abinc >= -100 && row.avbk[index].db.abinc <= 100) {
                colorx = "#000205"
                word = "正常 "
            } else if (row.avbk[index].db.abinc > 200 && row.avbk[index].db.abinc < 800) {
                colorx = BColor.upcolorbox
                word = "激增 "
            } else if (row.avbk[index].db.abinc > 800) {
                colorx = BColor.upcolorbox
                word = "激增 "
            } else {
                colorx = "#000205"
                word = "正常 "
            }

            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{word} {vv}%</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }
 

    function rendersName(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                    <Button  variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avsk[index].db.dealer_id, row.avsk[index].name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avsk[index].name}</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderSell(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avsk[index].db.avgsell / 1000).toFixed())

            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avsk[index].db.dealer_id, row.avsk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 張</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderSell1(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avsk[index].db.tsell / 1000).toFixed())
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avsk[index].db.dealer_id, row.avsk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avsk[index].db.tymd} / {word} 張</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderSWinprice(row, index, hasEmpty) {
        if (row.avsk != undefined && row.avsk.length > index) {
            let colorx = "#000205"
            let word = "持平 "
            let vv = row.avsk[index].db.asinc

            if (row.avsk[index].db.asinc < -100) {
                // colorx = BColor.downcolorbox
                word = "銳減 "
                vv = -vv
            } else if (row.avsk[index].db.asinc >= -100 && row.avsk[index].db.asinc <= 100) {
                colorx = "#000205"
                word = "正常 "
            } else if (row.avsk[index].db.asinc > 200 && row.avsk[index].db.asinc < 800) {
                colorx = BColor.downcolorbox
                word = "激增 "
            } else if (row.avsk[index].db.asinc > 800) {
                colorx = BColor.downcolorbox
                word = "激增 "
            } else {
                colorx = "#000205"
                word = "正常 "
            }

            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{word} {vv}%</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }
 





    function renderbsName(row, index, hasEmpty) {
        if (row.avbsk != undefined && row.avbsk.length > index) {
            let colorx = "#000205"
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                    <Button  variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbsk[index].db.dealer_id, row.avbsk[index].name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{row.avbsk[index].name}</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderBS(row, index, hasEmpty) {
        if (row.avbsk != undefined && row.avbsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbsk[index].db.avgtbs / 1000).toFixed())

            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbsk[index].db.dealer_id, row.avbsk[index].name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 張</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }

    function renderBS1(row, index, hasEmpty) {
        if (row.avbsk != undefined && row.avbsk.length > index) {
            let colorx = "#000205"
            let word = ((row.avbsk[index].db.tbs / 1000).toFixed())
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >
                <Button  onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, row.avbsk[index].db.dealer_id, row.avbsk[index].name)} variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{row.avbsk[index].db.tymd} / {word} 張</Button>
            </StyledTableCell>
        } else if (hasEmpty)
            return <StyledTableCell style={{ whiteSpace: 'nowrap' }}  >-</StyledTableCell>
    }


    return (
        <div>
            <BrokerList open={concentopen} handleClose={handleClosecc} stockId={openstock} dealerId={opendealer} stockname={opensname} dealername={opendname}></BrokerList>

            {props.row.tpbd1 != null && props.row.tpbd1 != undefined ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        買入異常增加
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table size="small" className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {renderName(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'40日均買入'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'40日均買入'}
                                    </StyledTableCell>
                                    {renderBuy(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'今日買入'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近期買入'}
                                    </StyledTableCell>
                                    {renderBuy1(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'增加幅度'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'增加幅度'}
                                    </StyledTableCell>
                                    {renderWinprice(props.row, 0, true)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        賣出異常增加
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table  size="small"  className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {rendersName(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'40日均賣出'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'40日均賣出'}
                                    </StyledTableCell>
                                    {renderSell(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'今日賣出'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近期賣出'}
                                    </StyledTableCell>
                                    {renderSell1(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'增加幅度'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'增加幅度'}
                                    </StyledTableCell>
                                    {renderSWinprice(props.row, 0, true)}
                                </StyledTableRow >


                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        買賣超異常
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table  size="small"  className={classes.table} aria-label="caption table">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <StyledTableRow key={'券商分點'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'券商分點'}
                                    </StyledTableCell>
                                    {renderbsName(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'40日均買賣超'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'40日均買賣超'}
                                    </StyledTableCell>
                                    {renderBS(props.row, 0, true)}
                                </StyledTableRow >

                                <StyledTableRow key={'今日買賣超'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近期買賣超'}
                                    </StyledTableCell>
                                    {renderBS1(props.row, 0, true)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

 
            </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                    很抱歉，近期沒有找到此股票券商分點的資料。
                        </Typography>}
        </div >
    );
}

