import React from 'react';
import ReportDashboard from '../report/ReportDashboard1';


export default function TReportboard(props) {
    return (
        <div>
            {/* <IconBreadcrumbs tp={3}></IconBreadcrumbs> */}
            <ReportDashboard ismobile={props.ismobile}></ReportDashboard>
        </div>
    );
}
