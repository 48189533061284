import React, { useState, useEffect, useRef } from 'react';

import Button from '@material-ui/core/Button'

import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

import BPage from '../BPage'

import Rule470 from './Rule470';
import Grid from '@material-ui/core/Grid';
import Rule471 from './Rule471';
import Rule472 from './Rule472';
import Rule473 from './Rule473';
import Rule474 from './Rule474';
import Rule475 from './Rule475';
import Rule476 from './Rule476';
import Rule477 from './Rule477';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
        overflow: 'auto',
    },
    panelTitle: {
        marginLeft: 10
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: 5,
    },
    input: {
        display: 'none',
    },
    margin: {
        width: '100%',
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}))

function PriceShapeHome(props) {
    const classes = useStyles();

    const handleOpen1 = () => {
        props.tabchange(BPage.donate_page)
    }


    return (
        <div className={classes.root} >
            <div>
                {props.tabchange != undefined ?
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => handleOpen1()}>
                        訂閱
                        </Button> : null
                }

                {props.saveRule1 != undefined ?
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => props.saveRule1()}>
                        儲存規則
                        </Button> : null
                }

                <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => props.clearRule()}>
                    清除規則
                    </Button>
            </div>


            {/* <BigTable open={open} handleClose={handleClose}></BigTable> */}
            <div style={{ fontSize: 12, color: '#8c8c8c' }} >
                <font style={{ color: '#d96f9c' }}>#訂閱專屬。</font>利用量價關係找出符合的型態。<br />
            </div>
            <div className={classes.root1}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule470 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule471 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule472 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule473 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule474 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule475 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule476 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Rule477 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                </Grid>

            </div>

        </div>
    );
}

export default withWidth()(PriceShapeHome);