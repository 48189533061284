import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';

import Button from '@material-ui/core/Button'
import BHighLowSetting from './BHighLowSetting'


echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, SVGRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));


export default function BHighLow(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [open1, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        runFectchAPII()
        setOpen(false);
    };

    useEffect(() => {
        if (props.value === 9) runFectchAPII();
    }, [props.value]);


    function yy() {
        return localStorage.getItem('big_high_low') === null ? "100日" : localStorage.getItem('big_high_low') + "日"
    }


    function genData(m1) {
        let k = genoption1()

        let x1 = {
            data: m1.map(function (item) {
                return item.d;
            })
        }

        let x2 = {
            gridIndex: 1,
            data: m1.map(function (item) {
                return item.d;
            })
        }

        if (props.ismobile) {
            let k1 = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = k1
            x2['axisPointer'] = k1
        }

        k['xAxis'] = [x1, x2]


        let series = []
        let y1 = {
            name: '加權指數',
            type: 'line',
            data: m1.map(function (item) {
                return item.v1;
            }),
            smooth: true,
        }

        let y2 = {
            name: yy() + "創新高",
            type: 'line',
            data: m1.map(v => { return v.v2 }),
            symbol: 'none',
            labelLayout: {
                moveOverlap: 'shiftY'
            },
            endLabel: {
                show: false
            },
            smooth: 'true',
            yAxisIndex: 1,
            xAxisIndex: 1,
        }

        let y3 = {
            name: yy() + "創新低",
            type: 'line',
            data: m1.map(v => { return v.v3 }),
            symbol: 'none',
            labelLayout: {
                moveOverlap: 'shiftY'
            },
            endLabel: {
                show: false
            },
            smooth: 'true',
            yAxisIndex: 1,
            xAxisIndex: 1,
        }


        series.push(y1)
        series.push(y2)
        series.push(y3)

        k['series'] = series


        if (props.ismobile) {
            k['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            k['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }
        } else {
            k['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            k['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }
        }


        return k
    }

    const runFectchAPII = () => {
        let a = {
        };

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        if (localStorage.getItem('big_high_low') != null && localStorage.getItem('big_high_low') != 100)
            a["v1"] = parseInt(localStorage.getItem('big_high_low'))



        fetch("/jstock/v1/stock/bigtable10", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    let k1 = genData(result.r)
                    setOption(k1)
                }
            );
    }

    function genoption1() {
        let k = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
            },
            grid: [{
                top: 30,
                left: 60,
                right: 40,
                height: 220,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            }, {
                top: 330,
                left: 60,
                right: 40,
                height: 250,
                borderWidth: 1,
                borderColor: "#878787",
                show: true
            }],
            yAxis: [
                {
                    type: 'value',
                    scale: true
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    scale: true,
                }
            ],

        }
        return k;
    }

    const handleOpen = () => {
        setOpen(true);
    };


    return (
        <div>
            <BHighLowSetting open={open1} handleClose={handleClose} handleOk={handleOk}></BHighLowSetting>
            <Grid container >
                <Grid item xs={12} sm={12} style={{}}>
                    <div style={{ display: 'flex', marginLeft: 5, marginTop: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            集中市場與創新高/新低數量<Button style={{ marginLeft: 2 }} size="small" color="primary" variant="outlined" onClick={(e) => handleOpen()}>設定</Button>
                        </Typography>
                    </div>
                </Grid>


                {option != null ? <Grid item xs={12} sm={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <ReactEChartsCore
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: 650 }}
                    />
                </Grid> : <div style={{ marginLeft: 5 }} >無集中市場與創新高/新低數量</div>}

            </Grid>
        </div>
    );
}

