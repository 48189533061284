import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import { putInpQueryParam } from '../util/Syno'

import SimpleStockList from '../mobile1/SimpleStockList'
import EasyDialog from '../mobile/EasyDialog'
import SaveRuleDialog from '../dialog/SaveRuleDialog'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Adsense } from '@ctrl/react-adsense';


const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(0.5),
        minWidth: 130,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(0.5),
        marginRight: 2,
        marginLeft: 2
    },
    button1: {
        marginTop: theme.spacing(0.5),
        marginLeft: 0
    },
}));

export default function FindRule(props) {
    const classes = useStyles();
    const [age, setAge] = React.useState(0);
    const [open1, setopen1] = React.useState(false)
    const [openSave, setOpenSave] = React.useState(false)

    const [isLoaded, setLoaded] = React.useState(true);
    const [rules, setRules] = React.useState([])
    const [count, setCount] = React.useState(20)
    const [page, setPage] = React.useState(0)
    const [total, setTotal] = React.useState(0)
    const [error, setError] = React.useState(null)
    const [items, setItems] = React.useState(null)
    const [bigtitle, setBigtitle] = React.useState(null)
    const [time, setTime] = useState(Date.now());
    const [formats, setFormats] = React.useState(() => ['bold', 'italic']);
    const needRefresh = React.useRef(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            openRuleDialog(1)
        } else if (newValue === 1) {
            openRuleDialog(2)
        } else if (newValue === 2) {
            openRuleDialog(10)
        } else if (newValue === 3) {
            openRuleDialog(12)
        } else if (newValue === 4) {
            openRuleDialog(13)
        } else if (newValue === 5) {
            handleSaveRule1()
        } else if (newValue === 6) {
            clearRuleData()
        }
    };

    const detailUpdate = (value) => {
        needRefresh.current = !value
    }

    const openRuleDialog = (page) => {
        setAge(page);
        setopen1(true)
    };

    const handleClose1 = () => {
        setopen1(false);
    };

    const handleSaveClose = () => {
        setOpenSave(false)
    };

    const handleSaveRule1 = () => {
        setOpenSave(true)
    };

    const updateRuleData = (id, data, enabled) => {
        if (enabled) {
            onAddItem(id, data);
        } else {
            onRemoveItem(id);
        }
    };

    const onRemoveItem = id => {
        const rulesa = rules.filter(item => item.id !== id);

        if (rulesa.length === 0) {
            clearRuleData()
        } else {
            runFectchAPII(rulesa, 0, count)
            setPage(0)
            setRules(rulesa)
        }
    };

    const clearRuleData = () => {
        setLoaded(false)
        setItems(null)
        setError(null)
        setRules([])
    };


    const onAddItem = (id, data) => {
        const rulesa = rules.filter(item => item.id !== id);
        const finalRules = rulesa.concat(data);
        runFectchAPII(finalRules, 0, count)

        setPage(0)
        setRules(finalRules)
    };


    useEffect(() => {
        if (localStorage.getItem("refreshr") === 'true') {
            const interval = setInterval(() => {
                let h = moment().hour()
                let w = moment().day()
                let isRun = h >= 9 && h < 14 && w > 0 && w < 6 && localStorage.getItem("refreshr") === 'true'
                if (isRun && needRefresh.current) runFectchAPII(rules, page, count)
            }, 25000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [rules, page, count]);


    const runFectchAPII = (rules, pages, count) => {
        if (rules.length === 0) {
            setLoaded(true)
            setItems(null)
            setError(null)
            setRules([])
        } else {
            setLoaded(false)
            let a = {
                rules: rules,
                page: pages,
                count: count,
                inp: {}
            };

            if (localStorage.getItem('lic') != null)
                a["lic"] = localStorage.getItem('lic')
            if (localStorage.getItem('token') != null)
                a["token"] = localStorage.getItem('token')

            a = putInpQueryParam(a)
            fectchAPI(a);
        }
    }

    const fectchAPI = (param) => {
        fetch("/jstock/v1/stock/rules", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setLoaded(true)
                        setItems(result.company)
                        setTotal(result.total)
                        setBigtitle(result.bigtitle)
                        setError(null)
                    } else {
                        this.setState({ open: true });
                    }
                },
                error => {
                    setLoaded(true)
                    setError(error)
                }
            );
    }

    const pageupdate = (page, count) => {
        runFectchAPII(rules, page, count)
        setPage(page)
        setCount(count)
    }

    const getNoAd = () => {
        return localStorage.getItem('noad') != null ? localStorage.getItem('noad') : "0"
    }

    const openGame = () => {
        window.open("https://game.stock-digger.com/#open", "_blank", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1024,height=800");
    }

    function createTable() {
        if (rules.length > 0 && (items === null || items.length === 0) && isLoaded) {
            return <div style={{ marginTop: 5, marginLeft: 5 }} >
                <Typography variant="body1" >挖掘不到任何股票。條件太嚴苛或者是太多了嗎？</Typography>
                {/* {getNoAd() === "0" && <img src="promotion2.png" onClick={e => openGame()} />} */}
                {getNoAd() === "0" && <Adsense
                    client="ca-pub-8613274553211553"
                    slot="2170068380"
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                />}
            </div>
        } else if (items != null && items.length > 0) {
            return <SimpleStockList detailUpdate={detailUpdate} total={total} pageupdate={pageupdate} currentpage={page} currentcount={count} items={items} bigtitle={bigtitle} />
        } else if (getNoAd() === "0") {
            return <div>
                {/* <img src="promotion2.png" onClick={e => openGame()} /> */}
                <Adsense
                    client="ca-pub-8613274553211553"
                    slot="2170068380"
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                />
            </div>
        }
    }


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <SaveRuleDialog ismobile={props.ismobile} rules={rules} open={openSave} handleClose={handleSaveClose}></SaveRuleDialog>
            <EasyDialog ismobile={props.ismobile} open={open1} handleClose={handleClose1} saveRule1={handleSaveRule1} updateRule={updateRuleData} rules={rules} clearRule={clearRuleData} tp={age} onRemoveItem={onRemoveItem} />

            <Tabs
                style={{}}
                variant="scrollable"
                scrollButtons="on"
                value={value}
                onChange={handleChange} >
                <Tab label="輕鬆選" {...a11yProps(0)} />
                <Tab label="股民私藏" {...a11yProps(1)} />
                <Tab label="產業別" {...a11yProps(2)} />
                <Tab label="進階選" {...a11yProps(3)} />
                <Tab label="型態選" {...a11yProps(4)} />
                <Tab label="儲存" {...a11yProps(5)} />
                <Tab label="清除" {...a11yProps(6)} />
            </Tabs>

            {createTable()}
        </div>
    );
}
