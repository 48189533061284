import React, { useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
    DatasetComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import zhtwLocale from "date-fns/locale/zh-TW";
import Button from '@material-ui/core/Button';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import BColor from '../BColor'
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import DataTable from 'react-data-table-component';
import * as dayjs from 'dayjs'
import CreateNewTran from './CreateNewTran';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CustomMaterialMenu from './CustomMaterialMenu'
import UpdateTran from './UpdateTran'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const getPriceColor = (value) => {
    if (value >= 0) return BColor.upcolorbox
    else return BColor.downcolorbox
}


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateReport1(props) {
    const classes = useStyles();
    const theme = useTheme();


    const [options, setOptions] = React.useState(null)
    const [tlog, setTLog] = React.useState(null)
    const [id, setId] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const [stopEarn, setstopEarn] = React.useState("");
    const [stopEarnp, setstopEarnp] = React.useState("");
    const [stopLost, setstopLost] = React.useState("");
    const [stopLostp, setstopLostp] = React.useState("");
    const [stopmove, setStopMove] = React.useState("");
    const [instrage, setInstrage] = React.useState("");
    const [outstrage, setOutstrage] = React.useState("");
    const [smemo, setSMemo] = React.useState("");
    const [rules, setRules] = React.useState([]);

    const [msgopen, setMsgOpen] = React.useState(false);
    const [errmsg, setErrMsg] = React.useState(null);

    const [showUpdateDia, setUpdateDia] = React.useState(false);
    const [updateData, setUpdateData] = React.useState(null);

    const handleCloseDia = () => {
        setUpdateDia(false);
        getOne()
    };


    function getLoveRules() {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
        } : {
            synno: synno,
            lic: localStorage.getItem('lic'),
        };


        fetch("/jstock/v1/stock/getloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setRules(result.data.allrules)
                    }
                }
            );
    }

    function makeid1(length) {
        var result = '';
        var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function getSynNo() {
        let id = makeid1(7) + "000"
        localStorage.setItem("synno", id)
        return id
    }

    useEffect(() => {
        if (props.open) getLoveRules()
    }, [props.open]);



    function getXX(tlog) {
        if (tlog != null && tlog.kl != null) {
            let data = tlog.kl.map(vv => {
                return vv.x
            })
            return data
        } else return []
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)
    function getYY1(tlog) {
        if (tlog != null && tlog.kl != null) {
            let data = tlog.kl.map(vv => {
                return vv.y
            })
            return data
        } else return []
    }

    function getMarkLabel(param) {
        if (param.name === '0') {
            return '買'// + param.value
        } else if (param.name === '1') {
            return '賣'// + param.value
        } else return ''//param.value
    }

    function getDefaultAChartOption(tlog) {
        let option = {
            legend: {
                show: true
            },
            grid: [
                {
                    top: '30',
                    left: '0',
                    right: '20',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    max: tlog.max.toFixed(2).parseFloat,
                    min: tlog.min.toFixed(2).parseFloat,
                    axisLabel: {
                        inside: true,
                        margin: 0
                    },
                }
            ],
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(tlog),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    marginTop: 45,
                    color: '#B80C00'
                }
            }
        }

        option['xAxis'] = [x1]

        if (tlog != null && tlog.kl != null) {
            let data = []
            if (tlog.tl != null && tlog.tl.strage != null && tlog.tl.strage.stopearn != null && tlog.tl.strage.stopearn != "") {
                data.push(
                    {
                        name: '停利',
                        yAxis: tlog.tl.strage.stopearn,
                        lineStyle: {
                            color: BColor.upcolorbox,
                            type: 'solid',
                            width: 3,
                            cap: 'round'
                        },
                        label: {
                            formatter: '{b}',
                            position: 'insideStartBottom',
                            color: "#FFFFFF",
                            backgroundColor: BColor.upcolorbox,
                            padding: 3
                        }
                    }
                )
            }

            if (tlog.tl != null && tlog.tl.strage != null && tlog.tl.strage.stoplost != null && tlog.tl.strage.stoplost != "") {
                data.push(
                    {
                        name: '停損',
                        yAxis: tlog.tl.strage.stoplost,
                        lineStyle: {
                            color: BColor.downcolorbox,
                            type: 'solid',
                            width: 3,
                            cap: 'round'
                        },
                        label: {
                            formatter: '{b}',
                            position: 'insideStartBottom',
                            color: "#FFFFFF",
                            backgroundColor: BColor.downcolorbox,
                            padding: 3
                        }
                    }
                )
            }

            if (tlog.tl != null && tlog.tl.mp != null && tlog.tl.mp != "") {
                data.push(
                    {
                        name: '移動停利',
                        yAxis: tlog.tl.mp,
                        lineStyle: {
                            color: "#e340bd",
                            // type: 'solid',
                            width: 3,
                            cap: 'round'
                        },
                        label: {
                            formatter: '{b}',
                            position: 'insideStartBottom',
                            color: "#FFFFFF",
                            backgroundColor: "#e340bd",
                            padding: 3
                        }
                    }
                )
            }

            let trandata = []
            if (tlog.tl != null && tlog.tl.tran != null) {
                tlog.tl.tran.map(tt => {
                    let ymd = dayjs(tt.ymd).format('YYYYMMDD')
                    trandata.push({
                        name: tt.bs + "",
                        coord: [ymd, tt.p],
                        value: tt.p,
                        itemStyle: {
                            color: tt.bs === 0 ? "#f04422" : "#1a9fad"
                        },
                        symbol: 'pin',
                        // symbolSize: [40, 20],
                        symbolOffset: [0, -20]
                    })
                })
            }

            option['series'] = [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: getYY1(tlog),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    markLine: {
                        data: data
                    },
                    markPoint: {
                        label: {
                            normal: {
                                formatter: function (param) {
                                    return param != null ? getMarkLabel(param) : ''; //Math.round(param.value)
                                }
                            },
                        },
                        data: trandata
                    }
                },
                {
                    name: 'MA5',
                    type: 'line',
                    data: tlog.kl.map(vv => {
                        return vv.a1
                    }),
                    showSymbol: false,
                }, {
                    name: 'MA10',
                    type: 'line',
                    data: tlog.kl.map(vv => {
                        return vv.a2
                    }),
                    showSymbol: false,
                }, {
                    name: 'MA20',
                    type: 'line',
                    data: tlog.kl.map(vv => {
                        return vv.a3
                    }),
                    showSymbol: false,
                },
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0],
                    start: 20,
                    end: 100
                }
            ]
        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: 10 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0],
                    start: 20,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: [0],
                    type: 'slider',
                    top: '320',
                    start: 20,
                    end: 100
                }
            ]
        }

        return option
    }


    useEffect(() => {
        if (props.id != null) getOne()
    }, [props.now]);

    function getString() {
        return "無資料"
    }


    function getTranType(tp) {
        if (tp === 0) return '現股'
        else if (tp === 1) return '融資'
        else if (tp === 2) return '融券'
        else return ''
    }

    const deleteHandler = (row) => {
        let a = {
            "id": props.id,
            "id1": row.id
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')


        fetch("/jstock/v1/stock/treport1/deltran", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        getOne()
                    } else if (result.status === -1) {
                        setErrMsg("交易明細不存在。")
                        setMsgOpen(true)
                    } else if (result.status === -2) {
                        setErrMsg("訂閱專屬功能。")
                        setMsgOpen(true)
                    } else {
                        setErrMsg("發生錯誤，請稍後嘗試")
                        setMsgOpen(true)
                    }
                }
            );
    }

    const updateHandler = (row) => {
        setUpdateData(row)
        setUpdateDia(true)
    }

    const columns1 = [
        {
            cell: row => <CustomMaterialMenu id={props.id} row={row} onUpdateRow={updateHandler} onDeleteRow={deleteHandler} />,
            allowOverflow: true,
            button: true,
            width: '56px', // custom width for icon button
        },
        {
            name: '成交日期',
            selector: 'ymd',
            sortable: true,
        },
        {
            name: '交易類別',
            selector: 'tp',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{getTranType(row.tp)}</div>,
        },
        {
            name: '交易買賣',
            selector: 'bs',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: row.bs === 0 ? BColor.upcolorbox : BColor.downcolorbox }} >{row.bs === 0 ? "買" : "賣"}</div>,
        },
        {
            name: '成交價格',
            selector: 'p',
            sortable: true,
            right: true,
        },
        {
            name: '成交股數',
            selector: 'num',
            sortable: true,
            right: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.num}股</div>,
        },
        {
            name: '交割金額',
            sortable: true,
            selector: 'cost',
            right: true,
        },
        {
            name: '損益',
            sortable: true,
            selector: 'profit',
            right: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.profit) }} >{row.profit}</div>,
        },
        {
            name: '報酬率',
            sortable: true,
            selector: 'roi',
            right: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.roi) }} >{row.roi != null ? row.roi + '%' : ''}</div>,
        },
        {
            name: '備註',
            selector: 'memo',
            sortable: true,
            maxWidth: '500px',
            width: '500px',
            // cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.riskr}</div>,
        }
    ];

    const getData1 = () => {
        if (tlog != null && tlog.tl != null && tlog.tl.tran != null) {
            return tlog.tl.tran
        } else return []
    }

    const getOne = () => {
        let a = {
            id: props.id,
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        fetch("/jstock/v1/stock/treport1/getone", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setTLog(result)
                        setOptions(getDefaultAChartOption(result))
                        if (result.tl != null && result.tl.strage != null) {
                            if (result.tl.strage.ins != null) setInstrage(result.tl.strage.ins)
                            // ; else setInstrage("")
                            if (result.tl.strage.outs != null) setOutstrage(result.tl.strage.outs)
                            // ; else setOutstrage("")
                            if (result.tl.strage.stopearn != null) setstopEarn(result.tl.strage.stopearn)
                            // ; else setstopEarn("")
                            if (result.tl.strage.stoplost != null) setstopLost(result.tl.strage.stoplost)
                            // ; else setstopLost("")
                            if (result.tl.strage.move != null) setStopMove(result.tl.strage.move)
                            // ; else setStopMove("")
                            if (result.tl.memo != null) setSMemo(result.tl.memo)
                            // ; else setSMemo("")
                        }
                        // else {
                        //     setInstrage("")
                        //     setOutstrage("")
                        //     setstopEarn("")
                        //     setstopLost("")
                        //     setStopMove("")
                        //     setSMemo("")
                        // }
                    }
                }
            );
    }

    const handleClose = () => {
        setOpen(false);
        getOne()
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const updateReport = () => {
        let a = {
            "id": props.id,
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')

        if (instrage != null && instrage != "")
            a["ins"] = instrage
        if (outstrage != null && outstrage != "")
            a["outs"] = outstrage
        if (stopEarn != null && stopEarn != "")
            a["stopearn"] = stopEarn
        if (stopEarnp != null && stopEarnp != "")
            a["stopearnp"] = stopEarnp
        if (stopLost != null && stopLost != "")
            a["stoplost"] = stopLost
        if (stopLostp != null && stopLostp != "")
            a["stoplostp"] = stopLostp
        if (stopmove != null && stopmove != "")
            a["move"] = stopmove
        if (smemo != null && smemo != "")
            a["memo"] = smemo

        fetch("/jstock/v1/stock/treport1/updatestrage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        // setstopEarn(null)
                        // setstopLost(null)
                        // setstopEarnp(null)
                        // setstopLostp(null)
                        // setInstrage(null)
                        // setOutstrage(null)
                        // setSMemo(null)

                        props.close()
                    } else if (result.status === -1) {
                        setErrMsg("交易日誌不存在，請重新新增交易日誌。")
                        setMsgOpen(true)
                    } else if (result.status === -2) {
                        setErrMsg("訂閱專屬功能。")
                        setMsgOpen(true)
                    } else {
                        setErrMsg("發生錯誤，請稍後嘗試")
                        setMsgOpen(true)
                    }
                }
            );
    }

    const handleMsgClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrMsg(null)
        setMsgOpen(false);
    };

    const getRealString = (real) => {
        if (real === 0) return "實單"
        else return "模擬單"
    }

    const customStyles = {
        header: {
            style: {
                fontSize: "36px",
                fontWeight: "bold",
                paddingLeft: 0
            },
        },
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };

    return (
        <Dialog style={{}} fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
            <DialogContent dividers style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000", paddingLeft: 4, paddingRight: 4 }}>
                <CreateNewTran id={props.id} open={open} close={handleClose}></CreateNewTran>
                <UpdateTran id={props.id} row={updateData} open={showUpdateDia} close={handleCloseDia}></UpdateTran>

                <Snackbar open={msgopen} autoHideDuration={6000} onClose={handleMsgClose}>
                    <Alert onClose={handleMsgClose} severity="error">
                        {errmsg}
                    </Alert>
                </Snackbar>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhtwLocale}>
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 10 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            {tlog != null && tlog.tl != null ? tlog.tl.name + '（' + tlog.tl.stock + '）' + getRealString(tlog.tl.real) + '交易日誌' : '交易日誌'}
                        </Typography>
                    </div>

                    <Grid container spacing={1} >
                        <Grid item xs={12} sm={12} >
                            {options != null ? <ReactEChartsCore
                                theme={theme.palette.type}
                                style={{ height: 370, marginTop: 0 }}
                                echarts={echarts}
                                option={options}
                                notMerge={true}
                                lazyUpdate={false}
                            /> : <div style={{}}>{getString()}</div>}
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: 35 }} />


                    <DataTable
                        theme={theme.palette.type}
                        customStyles={customStyles}
                        // style={{ borderWidth: 1, borderColor: "#dedede", borderStyle: "solid" }}
                        onRowClicked={updateHandler} title={<div style={{ display: 'flex', }}>
                            <Typography className={classes.title} variant="h6" id="tableTitle">
                                交易明細
                                <Button onClick={e => handleClickOpen()} variant="outlined" style={{ marginLeft: 5 }} color="secondary" size="small" >新增交易</Button>
                            </Typography>
                        </div>} pagination columns={columns1} data={getData1()} fixedHeader fixedHeaderScrollHeight="300px" />


                    <div style={{ display: 'flex', marginTop: 20, padding: 20, marginBottom: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            進出場策略
                        </Typography>
                    </div>

                    <Grid container spacing={1} style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Grid item xs={6} sm={4} md={4}>
                            <TextField
                                label="停利價"
                                id="stopearn"
                                value={stopEarn}
                                onChange={event => setstopEarn(event.target.value)}
                                fullWidth={true}
                            />
                        </Grid>


                        <Grid item xs={6} sm={4} md={4}>
                            <TextField
                                label="停損價"
                                id="stoplost"
                                value={stopLost}
                                fullWidth={true}
                                onChange={event => setstopLost(event.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4} md={4}>
                            <TextField
                                label="移動停利"
                                id="stopmove"
                                value={stopmove}
                                onChange={(event) => setStopMove(event.target.value)}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4} md={4}>
                            <TextField
                                label="進場策略"
                                id="instrage"
                                fullWidth={true}
                                value={instrage}
                                onChange={(event) => setInstrage(event.target.value)}
                                select
                            >
                                <MenuItem value="">
                                    <em>無策略</em>
                                </MenuItem>
                                {/* <MenuItem key={"無"} name={"無"} value={""} >{"無"}</MenuItem> */}
                                {rules.map((data, index) => { return (<MenuItem key={data.id + index} name={data.name} value={data.id} >{data.name}</MenuItem>) })}
                            </TextField>
                        </Grid>

                        <Grid item xs={6} sm={4} md={4}>
                            <TextField
                                label="出場策略"
                                id="outstrage"
                                fullWidth={true}
                                value={outstrage}
                                onChange={(event) => setOutstrage(event.target.value)}
                                select
                            >
                                <MenuItem value="">
                                    <em>無策略</em>
                                </MenuItem>
                                {/* <MenuItem key={"無"} name={"無"} value={""} >{"無"}</MenuItem> */}
                                {rules.map((data, index) => { return (<MenuItem key={data.id + index} name={data.name} value={data.id} >{data.name}</MenuItem>) })}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                id="smemo"
                                label="策略備註"
                                placeholder=""
                                helperText=""
                                fullWidth={true}
                                value={smemo}
                                onChange={(event) => setSMemo(event.target.value)}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>

                </MuiPickersUtilsProvider>

            </DialogContent>
            <DialogActions style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000" }}>
                <Button onClick={props.close} color="primary">
                    關閉
                </Button>
                <Button color="primary" onClick={e => updateReport()}>
                    更新
                </Button>
            </DialogActions>
        </Dialog>
    );
}