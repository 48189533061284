import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import * as dayjs from 'dayjs'
import "dayjs/locale/zh-tw";
import BColor from '../BColor'

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';


echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

export default function LoanDetail1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)

    function getXX() {
        if (props.row.loan != null && props.row.loan.fin != null &&
            props.row.kd3.d.length >= props.row.loan.fin.length) {
            let data = props.row.kd3.d.slice(-props.row.loan.fin.length).map(vv => {
                return vv.x
            })
            return data
        } else return []
    }

    function getYY1() {
        if (props.row.loan != null && props.row.loan.fin != null &&
            props.row.kd3.d.length >= props.row.loan.fin.length) {

            let data = props.row.kd3.d.slice(-props.row.loan.fin.length).map(vv => {
                return vv.y
            })
            return data
        } else return []
    }


    function getDefaultAChartOption(data) {
        let option = {
            // legend: {
            //     data: ['昂貴區', '合理區', '便宜區', '股價',]
            // },
            title: [
                {
                    left: 'center',
                    text: '日K線',
                    top: '0',
                },
                {
                    left: 'center',
                    text: '融資',
                    top: '280',
                },
                {
                    left: 'center',
                    text: '融券',
                    top: '390',
                },
                {
                    left: 'center',
                    text: '借券賣出',
                    top: '500',
                },
                {
                    left: 'center',
                    text: '融資維持率',
                    top: '610',
                },
            ],

            grid: [
                {
                    top: '30',
                    left: '45',
                    right: '45',
                    height: '200',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '310',
                    left: '45',
                    right: '45',
                    height: '50',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '420',
                    left: '45',
                    right: '45',
                    height: '50',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '530',
                    left: '45',
                    right: '45',
                    height: '50',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '650',
                    left: '45',
                    right: '45',
                    height: '100',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    show: true,
                    gridIndex: 1,
                    scale: true,
                    type: 'value'
                },
                {
                    show: true,
                    gridIndex: 2,
                    scale: true,
                    type: 'value'
                },
                {
                    show: true,
                    gridIndex: 3,
                    scale: true,
                    type: 'value'
                },
                {
                    show: true,
                    gridIndex: 4,
                    scale: true,
                    type: 'value'
                }
            ],
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        }

        let x2 = {
            gridIndex: 1,
            scale: true,
            type: 'category',
            data: data.fin.map(v => { return v.x }),
        }

        let x3 = {
            gridIndex: 2,
            scale: true,
            type: 'category',
            data: data.mar.map(v => { return v.x }),
        }

        let x4 = {
            gridIndex: 3,
            scale: true,
            type: 'category',
            data: data.bro.map(v => { return v.x }),
        }

        let x5 = {
            gridIndex: 4,
            scale: true,
            type: 'category',
            data: data.mrate.map(v => { return v.x }),
        }

        if (props.ismobile) {
            let vv = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            let vv1 = {
                show: true,
            }

            x1['axisPointer'] = vv
            x2['axisPointer'] = vv1
            x3['axisPointer'] = vv1
            x4['axisPointer'] = vv1
            x5['axisPointer'] = vv
        }

        option['xAxis'] = [x1, x2, x3, x4, x5]

        if (data != null) {
            option['series'] = [
                {
                    name: '日K',
                    type: 'candlestick',
                    data: getYY1(),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: '融資餘額',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.fin.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: 'true'
                },
                {
                    name: '融券餘額',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.mar.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    smooth: 'true'
                },
                {
                    name: '借券賣出餘額',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.bro.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 3,
                    yAxisIndex: 3,
                    smooth: 'true'
                },
                {
                    name: '融資維持率',
                    type: 'line',
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.mrate.map(v => { return v.y }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    endLabel: {
                        show: true
                    },
                    xAxisIndex: 4,
                    yAxisIndex: 4,
                    smooth: 'true',
                    markLine: {
                        label: {
                            show: false
                        },
                        symbol: 'none',
                        data: [
                            {
                                name: '166',
                                yAxis: 166,
                                lineStyle: {
                                    color: "#a1a1a1",
                                    // type: 'solid',
                                    width: 2,
                                    // cap: 'round'
                                }
                            }
                        ]
                    },
                }
            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

        } else {
            option['axisPointer'] = {
                link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            // option['dataZoom'] = [
            //     {
            //         type: 'inside',
            //         xAxisIndex: [0, 1, 2],
            //         start: 60,
            //         end: 100
            //     },
            //     {
            //         show: true,
            //         xAxisIndex: [0, 1, 2],
            //         type: 'slider',
            //         top: '720',
            //         start: 60,
            //         end: 100
            //     }
            // ]
        }

        return option
    }


    useEffect(() => {
        if (props.row.loan != null &&
            (props.row.loan.fin.length > 0 || props.row.loan.mrate.length > 0 || props.row.loan.bro.length > 0)) setOptions(getDefaultAChartOption(props.row.loan))
    }, [props.row.loan]);

    function getString() {
        return ""
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >

                    {props.row.loan != undefined && options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 850, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

                <Grid item xs={12} sm={8}>
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            融資/融券明細
                        </Typography>
                    </div>
                    {props.row.loan != undefined ? <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>日期</StyledTableCell>
                                    <StyledTableCell align="left">融資餘額</StyledTableCell>
                                    <StyledTableCell align="left">融資增減</StyledTableCell>
                                    <StyledTableCell align="left">融券餘額</StyledTableCell>
                                    <StyledTableCell align="left">融券增減</StyledTableCell>
                                    <StyledTableCell align="left">券資比</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.row.loan.mlist.map(data => {
                                    return <StyledTableRow key={data.ymd + 'b'}>
                                        <StyledTableCell component="th" scope="row">
                                            {data.ymd}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {data.fin_balance}張
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <font style={{ color: data.finance < 0 ? BColor.downcolorbox : BColor.upcolorbox }}>{data.finance}張</font>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {data.mar_balance}張
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <font style={{ color: data.margint < 0 ? BColor.downcolorbox : BColor.upcolorbox }}>{data.margint}張</font>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {((data.mar_balance * 100) / data.fin_balance).toFixed(2)}%
                                        </StyledTableCell>
                                    </StyledTableRow >
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> : <div style={{ paddingLeft: 10 }}>{"暫無任何融資/融券明細"}</div>}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            借券賣出明細
                        </Typography>
                    </div>
                    {props.row.loan != undefined ? <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>日期</StyledTableCell>
                                    <StyledTableCell align="left">借券賣出餘額</StyledTableCell>
                                    <StyledTableCell align="left">當日增減</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.row.loan.plist.map(data => {
                                    return <StyledTableRow key={data.ymd + 'p'}>
                                        <StyledTableCell component="th" scope="row">
                                            {data.ymd}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {(data.fin / 1000).toFixed(0)}張
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <font style={{ color: ((data.sell - data.back + data.tun) / 1000).toFixed(0) < 0 ? BColor.downcolorbox : BColor.upcolorbox }}>{((data.sell - data.back + data.tun) / 1000).toFixed(0)}張</font>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> : <div style={{ paddingLeft: 10 }}>{"暫無任何借券賣出明細"}</div>}
                </Grid>

                <Grid item xs={12} sm={4}>
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            融資維持率(推估)
                        </Typography>
                    </div>
                    {props.row.loan != undefined ? <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>日期</StyledTableCell>
                                    <StyledTableCell align="left">融資維持率</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.row.loan.mrate.slice(0).reverse().map(data => {
                                    return <StyledTableRow key={data.x + 'a'}>
                                        <StyledTableCell component="th" scope="row">
                                            {data.x}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {data.y}%
                                        </StyledTableCell>
                                    </StyledTableRow >
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer> : <div style={{ paddingLeft: 10 }}>{"暫無任何借券賣出明細"}</div>}
                </Grid>

            </Grid>
        </div >
    );
}