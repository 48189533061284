import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Selector from "../menu/Selector"
import './RadioBox.css'
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from '@material-ui/core/CardHeader';

const styles = theme => ({
    card: {
        // minWidth: 260,
        marginBottom: 15,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
    },
    pos: {
        marginTop: 10,
        color: "#777777",
    }
});

class Rule2ADesc extends React.Component {

    constructor(props) {
        super(props);
        let rule = this.getRule()

        if (rule != null) {
            this.state = {
                s1: rule.s1,
                s2: rule.s2,
                enable: true,
                option1: [],
                option2: [],
            };
        } else {
            this.state = {
                s1: 'A0041',
                s2: '*',
                enable: false,
                option1: [],
                option2: [],
            };
        }
    }

    componentDidMount() {
        fetch("/jstock/v1/stock/fund/broker", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            // body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.length > 0) {
                        this.setState({ option1: result, s1: result[0].id })
                        this.v1Change(result[0].id)
                    }
                }
            );
    }


    handleSwitchChange = event => {
        this.handleCardChange(event.target.checked);
    };

    clickTitle = () => {
        this.handleCardChange(!this.state.enable);
    };


    v1Change = (value) => {
        this.setState({ s1: value, s2: "*" })

        let param = {
            cid: value
        }

        fetch("/jstock/v1/stock/fund/fundmenu", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.length > 0)
                        this.setState({ option2: result, s2: result[0].id })
                }
            );

        if (this.state.enable) {
            this.updateValue(true, value, '*')
            // this.updateValue(true, value, this.state.s2)
        }
    }

    v2Change = (value) => {
        this.setState({ s2: value })

        if (this.state.enable) {
            this.updateValue(true, this.state.s1, value)
        }
    }


    handleCardChange = (enabled) => {
        this.setState({ enable: enabled });
        this.updateValue(enabled, this.state.s1, this.state.s2)
    }


    updateValue = (enabled, s1, s2) => {
        let data = {
            id: this.props.id,
            s1: s1,
            s2: s2,
        }

        this.props.ruleChange(this.props.id, data, enabled)
    }

    getRule = () => {
        if (this.props.rules != undefined) {
            const findId = id => (element, index, array) => {
                return element.id === id;
            }

            let rule = this.props.rules.find(findId(this.props.id))
            return rule;
        } else {
            return null
        }
    }

    isEmpty = () => {
        if (this.props.rules != undefined) {
            if (this.props.rules.length === 0) {
                this.setState({ enable: false });
                return true
            } else {
                return false
            }
        } else {
            this.setState({ enable: false });
            return true
        }
    }

    render() {
        const { classes } = this.props;
        let {
            s1,
            s2,
            enable,
            option1,
            option2,
        } = this.state;

        return (
            <Card className={classes.card} >
                <CardHeader
                    avatar={
                        <Checkbox
                            id={this.props.id}
                            checked={enable && !this.isEmpty()}
                            onChange={this.handleSwitchChange}
                            value="checkedA"
                            ref="in"
                            style={{ color: "#54A0CC", paddingLeft: 0, paddingRight: 0 }}
                        />
                    }
                    title={
                        <Typography variant="subtitle2" >
                            {this.props.t1}
                            {option1.length > 0 ?
                            <Selector d1={option1} mumChange={this.v1Change} selectedV={s1} />
                            :null}
                            {this.props.t2}
                            {option2.length > 0 ?
                            <Selector d1={option2} mumChange={this.v2Change} selectedV={s2} />
                            :null}
                            {this.props.t3}
                        </Typography>
                    }

                />
            </Card>
        );
    }
}

Rule2ADesc.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Rule2ADesc);