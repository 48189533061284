import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BigTable1 from './BigTable1';
import LoanTable from './LoanTable'
import UpAvg from './UpAvg';
import Badge from '@material-ui/core/Badge';
import Nine from './Nine';
import WinRate from './WinRate'
import BTurning from './BTurning';
import PeopleTable from './PeopleTable'
import FutureTable from './FutureTable';
import ADLTable from './ADLTable';
import BHighLow from './BHighLow';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box style={{ paddingLeft: 10, paddingRight: 2, paddingTop: 20, paddingBottom: 20 }} p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
        // backgroundColor: theme.palette.background.default,
    },
}));

export default function BigDetails(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [subtabIndex, setSubtabIndex] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue)
        setSubtabIndex(0)
    };

    return (
        <div className={classes.root}>
            <AppBar position="static"  color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab label="均線交叉" {...a11yProps(0)} value={0}/>
                    <Tab label="站上均線率" {...a11yProps(1)} value={1}/>
                    <Tab label="騰落線" {...a11yProps(2)} value={2}/>
                    <Tab label={<Badge className={classes.padding} badgeContent={"pro"} >
                        <div >融資維持率</div>
                    </Badge>} {...a11yProps(3)} />
                    <Tab label={<Badge className={classes.padding}  badgeContent={"pro"}>
                        <div >九轉序列圖</div>
                    </Badge>} {...a11yProps(4)} />
                    <Tab label={<Badge className={classes.padding}  badgeContent={"pro"}>
                        <div >期望指標</div>
                    </Badge>} {...a11yProps(5)} />
                    <Tab label={<Badge className={classes.padding}  badgeContent={"pro"}>
                        <div >轉折波</div>
                    </Badge>} {...a11yProps(6)} />
                    <Tab label={<Badge className={classes.padding}  badgeContent={"pro"}>
                        <div>股東人數</div>
                    </Badge>} {...a11yProps(7)} />
                    <Tab label={<Badge className={classes.padding}  badgeContent={"pro"}>
                        <div>轉折預測</div>
                    </Badge>} {...a11yProps(8)} />
                    <Tab label={<Badge className={classes.padding}  badgeContent={"pro"}>
                        <div>創新高/低數量</div>
                    </Badge>} {...a11yProps(9)} />
                    {/* style={{ color: '#f72a68' }}  style={{ color: "#8f8f8f" }} */}
                </Tabs>
            </AppBar>

            {value === 0 ? <TabPanel value={value} index={0}>
                <BigTable1 value={value} ismobile={props.ismobile} />
            </TabPanel> : null}

            {value === 1 ? <TabPanel value={value} index={1}>
                <UpAvg value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}

            {value === 2? <TabPanel value={value} index={2}>
                <ADLTable value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}

            {value === 3 ? <TabPanel value={value} index={3}>
                <LoanTable value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}
            {value === 4 ? <TabPanel value={value} index={4}>
                <Nine value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}   
            {value === 5 ? <TabPanel value={value} index={5}>
                <WinRate value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}             
            {value === 6 ? <TabPanel value={value} index={6}>
                <BTurning value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}
            {value === 7 ? <TabPanel value={value} index={7}>
                <PeopleTable value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}
            {value === 8 ? <TabPanel value={value} index={8}>
                <FutureTable value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}
            {value === 9 ? <TabPanel value={value} index={9}>
                <BHighLow value={value}  ismobile={props.ismobile}/>
            </TabPanel> : null}
        </div>
    );
}
