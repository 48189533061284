import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Motorcycle from '@material-ui/icons/Motorcycle'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ListAlt from '@material-ui/icons/ListAlt'
import Voicemail from '@material-ui/icons/Voicemail'
import Build from '@material-ui/icons/Build'
import Cake from '@material-ui/icons/Cake'
import Keyboard from '@material-ui/icons/Keyboard'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import LocalDining from '@material-ui/icons/LocalDining'
import NaturePeople from '@material-ui/icons/NaturePeople'

import KDSetting from './KDSetting'
import MACDSetting from './MACDSetting'
import BColor from '../BColor'
import BPage from '../BPage'
import HappySetting from './HappySetting'
import DMISetting from './DMISetting'
import WilliamSetting from './WilliamSetting'
import EMASetting from './EMASetting'
import BBSetting from '../details/BBSetting'
import RuleTp4 from '../ruletp/RuleTp4';

import {
    TECH_1, TECH_2, TECH_3, TECH_4, TECH_5, TECH_6, TECH_7, TECH_8,
    TECH_9, TECH_10, TECH_11, TECH_12, TECH_13, TECH_14, TECH_15, TECH_16,
    TECH_17
} from '../ruletp/RuleData'
import {
    TECH_1_IDS, TECH_2_IDS, TECH_3_IDS, TECH_4_IDS, TECH_5_IDS, TECH_6_IDS,
    TECH_7_IDS, TECH_8_IDS, TECH_9_IDS, TECH_10_IDS, TECH_11_IDS, TECH_12_IDS,
    TECH_13_IDS, TECH_14_IDS, TECH_15_IDS, TECH_16_IDS, TECH_17_IDS
} from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';



const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
    },
    panelTitle: {
        marginLeft: 10,
    },
    panelTitle1: {
        marginLeft: 10,
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    titlea: {
        backgroundColor: '#FFFFFF',
        opacity: 1
    }
})



class KDBasicboard extends React.Component {

    state = {
        expanded: 'panelx',
        open1: false,
        open2: false,
        open3: false,
        open4: false,
        open5: false,
        open6: false,
        open7: false,
    };

    handleClickOpen7 = () => {
        this.setState({
            open7: true,
        });
    };

    handleClose7 = () => {
        this.setState({
            open7: false,
        });
    };

    handleOk7 = () => {
        this.setState({
            open7: false,
        });
        this.props.freecashupdate()
    };

    handleClickOpen6 = () => {
        this.setState({
            open6: true,
        });
    };

    handleClose6 = () => {
        this.setState({
            open6: false,
        });
    };

    handleOk6 = () => {
        this.setState({
            open6: false,
        });
        this.props.freecashupdate()
    };


    handleClickOpen5 = () => {
        this.setState({
            open5: true,
        });
    };

    handleClose5 = () => {
        this.setState({
            open5: false,
        });
    };

    handleOk5 = () => {
        this.setState({
            open5: false,
        });
        this.props.freecashupdate()
    };

    handleClickOpen4 = () => {
        this.setState({
            open4: true,
        });
    };

    handleClose4 = () => {
        this.setState({
            open4: false,
        });
    };

    handleOk4 = () => {
        this.setState({
            open4: false,
        });
        this.props.freecashupdate()
    };


    handleClickOpen3 = () => {
        this.setState({
            open3: true,
        });
    };

    handleClose3 = () => {
        this.setState({
            open3: false,
        });
    };

    handleOk3 = () => {
        this.setState({
            open3: false,
        });
        this.props.freecashupdate()
    };



    handleClickOpen2 = () => {
        this.setState({
            open2: true,
        });
    };

    handleClose2 = () => {
        this.setState({
            open2: false,
        });
    };

    handleOk2 = () => {
        this.setState({
            open2: false,
        });
        this.props.freecashupdate()
    };

    handleClickOpen1 = () => {
        this.setState({
            open1: true,
        });
    };

    handleClose1 = () => {
        this.setState({
            open1: false,
        });
    };

    handleOk = () => {
        this.setState({
            open1: false,
        });
        this.props.freecashupdate()
    };

    handleOpen1 = () => {
        this.props.tabchange(BPage.donate_page)
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };


    render() {
        const { expanded, open1, open2, open3, open4, open5, open6, open7 } = this.state;
        const { classes } = this.props;

        const isTECHOn1 = this.props.rules.some(rule => TECH_1_IDS.includes(rule.id));
        const isTECHOn2 = this.props.rules.some(rule => TECH_2_IDS.includes(rule.id));
        const isTECHOn3 = this.props.rules.some(rule => TECH_3_IDS.includes(rule.id));
        const isTECHOn4 = this.props.rules.some(rule => TECH_4_IDS.includes(rule.id));
        const isTECHOn5 = this.props.rules.some(rule => TECH_5_IDS.includes(rule.id));
        const isTECHOn6 = this.props.rules.some(rule => TECH_6_IDS.includes(rule.id));
        const isTECHOn7 = this.props.rules.some(rule => TECH_7_IDS.includes(rule.id));
        const isTECHOn8 = this.props.rules.some(rule => TECH_8_IDS.includes(rule.id));
        const isTECHOn9 = this.props.rules.some(rule => TECH_9_IDS.includes(rule.id));
        const isTECHOn10 = this.props.rules.some(rule => TECH_10_IDS.includes(rule.id));
        const isTECHOn11 = this.props.rules.some(rule => TECH_11_IDS.includes(rule.id));
        const isTECHOn12 = this.props.rules.some(rule => TECH_12_IDS.includes(rule.id));
        const isTECHOn13 = this.props.rules.some(rule => TECH_13_IDS.includes(rule.id));
        const isTECHOn14 = this.props.rules.some(rule => TECH_14_IDS.includes(rule.id));
        const isTECHOn15 = this.props.rules.some(rule => TECH_15_IDS.includes(rule.id));
        const isTECHOn16 = this.props.rules.some(rule => TECH_16_IDS.includes(rule.id));
        const isTECHOn17 = this.props.rules.some(rule => TECH_17_IDS.includes(rule.id));

        return (
            <div className={classes.root}>
                <div>
                    {this.props.tabchange != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                            訂閱
                        </Button> : null
                    }

                    {this.props.saveRule1 != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.saveRule1()}>
                            儲存規則
                        </Button> : null
                    }

                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.clearRule()}>
                        清除規則
                    </Button>
                </div>
                <KDSetting open={open1} handleClose={this.handleClose1} handleOk={this.handleOk}></KDSetting>
                <MACDSetting open={open2} handleClose={this.handleClose2} handleOk={this.handleOk2}></MACDSetting>
                <HappySetting open={open3} handleClose={this.handleClose3} handleOk={this.handleOk3}></HappySetting>
                <DMISetting open={open4} handleClose={this.handleClose4} handleOk={this.handleOk4}></DMISetting>
                <WilliamSetting open={open5} handleClose={this.handleClose5} handleOk={this.handleOk5}></WilliamSetting>
                <EMASetting open={open6} handleClose={this.handleClose6} handleOk={this.handleOk6}></EMASetting>
                <BBSetting open={open7} handleClose={this.handleClose7} handleOk={this.handleOk7}></BBSetting>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn1'}
                    onChange={this.handleChange('TECHOn1')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn1 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Motorcycle color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>KDJ1
                        </Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen1}>
                            調整參數
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {TECH_1.map(row =>
                            <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn12'}
                    onChange={this.handleChange('TECHOn12')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn12 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Motorcycle color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>KDJ2
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_12.map(row =>
                            <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>



                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn2'}
                    onChange={this.handleChange('TECHOn2')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn2 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <VerifiedUser color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>MACD1
                        </Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen2}>
                            調整參數
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_2.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn13'}
                    onChange={this.handleChange('TECHOn13')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn13 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <VerifiedUser color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>MACD2
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_13.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn3'}
                    onChange={this.handleChange('TECHOn3')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn3 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ZoomIn color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>均線1</Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen6}>
                            調整參數
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_3.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn4'}
                    onChange={this.handleChange('TECHOn4')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn4 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ZoomIn color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>均線2</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_4.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn14'}
                    onChange={this.handleChange('TECHOn14')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn14 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ZoomIn color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>均線3</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_14.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn5'}
                    onChange={this.handleChange('TECHOn5')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn5 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Build color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>日K線</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_5.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn16'}
                    onChange={this.handleChange('TECHOn16')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn16 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Build color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>周K線</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_16.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn17'}
                    onChange={this.handleChange('TECHOn17')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn17 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Build color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>月K線</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_17.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn6'}
                    onChange={this.handleChange('TECHOn6')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn6 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Keyboard color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>成交量1</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_6.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn15'}
                    onChange={this.handleChange('TECHOn15')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn15 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Keyboard color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>成交量2</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_15.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn7'}
                    onChange={this.handleChange('TECHOn7')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn7 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Voicemail color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>RSI、威廉指標</Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen5}>
                            調整參數
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_7.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn8'}
                    onChange={this.handleChange('TECHOn8')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn8 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ListAlt color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>股價、大盤</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_8.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn9'}
                    onChange={this.handleChange('TECHOn9')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn9 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Cake color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>糾結線、布林通道、肯特納通道</Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen7}>
                            調整參數
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_9.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn10'}
                    onChange={this.handleChange('TECHOn10')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn10 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <NaturePeople color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>PSY、乖離率、RS、樂活、CCI</Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen3}>
                            調整參數
                        </Typography>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_10.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'TECHOn11'}
                    onChange={this.handleChange('TECHOn11')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isTECHOn11 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <LocalDining color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>SAR、OBV、DMI、鱷魚、DMA</Typography>
                        <Typography size="small" variant="caption" style={{ fontSize: 10, color: BColor.faircolor, marginLeft: 8 }} className={classes.button1} onClick={this.handleClickOpen4}>
                            調整參數
                        </Typography>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>

                        {TECH_11.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

export default withStyles(styles)(KDBasicboard);