import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import StockDetail2 from './StockDetail2'
import StockBaseInfo from './StockBaseInfo'
import { putInpQueryParam } from '../util/Syno'

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function StockDetail(props) {
    const classes = useStyles();
    const [isLoaded, setLoaded] = React.useState(true);
    const [error, setError] = React.useState(null)
    const [row, setRow] = React.useState(null)
    const [bigtitle, setBigtitle] = React.useState(null)
    const [curStock, setCurStock] = React.useState(null)

    const [enPrex, setEnPrex] = React.useState(true);
    const [enNext, setEnNext] = React.useState(true);

    var _0xeaa8 = ['now', 'random', '123456789', 'floor']; (function (_0x24cfb4, _0xeaa861) { var _0x1e65c8 = function (_0x782dc8) { while (--_0x782dc8) { _0x24cfb4['push'](_0x24cfb4['shift']()); } }; _0x1e65c8(++_0xeaa861); }(_0xeaa8, 0xfe)); var _0x1e65 = function (_0x24cfb4, _0xeaa861) { _0x24cfb4 = _0x24cfb4 - 0x0; var _0x1e65c8 = _0xeaa8[_0x24cfb4]; return _0x1e65c8; }; function makeid(_0x3863fa) { var _0x5a9e30 = ''; var _0x34b6ff = _0x1e65('0x0'); var _0x47c7e8 = _0x34b6ff['length']; for (var _0x339dea = 0x0; _0x339dea < _0x3863fa; _0x339dea++) { _0x5a9e30 += _0x34b6ff['charAt'](Math[_0x1e65('0x1')](Math[_0x1e65('0x3')]() * _0x47c7e8)); } return _0x5a9e30; } function getcode() { let _0x47aa83 = makeid(0x4); return _0x47aa83 + Math[_0x1e65('0x1')](Date[_0x1e65('0x2')]() / parseInt(_0x47aa83)); }

    useEffect(() => {
        if (props.open) {
            if (props.allIds != null) {
                const index = props.allIds.findIndex(id => id === props.stockno);
                if (index === 0) setEnPrex(false); else setEnPrex(true)
                if (index === props.allIds.length - 1) setEnNext(false); else setEnNext(true)
            } else {
                setEnPrex(false)
                setEnNext(false)
            }

            setCurStock(props.stockno)
            runFectchAPII(props.stockno);
        }
    }, [props.stockno, props.open]);

    const fectchAPI = (param) => {
        fetch("/jstock/v1/stock/info?", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0 && result.company.length > 0) {
                        setLoaded(true)
                        // console.log("result.company[0]:" + result.company[0])
                        setRow(result.company[0])
                        setBigtitle(result.bigtitle)
                        setError(null)
                    }
                },
                error => {
                    setLoaded(true)
                    setError(error)
                }
            );
    }

    const runFectchAPII = (astockNo) => {
        let stockNo = astockNo
        if (stockNo == null && curStock != null)
            stockNo = curStock

        if (stockNo != null) {
            let a = {
                stocks: [stockNo],
                inp: {},
                code: getcode()
            };

            if (localStorage.getItem('lic') != null)
                a["lic"] = localStorage.getItem('lic')
            if (localStorage.getItem('token') != null)
                a["token"] = localStorage.getItem('token')

            a = putInpQueryParam(a)
            
            fectchAPI(a);
        }
    }

    const handleNext = () => {
        if (props.allIds != null) {
            const index = props.allIds.findIndex(id => id === curStock);
            if (index != -1) {
                if (index + 1 < props.allIds.length) {
                    let newStockNo = props.allIds[index + 1]
                    setCurStock(newStockNo)
                    runFectchAPII(newStockNo)

                    if (index + 1 === props.allIds.length - 1) {
                        setEnNext(false)
                    } else {
                        setEnNext(true)
                    }
                    setEnPrex(true)
                } else {
                    //最後一頁
                }
            } else {
                //無此筆資料
            }
        }
    }

    const handlePrex = () => {
        if (props.allIds != null) {
            const index = props.allIds.findIndex(id => id === curStock);
            if (index != -1) {
                if (index - 1 >= 0) {
                    let newStockNo = props.allIds[index - 1]
                    setCurStock(newStockNo)
                    runFectchAPII(newStockNo)

                    if (index - 1 === 0) {
                        setEnPrex(false)
                    } else setEnPrex(true)

                    if (index - 1 === props.allIds.length - 1) {
                        setEnNext(false)
                    } else {
                        setEnNext(true)
                    }

                } else {
                    //最後一頁
                }
            } else {
                //無此筆資料
            }
        }
    }


    return (
        // <div style={{padding: 10}}>
        //     <CssBaseline />
        <Dialog fullScreen open={props.open} onClose={props.handleClose} scroll={"body"} TransitionComponent={Transition}>
            {row != null ? <StockBaseInfo enPrex={enPrex} enNext={enNext} handlePrex={handlePrex} handleNext={handleNext} row={row} handleClose={props.handleClose} menu={props.menu} myloveupdate={props.myloveupdate} ismobile={props.ismobile} allIds={props.allIds} /> : null}
            {row != null && bigtitle != null ? <StockDetail2 row={row} bigtitle={bigtitle} bigupdate={runFectchAPII} toolbar={props.toolbar} ismobile={props.ismobile} /> : null}
        </Dialog>
        // </div>
    );
}
