import React, { useState, useEffect, useRef } from 'react';

import Button from '@material-ui/core/Button'

import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

import BPage from '../BPage'
import Rule451 from './Rule451';
import Rule452 from './Rule452';
import Rule453 from './Rule453';
import Rule454 from './Rule454';
import Rule455 from './Rule455';
import Rule456 from './Rule456';
import Rule457 from './Rule457';
import Rule458 from './Rule458';
import Rule459 from './Rule459';
import Rule460 from './Rule460';
import Rule461 from './Rule461';
import Rule462 from './Rule462';
import Rule463 from './Rule463';
import Rule464 from './Rule464';
import Rule465 from './Rule465';
import Rule466 from './Rule466';
import Rule467 from './Rule467';
import Rule468 from './Rule468';
import Rule469 from './Rule469';
import Grid from '@material-ui/core/Grid';
import Rule478 from './Rule478';
import Rule479 from './Rule479';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
        overflow: 'auto',
    },
    panelTitle: {
        marginLeft: 10
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: 5,
    },
    input: {
        display: 'none',
    },
    margin: {
        width: '100%',
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}))

function ShapeHome1(props) {
    const classes = useStyles();

    const handleOpen1 = () => {
        props.tabchange(BPage.donate_page)
    }

    return (
        <div className={classes.root} >
            <div>
                {props.tabchange != undefined ?
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => handleOpen1()}>
                        訂閱
                        </Button> : null
                }

                {props.saveRule1 != undefined ?
                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => props.saveRule1()}>
                        儲存規則
                        </Button> : null
                }

                <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => props.clearRule()}>
                    清除規則
                    </Button>
            </div>


            <div style={{ fontSize: 12, color: '#8c8c8c' }} >
                <font style={{ color: '#d96f9c' }}>#訂閱專屬。</font>利用轉折波找出符合的型態。<br />
            </div>
            <div className={classes.root1}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule451 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule452 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule453 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule454 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule455 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule456 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule457 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule465 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule467 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule478 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>

                    {/* 空                     */}
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule458 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule459 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule460 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule461 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule462 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule463 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule464 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule468 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule466 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <Rule479 ruleChange={props.updateRule} rules={props.rules} />
                    </Grid>
                </Grid>



                {/* <GridList className={classes.gridList} spacing={10} cellHeight='auto' cols={getColumns(props)}>
                    <GridListTile key={'207'}>
                        <div style={{ marginBottom: 10 }}><Rule451 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule452 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule453 ruleChange={props.updateRule} rules={props.rules} /></div>
                    </GridListTile>
                    <GridListTile key={'208'}>
                        <div style={{ marginBottom: 10 }}><Rule454 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule455 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule456 ruleChange={props.updateRule} rules={props.rules} /></div>
                    </GridListTile>
                    <GridListTile key={'209'}>
                        <div style={{ marginBottom: 10 }}><Rule457 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule465 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule467 ruleChange={props.updateRule} rules={props.rules} /></div>

                    </GridListTile>
                    <GridListTile key={'213'}>
                        <div style={{ marginBottom: 10 }}><Rule469 ruleChange={props.updateRule} rules={props.rules} /></div>
                    </GridListTile>
                    <GridListTile key={'210'}>
                        <div style={{ marginBottom: 10 }}><Rule460 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule461 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule462 ruleChange={props.updateRule} rules={props.rules} /></div>
                    </GridListTile>
                    <GridListTile key={'211'}>
                        <div style={{ marginBottom: 10 }}><Rule463 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule464 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule458 ruleChange={props.updateRule} rules={props.rules} /></div>
                    </GridListTile>
                    <GridListTile key={'212'}>
                        <div style={{ marginBottom: 10 }}><Rule466 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule459 ruleChange={props.updateRule} rules={props.rules} /></div>
                        <div style={{ marginBottom: 10 }}><Rule468 ruleChange={props.updateRule} rules={props.rules} /></div>
                    </GridListTile>

                </GridList> */}


            </div>

        </div>
    );
}

export default withWidth()(ShapeHome1);