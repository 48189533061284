import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import BrokerList from './BrokerList';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'


function desc(a, b, orderBy) {
    let col = ''
    let index = orderBy.substr(orderBy.length - 1, 1)
    if (index === '1') col = 'b5'
    else if (index === '2') col = 'b10'
    else if (index === '3') col = 'b20'
    else if (index === '4') col = 'b40'

    let rorderBy = orderBy.substr(0, orderBy.length - 1)

    if (rorderBy === 'inc_count') {
        let bvalue = b.tpbd1[col]['db'][rorderBy]
        let btp = b.tpbd1[col]['db']['inc_tp']
        if (btp === 1) bvalue = -bvalue

        let avalue = a.tpbd1[col]['db'][rorderBy]
        let atp = a.tpbd1[col]['db']['inc_tp'] //0 買 1賣
        if (atp === 1) avalue = -avalue

        if (btp > atp) {
            return -1
        } else if (btp < atp) {
            return 1
        } else if (btp == atp && bvalue < avalue) {
            return -1
        } else if (btp == atp && bvalue > avalue) {
            return 1
        }
    } else {
        if (b.tpbd1 != undefined && a.tpbd1 != undefined && b.tpbd1[col] != undefined && a.tpbd1[col] != undefined && b.tpbd1[col]['db'][rorderBy] < a.tpbd1[col]['db'][rorderBy]) {
            return -1;
        }
        if (b.tpbd1 != undefined && a.tpbd1 != undefined && b.tpbd1[col] != undefined && a.tpbd1[col] != undefined && b.tpbd1[col]['db'][rorderBy] > a.tpbd1[col]['db'][rorderBy]) {
            return 1;
        }
    }

    return 0;
}


function stableSort(array, cmp) {

    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號' },

    { id: 'bname1', numeric: false, disablePadding: false, label: '券商分點' },
    { id: 'buyc1', numeric: false, disablePadding: false, label: '買超天數' },
    { id: 'inc_count1', numeric: false, disablePadding: false, label: '近日連續' },
    { id: 'buysell1', numeric: false, disablePadding: false, label: '張數' },
    { id: 'bcost1', numeric: false, disablePadding: false, label: '平均成本' },
    { id: 'winprice1', numeric: false, disablePadding: false, label: '預估損益' },
    // { id: 'buyprice1', numeric: false, disablePadding: false, label: '買入金額' },
    // { id: 'sellprice1', numeric: false, disablePadding: false, label: '賣出金額' },
    // { id: 'status1', numeric: false, disablePadding: false, label: '買賣休天數分配' },


    { id: 'bname2', numeric: false, disablePadding: false, label: '券商分點' },
    { id: 'buyc2', numeric: false, disablePadding: false, label: '買超天數' },
    { id: 'inc_count2', numeric: false, disablePadding: false, label: '近日連續' },
    { id: 'buysell2', numeric: false, disablePadding: false, label: '張數' },
    { id: 'bcost2', numeric: false, disablePadding: false, label: '平均成本' },
    { id: 'winprice2', numeric: false, disablePadding: false, label: '預估損益' },
    // { id: 'buyprice2', numeric: false, disablePadding: false, label: '買入金額' },
    // { id: 'sellprice2', numeric: false, disablePadding: false, label: '賣出金額' },
    // { id: 'status2', numeric: false, disablePadding: false, label: '買賣休天數分配' },


    { id: 'bname3', numeric: false, disablePadding: false, label: '券商分點' },
    { id: 'buyc3', numeric: false, disablePadding: false, label: '買超天數' },
    { id: 'inc_count3', numeric: false, disablePadding: false, label: '近日連續' },
    { id: 'buysell3', numeric: false, disablePadding: false, label: '張數' },
    { id: 'bcost3', numeric: false, disablePadding: false, label: '平均成本' },
    { id: 'winprice3', numeric: false, disablePadding: false, label: '預估損益' },
    // { id: 'buyprice3', numeric: false, disablePadding: false, label: '買入金額' },
    // { id: 'sellprice3', numeric: false, disablePadding: false, label: '賣出金額' },
    // { id: 'status3', numeric: false, disablePadding: false, label: '買賣休天數分配' },

    { id: 'bname4', numeric: false, disablePadding: false, label: '券商分點' },
    { id: 'buyc4', numeric: false, disablePadding: false, label: '買超天數' },
    { id: 'inc_count4', numeric: false, disablePadding: false, label: '近日連續' },
    { id: 'buysell4', numeric: false, disablePadding: false, label: '張數' },
    { id: 'bcost4', numeric: false, disablePadding: false, label: '平均成本' },
    { id: 'winprice4', numeric: false, disablePadding: false, label: '預估損益' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };


    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                {/* <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近1日買超天數最多
                </TableCell> */}
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近1週買超天數冠軍
                </TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近2週買超天數冠軍
                </TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近1個月買超天數冠軍
                </TableCell>
                <TableCell className={classes.stickya} colSpan={6} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">近2個月買超天數冠軍
                </TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'love' || headCell.id === 'bname2' || headCell.id === 'bname3' || headCell.id === 'ymd5' || headCell.id === 'psend') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'bname2' ||
                        headCell.id === 'bname3' || headCell.id === 'status1' || headCell.id === 'status2' || headCell.id === 'status3'
                        || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
},
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function BrokerDateTable(props) {
    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [opendealer, setOpenDealer] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opendname, setOpenDname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };


    const handleClickOpencc = (stock_no, stockname, dealer_id, dealername) => event => {
        setOpenstock(stock_no)
        setOpenDealer(dealer_id)
        setOpenSname(stockname)
        setOpenDname(dealername)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenDealer(null)
        setOpenSname(null)
        setOpenDname(null)
        setConcentOpen(false);
    };



    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    function renderName(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 7, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                    <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1}>{rv1.name}</Button>
                </TableCell>
            }
        }

        if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 7, paddingRight: 10, borderLeft: '1px solid #dbdbdb' }} size="small" align="left"></TableCell>
    }


    function renderStock(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = "中立"
                let vv = ((rv1.db.buysell / 1000).toFixed())

                if (rv1.db.buysell < 0) {
                    colorx = BColor.downcolorbox
                    word = "賣超 "
                    if (rv1.db.buysell <= -1000)
                        vv = ((-rv1.db.buysell / 1000).toFixed()) + "張"
                    else
                        vv = -rv1.db.buysell + "股"

                } else if (rv1.db.buysell === 0) {
                    colorx = BColor.nodatacolor
                    word = "中立 "
                    vv = "0張"
                } else if (rv1.db.buysell > 0) {
                    colorx = BColor.upcolorbox
                    word = "買超 "
                    if (rv1.db.buysell >= 1000)
                        vv = ((rv1.db.buysell / 1000).toFixed()) + "張"
                    else
                        vv = rv1.db.buysell + "股"
                } else {
                    colorx = BColor.nodatacolor
                    word = "中立 "
                    vv = ((rv1.db.buysell / 1000).toFixed()) + "張"
                }

                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} {vv}</Button>
                </TableCell>
            }
        }

        if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }


    function renderWinprice(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = "持平 "
                let vv = ((rv1.db.winprice / 10000).toFixed(1))

                if (rv1.db.winprice < 0) {
                    colorx = BColor.downcolorbox
                    word = "損失 "
                    vv = ((-rv1.db.winprice / 10000).toFixed(1))
                } else if (rv1.db.winprice === 0) {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                } else if (rv1.db.winprice > 0) {
                    colorx = BColor.upcolorbox
                    word = "獲利 "
                } else {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                }

                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 130, color: colorx }} className={classes.button1}>{word} {vv}萬元</Button>
                </TableCell>
            }
        }

        if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    function renderBuyDate(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let vv = rv1.db.buyc
                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="center">
                    <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{vv}天</Button>
                </TableCell>
            }
        }

        if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center"></TableCell>
    }


    function renderinc(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = "持平 "
                let vv = rv1.db.inc_count

                if (rv1.db.inc_tp === 0) {
                    colorx = BColor.upcolorbox
                    word = "買超 "
                } else if (rv1.db.inc_tp === 1) {
                    colorx = BColor.downcolorbox
                    word = "賣超 "
                } else if (rv1.db.inc_tp === 2) {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                } else {
                    colorx = BColor.nodatacolor
                    word = "持平 "
                }

                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="center">
                    <Button size="small" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, rv1.db.dealer_id, rv1.name)} variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} {vv}天</Button>
                </TableCell>
            }
        }

        if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center"></TableCell>
    }

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    function renderCost(row, index, hasEmpty) {
        if (row.tpbd1 != undefined) {
            let rv1 = row.tpbd1.b1
            if (index === 0) rv1 = row.tpbd1.b1
            else if (index === 1) rv1 = row.tpbd1.b5
            else if (index === 2) rv1 = row.tpbd1.b10
            else if (index === 3) rv1 = row.tpbd1.b20
            else if (index === 4) rv1 = row.tpbd1.b40

            if (rv1 != undefined) {
                let colorx = BColor.nodatacolor
                let word = rv1.db.bcost

                return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="left">
                    <Button size="small" variant="outlined" style={{ minWidth: 100, color: colorx }} className={classes.button1}>{word} 元</Button>
                </TableCell>
            }
        }

        if (hasEmpty)
            return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="right"></TableCell>
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <BrokerList open={concentopen} handleClose={handleClosecc} stockId={openstock} dealerId={opendealer} stockname={opensname} dealername={opendname}></BrokerList>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>

                                            </TableCell>

                                            {/* {renderName(row, 0, true)}
                                            {renderStock(row, 0, true)}
                                            {renderWinprice(row, 0, true)}
                                            {renderBuyPrice(row, 0, true)}
                                            {renderSellPrice(row, 0, true)} */}
                                            {/* {renderStatus(row, 0, true)}
                                            {renderinc(row, 0, true)}              */}


                                            {renderName(row, 1, true)}
                                            {renderBuyDate(row, 1, true)}
                                            {renderinc(row, 1, true)}
                                            {renderStock(row, 1, true)}
                                            {renderCost(row, 1, true)}
                                            {renderWinprice(row, 1, true)}
                                            {/* {renderBuyPrice(row, 1, true)}
                                            {renderSellPrice(row, 1, true)}
                                            {renderStatus(row, 1, true)} */}


                                            {renderName(row, 2, true)}
                                            {renderBuyDate(row, 2, true)}
                                            {renderinc(row, 2, true)}
                                            {renderStock(row, 2, true)}
                                            {renderCost(row, 2, true)}
                                            {renderWinprice(row, 2, true)}
                                            {/* {renderBuyPrice(row, 2, true)}
                                            {renderSellPrice(row, 2, true)} */}
                                            {/* {renderStatus(row, 2, true)} */}


                                            {renderName(row, 3, true)}
                                            {renderBuyDate(row, 3, true)}
                                            {renderinc(row, 3, true)}
                                            {renderStock(row, 3, true)}
                                            {renderCost(row, 3, true)}
                                            {renderWinprice(row, 3, true)}
                                            {/* {renderBuyPrice(row, 3, true)}
                                            {renderSellPrice(row, 3, true)}
                                            {renderStatus(row, 3, true)} */}


                                            {renderName(row, 4, true)}
                                            {renderBuyDate(row, 4, true)}
                                            {renderinc(row, 4, true)}
                                            {renderStock(row, 4, true)}
                                            {renderCost(row, 4, true)}
                                            {renderWinprice(row, 4, true)}
                                            {/* {renderBuyPrice(row, 4, true)}
                                            {renderSellPrice(row, 4, true)}
                                            {renderStatus(row, 4, true)} */}


                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(BrokerDateTable);
