import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, green } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // backgroundColor: "#FFFFFF",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    },

    root1: {
        display: 'flex',
    },
    paper: {
        padding: 10
    },
    symbolt: {
        fontSize: 18,
        // color: "#000000",
        whiteSpace: "nowrap",
    },
    symbols: {
        marginTop: 5,
        marginLeft: 5,
        fontSize: 14,
        // color: "#000000",
    },
    uprice: {
        marginRight: 5,
        fontSize: 14,
        color: "#e64c4c",
        whiteSpace: "nowrap"
    },
    dprice: {
        marginRight: 5,
        fontSize: 14,
        color: "#59b53f",
        whiteSpace: "nowrap"
    },
    eprice: {
        marginRight: 5,
        fontSize: 14,
        // color: "#000000",
        whiteSpace: "nowrap"
    },
    downinc: {
        marginRight: 5,
        fontSize: 14,
        color: "#59b53f",
    },
    upinc: {
        marginRight: 5,
        fontSize: 14,
        color: "#e64c4c",
    },
    eqinc: {
        marginRight: 5,
        fontSize: 14,
        // color: "#000000",
    },
    button: {
        color: "#FFFFFF"
    },
    nolove: {
        // color: "#000000",
        width: 22,
        height: 22,
        paddingTop: 5
        // marginRight: 3,
        // marginBottom: 5
    },
    love: {
        color: "#d93f3f",
        marginRight: 3,
        marginBottom: 5
    },
    rounded: {
        color: '#fff',
        backgroundColor: green[500],
        height: 28,
        width: 28,
        fontSize: 12,
        marginLeft: 2
    },
}));

export default function StockBaseInfo(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [getStock, setSavestock] = React.useState(null);
    const [lovemenu, setLovemenu] = React.useState(false)

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    function renderPrice(row) {
        if (row.fin.price.incv > 0) {
            return <div className={classes.uprice}>{row.fin.price.last_value}元</div>
        } else if (row.fin.price.incv < 0) {
            return <div className={classes.dprice}>{row.fin.price.last_value}元</div>
        } else {
            return <div className={classes.eprice}>{row.fin.price.last_value}元</div>
        }
    }

    function renderInc(row) {
        let oriValue = row.fin.price.last_value - row.fin.price.incv
        let percent = Math.abs(row.fin.price.incv * 100) / oriValue

        if (row.fin.price.incv > 0) {
            return <div className={classes.upinc}>▲{row.fin.price.incv} ({percent.toFixed(2)}%)</div>
        } else if (row.fin.price.incv < 0) {
            return <div className={classes.downinc}>▼{row.fin.price.incv} ({percent.toFixed(2)}%)</div>
        } else {
            return <div className={classes.eqinc}>{row.fin.price.incv} ({percent.toFixed(2)}%)</div>
        }
    }

    const backclose = () => {
        if (props.handleClose != undefined) props.handleClose()
    }

    useEffect(() => {
        // componentDidMount
        window.addEventListener('keydown', onKeyDown)
        return () => {
            // componentWillUnMount
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [props.row.market_company.stock_no]) // 這裡設定為[]代表state與props變化都不會執行，所以可以作為componentDidMount

    // 原本的function改為arrow function
    const onKeyDown = (e) => {
        console.log('key:', e.keyCode)

        if (e.keyCode === 65) {
            if (props.handlePrex != null) props.handlePrex()
        }
        if (e.keyCode === 68) {
            if (props.handleNext != null) props.handleNext()
        }
    }

    return (
        <div className={classes.root} >
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />
            <AppBar position="static" style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000", color: theme.palette.type === "light" ? "#000000" : "#FFFFFF" }}>
                <Toolbar>
                    {/* <Typography variant="h6" className={classes.title}> */}
                        <Box flexGrow={1}>
                            <div style={{ display: 'flex' }} onClick={handleLoveClick(props.row.market_company.stock_no)}>
                                <div style={{}}>
                                    <FavoriteBorder className={classes.nolove} />
                                </div>
                                <div className={classes.symbolt}>
                                    {props.row.market_company.short_name}
                                </div>
                                <div className={classes.symbols}>
                                    ({props.row.market_company.stock_no})
                                </div>
                                {props.row.noti.ispub ? <Avatar variant="rounded" style={{ fontSize: 12, marginLeft: 2, width: 26, height: 26, backgroundColor: deepOrange[900], color: "#fff" }}>
                                    處
                                </Avatar> : null}
                                {props.row.noti.isno ? <Avatar variant="rounded" style={{ fontSize: 12, marginLeft: 2, width: 26, height: 26, backgroundColor: deepOrange[900], color: "#fff" }}>
                                    注
                                </Avatar> : null}
                            </div>
                            <div style={{ display: 'flex' }}>
                                {renderPrice(props.row)}
                                {renderInc(props.row)}
                            </div>
                        </Box>
                    {/* </Typography> */}
                    {/* <Button color="inherit" onClick={props.handlePrex} >上一檔</Button> */}
                    {props.enPrex ? <KeyboardArrowLeftIcon style={{ color: "#707070" }} fontSize="large" onClick={props.handlePrex} /> :
                        <KeyboardArrowLeftIcon color="disabled" fontSize="large" onClick={props.handlePrex} />}

                    {props.enNext ? <KeyboardArrowRightIcon style={{ color: "#707070" }} fontSize="large" onClick={props.handleNext} /> :
                        <KeyboardArrowRightIcon color="disabled" fontSize="large" onClick={props.handleNext} />}

                    <CloseIcon style={{ color: "#707070" }} fontSize="large" onClick={backclose} />
                    {/* <Button color="inherit" onClick={props.handleNext} >下一檔</Button> */}
                    {/* <Button color="inherit" onClick={backclose} >關閉</Button> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}
