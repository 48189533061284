import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import EPSList from './EPSList';
import Typography from '@material-ui/core/Typography';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

// row.fin.price.last_value
function desc(a, b, orderBy) {

    if (orderBy.indexOf("mx") != -1 && b.eps.r1 != null && a.eps.r1) {
        if (b.eps.r1[orderBy] < a.eps.r1[orderBy]) {
            return -1;
        }
        if (b.eps.r1[orderBy] > a.eps.r1[orderBy]) {
            return 1;
        }
    } else if (orderBy === "price") {
        if (b.fin.price.last_value < a.fin.price.last_value) {
            return -1;
        }
        if (b.fin.price.last_value > a.fin.price.last_value) {
            return 1;
        }
    } else if (b.eps != null && a.eps != null) {
        if (b.eps[orderBy] < a.eps[orderBy]) {
            return -1;
        }
        if (b.eps[orderBy] > a.eps[orderBy]) {
            return 1;
        }
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// case class EPSInfo(ly : Int, lyesp : Double, ty : Int, tyeps : Double, r1max : Double, r1min : Double, r1avg : Double, r2max : Double, r2min : Double, r2avg : Double)

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'price', numeric: false, disablePadding: false, label: '收盤價', sid: 'price' },
    { id: 'lyeps', numeric: false, disablePadding: false, label: '去年', sid: 'lyeps' },
    { id: 'y1mx', numeric: false, disablePadding: false, label: '今年預估', sid: 'y1mx' },
    { id: 'tyeps', numeric: false, disablePadding: false, label: '今年目前', sid: 'tyeps' },

    { id: 'lys1', numeric: false, disablePadding: false, label: '去年', sid: 'lys1' },
    { id: 's1mx', numeric: false, disablePadding: false, label: '今年預估', sid: 's1mx' },
    { id: 'tys1', numeric: false, disablePadding: false, label: '今年實際', sid: 'tys1' },

    { id: 'lys2', numeric: false, disablePadding: false, label: '去年', sid: 'lys2' },
    { id: 's2mx', numeric: false, disablePadding: false, label: '今年預估', sid: 's2mx' },
    { id: 'tys2', numeric: false, disablePadding: false, label: '今年實際', sid: 'tys2' },

    { id: 'lys3', numeric: false, disablePadding: false, label: '去年', sid: 'lys3' },
    { id: 's3mx', numeric: false, disablePadding: false, label: '今年預估', sid: 's3mx' },
    { id: 'tys3', numeric: false, disablePadding: false, label: '今年實際', sid: 'tys3' },

    { id: 'lys4', numeric: false, disablePadding: false, label: '去年', sid: 'lys4' },
    { id: 's4mx', numeric: false, disablePadding: false, label: '今年預估', sid: 's4mx' },
    { id: 'tys4', numeric: false, disablePadding: false, label: '今年實際', sid: 'tys4' },

    // { id: 'lyh1', numeric: false, disablePadding: false, label: '去年', sid: 'lyh1' },
    // { id: 'h1av', numeric: false, disablePadding: false, label: '今年預估', sid: 'h1av' },
    // { id: 'tyh1', numeric: false, disablePadding: false, label: '今年實際', sid: 'tyh1' },

    // { id: 'lyh2', numeric: false, disablePadding: false, label: '去年', sid: 'lyh2' },
    // { id: 'h2av', numeric: false, disablePadding: false, label: '今年預估', sid: 'h2av' },
    // { id: 'tyh2', numeric: false, disablePadding: false, label: '今年實際', sid: 'tyh2' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        if (value != null && value != '' && value.length === 4) {
            localStorage.setItem('epnow', value);
            props.freecashupdate()
        } else {
            localStorage.removeItem("epnow")
            props.freecashupdate()
        }

        setAnchorEl(null);
    };


    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={4} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">
                    年度
                    <Typography size="small" variant="caption" style={{ fontSize: 10, color: '#e8e8e8', marginLeft: 8 }} className={classes.button1} onClick={handleClick}>
                        預估年份與說明
                    </Typography>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={(e) => handleClose('')}>近一個月累積年營收YOY * 去年總營收 * 近四季稅後營業利率平均 / 普通股數</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2024')}>2024年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2023')}>2023年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2022')}>2022年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2021')}>2021年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2020')}>2020年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2019')}>2019年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2018')}>2018年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2017')}>2017年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2016')}>2016年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2015')}>2015年度</MenuItem>
                        <MenuItem onClick={(e) => handleClose('2015')}>2014年度</MenuItem>
                    </Menu>
                </TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">第一季</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">第二季</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">第三季</TableCell>
                <TableCell className={classes.stickya} colSpan={3} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">第四季</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'lyesp' || headCell.id === 'lys1' || headCell.id === 'lys2' || headCell.id === 'lys3' || headCell.id === 'lys4' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'bname' || headCell.id === 'bname1' || headCell.id === 'bname2' ||
                        headCell.id === 'bname3' || headCell.id === 'status1' || headCell.id === 'status2' || headCell.id === 'status3'
                        || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.sid)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
    paper: {
        width: '100%',
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        // minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function EPSDetail(props) {
    let redcolor = BColor.upcolorbox
    let backcolor = "#FFFFFF"
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox

    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);

    const [openstock, setOpenstock] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);



    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no) => event => {
        setOpenstock(stock_no)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setConcentOpen(false);
    };




    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };



    const openDetail = (stockno) => {
        if (props.detailUpdate != undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate != undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const renderEPS = (value, base, stockno) => {
        let color = BColor.faircolor

        if (value != null && value != undefined) {
            return <Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {value}元
            </Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderBaseEPS = (value, base, stockno) => {
        let color = BColor.faircolor
        let color1 = BColor.faircolor
        let colorb = null// "#FFFFFF"
        if (value != null && value != undefined) {

            let a = "0%"
            if (value > 0 && base > 0) {
                let a1 = ((value / base) * 100).toFixed(0)

                if (a1 > 100) {
                    // colorb = backcolor
                    color1 = redcolor
                }
                a = a1 + "%"
            } else if (value > 0 && base < 0) {
                a = "盈"
                colorb = redback
                color1 = "#FFFFFF"
            } else if (value < 0 && base > 0) {
                a = "虧"
                colorb = greenback
                color1 = "#FFFFFF"
            }

            return <div><Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 110, color: color }} className={classes.button2} >
                {value}元
            </Button><Button size="small" variant="outlined" style={{ minWidth: 60, marginLeft: 1, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    {a}
                </Button></div>
        } else {
            return <div><Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
            </Button><Button size="small" variant="outlined" style={{ minWidth: 60, marginLeft: 1, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    0%
                </Button></div>
        }
    }


    const renderThisYearEPS = (eps, stockno) => {
        let color = BColor.faircolor
        let color1 = BColor.faircolor
        let colorb = null//"#FFFFFF"
        if (eps != null && eps != undefined && eps.tyeps != null && eps.tyeps != undefined) {
            let a = "0%"
            if (eps.tyeps > 0 && eps.lyeps > 0) {
                let a1 = ((eps.tyeps / eps.lyeps) * 100).toFixed(0)

                if (a1 > 100) {
                    // colorb = backcolor
                    color1 = redcolor
                }
                a = a1 + "%"
            } else if (eps.tyeps > 0 && eps.lyeps < 0) {
                a = "盈"
                colorb = redback
                color1 = "#FFFFFF"
            } else if (eps.tyeps < 0 && eps.lyeps > 0) {
                a = "虧"
                colorb = greenback
                color1 = "#FFFFFF"
            }

            return <div><Button size="small" variant="outlined" onClick={handleClickOpencc(stockno)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                {eps.tyeps}元
            </Button><Button size="small" variant="outlined" style={{ minWidth: 60, marginLeft: 1, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    {a}
                </Button></div>
        } else {
            return <div><Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
            </Button><Button size="small" variant="outlined" style={{ minWidth: 60, marginLeft: 1, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    0%
                </Button></div>
        }
    }

    const renderLastYearEPS = (eps, stockno) => {
        let color = BColor.faircolor

        if (eps != null && eps != undefined && eps.lyeps != null && eps.lyeps != undefined) {
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                {eps.ly}年 {eps.lyeps}元
            </Button>
        } else {
            return <Button size="small" variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                -
            </Button>
        }
    }

    const renderR1LastYearEPS = (eps, stockno) => {
        let color = BColor.faircolor
        let color1 = BColor.faircolor
        let colorb = null//"#FFFFFF"
        if (eps != null && eps != undefined && eps.r1 != null && eps.r1 != undefined && eps.r1.y1av != null && eps.r1.y1av != undefined) {
            let a = "0%"

            if (eps.r1.y1mx > 0 && eps.lyeps > 0) {
                let a1 = ((eps.r1.y1mx / eps.lyeps) * 100).toFixed(0)

                if (a1 > 100) {
                    // colorb = backcolor
                    color1 = redcolor
                }

                a = a1 + "%"
            } else if (eps.r1.y1mx > 0 && eps.lyeps < 0) {
                a = "盈"
                colorb = redback
                color1 = "#FFFFFF"
            } else if (eps.r1.y1mx < 0 && eps.lyeps > 0) {
                a = "虧"
                colorb = greenback
                color1 = "#FFFFFF"
            }


            return <div><Button size="small" onClick={handleClickOpencc(stockno)} variant="outlined" style={{ minWidth: 150, color: color }} className={classes.button2} >
                {eps.ty}年 {eps.r1.y1mx}元
            </Button><Button size="small" variant="outlined" style={{ minWidth: 60, marginLeft: 1, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    {a}
                </Button></div>
        } else {
            return <div><Button size="small" variant="outlined" style={{ minWidth: 150, color: BColor.faircolor }} className={classes.button2} >
                -
            </Button><Button size="small" variant="outlined" style={{ minWidth: 60, marginLeft: 1, backgroundColor: colorb, color: color1 }} className={classes.button2} >
                    0%
                </Button></div>
        }
    }

    const getAllIds = () => {
        return props.data.map(row => {
            return row.market_company.stock_no
        })
    }

    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>

                                            {row.fin != null && row.fin != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
                                                    {row.fin.price.last_value}元
                                                </Button>
                                            </TableCell> : null}

                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0 }} size="small" align="left">
                                                {renderLastYearEPS(row.eps, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {renderR1LastYearEPS(row.eps, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {renderThisYearEPS(row.eps, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor, paddingLeft: 5, paddingRight: 0 }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}

                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderEPS(row.eps.lys1, row.market_company.stock_no)}
                                            </TableCell> : null}

                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s1mx, row.eps.lys1, row.market_company.stock_no) : <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {renderBaseEPS(row.eps.tys1, row.eps.lys1, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}



                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderEPS(row.eps.lys2, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s2mx, row.eps.lys2, row.market_company.stock_no) : <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                                }
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {renderBaseEPS(row.eps.tys2, row.eps.lys2, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}


                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderEPS(row.eps.lys3, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s3mx, row.eps.lys3, row.market_company.stock_no) : <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {renderBaseEPS(row.eps.tys3, row.eps.lys3, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}



                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} size="small" align="left">
                                                {renderEPS(row.eps.lys4, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {row.eps.r1 != undefined ? renderBaseEPS(row.eps.r1.s4mx, row.eps.lys4, row.market_company.stock_no) : <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>}
                                            </TableCell> : null}
                                            {row.eps != null && row.eps != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, }} size="small" align="left">
                                                {renderBaseEPS(row.eps.tys4, row.eps.lys4, row.market_company.stock_no)}
                                            </TableCell> : null}
                                            {row.eps == null || row.eps == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 0, borderLeft: '1px solid #dbdbdb' }} colSpan={3} size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: '100%', color: BColor.faircolor }} className={classes.button2} >
                                                    -
                                                </Button>
                                            </TableCell> : null}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <EPSList open={concentopen} handleClose={handleClosecc} stockId={openstock}></EPSList>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                        匯出
                    </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Divider orientation="vertical" flexItem />
                        <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                            匯出
                        </Button>
                        <Divider orientation="vertical" flexItem />
                        <TablePagination
                            rowsPerPageOptions={getRowPageData(props.width)}
                            component="div"
                            count={props.total}
                            rowsPerPage={props.currentcount}
                            page={props.currentpage}
                            labelRowsPerPage={'每頁'}
                            labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(EPSDetail);
