import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EasyBoy from '../EasyBoy3'
import StockUser from '../StockUser1'
import NewBaiscboard from '../dashboard/NewBaiscboard';
import KDBasicboard from '../dashboard/KDBasicboard'
import MoneyDashboard from '../dashboard/MoneyDashboard'
import ProDashboard from '../dashboard/ProDashboard'
import MyStrageBoard from '../strage/MyStrageBoard'
import ShapeHome from '../shape/ShapeHome1';
import PriceShapeHome from '../shape/PriceShapeHome'
import IndustryHome from '../industry/IndustryHome1'
import WinerDashboard from '../winner/WinerDashboard1'
import NormalRules from '../mobile3/NormalRules';
import ShapeDashboard1 from '../dashboard/ShapeDashboard1';
import { useTheme } from '@material-ui/core/styles';

export default function EasyDialog(props) {
  const theme = useTheme()

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);


  function createBody() {
    if (props.tp === 1)
      return <EasyBoy ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    else if (props.tp === 2)
      return <StockUser ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    else if (props.tp === 3)
      return <NewBaiscboard ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    else if (props.tp === 4)
      return <KDBasicboard ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    else if (props.tp === 5)
      return <MoneyDashboard ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    else if (props.tp === 6)
      return <ProDashboard ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    else if (props.tp === 7) {
      return <MyStrageBoard ismobile={props.ismobile} saveRule1={props.saveRule1} runRuleData={props.runRuleData} clearRule={props.clearRule} />
    } else if (props.tp === 8) {
      return <ShapeHome ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    } else if (props.tp === 9) {
      return <PriceShapeHome ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    } else if (props.tp === 10) {
      return <IndustryHome ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} fromdialog={true} />
    } else if (props.tp === 11) {
      return <WinerDashboard ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} fromdialog={true} />
    } else if (props.tp === 12) {
      return <NormalRules ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} />
    } else if (props.tp === 13) {
      return <ShapeDashboard1 ismobile={props.ismobile} saveRule1={props.saveRule1} updateRule={props.updateRule} rules={props.rules} clearRule={props.clearRule} onRemoveItem={props.onRemoveItem} />
    }

    else return null
  }


  return (
    <div>
      {/* <Button onClick={handleClickOpen('paper')}>scroll=paper</Button>
      <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
      <Dialog
        // fullWidth={true}
        // maxWidth={'xl'}
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* <DialogTitle id="scroll-dialog-title">{getTitle()}</DialogTitle> */}
        <DialogContent dividers={true} style={{
          backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
          paddingTop: 0, padding: 0, paddingLeft: 5, paddingRight: 5, marginRight: 5, marginLeft: 5
        }}>
          {createBody()}
        </DialogContent>
        <DialogActions style={{
          backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",}}>
          {/* <Button onClick={props.handleClose} color="primary">
            取消
          </Button> */}
          <Button onClick={props.handleClose} color="primary">
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
