import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import BPage from './BPage'

import { EASY_1 } from './ruletp/RuleData'
import RuleTp5 from './ruletp/RuleTp5'
import ColorModeContext from './util/ColorMode'


const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
        overflow: 'auto',
    },
    panelTitle: {
        marginLeft: 10
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // width: 90,
        // marginLeft: 5,
    },
    input: {
        display: 'none',
    },
    margin: {
        width: '100%',
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        // backgroundColor: theme.palette.background.default,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}))

function EasyBoy(props) {
    const classes = useStyles();
    const theme = useTheme()

    const colorMode = React.useContext(ColorModeContext);
    const handleOpen = () => {
        props.tabchange(BPage.donate_page)
    }


    const getColumns = (props) => {
        if (props.tabchange === undefined && props.width === 'xs') {
            return 1.3
        } else if (props.width === 'xs') {
            return 1.4
        } if (props.width === 'sm') {
            return 2.6
        } if (props.width === 'md') {
            return 4
        } else return 4
    }

    const changeScreen = () => {
        let k = localStorage.getItem('run_computer') === null ? "0" : localStorage.getItem('run_computer')

        if (k === "0") {
            localStorage.setItem("run_computer", "1")
        } else {
            localStorage.setItem("run_computer", "0")
        }

        window.location.reload(false);
    }

    const getAValue = (index) => {
        let k = EASY_1.length - 5 * index
        if (k < 5) return k
        else return 5
    }

    return (
        <div className={classes.root} >
            {props.tabchange != undefined && localStorage.getItem("noad") != "1" ?
                <Button style={{}} variant="outlined" size="small" className={classes.button} onClick={handleOpen}>
                    訂閱
                </Button> : null
            }
            <Button style={{ marginLeft: 5 }} variant="outlined" size="small" className={classes.button} onClick={(e) => props.clearRule()}>
                清除規則
            </Button>

            {props.saveRule1 != undefined &&
                <Button style={{ marginLeft: 5 }} variant="outlined" size="small" className={classes.button} onClick={(e) => props.saveRule1()}>
                    儲存規則
                </Button>
            }
            <Button style={{ marginLeft: 5 }} variant="outlined" size="small" className={classes.button} onClick={colorMode.toggleColorMode}>
                {theme.palette.type === "light" ? <div>深色</div> : <div>淺色</div>}
            </Button>

            <Button style={{ marginLeft: 5 }} variant="outlined" size="small" className={classes.button} onClick={changeScreen}>
                換版面
            </Button>

            {/* {props.tabchange != undefined && (props.pathname === "/mdashboard1" || props.pathname === "/mdashboard" || props.pathname === "/mdashboardg" || props.pathname === "/mdashboardios") ?
                <Button style={{ marginLeft: 5 }} variant="outlined" size="small" className={classes.button} onClick={changeScreen}>
                    換版面
                </Button> : null
            } */}

            <div className={classes.root1}>
                <GridList className={classes.gridList} spacing={5} cellHeight='auto' cols={getColumns(props)}>
                    {[...Array(parseInt(EASY_1.length / 5) + 1).keys()].map(index => {
                        return <GridListTile key={index}>
                            {[...Array(getAValue(index)).keys()].map(index1 => {
                                let k = EASY_1[index * 5 + index1]
                                return <div key={k.id} style={{ marginBottom: 5 }}><RuleTp5
                                    key={k.id}
                                    id={k.id}
                                    t1={k.desc[0]}
                                    desc={k.sh}
                                    ruleChange={props.updateRule}
                                    rules={props.rules}
                                ></RuleTp5></div>
                            })}
                        </GridListTile>
                    })}
                </GridList>


            </div>

        </div>
    );
}

export default withWidth()(EasyBoy);