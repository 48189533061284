import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {

    },
});

export default function BackDetail(props) {
    const theme = useTheme();
    const classes = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const getDelayString = (value) => {
        if(value === 0) return "當日收盤價"
        else if(value === 1) return "隔日收盤價"
        else if(value === 2) return "隔日開盤價"
        else if(value === 3) return "當日開盤價"
        else return "當日收盤價"
    }

    const getDelayString1 = (value) => {
        if(value === 0) return "當日價"
        else if(value === 1) return "隔日收盤價"
        else if(value === 2) return "隔日開盤價"
        else return "當日價"
    }

    return (
        <div>
            {props.open ? <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography style={{ marginTop: 30, marginBottom: 10 }} className={classes.title} variant="h5" id="tableTitle" component="div">
                        條件
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}  size="small" >
                            <TableBody>
                                <TableRow key={'a'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >進場策略</TableCell>
                                    <TableCell align="left">{props.row.rname}</TableCell>
                                </TableRow>
                                <TableRow key={'b'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >起始時間</TableCell>
                                    <TableCell align="left">{props.row.start}</TableCell>
                                </TableRow>
                                <TableRow key={'c'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >結束時間</TableCell>
                                    <TableCell align="left">{props.row.end}</TableCell>
                                </TableRow>
                                <TableRow key={'d'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >股票數量</TableCell>
                                    <TableCell align="left">{props.row.num}</TableCell>
                                </TableRow>
                                <TableRow key={'e'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >交易手續費</TableCell>
                                    <TableCell align="left">{props.row.hfee}%</TableCell>
                                </TableRow>
                                <TableRow key={'0'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >已回測規則</TableCell>
                                    <TableCell align="left">{props.row.inr != null && props.row.inr !== "" ? props.row.inr : "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'1'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >未支援規則</TableCell>
                                    <TableCell align="left">{props.row.outr != null && props.row.outr !== "" ? props.row.outr : "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'3'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >停損賣出</TableCell>
                                    <TableCell align="left">{props.row.outlost}%</TableCell>
                                </TableRow>
                                <TableRow key={'2'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >停利賣出</TableCell>
                                    <TableCell align="left">{props.row.outwin}%</TableCell>
                                </TableRow>
                                <TableRow key={'3g'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >移動停利</TableCell>
                                    <TableCell align="left">{props.row.mvlost != null && props.row.mvlost !== "" ? props.row.mvlost + "%" : "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'4'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >策略賣出1</TableCell>
                                    <TableCell align="left">{props.row.outrname != null && props.row.outrname !== "" ? props.row.outrname : "無"}
                                    {props.row.outrulestr != null && props.row.outrulestr !== "" ? "：" + props.row.outrulestr : ""}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'4s'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >策略賣出2</TableCell>
                                    <TableCell align="left">{props.row.outrname1 != null && props.row.outrname1 !== "" ? props.row.outrname1 : "無"}
                                    {props.row.outrulestr1 != null && props.row.outrulestr1 !== "" ?  "：" + props.row.outrulestr1 : ""}
                                    </TableCell>
                                </TableRow>

                                <TableRow key={'4g'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >本金</TableCell>
                                    <TableCell align="left">{props.row.maxmoney != null && props.row.maxmoney !== "" ? props.row.maxmoney + "元" : "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'4i'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >單筆額度</TableCell>
                                    <TableCell align="left">{props.row.maxone != null && props.row.maxone !== "" ? props.row.maxone + "元": "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'4ii'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >最大持有天數</TableCell>
                                    <TableCell align="left">{props.row.maxholdday != null && props.row.maxholdday !== "" ? props.row.maxholdday + "天": "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'5'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >失敗訊息</TableCell>
                                    <TableCell align="left">{props.row.errmsg != null && props.row.errmsg !== "" ? props.row.errmsg : "無"}</TableCell>
                                </TableRow>
                                <TableRow key={'6'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >買進時機</TableCell>
                                    <TableCell align="left">{props.row.buydelay !=null && props.row.buydelay !== "" ? getDelayString(props.row.buydelay) : "不設限"}</TableCell>
                                </TableRow>
                                <TableRow key={'7'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >賣出時機</TableCell>
                                    <TableCell align="left">{props.row.selldelay !=null && props.row.selldelay !== "" ? getDelayString1(props.row.selldelay) : "不設限"}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Typography style={{ marginTop: 30, marginBottom: 10 }} className={classes.title} variant="h5" id="tableTitle" component="div">
                        結果
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" >
                            <TableBody>
                                <TableRow key={'a1'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >總交易數</TableCell>
                                    <TableCell align="left">{props.row.result != null ? props.row.result.tran_count + '筆' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'b1'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >勝場數</TableCell>
                                    <TableCell align="left">{props.row.result != null && props.row.result.wc != null ? props.row.result.wc + '筆' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'c1'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >敗場數</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.lc != null ? props.row.result.lc + '筆' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'d1'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >勝率</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.win_rate != null? props.row.result.win_rate + '%' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'e2'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >敗率</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.lostr != null ? props.row.result.lostr + '%' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'01'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >報酬率</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.roi != null ? props.row.result.roi + '%' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >平均報酬率</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.avroi != null ? props.row.result.avroi + '%' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13b'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >平均獲利</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.avgw != null ? props.row.result.avgw + '元' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13a'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >平均損失</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.avgl != null ? props.row.result.avgl + '元' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13c'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >盈虧比</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.wl != null ? props.row.result.wl : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13d'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >期望值</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.expv != null ? props.row.result.expv : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13dl'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >平均持有天數</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.avghold != null ? props.row.result.avghold + '天': '-'}</TableCell>
                                </TableRow>


                                <TableRow key={'011'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >未實現報酬率</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.frate != null ? props.row.result.frate + '%' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'131'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >未實現平均報酬率</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.avfroi != null ? props.row.result.avfroi + '%' : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'13kk'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >未實現平均持有天數</TableCell>
                                    <TableCell align="left">{props.row.result != null  && props.row.result.avghold1 != null ? props.row.result.avghold1  + '天': '-'}</TableCell>
                                </TableRow>

                                <TableRow key={'132'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >買入金額</TableCell>
                                    <TableCell align="left">{props.row.result != null && props.row.result.bamount != null ? ((props.row.result.bamount / 1000).toFixed(0)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "千元" : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'133'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >賣出金額</TableCell>
                                    <TableCell align="left">{props.row.result != null && props.row.result.samount != null ? ((props.row.result.samount / 1000).toFixed(0)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "千元" : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'134'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >手續費</TableCell>
                                    <TableCell align="left">{props.row.result != null && props.row.result.handle_fee != null ? props.row.result.handle_fee.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "元" : '-'}</TableCell>
                                </TableRow>
                                <TableRow key={'135'}>
                                    <TableCell style={{ whiteSpace: 'nowrap', width: 150 }} >交易稅</TableCell>
                                    <TableCell align="left">{props.row.result != null ? props.row.result.tran_fee.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + "元" : '-'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary" autoFocus>
                        關閉
                    </Button>
                </DialogActions>
            </Dialog> : null}
        </div>
    );
}
