import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import zhtwLocale from "date-fns/locale/zh-TW";
import Button from '@material-ui/core/Button';
import moment from 'moment'
import BackTable from './BackTable1'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { getSynNo, putInpQueryParam } from '../util/Syno'
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        // margin: theme.spacing(1),
        // minWidth: 100,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function BackTest(props) {
    const classes = useStyles();
    const [rid, setRid] = React.useState('');
    const [outwin, setOutwin] = React.useState(15);
    const [outlost, setOutlost] = React.useState(-20);
    const [num, setNum] = React.useState(2000);
    const [tranFee, setTranFee] = React.useState(0.1425);
    const [rules, setRules] = React.useState(null);
    const [outrid, setOutRid] = React.useState('');
    const [outrid1, setOutRid1] = React.useState('');
    const [outrname1, setOutname1] = React.useState('');

    const [startdate, setStartDate] = React.useState(Date.parse("2020-01-01"));
    const [endate, setEndDate] = React.useState(new Date());
    const [outrname, setOutname] = React.useState('');
    const [rname, setRname] = React.useState('');
    const [backlist, setBacklist] = React.useState(null);
    const [getlic, setlic] = React.useState(0);
    const [mvlost, setMvlost] = React.useState('');
    const [maxone, setMaxOne] = React.useState('');
    const [maxmoney, setMaxMoney] = React.useState('');
    const [maxholdday, setMaxHoldDay] = React.useState('');
    const [buydelay, setBuyDelay] = React.useState('');
    const [selldelay, setSellDelay] = React.useState('');

    const [msgopen, setMsgOpen] = React.useState(false);
    const [errmsg, setErrMsg] = React.useState(null);
    const pause = useRef(false);


    const handleMsgClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrMsg(null)
        setMsgOpen(false);
    };

    useEffect(() => {
        checkLic()
        fectchUserAPI()
        getBackList()

        const interval = setInterval(() => {
            if (!pause.current) getBackList()
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const stopRefresh = () => {
        pause.current = true
    };

    const restartRefresh = () => {
        pause.current = false
    };


    function checkLic() {
        let lic = localStorage.getItem('lic')
        if (lic != null) {
            let param = {
                lic: lic
            }

            fetch("/jstock/v1/stock/checklic", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0 && result.hlic != null) {
                            setlic(result.hlic.licok)
                        } else {
                            setlic(0)
                        }
                    }
                );
        }
    }

    function delbacktest(id) {
        let lic = localStorage.getItem('lic')
        if (lic != null) {
            let param = {
                lic: lic,
                id: id
            }

            fetch("/jstock/v1/stock/delbacktest", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(
                    result => {
                        getBackList()
                    }
                );
        }
    }

    const fectchUserAPI = () => {
        let synno = localStorage.getItem('synno') != null ? localStorage.getItem('synno') : getSynNo()
        let param = localStorage.getItem('lic') === null ? {
            synno: synno,
        } : {
            synno: synno,
            lic: localStorage.getItem('lic'),
        };


        fetch("/jstock/v1/stock/getloverule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        setRules(result.data.allrules)
                    }
                }
            );
    }


    const handleChange = (event, child) => {
        setRid(event.target.value)
        setRname(child.props.name)
    };

    const handleBuyDelayChange = (event) => {
        setBuyDelay(event.target.value);
    };

    const handleSellDelayChange = (event) => {
        setSellDelay(event.target.value);
    };


    const handleMaxholdDayChange = (event) => {
        setMaxHoldDay(event.target.value);
    };

    const handleMaxOneChange = (event) => {
        setMaxOne(event.target.value);
    };

    const handleMaxMoneyChange = (event) => {
        setMaxMoney(event.target.value);
    };

    const handleChange1 = (event) => {
        setOutwin(event.target.value);
    };

    const handleChange2 = (event) => {
        setOutlost(event.target.value);
    };

    const handleMvLostChange = (event) => {
        setMvlost(event.target.value);
    };

    const handleChange3 = (event) => {
        setNum(event.target.value);
    };

    const handleChange4 = (event) => {
        setTranFee(event.target.value);
    };

    const handleChange5 = (event, child) => {
        setOutRid(event.target.value)
        setOutname(child.props.name)
    };

    const handleChange6 = (event, child) => {
        setOutRid1(event.target.value)
        setOutname1(child.props.name)
    };


    const runBacktest = () => {
        let lic = localStorage.getItem('lic')
        let runcount = 0

        if (backlist != null) {
            let s1 = backlist.filter(bk => bk.status === 0 || bk.status === 1);
            runcount = s1.length
        }

        if (runcount >= 2) {
            setErrMsg("最多同時兩筆正在等待執行或者執行中的回測，請稍後在執行。")
            setMsgOpen(true)
        } else if (rid == null || rid === '') {
            setErrMsg("請選擇進場策略")
            setMsgOpen(true)
        } else if (lic != null && rid != '') {
            let a = {
                lic: lic,
                rid: rid,
                rname: rname,
                // start: moment(startdate).format('YYYYMMDD'),
                // end: moment(endate).format('YYYYMMDD'),
                num: num,
                hfee: tranFee,
                inp: {}
            }

            a = putInpQueryParam(a)

            if (startdate != null && startdate !== '') {
                a["start"] = moment(startdate).format('YYYYMMDD')
            } else {
                a["start"] = moment('20190101').format('YYYYMMDD')
            }

            if (endate != null && endate !== '') {
                a["end"] = moment(endate).format('YYYYMMDD')
            } else {
                a["end"] = moment(new Date()).format('YYYYMMDD')
            }

            if (outwin !== '')
                a["outwin"] = parseFloat(outwin)
            if (outlost !== '')
                a["outlost"] = parseFloat(outlost)
            if (outrid !== '')
                a["outrid"] = outrid
            if (outrid1 !== '')
                a["outrid1"] = outrid1

            if (outrname !== '')
                a["outrname"] = outrname
            if (outrname1 !== '')
                a["outrname1"] = outrname1

            if (mvlost !== '')
                a['mvlost'] = mvlost
            if (maxone !== '')
                a['maxone'] = maxone
            if (maxmoney !== '')
                a['maxmoney'] = maxmoney
            if (maxholdday !== '')
                a['maxholdday'] = maxholdday

            if (buydelay !== '')
                a['buydelay'] = buydelay
            if (selldelay !== '')
                a['selldelay'] = selldelay

            fetch("/jstock/v1/stock/backtest", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        getBackList()
                    }
                );
        } else {
            setErrMsg("此功能為訂閱專屬，請先訂閱後在來使用，謝謝。")
            setMsgOpen(true)
        }
    }

    const getBackList = () => {
        let lic = localStorage.getItem('lic')

        if (lic != null) {
            let a = {
                lic: lic
            }

            fetch("/jstock/v1/stock/backtestlist", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setBacklist(result.data)
                    }
                );
        }
    }

    return (
        <div className={classes.root}>
            <div style={{ fontSize: 10, color: '#8c8c8c' }} >
                #訂閱專屬。回測功能目前支援基本面與技術面跟部分籌碼面選股的規則。尚未支援籌碼面，輕鬆選，懶人選股等規則。目前測試年限最大為5年。<br />
                #排隊。回測需要排隊與準備資料，依據策略測試花費時間也不一定，所以可以稍後再回來看結果。
            </div>
            <Snackbar open={msgopen} autoHideDuration={6000} onClose={handleMsgClose}>
                <Alert onClose={handleMsgClose} severity="error">
                    {errmsg}
                </Alert>
            </Snackbar>
            {/* maxHeight: '40vh', overflowY: 'scroll'  */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhtwLocale}>
                <Grid container spacing={1} style={{ marginTop: 10, }}>




                    <Grid item xs={6} sm={4} md={3}>
                        <KeyboardDatePicker
                            size="small"
                            fullWidth={true}
                            required
                            // style={{ width: 300 }}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="回測開始日"
                            format="yyyy/MM/dd"
                            value={startdate}
                            minDate={Date.parse("2018-01-01")}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={date => handleStartDateChange(date)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <KeyboardDatePicker
                            size="small"
                            required
                            // style={{ width: 300 }}
                            fullWidth={true}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="回測結束日"
                            format="yyyy/MM/dd"
                            value={endate}
                            minDate={Date.parse("2018-01-01")}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={date => handleEndDateChange(date)}
                        />
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl} required>
                            <InputLabel id="demo-simple-select-outlined-label">進場策略</InputLabel>

                            {rules != null && rules.length > 0 ?
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={rid}
                                    onChange={handleChange}
                                    label="進場策略"
                                    shrink={"true"}
                                >
                                    <MenuItem value=''>
                                        <em>請選擇...</em>
                                    </MenuItem>
                                    {rules.map((data, index) => { return (<MenuItem key={data.id} name={data.name} value={data.id}>{data.name}</MenuItem>) })}
                                </Select> :
                                <Select
                                    shrink={"true"}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={rid}
                                    onChange={handleChange}
                                    label="進場策略"
                                >
                                    <MenuItem value=''>
                                        <em>請先新增選股策略</em>
                                    </MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl} required>
                            <InputLabel id="demo-simple-select-outlined-labelv">持有股票支數</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelv"
                                id="demo-simple-select-outlinedv"
                                value={num}
                                onChange={e =>handleChange3(e)}
                                label="最多持有幾檔股票"
                            >
                                <MenuItem value={5}>5檔</MenuItem>
                                <MenuItem value={10}>10檔</MenuItem>
                                <MenuItem value={15}>15檔</MenuItem>
                                <MenuItem value={20}>20檔</MenuItem>
                                <MenuItem value={50}>50檔</MenuItem>
                                <MenuItem value={100}>100檔</MenuItem>
                                <MenuItem value={200}>200檔</MenuItem>
                                <MenuItem value={1000}>1000檔</MenuItem>
                                <MenuItem value={2000}>2000檔</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl} required>
                            <InputLabel id="demo-simple-select-outlined-labelg">交易手續費</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelg"
                                id="demo-simple-select-outlinedg"
                                value={tranFee}
                                onChange={e => handleChange4(e)}
                                label="交易手續費"
                            >
                                <MenuItem value={0.1425}>0.1425%</MenuItem>
                                <MenuItem value={0.12825}>0.12825%(9折)</MenuItem>
                                <MenuItem value={0.114}>0.114%(8折)</MenuItem>
                                <MenuItem value={0.09975}>0.09975%(7折)</MenuItem>
                                <MenuItem value={0.0855}>0.0855%(6折)</MenuItem>
                                <MenuItem value={0.07125}>0.07125%(5折)</MenuItem>
                                <MenuItem value={0.04275}>0.04275%(3折)</MenuItem>
                                <MenuItem value={0.0285}>0.0285%(2折)</MenuItem>
                                <MenuItem value={0.01425}>0.01425%(1折)</MenuItem>
                                <MenuItem value={0}>0%</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={4} md={3}>
                        {/* <TextField
                            label="停利賣出"
                            id="outwin"
                            className={clsx(classes.margin, classes.textField)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            size="small"
                            variant="outlined"
                            value={outwin}
                            fullWidth
                            required
                            onChange={handleChange1}
                        /> */}

                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl} required>
                            <InputLabel id="demo-simple-select-outlined-labely">停利賣出</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labely"
                                id="demo-simple-select-outlinedy"
                                value={outwin}
                                onChange={e => handleChange1(e)}
                                label="出場條件(停利)"
                            >
                                <MenuItem value=''>
                                    <em>請選擇停利%</em>
                                </MenuItem>
                                {[...Array(41).keys()].map(i => {
                                    return <MenuItem key={i * 0.5} value={i * 0.5}>{i * 0.5}%</MenuItem>
                                }
                                )}
                                
                                <MenuItem value={30}>30%</MenuItem>
                                <MenuItem value={40}>40%</MenuItem>
                                <MenuItem value={50}>50%</MenuItem>
                                <MenuItem value={60}>60%</MenuItem>
                                <MenuItem value={100}>100%</MenuItem>
                                <MenuItem value={300}>300%</MenuItem>
                                <MenuItem value={500}>500%</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={4} md={3}>
                        {/* <TextField
                            label="停損賣出"
                            id="outlost"
                            className={clsx(classes.margin, classes.textField)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                // startAdornment: <InputAdornment position="start">-</InputAdornment>,
                            }}
                            size="small"
                            variant="outlined"
                            value={outlost}
                            fullWidth
                            required
                            onChange={handleChange2}
                        /> */}


                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl} required>
                            <InputLabel id="demo-simple-select-outlined-labelz">停損賣出</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelz"
                                id="demo-simple-select-outlinedz"
                                value={outlost}
                                onChange={e => handleChange2(e)}
                                label="出場條件(停損)"
                            >
                                <MenuItem value=''>
                                    <em>請選擇停損%</em>
                                </MenuItem>
                                {[...Array(41).keys()].map(i => {
                                    return <MenuItem key={-i * 0.5} value={-i * 0.5}>{-i * 0.5}%</MenuItem>
                                }
                                )}
                                
                                <MenuItem value={-30}>-30%</MenuItem>
                                <MenuItem value={-40}>-40%</MenuItem>
                                <MenuItem value={-50}>-50%</MenuItem>
                                <MenuItem value={-60}>-60%</MenuItem>
                                <MenuItem value={-70}>-70%</MenuItem>
                                <MenuItem value={-100}>-100%</MenuItem>
                                <MenuItem value={-300}>-300%</MenuItem>
                                <MenuItem value={-500}>-500%</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        非必選區
                        <Divider />
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-labelk">移動停利</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelk"
                                id="demo-simple-select-outlinedk"
                                value={mvlost}
                                onChange={e => handleMvLostChange(e)}
                                label="移動停利"
                            >
                                <MenuItem value=''>
                                    <em>請選擇%</em>
                                </MenuItem>
                                {[...Array(41).keys()].map(i => {
                                    return <MenuItem key={-i * 0.5} value={-i * 0.5}>{-i * 0.5}%</MenuItem>
                                }
                                )}
                                <MenuItem value={-30}>-30%</MenuItem>
                                <MenuItem value={-40}>-40%</MenuItem>
                                <MenuItem value={-50}>-50%</MenuItem>
                                <MenuItem value={-60}>-60%</MenuItem>
                                <MenuItem value={-100}>-100%</MenuItem>
                                <MenuItem value={-300}>-300%</MenuItem>
                                <MenuItem value={-500}>-500%</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">賣出策略1</InputLabel>

                            {rules != null && rules.length > 0 ?
                                <Select
                                    shrink="true"
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={outrid}
                                    onChange={handleChange5}
                                    label="賣出策略1"
                                >
                                    <MenuItem value=''>
                                        <em>請選擇...</em>
                                    </MenuItem>
                                    {rules.map((data, index) => { return (<MenuItem key={data.id} name={data.name} value={data.id} >{data.name}</MenuItem>) })}
                                </Select> :
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={outrid}
                                    onChange={handleChange5}
                                    label="賣出策略1"
                                >
                                    <MenuItem value=''>
                                        <em>請先新增選股策略</em>
                                    </MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">賣出策略2</InputLabel>

                            {rules != null && rules.length > 0 ?
                                <Select
                                    shrink="true"
                                    labelId="demo-simple-select-outlined-label2"
                                    id="demo-simple-select-outlined2"
                                    value={outrid1}
                                    onChange={handleChange6}
                                    label="賣出策略2"
                                >
                                    <MenuItem value=''>
                                        <em>請選擇...</em>
                                    </MenuItem>
                                    {rules.map((data, index) => { return (<MenuItem key={data.id} name={data.name} value={data.id} >{data.name}</MenuItem>) })}
                                </Select> :
                                <Select
                                    labelId="demo-simple-select-outlined-label2"
                                    id="demo-simple-select-outlined2"
                                    value={outrid1}
                                    onChange={handleChange6}
                                    label="賣出策略2"
                                >
                                    <MenuItem value=''>
                                        <em>請先新增選股策略</em>
                                    </MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-labely2">本金</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labely2"
                                id="demo-simple-select-outlinedy2"
                                value={maxmoney}
                                onChange={e => handleMaxMoneyChange(e)}
                                label="本金"
                            >
                                <MenuItem value=''>
                                    <em>不設限</em>
                                </MenuItem>
                                <MenuItem value={100000}>10萬</MenuItem>
                                <MenuItem value={200000}>20萬</MenuItem>
                                <MenuItem value={300000}>30萬</MenuItem>
                                <MenuItem value={400000}>40萬</MenuItem>
                                <MenuItem value={500000}>50萬</MenuItem>
                                <MenuItem value={600000}>60萬</MenuItem>
                                <MenuItem value={700000}>70萬</MenuItem>
                                <MenuItem value={800000}>80萬</MenuItem>
                                <MenuItem value={900000}>90萬</MenuItem>
                                <MenuItem value={1000000}>100萬</MenuItem>
                                <MenuItem value={2000000}>200萬</MenuItem>
                                <MenuItem value={3000000}>300萬</MenuItem>
                                <MenuItem value={4000000}>400萬</MenuItem>
                                <MenuItem value={5000000}>500萬</MenuItem>
                                <MenuItem value={6000000}>600萬</MenuItem>
                                <MenuItem value={7000000}>700萬</MenuItem>
                                <MenuItem value={8000000}>800萬</MenuItem>
                                <MenuItem value={9000000}>900萬</MenuItem>
                                <MenuItem value={10000000}>1000萬</MenuItem>
                                <MenuItem value={20000000}>2000萬</MenuItem>
                                <MenuItem value={30000000}>3000萬</MenuItem>
                                <MenuItem value={40000000}>4000萬</MenuItem>
                                <MenuItem value={50000000}>5000萬</MenuItem>
                                <MenuItem value={100000000}>1億</MenuItem>
                                <MenuItem value={200000000}>2億</MenuItem>
                                <MenuItem value={300000000}>3億</MenuItem>
                                <MenuItem value={400000000}>4億</MenuItem>
                                <MenuItem value={500000000}>5億</MenuItem>
                                <MenuItem value={1000000000}>10億</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>



                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-labely1">單筆額度</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labely1"
                                id="demo-simple-select-outlinedy1"
                                value={maxone}
                                onChange={e => handleMaxOneChange(e)}
                                label="單筆額度"
                            >
                                <MenuItem value=''>
                                    <em>不設限</em>
                                </MenuItem>
                                <MenuItem value={10000}>1萬</MenuItem>
                                <MenuItem value={20000}>2萬</MenuItem>
                                <MenuItem value={30000}>3萬</MenuItem>
                                <MenuItem value={40000}>4萬</MenuItem>
                                <MenuItem value={50000}>5萬</MenuItem>
                                <MenuItem value={60000}>6萬</MenuItem>
                                <MenuItem value={70000}>7萬</MenuItem>
                                <MenuItem value={80000}>8萬</MenuItem>
                                <MenuItem value={90000}>9萬</MenuItem>
                                <MenuItem value={100000}>10萬</MenuItem>
                                <MenuItem value={200000}>20萬</MenuItem>
                                <MenuItem value={300000}>30萬</MenuItem>
                                <MenuItem value={400000}>40萬</MenuItem>
                                <MenuItem value={500000}>50萬</MenuItem>
                                <MenuItem value={600000}>60萬</MenuItem>
                                <MenuItem value={700000}>70萬</MenuItem>
                                <MenuItem value={800000}>80萬</MenuItem>
                                <MenuItem value={900000}>90萬</MenuItem>
                                <MenuItem value={1000000}>100萬</MenuItem>
                                <MenuItem value={2000000}>200萬</MenuItem>
                                <MenuItem value={3000000}>300萬</MenuItem>
                                <MenuItem value={4000000}>400萬</MenuItem>
                                <MenuItem value={5000000}>500萬</MenuItem>
                                <MenuItem value={6000000}>600萬</MenuItem>
                                <MenuItem value={7000000}>700萬</MenuItem>
                                <MenuItem value={8000000}>800萬</MenuItem>
                                <MenuItem value={9000000}>900萬</MenuItem>
                                <MenuItem value={10000000}>1000萬</MenuItem>
                                <MenuItem value={20000000}>2000萬</MenuItem>
                                <MenuItem value={30000000}>3000萬</MenuItem>
                                <MenuItem value={40000000}>4000萬</MenuItem>
                                <MenuItem value={50000000}>5000萬</MenuItem>
                                <MenuItem value={100000000}>1億</MenuItem>
                                <MenuItem value={200000000}>2億</MenuItem>
                                <MenuItem value={300000000}>3億</MenuItem>
                                <MenuItem value={400000000}>4億</MenuItem>
                                <MenuItem value={500000000}>5億</MenuItem>
                                <MenuItem value={1000000000}>10億</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-labelyday">最大持有天數</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-labelyday"
                                id="demo-simple-select-outlinedyday"
                                value={maxholdday}
                                onChange={e => handleMaxholdDayChange(e)}
                                label="最大持有天數"
                            >
                                <MenuItem value=''>
                                    <em>不設限</em>
                                </MenuItem>
                                <MenuItem value={1}>1天</MenuItem>
                                <MenuItem value={2}>2天</MenuItem>
                                <MenuItem value={3}>3天</MenuItem>
                                <MenuItem value={4}>4天</MenuItem>
                                <MenuItem value={5}>5天</MenuItem>
                                <MenuItem value={6}>6天</MenuItem>
                                <MenuItem value={7}>7天</MenuItem>
                                <MenuItem value={8}>8天</MenuItem>
                                <MenuItem value={9}>9天</MenuItem>
                                <MenuItem value={10}>10天</MenuItem>
                                <MenuItem value={15}>15天</MenuItem>
                                <MenuItem value={20}>20天</MenuItem>
                                <MenuItem value={25}>25天</MenuItem>
                                <MenuItem value={30}>30天</MenuItem>
                                <MenuItem value={35}>35天</MenuItem>
                                <MenuItem value={45}>45天</MenuItem>
                                <MenuItem value={50}>50天</MenuItem>
                                <MenuItem value={60}>60天</MenuItem>
                                <MenuItem value={70}>70天</MenuItem>
                                <MenuItem value={80}>80天</MenuItem>
                                <MenuItem value={90}>90天</MenuItem>
                                <MenuItem value={120}>120天</MenuItem>
                                <MenuItem value={150}>150天</MenuItem>
                                <MenuItem value={200}>200天</MenuItem>
                                <MenuItem value={240}>240天</MenuItem>
                                <MenuItem value={300}>300天</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-buydelay">買進時機</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-buydelay"
                                id="demo-simple-select-buydelay"
                                value={buydelay}
                                onChange={e => handleBuyDelayChange(e)}
                                label="買進時機"
                            >
                                <MenuItem value=''>
                                    <em>不設限</em>
                                </MenuItem>
                                <MenuItem value={0}>當日收盤價</MenuItem>
                                <MenuItem value={1}>隔日收盤價</MenuItem>
                                <MenuItem value={2}>隔日開盤價</MenuItem>
                                <MenuItem value={3}>當日開盤價</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <FormControl size="small" variant="outlined" fullWidth={true} className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-selldelay">賣出時機</InputLabel>
                            <Select
                                shrink="true"
                                labelId="demo-simple-select-outlined-selldelay"
                                id="demo-simple-select-selldelay"
                                value={selldelay}
                                onChange={e => handleSellDelayChange(e)}
                                label="賣出時機"
                            >
                                <MenuItem value=''>
                                    <em>不設限</em>
                                </MenuItem>
                                <MenuItem value={0}>當日價</MenuItem>
                                <MenuItem value={1}>隔日收盤價</MenuItem>
                                <MenuItem value={2}>隔日開盤價</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    {getlic === 1 ? <Button size="large" variant="outlined" onClick={runBacktest} >
                                        執行回測
                                    </Button> : <Button size="large" disabled variant="outlined" onClick={runBacktest} >
                                        執行回測
                                    </Button>}
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </MuiPickersUtilsProvider>
            <BackTable data={backlist} delbacktest={delbacktest} ismobile={props.ismobile} stopRefresh={stopRefresh} restartRefresh={restartRefresh} />

        </div>
    );
}
