import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(ymd, fi, fistatus, letter, lestatus, se, sestatus) {
    return { ymd, fi, fistatus, letter, lestatus, se, sestatus };
}


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
  },
}));

export default function TLEDetail(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    useEffect(() => {
        // console.log("props.open:" + props.open + ",props.stockId:" + props.stockId)
        if (props.open) fectchStockData(props.stockId)
    }, [props.stockId]);


    function fectchStockData(stockId) {
        if (stockId != null) {
            let a = {
                sid: stockId
            };

            fetch("/jstock/v1/stock/tle", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let dd = result.bs.map(row =>
                            createData(moment(row.ymd, "YYYYMMDD").format('YYYY/MM/DD'), row.fi, row.fistatus, row.letter, row.lestatus, row.se, row.sestatus)
                        )
                        setBuyselldata(dd)
                    },
                    error => {
                    }
                );
        }
    }

    const renderInpriceStatusVTLE = (status, value) => {
        if (status === -1) {
          return <Button size="small" variant="outlined" style={{whiteSpace: "nowrap",  minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        } else if (status === 4) {
          return <Button size="small" style={{ whiteSpace: "nowrap", minWidth: 90, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>
            大賣{value}張
          </Button>
        } else if (status === 3) {
          return <Button size="small" variant="outlined" style={{whiteSpace: "nowrap",  minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>
            小賣{value}張
        </Button>
        } else if (status === 0) {
          return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.faircolor }} className={classes.button1} >
            中立{value}張
          </Button>
        } else if (status === 1) {
          return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.upcolorbox }} className={classes.button1} >
            小買{value}張
        </Button>
        } else if (status === 2) {
          return <Button size="small" style={{ whiteSpace: "nowrap", minWidth: 90, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >
            大買{value}張
        </Button>
        } else {
          return <Button size="small" variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >
            -
          </Button>
        }
      }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">三大法人</DialogTitle>

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="center">外資</StyledTableCell>
                                <StyledTableCell align="center">投信</StyledTableCell>
                                <StyledTableCell align="center">自營商</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.ymd}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.ymd}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" style={{}}>{renderInpriceStatusVTLE(row.fistatus, row.fi)}</StyledTableCell>
                                    <StyledTableCell align="left" style={{}}>{renderInpriceStatusVTLE(row.lestatus, row.letter)}</StyledTableCell>
                                    <StyledTableCell align="left" style={{}}>{renderInpriceStatusVTLE(row.sestatus, row.se)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
