import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import FinRateList from '../details/FinRateList3';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart, CandlestickChart } from 'echarts/charts';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {

    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import AVSetting from './AVSetting'
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, BarChart, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap',
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap',

        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function FinDetail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);

    const [startDraw, setStartDraw] = React.useState(false);
    // const [drawLine, setDrawLine] = React.useState(false);
    // const [drawGolden, setDrawGolden] = React.useState(false);

    const [tp, setTp] = React.useState(0)
    const lines = React.useRef([]);
    const goldenLines = React.useRef([]);

    const drawLine = React.useRef(false);
    const drawGolden = React.useRef(false);



    const mouseAction = React.useRef(null);

    const zStart = useRef(85);
    const zEnd = useRef(100);


    const rendereps = (year, eps) => {
        if (year === -99999) {
            return <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >-</Button>
        } else
            return <Button variant="outlined" style={{ minWidth: 110, whiteSpace: "nowrap" }} className={classes.button1} >{year}年 {eps}元</Button>
    }

    const rendereps1 = (eps) => {
        return <Button variant="outlined" style={{ minWidth: 110, whiteSpace: "nowrap" }} className={classes.button1} >{eps}元</Button>
    }

    const rendereps2 = (year, eps) => {
        if (year === "") {
            return <Button variant="outlined" style={{ minWidth: 110 }} className={classes.button1} >-</Button>
        } else
            return <Button variant="outlined" style={{ minWidth: 110, whiteSpace: "nowrap" }} className={classes.button1} >{year} {eps}元</Button>
    }

    const pbcolor = dy => {
        if (dy <= 0.7) return BColor.upcolor
        else return BColor.faircolor
    }

    const getTp = tp => {
        if (tp === "s") { return "上市"; }
        else if (tp === "o") { return "上櫃"; }
        else { return "" };
    }

    const dycolor = dy => {
        if (dy > 8) return BColor.upcolor
        else return BColor.faircolor
    }

    const pricecolor = incv => {
        if (incv > 0) return BColor.upcolor
        else if (incv < 0) return BColor.downcolor
        else return BColor.faircolor
    }

    const zerotosla1 = (value, unit) => {
        if (value === "-") return <Button variant="outlined" style={{ minWidth: 110, whiteSpace: "nowrap" }} className={classes.button1} >{0}{unit}</Button>
        else return <Button variant="outlined" style={{ minWidth: 110, whiteSpace: "nowrap" }} className={classes.button1} >{value}{unit}</Button>
    }

    function rendermeetingdate(row) {
        if (row.ii != undefined && row.ii.mi != null && row.ii.ymd != "")
            return <StyledTableCell align="left">
                {row.ii.mi.ymd}
            </StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無董事會</Button>
        </StyledTableCell>
    }

    function renderyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = (row.ii.mi.cash * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            }
        } else {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </StyledTableCell>
        }
    }

    function renderRate(row) {
        if (row.ii != undefined && row.ii.mi != null) {
            let rate = row.ii.mi.rate
            let ratetp = row.ii.mi.ratetp

            if (ratetp === 0) {
                if (rate < 80) {
                    return <StyledTableCell style={{}} size="small" align="left">
                        <Button variant="outlined" style={{ minWidth: 110, marginBottom: 0 }} className={classes.button1} >{rate}%</Button>
                    </StyledTableCell>
                } else {
                    return <StyledTableCell style={{}} size="small" align="left">
                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{rate}%</Button>
                    </StyledTableCell>
                }
            } else if (ratetp === 1) {
                return <StyledTableCell style={{}} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 110, marginBottom: 0 }} className={classes.button1} >當期無盈餘</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell style={{}} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 110, marginBottom: 0 }} className={classes.button1} >-</Button>
                </StyledTableCell>
            }
        }
    }

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        props.bigupdate()
        setOpen(false);
    };


    function renderValue0(value, unit, stockno, stockName) {
        let word = value + unit
        if (value === -99999.0) {
            word = " - "
        }

        return <Button size="small" variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110 }} className={classes.button1}>{word}</Button>
    }

    function getXX(tp) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                return vv.x
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                return vv.x
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                return vv.x
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                return vv.x
            })
            return data
        }
    }


    // p.o, p.h, p.l, p.p
    //开盘(open)，收盘(close)，最低(lowest)，最高(highest)

    function getYY1(tp) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                return vv.y
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                return vv.y
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                return vv.y
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                return vv.y
            })
            return data
        }
    }

    function getYY2(tp) {
        if (tp === 0) {
            let data = []
            for (var i = 0; i < props.row.kd3.d.length; i++) {
                data.push([i, props.row.kd3.d[i].v, props.row.kd3.d[i].i]);
            }
            return data
        } else if (tp === 1) {
            let data = []
            for (var i = 0; i < props.row.kd3.w.length; i++) {
                data.push([i, props.row.kd3.w[i].v, props.row.kd3.w[i].i]);
            }
            return data
        } else if (tp === 2) {
            let data = []
            for (var i = 0; i < props.row.kd3.m.length; i++) {
                data.push([i, props.row.kd3.m[i].v, props.row.kd3.m[i].i]);
            }
            return data
        } else {
            let data = []
            for (var i = 0; i < props.row.kd3.d.length; i++) {
                data.push([i, props.row.kd3.d[i].v, props.row.kd3.d[i].i]);
            }
            return data
        }
    }

    function getMAV(vv, line) {
        if (line === 1)
            return vv.a1
        else if (line === 2)
            return vv.a2
        else if (line === 3)
            return vv.a3
        else if (line === 4)
            return vv.a4
        else if (line === 5)
            return vv.a5
        else return vv.a1
    }

    function getMA(tp, line) {
        if (tp === 0) {
            let data = props.row.kd3.d.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        } else if (tp === 1) {
            let data = props.row.kd3.w.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        } else if (tp === 2) {
            let data = props.row.kd3.m.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        } else {
            let data = props.row.kd3.d.map(vv => {
                let v1 = getMAV(vv, line)
                if (v1 === 0) return null
                else return v1
            })
            return data
        }
    }

    function getDefaultOption() {
        let option = {
            animation: false,
            legend: {
                textStyle: {
                    fontSize: 10
                }
            },
            grid: [
                {
                    top: '30',
                    left: '0',
                    right: '0',
                    height: '250',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '300',
                    left: '10',
                    right: '10',
                    height: '100',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            visualMap: {
                show: false,
                dimension: 2,
                seriesIndex: 4,
                pieces: [{
                    value: 1,
                    color: "#d41c1c"
                }, {
                    value: 0,
                    color: "#8f8f8f"
                }, {
                    value: -1,
                    color: '#65b206'
                }]
            },
        };

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            if (startDraw) option['dataZoom'] = getMobileDataZoom(zStart.current, zEnd.current, true)
            else option['dataZoom'] = getMobileDataZoom(zStart.current, zEnd.current)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
            if (startDraw) option['dataZoom'] = getComputerDataZoom(zStart.current, zEnd.current, 410, true)
            else option['dataZoom'] = getComputerDataZoom(zStart.current, zEnd.current, 410)
        }

        return option
    }


    function genoption2() {
        let t = tp
        let k = getDefaultOption()

        let ks = '日K'
        if (t === 0) ks = '日K'
        else if (t === 1) ks = '周K'
        else if (t === 2) ks = '月K'

        let av1 = localStorage.getItem('av1') === null ? 'MA5' : 'MA' + localStorage.getItem('av1')
        let av2 = localStorage.getItem('av2') === null ? 'MA20' : 'MA' + localStorage.getItem('av2')
        let av3 = localStorage.getItem('av3') === null ? 'MA60' : 'MA' + localStorage.getItem('av3')
        let av4 = localStorage.getItem('av4') === null ? 'MA120' : 'MA' + localStorage.getItem('av4')
        let av5 = localStorage.getItem('av5') === null ? 'MA200' : 'MA' + localStorage.getItem('av5')

        let av1i = localStorage.getItem('av1i') === null ? true : localStorage.getItem('av1i').toLowerCase() === 'true'
        let av2i = localStorage.getItem('av2i') === null ? true : localStorage.getItem('av2i').toLowerCase() === 'true'
        let av3i = localStorage.getItem('av3i') === null ? true : localStorage.getItem('av3i').toLowerCase() === 'true'
        let av4i = localStorage.getItem('av4i') === null ? false : localStorage.getItem('av4i').toLowerCase() === 'true'
        let av5i = localStorage.getItem('av5i') === null ? false : localStorage.getItem('av5i').toLowerCase() === 'true'

        k['legend']['data'] = [av1, av2, av3, av4, av5]

        let selectedo = {}
        selectedo[av1] = av1i
        selectedo[av2] = av2i
        selectedo[av3] = av3i
        selectedo[av4] = av4i
        selectedo[av5] = av5i
        k['legend']['selected'] = selectedo

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: getXX(t),
            axisLine: { onZero: false },
            splitLine: { show: false },
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax'
        }

        if (props.ismobile) {
            x1['axisPointer'] = {
                show: true
            }
        }

        let x2 = {
            type: 'category',
            gridIndex: 1,
            data: getXX(t),
            scale: true,
            boundaryGap: true,
            min: 'dataMin',
            max: 'dataMax',
            axisLine: { onZero: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            splitNumber: 20,
        }

        if (props.ismobile) {
            x2['axisPointer'] = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }
        }

        k['xAxis'] = [x1, x2]

        let yAxis = [{
            scale: true,
            show: true,
            z: 100,
            position: 'left',
            axisLabel: {
                inside: true,
                margin: 0
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            }
        },
        {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
        }
        ]
        k['yAxis'] = yAxis

        let markdata = []
        if (drawLine.current && lines != null && lines.current.length > 0) {
            lines.current.map(l => {
                if (l != null && l.length == 4)
                    markdata.push([
                        { x: l[0], y: l[1] },
                        { x: l[2], y: l[3] }
                    ])
            })
        } else if (drawGolden.current && goldenLines != null && goldenLines.current.length > 0) {
            goldenLines.current.map(l => {
                if (l != null && l.length == 5)
                    markdata.push([
                        { x: l[0], y: l[1], value: l[4] + "", label: { show: true } },
                        { x: l[2], y: l[3] }
                    ])
            })
        }

        let serdata = [
            {
                name: ks,
                type: 'candlestick',
                data: getYY1(t),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markLine: {
                    lineStyle: {
                        color: theme.palette.type === "light" ? "#000000" : "#FFFFFF",
                        type: 'solid'
                    },
                    symbol: ['none', 'none'],
                    data: markdata
                },
            },
            {
                name: av1,
                type: 'line',
                data: getMA(t, 1),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: av2,
                type: 'line',
                data: getMA(t, 2),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: av3,
                type: 'line',
                data: getMA(t, 3),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
            {
                name: '成交量',
                type: 'bar',
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: getYY2(t)
            },
            {
                name: av4,
                type: 'line',
                data: getMA(t, 4),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            }, {
                name: av5,
                type: 'line',
                data: getMA(t, 5),
                smooth: true,
                lineStyle: {
                },
                showSymbol: false,
                animation: false
            },
        ]

        k['series'] = serdata
        setOptions(k)
    }


    const handleTp = (t) => {
        setTp(t)
    }

    useEffect(() => {
        if (props.row.kd3 != null) genoption2()
    }, [props.row.kd3, tp]);

    useEffect(() => {
        if (props.row.kd3 != null) {
            drawLine.current = false
            drawGolden.current = false
            setStartDraw(false)
            lines.current = []
            goldenLines.current = []
            genoption2()
        }
    }, [props.row.kd3]);

    const onRoomClient = (event) => {
        if (event != null && event.batch != null &&
            event.batch.length > 0 && event.batch[0].start != undefined &&
            event.batch[0].end != undefined) {
            zStart.current = event.batch[0].start
            zEnd.current = event.batch[0].end
        } else if (event != null && event.start != undefined &&
            event.end != undefined) {
            zStart.current = event.start
            zEnd.current = event.end
        }
    }

    const onChartLegendselectchanged = (event) => {
        let av1 = localStorage.getItem('av1') === null ? 'MA5' : 'MA' + localStorage.getItem('av1')
        let av2 = localStorage.getItem('av2') === null ? 'MA20' : 'MA' + localStorage.getItem('av2')
        let av3 = localStorage.getItem('av3') === null ? 'MA60' : 'MA' + localStorage.getItem('av3')
        let av4 = localStorage.getItem('av4') === null ? 'MA120' : 'MA' + localStorage.getItem('av4')
        let av5 = localStorage.getItem('av5') === null ? 'MA200' : 'MA' + localStorage.getItem('av5')

        let av1v = event.selected[av1]
        let av2v = event.selected[av2]
        let av3v = event.selected[av3]
        let av4v = event.selected[av4]
        let av5v = event.selected[av5]

        if (av1v != null && av1v) {
            localStorage.setItem('av1i', true)
        } else localStorage.setItem('av1i', false)

        if (av2v != null && av2v) {
            localStorage.setItem('av2i', true)
        } else localStorage.setItem('av2i', false)

        if (av3v != null && av3v) {
            localStorage.setItem('av3i', true)
        } else localStorage.setItem('av3i', false)

        if (av4v != null && av4v) {
            localStorage.setItem('av4i', true)
        } else localStorage.setItem('av4i', false)

        if (av5v != null && av5v) {
            localStorage.setItem('av5i', true)
        } else localStorage.setItem('av5i', false)
    }

    const onEvents = {
        'datazoom': onRoomClient,
        'legendselectchanged': onChartLegendselectchanged
    }

    const getVariant = (t) => {
        if (tp === t) return "contained"
        else return "outlined"
    }


    const chartReady = (event) => {
        let zr = event.getZr()
        zr.on('mousedown', onMouseDownClick)
        zr.on('mouseup', onMouseUpClick)
        zr.on('mousemove', onMouseMoveClick)
    }

    const onMouseDownClick = (...rest) => {
        mouseAction.current = "down"

        if (startDraw && rest != null && rest.length > 0) {
            const newLines = [...lines.current];

            newLines.push(
                [rest[0].offsetX, rest[0].offsetY, rest[0].offsetX, rest[0].offsetY]
            )
            lines.current = newLines
        }
    };

    const onMouseUpClick = (...rest) => {
        mouseAction.current = "up"

        const newLines = [...lines.current];

        if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
            let originX = newLines[newLines.length - 1][0]
            let originY = newLines[newLines.length - 1][1]
            newLines.splice(newLines.length - 1, 1);
            newLines.push(
                [originX, originY,
                    rest[0].offsetX,
                    rest[0].offsetY]
            )
            lines.current = newLines
            genoption2()
        }
    };


    const onMouseMoveClick = (...rest) => {
        if (startDraw && mouseAction.current === "down") {
            const newLines = [...lines.current];

            if (newLines != null && newLines.length > 0 && rest != null && rest.length > 0) {
                let originX = newLines[newLines.length - 1][0]
                let originY = newLines[newLines.length - 1][1]

                // console.log("rest[0].offsetX:" + rest[0].offsetX + "," + rest[0].offsetY)

                newLines.splice(newLines.length - 1, 1)
                newLines.push(
                    [originX, originY,
                        rest[0].offsetX,
                        rest[0].offsetY]
                )
                lines.current = newLines

                if (drawGolden) {
                    let markdata = []
                    if (newLines.length > 0) {
                        newLines.map(l => {
                            if (l != null && l.length == 4) {
                                let height = Math.abs(l[1] - l[3])

                                let y0 = -1
                                let y1 = -1
                                let y2 = -1
                                let y3 = -1
                                let y4 = -1
                                let y5 = -1
                                let y6 = -1

                                let x1 = l[0]
                                let x2 = l[2]

                                if (l[2] > l[0] && l[3] > l[1]) {//左上右下
                                    y0 = l[3]
                                    y1 = l[3] - height * 0.191
                                    y2 = l[3] - height * 0.382
                                    y3 = l[3] - height * 0.5
                                    y4 = l[3] - height * 0.618
                                    y5 = l[3] - height * 0.809
                                    y6 = l[3] - height * 1
                                } else if (l[0] > l[2] && l[1] > l[3]) {//右下往左上
                                    y0 = l[1]
                                    y1 = l[1] - height * 0.191
                                    y2 = l[1] - height * 0.382
                                    y3 = l[1] - height * 0.5
                                    y4 = l[1] - height * 0.618
                                    y5 = l[1] - height * 0.809
                                    y6 = l[1] - height * 1
                                } else if (l[2] > l[0] && l[1] > l[3]) {
                                    y0 = l[3]
                                    y1 = l[3] + height * 0.191
                                    y2 = l[3] + height * 0.382
                                    y3 = l[3] + height * 0.5
                                    y4 = l[3] + height * 0.618
                                    y5 = l[3] + height * 0.809
                                    y6 = l[3] + height * 1
                                } else if (l[0] > l[2] && l[3] > l[1]) {
                                    y0 = l[1]
                                    y1 = l[1] + height * 0.191
                                    y2 = l[1] + height * 0.382
                                    y3 = l[1] + height * 0.5
                                    y4 = l[1] + height * 0.618
                                    y5 = l[1] + height * 0.809
                                    y6 = l[1] + height * 1
                                }



                                if (y0 != -1 && y1 != -1) {
                                    markdata.push(
                                        [x1, y0, x2, y0, 0]
                                    )
                                    markdata.push(
                                        [x1, y1, x2, y1, 0.191]
                                    )
                                    markdata.push(
                                        [x1, y2, x2, y2, 0.382]
                                    )
                                    markdata.push(
                                        [x1, y3, x2, y3, 0.5]
                                    )
                                    markdata.push(
                                        [x1, y4, x2, y4, 0.618]
                                    )
                                    markdata.push(
                                        [x1, y5, x2, y5, 0.809]
                                    )

                                    markdata.push(
                                        [x1, y6, x2, y6, 1]
                                    )
                                }

                            }
                        })

                        goldenLines.current = markdata
                    }
                }



                genoption2()
            }
        }
    };

    const clickDraw = () => {
        clearDraw()

        if (drawGolden.current && startDraw) {
            drawGolden.current = false
        }

        if (drawLine.current && startDraw) {
            drawLine.current = false
            setStartDraw(false)

        } else {
            drawLine.current = true
            setStartDraw(true)
        }


    }


    const clickDrawGolden = () => {
        clearDraw()

        if (drawLine.current && startDraw) {
            drawLine.current = false
        }

        if (startDraw && drawGolden.current) {
            drawGolden.current = false
            setStartDraw(false)
        } else {
            drawGolden.current = true
            setStartDraw(true)
        }


    }

    const clearDraw = () => {
        lines.current = []
        goldenLines.current = []
        genoption2()
    }

    const isBuy = () => {
        return localStorage.getItem("noad") === "1"
    }

    return (
        <div>
            <FinRateList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></FinRateList>
            <AVSetting open={open1} handleClose={handleClose} handleOk={handleOk}></AVSetting>

            <Grid container spacing={0}>

                <Grid item xs={12} sm={12} >
                    <ButtonGroup color="primary" size="small" disableElevation >
                        <Button variant={getVariant(0)} onClick={(e) => handleTp(0)}>日K</Button>
                        <Button variant={getVariant(1)} onClick={(e) => handleTp(1)}>周K</Button>
                        <Button variant={getVariant(2)} onClick={(e) => handleTp(2)}>月K</Button>
                        <Button onClick={(e) => handleOpen()}>均線</Button>
                        <Button disabled={!isBuy()} variant={drawLine.current ? "contained" : "outlined"} onClick={(e) => clickDraw()}>畫線</Button>
                        <Button disabled={!isBuy()} variant={drawGolden.current ? "contained" : "outlined"} onClick={(e) => clickDrawGolden()}>黃金</Button>
                        <Button disabled={!isBuy()} variant="outlined" onClick={(e) => clearDraw()}>清除</Button>
                    </ButtonGroup>

                    {options != null && !startDraw ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 450, marginTop: 5 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        onEvents={onEvents}
                    /> : null}

                    {options != null && startDraw ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 450, marginTop: 5 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                        onChartReady={chartReady}
                    /> : null}

                </Grid>

                {!startDraw ? <Grid item xs={12} sm={6} style={{padding: 10}}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto' }}>
                        <Button variant="outlined" style={{ whiteSpace: 'nowrap', marginLeft: 2 }} color="primary" size="small" onClick={() => {
                            window.open('https://www.cmoney.tw/forum/stock/' + props.row.market_company.stock_no.toUpperCase() + '#price', '股市同學會')
                        }}>股市同學會</Button>
                        <Button variant="outlined" style={{ whiteSpace: 'nowrap', marginLeft: 2 }} color="primary" size="small" onClick={() => {
                            window.open('https://statementdog.com/analysis/' + props.row.market_company.stock_no + '#price', '財報狗')
                        }}>財報狗</Button>
                        <Button variant="outlined" style={{ whiteSpace: 'nowrap', marginLeft: 2 }} color="primary" size="small" onClick={() => {
                            window.open('https://tw.stock.yahoo.com/q/bc?s=' + props.row.market_company.stock_no, 'Yahoo')
                        }}>Yahoo</Button>
                        <Button variant="outlined" style={{ whiteSpace: 'nowrap', marginLeft: 2 }} color="primary" size="small" onClick={() => {
                            window.open('https://histock.tw/stock/' + props.row.market_company.stock_no + '#price', 'Histock')
                        }}>Histock</Button>
                        <Button variant="outlined" style={{ whiteSpace: 'nowrap', marginLeft: 2 }} color="primary" size="small" onClick={() => {
                            window.open('https://www.wantgoo.com/stock/' + props.row.market_company.stock_no + '?price', '玩股網')
                        }}>玩股網</Button>
                        <Button variant="outlined" style={{ whiteSpace: 'nowrap', marginLeft: 2 }} color="primary" size="small" onClick={() => {
                            window.open('https://pchome.megatime.com.tw/stock/sid' + props.row.market_company.stock_no + '.html?price', 'PCHome')
                        }}>PCHome</Button>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ whiteSpace: "nowrap" }}>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'上市櫃'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'上市櫃'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {getTp(props.row.market_company.tp)}
                                    </StyledTableCell>
                                </StyledTableRow >


                                <StyledTableRow key={'產業'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'產業'}
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        {props.row.market_company.inductry}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股本'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股本(億)'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.market_company.normal_stock != "" ? (props.row.market_company.normal_stock / 100000000).toFixed(2) : '無'}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'更新日期'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'更新日期'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div>{props.row.daily_stock.d.substr(4, 4)} {props.row.daily_stock.t}</div>
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'收盤價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'收盤價'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ color: pricecolor(props.row.fin.price.incv) }}>{props.row.fin.price.last_value}</div>
                                    </StyledTableCell>
                                </StyledTableRow >



                                <StyledTableRow key={'漲跌'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'漲跌'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ color: pricecolor(props.row.fin.price.incv) }}>{props.row.fin.price.incv}</div>
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'本益比'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'本益比'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.fin.price.per}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'股價淨值比'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股價淨值比'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <div style={{ color: pbcolor(props.row.fin.price.pb) }}>{props.row.fin.price.pb}</div>
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近一年EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近一年EPS'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {rendereps(props.row.daily_stock.ty, props.row.daily_stock.teps)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近一季EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近一季EPS'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {rendereps2(props.row.daily_stock.ls, props.row.daily_stock.seps)}
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'近四季EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近四季EPS'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {rendereps1(props.row.daily_stock.eps)}
                                    </StyledTableCell>
                                </StyledTableRow >


                                <StyledTableRow key={'最近一次董事會'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最近一次董事會'}
                                    </StyledTableCell>
                                    {rendermeetingdate(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'現金殖利率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最新公告現金殖利率'}
                                    </StyledTableCell>
                                    {renderyield(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'盈餘分配率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最新公告盈餘分配率'}
                                    </StyledTableCell>
                                    {renderRate(props.row)}
                                </StyledTableRow >


                                <StyledTableRow key={'毛利率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'毛利率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.fin.rate != undefined ? props.row.fin.rate.gp : '-- '}%
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'營業收益率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營業收益率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.fin.rate != undefined ? props.row.fin.rate.oi : '-- '}%
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'稅前純益率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'稅前純益率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.fin.rate != undefined ? props.row.fin.rate.txb : '-- '}%
                                    </StyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'稅後純益率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'稅後純益率'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {props.row.fin.rate != undefined ? props.row.fin.rate.txa : '-- '}%
                                    </StyledTableCell>
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> : null}


                {!startDraw ? <Grid item xs={12} sm={6} style={{padding: 10}}>
                    <div style={{ display: 'flex'}}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            財務比率
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ whiteSpace: "nowrap" }}>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'資產報酬率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'資產報酬率'}
                                    </StyledTableCell>

                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.roa, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >


                                <StyledTableRow key={'股東權益報酬率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'股東權益報酬率'}
                                    </StyledTableCell>

                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.roe, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >

                                <StyledTableRow key={'營運資金銷售率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'營運資金銷售率'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.cv, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >

                                <StyledTableRow key={'總資產周轉率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'總資產周轉率'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.tst, '次', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >

                                <StyledTableRow key={'應收帳款天數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'應收帳款天數'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.revd, '天', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >


                                <StyledTableRow key={'庫存天數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'庫存天數'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.invd, '天', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >


                                <StyledTableRow key={'利息保障倍數'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'利息保障倍數'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.ib, '倍', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >


                                <StyledTableRow key={'流動比率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'流動比率'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.cr, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >

                                <StyledTableRow key={'速動比率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'速動比率'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.qr, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >

                                <StyledTableRow key={'負債佔資產比率'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'負債佔資產比率'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.dea, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >

                                <StyledTableRow key={'合約負債'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'合約負債'}
                                    </StyledTableCell>
                                    {props.row.rate != null && props.row.rate != undefined ? <StyledTableCell align="left">
                                        {renderValue0(props.row.rate.cl, '%', props.row.market_company.stock_no, props.row.market_company.short_name)}
                                    </StyledTableCell> : null}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> : null}

            </Grid>
        </div >
    );
}