import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    SVGRenderer,
} from 'echarts/renderers';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'

echarts.use(
    [AxisPointerComponent, SVGRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));

// (p.o, p.h, p.l, p.p)) convert to //open，close，lowest，highest



export default function NineChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)


    function getAnnoValue(xvalue, yvalue, title, color) {
        let offsetX = 0
        if (title === "7") offsetX = -5
        else if (title === "9") offsetX = 25

        let word = title
        if (title === "9") word = "9 轉折"

        return {
            symbol: 'rect',
            symbolSize: 2,
            coord: [xvalue, yvalue],
            value: word,
            label: {
                show: true,
                backgroundColor: color,
                padding: 3,
                offset: [offsetX, -15],
            }
        }
    }

    function genData1() {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '九轉序列圖',
                    top: '10',
                },
            ],
            grid: [
                {
                    top: '80',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
            ]
        };


        let x1 = {
            type: 'category',
            data: props.row.chart11.d.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
        }

        option['xAxis'] = x1

        let yax = []
        if (props.row.nine != undefined) {
            props.row.nine.u.map(r => {
                yax.push(getAnnoValue(r.x, r.y, r.z, "#FF4560"))
            })
            props.row.nine.d.map(r => {
                yax.push(getAnnoValue(r.x, r.y, r.z, "#16a850"))
            })
        }

        option['series'] = [
            {
                name: '日K線',
                type: 'candlestick',
                data: props.row.chart11.d.map(vv => {
                    return [vv.y[0], vv.y[3], vv.y[2], vv.y[1]]
                }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
                markPoint: {
                    data: yax
                },
            },
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(0, 100)
        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
        }

        return option
    }



    useEffect(() => {
        if (props.row.chart11 != null) setOptions(genData1())
    }, [props.row]);

    function getString() {
        return "目前無相關的九轉序列圖"
    }

    return (
        <div>
            {localStorage.getItem('noad') != null && localStorage.getItem('noad') === "1" ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ fontSize: 14, color: "#808080" }}>九轉序列是根據TD馬克序列（Tom Demark Indicators）的思想產生的，即連續9天收盤價高於（低於）前4天的收盤價，其後走勢很可能發生轉向。搭配其他技術分析可以提高逃頂或抄底的成功率</div>
                    {options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 430, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>

            </Grid> : <div>目前九轉序列圖僅開放給訂閱者。</div>}
        </div >
    );
}