import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      // margin: theme.spacing(1),
    },
    marginTop:3
  },
}));

export default function ComputerOrMobile() {
  const classes = useStyles();
  
  function changeScreen(tp) {
    localStorage.setItem("run_computer", tp)
    window.location.reload(false);
}

  return ( 
    <div className={classes.root}>
      {/* <ButtonGroup variant="text" aria-label="text primary button group">
        <Button size="small" onClick={(e) => changeScreen("0")}>手機版</Button>
        <Button size="small" onClick={(e) => changeScreen("1")}>電腦版</Button>
      </ButtonGroup> */}
    </div>
  );
}
