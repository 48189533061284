import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TrendingUp from '@material-ui/icons/TrendingUp';
import ClearAll from '@material-ui/icons/ClearAll';
import Gesture from '@material-ui/icons/Gesture';
import Send from '@material-ui/icons/Send'
import LinkIcon from '@material-ui/icons/Link'
import Eject from '@material-ui/icons/Eject'
import Button from '@material-ui/core/Button';
import DataUsage from '@material-ui/icons/DataUsage'
import LocalDining from '@material-ui/icons/LocalDining'
import Keyboard from '@material-ui/icons/Keyboard'
import Voicemail from '@material-ui/icons/Voicemail'

import BPage from '../BPage'
import Rule675 from '../rules/Rule675';
import Rule676 from '../rules/Rule676';
import RuleTp4 from '../ruletp/RuleTp4';

import { BASIC_1, BASIC_2, BASIC_3, BASIC_4, BASIC_5, BASIC_6, BASIC_7, 
    BASIC_8, BASIC_9, BASIC_10 } from '../ruletp/RuleData'
import {
    BASIC_1_IDS, BASIC_2_IDS, BASIC_3_IDS, BASIC_4_IDS, BASIC_5_IDS, BASIC_6_IDS,
    BASIC_7_IDS, BASIC_8_IDS, BASIC_9_IDS, BASIC_10_IDS
} from '../ruletp/RuleData'

import { enableColor, disableColor } from '../util/Expansion'

import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';



const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
    },
    panelTitle: {
        marginLeft: 10,
    },
    panelTitle1: {
        marginLeft: 10,
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
})


class NewBasicboard extends React.Component {
    state = {
        expanded: 'panelx',
    };

    handleOpen1 = () => {
        this.props.tabchange(BPage.donate_page)
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };


    render() {
        const { expanded } = this.state;
        const { classes } = this.props;

        const isBASICOn1 = this.props.rules.some(rule => BASIC_1_IDS.includes(rule.id));
        const isBASICOn2 = this.props.rules.some(rule => BASIC_2_IDS.includes(rule.id));
        const isBASICOn3 = this.props.rules.some(rule => BASIC_3_IDS.includes(rule.id));
        const isBASICOn4 = this.props.rules.some(rule => BASIC_4_IDS.includes(rule.id));
        const isBASICOn5 = this.props.rules.some(rule => BASIC_5_IDS.includes(rule.id));
        const isBASICOn6 = this.props.rules.some(rule => BASIC_6_IDS.includes(rule.id));
        const isBASICOn7 = this.props.rules.some(rule => BASIC_7_IDS.includes(rule.id));
        const isBASICOn8 = this.props.rules.some(rule => BASIC_8_IDS.includes(rule.id));
        const isBASICOn9 = this.props.rules.some(rule => BASIC_9_IDS.includes(rule.id));
        const isBASICOn10 = this.props.rules.some(rule => BASIC_10_IDS.includes(rule.id));

        return (
            <div className={classes.root}>
                <div>
                    {this.props.tabchange != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.handleOpen1()}>
                            訂閱
                        </Button> : null
                    }

                    {this.props.saveRule1 != undefined ?
                        <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.saveRule1()}>
                            儲存規則
                        </Button> : null
                    }


                    <Button variant="outlined" size="small" color="secondary" className={classes.button} onClick={(e) => this.props.clearRule()}>
                        清除規則
                    </Button>

                </div>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn1'}
                    onChange={this.handleChange('BASICOn1')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn1 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <TrendingUp color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>營收</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_1.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn10'}
                    onChange={this.handleChange('BASICOn10')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn10 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Voicemail color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>EPS</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_10.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn2'}
                    onChange={this.handleChange('BASICOn2')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn2 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <ClearAll color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>股利、股本、殖利率</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_2.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn3'}
                    onChange={this.handleChange('BASICOn3')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn3 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Gesture color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>本益比、毛利率、類別</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_3.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn4'}
                    onChange={this.handleChange('BASICOn4')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn4 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Send color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>股東權益報酬率、資產報酬率、負債比率、本業收入、利息保障</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_4.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn5'}
                    onChange={this.handleChange('BASICOn5')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn5 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Keyboard color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>財報</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_5.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn6'}
                    onChange={this.handleChange('BASICOn6')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn6 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <LinkIcon color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>營業利率、稅後淨利率、股價淨值比</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_6.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn7'}
                    onChange={this.handleChange('BASICOn7')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn7 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <Eject color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>流動比率、速動比率、價值估算、合約負債</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_7.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn8'}
                    onChange={this.handleChange('BASICOn8')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn8 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <DataUsage color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>可轉換公司債、波動率、Beta</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_8.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === 'BASICOn9'}
                    onChange={this.handleChange('BASICOn9')}
                >
                    <ExpansionPanelSummary style={{
                        backgroundColor: isBASICOn9 ? enableColor : disableColor,
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderColor: "#e0e0e0",
                    }}>
                        <LocalDining color="secondary" style={{ fontSize: 30 }} />
                        <Typography className={classes.panelTitle}>ETF、自選股、處置、期貨</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{ padding: 0, display: 'block' }}>
                        {BASIC_9.map(row => {
                            return <RuleTp4 key={row.id} row={row} ruleChange={this.props.updateRule} rules={this.props.rules} />
                        })}


                        <Rule675 ruleChange={this.props.updateRule} rules={this.props.rules} />
                        <Rule676 ruleChange={this.props.updateRule} rules={this.props.rules} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

export default withStyles(styles)(NewBasicboard);