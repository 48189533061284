import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PressureIndexSetting(props) {
    //   const [open, setOpen] = React.useState(false);

    const yref = React.createRef();


    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('pressa', parseInt(yref.current.value));

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('pressa') === null ? 21 : localStorage.getItem('pressa')
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">參數設定</DialogTitle>

            <DialogContent>
                <br /><br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="天數(預設:21)"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}
