import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import BTurningSetting from './BTurningSetting';
import Button from '@material-ui/core/Button'

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",      
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function BTurning(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        runFectchAPII()
        setOpen(false);
    };


    function getAvgString() {
        if (localStorage.getItem('turng') != null && localStorage.getItem('turng') != 5)
            return localStorage.getItem('turng') + "日均"
        else return "5日均"
    }

    function genData(data) {
        let option = {
            title: [
                {
                    left: 'center',
                    text: '轉折波與趨勢線',
                    top: '10',
                },
                {
                    left: 'center',
                    text: '轉折波',
                    top: '420',
                }
            ],
            legend: {
                top: 45,
                data: [getAvgString(), '轉折波', '趨勢線']
            },
            grid: [
                {
                    top: '80',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                },
                {
                    top: '470',
                    left: '50',
                    right: '30',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                },
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                    gridIndex: 1,
                }
            ]
        };


        let x1 = {
            type: 'category',
            data: data.map(v => { return v.x })
        }

        let x2 = {
            gridIndex: 1,
            type: 'category',
            data: data.map(v => { return v.x })
        }

        if (props.ismobile) {
            let xp = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = xp
            x2['axisPointer'] = xp
        }

        option['xAxis'] = [x1, x2]

        if (data != null) {
            let trandata = []
            data.map(tt => {
                trandata.push({
                    name: tt.x,
                    coord: [tt.x, tt.t],
                    value: tt.m,
                    itemStyle: {
                        color: getMarkColorLabel(tt.m)
                    },
                    symbol: 'rect',
                    symbolSize: [30, 30],
                    // symbolOffset: [10, 0]
                })
            })


            option['series'] = [
                {
                    name: '日K線',
                    type: 'candlestick',
                    data: data.map(v => { return v.k }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                },
                {
                    name: getAvgString(),
                    type: 'line',
                    connectNulls: true,
                    // color: "#ff7171",
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.avg }),
                    symbol: 'none',
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 1
                    }
                },
                {
                    name: '轉折波',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.t }),
                    symbol: 'circle',
                    // symbolSize: 4,
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 3
                    }
                },
                {
                    name: '趨勢線',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.trend }),
                    symbol: 'circle',
                    // symbolSize: 4,
                    lineStyle: {
                        width: 3
                    }
                },
                {
                    name: '日K',
                    type: 'candlestick',
                    data: data.map(v => { return v.k }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    xAxisIndex: 1,
                    yAxisIndex: 1
                },
                {
                    name: '轉折波',
                    type: 'line',
                    connectNulls: true,
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.map(v => { return v.t }),
                    symbol: 'circle',
                    label: {
                        formatter: function (param) {
                            return param != null ? getMarkLabel(param) : ''; //Math.round(param.value)
                        }
                    },
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    lineStyle: {
                        width: 3
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    markPoint: {
                        label: {
                            fontSize: 14,
                            normal: {
                                formatter: function (param) {
                                    return param != null ? getMarkLabel(param) : ''; //Math.round(param.value)
                                }
                            },
                        },
                        data: trandata,
                    }

                },

            ]
        }

        if (props.ismobile) {
            option['tooltip'] = {
                triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['axisPointer'] = {
                // link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 90,
                    end: 100
                }
            ]
        } else {
            option['axisPointer'] = {
                // link: { xAxisIndex: 'all' },
                label: {
                    backgroundColor: '#777'
                }
            }

            option['tooltip'] = {
                // triggerOn: 'none',
                transitionDuration: 0,
                confine: true,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                },
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                position: function (pos, params, el, elRect, size) {
                    var obj = { top: pos[1] - 100 };
                    obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                    return obj;
                },
                extraCssText: 'width: 170px',
                bordeRadius: 4,
                borderWidth: 1,
                borderColor: '#333',
            }

            option['dataZoom'] = [
                {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 90,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: [0, 1],
                    type: 'slider',
                    top: '820',
                    start: 90,
                    end: 100
                }
            ]
        }

        return option
    }

    function getMarkLabel(param) {
        if (param.value === 0) {
            return '多'// + param.value
        } else if (param.value === 1) {
            return '空'// + param.value
        } else return '盤'//param.value
    }

    function getMarkColorLabel(value) {
        if (value === 0) {
            return '#ffa11c'// + param.value
        } else if (value === 1) {
            return '#1a9fad'// + param.value
        } else return '#d9d9d9'//param.value
    }


    const runFectchAPII = () => {
        let a = {
            inp: {}
        };

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')
        if (localStorage.getItem('turng') != null && localStorage.getItem('turng') != 5)
            a.inp["turng"] = parseInt(localStorage.getItem('turng'))


        fetch("/jstock/v1/stock/bigtable5", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.r != null && result.r.length > 0)
                        setOptions(genData(result.r))
                }
            );
    }



    useEffect(() => {
        if (props.value === 6) runFectchAPII();
    }, [props.value]);


    function getString() {
        return "目前無相關的轉折波與趨勢線"
    }

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <BTurningSetting open={open1} handleClose={handleClose} handleOk={handleOk}></BTurningSetting>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            集中市場<Button style={{ marginLeft: 2 }} size="small" color="primary" variant="outlined" onClick={(e) => handleOpen()}>設定</Button>
                        </Typography>
                    </div>


                    {options != null ? <ReactEChartsCore
                        theme={theme.palette.type}
                        style={{ height: 950, marginTop: 0 }}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={false}
                    /> : <div style={{  }}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}