import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import StyledTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BColor from '../BColor'
import InterestAllDetail from './InterestAllDetail'

const StyledStyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(StyledTableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function InterestDetail(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const renderInpriceStatus = (value) => {
        if (value === -99999) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (value === 0) {
            return <Button style={{ background: BColor.downcolorbox, minWidth: 110, color: '#FFFFFF' }} className={classes.button1}>零分 {value}%</Button>
        } else if (value > 0 && value < 60) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.downcolorbox }} className={classes.button1}>偏低 {value}%</Button>
        } else if (value >= 60 && value < 80) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >尚可 {value}%</Button>
        } else if (value >= 80 && value < 100) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button1} >優質 {value}%</Button>
        } else if (value === 100) {
            return <Button style={{ background: BColor.upcolorbox, minWidth: 110, color: '#FFFFFF' }} className={classes.button1} >滿分 {value}%</Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    const renderDay = (value) => {
        if (value === 99999) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (value >= 120) {
            return <Button style={{ background: BColor.downcolorbox, minWidth: 110, color: '#FFFFFF' }} className={classes.button1}>超級慢 {value}天</Button>
        } else if (value >= 60 && value < 120) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.downcolorbox }} className={classes.button1}>有點慢 {value}天</Button>
        } else if (value >= 20 && value < 60) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1} >尚可 {value}天</Button>
        } else if (value >= 5 && value < 20) {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button1} >有點快 {value}天</Button>
        } else if (value < 5) {
            return <Button style={{ background: BColor.upcolorbox, minWidth: 110, color: '#FFFFFF' }} className={classes.button1} >超級快 {value}天</Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }


    function renderEmpty(row, index) {
        if (row.ii == undefined || row.ii.a.length <= index)
            return <StyledTableCell colSpan="3" align="right">
                <Button variant="outlined" style={{ width: '100%', color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無資料</Button>

            </StyledTableCell>
    }



    function rendermeetingdate(row) {
        if (row.ii != undefined && row.ii.mi != null && row.ii.mi.ymd != "")
            return <StyledTableCell align="left">
                {row.ii.mi.ymd}
            </StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無董事會</Button>
        </StyledTableCell>
    }

    function rendermeetingdate1(row) {
        if (row.ii != undefined && row.ii.mi != null && row.ii.mi.ymd1 != null && row.ii.mi.ymd1 != "")
            return <StyledTableCell align="left">
                {row.ii.mi.ymd1}
            </StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無股東會</Button>
        </StyledTableCell>
    }

    function rendermeetingname(row) {
        if (row.ii != undefined && row.ii.mi != null)
            return <StyledTableCell align="left">
                {row.ii.mi.name}
            </StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無公告</Button>
        </StyledTableCell>
    }

    function rendermeetingcash(row) {
        if (row.ii != undefined && row.ii.mi != null)
            return <StyledTableCell align="left">
                <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', whiteSpace: "nowrap" }} >現:{row.ii.mi.cash.toFixed(2)}元/股:{row.ii.mi.stock.toFixed(2)}元</div>
            </StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >近期無公告</Button>
        </StyledTableCell>
    }

    function renderyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = (row.ii.mi.cash * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            }
        } else {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </StyledTableCell>
        }
    }

    function renderStockyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = (row.ii.mi.stock * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            }
        } else {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </StyledTableCell>
        }
    }

    function renderAllyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = ((row.ii.mi.cash + row.ii.mi.stock) * 100 / row.fin.price.last_value).toFixed(2)
            if (yieldrate < 6) {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            }
        } else {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </StyledTableCell>
        }
    }


    function renderBackyield(row) {
        if (row.ii != undefined && row.ii.mi != null && row.fin != null && row.fin.price != null) {
            let yieldrate = ((row.ii.mi.cash * 100) / row.fin.price.last_value + (row.ii.mi.stock * 10)).toFixed(2)

            if (yieldrate < 6) {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{yieldrate}%</Button>
                </StyledTableCell>
            }
        } else {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
            </StyledTableCell>
        }
    }

    function renderIntCell3(row, index, hasEmpty) {
        if (row.ii.a.length > index)
            return <StyledTableCell align="left">
                {row.ii.a[index].ints.map(function (item, aindex, array) {
                    let spc = array.length > 1 ? 8 : 5
                    let a = ""
                    if (item.cash > 0 && item.stock > 0) a = "現:" + item.cash.toFixed(2) + "元/股:" + item.stock.toFixed(2) + "元"
                    else if (item.cash > 0) a = "現金:" + item.cash.toFixed(2) + "元"
                    else if (item.stock > 0) a = "股票:" + item.stock.toFixed(2) + "元"

                    let b = ""
                    if (item.croi != null) b = "現金殖利率:" + item.croi + "%"
                    return <div key={Math.random()} style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: spc, marginBottom: spc, fontSize: 16 }} >{item.ymd} <br />{a}<br />{b}</div>
                    //   return renderCashStock(item.cash, item.stock);
                })
                }
            </StyledTableCell>
    }

    function renderIntCell4(row, index, hasEmpty) {
        if (row.ii.a.length > index)
            return <StyledTableCell align="left">
                {row.ii.a[index].ints.map(function (item, aindex, array) {
                    let spc = array.length > 1 ? 2 : 0
                    if (item.fill === 0) {
                        return <div key={Math.random()} ><Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: spc }} className={classes.button1} >已完成 {item.day}天</Button></div>
                        // return <div style={{ color: BColor.faircolor, borderColor: '#FFFFFF', marginTop: 5, marginBottom: 5}}>{item.day}</div>;
                    } else if (item.fill === 1) {
                        return <div key={Math.random()} ><Button variant="outlined" style={{ minWidth: 110, color: '#469447', marginBottom: spc }} className={classes.button1}>未完成 {item.day}天</Button></div>
                    } else if (item.fill === 2) {
                        return <div key={Math.random()}><Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: spc }} className={classes.button1} >即將開始</Button></div>
                    } else if (item.fill === 3) {
                        return <div key={Math.random()}><Button variant="outlined" style={{ minWidth: 110, color: BColor.nodatacolor, marginBottom: spc }} className={classes.button1} >-</Button></div>
                    }
                })
                }
            </StyledTableCell>
    }


    function renderRate(row) {
        if (row.ii != undefined && row.ii.mi != null) {
            let rate = row.ii.mi.rate
            let ratetp = row.ii.mi.ratetp

            if (ratetp === 0) {
                if (rate < 80) {
                    return <StyledTableCell style={{}} size="small" align="left">
                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >{rate}%</Button>
                    </StyledTableCell>
                } else {
                    return <StyledTableCell style={{}} size="small" align="left">
                        <Button variant="outlined" style={{ minWidth: 110, color: BColor.upcolorbox, marginBottom: 0 }} className={classes.button1} >{rate}%</Button>
                    </StyledTableCell>
                }
            } else if (ratetp === 1) {
                return <StyledTableCell style={{}} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >當期無盈餘</Button>
                </StyledTableCell>
            } else {
                return <StyledTableCell style={{}} size="small" align="left">
                    <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor, marginBottom: 0 }} className={classes.button1} >-</Button>
                </StyledTableCell>
            }
        }
    }

    return (
        <div>
            {open && <InterestAllDetail row={props.row} setOpen={setOpen} open={open} />}
            {props.row.ii != null && props.row.ii != undefined ? <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            除權息統計表
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledStyledTableCell>名稱</StyledStyledTableCell>
                                    <StyledStyledTableCell align="left">資料</StyledStyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'近9年股息發放'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'近9年股息發放'}
                                    </StyledStyledTableCell>

                                    <StyledStyledTableCell align="left">
                                        {renderInpriceStatus(props.row.ii.psend)}
                                    </StyledStyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'填息/權率'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'填息/權率'}
                                    </StyledStyledTableCell>
                                    <StyledStyledTableCell align="left">
                                        {renderInpriceStatus(props.row.ii.tprate)}
                                    </StyledStyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'填息/權所需天數'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'填息/權所需天數'}
                                    </StyledStyledTableCell>
                                    <StyledStyledTableCell align="left">
                                        {renderDay(props.row.ii.pdate)}
                                    </StyledStyledTableCell>
                                </StyledTableRow >

                                <StyledTableRow key={'最近一次董事會'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'最近一次董事會'}
                                    </StyledStyledTableCell>
                                    {rendermeetingdate(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'最近一次股東會'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'最近一次股東會'}
                                    </StyledStyledTableCell>
                                    {rendermeetingdate1(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'最近一次公告會議'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'最近一次公告會議'}
                                    </StyledStyledTableCell>
                                    {rendermeetingname(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'最近一次公告股利'}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'最近一次公告股利'}
                                    </StyledStyledTableCell>
                                    {rendermeetingcash(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'現金殖利率'} onClick={e => setOpen(true)}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'現金殖利率'}
                                    </StyledStyledTableCell>
                                    {renderyield(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'股票殖利率'}  onClick={e => setOpen(true)}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'股票殖利率'}
                                    </StyledStyledTableCell>
                                    {renderStockyield(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'會計殖利率'}  onClick={e => setOpen(true)}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'會計殖利率'}
                                    </StyledStyledTableCell>
                                    {renderAllyield(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'還原殖利率'}  onClick={e => setOpen(true)}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'還原殖利率'}
                                    </StyledStyledTableCell>
                                    {renderBackyield(props.row)}
                                </StyledTableRow >

                                <StyledTableRow key={'盈餘分配率'}  onClick={e => setOpen(true)}>
                                    <StyledStyledTableCell component="th" scope="row">
                                        {'盈餘分配率'}
                                    </StyledStyledTableCell>
                                    {renderRate(props.row)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            最近五次除權息
                        </Typography>

                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    {/* <StyledStyledTableCell>除權息日</StyledStyledTableCell> */}
                                    <StyledStyledTableCell align="left">股利</StyledStyledTableCell>
                                    <StyledStyledTableCell align="left">填權息狀態</StyledStyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'最近1次除權息'}>
                                    {/* {renderIntCell2(props.row, 0, false)} */}
                                    {renderIntCell3(props.row, 0, false)}
                                    {renderIntCell4(props.row, 0, false)}
                                    {renderEmpty(props.row, 0)}
                                </StyledTableRow >

                                <StyledTableRow key={'最近2次除權息'}>
                                    {/* {renderIntCell2(props.row, 1, false)} */}
                                    {renderIntCell3(props.row, 1, false)}
                                    {renderIntCell4(props.row, 1, false)}
                                    {renderEmpty(props.row, 1)}
                                </StyledTableRow >


                                <StyledTableRow key={'最近3次除權息'}>
                                    {/* {renderIntCell2(props.row, 2, false)} */}
                                    {renderIntCell3(props.row, 2, false)}
                                    {renderIntCell4(props.row, 2, false)}
                                    {renderEmpty(props.row, 2)}
                                </StyledTableRow >

                                <StyledTableRow key={'最近4次除權息'}>
                                    {/* {renderIntCell2(props.row, 3, false)} */}
                                    {renderIntCell3(props.row, 3, false)}
                                    {renderIntCell4(props.row, 3, false)}
                                    {renderEmpty(props.row, 3)}
                                </StyledTableRow >

                                <StyledTableRow key={'最近5次除權息'}>
                                    {/* {renderIntCell2(props.row, 4, false)} */}
                                    {renderIntCell3(props.row, 4, false)}
                                    {renderIntCell4(props.row, 4, false)}
                                    {renderEmpty(props.row, 4)}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid> : <Typography className={classes.title} variant="h6" id="tableTitle">
                近期沒有除權息的資料
            </Typography>}
        </div >
    );
}