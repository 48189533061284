import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function EMASetting(props) {
    const yref = React.createRef();
    const dref = React.createRef();

    const handleOk = () => {
        if (yref.current.value != null && yref.current.value != "") {
            localStorage.setItem('ema1', yref.current.value);
            
            if(yref.current.value === 'EMA') localStorage.setItem('ema', 1);
            else localStorage.setItem('ema', 0)
        }

        if (dref.current.value != null && dref.current.value != "") {
            localStorage.setItem('ema2', dref.current.value);
            
            if(dref.current.value === '1/n') localStorage.setItem('ematp', 1);
            else localStorage.setItem('ematp', 0)
        }

        props.handleOk()
    };


    function yy() {
        return localStorage.getItem('ema1') === null ? 'SMA' : localStorage.getItem('ema1')
    }

    function dd() {
        return localStorage.getItem('ema2') === null ? '2/(n+1)' : localStorage.getItem('ema2')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">均線參數</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="yy"
                    label="預設:SMA，可輸入EMA"
                    type="text"
                    fullWidth
                    defaultValue={yy()}
                    inputRef={yref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="dd"
                    label="EMA計算方式，預設:2/(n+1)，可輸入:1/n"
                    type="text"
                    fullWidth
                    defaultValue={dd()}
                    inputRef={dref}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </DialogContent>
            {localStorage.getItem("noad") === "1" ? <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions> : <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        關閉（訂閱專屬功能）
          </Button>
                </DialogActions>}
        </Dialog>
    );
}