import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function BigSmallSetting(props) {
    //   const [open, setOpen] = React.useState(false);
    const [age, setAge] = React.useState(yy());
    const [age1, setAge1] = React.useState(gg());
    
    const handleChange = (event) => {
        setAge(event.target.value);
      };

      const handleChange1 = (event) => {
        setAge1(event.target.value);
      };

    // const yref = React.createRef();
    // const gref = React.createRef();

    const handleOk = () => {
        // if (yref.current.value != null && yref.current.value != "")
            localStorage.setItem('lms', parseInt(age));

        // if (gref.current.value != null && gref.current.value != "")
            localStorage.setItem('sms', parseInt(age1));

        props.handleOk()
    };

    function yy() {
        return localStorage.getItem('lms') === null ? 12 : localStorage.getItem('lms')
    }

    function gg() {
        return localStorage.getItem('sms') === null ? 8 : localStorage.getItem('sms')
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">大戶/散戶設定</DialogTitle>

            <DialogContent>
                <div style={{ fontSize: 12, color: '#b3b3b3' }}>
                    大戶預設為擁有400張股票以上的股東，散戶預設為擁有50張股票以下的股東。
                </div>
                <br />

                大戶股票張數
                <Select
                fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    onChange={handleChange}
                >
                    <MenuItem value={2}>1張以上</MenuItem>
                    <MenuItem value={3}>5張以上</MenuItem>
                    <MenuItem value={4}>10張以上</MenuItem>
                    <MenuItem value={5}>15張以上</MenuItem>
                    <MenuItem value={6}>20張以上</MenuItem>
                    <MenuItem value={7}>30張以上</MenuItem>
                    <MenuItem value={8}>40張以上</MenuItem>
                    <MenuItem value={9}>50張以上</MenuItem>
                    <MenuItem value={10}>100張以上</MenuItem>
                    <MenuItem value={11}>200張以上</MenuItem>
                    <MenuItem value={12}>400張以上</MenuItem>
                    <MenuItem value={13}>600張以上</MenuItem>
                    <MenuItem value={14}>800張以上</MenuItem>
                    <MenuItem value={15}>1000張以上</MenuItem>
                </Select>

                <br /><br />
                散戶股票張數
                <Select
                fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age1}
                    onChange={handleChange1}
                >
                    <MenuItem value={1}>1張以下</MenuItem>
                    <MenuItem value={2}>5張以下</MenuItem>
                    <MenuItem value={3}>10張以下</MenuItem>
                    <MenuItem value={4}>15張以下</MenuItem>
                    <MenuItem value={5}>20張以下</MenuItem>
                    <MenuItem value={6}>30張以下</MenuItem>
                    <MenuItem value={7}>40張以下</MenuItem>
                    <MenuItem value={8}>50張以下</MenuItem>
                    <MenuItem value={9}>100張以下</MenuItem>
                    <MenuItem value={10}>200張以下</MenuItem>
                    <MenuItem value={11}>400張以下</MenuItem>
                    <MenuItem value={12}>600張以上</MenuItem>
                    <MenuItem value={13}>800張以上</MenuItem>
                    <MenuItem value={14}>1000張以上</MenuItem>
                </Select>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    取消
          </Button>
                <Button onClick={handleOk} color="primary">
                    確定
          </Button>
            </DialogActions>
        </Dialog>
    );
}
