import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';
import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function createData(stock_no, name, d5w, d5l, d5sper, d5mper, d10w, d10l, d10sper, d10mper, d20w, d20l, d20sper, d20mper, d60w, d60l, d60sper, d60mper, wd,
  d5sper1, d5, d10sper1, d10, d20sper1, d20, d60sper1, d60) {
  return {
    stock_no, name, d5w, d5l, d5sper, d5mper, d10w, d10l, d10sper, d10mper, d20w, d20l, d20sper, d20mper, d60w, d60l, d60sper, d60mper, wd,
    d5sper1, d5, d10sper1, d10, d20sper1, d20, d60sper1, d60
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function stableSort(array, cmp) {

  const stabilizedThis = array.map((el, index) =>
    [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}


const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: '股票代號' },
  // { id: 'stock_no', numeric: false, disablePadding: false, label: '代號' },

  { id: 'd5sper1', numeric: false, disablePadding: true, label: '漲幅' },
  { id: 'd5', numeric: false, disablePadding: true, label: '勝負' },

  { id: 'd10sper1', numeric: false, disablePadding: true, label: '漲幅' },
  { id: 'd10', numeric: false, disablePadding: true, label: '勝負' },

  { id: 'd20sper1', numeric: false, disablePadding: true, label: '漲幅' },
  { id: 'd20', numeric: false, disablePadding: true, label: '勝負' },

  { id: 'd60sper1', numeric: false, disablePadding: true, label: '漲幅' },
  { id: 'd60', numeric: false, disablePadding: true, label: '勝負' },

  { id: 'd5sper', numeric: false, disablePadding: true, label: '5日漲幅' },
  { id: 'd10sper', numeric: false, disablePadding: true, label: '10日漲幅' },
  { id: 'd20sper', numeric: false, disablePadding: true, label: '20日漲幅' },
  { id: 'd60sper', numeric: false, disablePadding: true, label: '60日漲幅' },
  { id: 'wd', numeric: false, disablePadding: false, label: '連勝大盤' },

  { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: color1 }}>
      <TableRow style={{}}>
        <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
        {/* <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">基本資料</TableCell> */}
        <TableCell className={classes.stickya} colSpan={2} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">5日</TableCell>
        <TableCell className={classes.stickya} colSpan={2} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">10日</TableCell>
        <TableCell className={classes.stickya} colSpan={2} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">20日</TableCell>
        <TableCell className={classes.stickya} colSpan={2} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">60日</TableCell>
        <TableCell className={classes.stickya} colSpan={5} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">漲幅</TableCell>
        <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
      </TableRow>
      <TableRow style={{}}>
        {headCells.map(headCell => (
          <TableCell
            // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
            key={headCell.id}
            className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
            style={{ ...((headCell.id === 'd5sper1' || headCell.id === 'd10sper1' || headCell.id === 'love' || headCell.id === 'd20sper1' || headCell.id === 'd60sper1' || headCell.id === 'd5sper' || headCell.id === 'inpriceStatus') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
            size="small"
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> : <TableSortLabel
              style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    margin: 0,
    // marginTop: theme.spacing(1),
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    // maxHeight: 550,
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  vvvv: {
    fontSize: 10,
    padding: 5,
    color: BColor.faircolor,
    overflow: 'visible'
  },
  button1: {
  },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
  stickya: {
    background: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    height: 35,
    zIndex: 10,
    top: 0,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    padding: 0,
    borderBottom: '1px solid #dbdbdb'
  },
  stickyh: {
backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 10,
    top: 37,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    padding: 0
  }, //This is header
  stickya1: {
    backgroundColor: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    height: 35,
    zIndex: 20,
    top: 0,
    left: 0,
    paddingTop: 7,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    padding: 0,
    borderBottom: '0px solid #dbdbdb'
  },
  stickyhh: {
    backgroundColor: color3,
    color: "#FFFFFF",
    position: '-webkit-sticky',
    position: 'sticky',
    top: 37,
    left: 0,
    zIndex: 20,
    paddingTop: 0,
    paddingBottom: 7,
    paddingLeft: 8,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    padding: 0,
    borderTop: '0px',
    borderRight: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb'
  }
}));



function WinMTable(props) {
  const classes = useStyles();
  const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState(loveItems);
  const [page, setPage] = React.useState(props.currentpage);
  const [dense, setDense] = React.useState(props.dense);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

  const [getStock, setSavestock] = React.useState(null);

  const [opendetail, setOpendetail] = React.useState(false)
  const [stockno, setStockNo] = React.useState(null);
  const [lovemenu, setLovemenu] = React.useState(false)
  const myContainer = React.useRef(null);

  const handleLoveClick = stockNo => event => {
    setSavestock(stockNo);
    setLovemenu(true);
  };

  const handleLoveClose = () => {
    setLovemenu(false);
  };
  const rows = props.data.map(row =>
    createData(row.market_company.stock_no,
      row.market_company.short_name,
      row.winm.d5.w,
      row.winm.d5.l,
      row.winm.d5.sper,
      row.winm.d5.mper,
      row.winm.d10.w,
      row.winm.d10.l,
      row.winm.d10.sper,
      row.winm.d10.mper,
      row.winm.d20.w,
      row.winm.d20.l,
      row.winm.d20.sper,
      row.winm.d20.mper,
      row.winm.d60.w,
      row.winm.d60.l,
      row.winm.d60.sper,
      row.winm.d60.mper,
      row.winm.wd,
      parseFloat(row.winm.d5.sper - row.winm.d5.mper),
      parseFloat((row.winm.d5.w * 100) / (row.winm.d5.w + row.winm.d5.l)),
      parseFloat(row.winm.d10.sper - row.winm.d10.mper),
      parseFloat((row.winm.d10.w * 100) / (row.winm.d10.w + row.winm.d10.l)),
      parseFloat(row.winm.d20.sper - row.winm.d20.mper),
      parseFloat((row.winm.d20.w * 100) / (row.winm.d20.w + row.winm.d20.l)),
      parseFloat(row.winm.d60.sper - row.winm.d60.mper),
      parseFloat((row.winm.d60.w * 100) / (row.winm.d60.w + row.winm.d60.l))
    )
  )

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.stock_no);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.pageupdate(newPage, props.currentcount)
  };

  const handleChangeRowsPerPage = event => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10));
    props.pageupdate(0, parseInt(event.target.value, 10))

    // setPage(0);
  };


  const renderInPrice = (value) => {
    if (value === -99999) return "-"
    else return value + "%"
  }

  const pricecolor = incv => {
    if (incv > 0) return BColor.upcolor
    else if (incv < 0) return BColor.downcolor
    else return BColor.faircolor
  }

  const calWinLoss = (win, loss) => {
    let result = (win * 100) / (win + loss)
    if (loss === -1 || win === -1) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
    } else if (result <= 20) {
      return <Button size="small" style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>大敗 {win}勝{loss}敗</Button>
    } else if (result > 20 && result < 48) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>小輸 {win}勝{loss}敗</Button>
    } else if (result >= 48 && result <= 52) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >平手 {win}勝{loss}敗</Button>
    } else if (result > 52 && result < 80) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >小贏 {win}勝{loss}敗</Button>
    } else if (result >= 80) {
      return <Button size="small" style={{ minWidth: 100, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >大贏 {win}勝{loss}敗</Button>
    } else {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
    }
  }

  function getRowPageData(screen) {
    if (screen === 'xs')
      return [20]
    else return [10, 20, 40, 80, 150];
  }


  const calPer = (sper, mper, ind) => {
    let result = (sper - mper).toFixed(2)
    if (sper === -99999 || mper === -99999) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
    } else if (result < -ind) {
      return <Button size="small" style={{ minWidth: 100, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>大敗 {result}%</Button>
    } else if (result >= -ind && result < 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>小輸  {result}%</Button>
    } else if (result === 0) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >平手  {result}%</Button>
    } else if (result > 0 && result < ind) {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >小贏  {result}%</Button>
    } else if (result >= ind) {
      return <Button size="small" style={{ minWidth: 100, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >大贏  {result}%</Button>
    } else {
      return <Button size="small" variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
    }
  }

  const openDetail = (stockno) => {
    if (props.detailUpdate != undefined) props.detailUpdate(true)
    setStockNo(stockno)
    setOpendetail(true)
  }

  const closeDetail = () => {
    if (props.detailUpdate != undefined) props.detailUpdate(false)
    if (props.closeupdate != undefined) props.closeupdate()
    setOpendetail(false)
  }

  const getAllIds = () => {
    return props.data.map(row => {
      return row.market_company.stock_no
    })
  }

  return (
    <div className={classes.root}>
      <StockDetail open={opendetail} allIds={getAllIds()} handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile} />
      <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}
          style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
        >
          <Table
            ref={myContainer}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >

            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.stock_no)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.stock_no}
                    // selected={isItemSelected}
                    >
                      <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            openDetail(row.stock_no)
                          }}
                          color="inherit"
                        >
                          {row.name.length > 8 ? `${row.name.substring(0, 8)}...` : row.name}
                        </Link>
                        <br />
                        <Link
                          style={{ color: BColor.upcolor }}
                          component="button"
                          variant="caption"
                          onClick={() => openDetail(row.stock_no)}
                        >
                          {row.stock_no}
                        </Link>
                      </TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="right">{calPer(row.d5sper, row.d5mper, 5)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">{calWinLoss(row.d5w, row.d5l)}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="right">{calPer(row.d10sper, row.d10mper, 8)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">{calWinLoss(row.d10w, row.d10l)}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="right">{calPer(row.d20sper, row.d20mper, 15)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">{calWinLoss(row.d20w, row.d20l)}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="right">{calPer(row.d60sper, row.d60mper, 25)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 10 }} size="small" align="right">{calWinLoss(row.d60w, row.d60l)}</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb', color: pricecolor(row.d5sper) }} size="small" align="center">{renderInPrice(row.d5sper)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pricecolor(row.d10sper) }} align="center">{renderInPrice(row.d10sper)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pricecolor(row.d20sper) }} align="center">{renderInPrice(row.d20sper)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, color: pricecolor(row.d60sper) }} align="center">{renderInPrice(row.d60sper)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} align="center">{row.wd}天</TableCell>

                      <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                        <Button
                          size="small"
                          onClick={handleLoveClick(row.stock_no)}
                          variant="outlined"
                          style={{ width: '100%', color: BColor.faircolor }}
                          className={classes.button}
                          startIcon={<FavoriteBorder />}
                        >
                          管理
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {props.disablepage ?
          <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
            匯出
          </Button></div>
          : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Divider orientation="vertical" flexItem />
            <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
              匯出
            </Button>
            <Divider orientation="vertical" flexItem />
            <TablePagination
              rowsPerPageOptions={getRowPageData(props.width)}
              component="div"
              count={props.total}
              rowsPerPage={props.currentcount}
              page={props.currentpage}
              labelRowsPerPage={'每頁'}
              labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /></div>
        }
      </Paper>
    </div>
  );
}

export default withWidth()(WinMTable);
