import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow);

function createData(title, eps, gindex, gr, yindex, yoy) {
    return { title, eps, gindex, gr, yindex, yoy };
}


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 250,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000"
  },
}));

export default function EPSList(props) {
    const classes = useStyles();
    const [buyselldata, setBuyselldata] = React.useState([]);

    useEffect(() => {
        if (props.open) fectchStockData(props.stockId)
    }, [props.stockId]);


    function fectchStockData(stockId) {
        if (stockId != null) {
            let a = {
                sid: stockId
            };

            fetch("/jstock/v1/stock/eps", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        let dd = result.epss.map(row =>
                            createData(row.title, row.eps, row.gindex, row.gr, row.yindex, row.yoy)
                        )
                        setBuyselldata(dd)
                    },
                    error => {
                    }
                );
        }
    }


    const renderSellerV1 = (index, value) => {
        if (index === 0) {
            let color = BColor.nodatacolor
            let bcolor = null//"#FFFFFF"
            if(value >= 0 && value < 100) {
                color = BColor.upcolorbox
            } else if(value >= 100) {
                 color = "#FFFFFF"
                 bcolor = BColor.upcolorbox                
            } else if(value < 0 && value > -100) {
                 color = BColor.downcolorbox              
            } else if(value < -100) {
                 color = "#FFFFFF"
                 bcolor = BColor.downcolorbox                
            }

            return <Button  variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, backgroundColor: bcolor, color: color }} className={classes.button1} >
                {value}%
          </Button>
        } else if (index === 1) {
            return <Button   variant="outlined"　style={{whiteSpace: "nowrap",  minWidth: 90, color: BColor.downcolorbox }} className={classes.button1}>盈轉虧</Button>
        } else if (index === 2) {
            return <Button  variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.upcolorbox }} className={classes.button1}>虧轉盈</Button>
        } else if (index === 3) {
            return <Button  variant="outlined" style={{ whiteSpace: "nowrap", minWidth: 90, color: BColor.downcolorbox }} className={classes.button1} >虧轉虧</Button>
        } else {
            return <Button   variant="outlined" style={{whiteSpace: "nowrap",  minWidth: 90, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">EPS明細</DialogTitle>

            <DialogContent>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table" size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>日期</StyledTableCell>
                                <StyledTableCell align="center" style={{whiteSpace: "nowrap", }}>EPS</StyledTableCell>
                                <StyledTableCell align="center" style={{whiteSpace: "nowrap", }}>QoQ</StyledTableCell>
                                <StyledTableCell align="center" style={{whiteSpace: "nowrap", }}>YoY</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {buyselldata.map(row => (
                                <StyledTableRow key={row.title}>
                                    <StyledTableCell component="th" scope="row" style={{whiteSpace: "nowrap",}}>
                                        {row.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{whiteSpace: "nowrap", paddingLeft: 5, paddingRight: 5, }}>{row.eps}元</StyledTableCell>
                                    <StyledTableCell align="center" style={{whiteSpace: "nowrap", paddingLeft: 5, paddingRight: 5,}}>{renderSellerV1(row.gindex, row.gr)}</StyledTableCell>
                                    <StyledTableCell align="center" style={{whiteSpace: "nowrap", paddingLeft: 5, paddingRight: 5,}}>{renderSellerV1(row.yindex, row.yoy)}</StyledTableCell>
                                </StyledTableRow>
))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={props.handleClose} color="primary">
                    取消
          </Button> */}
                <Button onClick={props.handleClose} color="primary">
                    關閉
          </Button>
            </DialogActions>
        </Dialog>
    );
}
