import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button } from '@material-ui/core';
import BColor from '../BColor'
import StockDetail from '../mobile/StockDetail'
import Link from '@material-ui/core/Link';
import * as dayjs from 'dayjs'
import CreateReport1 from './CreateReport1';
import UpdateReport1 from './UpdateReport1'
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CustomMaterialMenu from './CustomMaterialMenu'
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const getStatus = (status) => {
    if (status === 0) return '已出場'
    else if (status === 1) return '已達停利'
    else if (status === 2) return '已達停損'
    else if (status === 3) return '持有中'
    else if (status === 4) return '已達移動停利'
    else return '-'
}


const getPriceColor = (value) => {
    if (value >= 0) return BColor.upcolorbox
    else return BColor.downcolorbox
}


const conditionalRowStyles = [
    // {
    //   when: row => row.status === '持有中',
    //   style: {
    //     backgroundColor: 'green',
    //     color: 'white',
    //     '&:hover': {
    //       cursor: 'pointer',
    //     },
    //   },
    // },
    // // You can also pass a callback to style for additional customization
    // {
    //   when: row => row.status === '已出場',
    //   style: row => ({
    //     backgroundColor: row.isSpecia ? 'pink' : 'inerit',
    //   }),
    // },
];





const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
    },
}));

export default function ReportDashboard1(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [reports, setReports] = React.useState([]);
    const [stragel, setStragel] = React.useState([]);
    const [tranInfo, setTranInfo] = React.useState([]);

    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [ts, setTs] = React.useState(null);
    const [stock, setStock] = React.useState(null);
    const [now, setNow] = React.useState(null);
    const [id, setId] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [opendetail, setOpendetail] = React.useState(false)
    const [msgopen, setMsgOpen] = React.useState(false);
    const [errmsg, setErrMsg] = React.useState(null);

    const [status, setStatus] = React.useState(-1);
    const [instr, setInstr] = React.useState([])
    const [outstr, setOutstr] = React.useState([])
    const [instrv, setInstrv] = React.useState("-1")
    const [outstrv, setOutstrv] = React.useState("-1")

    const openDetail = (stock) => {
        setStock(stock)
        setOpendetail(true)
    }

    const closeDetail = () => {
        setOpendetail(false)
    }

    const handleClose = () => {
        setOpen(false);
        getAll(status, instrv, outstrv)
    };

    const columns = [
        {
            cell: row => <CustomMaterialMenu id={props.id} row={row} onUpdateRow={handleRowClicked} onDeleteRow={deleteHandler} />,
            allowOverflow: true,
            button: true,
            width: '56px', // custom width for icon button
        },
        {
            name: '進場日',
            wrap: false,
            selector: 'ymd',
            sortable: true,
            cell: row => <div onClick={(e) => handleRowClicked(row)}
                style={{ whiteSpace: "nowrap" }}
            >   {row.ymd}{row.real === 0 ? null : <Chip size="small" label="模" />}
            </div>
        },
        {
            name: '股票',
            selector: 'stock',
            sortable: true,
            cell: row => <Link
                style={{ whiteSpace: 'nowrap', color: BColor.upcolor }}
                component="button"
                variant="caption"
                onClick={() => openDetail(row.stock)}
            >
                {row.stock} - {row.name}
            </Link>,
        },
        {
            name: '狀態',
            sortable: true,
            selector: 'status',
            cell: row => <div onClick={(e) => handleRowClicked(row)} style={{ whiteSpace: 'nowrap', color: '#FFFFFF' }} >{getStatus(row.status)}</div>,
            conditionalCellStyles: [
                {
                    when: row => row.status === 0,
                    style: {
                        backgroundColor: BColor.bluecolor,
                    },
                },
                {
                    when: row => row.status === 1,
                    style: row => ({
                        backgroundColor: BColor.upcolorbox,
                    }),
                },
                {
                    when: row => row.status === 2,
                    style: row => ({
                        backgroundColor: BColor.downcolorbox,
                    }),
                },
                {
                    when: row => row.status === 3,
                    style: row => ({
                        backgroundColor: "#419154",
                    }),
                },
                {
                    when: row => row.status === 4,
                    style: row => ({
                        backgroundColor: BColor.upcolorbox,
                    }),
                }]
        },
        {
            name: '庫存',
            selector: 'storage',
            sortable: true,
            right: true,
            cell: row => <div onClick={(e) => handleRowClicked(row)} style={{ whiteSpace: 'nowrap' }} >{row.storage != null ? row.storage  + "股": "0股"}</div>,

        },
        {
            name: '平均買價',
            selector: 'avgb',
            sortable: true,
            right: true,
            cell: row => <div onClick={(e) => handleRowClicked(row)} style={{ whiteSpace: 'nowrap' }} >{row.avgb != null ? row.avgb : ""}</div>,

        },
        {
            name: '平均賣價',
            selector: 'avgs',
            sortable: true,
            right: true,
            cell: row => <div onClick={(e) => handleRowClicked(row)} style={{ whiteSpace: 'nowrap' }} >{row.avgs != null ? row.avgs : ""}</div>,

        },
        {
            name: '停利價',
            sortable: true,
            right: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.strage != null && row.strage.stopearn != null ? row.strage.stopearn : ""}</div>,
        },
        {
            name: '停損價',
            right: true,
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.strage != null && row.strage.stoplost != null ? row.strage.stoplost : ""}</div>,
        },
        {
            name: '實現損益',
            selector: 'profit',
            right: true,
            sortable: true,
            cell: row => <div onClick={(e) => handleRowClicked(row)} style={{ whiteSpace: 'nowrap', color: getPriceColor(row.profit) }} >{row.profit != null ? row.profit : ""}</div>,
        },
        {
            name: '報酬率',
            selector: 'roi',
            sortable: true,
            right: true,
            cell: row => <div onClick={(e) => handleRowClicked(row)} style={{ whiteSpace: 'nowrap', color: getPriceColor(row.roi) }} >{row.roi != null ? row.roi + "%" : ""}</div>,
        },
        {
            name: '備註',
            selector: 'memo',
            width: '600px',
            // maxWidth: '600px',
            // allowOverflow: true,
            cell: row => <div style={{ paddingTop: 10, paddingBottom: 10, }} onClick={(e) => handleRowClicked(row)}>{row.memo != null ? row.memo.substring(0, 300) : null}</div>
        },
    ];

    const columns1 = [
        {
            name: '交易',
            selector: 'real',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.real === 0 ? "實單" : "模擬單"}</div>,
        },
        {
            name: '總損益',
            selector: 'profit',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.profit) }} >{row.profit}</div>,
        },
        {
            name: '報酬率',
            selector: 'roi',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.roi) }} >{row.roi}%</div>,
        },
        {
            name: '勝率',
            selector: 'winr',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.winr) }} >{row.winr}%</div>,

        },
        {
            name: '停利',
            sortable: true,
            selector: 'ec',
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.ec}次</div>,
        },
        {
            name: '停損',
            selector: 'lc',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.lc}次</div>,
        }
    ];


    const columns2 = [
        {
            name: '交易策略',
            selector: 'iname',
            width: '150px',
            cell: row => <div style={{}} >{row.iname}<br />{row.oname}</div>,
        },
        {
            name: '勝率',
            selector: 'winr',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.winr}%</div>,
        },
        // {
        //     name: '敗率',
        //     selector: 'lostr',
        //     sortable: true,
        //     cell: row => <div style={{ whiteSpace: 'nowrap' }} >{row.lostr}%</div>,
        // },
        {
            name: '平均獲利',
            selector: 'winm',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.winm) }} >{row.winm}元</div>,

        },
        {
            name: '平均損失',
            selector: 'lostm',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.lostm) }} >{row.lostm}元</div>,
        },
        {
            name: '賺賠比',
            sortable: true,
            selector: 'winlost',
        },
        // {
        //     name: '單位預期回報',
        //     selector: 'riskr',
        //     sortable: true,
        //     cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor(row.riskr) }} >{row.riskr}%</div>,
        // },
        {
            name: '期望值',
            selector: 'exp',
            sortable: true,
            cell: row => <div style={{ whiteSpace: 'nowrap', color: getPriceColor1(row.exp) }} >{getWord(row.exp)}</div>,
        },
    ];

    const getPriceColor1 = (value) => {
        if (value === 0) return BColor.upcolorbox
        else if (value === 1) return BColor.downcolorbox
        else return BColor.upcolorbox
    }

    const getWord = (i) => {
        if (i === 0) return "正"
        else if (i === 1) return "負"
        else return "-"
    }

    const getAll = (status, inv, ouv) => {
        if (localStorage.getItem('lic') != null) {
            let a = {
            }

            a["lic"] = localStorage.getItem('lic')
            if (status != null && status != -1) a['status'] = status
            if (inv != null && inv != "-1") a['ins'] = inv
            if (ouv != null && ouv != "-1") a['outs'] = ouv

            fetch("/jstock/v1/stock/treport1/getall", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(a)
            })
                .then(res => res.json())
                .then(
                    result => {
                        // if (result.length > 0) 
                        setReports(result.logs)
                        setStragel(result.strages)
                        setTranInfo(result.tinfo)
                        setInstr(result.instr)
                        setOutstr(result.outstr)
                    }
                ).catch(err => { /* maybe 400 */ });
        }
    }

    useEffect(() => {
        getAll(status, instrv, outstrv)
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        getAll(status, instrv, outstrv)
    };

    const handleRowClicked = (row) => {
        // setTs(row.ts)
        // setStock(row.stock_no)
        setId(row.id)
        var n = Date.now()
        setNow(n)
        setOpenUpdate(true)
    }

    // const actions = (
    //     <Button variant="outlined" size="small" color="secondary" onClick={(e) => handleClickOpen()} >
    //         新增買進日誌
    //     </Button>
    // );

    const deleteHandler = (row) => {
        let a = {
            "id": row.id,
        }

        if (localStorage.getItem('lic') != null)
            a["lic"] = localStorage.getItem('lic')


        fetch("/jstock/v1/stock/treport1/dellog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(a)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === 0) {
                        getAll(status, instrv, outstrv)
                    } else if (result.status === -1) {
                        setErrMsg("交易明細不存在。")
                        setMsgOpen(true)
                    } else if (result.status === -2) {
                        setErrMsg("訂閱專屬功能。")
                        setMsgOpen(true)
                    } else {
                        setErrMsg("發生錯誤，請稍後嘗試")
                        setMsgOpen(true)
                    }
                }
            );
    }

    const customStyles = {
        header: {
            style: {
                fontSize: "36px",
                fontWeight: "bold",
                paddingLeft: 0
            },
        },
        rows: {
            style: {
                maxHeight: '200px', // override the row height
            }
        },
        headCells: {
            style: {
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white
            },
        },
        pagination: {
            pageButtonsStyle: {
                backgroundColor: null,
                '&:hover:not(:disabled)': {
                    backgroundColor: null,
                },
                '&:focus': {
                    outline: 'none',
                    backgroundColor: null,
                },
                height: '30px',
                width: '30px',
                padding: '2px',
                margin: '2px',
            }
        }
    };

    const statusUpdate = (e) => {
        setStatus(e.target.value)
        getAll(e.target.value, instrv, outstrv)
    }

    const instrUpdate = (e) => {
        setInstrv(e.target.value)
        getAll(status, e.target.value, outstrv)
    }

    const outstrUpdate = (e) => {
        setOutstrv(e.target.value)
        getAll(status, instrv, e.target.value)
    }

    return (
        <div style={{ backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000", }}>
            <StockDetail open={opendetail} handleClose={closeDetail} stockno={stock} ismobile={props.ismobile} />
            <CreateReport1 open={open} close={handleClose} ismobile={props.ismobile} ></CreateReport1>
            <UpdateReport1 now={now} id={id} open={openUpdate} close={handleCloseUpdate} ismobile={props.ismobile}></UpdateReport1>




            <DataTable
                theme={theme.palette.type}
                // style={{ borderWidth: 1, borderColor: "#dedede", borderStyle: "solid" title={} }}
                customStyles={customStyles} onRowClicked={handleRowClicked} noDataComponent={"無任何交易日誌"} title={<div style={{}}><Typography variant="h5" id="tableTitle">
                    交易日誌<Button disableElevation color="primary" onClick={(e) => handleClickOpen()} variant="contained" style={{ marginLeft: 5 }}  >新增</Button>
                </Typography>
                    <div style={{ display: 'flex', marginTop: 5, marginBottom: 5 }}>
                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 0 }}>
                            <InputLabel id="s1-label">狀態</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s1-label"
                                id="s1"
                                value={status}
                                onChange={e => statusUpdate(e)}
                            >
                                <MenuItem value={-1}>全部</MenuItem>
                                <MenuItem value={5}>尚未出場</MenuItem>
                                <MenuItem value={0}>已出場</MenuItem>
                                <MenuItem value={1}>已達停利</MenuItem>
                                <MenuItem value={2}>已達停損</MenuItem>
                                <MenuItem value={3}>持有中</MenuItem>
                                <MenuItem value={4}>已達移動停利</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">進場</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s2-label"
                                id="s2"
                                value={instrv}
                                onChange={e => instrUpdate(e)}
                            >
                                <MenuItem value={"-1"}>全部</MenuItem>
                                {instr.map(row => {
                                    return <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl size="small" variant="filled" className={classes.formControl} style={{ marginLeft: 5 }}>
                            <InputLabel id="s1-label">出場</InputLabel>
                            <Select
                                style={{ minWidth: 80 }}
                                labelId="s3-label"
                                id="s3"
                                value={outstrv}
                                onChange={e => outstrUpdate(e)}
                            >
                                <MenuItem value={"-1"}>全部</MenuItem>
                                {outstr.map(row => {
                                    return <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl></div></div>

                } pagination paginationPerPage={30} paginationRowsPerPageOptions={[10, 30, 50]} conditionalRowStyles={conditionalRowStyles} columns={columns} data={reports} fixedHeader fixedHeaderScrollHeight="300px" />

            <DataTable
                theme={theme.palette.type}
                // style={{ borderWidth: 1, borderColor: "#dedede", borderStyle: "solid" }}
                customStyles={customStyles} noDataComponent={"無任何交易統計，請先新增交易日誌。"} title={<div style={{ display: 'flex', marginTop: 20 }}>
                    <Typography className={classes.title} variant="h5" id="tableTitle">
                        交易統計
                    </Typography>
                </div>} pagination paginationPerPage={30} conditionalRowStyles={conditionalRowStyles} columns={columns1} data={tranInfo} fixedHeader fixedHeaderScrollHeight="300px" />

            <DataTable
                theme={theme.palette.type}
                // style={{ borderWidth: 1, borderColor: "#dedede", borderStyle: "solid" }}
                customStyles={customStyles} noDataComponent={"無任何策略統計資料，請在交易日誌中選擇策略。"} title={<div style={{ display: 'flex', marginTop: 20 }}>
                    <Typography className={classes.title} variant="h5" id="tableTitle">
                        策略統計
                    </Typography>
                </div>} pagination paginationPerPage={30} paginationRowsPerPageOptions={[10, 30, 50]} conditionalRowStyles={conditionalRowStyles} columns={columns2} data={stragel} fixedHeader fixedHeaderScrollHeight="300px" />
        </div>
    )
}
