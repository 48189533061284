import React, { useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    AxisPointerComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import Button from '@material-ui/core/Button'
import { getComputerOnlyDataZoom, getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import { TextField } from '@material-ui/core';

echarts.use(
    [AxisPointerComponent, CanvasRenderer, VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        // overflowScrolling: 'touch',
        // WebkitOverflowScrolling: 'touch',  
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1)}px)`
    }
}));


export default function SilkBand(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [options, setOptions] = React.useState(null)
    const [open1, setOpen] = React.useState(false);

    const [data, setData] = React.useState(null)
    const [loading, setLoading] = React.useState(false);
    const [avg, setAvg] = React.useState(localStorage.getItem("silk_avg") != null ? localStorage.getItem("silk_avg") : 20)
    const [back, setBack] = React.useState(localStorage.getItem("silk_back") != null ? localStorage.getItem("silk_back") : 10)

    useEffect(() => {
        if (props.row != null) getPHV1()
    }, [props.row, avg, back]);

    useEffect(() => {
        if (data != null) setOptions(genData1())
    }, [data]);

    const getPHV1 = () => {
        setLoading(true)
        let lic = localStorage.getItem('lic')


        if (lic != null) {
            let param = {
                lic: lic,
                sid: props.row.market_company.stock_no,
                avg: avg,
                back: back
            };

            fetch("/jstock/v1/stock/silk", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        setLoading(false)
                        if (result != null && result.status === 0) {
                            setData(result.sio)
                        }
                    }
                )

        }
    }



    function genData1() {
        let option = {
            // legend: {
            //     data: ['昂貴區', '合理區', '便宜區', '股價',]
            // },
            grid: [
                {
                    top: '20',
                    left: '40',
                    right: '10',
                    height: '300',
                    borderWidth: 0,
                    borderColor: "#000205",
                    show: true
                }
            ],
            yAxis: [
                {
                    scale: true,
                    splitArea: {
                        show: false
                    },
                    show: true,
                    position: 'left',
                    splitLine: {
                        show: true
                    },
                }
            ],
        };

        let x1 = {
            type: 'category',
            scale: true,
            boundaryGap: true,
            data: data.map(v => { return v.p.d }),
        }


        if (props.ismobile) {
            let k = {
                type: 'shadow',
                label: { show: false },
                triggerTooltip: true,
                handle: {
                    show: true,
                    margin: 25,
                    color: '#1868d9',
                    size: 30
                }
            }

            x1['axisPointer'] = k
        }

        option['xAxis'] = [x1]

        let dif = data.map((v, i) => v.m1 - v.m2);

        option['series'] = [
            {
                name: '日K',
                type: 'candlestick',
                data: data.map(v => { return [v.p.o, v.p.p, v.p.l, v.p.h] }),
                itemStyle: {
                    color: "#d41c1c",
                    color0: '#65b206',
                    borderColor: "#d41c1c",
                    borderColor0: '#65b206'
                },
                selectedMode: 'single',
            },
            {
                name: '多方緞帶',
                type: 'line',
                data: data.map(v => { return v.m1 }),
                smooth: 'true',
                showSymbol: false,
                stack: 'x',
            },
            {
                data: data.map((v, i) => v.m2 - v.m1),
                type: 'line',
                stack: 'x',                  // stack name
                stackStrategy: 'all',   // strategy
                lineStyle: {
                    opacity: 0                 // hide line
                },
                symbol: 'none',              // hide symbol
                areaStyle: {
                    color: '#ccc'
                },
                tooltip: {
                    show: false                // hide value on tooltip
                }
            },
            {
                name: '空方緞帶',
                type: 'line',
                data: data.map(v => { return v.m2 }),
                smooth: 'true',
                showSymbol: false,
                itemStyle: {
                    color: "#d41c1c",
                },
                stack: 'y',
            },
            // {
            //     data: data.map((v, i) => v.m1 - v.m2),
            //     type: 'line',
            //     stack: 'y',                  // stack name
            //     stackStrategy: 'samesign',   // strategy
            //     lineStyle: {
            //         opacity: 0                 // hide line
            //     },
            //     symbol: 'none',              // hide symbol
            //     areaStyle: {
            //         color: '#111'
            //     },
            //     tooltip: {
            //         show: false                // hide value on tooltip
            //     }
            // },
        ]

        if (props.ismobile) {
            option['tooltip'] = getMobileTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getMobileDataZoom(80, 100)

        } else {
            option['tooltip'] = getComputerTooltip()
            option['axisPointer'] = getAxisPointer()
            option['dataZoom'] = getComputerOnlyDataZoom(70, 100)
        }

        return option
    }

    function getString() {
        return "目前無相關的均線緞帶"
    }

    const avgChange = (event) => {
        localStorage.setItem("silk_avg", event.target.value)
        setAvg(event.target.value)
    }

    const backChange = (event) => {
        localStorage.setItem("silk_back", event.target.value)
        setBack(event.target.value)
    }

    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={12} >
                    <div style={{ display: 'flex', marginBottom: 5, paddingLeft: 0 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            均線緞帶
                        </Typography>
                    </div>
                    <div style={{ fontSize: 14, color: "#808080" }}>
                        藍色線在上為多方，紅色線在上為空方
                        <br />
                        <TextField
                            label="均線(預設:20日)"
                            id="avg"
                            value={avg}
                            onChange={event => avgChange(event)}

                        />
                        <TextField
                            label="緞帶長度(預設:10日)"
                            id="back"
                            value={back}
                            onChange={event => backChange(event)}

                        />
                    </div>
                    {options != null ? <ReactEChartsCore
                        style={{ height: 350, marginTop: 20 }}
                        theme={theme.palette.type}
                        echarts={echarts}
                        option={options}
                        notMerge={true}
                        lazyUpdate={true}
                        showLoading={loading}
                    /> : <div style={{}}>{getString()}</div>}
                </Grid>
            </Grid>
        </div >
    );
}