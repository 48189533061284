import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button'
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import BPage from './BPage'
import Grid from '@material-ui/core/Grid';

import { EASY_1 } from './ruletp/RuleData'
import RuleTp5 from './ruletp/RuleTp5'

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            padding: 15,
        },
        [theme.breakpoints.up('lg')]: {
            padding: 15,
        },
        overflow: 'auto',
    },
    panelTitle: {
        marginLeft: 10
    },
    divider: {
        margin: 15,
        backgroundColor: "#E7E7E7"
    },
    titleLabel: {
        fontSize: 20,
        fontWeight: 350,
        marginBottom: 0,
        color: theme.palette.primary.dark
    },
    chip: {
        margin: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: "#FFFFFF",
        backgroundColor: "#D80C67"
    },
    button: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: 5,
    },
    input: {
        display: 'none',
    },
    margin: {
        width: '100%',
    },
    root1: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        // backgroundColor: theme.palette.background.default,
    },
    gridList: {
        width: '100%',
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}))

function EasyBoy3(props) {
    const classes = useStyles();

    const handleOpen = () => {
        props.tabchange(BPage.donate_page)
    }

    const changeScreen = () => {
        let k = localStorage.getItem('run_computer') === null ? "0" : localStorage.getItem('run_computer')

        if (k === "0") {
            localStorage.setItem("run_computer", "1")
        } else {
            localStorage.setItem("run_computer", "0")
        }

        window.location.reload(false);
    }

    return (
        <div className={classes.root} >
            {props.tabchange != undefined ?
                <Button variant="outlined" size="small" color="primary" className={classes.button} onClick={handleOpen}>
                    訂閱
                </Button> : null
            }
            {props.tabchange != undefined && (props.pathname === "/mdashboard1" || props.pathname === "/mdashboard" || props.pathname === "/mdashboardg" || props.pathname === "/mdashboardios") ?
                <Button variant="outlined" size="small" color="primary" className={classes.button} onClick={changeScreen}>
                    更換版面
                </Button> : null
            }

            <Button variant="outlined" size="small" color="primary" className={classes.button} onClick={(e) => props.clearRule()}>
                清除規則
            </Button>

            <div className={classes.root1} >
                <Grid container spacing={1}>
                    {EASY_1.map(k => {
                        return <Grid key={k.id} item xs={12} sm={6} md={4}>
                            <RuleTp5
                                id={k.id}
                                t1={k.desc[0]}
                                desc={k.sh}
                                ruleChange={props.updateRule}
                                rules={props.rules}
                            ></RuleTp5>
                        </Grid>
                    })}
                </Grid>
            </div>

        </div>
    );
}

export default withWidth()(EasyBoy3);