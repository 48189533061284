import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TablePagination from '@material-ui/core/TablePagination';
import withWidth from '@material-ui/core/withWidth';

import StockDetail from '../mobile/StockDetail'
import BColor from '../BColor'
import MACDList from './MACDList';
import MyLoveList from '../mylove/MyLoveList';
import FavoriteBorder from '@material-ui/icons/Build'
import { exportExcel } from '../util/ExportCSV'
import Divider from '@material-ui/core/Divider'

function desc(a, b, orderBy) {
    let key = orderBy.substring(1)
    if (b.macd != null && a.macd != null) {
        if (orderBy.indexOf("0") != -1) {
            if (b.macd.d[key] < a.macd.d[key]) {
                return -1;
            }
            if (b.macd.d[key] > a.macd.d[key]) {
                return 1;
            }
        } else if (orderBy.indexOf("1") != -1) {
            if (b.macd.w[key] < a.macd.w[key]) {
                return -1;
            }
            if (b.macd.w[key] > a.macd.w[key]) {
                return 1;
            }
        } else if (orderBy.indexOf("2") != -1) {
            if (b.macd.m[key] < a.macd.m[key]) {
                return -1;
            }
            if (b.macd.m[key] > a.macd.m[key]) {
                return 1;
            }
        }
    }

    return 0;
}


function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) =>
        [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// case class KDInfo(ymd : String, k : Double, d : Double, dir : Int, pas : Int, pashday : Int, paslday : Int , pasmday : Int, corss : Int, crosg : Int, crosd : Int, crosp : Int)


const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: '股票代號', sid: 'name' },

    { id: 'ymd', numeric: false, disablePadding: false, label: '日期', sid: '0ymd' },
    { id: '0ema12', numeric: false, disablePadding: false, label: 'EMA（短）', sid: '0ema12' },
    { id: '0ema26', numeric: false, disablePadding: false, label: 'EMA（長）', sid: '0ema26' },
    { id: '0diff', numeric: false, disablePadding: false, label: 'DIF', sid: '0diff' },
    { id: '0macd', numeric: false, disablePadding: false, label: 'MACD', sid: '0macd' },
    { id: '0linp', numeric: false, disablePadding: false, label: '快慢線位置', sid: '0linp' },
    { id: '0cross', numeric: false, disablePadding: false, label: '快慢線交叉', sid: '0cross' },
    { id: '0osc', numeric: false, disablePadding: false, label: 'OSC', sid: '0osc' },
    { id: '0oscs', numeric: false, disablePadding: false, label: '柱體狀態', sid: '0oscs' },

    { id: '1ema12', numeric: false, disablePadding: false, label: 'EMA（短）', sid: '1ema12' },
    { id: '1ema26', numeric: false, disablePadding: false, label: 'EMA（長）', sid: '1ema26' },
    { id: '1diff', numeric: false, disablePadding: false, label: 'DIF', sid: '1diff' },
    { id: '1macd', numeric: false, disablePadding: false, label: 'MACD', sid: '1macd' },
    { id: '1linp', numeric: false, disablePadding: false, label: '快慢線位置', sid: '1linp' },
    { id: '1cross', numeric: false, disablePadding: false, label: '快慢線交叉', sid: '1cross' },
    { id: '1osc', numeric: false, disablePadding: false, label: 'OSC', sid: '1osc' },
    { id: '1oscs', numeric: false, disablePadding: false, label: '柱體狀態', sid: '1oscs' },


    { id: '2ema12', numeric: false, disablePadding: false, label: 'EMA（短）', sid: '2ema12' },
    { id: '2ema26', numeric: false, disablePadding: false, label: 'EMA（長）', sid: '2ema26' },
    { id: '2diff', numeric: false, disablePadding: false, label: 'DIF', sid: '2diff' },
    { id: '2macd', numeric: false, disablePadding: false, label: 'MACD', sid: '2macd' },
    { id: '2linp', numeric: false, disablePadding: false, label: '快慢線位置', sid: '2linp' },
    { id: '2cross', numeric: false, disablePadding: false, label: '快慢線交叉', sid: '2cross' },
    { id: '2osc', numeric: false, disablePadding: false, label: 'OSC', sid: '2osc' },
    { id: '2oscs', numeric: false, disablePadding: false, label: '柱體狀態', sid: '2oscs' },

    { id: 'love', numeric: false, disablePadding: false, label: '自選清單' },
];

const color1 = BColor.blue1color// "#e0757e"
const color2 = BColor.column
const color3 = BColor.blackcolor


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        if (value != null && value != '' && value.length === 4) {
            localStorage.setItem('epnow', value);
            props.freecashupdate()
        }

        setAnchorEl(null);
    };


    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{ background: color1 }}>
            <TableRow style={{}}>
                <TableCell className={classes.stickya1} colSpan={1} style={{ color: "#FFFFFF", borderRight: '1px solid #dbdbdb' }}>股票名稱</TableCell>
                <TableCell className={classes.stickya} colSpan={9} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">日MACD</TableCell>
                <TableCell className={classes.stickya} colSpan={8} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">週MACD</TableCell>
                <TableCell className={classes.stickya} colSpan={8} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center">月MACD</TableCell>
                <TableCell className={classes.stickya} colSpan={1} style={{ color: "#FFFFFF", borderLeft: '1px solid #dbdbdb' }} align="center"></TableCell>
            </TableRow>
            <TableRow style={{}}>
                {headCells.map(headCell => (
                    <TableCell
                        // style={{ margin: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: headCell.paddingLeft, paddingRight: headCell.paddingRight }}
                        key={headCell.id}
                        className={(headCell.id === 'name' ? classes.stickyhh : classes.stickyh)}
                        style={{ ...((headCell.id === 'ymd' || headCell.id === '1ema12' || headCell.id === '2ema12' || headCell.id === 'love') ? { borderLeft: '1px solid #dbdbdb' } : {}) }}
                        size="small"
                        align={headCell.id === 'name' ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.id === 'pashday' || headCell.id === 'pashday1' || headCell.id === 'pashday2'
                        || headCell.id === 'love' ? <div style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}>{headCell.label}</div> :
                        <TableSortLabel
                            style={{ whiteSpace: 'nowrap', ...(headCell.id === 'name' ? { width: 10 } : {}), }}
                            active={orderBy === headCell.id}
                            direction={order}
                            onClick={createSortHandler(headCell.sid)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        margin: 0,
        // marginTop: theme.spacing(1),
    },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        // maxHeight: 550,
        overflowX: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },

    vvvv: {
        fontSize: 10,
        padding: 5,
        color: BColor.faircolor,
        overflow: 'visible'
    },
    button1: {
        minWidth: 110,
    },
    button2: {
        // minWidth: 90,
    },
    button3: {
        minWidth: 181,
    },
    stickyc: {
    paddingLeft: 10,
    paddingRight: 5,
    width: 10,
    whiteSpace: 'nowrap',
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 1,
    left: 0,
    backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
  },
    stickya: {
        background: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 10,
        top: 0,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0,
        borderBottom: '1px solid #dbdbdb'
    },
stickyh: {
    backgroundColor: theme.palette.type === "light" ? color2 : "#000000",
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: 10,
        top: 37,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 10,
        padding: 0
    }, //This is header
    stickya1: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        height: 35,
        zIndex: 20,
        top: 0,
        left: 0,
        paddingTop: 7,
        paddingBottom: 0,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderBottom: '0px solid #dbdbdb'
    },
    stickyhh: {
        backgroundColor: color3,
        color: "#FFFFFF",
        position: '-webkit-sticky',
        position: 'sticky',
        top: 37,
        left: 0,
        zIndex: 20,
        paddingTop: 0,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        padding: 0,
        borderTop: '0px',
        borderRight: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb'
    }
}));



function MACDDetail(props) {
    let redcolor = BColor.upcolor
    let greencolor = BColor.downcolor
    let greenback = BColor.downcolorbox
    let redback = BColor.upcolorbox

    const classes = useStyles();
    const loveItems = localStorage.getItem('love_stock') != null ? JSON.parse(localStorage.getItem('love_stock')) : []

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState(loveItems);
    const [page, setPage] = React.useState(props.currentpage);
    const [dense, setDense] = React.useState(props.dense);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.currentcount);

    const [getStock, setSavestock] = React.useState(null);

    const [opendetail, setOpendetail] = React.useState(false)
    const [stockno, setStockNo] = React.useState(null);


    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [opentp, setOpenTp] = React.useState(0);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [lovemenu, setLovemenu] = React.useState(false)
    const myContainer = React.useRef(null);

    const handleLoveClick = stockNo => event => {
        setSavestock(stockNo);
        setLovemenu(true);
    };

    const handleLoveClose = () => {
        setLovemenu(false);
    };

    const handleClickOpencc = (stock_no, stockname, tp) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockname)
        setOpenTp(tp)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setOpenTp(0)
        setConcentOpen(false);
    };

    function getRowPageData(screen) {
        if (screen === 'xs')
            return [20]
        else return [10, 20, 40, 80, 150];
    }

    const rows = props.data

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.stock_no);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.pageupdate(newPage, props.currentcount)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0)
        setRowsPerPage(parseInt(event.target.value, 10));
        props.pageupdate(0, parseInt(event.target.value, 10))
    };

    const openDetail = (stockno) => {
        if (props.detailUpdate!= undefined) props.detailUpdate(true)
        setStockNo(stockno)
        setOpendetail(true)
    }

    const closeDetail = () => {
        if (props.closeupdate != undefined) props.closeupdate()
        if (props.detailUpdate!= undefined) props.detailUpdate(false)
        setOpendetail(false)
    }

    const renderLinP = (value, tp, row) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                快慢線穿越0軸
        </Button>
        } else if (value === 1) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                快慢線跌破0軸
        </Button>
        } else if (value === 2) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                快慢線0軸上
        </Button>
        } else if (value === 3) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                快慢線0軸下
        </Button>
        } else if (value === 4) {
            return <div><Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button2} >
                快線0軸上
        </Button><br /><Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.downcolorbox, marginTop: 1 }} className={classes.button2} >
                    慢線0軸下
        </Button></div>
        } else if (value === 5) {
            return <div><Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.upcolorbox }} className={classes.button2} >
                慢線0軸上
        </Button><br /><Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: BColor.downcolorbox, marginTop: 1 }} className={classes.button2} >
                    快線0軸下
        </Button></div>
        } else if (value === -1) {
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }

    const renderCross = (value, tp, row) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: redcolor }} className={classes.button2} >
                金叉
        </Button>
        } else if (value === 1) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: greencolor }} className={classes.button2} >
                死叉
        </Button>
        } else if (value === 2) {
            color = BColor.nodatacolor
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                無交叉
        </Button>
        } else if (value === -1) {
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }

    const renderOSCS = (value, tp, row) => {
        let color = BColor.nodatacolor

        if (value === 0) {
            // color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: "#FFFFFF", backgroundColor: redback }} className={classes.button2} >
                由綠翻紅
        </Button>
        } else if (value === 1) {
            // color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: "#FFFFFF", backgroundColor: greenback }} className={classes.button2} >
                由紅轉綠
        </Button>
        } else if (value === 2) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                紅柱逐漸變大
        </Button>
        } else if (value === 3) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                紅柱逐漸變小
        </Button>
        } else if (value === 4) {
            color = BColor.upcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                持續紅柱
        </Button>
        } else if (value === 5) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                綠柱逐漸變大
        </Button>
        } else if (value === 6) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                綠柱逐漸變小
        </Button>
        } else if (value === 7) {
            color = BColor.downcolorbox
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                維持綠柱
        </Button>
        } else if (value === -1) {
            return <Button size="small" variant="outlined" onClick={handleClickOpencc(row.market_company.stock_no, row.market_company.short_name, tp)} style={{ minWidth: 110, color: color }} className={classes.button2} >
                -
        </Button>
        }
    }

    const getAllIds = () => {
        return props.data.map (row => {
          return row.market_company.stock_no
        })
      }
      
    return (
        <div className={classes.root}>
            <StockDetail open={opendetail} allIds={getAllIds()}  handleClose={closeDetail} stockno={stockno} ismobile={props.ismobile}/>
            <MACDList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockname={opensname} tp={opentp}></MACDList>
            <MyLoveList open={lovemenu} handleClose={handleLoveClose} stockno={getStock} myloveupdate={props.myloveupdate} />

            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}
                    style={{ ...((localStorage.getItem('hfix') == null || localStorage.getItem('hfix') === "y") ? { maxHeight: '70vh' } : {}) }}
                >
                    <Table
                        ref={myContainer}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >

                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            freecashupdate={props.freecashupdate}

                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, index) => {
                                    //   const isItemSelected = isSelected(row.stock_no);

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.market_company.stock_no}
                                        >
                                            <TableCell className={classes.stickyc} style={{ borderRight: '1px solid #dbdbdb', borderBottom: '1px solid #d9d9d9' }} padding="none" size="small" align="left">
                                                <Link
    component="button"
    variant="body2"
    onClick={() => {
        openDetail(row.market_company.stock_no)
    }}
    color="inherit"
>{row.market_company.short_name}
</Link>
                                                <br />
                                                <Link
                                                    style={{ color: BColor.upcolor }}
                                                    component="button"
                                                    variant="caption"
                                                    onClick={() => openDetail(row.market_company.stock_no)}
                                                >
                                                    {row.market_company.stock_no}
                                                </Link>
                                            </TableCell>


                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="center">
                                                {row.macd.d.ymd}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.d.ema12}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.d.ema26}
                                            </TableCell> : null}


                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.d.diff}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.d.macd}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderLinP(row.macd.d.linp, 0, row)}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderCross(row.macd.d.cross, 0, row)}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.d.osc}
                                            </TableCell> : null}

                                            {row.macd.d != null && row.macd.d != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderOSCS(row.macd.d.oscs, 0, row)}
                                            </TableCell> : null}


                                            {row.macd.d == null || row.macd.d == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="9" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    無資料
                                                    </Button>
                                            </TableCell> : null}


                                            {/* ////////////////////////////////////////////////////////////// */}
                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="center">
                                                {row.macd.w.ema12}
                                            </TableCell> : null}

                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.w.ema26}
                                            </TableCell> : null}


                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.w.diff}
                                            </TableCell> : null}

                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.w.macd}
                                            </TableCell> : null}

                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderLinP(row.macd.w.linp, 1, row)}
                                            </TableCell> : null}

                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderCross(row.macd.w.cross, 1, row)}
                                            </TableCell> : null}

                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.w.osc}
                                            </TableCell> : null}

                                            {row.macd.w != null && row.macd.w != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderOSCS(row.macd.w.oscs, 1, row)}
                                            </TableCell> : null}


                                            {row.macd.w == null || row.macd.w == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="8" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    無資料
                                                    </Button>
                                            </TableCell> : null}


                                            {/* ////////////////////////////////////////////////////////////// */}
                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} size="small" align="center">
                                                {row.macd.m.ema12}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.m.ema26}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.m.diff}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.m.macd}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderLinP(row.macd.m.linp, 2, row)}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderCross(row.macd.m.cross, 2, row)}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="center">
                                                {row.macd.m.osc}
                                            </TableCell> : null}

                                            {row.macd.m != null && row.macd.m != undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5 }} size="small" align="left">
                                                {renderOSCS(row.macd.m.oscs, 2, row)}
                                            </TableCell> : null}


                                            {row.macd.m == null || row.macd.m == undefined ? <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 5, paddingRight: 5, borderLeft: '1px solid #dbdbdb' }} colSpan="8" size="small" align="left">
                                                <Button size="small" variant="outlined" style={{ width: "100%", color: BColor.faircolor }} className={classes.button2} >
                                                    無資料
                                                    </Button>
                                            </TableCell> : null}

                                            <TableCell style={{ whiteSpace: 'nowrap', borderLeft: '1px solid #dbdbdb', paddingLeft: 5, paddingRight: 5 }} align="center">
                                                <Button
                                                    size="small"
                                                    onClick={handleLoveClick(row.market_company.stock_no)}
                                                    variant="outlined"
                                                    style={{ width: '100%', color: BColor.faircolor }}
                                                    className={classes.button}
                                                    startIcon={<FavoriteBorder />}
                                                >
                                                    管理
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {props.disablepage ?
                    <div><Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                    匯出
                  </Button></div>
                    : <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                    <Divider orientation="vertical" flexItem />
                    <Button size="small" className={classes.button} onClick={e => exportExcel(myContainer.current)}>
                      匯出
                    </Button>
                    <Divider orientation="vertical" flexItem />
                    <TablePagination
                      rowsPerPageOptions={getRowPageData(props.width)}
                      component="div"
                      count={props.total}
                      rowsPerPage={props.currentcount}
                      page={props.currentpage}
                      labelRowsPerPage={'每頁'}
                      labelDisplayedRows={({ from, to, count }) => `第${from}筆，共 ${count}筆`}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /></div>
                }
            </Paper>
        </div>
    );
}

export default withWidth()(MACDDetail);
