import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CashFlowSetting from '../details/CashFlowSetting'
import InterestSetting from '../details/InterestSetting'
import IntSetting from '../details/IntSetting'
import PERSetting from '../details/PERSetting';
import PBRSetting from '../details/PBRSetting';
import BColor from '../BColor'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width: `calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function CashDetail(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);


    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const handleClickOpen4 = () => {
        setOpen4(true);
    };

    const handleClose4 = () => {
        setOpen4(false);
    };


    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        props.bigupdate()
    };


    const renderInpriceStatus = (lowprice, highprice, minprice, lastvalue) => {
        if (lowprice === -1.0) {
            return <Button variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        } else if (lastvalue <= lowprice) {
            return <Button style={{ background: BColor.downcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1}>超低 {lastvalue}元</Button>
        } else if (lastvalue > lowprice && lastvalue < minprice) {
            return <Button variant="outlined" style={{ minWidth: 100, color: BColor.downcolorbox }} className={classes.button1}>偏低 {lastvalue}元</Button>
        } else if (lastvalue === minprice) {
            return <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button1} >合理 {lastvalue}元</Button>
        } else if (lastvalue > minprice && lastvalue < highprice) {
            return <Button variant="outlined" style={{ minWidth: 100, color: BColor.upcolorbox }} className={classes.button1} >偏高 {lastvalue}元</Button>
        } else if (lastvalue >= highprice) {
            return <Button style={{ background: BColor.upcolorbox, minWidth: 100, color: '#FFFFFF' }} className={classes.button1} >超高 {lastvalue}元</Button>
        } else {
            return <Button variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor }} className={classes.button1} >無法評估 {lastvalue}元</Button>
        }
    }

    function renderIntCell2(value, defaultv, unit, desc) {
        if (value != defaultv)
            return <StyledTableCell align="left">{desc}{(value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}{unit}</StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
        </StyledTableCell>

    }

    function renderIntCell1(value, defaultv) {
        if (value != defaultv)
            return <StyledTableCell align="left">{value}元</StyledTableCell>
        else return <StyledTableCell align="left">
            <Button variant="outlined" style={{ minWidth: 100, color: BColor.nodatacolor, marginBottom: 0 }} className={classes.button1} >-</Button>
        </StyledTableCell>
    }


    function renderprice(lastvalue, status) {
        let k
        if (status === -1) {
            k = <Button variant="outlined" style={{ minWidth: 130, color: BColor.nodatacolor }} className={classes.button1} >無法評估 {lastvalue}元</Button>
        } else if (status === 0) {
            k = <Button style={{ minWidth: 130, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>超低 {lastvalue}元</Button>
        } else if (status === 1) {
            k = <Button variant="outlined" style={{ minWidth: 130, color: BColor.downcolorbox }} className={classes.button1}>偏低 {lastvalue}元</Button>
        } else if (status === 2 || status === 3) {
            k = <Button variant="outlined" style={{ minWidth: 130, color: BColor.faircolor }} className={classes.button1} >合理 {lastvalue}元</Button>
        } else if (status === 4) {
            k = <Button variant="outlined" style={{ minWidth: 130, color: BColor.upcolorbox }} className={classes.button1} >偏高 {lastvalue}元</Button>
        } else if (status === 5) {
            k = <Button style={{ minWidth: 130, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >超高 {lastvalue}元</Button>
        } else {
            k = <Button variant="outlined" style={{ minWidth: 130, color: BColor.nodatacolor }} className={classes.button1} >無法評估 {lastvalue}元</Button>
        }

        return <StyledTableCell style={{}} align="left">
            {k}
        </StyledTableCell>
    }

    function render1a(value) {
        let color = BColor.faircolor

        let k = value + '元'
        if (value < 0) k = '-'

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} size="small" align="left">
            <Button variant="outlined" style={{ minWidth: 130, color: color, marginRight: 1 }} className={classes.button2} >
                {k}
            </Button>
        </StyledTableCell>
    }

    function render1(pe, eps) {
        let color = BColor.faircolor
        if (eps > 0) {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 100, color: color, marginRight: 1 }} className={classes.button2} >
                    {pe}
                </Button>
                <Button variant="outlined" style={{ minWidth: 100, color: color }} className={classes.button2} >
                    {(pe * eps).toFixed(2)}元
            </Button>
            </StyledTableCell>
        } else {
            return <StyledTableCell align="left">
                <Button variant="outlined" style={{ minWidth: 100, color: color, marginRight: 1 }} className={classes.button2} >
                    {pe}
                </Button>
                <Button variant="outlined" style={{ minWidth: 100, color: color }} className={classes.button2} >
                    -
            </Button>
            </StyledTableCell>
        }

    }


    function renderpricea(status) {
        let k

        if (status === 0) {
            k = <Button style={{ minWidth: 180, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>低於極度悲觀價</Button>
        } else if (status === 1) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.downcolorbox }} className={classes.button1}>極度悲觀價 ~ 過度悲傷價</Button>
        } else if (status === 2) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.faircolor }} className={classes.button1} >過度悲傷價 ~ 合理價</Button>
        } else if (status === 3) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.faircolor }} className={classes.button1} >合理價 ~ 過度樂觀價</Button>
        } else if (status === 4) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.upcolorbox }} className={classes.button1} >過度樂觀價 ~ 極度樂觀價</Button>
        } else if (status === 5) {
            k = <Button style={{ minWidth: 180, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >大於極度樂觀價</Button>
        } else {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} size="small" align="left">
            {k}
        </StyledTableCell>
    }

    function renderprice1a(status) {
        let k

        if (status === 0) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.downcolorbox }} className={classes.button1}>空方向下</Button>
        } else if (status === 1) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.upcolorbox }} className={classes.button1} >多方向上</Button>
        } else if (status === 2) {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.faircolor }} className={classes.button1} >盤整型態</Button>
        } else {
            k = <Button variant="outlined" style={{ minWidth: 180, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }

        return <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} size="small" align="left">
            {k}
        </StyledTableCell>
    }

    function renderTp1(happy) {
        let k

        if (happy.d != -1 && happy.p <= happy.d) {
            k = <Button size="small" style={{ minWidth: 180, background: BColor.downcolorbox, color: '#FFFFFF' }} className={classes.button1}>低於下通道</Button>
        } else if (happy.d != -1 && happy.avg != -1 && happy.p < happy.avg && happy.p > happy.d) {
            k = <Button size="small" variant="outlined" style={{ minWidth: 180, color: BColor.downcolorbox }} className={classes.button1}>20周均線 ~ 下通道</Button>
        } else if (happy.avg != -1 && happy.p === happy.avg) {
            k = <Button size="small" variant="outlined" style={{ minWidth: 180, color: BColor.faircolor }} className={classes.button1} >20周均線附近</Button>
        } else if (happy.u != -1 && happy.avg != -1 && happy.p > happy.avg && happy.p < happy.u) {
            k = <Button size="small" variant="outlined" style={{ minWidth: 180, color: BColor.faircolor }} className={classes.button1} >上通道 ~ 20周均線</Button>
        } else if (happy.u != -1 && happy.p >= happy.u) {
            k = <Button size="small" style={{ minWidth: 180, background: BColor.upcolorbox, color: '#FFFFFF' }} className={classes.button1} >高於上通道</Button>
        } else {
            k = <Button size="small" variant="outlined" style={{ minWidth: 180, color: BColor.nodatacolor }} className={classes.button1} >-</Button>
        }

        return <TableCell style={{ whiteSpace: 'nowrap', paddingLeft: 0, paddingRight: 0 }} size="small" align="left">
            {k}
        </TableCell>
    }


    return (
        <div>
            <Grid container spacing={3} className={classes.grid}>
                <CashFlowSetting open={open} handleClose={handleClose} handleOk={handleOk}></CashFlowSetting>
                <InterestSetting open={open1} handleClose={handleClose1} handleOk={handleOk}></InterestSetting>
                <IntSetting open={open2} handleClose={handleClose2} handleOk={handleOk}></IntSetting>
                <PERSetting open={open3} handleClose={handleClose3} handleOk={handleOk}></PERSetting>
                <PBRSetting open={open4} handleClose={handleClose4} handleOk={handleOk}></PBRSetting>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            現金流折現法
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen()}>調整參數</Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'收盤價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'收盤價'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInpriceStatus(props.row.inprice.c1l, props.row.inprice.c1h, props.row.inprice.c1m, props.row.inprice.f)}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'自由現金流'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'自由現金流'}
                                    </StyledTableCell>
                                    {renderIntCell2(props.row.inprice.free4s, -99999, '百萬元', '')}
                                </StyledTableRow >
                                <StyledTableRow key={'便宜價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'便宜價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.c1l, -1.0)}
                                </StyledTableRow >
                                <StyledTableRow key={'合理價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'合理價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.c1m, -1.0)}
                                </StyledTableRow >
                                <StyledTableRow key={'昂貴價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'昂貴價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.c1h, -1.0)}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            股利法
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen1()}>調整參數</Button>
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'收盤價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'收盤價'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInpriceStatus(props.row.inprice.i1l, props.row.inprice.i1h, props.row.inprice.i1m, props.row.inprice.f)}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'平均現金股利'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'平均現金股利'}
                                    </StyledTableCell>
                                    {renderIntCell2(props.row.inprice.ints, -99999.0, '元', '')}
                                </StyledTableRow >
                                <StyledTableRow key={'便宜價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'便宜價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.i1l, -1.0)}
                                </StyledTableRow >
                                <StyledTableRow key={'合理價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'合理價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.i1m, -1.0)}
                                </StyledTableRow >
                                <StyledTableRow key={'昂貴價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'昂貴價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.i1h, -1.0)}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            市場本益比比較法
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen2()}>調整參數</Button>
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'收盤價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'收盤價'}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {renderInpriceStatus(props.row.inprice.p1l, props.row.inprice.p1h, props.row.inprice.p1m, props.row.inprice.f)}
                                    </StyledTableCell>
                                </StyledTableRow >
                                <StyledTableRow key={'近四季EPS'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'近四季EPS'}
                                    </StyledTableCell>
                                    {renderIntCell2(props.row.inprice.eps, -99999.0, '元', '')}
                                </StyledTableRow >
                                <StyledTableRow key={'便宜價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'便宜價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.p1l, -1.0)}
                                </StyledTableRow >
                                <StyledTableRow key={'合理價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'合理價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.p1m, -1.0)}
                                </StyledTableRow >
                                <StyledTableRow key={'昂貴價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'昂貴價'}
                                    </StyledTableCell>
                                    {renderIntCell1(props.row.inprice.p1h, -1.0)}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            盈餘法
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen3()}>調整參數</Button>
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'0'}>
                                    <StyledTableCell component="th" scope="row">
                                        收盤價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? renderprice(props.row.ip2.lastp, props.row.ip2.rr1.status) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >
                                        -
            </Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'1'}>
                                    <StyledTableCell component="th" scope="row">
                                        5th本益比/超低價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? render1(props.row.ip2.rr1.l1, props.row.ip2.rr1.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'2'}>
                                    <StyledTableCell component="th" scope="row">
                                        25th本益比/偏低價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? render1(props.row.ip2.rr1.l2, props.row.ip2.rr1.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'3'}>
                                    <StyledTableCell component="th" scope="row">
                                        50th本益比/合理價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? render1(props.row.ip2.rr1.l3, props.row.ip2.rr1.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'4'}>
                                    <StyledTableCell component="th" scope="row">
                                        75th本益比/偏高價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? render1(props.row.ip2.rr1.l4, props.row.ip2.rr1.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'5'}>
                                    <StyledTableCell component="th" scope="row">
                                        95th本益比/超高價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? render1(props.row.ip2.rr1.l5, props.row.ip2.rr1.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'6'}>
                                    <StyledTableCell component="th" scope="row">
                                        今年預估EPS
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr1 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left"　　>
                                        {props.row.ip2.rr1.d1}元
                                    </StyledTableCell> : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            淨值法
                        </Typography>
                        <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen4()}>調整參數</Button>
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'7'}>
                                    <StyledTableCell component="th" scope="row">
                                        收盤價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? renderprice(props.row.ip2.lastp, props.row.ip2.rr2.status) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'8'}>
                                    <StyledTableCell component="th" scope="row">
                                        5th股價淨值比/超低價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? render1(props.row.ip2.rr2.l1, props.row.ip2.rr2.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'9'}>
                                    <StyledTableCell component="th" scope="row">
                                        25th股價淨值比/偏低價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? render1(props.row.ip2.rr2.l2, props.row.ip2.rr2.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'10'}>
                                    <StyledTableCell component="th" scope="row">
                                        50th股價淨值比/合理價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? render1(props.row.ip2.rr2.l3, props.row.ip2.rr2.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'11'}>
                                    <StyledTableCell component="th" scope="row">
                                        75th股價淨值比/偏高價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? render1(props.row.ip2.rr2.l4, props.row.ip2.rr2.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'12'}>
                                    <StyledTableCell component="th" scope="row">
                                        95th股價淨值比/超高價
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? render1(props.row.ip2.rr2.l5, props.row.ip2.rr2.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'13'}>
                                    <StyledTableCell component="th" scope="row">
                                        每股淨值
                                    </StyledTableCell>
                                    {props.row.ip2 != undefined && props.row.ip2.rr2 != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} align="left"　　>
                                        {props.row.ip2.rr2.d1}元
                                    </StyledTableCell> : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            樂活五線譜
                        </Typography>
                        {/* <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen4()}>調整參數</Button> */}
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'7'}>
                                    <StyledTableCell component="th" scope="row">
                                        收盤價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small"　>
                                        {props.row.happy.p}元
                                            </StyledTableCell> : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'8'}>
                                    <StyledTableCell component="th" scope="row">
                                        現行位階
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? renderpricea(props.row.happy.tp) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'9'}>
                                    <StyledTableCell component="th" scope="row">
                                        趨勢方向
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? renderprice1a(props.row.happy.dir) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'10'}>
                                    <StyledTableCell component="th" scope="row">
                                        極度悲觀價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.d1) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'11'}>
                                    <StyledTableCell component="th" scope="row">
                                        過度悲觀價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.d2) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'12'}>
                                    <StyledTableCell component="th" scope="row">
                                        合理價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.d3) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'13'}>
                                    <StyledTableCell component="th" scope="row">
                                        過度樂觀價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.d4) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'14'}>
                                    <StyledTableCell component="th" scope="row">
                                        極度樂觀價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.d5) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                            樂活通道
                        </Typography>
                        {/* <Button variant="outlined" style={{ marginLeft: 5 }} color="primary" size="small" onClick={event => handleClickOpen4()}>調整參數</Button> */}
                    </div>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="caption table" size="small">
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell>資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow key={'7'}>
                                    <StyledTableCell component="th" scope="row">
                                        收盤價
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }} size="small"　>
                                        {props.row.happy.p}元
                                            </StyledTableCell> : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'8'}>
                                    <StyledTableCell component="th" scope="row">
                                        現行位階
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? renderTp1(props.row.happy) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'9'}>
                                    <StyledTableCell component="th" scope="row">
                                        上通道
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.u) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'10'}>
                                    <StyledTableCell component="th" scope="row">
                                    20周均線
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.avg) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                                <StyledTableRow key={'11'}>
                                    <StyledTableCell component="th" scope="row">
                                    下通道
                                    </StyledTableCell>
                                    {props.row.happy != undefined ? render1a(props.row.happy.d) : <Button variant="outlined" style={{ minWidth: 100, color: BColor.faircolor }} className={classes.button2} >-</Button>}
                                </StyledTableRow >
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </div >
    );
}