import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ETFIntList from '../details/ETFIntList'
import BColor from '../BColor'
import TLEDetail from '../details/TLEDetail'

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#333333',
        color: theme.palette.common.white,
        fontSize: 16,
        // whiteSpace: 'nowrap'
    },
    body: {
        fontSize: 16,
        // whiteSpace: 'nowrap'
        // fontWeight: "bold"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.background.default,
        // },
    },
}))(TableRow)

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
        backgroundColor: theme.palette.type === "light" ? "#FFFFFF" : "#000000",
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        width:`calc(100% + ${6 + theme.spacing(1) }px)`
    }
}));


export default function MStockETF(props) {
    const classes = useStyles();
    const [openstock, setOpenstock] = React.useState(null);
    const [opensname, setOpenSname] = React.useState(null);
    const [concentopen, setConcentOpen] = React.useState(false);
    const [concentopen1, setConcentOpen1] = React.useState(false);

    const handleClickOpencc = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen(true);
    };

    const handleClosecc = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen(false);
    };

    const handleClickOpencc1 = (stock_no, stockName) => event => {
        setOpenstock(stock_no)
        setOpenSname(stockName)
        setConcentOpen1(true);
    };

    const handleClosecc1 = () => {
        setOpenstock(null)
        setOpenSname(null)
        setConcentOpen1(false);
    };


    function renderValueA(value, stockno, stockName) {
        let colorx = "#000205"
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = '持續大幅增強'
        } else if (value === 1) {
            colorx = BColor.upcolorbox
            word = '大幅增強'
        } else if (value === 2) {
            colorx = BColor.upcolorbox
            word = '小幅增強'
        } else if (value === 3) {
            colorx = BColor.downcolorbox
            word = '小幅減弱'
        } else if (value === 4) {
            colorx = BColor.downcolorbox
            word = '大幅減弱'
        } else if (value === 5) {
            colorx = BColor.downcolorbox
            word = '持續大幅減弱'
        } else {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValueB(value, unit, stockno, stockName) {
        let colorx = "#000205"

        if (value > 0) {
            colorx = BColor.upcolorbox
        } else if (value < 0) {
            colorx = BColor.downcolorbox
        } else {
            colorx = BColor.faircolor
        }
        // {(value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}
        return <div variant="outlined" style={{ color: colorx }} >{value}{unit}</div>
    }

    function renderValueC(value, unit, stockno, stockName) {
        let colorx = "#000205"
        return <div variant="outlined" style={{ color: colorx }}>{(value.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}{unit}</div>
    }

    function renderValueD(value, stockno, stockName) {
        let colorx = "#000205"
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = '往上'
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = '往下'
        } else if (value === 2) {
            colorx = "#000205"
            word = '持平'
        } else {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValueE(value, stockno, stockName) {
        let colorx = "#000205"
        let word = ''

        if (value === 0) {
            colorx = BColor.upcolorbox
            word = '金叉'
        } else if (value === 1) {
            colorx = BColor.downcolorbox
            word = '死叉'
        } else if (value === 2) {
            colorx = "#000205"
            word = '無交叉'
        } else {
            colorx = BColor.faircolor
            word = '-'
        }
        return <Button variant="outlined" onClick={handleClickOpencc(stockno, stockName)} style={{ minWidth: 110, color: colorx }} className={classes.button1}>{word}</Button>
    }

    function renderValue1(value) {
        let colorx = BColor.faircolor
        if (value > 0) {
            colorx = BColor.upcolor
        } else if(value < 0) {
            colorx = BColor.downcolor
        } 

        return <Button  variant="outlined"  style={{ minWidth: 110, color: colorx }} className={classes.button1}>{value}%</Button>
    }

    return (
        <div>
            <ETFIntList open={concentopen} handleClose={handleClosecc} stockId={openstock} stockName={opensname}></ETFIntList>
            <TLEDetail open={concentopen1} handleClose={handleClosecc1} stockId={openstock} stockName={opensname}></TLEDetail>

            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        淨值
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'最後更新日期'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'最後更新日期'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.i} {props.row.etf.j}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'成交價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'成交價'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.e}元</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'預估淨值'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'預估淨值'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.f}元</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'前一日淨值'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'前一日淨值'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.h}元</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                                <StyledTableRow key={'折溢價'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'折溢價'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    {renderValue1(props.row.etf.g)}
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'歷年配息'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'歷年配息'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" onClick={handleClickOpencc(props.row.market_company.stock_no, props.row.market_company.short_name)}  style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>明細</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >


                                <StyledTableRow key={'三大法人'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'三大法人'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" onClick={handleClickOpencc1(props.row.market_company.stock_no, props.row.market_company.short_name)}  style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>明細</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        受益權單位數
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'已發行'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'已發行'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{(props.row.etf.c.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'前一日差異'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'前一日差異'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button1}>{(props.row.etf.d.toString()).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >





                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', marginBottom: 5 }}>
                        <Typography className={classes.title} variant="h6" id="tableTitle">
                        基本資料
                        </Typography>
                    </div>

                    <TableContainer component={Paper} >
                        <Table className={classes.table} aria-label="caption table" size="small" style={{}}>
                            <caption></caption>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>名稱</StyledTableCell>
                                    <StyledTableCell align="left">資料</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow key={'基金名稱'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'基金名稱'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{  minWidth: 200, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.name}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'追蹤指數名稱'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'追蹤指數名稱'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{  minWidth: 200, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.target}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                                <StyledTableRow key={'基金類型'}>
                                    <StyledTableCell component="th" scope="row">
                                        {'基金類型'}
                                    </StyledTableCell>

                                    {props.row.etf != null && props.row.etf != undefined ? <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                    <Button  variant="outlined" style={{ minWidth: 200, color: BColor.faircolor }} className={classes.button1}>{props.row.etf.tpn}</Button>
                                    </StyledTableCell> : <StyledTableCell style={{ whiteSpace: 'nowrap' }} size="small" align="left">
                                            <Button variant="outlined" style={{ minWidth: 110, color: BColor.faircolor }} className={classes.button2} >
                                                -
                                                    </Button>
                                        </StyledTableCell>}
                                </StyledTableRow >



                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>                


                  
            </Grid>
        </div >
    );
}

